function generatePin() {
    // Randomly choose a length between 4 and 6
    const length = Math.floor(Math.random() * 3) + 4; // This gives either 4, 5, or 6
    
    // Generate a random number with the chosen length
    const min = Math.pow(10, length - 1);
    const max = Math.pow(10, length) - 1;
  
    const code = Math.floor(Math.random() * (max - min + 1)) + min;
  
    return code;
  }
  
  export default generatePin;
  