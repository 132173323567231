import React from 'react';
import EditImageStyleContainer from './EditImageContainer';

const EditImageStyleSection = ({
  previewImage,
  imageDesign,
  setImageDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload,
}) => {
  return (
    <>
      <EditImageStyleContainer
        imageDesign={imageDesign}
        setImageDesign={setImageDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditImageStyleSection;
