import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight } from "feather-icons-react/build/IconComponents";
import API from "../../API";
import { SHOPEASY_BLACK_LOGO } from "../../Assets/StaticData/StaticImage";
function VerifyEmail() {
    const location = useLocation();
    const navigate = useNavigate()
    const secretKey = window.location.href.split("/").pop();
    const [loader, setLoader] = useState(true)
    const [alreadyExist, setAlreadyExist] = useState(false)

    useEffect(() => {
        verifyEmail();
    }, []);
    const verifyEmail = async () => {
        // await fetch(
        //   `${process.env.REACT_APP_BACKEND_URL}/super/admin/tenant/emailVerify/${secretKey}`,
        //   {
        //     method: "POST",
        //     headers: {
        //       "content-type": "application/json",
        //     },
        //   }
        // ).then((res) => {
        //   if (res.status === 500) {
        //     toast.error("Something Went Wrong !  Check The Data You Entered ");
        //   }
        //   else if (res.status === 408) {
        //     navigate("/decline-page")
        //   }
        //   else if (res.status === 400) {
        //     setAlreadyExist(true)
        //   }
        //   setLoader(false);
        // });
        try {
            const ApiCall = await API.get(`/admin/user/verify/${secretKey}`)
            if (ApiCall.status === 408) {
                navigate("/decline-page")
            }
            else if (ApiCall.status === 400) {
                setAlreadyExist(true)
            }
        } catch (error) {
            console.log(error);
        }finally{
            setLoader(false)
        }
    };
    return (
        <div className="bg">
            {
                loader ?
                    (
                        <div className="flex flex-col items-center justify-center h-screen">
                            <div className="loader"></div>
                            <span className="p-5 font-medium">Almost there! We're getting things ready for you...</span>
                        </div>
                    ) :

                    <div className="relative flex min-h-screen flex-col items-center justify-center overflow-hidden py-6 sm:py-12">
                        <div className="pb-5">
                            <img className="max-w-[12rem] mx-auto" alt="logo" src={SHOPEASY_BLACK_LOGO} />
                        </div>
                        <div className="max-w-xl px-5 text-center">
                            {
                                alreadyExist ?
                                    <>
                                        <h2 className="mb-2 text-[30px] max-sm:text-[30px] max-sm:mb-5 font-bold text-red-700 ">
                                            Email already registered
                                        </h2>
                                        <button
                                            className="cursor-pointer inline-flex items-center ms-1 text-center hover:text-indigo-500 transition-all duration-150 font-medium underline  text-black"
                                            onClick={() => {
                                                navigate('/')
                                            }}
                                        >
                                            <ArrowLeft className="w-4 me-1 h-4" />
                                            Back to create store
                                        </button>
                                    </>
                                    :
                                    <>
                                        <h1 className="mb-4 text-[42px] max-sm:text-[30px] max-sm:mb-5 font-bold text-green-800">
                                            Registration Successful
                                        </h1>
                                        <p className="mb-2 text-lg text-black">
                                            <b className="text-xl m-2">
                                                Thank you for connecting with Shopeasy...
                                            </b>
                                            <br />{" "}
                                            <p className="font-medium text-black text-base">
                                                Please wait while we verify your store..We will inform you via
                                                email on your registered email..
                                            </p>
                                            <span className="font-medium text-[#234857]">
                                                <br></br>
                                                {location.state}
                                            </span>
                                        </p>
                                    </>

                            }
                        </div>
                        <marquee
                            className="text-black inline-block text-xl mt-10"
                            width="100%"
                            direction="left"
                            height="100px"
                        >
                            <pre>
                                Shopeasy.ai - AI-based e-Commerce platform to sell more
                                globally! Start your own e-Commerce store within <b className="me-2">30 seconds!</b>
                                Shopeasy.ai - AI-based eCommerce platform to sell more
                                globally! Start your own eCommerce store within <b className="me-2">30 seconds!</b>
                            </pre>

                        </marquee>
                        <div className="flex items-center justify-between">
                            <p className="text-center w-full text-base">
                                Login to your store
                                <Link
                                    className='cursor-pointer inline-flex items-center ms-1 text-center hover:text-indigo-500 transition-all duration-150 font-medium underline  text-black'
                                    to="/"
                                >
                                    Login
                                </Link>
                            </p>
                            <p className="text-center w-full text-base">
                                New to shopeasy?
                                <Link
                                    className='cursor-pointer inline-flex items-center ms-1 text-center hover:text-indigo-500 transition-all duration-150 font-medium underline  text-black'
                                    to="http://docs.shopeasy.ai"
                                    target="_blank"
                                >
                                    Get started
                                    <ArrowRight className="w-3 h-3 ms-1" />
                                </Link>
                            </p>
                        </div>
                    </div>
            }
        </div>
    );
}
export default VerifyEmail;
