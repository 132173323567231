import React, { useState, useEffect } from 'react';
import Recaptcha from 'react-recaptcha';
import { ArrowLeft, ArrowRight, Check, X } from 'feather-icons-react/build/IconComponents';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import API from '../../API';
import Notification from '../Notification';
import { clientData, GetClient } from '../../controllers/GetClient';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const CreateStore = ({ token, regisData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pageName = location.pathname.slice(1)
  const [err, seterr] = useState(false);
  const [captchaVarify, setCatptcha] = useState(false);
  const [storeName, setStoreName] = useState();
  const [mailSent, setMailSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailErr, setValidEmailErr] = useState(false);
  const [inputErr, setInputErr] = useState(false);
  const [bussinessCategories, setBussinessCategories] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [RegData, setRegData] = useState({
    first_name: regisData?.first_name ?? '',
    last_name: regisData?.last_name ?? '',
    email: regisData?.email ?? '',
    business_name: '',
    business_category: '',
  });
  // useEffect(() => {
  //   GetClient()
  // }, [token])
  const { first_name, last_name, email, business_name, business_category } = RegData;
  //Show And Hide Password
  const [message, setmessage] = useState('');
  // get All Entered  Input Data In our UseState
  useEffect(() => {
    if ((!token) && pageName === 'createstore') {
      navigate('/');
    }
  }, []);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  useEffect(() => {
    if(regisData) {
      setRegData({...RegData, email: regisData?.email, first_name: regisData?.first_name, last_name: regisData?.last_name})
      setValidEmail(true);
    }
  },[regisData])

  useEffect(() => {
    if(clientData?.business_email) {
      setRegData({...RegData, email: clientData?.business_email, first_name: clientData?.user?.first_name, last_name: clientData?.user?.last_name})
    }
  },[clientData?.business_email])

  const HandleInput = (e) => {
    if (e.target.name === 'email') {
      setEmailErr(false);
      setValidEmail(false);
      setValidEmailErr(false);
    }
    setRegData({ ...RegData, [e.target.name]: e.target.value });
  };
  console.log("regisData?.tenant", regisData?.tenant);
  // Api Call for Register
  const HandleRegister = async (e) => {
    e.preventDefault();
    setEmailErr(false);
    setLoader(true);
    const apiUrl = !regisData?.tenant ? '/super/admin/tenant/emailVerifySend' : '/super/admin/tenant/emailVerifySendForMultiStore'
    let body = {
      first_name,
      last_name,
      email,
      business_category,
      store_id: storeName,
      business_name,
    }
    if (pageName === 'createstore') {
      body = {
        ...body,
        id: clientData?.user?._id,
      }
    }
    if (
      business_category !== '' &&
      business_name !== '' &&
      email !== '' &&
      last_name !== '' &&
      first_name !== '' &&
      RegData?.business_category
    ) {
      try {
        const payload = encryptData(body)
        const ApiCall = await API.post(apiUrl, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const res = decryptData(ApiCall?.data);
          if(res?.data?.token) {
            localStorage.setItem('store_id', res?.data?.store_id);
            localStorage.setItem('token', res?.data?.token);
            navigate('/stores');
          } else {
            setMailSent(true);
          }
          setCatptcha(false);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
        setEmailErr(true);
      } finally {
        setLoader(false);
      }
    } else {
      setInputErr(true);
    }
  };

  const Handleblur = () => {
    setIsVarified(false);
    seterr(false);
    let store = business_name;
    const uniqueStore = store
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
      .replace(/[^a-zA-Z0-9\-]/g, '') // Remove special characters
      .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen;
    setStoreName(uniqueStore);
  };

  const getStoreCategories = async () => {
    try {
      const ApiCall = await API.get(`/super/admin/storecategory/getStoreCategory`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setBussinessCategories(result?.data?.categorys);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    getStoreCategories();
  }, []);

  const storeapidata = async () => {
    if (storeName?.length >= 3) {
      try {
        const storeApiData = await API.get(`/super/admin/tenant/storeIdVarify/${storeName}`);
        if (storeApiData.status === 200 || storeApiData.status === 304) {
          seterr(false);
          setIsVarified(true);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
        seterr(true);
      }
    }
  };
  const emailVerify = async () => {
    if (email?.length >= 3) {
      try {
        const storeApiData = await API.get(`/super/admin/tenant/validemail/${email}`);
        if (storeApiData.status === 200 || storeApiData.status === 304) {
          setValidEmail(true);
          setValidEmailErr(false);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Email_is_invalid'));
        setSeverity('error');
        setValidEmail(false);
        setValidEmailErr(true);
      }
    }
  };
  //captcha
  const [isVarified, setIsVarified] = useState(false);
  const RecaptchaLoaded = () => { };
  const [searchParams] = useSearchParams();
  const store = searchParams.get('store');
  useEffect(() => {
    if (store?.length > 0) {
      setRegData({
        ...RegData,
        business_name: store,
      });
      setStoreName(store?.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').replace(/-+/g, '-'))
      handleVerifyFromParams(store)
    }
  }, [store]);
  const handleVerifyFromParams = async (store) => {
    const makeId = store?.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9\-]/g, '').replace(/-+/g, '-')
    try {
      const storeApiData = await API.get(`/super/admin/tenant/storeIdVarify/${makeId}`);
      if (storeApiData.status === 200 || storeApiData.status === 304) {
        seterr(false);
        setIsVarified(true);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      seterr(true);
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='bg flex h-screen w-screen items-center justify-center'>
        <div className='w-[90%] form sm:w-8/12 xl:w-1/3 m-5 bg-white p-5 sm:p-7 rounded-[6px]'>
          <div className='mb-2'>
            <img
              className='mx-auto max-w-[12rem]'
              src='https://cdn.shopeasy.ai/1/category/shopeasylogo.png'
              alt='Your Company'
            />
            {!mailSent && !loader && (
              <>
                <h6 className='mt-4 text-center font-medium text-primary'>
                  {t('lang_Create_your_store')}
                </h6>
                <p className='text-center text-[14px] table-text'>
                  {t('lang_Your_account_will_allow_you_to_partner_with_shopeasy')}
                </p>
              </>
            )}
          </div>
          {loader && (
            <div className='flex flex-col items-center justify-center'>
              <div className='loader my-4'></div>
              <p className='text-center text-base w-[75%] mx-auto mt-4'>
                {t('lang_Your_account_will_allow_you_to_partner_with_shopeas_details')}
              </p>
            </div>
          )}

          {!mailSent && !loader ? (
            <div className=''>
              <hr className='my-4 border-gray-300' />
              <input type='hidden' name='remember' defaultValue='true' />
              <div className='rounded-md  space-y-4'>
                <div className='grid grid-cols-2 gap-5'>
                  <div>
                    <label
                      htmlFor='first_name'
                      className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                    >
                      {t('lang_firstname')}<span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      id='first_name'
                      name='first_name'
                      autoFocus
                      tabIndex={1}
                      type='text'
                      value={first_name}
                      required
                      onChange={HandleInput}
                      className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                      placeholder='Enter your first name'
                    />
                  </div>
                  <div>
                    <label
                      htmlFor='last_name'
                      className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                    >
                      {t('lang_lastname')}<span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      id='last_name'
                      name='last_name'
                      tabIndex={2}
                      type='text'
                      value={last_name}
                      required
                      onChange={HandleInput}
                      className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                      placeholder='Enter your last name'
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor='email-address'
                    className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                  >
                    {t('lang_email_address')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    id='email-address'
                    name='email'
                    type='email'
                    tabIndex={3}
                    value={email}
                    required
                    onChange={HandleInput}
                    className={`relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]`}
                    placeholder='Enter your email address'
                    onBlur={emailVerify}
                  />
                  {emailErr && (
                    <h1 className='text-sm text-red-500 ps-1'>{t('lang_Email_already_registered')}</h1>
                  )}
                  {inputErr && email === '' && (
                    <h1 className='text-sm text-red-500 ps-1'>{t('lang_Email_is_required')}</h1>
                  )}
                  {validEmailErr && (
                    <p className='text-sm text-red-500 ps-1'>{t('lang_Email_is_invalid')}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='Businessname'
                    className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                  >
                    {t('lang_Business_Name')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <input
                    id='BusinessName'
                    name='business_name'
                    type='Text'
                    tabIndex={4}
                    value={business_name}
                    onBlur={Handleblur}
                    onChange={HandleInput}
                    required
                    className='relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]'
                    placeholder='Enter your bussiness name'
                  />
                  {inputErr && business_name === '' && (
                    <h1 className='text-sm text-red-500 font-medium'>{t('lang_Business_Name_is_required')}</h1>
                  )}
                </div>
                <div>
                  <label
                    htmlFor='Store Id'
                    className='capitalize text-gray-600 text-[13px] mb-1 font-medium'
                  >
                    {t('lang_Store_Id')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <div className='relative'>
                    <input
                      id='Store Id'
                      name='storeName'
                      type='text'
                      value={storeName}
                      tabIndex={5}
                      required
                      onChange={(e) => {
                        setStoreName(
                          e.target.value
                            .toLowerCase()
                            .replace(/\s+/g, '-') // Replace consecutive spaces with a single hyphen
                            .replace(/[^a-zA-Z0-9\-]/g, '') // Remove special characters
                            .replace(/-+/g, '-'), // Replace multiple hyphens with a single hyphen;
                        );
                        setIsVarified(false);
                        seterr(false);
                      }}
                      className='relative block w-full rounded-[6px] border border-gray-300 outline-none focus:border-gray-500 py-[11px] text-gray-900 pl-4 placeholder:text-gray-400  max-[420px]:text-sm text-[15px]'
                      placeholder='Store id'
                      verifyCallback={(response) =>
                        response ? setIsVarified(true) : setIsVarified(false)
                      }
                      onBlur={() => setmessage('')}
                    />
                    {message}
                    {!err && storeName && !isVarified && storeName.length >= 3 ? (
                      <span
                        className='absolute z-10 py-1 top-[50%] -translate-y-[50%] right-3 cursor-pointer justify-end rounded-[6px] bg-green-600 px-3 text-xs font-medium text-white hover:bg-[#28282B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#28282B]'
                        tabIndex={6}
                        onClick={storeapidata}
                      >
                        {t('lang_Verify')}
                      </span>
                    ) : null}
                    {err && (
                      <h1 className='absolute z-10 top-[50%] -translate-y-[50%] right-3 text-white rounded-full bg-red-600 h-[22px] w-[22px] flex items-center justify-center'>
                        <X className='w-4 h-4 stroke-white' />
                      </h1>
                    )}
                    {isVarified ? (
                      <h1 className='absolute z-10 top-[50%] -translate-y-[50%] right-3 text-white rounded-full bg-green-600 h-[22px] w-[22px] flex items-center justify-center'>
                        <Check className='w-4 h-4 stroke-white' />
                      </h1>
                    ) : (
                      <></>
                    )}
                  </div>
                  {storeName && storeName?.length >= 3 && !isVarified && !err ? (
                    <span className='text-red-500 font-medium inline-block text-[13px] mt-0'>
                      {t('lang_Please_click_on_verify')}
                    </span>
                  ) : (
                    <></>
                  )}
                  {storeName && storeName?.length < 3 && !isVarified && !err ? (
                    <span className='text-red-500 font-medium inline-block text-[13px] mt-0'>
                      {t('lang_Minimum_3_characters_required')}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <label className='capitalize text-gray-600 text-[13px] mb-1 font-medium'>
                    {t('lang_Business_Category')}<span className='text-red-500 ms-1'>*</span>
                  </label>
                  <Autocomplete
                    className='autocomplete'
                    tabIndex={7}
                    options={bussinessCategories?.length > 0 ? bussinessCategories : []}
                    getOptionLabel={(category) => category}
                    disableClearable
                    onChange={(e, v) => {
                      setRegData({ ...RegData, business_category: v });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder='Select business category'
                      />
                    )}
                  />
                  {inputErr && business_category === '' && (
                    <h1 className='text-sm text-red-500 font-medium'>
                      {t('lang_Business_Category_is_required')}
                    </h1>
                  )}
                </div>
              </div>
              <div className='mt-4'>
                <Recaptcha
                  className='rounded-[6px]'
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  render='explicit'
                  onloadCallback={RecaptchaLoaded}
                  verifyCallback={(response) => (response ? setCatptcha(true) : setCatptcha(false))}
                />
                {!isVarified ||
                  !validEmail ||
                  !captchaVarify ||
                  inputErr ||
                  RegData?.business_category === '' ||
                  RegData?.email === '' ||
                  RegData?.business_name === '' ? (
                  <>
                    <button className='mt-4 opacity-40 rounded-[6px] text-sm text-white w-full block cursor-not-allowed bg-btn'>
                      {t('lang_Create_Store')}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className='mt-4 text-sm text-white w-full block btn'
                      onClick={(e) => HandleRegister(e)}
                    >
                      {t('lang_Create_Store')}
                    </button>
                  </>
                )}
              </div>
              <div className='flex mt-2 items-center justify-between'>
                <p className=' w-full text-sm'>
                  {
                    pageName === 'register' && !location?.state?.email &&
                      <>
                        Already have a store?
                        <Link
                          className='cursor-pointer inline-flex items-center ms-1 text-link transition-all duration-150 font-medium hover:underline  text-black'
                          to='/'
                        >
                          Login
                          {/* <ArrowRight className='w-3 h-3 ms-1' /> */}
                        </Link>
                      </>
                  }
                  {pageName !== 'register' && <Link
                      className='cursor-pointer inline-flex items-center ms-1 text-center text-link transition-all duration-150 font-medium hover:underline  text-black'
                      to='/dashboard'
                    >
                      <ArrowLeft className='w-3 h-3 me-1' />
                      {t('lang_Go_To_Dashboard')}
                    </Link>}
                </p>
                <p className='w-full text-right text-sm'>
                  {t('lang_New_to_shopeasy')}
                  <Link
                    className='cursor-pointer inline-flex items-center ms-1 text-link transition-all duration-150 font-medium hover:underline  text-black'
                    to='http://docs.shopeasy.ai'
                    target='_blank'
                  >
                    {t('lang_Get_started')}
                    <ArrowRight className='w-3 h-3 ms-1' />
                  </Link>
                </p>
              </div>
            </div>
          ) : (
            !loader && (
              <div className=''>
                <iframe src="https://lottie.host/embed/7abeede6-4a08-4154-873a-b6d47731c72d/Djs0gi9Rcc.json" className='mx-auto'></iframe>
                <p className='text-center text-base w-[70%] mx-auto mt-4'>
                  {t('lang_Check_your_email_inbox_and_verify_your_e_mail_address')}
                </p>
                <div className='text-center'>
                  <button
                    className='mx-auto font-medium inline-flex items-center underline text-sm text-link'
                    onClick={() => {
                      setMailSent(false);
                      setRegData({
                        email: '',
                        business_name: '',
                        business_category: '',
                      });
                      setStoreName('');
                      setIsVarified(false);
                      navigate('/');
                    }}
                  >
                    Login to store
                    <ArrowRight className='w-4 h-4 ms-1' />
                  </button>
                </div>
              </div>
            )
          )}
        </div>
      </div >
    </>
  );
};

export default CreateStore;
