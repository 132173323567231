import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const AddBlogGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [blogGroupTitle, setBlogGroupTitle] = useState('');
  const [blogData, setBlogData] = useState({
    button_link: '/blog',
    blog_limit: 3,
    carousel_status: true,
    status: true,
    blog_image_width: '',
    blog_image_height: '',
    carousel_speed_ms: '',
    button_text: '',
    blog_display_grid: true,
  });
  const [addBlog, setAddBlog] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddBlog = () => {
    setAddBlog(false);
  };
  const [selectedBlogs, setSelectedBlogs] = useState([]);
  const handleSelectBlog = (blog, name, image) => {
    if (blog) {
      const existBlog = selectedBlogs.find((x) => x?.blog === blog);
      if (!existBlog) {
        setSelectedBlogs([...selectedBlogs, { blog, name, image }]);
      } else {
        const removeSelected = selectedBlogs.filter((x) => x?.blog !== blog);
        setSelectedBlogs(removeSelected);
      }
    }
  };
  const [allBlogs, setAllBlogs] = useState([]);
  const [search, setSearch] = useState('');
  const GetBlogs = async () => {
    try {
      const apiCall = await API.get(`/admin/blog/GetBlogTable?searchkey=${search}&is_draft=false`);
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllBlogs(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addBlog === true && (search?.length === 0 || search?.length >= 3)) {
      GetBlogs();
    }
  }, [addBlog, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBlogGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      let body = {
        blog_section_title: blogGroupTitle,
        button_text: blogData?.button_text,
        button_link: blogData?.button_link,
        blog_display_grid: blogData?.blog_display_grid,
        blog_limit: blogData?.blog_limit,
        carousel_status: blogData?.carousel_status,
        status: blogData?.status,
        carousel_speed_ms: blogData?.carousel_speed_ms,
        blogs: selectedBlogs,
      };
      if (blogData?.blog_image_height?.length > 0 || blogData?.blog_image_width?.length > 0) {
        body = {
          ...body,
          blog_image_width: blogData?.blog_image_width,
          blog_image_height: blogData?.blog_image_height,
        };
      }
      const payload = encryptData(body)
      await API.post(`/admin/dynamicBlog/addBlogGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/blog-group',
        title: 'add blog group',
      });
      navigate('/module-setting/blog-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    if (
      selectedBlogs?.length > 0 ||
      blogData?.carousel_speed_ms?.length > 0 ||
      blogData?.button_text?.length > 0 ||
      blogGroupTitle?.length > 0 ||
      blogData?.blog_image_height?.length > 0 ||
      blogData?.blog_image_width?.length > 0 ||
      blogData?.blog_display_grid === false ||
      blogData?.status === false ||
      blogData?.carousel_status === false ||
      blogData?.blog_limit !== 3 ||
      blogData?.button_link !== '/blog'
    ) {
      setSaveChanges({
        backLink: '/module-setting/blog-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add blog group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/blog-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add blog group',
      });
    }
  }, [selectedBlogs, blogData, blogGroupTitle]);
  const checkChanges = () => {
    if (
      selectedBlogs?.length > 0 ||
      blogData?.carousel_speed_ms?.length > 0 ||
      blogData?.button_text?.length > 0 ||
      blogGroupTitle?.length > 0 ||
      blogData?.blog_image_height?.length > 0 ||
      blogData?.blog_image_width?.length > 0 ||
      blogData?.blog_display_grid === false ||
      blogData?.status === false ||
      blogData?.carousel_status === false ||
      blogData?.blog_limit !== 3 ||
      blogData?.button_link !== '/blog'
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/blog-group',
        title: 'add blog group',
      });
    } else {
      navigate('/module-setting/blog-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Blog_Group'), path: '/module-setting/blog-group' },
                { name: t('lang_Add_New_Blog_Group'), path: '/blog-categories/add-blog-group' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Blog_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Blog_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={blogGroupTitle}
                onChange={(e) => setBlogGroupTitle(e.target.value)}
                placeholder='Enter group title....'
              />
              {dataErr && blogGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Blog_Group_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={blogData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, status: !blogData?.status });
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Blog_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_limit}
                    onChange={(e) => setBlogData({ ...blogData, blog_limit: e.target.value })}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full'>
                <label>{t('lang_Blog_Display')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_display_grid}
                    onChange={(e) =>
                      setBlogData({ ...blogData, blog_display_grid: e.target.value })
                    }
                  >
                    <option value={true}>{t('lang_Grid')}</option>
                    <option value={false}>{t('lang_List')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={blogData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, carousel_status: !blogData?.carousel_status });
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.carousel_speed_ms}
                    disabled={blogData?.carousel_status === 'disabled'}
                    onChange={(e) =>
                      setBlogData({ ...blogData, carousel_speed_ms: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={blogData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => setBlogData({ ...blogData, button_text: e.target.value })}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={blogData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => setBlogData({ ...blogData, button_link: e.target.value })}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Width')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_width}
                    onChange={(e) => setBlogData({ ...blogData, blog_image_width: e.target.value })}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 318'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Height')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_height}
                    onChange={(e) =>
                      setBlogData({ ...blogData, blog_image_height: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 192'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Select_Blogs_For_Show_In_This_Group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddBlog(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                {t('lang_Select_Blogs')}
              </span>
            </div>
            {selectedBlogs?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Blog_Name')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!addBlog &&
                      selectedBlogs?.map((row) => {
                        return (
                          <tr>
                            <td className='table-image px-3 text-center min-w-[80px]'>
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                width={80}
                                height={50}
                                src={row.image || NOPHOTO_IMAGE}
                                className='p-1 max-w-full max-h-full object-contain'
                                alt={row.name}
                              />
                            </td>
                            <td className='w-full px-3'>
                              <span className='line-clamp-2'>{row.name || '-'}</span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>
        </form>
        <Dialog
          open={addBlog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddBlog(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>{t('lang_Select_Blogs')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddBlog(false);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search blogs..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allBlogs?.map((elem) => {
                return (
                  <label className='flex py-2 main-border-color table-text items-start space-x-4'>
                    <input
                      type='checkbox'
                      checked={selectedBlogs?.find((x) => x.blog === elem?._id)}
                      value={elem?._id}
                      onClick={(e) => handleSelectBlog(elem?._id, elem?.title, elem?.image)}
                      className='block mt-1 w-4 h-4'
                    />
                    <div className='flex space-x-2'>
                      <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={elem?.image || NOPHOTO_IMAGE}
                          className='max-w-full max-h-full'
                          alt='blog image'
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.title}</p>
                    </div>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddBlog()}>
              Add blogs
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Blog_Group')}
            </Link>
            <div>
              {!blogGroupTitle || blogGroupTitle === '' || selectedBlogs?.length === 0 ? (
                <button
                  type='button'
                  disabled
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Blog_group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[144px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBlogGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Blog_group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBlogGroup;
