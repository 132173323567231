import {
    Autocomplete,
    Dialog,
    TextField
} from '@mui/material';
import { ArrowLeft, Check, X } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from "../../API";
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FileDownloader } from '../../controllers/FileDownload';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import Notification from '../Notification';
import { t } from 'i18next';
const ViewContract = () => {
    const params = useParams()
    const [contractData, setContractData] = useState({});
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [addchange, setAddChange] = useState(false)
    const [severity, setSeverity] = useState()
    const [editShipping, setEditShipping] = useState({
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        pincode: ""
    })
    const GetContractData = async () => {
        try {
            const data = await API.get(`/admin/payment/getsubscriptionorder/${params?.view_contract}`);
            if (data.status === 200 || data.status === 304) {
                const result = decryptData(data?.data)
                setContractData(result);
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    };
    useEffect(() => {
        GetContractData();
    }, [params?.view_contract]);

    const navigate = useNavigate();

    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    const handlePlanStatus = async (elem) => {
        try {
            await API.patch(`/admin/payment/subscriptionOrderStatusChange/${elem?._id}?status=${elem?.status === 'active' ? 'skipped' : 'active'}`)
            await GetContractData();
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification
                    message={notificationMsg}
                    close={handleClose}
                    severity={severity}
                />
            )}
            <div className='h-auto overflow-y-auto pb-[64px]'>
                <div className='sm:flex block items-center justify-between w-full overflow-auto pb-[30px]'>
                    <div className='flex items-center space-x-2'>
                        <button
                            onClick={(e) => { e.preventDefault(); navigate('/contracts'); }}
                            className='back-arrow'
                        >
                            <ArrowLeft />
                        </button>
                        <div>
                            <Breadcrumbs
                                crumbs={[
                                    { name: t('lang_Contracts'), path: '/contracts' },
                                    { name: t('lang_Contract_detail'), path: '/contract/contracts-details' },
                                ]}
                            />
                            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                                {t('lang_Contract_detail')}
                            </h4>
                        </div>
                    </div>
                </div>

                <div className='overflow-x-hidden w-full'>
                    <div className='inline-block w-full'>
                        <h6 className='text-base md:text-xl font-medium my-4 text-primary'>
                            {t('lang_Subscription')}
                        </h6>
                        <div className='flex justify-between secondary-bg-color border rounded-[15px] main-border-color p-5'>
                            <div className='w-[60%]'>
                                <div className='flex items-center space-x-4'>
                                    <div className='w-[50px] h-[50px] border border-gray-300 p-0.5 flex items-center justify-between'>
                                        <img
                                            onError={(e) => { e.target.src = NOPHOTO_IMAGE; }}
                                            src={contractData?.product?.image || NOPHOTO_IMAGE}
                                            className='max-h-full max-w-full'
                                            alt={contractData?.product?.product_name}
                                        />
                                    </div>
                                    <span className='text-sm'>
                                        {contractData?.product?.product_name}
                                    </span>
                                </div>
                                <div className='mt-2 flex items-center'>
                                    <span className='text-sm inline-block me-2'>
                                        {contractData?.plan}
                                    </span>
                                </div>
                            </div>
                            <div className='w-[40%]'>
                                <div className='mt-2 flex items-center'>
                                    <span className='text-sm inline-block me-2'>
                                        {`${t('lang_payment')} ${('lang_method')}`}:
                                    </span>
                                    <p className='text-sm inline-block'>
                                        {contractData?.payment_method}
                                    </p>
                                </div>
                                <div className='mt-2 flex items-center gap-5'>
                                    <span className='text-sm inline-block me-2'>
                                        {FormatAmount(contractData?.discount)} × {contractData?.quantity}
                                    </span>
                                    <p className='text-sm inline-block'>
                                        {FormatAmount(contractData?.discount * contractData?.quantity)}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h6 className='text-base md:text-xl font-medium my-4 text-primary'>
                            {t('lang_Customer_detail')}
                        </h6>
                        <div className='flex justify-between secondary-bg-color border rounded-[15px] main-border-color p-5'>
                            <div className='w-full'>
                                <p className='text-sm inline-block me-2'>
                                    {t('lang_customer')}:
                                </p>
                                <p className='text-sm inline-block'>
                                    {contractData?.user?.name}
                                </p>
                                <div className='mt-2 flex items-center'>
                                    <span className='text-sm inline-block me-2'>
                                        {t('lang_Shipping_address')}:
                                    </span>
                                    <p className='text-sm inline-block'>
                                        {[
                                            contractData?.addresses?.addressLine1,
                                            contractData?.addresses?.addressLine2,
                                            contractData?.addresses?.city,
                                            contractData?.addresses?.state,
                                            contractData?.addresses?.pincode
                                        ].filter(Boolean).join(', ')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h6 className='text-base md:text-xl font-medium my-4 text-primary'>
                            {t('lang_Upcoming_orders')}
                        </h6>
                        <div className='w-full border main-border-color overflow-x-auto overflow-y-hidden rounded-[15px]'>
                            <table className='w-full'>
                                <thead>
                                    <tr>
                                        <td className='px-3 min-w-[200px] w-full text-left'>{t('lang_Upcoming_date')}</td>
                                        <td className='px-3 min-w-[150px] max-w-[150px] text-right'></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contractData?.subscriptions?.map((elem, index) => (
                                        <tr key={index} className='bg-white'>
                                            <td className='px-3 min-w-[200px] w-full text-left'>
                                                {formatDate(elem?.date)}
                                            </td>
                                            <td className='px-3 min-w-[150px] max-w-[150px] text-right'>
                                                <button className='text-link' type='button' onClick={() => handlePlanStatus(elem)}>{elem?.status === 'active' ? t('lang_Skip') : t('lang_Resume')}</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ViewContract;
