import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useState } from 'react';
import API from '../../API';
import { useEffect } from 'react';
import { GetClient, clientData } from '../../controllers/GetClient';
import Notification from '../Notification';
import { CurrencyOptions } from '../../Assets/StaticData/CurrencyOption';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { encryptData } from '../../controllers/encryptionUtils';
const CurrencyFormat = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const currencyPosition = [
    {
      position: t('lang_Left'),
      type: 'left',
    },
    {
      position: t('lang_Right'),
      type: 'right',
    },
    {
      position: t('lang_Left_With_Space'),
      type: 'left_with_space',
    },
    {
      position: t('lang_Right_With_Space'),
      type: 'right_with_space',
    },
  ];
  const dateFormat = [
    'DD/MM/YYYY',
    'MM/DD/YYYY',
    'DD/MM/YY',
    'MM/DD/YY',
    'YYYY-MM-DD',
    'MMMM D, YYYY',
  ];
  const timeFormat = ['h:mm a', 'h:mm A', 'HH:mm'];
  const currency = localStorage.getItem('currencyrate')?.length > 0 && JSON.parse(localStorage.getItem('currencyrate'));
  const ratesArray = Object.keys(currency).map(key => {
    return { name: key, rate: currency[key] };
  }) ?? [];
  const clientInfo = clientData;
  const [selectCuruncyPosition, setSelectCuruncyPosition] = useState(clientInfo?.currency_position);
  const [selectDateFormat, setSelectDateFormat] = useState(clientInfo?.date_format);
  const [selectTimeFormat, setSelectTimeFormat] = useState(clientInfo?.time_format);
  const [currencyData, setCurrencyData] = useState(clientInfo?.currency_format);
  const [thousandSeparator, setThousandSeparator] = useState(clientInfo?.thousand_separator);
  const [decimalSeparator, setDecimalSeparator] = useState(clientInfo?.decimal_separator);
  const [numOfDecimal, setNumOfDecimal] = useState(clientInfo?.number_of_decimals);
  const filterOptions = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.code?.toLowerCase()} ${option?.label?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        clientInfo: {
          currency_format: currencyData,
          currency_position: selectCuruncyPosition,
          thousand_separator: thousandSeparator,
          decimal_separator: decimalSeparator,
          number_of_decimals: numOfDecimal,
          date_format: selectDateFormat,
          time_format: selectTimeFormat,
        },
      });
      await API.put('/admin/client/updateClient', payload);
      GetClient();
      setIsNotification(true);
      setNotificationMsg(`Currency format is set to ${currencyData}`);
      setSeverity('success');
    } catch (error) {
      console.log(error);
    }
  };
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isChange) {
      updateFormat();
    }
  }, [currencyData]);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Currency_Date_Format')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Setup_currency_date_format_of_your_store')}
            </p>
          </div>
        </div>
        <form className='grid gap-4 grid-cols-2 sm:pb-[25px]'>
          <div>
            <label>{t('lang_Currency_Format')}</label>
            <Autocomplete
              className='autocomplete'
              options={ratesArray ?? []}
              filterOptions={filterOptions}
              getOptionLabel={(currency) => currency?.name}
              value={ratesArray?.find((x) => x.name === currencyData) || null}
              onChange={(e, v) => {
                setCurrencyData(v?.name);
              }}
              renderInput={(currency) => (
                <TextField
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  placeholder='Select currency Format'
                  {...currency}
                />
              )}
            />
          </div>
          <div>
            <label>{t('lang_Currency_Position')}</label>
            <Autocomplete
              className='autocomplete capitalize'
              options={currencyPosition ? currencyPosition : []}
              getOptionLabel={(position) => position?.position}
              value={currencyPosition?.find((x) => x?.type === selectCuruncyPosition) || null}
              onChange={(e, v) => {
                setSelectCuruncyPosition(v?.type);
              }}
              renderInput={(position) => (
                <TextField
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  placeholder='Select currency position'
                  {...position}
                />
              )}
            />
          </div>
          <div>
            <label>{t('lang_thousand_Separator')}</label>
            <input
              type='text'
              value={thousandSeparator}
              onChange={(e) => setThousandSeparator(e.target.value)}
              className='w-full outline-none border main-border-color'
            />
          </div>
          <div>
            <label>{t('lang_decimal_Separator')}</label>
            <input
              type='text'
              value={decimalSeparator}
              onChange={(e) => setDecimalSeparator(e.target.value)}
              className='w-full outline-none border main-border-color'
            />
          </div>
          <div>
            <label>{t('lang_number_of_decimals')}</label>
            {/* <input
              type='number'
              value={numOfDecimal}
              onChange={(e) => setNumOfDecimal(e.target.value)}
              className='w-full outline-none border main-border-color'
            /> */}
            <input
              type='number'
              value={numOfDecimal}
              min={0}
              max={5}
              maxLength={1} // This ensures only one digit can be entered
              onChange={(e) => {
                const value = e.target.value;
                // Check if the input value is between 0 and 5
                if (value >= 0 && value <= 5) {
                  setNumOfDecimal(value);
                }
              }}
              className='w-full outline-none border main-border-color'
            />
            <p className='text-xs mt-1 text-gray-400'>max 5 decimals allowed</p>
          </div>
          <div>
            <label>{t('lang_Date_Format')}</label>
            <Autocomplete
              className='autocomplete capitalize'
              options={dateFormat ? dateFormat : []}
              getOptionLabel={(date) => date}
              value={dateFormat?.find((x) => x === selectDateFormat) || null}
              onChange={(e, v) => {
                setSelectDateFormat(v);
              }}
              renderInput={(date) => (
                <TextField
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  placeholder='Select date format'
                  {...date}
                />
              )}
            />
          </div>
          <div>
            <label>{t('lang_Time_Format')}</label>
            <Autocomplete
              className='autocomplete capitalize'
              options={timeFormat ? timeFormat : []}
              getOptionLabel={(time) => time}
              value={timeFormat?.find((x) => x === selectTimeFormat) || null}
              onChange={(e, v) => {
                setSelectTimeFormat(v);
              }}
              renderInput={(time) => (
                <TextField
                  onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                  placeholder='Select time format'
                  {...time}
                />
              )}
            />
          </div>
        </form>
        <div className='w-full flex justify-end'>
          <button className='btn text-sm text-white' onClick={(e) => handleSubmit(e)}>
            {t('lang_Apply_Changes')}
          </button>
        </div>
      </div>
    </>
  );
};

export default CurrencyFormat;
