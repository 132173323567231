import { Backdrop, CircularProgress, Skeleton, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
const EditManufacturer = () => {
  const params = useParams();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [warn, setwarn] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [mData, setmData] = useState({
    manufacturerName: '',
    meta_title: '',
    meta_description: '',
    manufactureId: '',
  });
  const [description, setDescription] = useState();
  const [image, setimage] = useState();
  const [slugName, setSlugName] = useState();
  const [brandData, setBrandData] = useState();
  const [formSpinner, setFormSpinner] = useState(true);
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const clientInfo = clientData;
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/manufacture`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  useEffect(() => {
    getMetaField();
  }, [])
  const getManufacturerBySlugname = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(`/admin/manufactures/GetManufacturerById/${params?.slugname}`);
      const result = decryptData(ApiCall?.data)
      setBrandData(result?.data);
      setmData({
        manufacturerName: `${result?.data?.brand_name}`,
        meta_title: result?.data?.meta_title ? `${result?.data?.meta_title}` : '',
        meta_description: result?.data?.meta_description
          ? `${result?.data?.meta_description}`
          : '',
        manufactureId: result?.data?._id,
      });
      setDescription(result?.data?.description);
      setimage(`${result?.data?.brand_logo}`);
      setSlugName(
        result?.data?.brand_slug_name
          ? result?.data?.brand_slug_name
          : result?.data?.brand_name,
      );
      setmeta_fields(result?.data?.custom_fields);
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getManufacturerBySlugname();
  }, [params?.slugname]);
  // image upload logic
  const [err, setErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setIsChange(true);
    setimage(value);
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  const handleGetSelectedProduct = async () => {
    try {
      let getProductsForBrand = await API.get(
        `/admin/manufactures/GetProductsForBrand?brand=${mData?.manufactureId}&brand_exist=1`,
      );
      const result = decryptData(getProductsForBrand?.data)
      setSelectedProduct(
        result?.data?.records?.length > 0 &&
        result?.data?.records?.map((x) => {
          return { name: x?.product_name, product: x?._id, image: x?.image };
        }),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const [selectedRemove, setSelectedRemove] = useState([]);
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    setSelectedRemove([...selectedRemove, id]);
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  useEffect(() => {
    if (mData?.manufactureId) {
      handleGetSelectedProduct();
    }
  }, [mData?.manufactureId]);
  const handleAddProduct = async (id) => {
    removeBrandInProduct();
    try {
      const payload = encryptData({
        id: selectedProduct?.map((x) => x?.product),
        brand: brandData?._id,
      })
      await API.post(`/admin/manufactures/AssignBrandInProduct`, payload);
    } catch (error) {
      console.log(error);
    }
  };
  const removeBrandInProduct = async () => {
    try {
      const payload = encryptData({
        brand: brandData?._id,
        id: selectedRemove?.map((x) => x),
      })
      await API.post(`/admin/manufactures/removeBrandInProduct`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [AiLoader, setAiloader] = useState(false);
  const generateDesc = async () => {
    setAiloader(true);
    try {
      const payload = encryptData({
        name: mData?.manufacturerName,
      })
      let ApiCall = await API.post('/admin/ai/description_generator', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setDescription(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };

  const HandleInput = (e) => {
    setIsChange(true);
    setmData({ ...mData, [e.target.name]: e.target.value });
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    handleAddProduct();
    try {
      let updateBody = {
        brand_name: mData?.manufacturerName,
        brand_logo: image,
        meta_title: mData?.meta_title,
        meta_description: mData?.meta_description,
        description: description,
        custom_fields: meta_fields,
      };
      if (slugEdited && slugName?.length > 0) {
        updateBody = {
          ...updateBody,
          brand_slug_name: slugName,
        };
      }
      const payload = encryptData(updateBody)
      await API.put(`/admin/manufactures/updateManufacturer/${mData?.manufactureId}`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/manufacturers',
        title: 'edit manufacture',
      });
      navigate('/manufacturers');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [desErr, setDesErr] = useState(false);
  const [isChange, setIsChange] = useState(false);
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/manufacturers',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit manufacture',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/manufacturers',
        title: 'edit manufacture',
      });
    } else {
      navigate('/manufacturers');
    }
  };
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setDesErr(true);
    } else {
      setDesErr(false);
      try {
        setAiloader(true);
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/manufacture_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setmData({ ...mData, meta_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/manufacture_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setmData({ ...mData, meta_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        setAiloader(false);
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
    setIsChange(true);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/manufactures/GetProductsForBrand?brand=${mData?.manufactureId}&brand_exist=2`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[130px]'>
          <div className='flex items-center pb-[30px] space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_Manufactures'), path: '/manufacturers' },
                  {
                    name: t('lang_Update_Manufacturer'),
                    path: '/manufacturers/update-manufacture',
                  },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Update_Manufacturer')} - {brandData?.brand_name}
              </h4>
            </div>
          </div>
          <div className='flex justify-evenly'>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
            >
              {formSpinner ? (
                <div className='space-y-4'>
                  <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <div className='flex items-center space-x-4 w-full'>
                      <div className='w-full'>
                        <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                        <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                      </div>
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <Skeleton className='w-full min-h-[100px]' variant='rounded-[6px]' />
                  </div>
                </div>
              ) : (
                <>
                  <div className='secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
                    <div className='mb-4'>
                      <label
                        htmlFor='manufacturerName'
                        className='flex items-center justify-between'
                      >
                        <p className='text-[13px]'>
                          {t('lang_manufacture_name')}
                          <span className='text-red-500 ms-1'>*</span>
                        </p>
                      </label>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='manufacturerName'
                        name='manufacturerName'
                        value={mData.manufacturerName}
                        onChange={HandleInput}
                        placeholder='e.g. Apple'
                        onBlur={() => setmData({ ...mData, meta_title: mData?.manufacturerName })}
                      />
                      <div className='w-full flex justify-between'>
                        <p className='text-xs mt-0 text-gray-400'>
                          {t('lang_recommend_character')} {`${mData.manufacturerName?.length} ${t('lang_characters')}`}
                        </p>
                      </div>
                      {warn && (
                        <p className='text-sm text-red-500'>
                          * {t('lang_Manufacturer_Name_Generate_Ai_Response')}
                        </p>
                      )}
                    </div>
                    <div className='mb-4'>
                      <label htmlFor='category_name'>{t('lang_Manufacturer_Slug_Name')}<span className='text-red-500 ms-1'>*</span></label>
                      <div
                        className={` relative flex items-center justify-between w-full border ${err ? 'border-red-500' : 'main-border-color'
                          } rounded-[4px]`}
                      >
                        <input
                          className='w-full focus:outline-none'
                          type='text'
                          name='manufacture_slug_name'
                          value={slugName}
                          disabled={!enableSlug}
                          onChange={(e) => {
                            setSlugName(e.target.value);
                            setIsChange(true);
                          }}
                        />
                        <Tooltip
                          title={`${enableSlug ? t('lang_save') : t('lang_edit')}`}
                          arrow
                          placement='bottom'
                          className='ml-2 -tracking-tighter capitalize'
                        >
                          <button type='button' className='slug-edit w-8 h-8'>
                            {enableSlug ? (
                              <Check
                                onClick={async () => {
                                  if (slugName?.length > 0) {
                                    setSlugName(await GenerateSlugName(slugName));
                                    setEnableSlug(!enableSlug);
                                    setErr(false);
                                    setSlugEdited(true);
                                  } else {
                                    setIsNotification(true);
                                    setNotificationMsg('Slug can not be blank');
                                    setSeverity('warning');
                                  }
                                }}
                                className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                              />
                            ) : (
                              <Edit
                                onClick={() => setEnableSlug(!enableSlug)}
                                className='text-black-500 p-1 h-7 w-7 cursor-pointer'
                              />
                            )}
                          </button>
                        </Tooltip>
                      </div>
                      {err && (
                        <p className='text-xsm text-red-500'>{t('lang_Please_Add_Slug_Name')}</p>
                      )}
                      <p className='w-full text-xs mt-0 text-gray-400'>{`Preview: ${clientInfo?.custom_domain
                        ? clientInfo?.custom_domain
                        : clientInfo?.customer_domain
                        }/manufacturers/${slugName}`}</p>
                    </div>
                    <div className='w-full mb-4'>
                      <label htmlFor='image'>
                        <p className='text-[13px]'>
                          {t('lang_manufacture_image')}
                        </p>
                      </label>
                      <ImageUploader
                        alreadyImage={image}
                        handleSetImage={handleSetImage}
                        folder='manufacture'
                        format='image'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex space-x-3 justify-between items-center mb-1'>
                        <label htmlFor='Description'>{t('lang_description')}</label>
                        <div className='flex items-center'>
                          <span
                            className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                            onClick={() => {
                              manufacturerName === ''
                                ? setwarn(true)
                                : (generateDesc(), setwarn(false));
                            }}
                          >
                            <BardSvg /> {t('lang_get_description')}
                          </span>
                          <span
                            className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                            onClick={handleViewHtml}
                          >
                            <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                          </span>
                        </div>
                      </div>
                      <ReactQuill
                        modules={module}
                        ref={quillRef}
                        className={desErr ? 'border rounded-[6px] border-red-500' : 'w-full h-auto'}
                        theme='snow'
                        name='description'
                        value={
                          description?.length && description?.split(' ').slice(0, 280).join(' ')
                        }
                        onChange={handleQuillChange}
                        onBlur={() => {
                          setmData({
                            ...mData,
                            meta_description:
                              description
                                ?.replace(/<[^>]*>/g, ' ')
                                ?.split(' ')
                                .slice(0, 50)
                                .join(' ') || '',
                          });
                        }}
                        placeholder='Enter description'
                      />
                      <div className='flex items-center justify-between'>
                        {desErr ? (
                          <p className='text-red-500 text-xs '>
                            {t('lang_Please_Enter_Manufacture_Description')}
                          </p>
                        ) : (
                          ''
                        )}
                        <div className='w-full flex justify-between'>
                          <p className='text-xs mt-0 text-gray-400'>
                            {t('lang_Only_Words_allowed', { words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280` })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <details className='secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
                    <summary className='text-base flex items-center justify-between font-medium '>
                      <div>
                        {t('lang_products')}
                        <p className='text-xs text-gray-500 font-normal'>
                          {t('lang_Select_Product_For_Add_In_This_Manufacture')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className='w-4 h-5' />
                      </span>
                    </summary>
                    <hr className='mt-4 main-border-color' />
                    <div className='w-full text-center'>
                      {selectedProduct?.length > 0 ? (
                        <div className='max-h-[450px] overflow-y-auto divide-y'>
                          {selectedProduct?.map((elem) => {
                            return (
                              <div className='flex items-center main-border-color py-2 space-x-3'>
                                <button
                                  onClick={(e) => removeSelectProduct(e, elem?.product)}
                                  className='close-btn'
                                >
                                  <X className='w-[14px] h-[14px] text-red-600' />
                                </button>
                                <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                  <img
                                    onError={(e) => {
                                      e.target.src = NOPHOTO_IMAGE;
                                    }}
                                    src={elem?.image || NOPHOTO_IMAGE}
                                    className='max-w-full max-h-full'
                                    alt={elem?.name}
                                  />
                                </div>
                                <p className='text-sm table-text'>{elem?.name}</p>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <button
                          className='bulk-btn mt-4'
                          onClick={(e) => {
                            e.preventDefault();
                            setIsSelectProduct(true);
                          }}
                        >
                          {t('lang_Select_Product')}
                        </button>
                      )}
                      {selectedProduct?.length > 0 && (
                        <div className='flex items-center border-t main-border-color justify-between pt-2'>
                          <button
                            className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSelectProduct(true);
                            }}
                          >
                            + {t('lang_select_more_product')}
                          </button>
                          <button
                            className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedRemove(selectedProduct?.map((x) => x?.product));
                              setSelectedProduct([]);
                            }}
                          >
                            <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                            {t('lang_remove_all_product')}
                          </button>
                        </div>
                      )}
                    </div>
                  </details>
                  <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full flex flex-col items-center font-medium '
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span>{t('lang_search_engine_listing')}</span>
                          <p className='text-xs text-gray-500 font-normal'>
                            {t('lang_search_engine_listing_detail')}
                          </p>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                      {mData?.meta_title?.length > 0 && (
                        <div className='text-left font-normal mt-2 w-full'>
                          <p className='text-blue-700 text-base'>{mData?.meta_title}</p>
                          <p className='flex text-xs text-green-700'>
                            {`${clientInfo?.custom_domain
                              ? clientInfo?.custom_domain
                              : clientInfo?.customer_domain
                              }/manufacturers/${slugName}`}{' '}
                          </p>
                          <p
                            className='table-text text-sm'
                            dangerouslySetInnerHTML={{ __html: mData?.meta_description }}
                          ></p>
                        </div>
                      )}
                    </summary>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label htmlFor='meta_title' className='flex items-center justify-between'>
                          {t('lang_meta_title')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'title');
                          }}
                        >
                          <BardSvg />
                          {t('lang_get_meta_title')}
                        </span>
                      </div>
                      <input
                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='meta_title'
                        name='meta_title'
                        value={mData.meta_title}
                        onChange={HandleInput}
                        placeholder='Enter meta title'
                      />
                    </div>
                    <div className='mb-4'>
                      <div className='flex items-center justify-between'>
                        <label
                          htmlFor='meta_description'
                          className='flex items-center justify-between'
                        >
                          {t('lang_meta_description')}
                        </label>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            generateMetaData(description, 'description');
                          }}
                        >
                          <BardSvg />
                          {t('lang_get_meta_description')}
                        </span>
                      </div>
                      <textarea
                        className='w-full h-24 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                        type='text'
                        id='meta_description'
                        name='meta_description'
                        value={mData.meta_description}
                        onChange={HandleInput}
                        placeholder='Enter meta description'
                      />
                    </div>
                  </details>
                  {isCustomField && <UpdateCustomMetaField
                    meta_fields={meta_fields}
                    setmeta_fields={setmeta_fields}
                    heading='manufacture'
                  />}
                </>
              )}
            </form>
          </div>
          <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
            <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_Manufacturer')}
              </Link>
              <div>
                {mData?.manufacturerName === '' || !mData?.manufacturerName ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Update_Manufacturer')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[177px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_Saving')}</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Update_Manufacturer')}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default EditManufacturer;
