import React from 'react';
import { formatDate } from '../../controllers/FormatAmount';
import { useNavigate } from 'react-router-dom';

const NotificationGroup = ({ title, notifications, handlePerticularRead, closeNotification }) => {
  const navigate = useNavigate();
  return (
    notifications &&
    notifications?.length > 0 && (
      <div>
        <span className='mb-1 text-primary text-[12px] font-medium block'>{title}</span>
        <ul className='divide-y'>
          {notifications.map((elem, index) => {
            return (
              <li
                key={index}
                className={`py-3 relative main-border-color flex w-full space-x-2 cursor-pointer ${
                  elem?.read === false ? 'px-4 primary-bg-color' : 'secondary-bg-color'
                }`}
                onClick={() => {
                  navigate(`/${elem?.type?.replace('_', '-')}/${elem?.type_id}`);
                  elem?.read === false ? handlePerticularRead(elem?._id) : closeNotification(false);
                }}
              >
                {elem?.read === false ? (
                  <div className='bg-[#28282B] absolute top-[50%] -translate-y-[50%] left-2.5 w-1.5 h-1.5 rounded-full'></div>
                ) : (
                  <></>
                )}
                <div className='w-full table-text'>
                  <div className='block md:flex w-full items-center justify-between'>
                    <p className='text-[13px] sm:text-sm font-medium'>{elem?.title}</p>
                    <span className='text-xs inline-block table-text'>
                      {formatDate(elem?.createdAt)}
                    </span>
                  </div>
                  <p className='m-0 text-[13px]'>{elem?.description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default NotificationGroup;
