import { Autocomplete, Dialog, TextField } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';

const RegisterMetaFieldForm = ({ openMetaFieldForm, handleClose, heading, editData }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [fieldData, setFieldData] = useState({
    field_name: editData?.custom_field_name ?? '',
    field_type: editData?.custom_field_type ?? 'file',
  });
  const handleCloseNotification = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const buttonDisabled = fieldData.field_name === '' || fieldData.field_type === '';
  const handleInput = (e) => {
    setFieldData({ ...fieldData, [e.target.name]: e.target.value });
  };
  const options = ['text', 'file', 'number', 'date'];
  const HandleSubmit = async (e) => {
    e.preventDefault();
    if (heading === 'Registration') {
      try {
        const payload = encryptData({
          custom_fields: [
            {
              custom_field_name: fieldData?.field_name,
              custom_field_type: fieldData?.field_type,
            },
          ],
        })
        const ApiCall = await API.post('/admin/client/AddCustomField', payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Custom Field Added Successfully');
          setSeverity('success');
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        handleClose();
      }
    } else {
      try {
        const payload = encryptData({
          custom_fields: [
            {
              custom_field_name: fieldData?.field_name,
              custom_field_type: fieldData?.field_type,
            },
          ],
        })
        const ApiCall = await API.post('/admin/client/AddFieldCheckOut', payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Custom Field Added Successfully');
          setSeverity('success');
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        handleClose();
      }
    }
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    if (heading === 'Registration') {
      try {
        const payload = encryptData({
          custom_field_name: fieldData?.field_name,
          custom_field_type: fieldData?.field_type,
        })
        const ApiCall = await API.put(`/admin/client/UpdateCustomField/${editData?._id}`, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Custom Field Updated Successfully');
          setSeverity('success');
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        handleClose();
      }
    } else {
      try {
        const payload = encryptData({
          custom_field_name: fieldData?.field_name,
          custom_field_type: fieldData?.field_type,
        })
        const ApiCall = await API.put(`/admin/client/UpdateFieldCheckOut/${editData?._id}`, payload);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Custom Field Updated Successfully');
          setSeverity('success');
          setFieldData({
            field_name: '',
            field_type: 'file',
          });
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      } finally {
        handleClose();
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification
          message={notificationMsg}
          close={handleCloseNotification}
          severity={severity}
        />
      )}
      <Dialog
        open={openMetaFieldForm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description customer-popup'
      >
        <div className='secondary-bg-color w-full lg:w-[700px] border main-border-color  space-y-4 rounded-[6px] p-5'>
          <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
            <h6 className='font-medium text-xl'>
              {Object.keys(editData).length > 0 ? 'Edit' : 'Add'} {heading} {t('lang_customField')}
            </h6>
            <button type='button' className='close-btn' onClick={handleClose}>
              <X className='w-4 h-4' />
            </button>
          </div>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='get'
            className='w-full'
          >
            <div className='mb-4'>
              <label htmlFor='field_name'>
                {t('lang_Field_Name')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className={`w-full px-3 py-2 border main-border-color rounded-[6px]  focus:outline-none`}
                type='text'
                id='field_name'
                name='field_name'
                value={fieldData.field_name}
                onChange={(e) => handleInput(e)}
                placeholder='e.g. Tech'
                required
              />
            </div>
            <div className='mb-4'>
              <Autocomplete
                id='controllable-states-demo'
                className='w-full'
                fullWidth
                value={fieldData.field_type}
                options={options}
                onChange={(event, newValue) => {
                  setFieldData({ ...fieldData, field_type: newValue });
                }}
                renderInput={(params) => <TextField {...params} label='Field Type' />}
              />
            </div>
            <button
              className={`w-full block text-white text-sm font-medium py-2 px-3 rounded-[6px] transition duration-300 ${
                buttonDisabled ? 'bg-btn opacity-50 cursor-not-allowed' : 'btn'
              }`}
              type='button'
              disabled={buttonDisabled}
              onClick={Object.keys(editData).length > 0 ? handleEdit : HandleSubmit}
            >
              {Object.keys(editData).length > 0 ? 'Edit' : 'Add'} {t('lang_customField')}
            </button>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default RegisterMetaFieldForm;
