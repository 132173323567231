import { Dialog, Tooltip } from '@mui/material';
import {
    Check,
    ChevronDown,
    Eye,
    X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const ViewOrderOfCustomer = ({ name, user_id, closeView }) => {
    const navigate = useNavigate();
    const [orderDetails, setOrderDetails] = useState([]);
    const initialLimit = localStorage.getItem('limit');
    const token = localStorage.getItem('token');
    const [limit, setLimit] = useState(initialLimit);
    const [page, setPage] = useState(getInitialPage());
    const [sortBy, setSortBy] = useState('');
    const [iconVisible, setIconVisible] = useState(false);
    const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
    const [isNotification, setIsNotification] = useState(false)
    const [notificationMsg, setNotificationMsg] = useState('')
    const [severity, setSeverity] = useState()
    var startRecord = (orderDetails?.data?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(
        orderDetails?.data?.currentPage * limit,
        orderDetails?.data?.totalRecords,
    );
    const { dataLoading, setDataLoading } = useContext(DataLoad);

    // Sorting Data
    const SortData = (sort_by) => {
        if (!orderBy.isDecending) {
            setOrderBy({ orderByValue: 'DESC', isDecending: true });
        } else {
            setOrderBy({ orderByValue: 'ASC', isDecending: false });
        }
        GetOrders();
    };

    // sort by status
    const GetOrders = async () => {
        setDataLoading(true)
        try {
            let ApiCall = await API.get(
                `/admin/order/GetOrdersTable?user_id=${user_id}&page=${page}&limit=all`,
            );
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const result = decryptData(ApiCall?.data)
                setOrderDetails(result);
                if (Number(startRecord) > Number(result?.data?.totalRecords)) {
                    setPage(page - 1)
                }
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        } finally {
            setDataLoading(false)
        }
    };
    const [showProduct, setShowProduct] = useState(false);
    const [selectedId, setselectedId] = useState();
    useEffect(() => {
        GetOrders();
    }, [page, limit]);
    const ViewOrderDetail = (elem) => {
        navigate(`/order/${elem._id}`);
    };
    // Accept Order
    const AcceptOrder = async (order_id) => {
        try {
            const payload = encryptData({
                status: 'in process',
            })
            let Apicall = await API.put(`/admin/order/updateOrder/${order_id}`, payload);
            if (Apicall.status === 200 || Apicall.status === 304) {
                GetOrders();
                setIsNotification(true)
                setNotificationMsg('Order accepted successfully!')
                setSeverity('success')
                getOrdersStatusWithCount();
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    };
    const handleClose = () => {
        setIsNotification(false)
        setNotificationMsg('')
        setSeverity('')
    }
    // Cancel Order
    const DeclineOrder = async (order_id, paymentMethod, price) => {
        try {
            const payload = encryptData({
                status: 'denied',
            })
            let ApiCall = await API.put(`admin/order/updateOrder/${order_id}`, payload);
            if (ApiCall.status === 200 || ApiCall.status == 304) {
                if (token) {
                    paymentMethod === 'Razorpay' && (await RefundOrder(order_id, price));
                }
                setIsNotification(true)
                setNotificationMsg('Order declined successfully!')
                setSeverity('success')
                getOrdersStatusWithCount();
                GetOrders();
            }
        } catch (error) {
            setIsNotification(true)
            setNotificationMsg(error)
            setSeverity("error")
        }
    };

    // Refund If Razorpay
    const RefundOrder = async (id, price) => {
        try {
            const payload = encryptData({ return: id, total_price: price })
            await API.post('/admin/payment/refund', payload);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        localStorage.setItem('limit', limit);
    }, [limit]);

    useEffect(() => {
        replacePageInUrl(page)
    }, [page])
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto w-full sm:w-[600px] secondary-bg-color overflow-x-hidden p-5'>
                <div className='w-full flex items-center justify-between'>
                    <h6 className='text-xl md:text-2xl  font-medium  text-primary'>{name} - {t("lang_orders")} </h6>
                    <button onClick={(e) => {
                        e.preventDefault();
                        closeView(false)
                    }} className="close-btn">
                        <X className="w-4 h-4" />
                    </button>
                </div>
                <hr className='border-t main-border-color my-4' />
                <div className='w-full h-auto block'>
                    {
                        dataLoading && !orderDetails ?
                            <TableSkeleton forTable={"order"} /> :
                            orderDetails?.data?.records?.length > 0 ?
                                <div className='border w-full transition-all duration-100 mb-4 main-border-color rounded-[6px] overflow-y-hidden'>
                                    <table className='w-full'>
                                        <thead>
                                            <tr>
                                                <td className='min-w-[50px] max-w-[50px] px-3'>
                                                    <div className='flex items-center'>
                                                        <button
                                                            className={`uppercase flex ${iconVisible && sortBy === 'order_id' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('order_id') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('order_id')}
                                                        >
                                                            {t("lang_order")}
                                                            {iconVisible && sortBy === 'order_id' ? (
                                                                !orderBy.isDecending ? (
                                                                    <CaretUpFill className='ms-2' />
                                                                ) : (
                                                                    <CaretDownFill className='ms-2' />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className='min-w-[150px] text-right max-w-[150px] px-3'>
                                                    <div className='flex w-full items-center justify-end'>
                                                        <button className={`uppercase flex ${iconVisible && sortBy === 'createdAt' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('createdAt') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('createdAt')}>
                                                            {iconVisible && sortBy === 'createdAt' ? (
                                                                !orderBy.isDecending ? (
                                                                    <CaretUpFill className='me-2' />
                                                                ) : (
                                                                    <CaretDownFill className='me-2' />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {t("lang_date")}
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className='min-w-[150px] max-w-[150px] px-3'>
                                                    <div className='flex items-center w-full justify-end pe-3'>
                                                        <button className={`uppercase flex ${iconVisible && sortBy === 'total_price' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('total_price') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('total_price')}>
                                                            {iconVisible && sortBy === 'total_price' ? (
                                                                !orderBy.isDecending ? (
                                                                    <CaretUpFill className='me-2' />
                                                                ) : (
                                                                    <CaretDownFill className='me-2' />
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                            {t("lang_price")}
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className='min-w-[150px] max-w-[150px] text-right px-3'>{t("lang_items")}</td>
                                                <td className='min-w-[200px] text-right max-w-[200px] px-3'>{t("lang_status")}</td>
                                                <td className='min-w-[150px] max-w-[150px] text-right px-3'>{t("lang_action")}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                orderDetails?.data?.records?.map((row, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className='min-w-[50px] max-w-[50px] px-3'>#{row.order_id || '-'}</td>
                                                            <td className='min-w-[150px] text-right max-w-[150px] px-3'>{formatDate(row?.createdAt)}</td>
                                                            <td className='min-w-[150px] max-w-[150px] px-3'>
                                                                <span className='w-full text-right block pe-3'>
                                                                    {FormatAmount(row?.total_price)}
                                                                </span>
                                                            </td>
                                                            <td className='min-w-[150px] max-w-[150px] px-3'>
                                                                <div
                                                                    className='flex items-center justify-end w-full cursor-pointer select-none'
                                                                >
                                                                    <button aria-describedby={row?._id} variant="contained" className='flex items-center' onClick={() => { setShowProduct(!showProduct); setselectedId(row._id) }}>
                                                                        {`${row?.products?.length} items`} <ChevronDown className='w-3 h-3 ms-1 mt-1' />
                                                                    </button>
                                                                    {
                                                                        row?._id === selectedId ?
                                                                            <Dialog
                                                                                open={showProduct}
                                                                                TransitionComponent={Transition}
                                                                                keepMounted
                                                                                onClose={() => setShowProduct(false)}
                                                                                aria-describedby="alert-dialog-slide-description"
                                                                            >
                                                                                <div className=' px-5 pb-5 pt-2.5 w-[400px]'>
                                                                                    <div className='flex items-center font-medium justify-between'>
                                                                                        <h6 className='capitalize'>{`${row.user?.name || row?.guest_user?.name || ''} - `}{t("lang_orders")}</h6>
                                                                                        <button className='close-btn' onClick={(e) => { e.preventDefault(); setShowProduct(false) }}>
                                                                                            <X className="w-4 h-4 cursor-pointer" />
                                                                                        </button>
                                                                                    </div>
                                                                                    <hr className='my-3' />
                                                                                    <div className='divide-y space-y-2 max-h-[550px] overflow-auto'>
                                                                                        {row?.products?.map((elem) => {
                                                                                            return (
                                                                                                <div className='flex w-full pt-2 max-h-20'>
                                                                                                    <div className='min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] overflow-hidden flex items-center justify-center border main-border-color'>
                                                                                                        <img
                                                                                                            onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={elem?.image || NOPHOTO_IMAGE}
                                                                                                            className='max-w-full mx-auto block max-h-full object-contain'
                                                                                                            alt={elem?.name}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className='flex items-start ps-2 w-full justify-between'>
                                                                                                        <div className=''>
                                                                                                            <p className='text-sm line-clamp-2'>{elem?.name}</p>
                                                                                                            <p className='text-xs mt-0.5 text-gray-500'>{`Total: ${FormatAmount(elem?.product_total)}`}</p>
                                                                                                        </div>
                                                                                                        <p className='flex px-2 items-center justify-end text-xs min-w-[55px]'>
                                                                                                            {FormatAmount(elem?.selling_price)}
                                                                                                            <X className='w-3 h-3 mx-0.5' />
                                                                                                            {elem?.quantity}
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </Dialog> : <></>
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className='min-w-[200px] text-right max-w-[200px] px-3'>
                                                            <div className='w-full text-right text-xs whitespace-nowrap'>
          {row.status === 'payment failed' ||
            row.status === 'denied' ||
            row.status === 'return declined' ? (
            <span className='status-bedge text-red-500 bg-red-100'>
              {row.status}
            </span>
          ) : row.status === 'canceled' ? (
            <>
              <span className='status-bedge text-gray-500 bg-gray-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'refunded' ||
            row.status === 'order placed' ? (
            <>
              <span className='status-bedge text-sky-500 bg-sky-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'delivered' ? (
            <>
              <span className='status-bedge text-green-500 bg-green-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'in process' ||
            row.status === 'in transit' ? (
            <>
              <span className='status-bedge text-purple-500 bg-purple-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'ready to ship' ||
            row.status === 'reached at destination hub' ||
            row.status === 'out for pickup' ||
            row.status === 'out for delivery' ? (
            <>
              <span className='status-bedge text-black-500 bg-blue-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'shipped' ? (
            <>
              <span className='status-bedge text-orange-500 bg-orange-100'>
                {row.status}
              </span>
            </>
          ) : (
            <>
              <span className='status-bedge text-yellow-500 bg-yellow-100'>
                {row.status}
              </span>
            </>
          )}
        </div>
                                                            </td>
                                                            <td className='min-w-[150px] max-w-[150px] flex items-center px-3 justify-end w-full space-x-3 text-sm'>
                                                                {row.status === 'canceled' || row.status === 'denied' || row.status === 'delivered' ? (
                                                                    <div>
                                                                        <Tooltip title={t("lang_view")} arrow>
                                                                            <button className=''>
                                                                                <Eye
                                                                                    className='cursor-pointer w-[17px] h-[17px]'
                                                                                    onClick={() => {
                                                                                        ViewOrderDetail(row);
                                                                                    }}
                                                                                />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {row.status === 'in process' ? (
                                                                            <>
                                                                                <div className='flex space-x-3'>
                                                                                    <div>
                                                                                        <Tooltip title={t("lang_view")} arrow>
                                                                                            <button className=''>
                                                                                                <Eye
                                                                                                    className='cursor-pointer w-[17px] h-[17px]'
                                                                                                    onClick={() => {
                                                                                                        ViewOrderDetail(row);
                                                                                                    }}
                                                                                                />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Tooltip title={t("lang_decline")} arrow>
                                                                                            <button className=''>
                                                                                                <X
                                                                                                    className='cursor-pointer w-[17px] h-[17px] text-red-500'
                                                                                                    onClick={() => {
                                                                                                        DeclineOrder(row._id, row?.payment_method_name, row?.total_price);
                                                                                                    }}
                                                                                                />
                                                                                            </button>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ) : row.status === 'order placed' ? (
                                                                            <div className='flex space-x-3'>
                                                                                <div>
                                                                                    <Tooltip title={t("lang_view")} arrow>
                                                                                        <button className=''>
                                                                                            <Eye
                                                                                                className='cursor-pointer w-[17px] h-[17px]'
                                                                                                onClick={() => {
                                                                                                    ViewOrderDetail(row);
                                                                                                }}
                                                                                            />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <div>
                                                                                    <Tooltip title={t("lang_accept")} arrow>
                                                                                        <button className=''>
                                                                                            <Check
                                                                                                className='cursor-pointer text-green-500 w-[17px] h-[17px]'
                                                                                                onClick={(e) => { e.preventDefault(); AcceptOrder(row?._id) }}
                                                                                            />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                                <div>
                                                                                    <Tooltip title={t("lang_decline")} arrow>
                                                                                        <button className=''>
                                                                                            <X
                                                                                                className='cursor-pointer w-[17px] h-[17px] text-red-500'
                                                                                                onClick={() => {
                                                                                                    DeclineOrder(row._id, row?.payment_method_name, row?.total_price);
                                                                                                }}
                                                                                            />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <Tooltip title={t("lang_view")} arrow>
                                                                                    <button className=''>
                                                                                        <Eye
                                                                                            className='cursor-pointer w-[17px] h-[17px]'
                                                                                            onClick={() => {
                                                                                                ViewOrderDetail(row);
                                                                                            }}
                                                                                        />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                </div> : <NoRecords />

                    }
                </div>
            </div>
        </>
    )
}

export default ViewOrderOfCustomer