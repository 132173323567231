import { Dialog, Popover } from '@mui/material';
import React, { useEffect, useRef } from 'react'
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { t } from 'i18next';
const VideoLinkUpload = ({ isAddVideoLink, closeAddVideoLink, handleSetLink }) => {
    const [videoLink, setVideoLink] = useState('')
    const [err, setErr] = useState(false)
    const [openYoutubeInfo, setOpenYoutubeInfo] = useState(false);
    const videoLinkRef = useRef(null)
    function getYouTubeVideoId(url) {
        // Match the video ID using a regular expression
        const match = videoLink.match(/youtu\.be\/([^?]+)/);
        // If there is a match, return the video ID, otherwise return null
        return match ? match[1] : null;
    }
    useEffect(() => {
        if(isAddVideoLink) {
            videoLinkRef.current.focus()
        }
    },[isAddVideoLink])
    const addLink = () => {
        let videoId = getYouTubeVideoId();
        if (videoId) {
            setErr(false)
            handleSetLink(videoId);
            setVideoLink('')
            closeAddVideoLink(false);
        } else {
            if (videoLink?.length > 0) {
                setErr(true)
            }
        }
    }
    const [anchorEl, setAnchorEl] = useState()
    return (
        <Dialog
            open={isAddVideoLink}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { closeAddVideoLink(false) }}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full md:w-[35rem]  p-5 rounded-[6px] secondary-bg-color table-text shadow dark:bg-gray-700'>
                <div>
                    <label className='flex items-center justify-between text-gray-700 text-md font-medium mb-2' htmlFor='product_name'>
                        <div className='flex items-center  text-primary'>
                        {t('lang_add_video_link')}
                            <button className='ms-2 cursor-pointer' aria-describedby="youtubeinfo" variant="contained" onMouseEnter={(e) => { e.preventDefault(); setAnchorEl(e.currentTarget); setOpenYoutubeInfo(true) }}>
                                <QuestionCircleFill className='info-link' />
                            </button>
                        </div>
                        <button onClick={(e) => {
                            e.preventDefault();
                            closeAddVideoLink(false)
                        }} className="close-btn">
                            <X className="w-4 h-4" />
                        </button>
                    </label>
                    <hr className='my-4 main-border-color' />
                    <form>
                        <label>{t('lang_youtube_URL')}</label>
                        <input className='w-full px-3 py-2.5 border main-border-color rounded-[6px] focus:outline-none' ref={videoLinkRef} type='text' value={videoLink} onChange={(e) => setVideoLink(e.target.value)} placeholder='Paste your link here...' />
                        {err && <p className='text-red-500 text-sm'>{t('lang_invalid_youtube_url')}</p>}
                        {
                            videoLink?.length > 0 ?
                                <button onClick={(e) => { e.preventDefault(); addLink(); }} className='btn text-sm text-white mt-4'>
                                    add embedded video
                                </button> :
                                <button disabled onClick={(e) => { e.preventDefault() }} className='bg-btn opacity-40 px-5 py-2 rounded-[6px] transition-all duration-150 cursor-not-allowed text-sm text-white mt-4'>
                                    add embedded video
                                </button>
                        }
                    </form>
                    <Popover
                        id={"youtubeinfo"}
                        className='youtubeInfo'
                        open={openYoutubeInfo}
                        anchorEl={anchorEl}
                        onClose={() => setOpenYoutubeInfo(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className='p-4 text-sm'>
                            <p><b>1.</b> {t('lang_Open_the_youTube_video_you_want_to_share')}</p>
                            <br></br>
                            <p><b>2.</b> {t('lang_Open_the_youTube_video_you_want_to_share_details')}</p>
                            <br></br>
                            <p><b>3.</b> {t('lang_click_on_the_button')}</p>
                            <br></br>
                            <p><b>4.</b> {t('lang_click_on_the_button_copy')}</p>
                        </div>
                    </Popover>
                </div>
            </div>
        </Dialog>
    )
}

export default VideoLinkUpload