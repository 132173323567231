import { Dialog } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { NOUSER_IMAGE } from '../../Assets/StaticData/StaticImage';
import { formatDate } from '../../controllers/FormatAmount';
import { Transition } from '../../controllers/Transition';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
import ViewOrderOfCustomer from './ViewOrderOfCustomer';
const CustomerProfile = () => {
  const [customerInfo, setCustomerInfo] = useState([]);
  const params = useParams()
  const [nodata, setNodata] = useState(false)
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const HandleCustomerInfo = async () => {
    try {
      const ApiCall = await API.get(`/admin/client/getParticularCustomer/${params.cust_id}`);
      const result = decryptData(ApiCall?.data)
      setCustomerInfo(result?.data);
    } catch (error) {
      setNodata(true)
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  useEffect(() => {
    HandleCustomerInfo();
  }, [params?.cust_id]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [isViewOrder, setIsViewOrder] = useState(false);
  const closeView = (value) => {
    setIsViewOrder(value)
  }
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {
        nodata ? <NoDataFound /> :
          <div className='h-auto overflow-y-auto pb-[64px]'>
            <div className='flex items-center space-x-2 pb-[30px]'>
              <button onClick={(e) => { e.preventDefault(); navigate('/customers') }} className='back-arrow'><ArrowLeft /></button>
              <div>
                <Breadcrumbs crumbs={[
                  { name: t("lang_dashboard"), path: `/dashboard` },
                  { name: t("lang_customers"), path: '/customers' },
                  { name: t("lang_customer_deatil"), path: '/store-setting/customer-details' },
                ]} />
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t("lang_customer_deatil")}</h4>
              </div>
            </div>

            <div className='w-[60%] mx-auto rounded-[6px]'>
              <div className='table-text  flex flex-wrap md:flex-nowrap items-start justify-start'>
                <div className='min-w-[300px] secondary-bg-color me-2 flex items-center justify-center p-2'>
                  <img
                    className='relative max-h-full w-full block'
                    onError={(e) => { e.target.src = NOUSER_IMAGE }} src={customerInfo?.user?.profile_picture || NOUSER_IMAGE}
                    alt='profile picture'
                  />
                </div>
                <div className='grid p-5 secondary-bg-color w-full text-sm'>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_firstname")} : </div>
                    <div className='py-2 ms-1'> {customerInfo?.user?.first_name && customerInfo.user.first_name}</div>
                  </div>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_lastname")} : </div>
                    <div className='py-2 ms-1'>{customerInfo?.user?.last_name && customerInfo.user.last_name}</div>
                  </div>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_role")} : </div>
                    <div className='py-2 ms-1'>{customerInfo?.user?.role && customerInfo.user.role}</div>
                  </div>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_email")} : </div>
                    <div className='py-2 ms-1'>{customerInfo?.user?.email && customerInfo.user.email}</div>
                  </div>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_mobile_number")} : </div>
                    <div className='py-2 ms-1'>{customerInfo?.user?.country_code && customerInfo?.user?.country_code} {customerInfo?.user?.phone && customerInfo.user.phone}</div>
                  </div>
                  <div className='flex items-center mb-2'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_joined_date")} : </div>
                    <div className='py-2'>{customerInfo?.user?.createdAt && formatDate(customerInfo?.user?.createdAt)}</div>
                  </div>
                  <div className='flex items-center'>
                    <div className='py-2 font-semibold capitalize'>{t("lang_address")} : </div>
                    {
                      customerInfo?.addresses?.length ?
                        <div className='py-2 ms-1'>{customerInfo?.addresses[0].addressLine1 !== '' && customerInfo?.addresses[0]?.addressLine1 + ", "}{customerInfo?.addresses[0]?.addressLine2 !== '' && customerInfo?.addresses[0]?.addressLine2 + ", "}{customerInfo?.addresses[0]?.city !== '' && customerInfo?.addresses[0]?.city + ", "}{customerInfo?.addresses[0]?.state !== '' && customerInfo?.addresses[0]?.state + ", "}{customerInfo?.addresses[0]?.country !== '' && customerInfo?.addresses[0]?.country}</div>
                        : <span className='py-2'>-</span>
                    }
                  </div>
                  <div className='text-left mt-4'>
                    <button onClick={(e) => { e.preventDefault(); setIsViewOrder(true) }} className='outline-none text-link hover:underline font-medium'>{t("lang_view_orders")}</button>
                  </div>
                </div>
              </div>
            </div>
            <Dialog
              open={isViewOrder}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setIsViewOrder(false)}
              aria-describedby="alert-dialog-slide-description"
            >
              <ViewOrderOfCustomer name={customerInfo?.user?.name} user_id={params.cust_id} closeView={closeView} />
            </Dialog>
          </div>
      }
    </>
  );
};

export default CustomerProfile;
