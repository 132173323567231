import React, { useState } from 'react'
import Notification from '../Notification';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const DigitalProduct = ({ isDownload, setIsDownload, fileType, setFileType, previewFile, setPreviewFile, originalFile, setOriginalFile, download_btn_text, setDownload_btn_text, handlePreviewFile, handleOriginalFile, expireDate, setExpireDate }) => {
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <details
                open
                className='bg-white group table-text shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'
            >
                <summary className='text-base  text-primary flex items-center justify-between font-medium capitalize'>
                    {t('lang_digital_product')}
                    <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                    </span>
                </summary>
                <div className='mb-4 w-full'>
                    <label
                        for="digital product"
                        className='flex gap-2'
                    >
                        <input
                            type="checkbox"
                            name='isDownload'
                            checked={isDownload}
                            onChange={(e) => setIsDownload(e.target.checked)}
                        />
                        {t('lang_Downloadable')}
                    </label>
                </div>
                {isDownload && <div className='w-full'>
                    <label className='flex items-center' htmlFor='quantity'>
                        {t('lang_Button_Text_Name')}
                    </label>
                    <input
                        className="w-full px-3 py-2 border rounded-[6px] focus:outline-none"
                        type='text'
                        id='button_text'
                        name='button_text'
                        value={download_btn_text}
                        onChange={(e) => setDownload_btn_text(e.target.value)}
                        placeholder='e.g. Download'
                    />
                </div>}
                <div className='mb-4'>
                    <label>File Type</label>
                    <div className='relative'>
                        <select
                            className='w-full'
                            placeholder='Select file type'
                            value={fileType}
                            onChange={(e) => setFileType(e.target.value)}
                        >
                            <option value='url'>{t('lang_url')}</option>
                            <option value='file'>{t('lang_file')}</option>
                        </select>
                        <div className='select-arrow'></div>
                    </div>
                </div>
                {fileType === 'url' ? <>
                    <div className='mb-4'>
                        <label className='flex items-center' htmlFor='quantity'>
                            {t('lang_preview_file_URL')}
                        </label>
                        <input
                            className="w-full px-3 py-2 border rounded-[6px] focus:outline-none"
                            type='text'
                            id='button_text'
                            name='button_text'
                            value={previewFile}
                            onChange={(e) => setPreviewFile(e.target.value)}
                            placeholder='e.g. https://file-example.com'
                        />
                    </div>
                    <div className='mb-4'>
                        <label className='flex items-center' htmlFor='quantity'>
                            {t('lang_original_file_URL')}
                        </label>
                        <input
                            className="w-full px-3 py-2 border rounded-[6px] focus:outline-none"
                            type='text'
                            id='button_text'
                            name='button_text'
                            value={originalFile}
                            onChange={(e) => setOriginalFile(e.target.value)}
                            placeholder='e.g. https://file-example.com'
                        />
                    </div>
                </>
                    :
                    <>
                        <div className='mb-4'>
                            <label className='flex items-center' htmlFor='quantity'>
                                {t('lang_preview_upload_file')}
                            </label>
                            <input
                                id='digital-file'
                                type='file'
                                name='file'
                                onChange={(e) => handlePreviewFile(e.target.files[0])}
                            />
                        </div>
                        <div className='mb-4'>
                            <label className='flex items-center' htmlFor='quantity'>
                                {t('lang_original_upload_file')}
                            </label>
                            <input
                                id='digital-file'
                                type='file'
                                name='file'
                                onChange={(e) => handleOriginalFile(e.target.files[0])}
                            />
                        </div>
                    </>
                }
                <div className='mb-4'>
                    <label className='flex items-center' htmlFor='quantity'>
                        {t('lang_expired_date')}
                    </label>
                    <input
                        className="w-full px-3 py-2 border rounded-[6px] focus:outline-none"
                        type='text'
                        id='expired_date'
                        name='expired_date'
                        value={expireDate}
                        onChange={(e) => setExpireDate(e.target.value)}
                        placeholder='e.g. 30 Days'
                    />
                </div>
            </details>
        </>
    )
}

export default DigitalProduct