import React, { useContext, useEffect } from 'react';
import { Alert } from '@mui/material';
import Snackbar from '@mui/joy/Snackbar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFill, ExclamationCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { X } from 'feather-icons-react/build/IconComponents';
import { decryptData } from '../controllers/encryptionUtils';
import { StoreDeletePending } from '../App';
const Notification = ({ message, close, severity, handlestoreDeleteRequestPending }) => {
  const [open, setopen] = useState(true);
  const [msg, setMsg] = useState('');
  const handleClose = () => {
    close();
    setopen(false);
    setMsg('');
  };
  const ERROR_MESSAGE_STATUS_CODE = [
    401, // Unauthorized:  The request requires user authentication, and the client must provide valid credentials to access the requested resource.
    402, // Payment error
    403, // Forbidden: The server understands the request, but the client does not have the necessary permissions to access the requested resource.
    404, // Not Found: The server cannot find the requested resource or endpoint.
    408, // Request Timeout
    409, // Conflict: The request could not be completed due to a conflict with the current state of the target resource (e.g., duplicate data).
    422, // Unprocessable Entity: The server understands the request, but the provided data or entity is semantically incorrect or not processable.
    498, // Subscription plan expired
    882, //  It seems your plan doesn't have the necessary permissions
  ];
  const INTERNAL_SERVER_ERROR = [
    400, // Bad Request: The server cannot understand the request due to a client error, such as malformed syntax or invalid parameters.
    500, // Internal Server Error: The server encountered an unexpected condition that prevented it from fulfilling the request.
  ];
  const { storeDeleteRequestPending, setStoreDeleteRequestPending } = useContext(StoreDeletePending);

  const navigate = useNavigate();
  useEffect(() => {
    if (message?.response && ERROR_MESSAGE_STATUS_CODE?.includes(message?.response?.status)) {
      if (message?.response?.status === 401) {
        navigate('/');
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('business_name');
      }
      const result = decryptData(message?.response?.data)
      setMsg(result?.error ?? result?.message);
    } else if (message?.response && INTERNAL_SERVER_ERROR?.includes(message?.response?.status)) {
      setMsg('Something went wrong!');
    } else if (message?.response && message?.response?.status === 405) {
      setStoreDeleteRequestPending(true)
    } else {
      setMsg(message);
    }
  }, [message]);
  return (
    <Snackbar
      startDecorator={
        severity === 'error' ? (
          <XCircleFill className='w-6 h-5' />
        ) : severity === 'warning' ? (
          <ExclamationCircleFill className='w-6 h-5' />
        ) : (
          <CheckCircleFill className='w-6 h-5' />
        )
      }
      endDecorator={
        <button onClick={() => handleClose()} size='md' variant='soft' color={severity}>
          <X className='w-4 h-4' />
        </button>
      }
      size='md'
      variant='soft'
      open={open && msg?.length > 0}
      color={severity === 'error' ? 'danger' : severity}
      autoHideDuration={severity === 'success' ? 3000 : null}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      {msg}
    </Snackbar>
  );
};
export default Notification;
