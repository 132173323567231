import { X } from 'feather-icons-react/build/IconComponents';
import React, { useEffect, useState } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import API from '../../API';
import BlogCategoryChild from '../../controllers/BlogCategoryChild';
import CategoryChild from '../../controllers/CategoryChild';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import PortfolioCategoryChild from '../../controllers/PortfolioCategoryChild';
import Notification from '../Notification';
const AddHeaderGroup = ({ closeAddBlock, getHeaderData, isChangeHeader }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [groupTitle, setGroupTitle] = useState();
  const [groupLink, setGroupLink] = useState()
  const [linkColumn, setLinkColumn] = useState(1);
  const [dataErr, setDataErr] = useState(false);
  const [pageData, setPageData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [allBlogCategory, setAllBlogCategory] = useState();
  const [customDataErr, setCustomDataErr] = useState(false)
  const [portfolioData, setPortfolioData] = useState();
  const [storeCustomData, setStoreCustomData] = useState([])
  const [customData, setCustomData] = useState({
    section_name: '',
    section_link: '',
    section_type: 'custom',
    redirect_type: 'new',
  });
  const getPageData = async () => {
    try {
      const getPages = await API.get(`/admin/client/getPages`);
      const result = decryptData(getPages?.data)
      setPageData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getCategoryData = async () => {
    try {
      const getCategories = await API.get(`/admin/category/GetCategoriesWithChildCategories`);
      const result = decryptData(getCategories?.data)
      setCategoryData(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllBlogCategories = async () => {
    try {
      const getBlogCategories = await API.get(`/admin/blogcategory/GetBlogCategoriesWithChildCategories`);
      if (getBlogCategories.status === 200 || getBlogCategories.status === 304) {
        const result = decryptData(getBlogCategories?.data)
        setAllBlogCategory(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const GetAllPortfolioCategories = async () => {
    try {
      const getPortfolioCategories = await API.get(`/admin/PortfolioCategory/GetPortfolioCategoriesWithChildCategories`);
      if (getPortfolioCategories.status === 200 || getPortfolioCategories.status === 304) {
        const result = decryptData(getPortfolioCategories?.data)
        setPortfolioData(result?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleAddCustomData = (e) => {
    e.preventDefault();
    if (customData?.section_name === '' || customData?.section_link === '') {
      setCustomDataErr(true)
    } else {
      setCustomDataErr(false)
      setStoreList([...storeList, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }])
      setStoreCustomData([...storeCustomData, { section_name: customData?.section_name, section_link: customData?.section_link, section_type: 'custom', redirect_type: customData?.redirect_type }]); setCustomData({ section_name: '', section_link: '', redirect_type: "new" })
    }
  };
  useEffect(() => {
    getPageData();
    getCategoryData();
    GetAllBlogCategories();
    GetAllPortfolioCategories();
  }, []);
  const [storeList, setStoreList] = useState([]);
  const handleSelectProduct = (name, slugname, type, category_id) => {
    if (slugname) {
      const existList = storeList?.find((x) => x?.section_link === slugname);
      if (!existList) {
        setStoreList([...storeList, { section_name: name, section_type: type, section_link: slugname, category_id: category_id }]);
      } else {
        const removeFilter = storeList?.filter((x) => x?.section_link !== slugname);
        setStoreList(removeFilter);
      }
    }
  };
  const [btnLoading, setBtnLoading] = useState(false)
  const handleAddSection = async (e) => {
    e.preventDefault();
    setBtnLoading(true)
    try {
      const payload = encryptData({
        header_section_title: groupTitle,
        header_section_redirect_link: groupLink,
        column: linkColumn,
        header: storeList,
      })
      const apicall = await API.post(`/admin/header/addHeaderGroup`, payload);
      if (apicall?.status === 200) {
        setGroupTitle();
        setGroupLink()
        setLinkColumn(1)
        setStoreList([])
        setCustomData({
          section_name: '',
          section_link: '',
          section_type: 'custom',
          redirect_type: 'new',
        })
      }
      isChangeHeader();
      getHeaderData();
      closeAddBlock(false);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false)
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto secondary-bg-color p-5 w-full sm:w-[600px] overflow-y-auto pb-[80px]'>
        <div className='flex items-center justify-between space-x-2'>
          <div>
            <h6 className='text-xl font-semibold text-primary'>
              Add New Header Menu
            </h6>
          </div>
          <button onClick={(e) => { e.preventDefault(); closeAddBlock(false) }} className="close-btn" ><X className="w-4 h-4" /></button>
        </div>
        <hr className='border-t main-border-color my-4'></hr>
        <form onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()} className='w-full mx-auto'>
          <div className='secondary-bg-color w-full space-y-4'>
            <div className='mb-4'>
              <label htmlFor='image'>Header menu Title</label>
              <input
                type='text'
                className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm ${dataErr ? 'border-red-500' : ''
                  }`}
                placeholder='e.g. Categories'
                value={groupTitle}
                onChange={(e) => setGroupTitle(e.target.value)}
              />
              {dataErr && !groupTitle && (
                <p className='text-xs text-red-500'>Please enter menu name</p>
              )}
            </div>
            <div className='mb-4'>
              <label htmlFor='image'>
                section redirect URL
              </label>
              <input type='text' className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm`} placeholder='e.g. /shop' value={groupLink} onChange={(e) => setGroupLink(e.target.value)} />
            </div>
            <div className='mb-4'>
              <label htmlFor='image'>menu Link column</label>
              <div className='relative'>
                <select value={linkColumn} onChange={(e) => setLinkColumn(e.target.value)} className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm`}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded-[6px] p-[15px]'
              >
                <summary className='text-base  text-primary flex items-center justify-between '>
                  Content pages
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {pageData?.records?.map((elem) => {
                    return (
                      <label className='flex items-center space-x-4'>
                        <input
                          type='checkbox'
                          value={elem?._id}
                          onClick={(e) =>
                            handleSelectProduct(elem?.pages_name, `/page/${elem?.page_slug_name}`, "pages", elem?._id)
                          }
                          className='block w-4 h-4'
                        />
                        <p className='text-sm  font-normal break-all'>
                          {elem?.pages_name}
                        </p>
                      </label>
                    );
                  })}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded-[6px] p-[15px]'
              >
                <summary className='text-base  text-primary flex items-center justify-between '>
                  Portfolio Category
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {portfolioData?.map((category) => (
                    <PortfolioCategoryChild category={category} handleSelectProduct={handleSelectProduct} />
                  ))}
                </div>
              </details>
            </div>

            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded-[6px] p-[15px]'
              >
                <summary className='text-base  text-primary flex items-center justify-between '>
                  Categories
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {categoryData?.map((category) => (
                    <CategoryChild category={category} handleSelectProduct={handleSelectProduct} sectionFor="header" />
                  ))}
                </div>
              </details>
            </div>
            <div className='mb-4'>
              <details
                open
                className='secondary-bg-color group border main-border-color space-y-4 rounded-[6px] p-[15px]'
              >
                <summary className='text-base text-primary flex items-center justify-between '>
                  Blog Category
                  <span className='transition group-open:rotate-180'>
                    <ChevronDown className=' w-4 h-5' />
                  </span>
                </summary>
                <div className='w-full max-h-[300px] overflow-auto space-y-2 pt-4'>
                  {allBlogCategory?.map((category) => (
                    <BlogCategoryChild category={category} handleSelectProduct={handleSelectProduct} />
                  ))}
                </div>
              </details>
            </div>
            <details
              open
              className='secondary-bg-color group border main-border-color space-y-4 rounded-[6px] p-[15px]'
            >
              <summary className='text-base text-primary flex items-center justify-between '>
                Custom content
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <div className='w-full pt-4'>
                <div className='mb-4'>
                  <label htmlFor='image'>Title</label>
                  <input
                    type='text'
                    className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_name === "" ? 'border-red-500' : ''}`}
                    placeholder='e.g. Google'
                    value={customData?.section_name}
                    onChange={(e) => setCustomData({ ...customData, section_name: e.target.value })}
                  />
                  {
                    customDataErr && customData?.section_name === "" && <p className='text-xs text-red-500'>Please enter title</p>
                  }
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>URL</label>
                  <input
                    type='text'
                    className={`border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm ${customDataErr && customData?.section_link === "" ? 'border-red-500' : ''}`}
                    placeholder='e.g. https://google.com'
                    value={customData?.section_link}
                    onChange={(e) => setCustomData({ ...customData, section_link: e.target.value })}
                  />
                  {
                    customDataErr && customData?.section_link === "" && <p className='text-xs text-red-500'>Please enter url</p>
                  }
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>Redirect Type</label>
                  <select
                    name='gender'
                    id='id_gender'
                    className='w-full '
                    value={customData.redirect_type}
                    onChange={(e) =>
                      setCustomData({ ...customData, redirect_type: e.target.value })
                    }
                  >
                    <option value='new'>New</option>
                    <option value='current'>Current</option>
                  </select>
                </div>
                <button
                  onClick={(e) => handleAddCustomData(e)}
                  className='secondary-btn text-sm inline-block'
                >
                  Add custom data
                </button>
                {
                  storeCustomData?.map((elem) => {
                    return (
                      <div className='border main-border-color p-3 rounded-[6px] mt-4'>
                        <p className='text-sm'><span className='font-medium'>Title : </span><span>{elem?.section_name}</span></p>
                        <p className='text-sm'><span className='font-medium'>URL : </span><span>{elem?.section_link}</span></p>
                      </div>
                    )
                  })
                }
              </div>
            </details>
          </div>
        </form>
      </div>
      <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-40 secondary-bg-color border-t main-border-color p-3 left-0'>
        <div >
          {groupTitle === "" || !groupTitle ? (
            <button className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'>
              <span>Add Header Menu</span>
            </button>
          ) : btnLoading ?
            <button type='button'
              className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[161px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'>
              <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full">
              </div>
              <span>Loading...</span>
            </button> :
            <button
              onClick={(e) => handleAddSection(e)}
              className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            >
              <span>Add Header Menu</span>
            </button>
          }
        </div>
      </div>
    </>
  );
};

export default AddHeaderGroup;
