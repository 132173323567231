import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import MuiSwitch from '../../../../../controllers/MuiSwitch';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';
import GenerateUniqueFilename from '../../../../../controllers/GenerateUniqueFilename';
import API from '../../../../../API';
import { decryptData } from '../../../../../controllers/encryptionUtils';

const StaticSixContentSection = ({
  section_id,
  section_type,
  saveElementorData,
  btnLoading,
  handleSixContentTagChange,
  sectionContent,
  headingLevel,
  handleSixContentTitleValueChange,
  handleSixContentContentChange,
  handleSixContentDescriptionChange,
  descriptionContent,
  sectionButtonContent,
  handleSixContentSubButtonValueChange,
  sectionSubButtonContent,
  valueContent,
  sectionButtonLink,
  handleSixContentButtonLinkChange,
  sectionImageContent,
  handleSixContentImageChange,
  sectionProfileContent,
  handleSixContentProfileChange,
  sectionObjectFitContent,
  handleSixContentObjectFitChange,
  sectionIconFillContent,
  handleSixContentIconFillValueChange,
  handleSixContentProfileFillValueChange,
  sectionProfileFillContent,
  sectionIconContent,
  handleSixContentIconChange,
  setIsNotification,
  setNotificationMsg,
  setSeverity,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [subButton, setSubButton] = useState(sectionSubButtonContent);
  const [ButtonLink, setButtonLink] = useState(sectionButtonLink);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [imageValue, setImageValue] = useState(sectionImageContent);
  const [profileValue, setProfileValue] = useState(sectionProfileContent);
  const [objectFitValue, setObjectFitValue] = useState(sectionObjectFitContent);
  const [iconFillValue, setIconFillValue] = useState(sectionIconFillContent);
  const [iconProfileFillValue, setProfileFillValue] = useState(sectionProfileFillContent);
  const [iconValue, setIconValue] = useState(sectionIconContent);

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ShortquillRef = useRef(null);
  const fileInputRef = useRef(null);

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [contentValue, setContentValue] = useState(valueContent);
  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSixContentTitleValueChange(section_id, value);
  };

  const mappings = [
    { state: sectionContent, setter: setGroupTitle },
    { state: sectionSubButtonContent, setter: setSubButton },
    { state: descriptionContent, setter: setShortDescription },
    { state: valueContent, setter: setContentValue },
    { state: headingLevel, setter: setSelectedHeadingLevel },
    { state: sectionImageContent, setter: setImageValue },
    { state: sectionProfileContent, setter: setProfileValue },
    { state: sectionObjectFitContent, setter: setObjectFitValue },
    { state: sectionIconFillContent, setter: setIconFillValue },
    { state: sectionProfileFillContent, setter: setProfileFillValue },
    { state: sectionIconContent, setter: setIconValue },
  ];

  useEffect(() => {
    mappings.forEach(({ state, setter }) => setter(state));
  }, [
    sectionContent,
    sectionSubButtonContent,
    descriptionContent,
    valueContent,
    headingLevel,
    sectionImageContent,
    sectionProfileContent,
    sectionObjectFitContent,
    sectionIconFillContent,
    sectionProfileFillContent,
    sectionIconContent,
  ]);

  const onSubTitleContentChange = (e) => {
    const newValue = e.target.value;
    setSubButton(newValue);
    handleSixContentSubButtonValueChange(section_id, newValue);
  };

  const onButtonLinkChange = (e) => {
    const newValue = e.target.value;
    setButtonLink(newValue);
    handleSixContentButtonLinkChange(section_id, newValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSixContentTagChange(section_id, newTag);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSixContentTitleValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSixContentDescriptionChange(section_id, value);
  };

  const handleContentQuillChange = (value) => {
    setContentValue(value);
    handleSixContentContentChange(section_id, value);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      setImageValue(result?.data[0]);
      handleSixContentImageChange(section_id, result?.data[0]);
      setIsNotification(true);
      setNotificationMsg(result?.message);
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleProfileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validImageTypes = ['image/svg+xml', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(file.type)) {
      alert('Please select a valid SVG, JPEG, or PNG file.');
      return;
    }

    const reader = new FileReader();
    if (file.type === 'image/svg+xml') {
      reader.onload = (e) => {
        const svgContent = e.target.result;
        setProfileValue(svgContent);
        handleSixContentProfileChange(section_id, svgContent);
      };
      reader.readAsText(file);
    } else {
      let fileName = file?.name;
      if (!fileName) {
        fileName = await GenerateUniqueFilename();
      }
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'images');
      try {
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        console.log('setBgImage', result);
        setProfileValue(result?.data[0]);
        handleSixContentProfileChange(section_id, result?.data[0]);
        setIsNotification(true);
        setNotificationMsg(result?.message);
        setSeverity('success');
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    setObjectFitValue(backgroundFit);
    handleSixContentObjectFitChange(section_id, backgroundFit);
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    setIconFillValue(checked);
    handleSixContentIconFillValueChange(section_id, checked);
  };

  const handleProfileIconFill = (e) => {
    const { checked } = e.target;
    setProfileFillValue(checked);
    handleSixContentProfileFillValueChange(section_id, checked);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    setIconValue(iconComponent);
    handleSixContentIconChange(section_id, iconComponent);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div>
        <label>Content</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          // value={shortdescription?.length ? shortdescription.split(' ').slice(0, 280).join(' ') : ''}
          value={contentValue}
          onChange={handleContentQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='mt-4'>
        <label>Link button</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={subButton}
          onChange={onSubTitleContentChange}
        />
      </div>

      <div className='mt-4'>
        <label>Link URL</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={ButtonLink}
          onChange={onButtonLinkChange}
        />
      </div>

      <div className='w-full mb-4 mt-2'>
        <label>Image</label>
        <img
          src={imageValue}
          alt='Uploaded Preview'
          className='w-full mb-5 max min-h-28 max-h-72 object-contain'
        />
        <input
          type='file'
          accept='image/*'
          name='image'
          onChange={(e) => handleImageUpload(e)}
          className='truncate max-w-full overflow-hidden block mb-5'
        />
      </div>

      <div className='flex items-center mt-2 mb-4'>
        <label htmlFor='sub_title' className='text-[#242323] w-[45%]'>
          {t('lang_fit')}
        </label>
        <div className='relative w-[100%] primary-bg-color'>
          <select
            className='edit-select outline-none h-8 primary-bg-color rounded-[6px] table-text w-full capitalize'
            value={objectFitValue}
            onChange={handleBgFitChange}
            name='objectFit'
          >
            <option>{t('lang_fill')}</option>
            <option>{t('lang_contain')}</option>
            <option>{t('lang_cover')}</option>
            <option>{t('lang_none')}</option>
            <option>{t('lang_scale_down')}</option>
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4'>
        <label>Profile</label>

        {profileValue &&
        (profileValue.startsWith('data:image/svg+xml') || profileValue.includes('<svg')) ? (
          <SvgSection
            svgContent={profileValue}
            width={'100%'}
            height={'100px'}
            fill={'#9ca3af'}
            stroke={'#9ca3af'}
            style={{ marginBottom: '10px' }}
          />
        ) : (
          <img
            src={profileValue}
            alt='Uploaded Preview'
            className='w-full mb-5 max min-h-28 max-h-72 object-contain'
          />
        )}
        <input
          type='file'
          accept='image/*'
          name='profile'
          onChange={(e) => handleProfileUpload(e)}
          className='truncate max-w-full overflow-hidden block mb-5'
        />
      </div>

      <div className='mt-2'>
        <label htmlFor='text'>Icon</label>
        <div
          className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {iconValue &&
            (React.isValidElement(iconValue) ? (
              React.cloneElement(iconValue, { width: '22px', height: '22px' })
            ) : (
              <SvgSection svgContent={iconValue} width='22px' height='22px' />
            ))}

          {isHovered && (
            <>
              <button
                className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleIconClick('iconList')}
              >
                Icon library
              </button>
              <button
                className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                onClick={() => handleSvgClick()}
              >
                SVG Icon
              </button>
            </>
          )}
        </div>
      </div>

      <div className='w-full mb-4 mt-2'>
        <label className='flex items-center justify-between cursor-pointer'>
          <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Profile Fill</span>
          <FormControlLabel
            control={
              <MuiSwitch
                checked={iconProfileFillValue || false}
                onChange={handleProfileIconFill}
                name='profileFill'
                type='checkbox'
              />
            }
          />
        </label>
      </div>

      <div className='w-full mb-4 mt-2'>
        <label className='flex items-center justify-between cursor-pointer'>
          <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>Icon Fill</span>
          <FormControlLabel
            control={
              <MuiSwitch
                checked={iconFillValue || false}
                onChange={handleIconFill}
                name='isFill'
                type='checkbox'
              />
            }
          />
        </label>
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticSixContentSection;
