import React, { useRef, useState } from 'react';
import IconList from './IconList';
import SvgSection from '../../../../controllers/SvgSection';
import { t } from 'i18next';

const ListItem = ({
  item,
  handleInputChange,
  handleIconClick,
  handleItemClick,
  isSelected,
  onRemove,
  onCopy,
  handleSvgClick,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className={`p-3 border border-gray-200 rounded-md mb-2 ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <span className='mr-2 flex'>
          <div>
            {item?.icon &&
              (React.isValidElement(item?.icon) ? (
                React.cloneElement(item?.icon, { width: '20px', height: '20px' })
              ) : (
                <SvgSection svgContent={item?.icon} width='20px' height='20px' />
              ))}
          </div>
          &nbsp;
        </span>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            onClick={() => onCopy(item)}
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            onClick={() => onRemove(item.id)}
            className='text-red-400 hover:text-red-600'
            title='Remove'
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <label htmlFor='text'>Icon</label>
          <div
            className='relative flex justify-center text-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {item?.icon &&
              (React.isValidElement(item?.icon) ? (
                React.cloneElement(item?.icon, { width: '22px', height: '22px' })
              ) : (
                <SvgSection svgContent={item?.icon} width='22px' height='22px' />
              ))}

            {isHovered && (
              <>
                <button
                  className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                  onClick={() => handleIconClick('iconList')}
                >
                  Icon library
                </button>
                <button
                  className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                  onClick={() => handleSvgClick()}
                >
                  SVG Icon
                </button>
              </>
            )}
          </div>

          <div className='w-full mb-4 mt-2'>
            <label htmlFor='text'>Link</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='link'
              id='link'
              name='link'
              placeholder='Enter URL...'
              value={item?.link || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SocialIconSettingSection = ({
  items,
  setsocialIconData,
  section_id,
  handleSocialIconDataValue,
  handleSocialIconDataRemoveValue,
  handleSocialIconCopyValue,
  setTabIndex,
  socialIconData,
  tabIndex,
  handleSocialIconListValue,
  setIsOpen,
  isOpen,
  handleSocialIconValue,
  saveElementorData,
  btnLoading,
}) => {
  const fileInputRef = useRef(null);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedTabValue = {
      ...socialIconData,
      item: socialIconData?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setsocialIconData(updatedTabValue);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    handleSocialIconValue(section_id, iconComponent, tabIndex);
  };

  const addItem = () => {
    const newId = items.length ? items[items.length - 1].id + 1 : 1;
    const newItem = {
      id: newId,
      icon: `<svg
            aria-hidden='true'
            class='e-font-icon-svg e-fas-check'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path>
          </svg>`,
    };

    handleSocialIconDataValue(section_id, newItem);
  };

  const removeItem = (id) => {
    handleSocialIconDataRemoveValue(section_id, id);
  };

  const copyItem = (item, index) => {
    handleSocialIconCopyValue(section_id, item, index);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedTabValue = {
      ...socialIconData,
      item: socialIconData?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setsocialIconData(updatedTabValue);
    handleSocialIconListValue(section_id, updatedTabValue);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleSocialIconValue(section_id, imageUrl, tabIndex);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='max-w-md mx-auto mt-8'>
      <h2 className='text-lg font-semibold mb-4'>Items</h2>
      <div>
        {items?.map((item, index) => (
          <ListItem
            key={index}
            item={item}
            onRemove={removeItem}
            onCopy={() => copyItem(item, index)}
            handleItemClick={() => handleItemClick(index)}
            isSelected={tabIndex === index}
            handleInputChange={handleInputChange}
            handleIconClick={handleIconClick}
            handleSvgClick={handleSvgClick}
          />
        ))}
      </div>
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
          handleSvgClick={handleSvgClick}
        />
      ) : (
        ''
      )}
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SocialIconSettingSection;
