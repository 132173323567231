import moment from "moment-timezone";
import { clientData } from "./GetClient";

const currencyRate = localStorage.getItem('currencyrate')?.length > 0 && JSON.parse(localStorage.getItem('currencyrate'));
const ratesArray = Object.keys(currencyRate).map(key => {
    return { name: key, rate: currencyRate[key] };
}) ?? [];
export const FormatAmount = (amount) => {
    const clientInfo = clientData;
    let currency;

    // Find the exchange rate for the client's currency format
    const currencyRate = ratesArray?.find((elem) => clientInfo?.currency_format === elem?.name);
    // If rate is found, use it to calculate the amount; otherwise, default to the original amount
    if (currencyRate) {
        currency = Number(amount) * currencyRate.rate;
    } else {
        currency = Number(amount);
    }
    // Custom formatting based on client preferences
    const thousandSeparator = clientInfo?.thousand_separator || ',';
    const numberOfDecimals = Number(clientInfo?.number_of_decimals) || 2;
    const decimalSeparator = clientInfo?.decimal_separator || '.';
    const currencyPosition = clientInfo?.currency_position || 'left';

    // Remove existing thousand separators from the formatted currency
    const regex = new RegExp(`\\${thousandSeparator}`, 'g');
    const cleanedAmount = currency.toString()?.replace(regex, '');

    // Convert to a number and apply fixed decimal places
    if (cleanedAmount) {
        let formattedNumber = Number(cleanedAmount.replace(/[^\d.-]/g, '')).toFixed(numberOfDecimals);

        // Split the number into integer and decimal parts
        let parts = formattedNumber.split('.');
        let integerPart = parts[0];
        let decimalPart = parts[1] || '';

        // Add thousand separators to the integer part
        let integerWithSeparators = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);

        // Combine the integer part with the decimal part using the custom decimal separator
        let finalAmount = integerWithSeparators + (decimalPart ? decimalSeparator + decimalPart : '');

        // Set currency symbol based on the client format
        let currencySymbol;
        switch (clientInfo?.currency_format) {
            case 'INR':
                currencySymbol = '₹';
                break;
            case 'USD':
                currencySymbol = '$';
                break;
            case 'EUR':
                currencySymbol = '€';
                break;
            // Add other currencies as needed
            default:
                currencySymbol = '₹'; // Default to INR symbol if not defined
        }

        // Apply currency position formatting
        let result = '';
        switch (currencyPosition) {
            case 'left':
                result = `${currencySymbol}${finalAmount}`;
                break;
            case 'right':
                result = `${finalAmount}${currencySymbol}`;
                break;
            case 'left_with_space':
                result = `${currencySymbol} ${finalAmount}`;
                break;
            case 'right_with_space':
                result = `${finalAmount} ${currencySymbol}`;
                break;
            default:
                result = `${currencySymbol}${finalAmount}`; // Default to left position
        }

        return result;
    }

    return null;
};


export function GetCurrencySymbol() {
    const clientInfo = clientData;

    // Define a mapping of currency formats to symbols
    const currencySymbols = {
        'INR': '₹',
        'USD': '$',
        'EUR': '€',
        'AUD': 'A$',
        'CAD': 'C$',
        'NZD': 'NZ$',
        // Add other currencies as needed
    };

    // Return the corresponding symbol or a default (₹ if not found)
    return currencySymbols[clientInfo?.currency_format] || '₹';
}

export const formatDate = (date) => {
    const clientInfo = clientData;
    const dateFormat = clientInfo?.date_format
    const result = moment(date).format(dateFormat)
    return result
}