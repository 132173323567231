import { Dialog } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import React from 'react';
import { Transition } from '../../controllers/Transition';
import { t } from 'i18next';

const EditAdditionalLicencesField = ({
  openEditField,
  setOpenEditField,
  editAdditionalLicensesField,
  setEditAdditionalLicensesField,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={openEditField}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenEditField(false)}
    >
      <form
        className='secondary-bg-color w-[700px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded-[6px] p-5'
        method='post'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
          <h6 className='font-medium  text-xl'>{t('lang_Edit_Additional_Licenses_Field')}</h6>
          <button className='close-btn' onClick={() => setOpenEditField(false)}>
            <X className='w-5 h-4' />
          </button>
        </div>
        <div className='mb-4'>
          <label htmlFor='title'>{t('lang_Field_Name')}</label>
          <input
            className={`w-full px-3 py-2 border ${
              editAdditionalLicensesField?.field_name?.split(' ').length < 60
                ? 'main-border-color'
                : 'border-red-500'
            } rounded-[6px] focus:outline-none`}
            type='text'
            value={
              editAdditionalLicensesField?.field_name?.length
                ? editAdditionalLicensesField?.field_name?.split(' ').slice(0, 40).join(' ')
                : ''
            }
            name='field_name'
            placeholder='Enter field name'
            onChange={(e) =>
              setEditAdditionalLicensesField({
                ...editAdditionalLicensesField,
                field_name: e.target.value,
              })
            }
            required
          />
          <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
        </div>
        <div className='mb-4'>
          <div className='flex items-center justify-between'>
            <label htmlFor='instagram' className='flex items-center justify-between'>
              {t('lang_value')}
            </label>
          </div>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='url'
            value={editAdditionalLicensesField?.value}
            name='value'
            placeholder='Enter value'
            onChange={(e) =>
              setEditAdditionalLicensesField({
                ...editAdditionalLicensesField,
                value: e.target.value,
              })
            }
          />
        </div>
        <button
          type='submit'
          className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
        >
          {t('lang_Update_Additional_Licenses_Field')}
        </button>
      </form>
    </Dialog>
  );
};

export default EditAdditionalLicencesField;
