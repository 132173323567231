import { Popover, Tooltip } from '@mui/material';
import {
    ArrowLeft,
    Edit,
    Filter,
    PlusCircle,
    Trash,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../../API';
import { DataLoad, PlanPermission } from '../../../App';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { decryptData } from '../../../controllers/encryptionUtils';
import NoRecords from '../../Main/NoRecords';
import Notification from '../../Notification';
import TableSkeleton from '../../Skeleton/TableSkeleton';
import { clientData } from '../../../controllers/GetClient';

const PosLocations = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dataLoading, setDataLoading } = useContext(DataLoad);
    const { setPlanPermission } = useContext(PlanPermission);
    const [locations, setLocations] = useState();
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const [clearRow, setClearRow] = useState(false);
    const [search, setSearch] = useState(
        location?.state?.searchkey ? location?.state?.searchkey : '',
    );
    useEffect(() => {
        setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
    }, [location?.state?.searchkey]);
    useEffect(() => {
        if (search === '' || search?.length > 2) {
            getStaff();
        }
    }, [search]);
    const getStaff = async () => {
        setDataLoading(true);
        try {
            let ApiCall = await API.get(
                `/admin/pos/getPOSLocations?searchkey=${search?.length > 2 ? search : ''
                }`,
            );
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const result = decryptData(ApiCall?.data)
                setLocations(result);
            }
        } catch (error) {
            if (error?.response?.status === 882) {
                setPlanPermission({ isPlanPermissions: false, forModule: 'pos' });
            }
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setDataLoading(false);
        }
    };

    // Update locations
    const Handleedit = (id) => {
        navigate(`/pos-location/${id}`);
    };
    const [filter, setFilter] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    return (
        <div className='h-auto overflow-y-auto pb-[64px]'>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='flex items-end justify-between w-full overflow-auto pb-[30px]'>
                <div className='flex items-center space-x-2'>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            navigate('/dashboard');
                        }}
                        className='back-arrow'
                    >
                        <ArrowLeft />
                    </button>
                    <div>
                        <Breadcrumbs
                            crumbs={[
                                { name: t('lang_dashboard'), path: `/dashboard` },
                                { name: t('lang_Location'), path: '/pos-location' },
                            ]}
                        />
                        <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Location')}</h4>
                    </div>
                </div>
                <div className='flex items-center'>
                    <button
                        className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
                        onClick={() => navigate('/pos-location/add-location')}
                    >
                        <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
                        {t('lang_Add_Location')}
                    </button>
                </div>
            </div>

            <div className='flex justify-between space-x-3 w-full relative'>
                <div className='w-full space-y-3'>
                    <div className='flex items-center justify-between'>
                        <div>
                        </div>
                        {locations?.length > 0 && <Tooltip title='Filter' arrow>
                            <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                                <button className=''>
                                    <Filter className='h-5 w-5' />
                                </button>
                            </div>
                        </Tooltip>}
                    </div>
                    <div
                        className={'flex w-full h-full overflow-hidden relative'}
                    >
                        <div className='w-full'>
                            {
                                dataLoading && !locations ?
                                    <TableSkeleton forTable="order" /> :
                                    locations?.length > 0 ?
                                        <div className='w-full sticky-table  transition-all duration-100 mb-1 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                                            <table className='w-full'>
                                                <thead>
                                                    <tr>
                                                        <td className='px-3 w-full'>
                                                            {t('lang_Location')}
                                                        </td>
                                                        <td className='px-3 w-full'>
                                                            {t('lang_language')}
                                                        </td>
                                                        <td className='px-3 w-full'>
                                                            {t('lang_staff')}
                                                        </td>
                                                        <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                                                            <span className='text-right w-full'>action</span>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        locations?.map((elem, index) => {
                                                            return (
                                                                <tr key={index} onClick={() => Handleedit(elem?._id)}>
                                                                    <td className='px-3 w-full'>
                                                                        <span >{elem.name ?? '-'}</span>
                                                                    </td>
                                                                    <td className='px-3 w-full'>
                                                                        <span >{elem?.language ?? '-'}</span>
                                                                    </td>
                                                                    <td className='px-3 w-full'>
                                                                        <span >{elem?.staff ?? '-'}</span>
                                                                    </td>
                                                                    <td className='px-3 min-w-[120px] max-w-[120px] text-right' onClick={(e) => e.stopPropagation()}>
                                                                        <div className='space-x-2 w-full flex items-center justify-end'>
                                                                            {elem?.email !== clientData?.business_email && (
                                                                                <>
                                                                                    <Tooltip title='Edit' arrow>
                                                                                        <button className='text-black-500 hover:text-black-600' onClick={() => { Handleedit(elem?._id) }}>
                                                                                            <Edit className='w-[17px] h-[17px]' />
                                                                                        </button>
                                                                                    </Tooltip>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div> : <NoRecords />
                            }
                        </div>
                        <Popover
                            open={filter}
                            anchorEl={anchorEl}
                            onClose={() => { setFilter(false); setAnchorEl(null) }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            PaperProps={{
                                style: { marginRight: '35px', marginTop: '10px' }, // Adjust padding and margin
                            }}
                            className='rounded-[6px]'
                        >
                            <div className='p-[20px] filter'>
                                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                                    <Filter className='me-1 w-4 h-4' />
                                    {t('lang_filter')}
                                </p>
                                <hr className='main-border-color my-3'></hr>
                                <div className='grid gap-y-5'>
                                    <div>
                                        <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                                            {t('lang_search')}
                                        </span>
                                        <label className='relative block w-full'>
                                            <input
                                                className='w-full block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                                                placeholder='Search team'
                                                type='text'
                                                name='search'
                                                value={search}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                }}
                                            />
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PosLocations;
