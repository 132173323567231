import React from 'react';
import SvgSection from '../../controllers/SvgSection';
import { getAlertClass, mapPositionToCSS } from '../../controllers/DynamicEditorSwitchCase';

const AlertSection = ({ obj }) => {
  return (
    <>
      <div
        key={obj?.id}
        style={{
          paddingTop: `${obj?.style?.padding_top}px`,
          paddingBottom: `${obj?.style?.padding_bottom}px`,
          paddingLeft: `${obj?.style?.padding_left}px`,
          paddingRight: `${obj?.style?.padding_right}px`,
          marginTop: `${obj?.style?.margin_top}px`,
          marginBottom: `${obj?.style?.margin_bottom}px`,
          marginLeft: `${obj?.style?.margin_left}px`,
          marginRight: `${obj?.style?.margin_right}px`,
          borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
          borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
          borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
          borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
          borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
          boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
          background:
            obj?.style?.background_gradient === 'gradient'
              ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
              : obj?.style?.background_image
              ? `url(${obj?.style?.background_image})`
              : obj?.style?.background,
          transform: `rotate(${obj?.style?.text_rotate}deg)`,
          backgroundRepeat: obj?.style?.background_repeat,
          backgroundAttachment: obj?.style?.background_attachment,
          objectFit: obj?.style?.background_fit,
          backgroundSize:
            obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
              ? obj?.style?.background_fit
              : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
          backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
          backgroundColor: obj?.style?.alert_background,
        }}
      >
        <div
          key={obj.id}
          className={`relative p-3 border rounded-lg ${getAlertClass(
            obj?.content?.alertType || 'info',
          )}`}
        >
          {obj?.content?.showIcon && (
            <button className='absolute top-2 right-2 text-[#31708f] hover:text-[#155a6c] font-bold'>
              {obj?.content?.icon && React.isValidElement(obj?.content?.icon) ? (
                React.cloneElement(obj?.content?.icon, {
                  width: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px',
                  height: obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px',
                  fill: obj?.style?.icon_color
                    ? `${obj.style?.icon_color}`
                    : obj?.icon?.props?.fill,

                  stroke: obj?.style?.icon_color
                    ? `${obj.style?.icon_color}`
                    : obj?.icon?.props?.stroke,
                })
              ) : (
                <SvgSection
                  svgContent={obj?.content?.icon}
                  width={obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px'}
                  height={obj?.style?.icon_width ? `${obj.style.icon_width}px` : '15px'}
                  fill={obj?.style?.icon_color}
                  stroke={obj?.style?.icon_color}
                />
              )}
            </button>
          )}
          <div
            style={{
              fontWeight: obj?.style?.title_font_weight,
              fontSize: obj?.style?.title_font_size ? `${obj?.style?.title_font_size}px` : '22px',
              lineHeight: obj?.style?.title_font_height
                ? `${obj?.style?.title_font_height}px`
                : '29px',
              color: obj?.style?.title_font_color,
              textAlign: obj?.style?.title_font_align,
              fontStyle: obj?.style?.title_font_style,
              textDecoration: obj?.style?.title_font_decoration,
              textTransform: obj?.style?.title_font_capitalize,
              wordBreak: obj?.style?.title_font_break,
              textWrap: obj?.style?.title_font_wrap,
              letterSpacing: `${obj?.style?.title_letter_spacing}px`,
              wordSpacing: `${obj?.style?.title_word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
              textShadow: `${obj?.style?.title_shadow_horizontal}px ${obj?.style?.title_shadow_vertical}px ${obj?.style?.title_shadow_blur}px ${obj?.style?.title_shadow_color}`,
              textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
            }}
          >
            {obj?.content?.title}
          </div>
          <p
            className='mt-2'
            style={{
              fontWeight: obj?.style?.desc_font_weight,
              fontSize: `${obj?.style?.desc_font_size}px`,
              lineHeight: `${obj?.style?.desc_font_height}px`,
              color: obj?.style?.desc_font_color,
              textAlign: obj?.style?.desc_font_align,
              fontStyle: obj?.style?.desc_font_style,
              textDecoration: obj?.style?.desc_font_decoration,
              textTransform: obj?.style?.desc_font_capitalize,
              wordBreak: obj?.style?.desc_font_break,
              textWrap: obj?.style?.desc_font_wrap,
              letterSpacing: `${obj?.style?.desc_letter_spacing}px`,
              wordSpacing: `${obj?.style?.desc_word_spacing}px`,
              WebkitTextStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
              textStroke: `${obj?.style?.desc_text_stroke}px ${obj?.style?.desc_stroke_color}`,
              textShadow: `${obj?.style?.desc_shadow_horizontal}px ${obj?.style?.desc_shadow_vertical}px ${obj?.style?.desc_shadow_blur}px ${obj?.style?.desc_shadow_color}`,
              marginTop: `${obj?.style?.content_spacing}px`,
            }}
          >
            {obj?.content?.content}
          </p>
        </div>
      </div>
    </>
  );
};

export default AlertSection;
