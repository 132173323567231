export const BardSvg = () => {
    return (
        <svg className="me-0.5" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_780_408)">
                <path d="M7.15387 10.0807L6.55548 11.4512C6.32545 11.978 5.59642 11.978 5.36641 11.4512L4.768 10.0807C4.23548 8.86097 3.27696 7.89011 2.08134 7.35939L0.434254 6.62827C-0.0894127 6.39582 -0.0894127 5.63402 0.434254 5.40157L2.02989 4.69328C3.25627 4.1489 4.23198 3.14206 4.75541 1.88077L5.36156 0.420182C5.58649 -0.121832 6.33538 -0.121832 6.56032 0.420182L7.16648 1.88078C7.68991 3.14206 8.6656 4.1489 9.89198 4.69328L11.4876 5.40157C12.0113 5.63402 12.0113 6.39582 11.4876 6.62827L9.84053 7.35939C8.64492 7.89011 7.68641 8.86097 7.15387 10.0807Z" fill="currentcolor" />
                <path d="M12.7152 14.4021L12.5469 14.7878C12.4238 15.0701 12.0328 15.0701 11.9096 14.7878L11.7413 14.4021C11.4413 13.7144 10.901 13.1668 10.2268 12.8673L9.70831 12.6369C9.42796 12.5124 9.42796 12.1048 9.70831 11.9803L10.1978 11.7628C10.8894 11.4555 11.4394 10.8877 11.7342 10.1766L11.907 9.75986C12.0275 9.46937 12.4291 9.46937 12.5495 9.75986L12.7223 10.1766C13.0172 10.8877 13.5672 11.4555 14.2588 11.7628L14.7482 11.9803C15.0286 12.1048 15.0286 12.5124 14.7482 12.6369L14.2297 12.8673C13.5555 13.1668 13.0152 13.7144 12.7152 14.4021Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_780_408">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}