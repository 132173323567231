import React, { useEffect, useState } from 'react';
import API from '../../API';
import Notification from '../Notification';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { clientData } from '../../controllers/GetClient';
import { encryptData } from '../../controllers/encryptionUtils';

const CustomStaticScript = () => {
  const [custom_js, setcustom_js] = useState('');
  const [custom_css, setcustom_css] = useState('');
  const [initialCustomJs, setInitialCustomJs] = useState('');
  const [initialCustomCss, setInitialCustomCss] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  useEffect(() => {
    if (clientData) {
      setcustom_js(clientData.custom_js || '');
      setcustom_css(clientData.custom_css || '');
      setInitialCustomJs(clientData.custom_js || '');
      setInitialCustomCss(clientData.custom_css || '');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let payload = {};
      if (custom_js.length > 0 && custom_js !== initialCustomJs) {
        payload.custom_js = JSON.stringify(custom_js);
      }
      if (custom_css.length > 0 && custom_css !== initialCustomCss) {
        payload.custom_css = JSON.stringify(custom_css);
      }
      const body = encryptData({ clientInfo: payload });
      const apiCall = await API.put('/admin/client/updateClient', body);
      if (apiCall.status === 200) {
        setInitialCustomJs(custom_js);
        setInitialCustomCss(custom_css);
        setIsNotification(true);
        setNotificationMsg('Custom Script added successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error.message);
      setSeverity('error');
    }
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Custom_Code')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_social_media_static_code')}
            </p>
          </div>
        </div>
        <>
          <form
            method='post'
            className='w-full pb-4 sm:pb-[25px] text-sm mx-auto rounded-[6px]'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className='mb-4'>
              <label htmlFor='title'>{t('lang_Custom_JS')}</label>
              <textarea
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                value={custom_js}
                name='custom_js'
                placeholder='Enter custom JS'
                onChange={(e) => setcustom_js(e.target.value)}
              />
            </div>
            <div className='mb-4'>
              <label htmlFor='title'>{t('lang_Custom_Css')}</label>
              <textarea
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                value={custom_css}
                name='custom_css'
                placeholder='Enter custom CSS'
                onChange={(e) => setcustom_css(e.target.value)}
              />
            </div>
            <div className='w-full flex justify-end'>
              <button
                type='submit'
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300 capitalize'
              >
                {t('lang_save')}
              </button>
            </div>
          </form>
        </>
      </div>
    </>
  );
};

export default CustomStaticScript;
