import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

const AddProductGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [productGroupTitle, setProductGroupTitle] = useState('');
  const [productData, setProductData] = useState({
    button_link: '/product',
    product_limit: 4,
    carousel_status: true,
    status: true,
    product_image_width: '',
    product_image_height: '',
    carousel_speed_ms: '',
    auto_play: false,
    pause_on_hover: false,
    loop_forever: false,
    button_text: '',
    is_filter_apply: false,
    filter_type: '',
  });
  const [addProduct, setAddProduct] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddProduct = () => {
    setAddProduct(false);
  };
  const [selectedProduct, setSelectedProduct] = useState([]);
  const handleSelectProduct = (product, name, image) => {
    if (product) {
      const existProduct = selectedProduct?.find((x) => x?.product === product);
      if (!existProduct) {
        setSelectedProduct([...selectedProduct, { product, name, image }]);
      } else {
        const removeSelected = selectedProduct?.filter((x) => x?.product !== product);
        setSelectedProduct(removeSelected);
      }
    }
  };
  const [allProduct, setAllProduct] = useState([]);
  const [search, setSearch] = useState('');
  const GetProduct = async () => {
    try {
      const payload = encryptData({
        searchkey: search,
      })
      const apiCall = await API.post(`/admin/product/GetProductsTable`, payload);
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllProduct(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addProduct === true && (search?.length === 0 || search?.length >= 3)) {
      GetProduct();
    }
  }, [addProduct, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddProductGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    let body = {
      product_section_title: productGroupTitle,
      button_text: productData?.button_text,
      button_link: productData?.button_link,
      product_limit: productData?.product_limit,
      carousel_status: productData?.carousel_status,
      status: productData?.status,
      carousel_speed_ms: productData?.carousel_speed_ms,
      loop_forever: productData?.loop_forever,
      auto_play: productData?.auto_play,
      pause_on_hover: !productData?.auto_play ? false : productData?.pause_on_hover,
      is_filter_apply: productData?.is_filter_apply,
      products: selectedProduct,
    };
    if (productData?.is_filter_apply === true) {
      body = {
        ...body,
        filter_type: productData?.filter_type,
        products: filterProducts,
      };
    } else if (
      productData?.product_image_width?.length > 0 ||
      productData?.product_image_height?.length > 0
    ) {
      body = {
        ...body,
        product_image_width: productData?.product_image_width,
        product_image_height: productData?.product_image_height,
      };
    } else {
      body = {
        ...body,
      };
    }
    const payload = encryptData(body)
    try {
      await API.post(`/admin/dynamicProduct/addProductGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/product-group',
        title: 'add product category',
      });
      navigate('/module-setting/product-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [filterProducts, setFilterProducts] = useState([]);
  const getFilterProduct = async () => {
    if (productData?.is_filter_apply === true) {
      try {
        const payload = encryptData({
          filter: productData?.filter_type,
          product_limit: productData?.product_limit,
        })
        const apiCall = await API.post(`/admin/dynamicProduct/getFilterData`, payload);
        const result = decryptData(apiCall?.data)
        setFilterProducts(result?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  useEffect(() => {
    getFilterProduct();
  }, [productData?.is_filter_apply, productData?.filter_type, productData?.product_limit]);
  useEffect(() => {
    if (
      selectedProduct?.length > 0 ||
      productData?.carousel_speed_ms?.length > 0 ||
      productData?.button_text?.length > 0 ||
      productGroupTitle?.length > 0 ||
      productData?.product_image_height?.length > 0 ||
      productData?.product_image_width?.length > 0 ||
      productData?.loop_forever === false ||
      productData?.status === false ||
      productData?.carousel_status === false ||
      productData?.auto_play === true ||
      productData?.product_limit !== 4 ||
      productData?.pause_on_hover === true ||
      productData?.is_filter_apply === true ||
      productData?.filter_type?.length > 0 ||
      productData?.button_link !== '/product'
    ) {
      setSaveChanges({
        backLink: '/module-setting/product-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add product category',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/product-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add product category',
      });
    }
  }, [selectedProduct, productData, productGroupTitle]);
  const checkChanges = () => {
    if (
      selectedProduct?.length > 0 ||
      productData?.carousel_speed_ms?.length > 0 ||
      productData?.button_text?.length > 0 ||
      productGroupTitle?.length > 0 ||
      productData?.product_image_height?.length > 0 ||
      productData?.product_image_width?.length > 0 ||
      productData?.loop_forever === true ||
      productData?.status === false ||
      productData?.carousel_status === false ||
      productData?.auto_play === true ||
      productData?.product_limit !== 4 ||
      productData?.pause_on_hover === true ||
      productData?.is_filter_apply === true ||
      productData?.filter_type?.length > 0 ||
      productData?.button_link !== '/product'
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/product-group',
        title: 'add product group',
      });
    } else {
      navigate('/module-setting/product-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Product_Group'), path: '/module-setting/product-group' },
                {
                  name: t('lang_Add_New_Product_Group'),
                  path: '/module-setting/product-group/add-product-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Product_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Product_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={productGroupTitle}
                onChange={(e) => setProductGroupTitle(e.target.value)}
                placeholder='Enter group title....'
              />
              {dataErr && productGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full'>
              <div className='w-full'>
                <label>{t('lang_Filter_Type')}</label>
                <div
                  className={
                    dataErr && productData?.is_filter_apply && productData?.filter_type === ''
                      ? 'border border-red-500 rounded-[6px] w-full relative'
                      : 'relative filterType'
                  }
                >
                  <select
                    className='w-full'
                    disabled={!productData?.is_filter_apply}
                    value={productData?.filter_type}
                    onClick={() => setDataErr(false)}
                    onChange={(e) => {
                      setProductData({ ...productData, filter_type: e.target.value });
                    }}
                  >
                    <option value=''>{t('lang_Select_Filter_Type')}</option>
                    <option value='New Arrivals'>{t('lang_New_Arrivals')}</option>
                    <option value='Special'>{t('lang_Special')}</option>
                    <option value='Featured'>{t('lang_featured')}</option>
                    <option value='Top Rated'>{t('lang_Top_Rated')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                {dataErr && productData?.is_filter_apply && productData?.filter_type === '' ? (
                  <p className='text-xs text-red-500'>{t('lang_Please_Select_Filter_Type')}</p>
                ) : (
                  ''
                )}
              </div>
              <div className='w-full'>
                <label>{t('lang_Product_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={productData?.product_limit}
                    onChange={(e) =>
                      setProductData({ ...productData, product_limit: e.target.value })
                    }
                  >
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>

              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.carousel_speed_ms}
                    disabled={productData?.carousel_status === 'disabled'}
                    onChange={(e) =>
                      setProductData({ ...productData, carousel_speed_ms: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              {productData?.auto_play && (
                <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                  <p className='text-[12px] font-medium table-text'>{t('lang_Pause_On_Hover')}</p>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={productData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setProductData({
                          ...productData,
                          pause_on_hover: !productData?.pause_on_hover,
                        });
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={productData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => setProductData({ ...productData, button_text: e.target.value })}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={productData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => setProductData({ ...productData, button_link: e.target.value })}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Product_Image_Width')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.product_image_width}
                    onChange={(e) =>
                      setProductData({ ...productData, product_image_width: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 322'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Product_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={productData?.product_image_height}
                    onChange={(e) =>
                      setProductData({ ...productData, product_image_height: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 416'
                  />
                </div>
              </div>
              <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                <p className='text-[12px] font-medium table-text capitalize'>
                  {t('lang_Carousel_Status')}
                </p>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({
                        ...productData,
                        carousel_status: !productData?.carousel_status,
                      });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                <p className='text-[12px] font-medium table-text'>{t('lang_Carousel_Loop')}</p>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, loop_forever: !productData?.loop_forever });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                <p className='text-[12px] font-medium table-text'>{t('lang_Autoplay')}</p>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, auto_play: !productData?.auto_play });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                <p className='text-[12px] font-medium table-text'>
                  {t('lang_Product_Group_Status')}
                </p>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({ ...productData, status: !productData?.status });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 h-[39px] border main-border-color rounded-[6px] px-4 flex items-center justify-between'>
                <p className='text-[12px] font-medium table-text'>{t('lang_Filter_Apply')}</p>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={productData?.is_filter_apply}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setProductData({
                        ...productData,
                        is_filter_apply: !productData?.is_filter_apply,
                      });
                    }}
                  />
                </label>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            {!productData?.is_filter_apply && (
              <>
                <div className='flex items-center justify-between w-full text-sm'>
                  <div>
                    {t('lang_Select_Products_For_Show_In_This_Group')}
                    <span className='text-red-500 ms-1'>*</span>
                  </div>
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setAddProduct(true);
                    }}
                    className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                  >
                    {t('lang_Select_Product')}
                  </span>
                </div>
                {selectedProduct?.length > 0 ? (
                  <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                          <td className='w-full px-3'>{t('lang_Product_Name')}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProduct?.map((row) => {
                          return (
                            <tr>
                              <td className='table-image px-3 text-center min-w-[80px]'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  width={80}
                                  height={50}
                                  src={row.image || NOPHOTO_IMAGE}
                                  className='p-1 max-w-full max-h-full inline-block m-0'
                                  alt={row?.name}
                                />
                              </td>
                              <td className='w-full px-3'>
                                <span
                                  className='line-clamp-2'
                                  dangerouslySetInnerHTML={{ __html: row.name || '-' }}
                                ></span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                <hr className='border-t main-border-color my-4'></hr>
              </>
            )}
          </div>
        </form>
        <Dialog
          open={addProduct}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddProduct(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl text-primary'>{t('lang_Select_Product')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddProduct(false);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search products..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allProduct?.map((elem) => {
                return (
                  <label className='flex table-text py-2 main-border-color items-start space-x-4'>
                    <input
                      type='checkbox'
                      checked={selectedProduct?.find((x) => x.product === elem?._id)}
                      value={elem?._id}
                      onClick={(e) =>
                        handleSelectProduct(elem?._id, elem?.product_name, elem?.image)
                      }
                      className='block mt-1 w-4 h-4'
                    />
                    <div className='flex space-x-2'>
                      <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                        <img
                          src={elem?.image}
                          className='max-w-full max-h-full'
                          alt={elem?.product_name}
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.product_name}</p>
                    </div>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddProduct()}>
              {t('lang_Add_Product')}
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
           <div className='w-[90%] flex items-center justify-between lg:w-[80%] xl:w-[68%] mx-auto'>
           <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline mt-2 text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
             Go To Product groups
            </Link>
           <div>
              {productGroupTitle?.length === 0 ||
                (!productData?.is_filter_apply && selectedProduct?.length === 0) ||
                (productData?.is_filter_apply && productData?.filter_type === '') ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Product_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[168px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddProductGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Product_Group')}</span>
                </button>
              )}
            </div>
           </div>
           </div>
      </div>
    </>
  );
};

export default AddProductGroup;
