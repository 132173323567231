import { Skeleton } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
function UpdateAttributes() {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const params = useParams();
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [groupValueArray, setGroupValueArray] = useState([]);
  const [formSpinner, setFormSpinner] = useState(true);
  const [groupName, setGroupName] = useState('');
  const getVarientById = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(
        `/admin/product/getProductAttributeGroupById/${params?.attributesid}`,
      );
      const result = decryptData(ApiCall?.data)
      setGroupName(result?.data?.attribute_name);
      setGroupValueArray(result?.data?.attribute_value);
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getVarientById();
  }, [params?.variantsid]);
  const [isChange, setIsChange] = useState(false);
  const [groupValue, setGroupValue] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // save Group Values In Array
  const SaveGroupValue = () => {
    if (groupValue.length > 0) {
      if (groupValue.includes(',')) {
        let TagSeperator = groupValue.split(',');
        let finalArray = [];
        let productTags = [...groupValueArray, ...TagSeperator];
        productTags.filter((tag) => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
          if (!exists) {
            finalArray.push(tag.trim());
          }
        });
        setGroupValueArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${groupValue}$`, 'i');

        const exists = groupValueArray.some((tag) => searchRegex.test(tag));

        if (!exists) {
          setGroupValueArray((prev) => [...prev, groupValue?.trim()]);
        }
      }
      setGroupValue('');
    }
  };
  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      groupValueArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setGroupValueArray([...groupValueArray]);
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        attribute_name: groupName?.trim(),
        attribute_value: groupValueArray,
      })
      const data = await API.put(
        `/admin/product/updateProductAttributeGroup/${params?.attributesid}`, payload
      );
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/attributes',
          title: 'edit attributes',
        });
        navigate('/attributes');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: false,
        backLink: '/attributes',
        title: 'edit attributes',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/attributes',
        title: 'edit attributes',
      });
    } else {
      navigate('/attributes');
    }
  };

  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_attributes'), path: '/attributes' },
                {
                  name: t('lang_Update_attributes'),
                  path: '/attributes/edit-attributes',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_attributes')} - {groupName}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <>
                <div className='space-y-4 secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <Skeleton className='w-full mt-4 min-h-[45px]' variant='rounded-[6px]' />
              </>
            ) : (
              <>
                <div className='secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
                  <div className='mb-4'>
                    <label htmlFor='product_name' className='lowercase'>
                      {t('lang_Attribute_name')}
                      <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='group_name'
                      name='group_name'
                      value={groupName}
                      onChange={(e) => { setGroupName(e.target.value); setIsChange(true); }}
                      placeholder='Group name'
                    />
                  </div>
                  <div className='mb-2'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='Description' className='lowercase'>
                        {t('lang_Attribute_value')}
                        <span className='text-red-500 ms-1'>*</span>
                      </label>
                    </div>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='group_value'
                      name='group_value'
                      value={groupValue}
                      onChange={(e) => {
                        setGroupValue(e.target.value);
                        setIsChange(true);
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' ? SaveGroupValue() : '')}
                      placeholder='e.g. Black'
                    />
                    <p className='w-full text-xs mt-0 text-gray-400'>
                      {t('lang_use')} <b className='text-gray-500'>{t('lang_enter')} {t('lang_OR')} {t('lang_Comma')}</b>{' '}
                      {t('lang_key_to_separate_the_value')}
                    </p>
                  </div>
                  <div className='flex flex-wrap items-center mb-4'>
                    {groupValueArray &&
                      groupValueArray.map((elem, index) => {
                        return (
                          <span
                            key={index}
                            className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 py-1.5 rounded-[6px]'
                          >
                            {elem}
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                              onClick={() => RemoveTag(index)}
                            />
                          </span>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_attributes')}
            </Link>
            <div>
              {groupValueArray?.length === 0 ||
                groupName === '' ||
                !groupName ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_attributes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[196px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <button
                  type='button'
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_attributes')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateAttributes;
