import API from "../../API";
import { decryptData, encryptData } from "../../controllers/encryptionUtils";
import { FormatAmount } from "../../controllers/FormatAmount";
import { clientData } from "../../controllers/GetClient";
// import Razorpay from 'razorpay';
const AdminPlanRazorpay = async (elem, checkPlan, closeAdminPlanPopup, closeSpinner) => {
    const select = elem?.plan?.find(p => p.plan_type === checkPlan)
    const clientInfo = clientData;
    const razorpayKey = process.env.REACT_APP_RAZORPAY_KEY_ID;
    if (!razorpayKey) {
        console.error("Razorpay Key is not defined");
        return;
    }
    const sellingPrice = FormatAmount(select?.price);
    const priceWithoutSymbol = sellingPrice?.slice(1);
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error("Failed to load Razorpay SDK"));
            };
            document.body.appendChild(script);
        });
    }
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    if (!res) {
        console.log("Razorpay SDK failed to load. Are you online?");
        return;
    }
    const options = {
        key: razorpayKey, // Replace with your Razorpay Key ID
        amount: priceWithoutSymbol, // Amount in paisa
        currency: clientInfo?.currency_format,
        name: 'Shopeasy',
        subscription_id: 'sub_9c7K2Q1Wdp8sKL',
        // order_id: elem?._id, // Use the order ID received from backend
        handler: async function (response) {
            const values = {
                planId: elem?._id,
                transaction_id: response.razorpay_payment_id,
                planTypeId: select?._id
            };
            try {
                const payload = encryptData(values)
                await API.post('/admin/plan/purchasePlan', payload);
                await closeAdminPlanPopup("success");
                await closeSpinner()
            } catch (error) {
                console.log("Error during purchase", error);
            }
        },
        prefill: {
            name: clientInfo?.business_name,
            email: clientInfo?.business_email,
            contact: clientInfo?.business_phone,
        },
        notes: {
            store_id: clientInfo?.store_id,
        },
        theme: {
            color: '#3399cc',
        },
        modal: {
            ondismiss: function () {
                closeAdminPlanPopup();
                closeSpinner();
            }
        }
    };
    try {
        const createOrderPayload = encryptData({
            amount: priceWithoutSymbol,
            plan_period: (select?.plan_type === "Yearly" ? 'yearly' : 'Monthly').toLowerCase(),
            plan_name: elem?.name,
            interval: select?.plan_type === "Quarterly" ? 3 : select?.plan_type === "Semi Yearly" ? 6 : 1
        })
        const createOrderPayment = await API.post("/admin/payment/CreatePlanPayment", createOrderPayload);
        if (createOrderPayment.data) {
            const createOrderRes = decryptData(createOrderPayment?.data)
            const createSubscription = await API.post("/admin/payment/CreateSubscription", {
                plan_id: createOrderRes.id,
            });
            options.subscription_id = createSubscription.data.id
            options.plan_id = createOrderRes.id; // Ensure the correct order_id is set
            options.amount = priceWithoutSymbol; // Amount in paisa

            var rzp1 = new window.Razorpay(options);
            await rzp1.open();
            rzp1.on('payment.failed', function (response) {
                console.error(response.error);
            });
        }
    } catch (error) {
        const res = decryptData(error?.response?.data)
    }
};
export default AdminPlanRazorpay;
