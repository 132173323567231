import React, { useEffect, useState } from 'react'
import { Dialog, Pagination } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import API from '../API';
import { NOPHOTO_IMAGE } from '../Assets/StaticData/StaticImage';
import { decryptData } from './encryptionUtils';
import { t } from 'i18next';

const SelectCustomer = ({ openCustomerOptions, closeCustomerModal, api, alreadySelectedCustomers, getSelectedCustomer }) => {
    const [selectedCustomers, setSelectedCustomers] = useState(alreadySelectedCustomers ? alreadySelectedCustomers : [])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [allCustomers, setAllCustomers] = useState([])
    const getCustomer = async () => {
        if (search?.length === 0 || search?.length >= 3) {
            try {
                const data = await API.post(`${api}?page=${page}&limit=15&searchkey=${search}&sort_by=&order_by=ASC`)
                const result = decryptData(data?.data)
                setAllCustomers(result?.data);
            } catch (error) {
                console.log(error);
            }
        }
    }
    const handleSelectCustomer = (id, name) => {
        if (id) {
            const cat = selectedCustomers?.length > 0 && selectedCustomers?.find((x) => x?._id === id);
            if (!cat) {
                setSelectedCustomers([...selectedCustomers, { _id: id, name: name }]);
            } else {
                const removeSelected = selectedCustomers?.filter((x) => x?._id !== id)
                setSelectedCustomers(removeSelected)
            }
        }
    }
    useEffect(() => {
        getCustomer();
    }, [search, page]);
    return (
        <Dialog
            open={openCustomerOptions}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeCustomerModal}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='secondary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
                <div className='flex border-b main-border-color pb-3 items-center justify-between'>
                    <h6 className='font-medium text-xl'>Select Customer</h6>
                    <button className='close-btn' onClick={closeCustomerModal}><X className="w-4 h-4" /></button>
                </div>
                <input value={search} onChange={(e) => setSearch(e.target.value)} className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 bg-transparent text-sm' placeholder='Search customers..' />

                <div className='h-[450px] overflow-y-auto divide-y'>
                    {allCustomers && allCustomers?.records?.length > 0 ? allCustomers?.records?.map((item, index) => {
                        return (
                            <label key={index} className='flex main-border-color items-center py-2 space-x-3'>
                                <input
                                    type="checkbox"
                                    checked={selectedCustomers?.length > 0 && selectedCustomers?.find((x) => x._id === item?._id) !== undefined}
                                    value={item?._id}
                                    onChange={() => handleSelectCustomer(item._id, item.name)}
                                />
                                <div>
                                    <p className='text-sm font-medium'>{item?.name}</p>
                                    <span className='text-sm'>{item?.email}</span>
                                </div>
                            </label>
                        )
                    }
                    ) :
                    <div className='w-full flex items-center justify-center border px-2 py-2'>
                            {t('lang_Data_not_found')}
                    </div>
                    }
                </div>
                <div className='flex justify-between pagination items-center flex-wrap'>
                    <button className={`flex px-5 py-2 btn rounded-s text-sm items-center text-white transition duration-300 ${selectedCustomers?.length <= 0 && 'inline-block cursor-not-allowed opacity-50 bg-btn'}`} disabled={selectedCustomers?.length <= 0} onClick={(e) => { e.preventDefault(); getSelectedCustomer(selectedCustomers); closeCustomerModal() }}>Add customer</button>
                    <Pagination
                        count={allCustomers?.totalPages}
                        page={page}
                        onChange={(e, v) => setPage(v)}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default SelectCustomer