import { Autocomplete, Dialog, TextField } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ImageUploader from '../../controllers/ImageUploader';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
const AddPortfolioComments = ({
  commentRef,
  isAddComment,
  closeAddComment,
  type,
  editCommentData,
}) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [commentData, setCommentData] = useState({
    name: '',
    profile_picture: '',
    comment: '',
    portfolioId: '',
  });
  useEffect(() => {
    if (editCommentData) {
      setCommentData({
        name: editCommentData?.name,
        profile_picture: editCommentData?.profile_picture,
        comment: editCommentData?.comment,
        portfolioId: editCommentData?.portfolioId?._id,
      });
    }
  }, [editCommentData]);
  // image upload logic
  const handleSetImage = (value) => {
    setCommentData({ ...commentData, profile_picture: value });
  };
  // get all portfolios
  const [allPortfolios, setAllPortfolios] = useState([]);
  const GetPortfolios = async () => {
    try {
      const data = await API.get(`/admin/portfolio/getPortfolios?is_draft=false`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setAllPortfolios(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetPortfolios();
  }, []);
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const handleAddPortfolioComment = async (e) => {
    e.preventDefault();
    if (type === 'edit') {
      try {
        const payload = encryptData(commentData);
        await API.put(`/admin/portfolio/UpdatePortfolioComment/${editCommentData?._id}`, payload);
        closeAddComment('call api');
        setIsNotification(true);
        setNotificationMsg('portfolio comment updated successfully');
        setSeverity('success');
        setCommentData({
          name: '',
          profile_picture: '',
          comment: '',
          portfolioId: '',
        });
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const payload = encryptData(commentData);
        const apiCall = await API.post(`/admin/portfolio/AddAdminComment`, payload);
        closeAddComment('call api');
        setCommentData({
          name: '',
          profile_picture: '',
          comment: '',
          portfolioId: '',
        });
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <Dialog
        open={isAddComment}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          closeAddComment();
          setCommentData({
            name: '',
            profile_picture: '',
            comment: '',
            portfolioId: '',
          });
        }}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='h-auto secondary-bg-color overflow-y-auto p-5'>
          <div className='flex items-end justify-between w-full overflow-auto'>
            <h6 className='text-xl md:text-2xl font-semibold text-primary'>{`${
              type === 'edit' ? 'Update' : 'Add'
            } portfolio comment`}</h6>
            <button
              type='button'
              className='close-btn'
              onClick={(e) => {
                e.preventDefault();
                closeAddComment();
                setCommentData({
                  name: '',
                  profile_picture: '',
                  comment: '',
                  portfolioId: '',
                });
              }}
            >
              <X className='h-4 w-4' aria-hidden='true' />
            </button>
          </div>
          <hr className='my-4 main-border-color' />
          <div className='flex w-full md:w-[600px] justify-evenly'>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='space-y-4 w-full mx-auto'
            >
              <div className='secondary-bg-color group space-y-4'>
                {type === 'add' && (
                  <div className='w-full'>
                    <label htmlFor='title' className='capitalize'>
                      {t('lang_name')}
                      <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                      type='text'
                      name='title'
                      ref={commentRef}
                      value={commentData?.name}
                      onChange={(e) => setCommentData({ ...commentData, name: e.target.value })}
                      placeholder='e.g. John'
                    />
                  </div>
                )}
                {type === 'add' && (
                  <div className='w-full mb-4'>
                    <label htmlFor='title'>{t('lang_Profile_Picture')}</label>
                    <ImageUploader
                      alreadyImage={commentData?.profile_picture}
                      handleSetImage={handleSetImage}
                      folder='profile'
                      format='image'
                    />
                  </div>
                )}
                <div className='w-full'>
                  <label htmlFor='title' className='capitalize'>
                    {t('lang_comment')}
                    <span className='text-red-500 ms-1'>*</span>
                  </label>
                  <textarea
                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                    type='text'
                    name='title'
                    ref={commentRef}
                    value={commentData?.comment}
                    onChange={(e) => setCommentData({ ...commentData, comment: e.target.value })}
                    placeholder='Enter your comment here...'
                  />
                </div>
                {type === 'add' && (
                  <div className='mb-4'>
                    <label htmlFor='description'>
                      {t('lang_Select_Portfolio')}
                      <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <Autocomplete
                      className='autocomplete'
                      options={allPortfolios ? allPortfolios : []}
                      getOptionLabel={(portfolio) => portfolio.title}
                      value={allPortfolios?.find((x) => x._id === commentData?.portfolioId) || null}
                      onChange={(e, v) =>
                        v ? setCommentData({ ...commentData, portfolioId: v?._id }) : ''
                      }
                      noOptionsText={
                        allPortfolios?.length > 0 && !autocompleteSearch
                          ? 'Please enter at least 3 characters'
                          : 'No option found'
                      }
                      renderInput={(params) => (
                        <TextField
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          placeholder='Select Portfolio'
                          className='cursor-pointer'
                          value={params?.inputValue}
                          onChange={(e) => {
                            const newInputValue = e.target.value;
                            if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                              setAutocompleteSearch(newInputValue);
                            } else {
                              setAutocompleteSearch();
                            }
                          }}
                          {...params}
                        />
                      )}
                    />
                  </div>
                )}
                {commentData?.name === '' ||
                commentData?.portfolioId === '' ||
                commentData?.comment === '' ? (
                  <div className='w-full flex justify-end'>
                    <button
                      onClick={(e) => e.preventDefault()}
                      className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                    >
                      {type === 'add' ? 'Add portfolio Comment' : 'Update portfolio Comment'}
                    </button>
                  </div>
                ) : (
                  <div className='w-full flex justify-end'>
                    <button
                      onClick={(e) => handleAddPortfolioComment(e)}
                      className='btn text-white text-sm'
                    >
                      {type === 'add' ? 'Add portfolio Comment' : 'Update portfolio Comment'}
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default AddPortfolioComments;
