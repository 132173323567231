import React from 'react';
import { Skeleton } from '@mui/material';
import { t } from 'i18next';
import { CloudUpload,X } from 'react-bootstrap-icons';

const ProductModel = ({
  modelImageLoader,
  productModelImage,
  setProductModelImage,
  modelImageErr,
  HandleModelImage,
}) => {
  return (
    <div className='w-full xl:w-full mb-4'>
      <label htmlFor='product_name'>{t('lang_Product_3D_Model')}</label>
      {!productModelImage ? (
        <label
          htmlFor='dropzone-file'
          className={`${!productModelImage && !modelImageLoader && 'file-tag-hover'} flex items-center h-[200px] min-w-full rounded px-4`}
        >
          <label className='text-center w-full block font-medium text-sm cursor-pointer'>
            <input
              type='file'
              name='modelImage'
              accept='.glb, .gltf'
              onChange={(e) => HandleModelImage(e.target.files[0])}
              className='hidden'
            />
            <div className='w-full flex flex-col items-center gap-1'>
              {!productModelImage && !modelImageLoader && (
                <>
                 <CloudUpload className="w-9 inline-block mx-auto mb-1 h-9"/>
                  <p className='text-sm block font-medium'>
                    <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                  </p>
                  <div className='flex flex-wrap gap-1'>
                    <span className='tab focus-tab inline-block rounded-full'>{t('lang_GLB')}</span>
                    <span className='tab focus-tab inline-block rounded-full'>{t('lang_GLTF')}</span>
                  </div>
                </>
              )}
              {modelImageLoader === true && (
                <Skeleton variant='rectangular' className='min-w-full min-h-[200px] max-h-[200px]' width={120} height={200} />
              )}
            </div>
          </label>
          {modelImageErr && (
            <span className='text-sm bg-yellow-100 p-1 rounded-[6px]'>
              <b className='uppercase'>{t('lang_note')}: </b> {t('lang_File_Image_Details')}
            </span>
          )}
        </label>
      ) : (
        <div className='flex flex-wrap w-full h-full justify-center max-w-full my-5'>
          <model-viewer
            id='test'
            ar
            ar-modes='webxr scene-viewer quick-look'
            ar-scale='auto'
            camera-controls
            src={productModelImage}
            alt='A 3D product'
            shadow-intensity='1'
            touch-action='pan-y'
          >
            <button
              slot='ar-button'
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '4px',
                border: 'none',
                position: 'absolute',
                top: '16px',
                right: '16px',
              }}
            >
              {t('lang_Activate_AR')}
            </button>
          </model-viewer>
          <button
            onClick={(e) => {
              e.preventDefault();
              setProductModelImage('');
            }}
            className='top-0 rounded-tr right-0 z-30 w-[25px] h-[25px] flex items-center justify-center text-red-600 primary-bg-color border-s border-b main-border-color text-xs'
          >
            <X className='w-4 h-4' />
          </button>
        </div>
      )}
      {/* <p className='text-xs table-text font-normal'>Only .GLB or .GLTF File Are Supported</p> */}
    </div>
  );
};

export default ProductModel;
