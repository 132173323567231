import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const AddCategoryGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [categoryGroupTitle, setCategoryGroupTitle] = useState('');
  const [categoryData, setCategoryData] = useState({
    button_text: '',
    button_link: '/product',
    category_limit: 5,
    carousel_status: true,
    status: true,
    category_image_width: '',
    category_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
  });
  const [addCategory, setAddCategory] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddCategory = () => {
    setAddCategory(false);
  };
  const [selectedCategory, setSelectedCategory] = useState([]);
  const handleSelectCategory = (category, name, image) => {
    if (category) {
      const existCategory = selectedCategory.find((x) => x?.category === category);
      if (!existCategory) {
        setSelectedCategory([...selectedCategory, { category, name, image }]);
      } else {
        const removeSelected = selectedCategory.filter((x) => x?.category !== category);
        setSelectedCategory(removeSelected);
      }
    }
  };
  const [allCategory, setAllCategory] = useState([]);
  const [search, setSearch] = useState('');
  const GetCategories = async () => {
    try {
      const apiCall = await API.get(
        `/admin/category/GetCategoriesWithProductCount?searchkey=${search}`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllCategory(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addCategory === true && (search?.length === 0 || search?.length >= 3)) {
      GetCategories();
    }
  }, [addCategory, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddCategoryGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      let body = {
        category_section_title: categoryGroupTitle,
        button_text: categoryData?.button_text,
        button_link: categoryData?.button_link,
        category_limit: categoryData?.category_limit,
        carousel_status: categoryData?.carousel_status,
        status: categoryData?.status,
        carousel_speed_ms: categoryData?.carousel_speed_ms,
        loop_forever: categoryData?.loop_forever,
        auto_play: categoryData?.auto_play,
        pause_on_hover: categoryData?.pause_on_hover,
        categories: selectedCategory,
      };
      if (
        categoryData?.category_image_height?.length > 0 ||
        categoryData?.category_image_width?.length > 0
      ) {
        body = {
          ...body,
          category_image_width: categoryData?.category_image_width,
          category_image_height: categoryData?.category_image_height,
        };
      }
      const payload = encryptData(body)
      await API.post(`/admin/dynamicCategory/addCategoryGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/category-group',
        title: 'add category group',
      });
      navigate('/module-setting/category-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    if (
      selectedCategory?.length > 0 ||
      categoryData?.carousel_speed_ms?.length > 0 ||
      categoryData?.button_text?.length > 0 ||
      categoryGroupTitle?.length > 0 ||
      categoryData?.category_image_height?.length > 0 ||
      categoryData?.category_image_width?.length > 0 ||
      categoryData?.loop_forever === false ||
      categoryData?.status === false ||
      categoryData?.carousel_status === false ||
      categoryData?.auto_play === true ||
      categoryData?.category_limit !== 5 ||
      categoryData?.pause_on_hover === true ||
      categoryData?.button_link !== '/product'
    ) {
      setSaveChanges({
        backLink: '/module-setting/category-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add category group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/category-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add category group',
      });
    }
  }, [selectedCategory, categoryData, categoryGroupTitle]);
  const checkChanges = () => {
    if (
      selectedCategory?.length > 0 ||
      categoryData?.carousel_speed_ms?.length > 0 ||
      categoryData?.button_text?.length > 0 ||
      categoryGroupTitle?.length > 0 ||
      categoryData?.category_image_height?.length > 0 ||
      categoryData?.category_image_width?.length > 0 ||
      categoryData?.loop_forever === false ||
      categoryData?.status === false ||
      categoryData?.carousel_status === false ||
      categoryData?.auto_play === true ||
      categoryData?.category_limit !== 5 ||
      categoryData?.pause_on_hover === true ||
      categoryData?.button_link !== '/product'
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/category-group',
        title: 'add category group',
      });
    } else {
      navigate('/module-setting/category-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Category_Group'), path: '/module-setting/category-group' },
                {
                  name: t('lang_Add_New_Category_Group'),
                  path: '/blog-categories/add-category-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Category_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Category_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={categoryGroupTitle}
                onChange={(e) => setCategoryGroupTitle(e.target.value)}
                placeholder='Enter group title....'
              />
              {dataErr && categoryGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Category_Group_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, status: !categoryData?.status });
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Category_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={categoryData?.category_limit}
                    onChange={(e) =>
                      setCategoryData({ ...categoryData, category_limit: e.target.value })
                    }
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label className='capitalize'>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({
                        ...categoryData,
                        carousel_status: !categoryData?.carousel_status,
                      });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Loop')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({
                        ...categoryData,
                        loop_forever: !categoryData?.loop_forever,
                      });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Autoplay')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={categoryData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setCategoryData({ ...categoryData, auto_play: !categoryData?.auto_play });
                    }}
                  />
                </label>
              </div>
              {categoryData?.auto_play && (
                <div className='mb-4 flex items-center gap-2'>
                  <label>{t('lang_Pause_On_Hover')}</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={categoryData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setCategoryData({
                          ...categoryData,
                          pause_on_hover: !categoryData?.pause_on_hover,
                        });
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.carousel_speed_ms}
                    disabled={categoryData?.carousel_status === 'disabled'}
                    onChange={(e) =>
                      setCategoryData({ ...categoryData, carousel_speed_ms: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={categoryData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) =>
                    setCategoryData({ ...categoryData, button_text: e.target.value })
                  }
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={categoryData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) =>
                    setCategoryData({ ...categoryData, button_link: e.target.value })
                  }
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Category_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_width}
                    onChange={(e) =>
                      setCategoryData({ ...categoryData, category_image_width: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Category_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={categoryData?.category_image_height}
                    onChange={(e) =>
                      setCategoryData({ ...categoryData, category_image_height: e.target.value })
                    }
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Select_Categories_For_Show_In_This_Group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddCategory(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                {t('lang_Select_Categories')}
              </span>
            </div>
            {selectedCategory?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_category_name')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!addCategory &&
                      selectedCategory?.map((row) => {
                        return (
                          <tr>
                            <td className='table-image px-3 min-w-[80px]'>
                              <img
                                width={80}
                                height={50}
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={row.image || NOPHOTO_IMAGE}
                                className='p-1 inline-block max-w-full object-contain max-h-full'
                                alt={row?.name}
                              />
                            </td>
                            <td className='w-full px-3'>
                              <span
                                className='line-clamp-2'
                                dangerouslySetInnerHTML={{ __html: row.name || '-' }}
                              ></span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}

          </div>

        </form>
        <Dialog
          open={addCategory}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddCategory(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl text-primary'>{t('lang_Select_Categories')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddCategory(false);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search categories..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allCategory?.map((elem) => {
                return (
                  <label className='flex main-border-color table-text py-2 items-start justify-between'>
                    <div className='flex'>
                      <input
                        type='checkbox'
                        checked={selectedCategory?.some((x) => x.category === elem?._id)}
                        value={elem?._id}
                        onClick={(e) => {
                          handleSelectCategory(elem?._id, elem?.category_name, elem?.image);
                        }}
                        className='block mt-1 w-4 h-4 me-4'
                      />
                      <div className='w-[60px] h-[60px] p-1 me-2 border main-border-color flex items-center justify-center'>
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={elem?.image || NOPHOTO_IMAGE}
                          className='max-w-full max-h-full'
                          alt='category image'
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.category_name}</p>
                    </div>
                    <p className='me-2 text-base '>({elem?.product_count})</p>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddCategory()}>
              {t('lang_add_category')}
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Category_Group')}
            </Link>
            <div>
              {!categoryGroupTitle ||
                categoryGroupTitle === '' ||
                selectedCategory?.length === 0 ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Category_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddCategoryGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Category_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategoryGroup;
