import {
    ArrowLeft,
    ChevronDown,
    Edit,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { QuestionCircleFill, Trash, Trash2, X } from 'react-bootstrap-icons';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import SelectProduct from '../../controllers/SelectProduct';
import DataTable from 'react-data-table-component';
import Loader from '../Loader/Loader';
import { Tooltip } from '@mui/material';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

function AddSubscriptionPlan() {
    const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
    const navigate = useNavigate();
    const [warn, setwarn] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [interval, setInterval] = useState(1);
    const [period, setPeriod] = useState('weekly');
    const [title, setTitle] = useState('');
    const [discountType, setDiscountType] = useState('percentage')
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [percentage, setPercentage] = useState('');
    const [percentageWarn, setPercentageWarn] = useState(false);
    const [amount, setAmount] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [isSelectProduct, setIsSelectProduct] = useState(false);
    const [options, setOptions] = useState([])
    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState('');
    const closeSelectProduct = () => {
        setIsSelectProduct(false);
    };
    const getSelectedProduct = (value) => {
        setSelectedProduct(value);
    };
    const removeSelectProduct = (e, id) => {
        e.preventDefault();
        const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
        setSelectedProduct(filterSelectedProduct);
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    useEffect(() => {
        if (
            title?.length > 0 ||
            selectedProduct?.length > 0 ||
            options?.length > 0 ||
            discountType?.length > 0 ||
            percentage?.length > 0 ||
            amount?.length > 0
        ) {
            setSaveChanges({
                backLink: '/product-subscription',
                isUnsaveChanges: true,
                showChangesPopup: false,
                interval: 'add subscription plan',
            });
        } else {
            setSaveChanges({
                backLink: '/product-subscription',
                isUnsaveChanges: false,
                showChangesPopup: false,
                interval: 'add subscription plan',
            });
        }
    }, [title, selectedProduct]);
    const checkChanges = () => {
        if (
            title?.length > 0 ||
            selectedProduct?.length > 0 ||
            options?.length > 0 ||
            discountType?.length > 0 ||
            percentage?.length > 0 ||
            amount?.length > 0
        ) {
            setSaveChanges({
                isUnsaveChanges: true,
                showChangesPopup: true,
                backLink: '/product-subscription',
                interval: 'add subscription plan',
            });
        } else {
            navigate('/product-subscription');
        }
    };
    const AddOption = () => {
        if (isEdit) {
            const updatedOptions = options?.filter((elem, idx) => idx !== editIndex);
            updatedOptions.push({ interval: interval, period: period, discount: discountType === 'percentage' ? percentage : amount });
            setOptions(updatedOptions);
            setIsEdit(false);
            setAmount('');
            setPercentage('');
        } else {
            setOptions([...options, { interval: interval, period: period, discount: discountType === 'percentage' ? percentage : amount }])
            setAmount('');
            setPercentage('');
        }
    }
    const columns = [
        {
            name: t('lang_interval'),
            minWidth: '150px',
            cell: (row) => <p className='line-clamp-2'>{row.interval || '-'}</p>,
        },
        {
            name: 'Preiod',
            minWidth: '150px',
            cell: (row) => <p className='line-clamp-2'>{row.period || '-'}</p>,
        },
        {
            name: 'Discount',
            minWidth: '150px',
            cell: (row) => <p className='line-clamp-2'>{discountType === 'percentage' ? row.discount + '%' : row.discount || '-'}</p>,
        },
        {
            name: <span className='w-full block text-right capitalize'>{t('lang_action')}</span>,
            minWidth: '150px',
            cell: (row, index) => (
                <div className='space-x-3 w-full flex justify-end items-center'>
                    <button type='button' className='text-xs font-thin text-black-500 hover:text-black-600'>
                        <div>
                            <Tooltip title='Edit' arrow>
                                <button
                                    type='button'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setIsEdit(true);
                                        editOptions(row, index);
                                    }}
                                >
                                    <Edit className='w-[17px] h-[17px]' />
                                </button>
                            </Tooltip>
                        </div>
                    </button>
                    <button
                        type='button'
                        className='text-xs font-thin text-red-500 hover:text-red-600'
                        onClick={(e) => {
                            e.preventDefault();
                            if (isEdit) {
                                setIsEdit(false);
                            }
                            let arr = options?.filter((elem, idx) => idx !== index)
                            setOptions(arr)
                        }}
                    >
                        <div>
                            <Tooltip title='Delete' arrow>
                                <button type='button' className=''>
                                    <Trash className='w-[17px] h-[17px]' />
                                </button>
                            </Tooltip>
                        </div>
                    </button>
                </div>
            ),
        },
    ];
    const editOptions = (row, index) => {
        setEditIndex(index)
        setInterval(row?.interval);
        setPeriod(row?.period);
        discountType === 'percentage' ? setPercentage(row?.discount) : setAmount(row?.discount);
    }
    const handleSubmit = async () => {
        setBtnLoading(true);
        const body = {
            plan_name: title,
            discount_Type: discountType,
            plans: options,
            productIds: selectedProduct?.map((elem, idx) => elem.product)
        }
        try {
            const payload = encryptData(body)
            const res = await API.post('/admin/payment/addPlan', payload)
            const result = decryptData(res?.data)
            if (result?.data) {
                setIsNotification(true);
                setNotificationMsg('Product subscription plan added successfully!');
                setSeverity('success');
                setTitle('');
                setDiscountType('');
                setOptions([]);
                setSelectedProduct([]);
                navigate('/product-subscription');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
        finally {
            setBtnLoading(false);
        }
    }
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto pb-[130px]'>
                <div className='flex items-center pb-[30px] space-x-2'>
                    <button
                        type='button'
                        onClick={(e) => {
                            e.preventDefault();
                            checkChanges();
                        }}
                        className='back-arrow'
                    >
                        <ArrowLeft />
                    </button>
                    <div>
                        <Breadcrumbs
                            crumbs={[
                                { name: t('lang_Product_subscription'), path: '/product-subscription' },
                                { name: t('lang_add_new', { module: t('lang_Product_subscription') }), path: '/product-subscription/add-subscription-plan' },
                            ]}
                        />
                        <div className='flex items-end'>
                            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                            {t('lang_add_new', { module: t('lang_Product_subscription') })}
                            </h4>
                            <a
                                target='blank'
                                href='https://docs.shopeasy.ai/blog/adding-a-new-blog-post'
                                className='how-it-works'
                            >
                                <QuestionCircleFill className='me-2' />
                                {t('lang_how_to_use')}
                            </a>
                        </div>
                    </div>
                </div>
                <div className='flex justify-evenly'>
                    <form
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        method='POST'
                        className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
                    >
                        <div className='secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                            <div className='w-full'>
                                <label htmlFor='title' className='flex items-center justify-between'>
                                    <p className='text-[13px]'>
                                        {t('lang_Title')}
                                        <span className='text-red-500 ms-1'>*</span>
                                    </p>
                                </label>
                                <input
                                    className={
                                        warn
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                            : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                    }
                                    type='text'
                                    name='title'
                                    maxLength={60}
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                        setwarn(false);
                                    }}
                                    placeholder='e.g. Weekly Veggie'
                                />
                                {warn ? (
                                    <p className='text-red-500 text-xs'>{t('lang_Please_enter_plan_title')}</p>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <div className='w-full'>
                                <label htmlFor='product' className='flex items-center justify-between'>
                                    <p className='text-[13px]'>
                                        {t('lang_Product')}
                                        <span className='text-red-500 ms-1'>*</span>
                                    </p>
                                </label>
                                {selectedProduct?.length > 0 ? (
                                    <div className='max-h-[450px] overflow-y-auto divide-y'>
                                        {selectedProduct?.map((elem) => {
                                            return (
                                                <div className='flex items-center main-border-color py-2 space-x-3'>
                                                    <button
                                                        type='button'
                                                        onClick={(e) => removeSelectProduct(e, elem?.product)}
                                                        className='close-btn'
                                                    >
                                                        <X className='w-[14px] h-[14px] text-red-600' />
                                                    </button>
                                                    <div className='w-[50px] h-[50px] p-0.5 border border-gray-200 flex items-center justify-center'>
                                                        <img
                                                            onError={(e) => {
                                                                e.target.src = NOPHOTO_IMAGE;
                                                            }}
                                                            src={elem?.image || NOPHOTO_IMAGE}
                                                            className='max-w-full max-h-full'
                                                            alt={elem?.name}
                                                        />
                                                    </div>
                                                    <p className='text-sm'>{elem?.name}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className='flex items-center justify-center'>
                                        <button
                                            type='button'
                                            className='bulk-btn mt-3'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIsSelectProduct(true);
                                            }}
                                        >
                                            {t('lang_select')} {t('lang_products')}
                                        </button>
                                    </div>
                                )}
                                {selectedProduct?.length > 0 && (
                                    <>
                                        <div className='flex items-center border-t main-border-color justify-between pt-2'>
                                            <button
                                                type='button'
                                                className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setIsSelectProduct(true);
                                                }}
                                            >
                                                + {t('lang_select_more_product')}
                                            </button>
                                            <button
                                                type='button'
                                                className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setSelectedProduct([]);
                                                }}
                                            >
                                                <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                                                {t('lang_remove_all_product')}
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='w-full'>
                                <label htmlFor='Discount' className='flex items-center justify-between'>
                                    <p className='text-[13px]'>
                                        {t('lang_Discount_and_delivery')}
                                        <span className='text-red-500 ms-1'>*</span>
                                    </p>
                                </label>
                                <div className='flex px-4 py-1.5 items-center'>
                                    <input
                                        id='percentage'
                                        type='radio'
                                        name='Discount'
                                        checked={discountType === 'percentage'}
                                        className='me-2 w-4 h-4 inline-block text-black-600 primary-bg-color main-border-color'
                                        onChange={(e) => { setDiscountType("percentage"); setOptions([]); setAmount(''); setPercentage(''); }}
                                    />
                                    <p className='table-text font-medium text-sm'>{t('lang_Percentage_off')}</p>
                                </div>
                                <div className='flex px-4 py-1.5 items-center'>
                                    <input
                                        id='percentage'
                                        type='radio'
                                        name='Discount'
                                        checked={discountType === 'rupes'}
                                        className='me-2 w-4 h-4 inline-block text-black-600 primary-bg-color main-border-color'
                                        onChange={() => { setDiscountType("rupes"); setOptions([]); setAmount(''); setPercentage(''); }}
                                    />
                                    <p className='table-text font-medium text-sm'>{t('lang_Amount_off')}</p>
                                </div>
                                <div className='sm:grid-cols-1 grid md:grid-cols-3 '>
                                    <div className='p-2'>
                                        <label>{t('lang_Delivery_frequency')}</label>
                                        <input
                                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                            type='number'
                                            value={interval}
                                            onChange={(e) => setInterval(e.target.value)}
                                        />
                                    </div>
                                    <div className='p-2'>
                                        <label>{t('lang_Delivery_interval')}</label>
                                        <div className='relative'>
                                            <select
                                                className='w-full'
                                                placeholder='Select period'
                                                value={period}
                                                onChange={(e) => setPeriod(e.target.value)}
                                            >
                                                <option value='weekly'>{t('lang_Weekly')}</option>
                                                <option value='monthly'>{t('lang_Monthly')}</option>
                                                <option value='yearly'>{t('lang_Yearly')}</option>
                                            </select>
                                            <div className='select-arrow'></div>
                                        </div>
                                    </div>
                                    <div className='p-2'>
                                        {discountType === 'percentage' ?
                                            <>
                                                <label>{t('lang_Percentage_off')}</label>
                                                <input
                                                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                                    type='number'
                                                    value={percentage}
                                                    onChange={(e) => setPercentage(e.target.value)}
                                                    onBlur={(e) => {
                                                        if (e.target.value <= 0 || e.target.value > 90) {
                                                            setPercentage();
                                                            setPercentageWarn(true)
                                                        } else {
                                                            setPercentageWarn(false)
                                                        }
                                                    }}
                                                />
                                                {percentageWarn && <p className='text-red-500 text-xs'>{t('lang_Discount_percentage_validation')}</p>}
                                            </>
                                            :
                                            <>
                                                <label>{t('lang_Amount_off')}<span className='text-red-500 ms-1'>*</span></label>
                                                <input
                                                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                                    type='number'
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                                {interval === '' || period === '' || (amount === '' && percentage === '') ?
                                    <button
                                        className='flex cursor-not-allowed bulk-btn px-5 py-2 rounded-s text-sm items-center transition duration-300'
                                        type='button'
                                        disabled
                                    >
                                        {isEdit ? "Edit options" : "Add option"}
                                    </button>
                                    :
                                    <button
                                        className='flex bulk-btn rounded-s px-5 py-2.5 text-sm items-center transition duration-300'
                                        type='button'
                                        onClick={AddOption}
                                    >
                                        {isEdit ? "Edit options" : "Add option"}
                                    </button>
                                }
                                {options?.length > 0 && <>
                                    <hr className='my-4' />
                                    <div className='w-full transition-all duration-100 inline-block overflow-auto border main-border-color rounded-[6px] mt-2'>
                                        <DataTable
                                            columns={columns}
                                            data={options}
                                            selectableRowsHighlight
                                            highlightOnHover
                                            progressComponent={<Loader />}
                                        />
                                    </div>
                                </>}
                            </div>
                        </div>
                        <div className='flex items-center justify-between w-full'>
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    checkChanges();
                                }}
                                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                            >
                                <ArrowLeft className='w-3 mr-1' /> {t('lang_Go_to_product_subscription')}
                            </Link>
                            <div>
                                {title === '' || options?.length <= 0 || selectedProduct?.length <= 0 ? (
                                    <div className='flex opacity-50 cursor-not-allowed items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                                        <button
                                            className='flex cursor-not-allowed bg-btn px-5 py-2 rounded text-sm items-center text-white transition duration-300'
                                            type='button'
                                            disabled
                                        >
                                            {t('lang_Add_subcription_plan')}
                                        </button>
                                    </div>
                                ) : btnLoading ? (
                                    <button
                                        type='button'
                                        className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[124px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                                    >
                                        <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                                        <span> {t('lang_loading')}...</span>
                                    </button>
                                ) : (
                                    <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
                                        <button
                                            className='flex btn rounded-s px-5 py-2.5 text-sm items-center text-white transition duration-300'
                                            type='button'
                                            onClick={handleSubmit}
                                        >
                                            {t('lang_Add_subcription_plan')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {isSelectProduct && (
                <SelectProduct
                    isSelectProduct={isSelectProduct}
                    api={`/admin/product/GetProductsForCategory?`}
                    closeSelectProduct={closeSelectProduct}
                    getSelectedProduct={getSelectedProduct}
                    alreadySelected={selectedProduct}
                />
            )}
        </>
    );
}

export default AddSubscriptionPlan;
