import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  Pagination,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  ArrowLeft,
  ChevronDown,
  Copy,
  Edit,
  Filter,
  PlusCircle,
  Trash,
} from 'feather-icons-react/build/IconComponents';
import Trash2 from 'feather-icons-react/build/IconComponents/Trash2';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import {
  Box2Fill,
  BoxArrowRight,
  CaretDownFill,
  CaretUpFill,
  Star,
  StarFill,
} from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReactDragListView from 'react-drag-listview';

const ProductTable = ({ bulkEditProductLoader }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [Product, setAllProduct] = useState();
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(Number(initialLimit) || 10);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [filtercategory, setfiltercategory] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sortBy, setSortBy] = useState(location?.state?.accending ? location?.state?.field : '');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [openTab, setOpenTab] = useState(getQueryParams());
  const [clearRow, setClearRow] = useState(false);
  const [productId, setProductId] = useState(null);
  const [tableData, setTableData] = useState()
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = () => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    openTab === 'all' && GetAllProducts();
    openTab === 'publish' && GetPublishProducts();
    openTab === 'draft' && getDraftProducts();
  };
  const { planPermission, setPlanPermission } = useContext(PlanPermission);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [paginationRecord, setPaginationRecord] = useState({ startRecord: '', endRecord: '' });

  const [publishProductCount, setPublishProductCount] = useState();
  const [draftProductCount, setDraftProductCount] = useState();
  const [allProductCount, setAllProductCount] = useState('');
  const GetAllProducts = async () => {
    setDataLoading(true);
    try {
      const payload = encryptData({
        searchkey: search?.length > 2 ? search : '',
        page: Page,
        limit: limit,
        categories: filtercategory,
        sort_by: sortBy,
        order_by: orderBy.orderByValue,
      });
      const data = await API.post(`/admin/product/GetAllProductsTable`, payload);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        const totalRecords = result?.data?.totalRecords || 0;

        const formattedRecords = totalRecords.toString().padStart(2, '0');

        setAllProductCount(formattedRecords);
        if (openTab === 'all') {
          setPublishProductCount(result?.data?.publishedCount?.toString().padStart(2, '0'));
          setDraftProductCount(result?.data?.draftCount?.toString().padStart(2, '0'));
          setAllProduct(result);
          setPaginationRecord({
            startRecord: (result?.data?.currentPage - 1) * limit + 1 || '',
            endRecord:
              Math.min(result?.data?.currentPage * limit, result?.data?.totalRecords) || '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Products' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const GetPublishProducts = async () => {
    setDataLoading(true);
    try {
      const payload = encryptData({
        searchkey: search?.length > 2 ? search : '',
        page: Page,
        limit: limit,
        categories: filtercategory,
        sort_by: sortBy,
        order_by: orderBy.orderByValue,
      });
      const data = await API.post(`/admin/product/GetProductsTable`, payload);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        const totalRecords = result?.data?.totalRecords || 0;
        const formattedRecords = totalRecords.toString().padStart(2, '0');
        if (openTab === 'publish') {
          setAllProduct(result);
          setPaginationRecord({
            startRecord: (result?.data?.currentPage - 1) * limit + 1 || '',
            endRecord:
              Math.min(result?.data?.currentPage * limit, result?.data?.totalRecords) || '',
          });
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'product' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const getDraftProducts = async () => {
    setDataLoading(true);
    try {
      const payload = encryptData({
        searchkey: search?.length > 2 ? search : '',
        categories: filtercategory,
        sort_by: sortBy,
        order_by: orderBy.orderByValue,
      });
      const data = await API.post(
        `/admin/product/getDraftProducts?page=${Page}&limit=${limit}&key=sort_order`,
        payload,
      );
      const result = decryptData(data?.data);
      const totalRecords = result?.data?.totalRecords || 0;
      const formattedRecords = totalRecords.toString().padStart(2, '0');

      if (openTab === 'draft') {
        setAllProduct(result);
        setPaginationRecord({
          startRecord: (result?.data?.currentPage - 1) * limit + 1 || '',
          endRecord: Math.min(result?.data?.currentPage * limit, result?.data?.totalRecords) || '',
        });
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'product' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const GetAllCategories = async () => {
    try {
      const data = await API.get(`/admin/category/GetCategoriesName`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setCategories(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetAllCategories();
  }, []);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleRemove = async (id) => {
    const findId = Product?.data?.records?.find((x) => x?._id === selectedRowsId[0]);
    if (openTab === 'publish' || (openTab === 'all' && !findId?.is_draft)) {
      try {
        const payload = encryptData({
          id: selectedRowsId,
        });
        const data = await API.post(`/admin/product/deleteProduct`, payload);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Product Removed Successfully!');
          setSeverity('success');
          GetPublishProducts();
          GetAllProducts();
          setSelectedRowsId([]);
          setClearRow(!clearRow);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const payload = encryptData({
          id: selectedRowsId,
        });
        const data = await API.post(`/admin/product/deleteDraftProducts`, payload);
        if (data.status === 200 || data.status === 304) {
          setIsNotification(true);
          setNotificationMsg('Product Removed Successfully!');
          setSeverity('success');
          getDraftProducts();
          GetAllProducts();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    setConfirmationPopup(false);
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    if (search?.length < 2) {
      if (openTab === 'all') {
        GetAllProducts();
      } else if (openTab === 'publish') {
        GetPublishProducts();
      } else {
        getDraftProducts();
      }
    }
  }, [search, limit, filtercategory, Page, openTab, orderBy.orderByValue]);
  useEffect(() => {
    setPage(1);
  }, [openTab]);
  useEffect(() => {
    GetAllProducts();
  }, []);
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const handleFeatured = async (id, value) => {
    try {
      const payload = encryptData({
        featured_status: value,
      });
      const data = await API.put(`/admin/product/updateProduct/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        openTab === 'all' && GetAllProducts();
        openTab === 'publish' && GetPublishProducts();
        setIsNotification(true);
        setNotificationMsg(
          value === true ? 'Product is added in featured' : 'Product is removed from featured',
        );
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // Export Products

  const HandleExport = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({ product_id: selectedRowsId });
      const response = await API.post('/admin/product/exportProduct', payload, {
        responseType: 'blob', // Set the response type to blob for downloading files
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ProductList.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex:
          index === 0 && Page == 1
            ? 0
            : Page != 1
            ? Number(index) + Number(limit) * Number(Page - 1)
            : Number(index),
      });
      const apiCall = await API.put(`/admin/product/updateProduct/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handlePublishProduct = async (id, value, key) => {
    if (key === 'publish') {
      try {
        const payload = encryptData({
          published: value,
        });
        await API.put(`/admin/product/updateProduct/${id}`, payload);
        if (openTab === 'all') {
          GetAllProducts();
        } else {
          GetPublishProducts();
        }
        setIsNotification(true);
        setNotificationMsg(
          value === true ? 'Product is set to active' : 'Product is set to hidden',
        );
        setSeverity('success');
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    } else {
      try {
        const payload = encryptData({
          published: value,
        });
        await API.put(`/admin/product/updateDraftProducts/${id}`, payload);
        getDraftProducts();
        setIsNotification(true);
        setNotificationMsg(
          value === true ? 'Product is set to active' : 'Product is set to hidden',
        );
        setSeverity('success');
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleDublicate = async (slug) => {
    let data;
    try {
      let ApiCall = await API.get(`/admin/product/getParticularProduct/${slug}`);
      const result = decryptData(ApiCall?.data);
      data = result?.data;
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    if (data) {
      delete data.sku_id;
      delete data.client;
      delete data.createdAt;
      delete data.tenant;
      delete data.updatedAt;
      delete data.user;
      delete data._id;
      delete data.notify;
      let variantArray = [];
      for (const variant of data.variants) {
        delete variant._id;
        delete variant.createdAt;
        delete variant.client;
        delete variant.tenant;
        delete variant.updatedAt;
        delete variant.user;
        delete variant.sku_id;
        variantArray.push(variant);
      }
      let discountArray = [];
      for (const discount of data.product_discount) {
        delete discount._id;
        discountArray.push(discount);
      }
      data.variants = variantArray;
      data.product_discount = discountArray;
      data.isCopy = 1;
      const payload = encryptData(data);
      const addPro = await API.post('/admin/product/addProduct', payload);
      if (addPro.status === 200 || addPro.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Duplicate product added successfully');
        setSeverity('success');
        openTab === 'all' && GetAllProducts();
        openTab === 'publish' && GetPublishProducts();
        openTab === 'draft' && getDraftProducts();
      }
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(Product?.data?.records);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setAllProduct({ data: { ...Product?.data, records: items } });
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...Product?.data?.records];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setAllProduct({ data: { ...Product?.data, records: newItems } });
      sortProduct(toIndex, productId);
      setProductId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  // Drag And Drop Functionality
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = Product?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = Product?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const final = Product?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !final.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [...new Set([...prev, ...final.map((item) => item._id)])]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(Product?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const filterOptionsForCategory = (options, { inputValue }) => {
    const normalizedInputValue = inputValue.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options?.filter((option) => {
      const combinedFields = `${option?.lowercase_category?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();
  const updateQueryParams = (params) => {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.set('type', params);
    window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  };
  function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const initial = urlParams.get('type');
    return initial ? initial : 'all'; // Adjust for zero-based indexing
  }
  useEffect(() => {
    updateQueryParams(openTab);
  }, [openTab]);
  useEffect(() => {
    setAllProduct();
  }, []);

  const handleBulkEdit = (e) => {
    e.preventDefault();
    const queryString = selectedRowsId.map((id) => `id=${id}`).join('&');
    navigate(`/products/bulk-edit/products?${queryString}`);
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Product'
        subheading='Do you really want to delete this product from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleRemove}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}

      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex flex-wrap items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_products'), path: '/products' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_products')}
              </h4>
            </div>
          </div>
          <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
            <button
              className='flex btn rounded-s text-sm items-center font-medium  text-white transition duration-300'
              onClick={() => navigate('/products/add-products')}
            >
              <PlusCircle className='w-4 h-4 capitalize sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_add_new', { module: 'Product' })}
            </button>
            <button
              className='flex btn px-3 border-s border-gray-600 rounded-e py-[9px] items-center text-white transition duration-300'
              onClick={handleClick}
            >
              <ChevronDown className='w-[18px] h-[18px]' />
            </button>
            <div>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                className='text-sm'
              >
                <MenuItem
                  className='text-sm block'
                  onClick={() => navigate('/products/bulk-upload')}
                >
                  <Box2Fill className='me-2' /> {t('lang_bulk')} {t('lang_upload')}
                </MenuItem>
                <MenuItem
                  className='text-sm flex items-center'
                  onClick={() => navigate('/products/shopify-product-import')}
                >
                  <svg
                    version='1.1'
                    id='Layer_1'
                    xmlns='http://www.w3.org/2000/svg'
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    x='0px'
                    width={18}
                    height={18}
                    className='me-2'
                    y='0px'
                    viewBox='0 0 108.44 122.88'
                    style={{ enableBackground: 'new 0 0 108.44 122.88' }}
                    xmlSpace='preserve'
                  >
                    <style
                      type='text/css'
                      dangerouslySetInnerHTML={{
                        __html:
                          '.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#222222;} .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#484848;} .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}',
                      }}
                    />
                    <g>
                      <path
                        className='st0'
                        d='M94.98,23.66c-0.09-0.62-0.63-0.96-1.08-1c-0.45-0.04-9.19-0.17-9.19-0.17s-7.32-7.1-8.04-7.83 c-0.72-0.72-2.13-0.5-2.68-0.34c-0.01,0-1.37,0.43-3.68,1.14c-0.38-1.25-0.95-2.78-1.76-4.32c-2.6-4.97-6.42-7.6-11.03-7.61 c-0.01,0-0.01,0-0.02,0c-0.32,0-0.64,0.03-0.96,0.06c-0.14-0.16-0.27-0.32-0.42-0.48c-2.01-2.15-4.58-3.19-7.67-3.1 c-5.95,0.17-11.88,4.47-16.69,12.11c-3.38,5.37-5.96,12.12-6.69,17.35c-6.83,2.12-11.61,3.6-11.72,3.63 c-3.45,1.08-3.56,1.19-4.01,4.44C9.03,39.99,0,109.8,0,109.8l75.65,13.08l32.79-8.15C108.44,114.73,95.06,24.28,94.98,23.66 L94.98,23.66z M66.52,16.63c-1.74,0.54-3.72,1.15-5.87,1.82c-0.04-3.01-0.4-7.21-1.81-10.83C63.36,8.47,65.58,13.58,66.52,16.63 L66.52,16.63z M56.69,19.68c-3.96,1.23-8.29,2.57-12.63,3.91c1.22-4.67,3.54-9.33,6.38-12.38c1.06-1.14,2.54-2.4,4.29-3.12 C56.38,11.52,56.73,16.39,56.69,19.68L56.69,19.68z M48.58,3.97c1.4-0.03,2.57,0.28,3.58,0.94C50.55,5.74,49,6.94,47.54,8.5 c-3.78,4.06-6.68,10.35-7.83,16.43c-3.6,1.11-7.13,2.21-10.37,3.21C31.38,18.58,39.4,4.23,48.58,3.97L48.58,3.97z'
                      />
                      <path
                        className='st1'
                        d='M93.9,22.66c-0.45-0.04-9.19-0.17-9.19-0.17s-7.32-7.1-8.04-7.83c-0.27-0.27-0.63-0.41-1.02-0.47l0,108.68 l32.78-8.15c0,0-13.38-90.44-13.46-91.06C94.9,23.04,94.35,22.7,93.9,22.66L93.9,22.66z'
                      />
                      <path
                        className='st2'
                        d='M57.48,39.52l-3.81,14.25c0,0-4.25-1.93-9.28-1.62c-7.38,0.47-7.46,5.12-7.39,6.29 c0.4,6.37,17.16,7.76,18.11,22.69c0.74,11.74-6.23,19.77-16.27,20.41c-12.05,0.76-18.69-6.35-18.69-6.35l2.55-10.86 c0,0,6.68,5.04,12.02,4.7c3.49-0.22,4.74-3.06,4.61-5.07c-0.52-8.31-14.18-7.82-15.04-21.48c-0.73-11.49,6.82-23.14,23.48-24.19 C54.2,37.88,57.48,39.52,57.48,39.52L57.48,39.52z'
                      />
                    </g>
                  </svg>
                  <span>
                    {t('lang_shopify')} {t('lang_import')}
                  </span>
                </MenuItem>
                <MenuItem
                  className='text-sm block'
                  onClick={() => navigate('/products/wordpress-product-import')}
                >
                  <>
                    <svg
                      version='1.1'
                      id='Layer_1'
                      width={18}
                      height={18}
                      className='me-2'
                      xmlns='http://www.w3.org/2000/svg'
                      xmlnsXlink='http://www.w3.org/1999/xlink'
                      x='0px'
                      fill='#222222'
                      y='0px'
                      viewBox='0 0 122.88 122.88'
                      style={{ enableBackground: 'new 0 0 122.88 122.88' }}
                      xmlSpace='preserve'
                    >
                      <style
                        type='text/css'
                        dangerouslySetInnerHTML={{ __html: '.st0{fill:#222222;}' }}
                      />
                      <g>
                        <path
                          className='st0'
                          d='M61.44,0C27.51,0,0,27.51,0,61.44c0,33.93,27.51,61.44,61.44,61.44c33.93,0,61.44-27.51,61.44-61.44 C122.88,27.51,95.37,0,61.44,0L61.44,0z M106.37,36.88c0.22,1.63,0.34,3.38,0.34,5.26c0,5.19-0.97,11.03-3.89,18.34l-15.64,45.21 c15.22-8.87,25.46-25.37,25.46-44.25C112.64,52.54,110.37,44.17,106.37,36.88L106.37,36.88z M62.34,65.92l-15.36,44.64 c4.59,1.35,9.44,2.09,14.46,2.09c5.96,0,11.68-1.03,17-2.9c-0.14-0.22-0.26-0.45-0.37-0.71L62.34,65.92L62.34,65.92z M96,58.86 c0-6.33-2.27-10.71-4.22-14.12c-2.6-4.22-5.03-7.79-5.03-12.01c0-4.71,3.57-9.09,8.6-9.09c0.23,0,0.44,0.03,0.66,0.04 c-9.11-8.35-21.25-13.44-34.57-13.44c-17.89,0-33.62,9.18-42.78,23.08c1.2,0.04,2.33,0.06,3.3,0.06c5.35,0,13.65-0.65,13.65-0.65 c2.76-0.16,3.08,3.89,0.33,4.22c0,0-2.77,0.32-5.86,0.49l18.64,55.46l11.21-33.6l-7.98-21.86c-2.76-0.16-5.37-0.49-5.37-0.49 c-2.76-0.16-2.44-4.38,0.32-4.22c0,0,8.45,0.65,13.48,0.65c5.35,0,13.65-0.65,13.65-0.65c2.76-0.16,3.08,3.89,0.33,4.22 c0,0-2.78,0.32-5.86,0.49L87,92.47l5.28-16.74C94.63,68.42,96,63.24,96,58.86L96,58.86z M10.24,61.44 c0,20.27,11.78,37.78,28.86,46.08L14.67,40.6C11.83,46.97,10.24,54.01,10.24,61.44L10.24,61.44z M61.44,3.69 c7.8,0,15.36,1.53,22.48,4.54c3.42,1.45,6.72,3.24,9.81,5.32c3.06,2.07,5.94,4.44,8.55,7.05c2.61,2.61,4.99,5.49,7.05,8.55 c2.09,3.09,3.88,6.39,5.32,9.81c3.01,7.12,4.54,14.68,4.54,22.48c0,7.8-1.53,15.36-4.54,22.48c-1.45,3.42-3.24,6.72-5.32,9.81 c-2.07,3.06-4.44,5.94-7.05,8.55c-2.61,2.61-5.49,4.99-8.55,7.05c-3.09,2.09-6.39,3.88-9.81,5.32c-7.12,3.01-14.68,4.54-22.48,4.54 c-7.8,0-15.36-1.53-22.48-4.54c-3.42-1.45-6.72-3.24-9.81-5.32c-3.06-2.07-5.94-4.44-8.55-7.05c-2.61-2.61-4.99-5.49-7.05-8.55 c-2.09-3.09-3.88-6.39-5.32-9.81C5.21,76.8,3.69,69.24,3.69,61.44c0-7.8,1.53-15.36,4.54-22.48c1.45-3.42,3.24-6.72,5.32-9.81 c2.07-3.06,4.44-5.94,7.05-8.55c2.61-2.61,5.49-4.99,8.55-7.05c3.09-2.09,6.39-3.88,9.81-5.32C46.08,5.21,53.64,3.69,61.44,3.69 L61.44,3.69z'
                        />
                      </g>
                    </svg>
                  </>
                  {t('lang_wordpress')} {t('lang_import')}
                </MenuItem>
              </Menu>
            </div>
          </div>
        </div>
        <div className='flex justify-between w-full space-x-4'>
          <div className='w-full space-y-3'>
            <div className='flex items-center justify-between'>
              {/* <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>
                        {t('lang_delete')} {t('lang_selection')}
                      </span>
                    </button>
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center disabled:cursor-not-allowed'
                      onClick={handleBulkEdit}
                      disabled={bulkEditProductLoader}
                    >
                      <Edit className='w-3.5 h-3.5' />
                      <span>
                        {t('lang_bulk')} {t('lang_edit')}
                      </span>
                    </button>
                    {openTab === 'publish' && (
                      <button
                        className='bulk-btn space-x-2 inline-flex items-center'
                        onClick={HandleExport}
                      >
                        <BoxArrowRight className='w-3.5 h-3.5' />
                        <span>
                          {t('lang_export')} {t('lang_selection')}
                        </span>
                      </button>
                    )}
                  </>
                )}
              </div> */}
              {/* <div
                className='filter-btn'
                onClick={(e) => {
                  setFilter(!filter);
                  setAnchorEl2(e.currentTarget);
                }}
              >
                <Tooltip title={t('lang_filter')} arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </div> */}
            </div>
            <div className={'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full'>
                <div className='flex justify-between'>
                  <div className='flex mb-2'>
                    {allProductCount > 0 && <div class='grid w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1'>
                      <a
                        className={`tab ${openTab === 'all' ? 'focus-tab' : 'bg-transparent'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('all');
                        }}
                        data-toggle='tab'
                        href='#link1'
                      >
                        {t('lang_all')}{' '}
                        {allProductCount > 0 && (
                          <span
                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                              openTab === 'all' ? "secondary-bg-color" : "primary-bg-color"
                            }`}
                          >
                            {allProductCount}
                          </span>
                        )}
                      </a>
                      <a
                        className={`tab ${openTab === 'publish' ? 'focus-tab' : 'bg-transparent'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('publish');
                        }}
                        data-toggle='tab'
                        href='#link1'
                      >
                        {t('lang_published')}
                        {publishProductCount > 0 && (
                          <span
                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                              openTab === 'publish' ? "secondary-bg-color" : "primary-bg-color"
                            }`}
                          >
                            {publishProductCount}
                          </span>
                        )}
                      </a>
                      {draftProductCount > 0 && (
                        <a
                          className={`tab ${openTab === 'draft' ? 'focus-tab' : 'bg-transparent'}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab('draft');
                          }}
                          data-toggle='tab'
                          href='#link1'
                        >
                          {t('lang_draft')}
                          {draftProductCount > 0 && (
                            <span
                              className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                                openTab === 'draft' ? "secondary-bg-color" : "primary-bg-color"
                              }`}
                            >
                              {draftProductCount}
                            </span>
                          )}
                        </a>
                      )}
                    </div>}
                    <div className='flex items-center'>
                      {selectedRowsId && selectedRowsId?.length > 0 && (
                        <>
                          <span className='text-sm px-3'>
                            {selectedRowsId?.length} {t('lang_selected')}
                          </span>
                          |
                          <button
                            className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmationPopup(true);
                            }}
                          >
                            <Trash2 className='w-3.5 h-3.5' />
                            <span>
                              {t('lang_delete')} {t('lang_selection')}
                            </span>
                          </button>
                          <button
                            className='bulk-btn mx-3 space-x-2 inline-flex items-center disabled:cursor-not-allowed'
                            onClick={handleBulkEdit}
                            disabled={bulkEditProductLoader}
                          >
                            <Edit className='w-3.5 h-3.5' />
                            <span>
                              {t('lang_bulk')} {t('lang_edit')}
                            </span>
                          </button>
                          {openTab === 'publish' && (
                            <button
                              className='bulk-btn space-x-2 inline-flex items-center'
                              onClick={HandleExport}
                            >
                              <BoxArrowRight className='w-3.5 h-3.5' />
                              <span>
                                {t('lang_export')} {t('lang_selection')}
                              </span>
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {allProductCount > 0 && <Tooltip title={t('lang_filter')} arrow>
                    <div
                      className='filter-btn'
                      onClick={(e) => {
                        setFilter(!filter);
                        setAnchorEl2(e.currentTarget);
                      }}
                    >
                        <button className=''>
                          <Filter className='h-5 w-5' />
                        </button>
                    </div>
                  </Tooltip>}
                </div>
                {dataLoading && !Product ? (
                  <TableSkeleton forTable='product' />
                ) : Product?.data?.records?.length > 0 ? (
                  <div className='w-full transition-all sticky-table duration-100 inline-block mb-1 overflow-x-auto overflow-y-hidden border main-border-color rounded-[6px]'>
                    <table className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === Product?.data?.records?.length}
                              indeterminate={
                                selectedRowsId?.length !== Product?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='thead-image min-w-[80px]'>{t('lang_image')}</td>
                          <td className='min-w-[250px] w-full px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'product_name' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('product_name');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('product_name')}
                              >
                                {t('lang_name')}
                                {iconVisible && sortBy === 'product_name' ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3'>{t('lang_type')}</td>
                          <td className='min-w-[150px] max-w-[150px] px-3'>{t('lang_Tag')}</td>
                          <td className='min-w-[180px] max-w-[180px] px-3 text-right'>
                            <div className='flex items-center justify-end w-full'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'selling_price' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('selling_price');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('selling_price')}
                              >
                                {iconVisible && sortBy === 'selling_price' ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_price')}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                            <div className='flex items-center w-full justify-end'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'quantity' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('quantity');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('quantity')}
                              >
                                {iconVisible && sortBy === 'quantity' ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_quantity')}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            {openTab !== 'draft' && `${t('lang_active')}`}
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <ReactDragListView {...dragProps}>
                          {Product?.data?.records?.map((row, index) => (
                            <tr
                              style={{
                                // boxShadow: snapshot.isDragging
                                //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                //   : 'none',
                                background: 'white',
                              }}
                              key={index}
                              onClick={() =>
                                row?.is_draft || openTab === 'draft'
                                  ? navigate(
                                      `/products/draft=${row?.draft_product_slug_name}`,
                                      { state: { row } },
                                    )
                                  : navigate(`/products/publish=${row?.product_slug_name}`, {
                                      state: { row },
                                    })
                              }
                              onDragStart={() => setProductId(row?._id)}
                              className={`${
                                selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                              } select-none flex w-full`}
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === row?._id)
                                  }
                                  className='inline-block'
                                  onChange={(e) => handleSelectRow(e, row?._id)}
                                />
                              </td>
                              <td className='table-image min-w-[80px]'>
                                <img
                                  className='p-1'
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  width={40}
                                  height={50}
                                  src={row.image || NOPHOTO_IMAGE}
                                  alt='Product image'
                                />
                              </td>
                              <td className='min-w-[250px] w-full px-3'>
                                <p className='line-clamp-2'>
                                  <>
                                    {row?.is_draft === true && openTab === 'all' && (
                                      <span className='inline-block uppercase text-xs text-black-600'>
                                        {t('lang_draft')} :
                                      </span>
                                    )}{' '}
                                    {row.product_name}
                                  </>
                                </p>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3'>
                                <p className='line-clamp-2'>{row.product_type || '-'}</p>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3'>
                                {row?.marketplace ?<span className='relative capitalize inline-block px-4 py-1 font-medium rounded-md  text-black-500 bg-blue-100 leading-tight'>
                                    {t('lang_MarketPlace')}
                                </span> : "-"}
                              </td>
                              <td className='min-w-[180px] max-w-[180px] px-3 text-right'>
                                <span className='text-right w-full'>
                                  {FormatAmount(row?.selling_price)}
                                </span>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                                <span className='block w-full text-right'>
                                  {row.quantity >= 5 && row.quantity <= 10 ? (
                                    <span className='text-yellow-600 font-semibold'>
                                      {row.quantity}
                                    </span>
                                  ) : (row.quantity >= 0 && row.quantity <= 5) ||
                                    row?.quantity === null ? (
                                    <span className='text-red-600 font-semibold'>
                                      {row?.quantity === null ? 0 : row.quantity}
                                    </span>
                                  ) : (
                                    <span>{row.quantity}</span>
                                  )}
                                </span>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                {row?.product_publish_date &&
                                new Date(row?.product_publish_date) >= new Date() ? (
                                  <div>
                                    <span className='block table-text text-right mb-0.5 font-medium'>
                                      {t('lang_active')}
                                    </span>
                                    <p className='flex table-text text-xs items-center justify-end'>
                                      {formatDate(row?.product_publish_date)}
                                    </p>
                                  </div>
                                ) : (
                                  !row?.is_draft &&
                                  openTab !== 'draft' && (
                                    <label className='relative flex justify-end w-full'>
                                      <FormControlLabel
                                        checked={row.published}
                                        control={<MuiSwitch />}
                                        onChange={() => {
                                          handlePublishProduct(
                                            row?._id,
                                            !row?.published,
                                            'publish',
                                          );
                                        }}
                                      />
                                    </label>
                                  )
                                )}
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-3 w-full flex justify-end items-center'>
                                  {!row?.is_draft && (
                                    <>
                                      {row.featured_status === true ? (
                                        <Tooltip title={t('lang_featured')} arrow>
                                          <button>
                                            <StarFill
                                              className='w-5 h-5 text-yellow-500'
                                              onClick={() => handleFeatured(row._id, false)}
                                            />
                                          </button>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip title={t('lang_featured')} arrow>
                                          <button>
                                            <Star
                                              className='w-5 h-5 text-yellow-500'
                                              onClick={() => handleFeatured(row._id, true)}
                                            />
                                          </button>
                                        </Tooltip>
                                      )}
                                      <div className='text-xs font-thin  hover:text-gray-900'>
                                        <Tooltip title={t('lang_dublicate')} arrow>
                                          <button
                                            onClick={() => handleDublicate(row?.product_slug_name)}
                                          >
                                            <Copy className='w-[16px] h-[16px]  table-text' />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </>
                                  )}
                                  <div
                                    className='text-xs font-thin text-black-500 hover:text-black-600'
                                    onClick={() =>
                                      row?.is_draft || openTab === 'draft'
                                        ? navigate(
                                            `/products/draft=${row?.draft_product_slug_name}`,
                                            { state: { row } },
                                          )
                                        : navigate(`/products/publish=${row?.product_slug_name}`, {
                                            state: { row },
                                          })
                                    }
                                  >
                                    <Tooltip title={t('lang_edit')} arrow>
                                      <button className=''>
                                        <Edit className='w-[17px] h-[17px]' />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <div
                                    className='text-xs font-thin text-red-500 hover:text-red-600'
                                    onClick={() => {
                                      setSelectedRowsId([row?._id]);
                                      setConfirmationPopup(true);
                                    }}
                                  >
                                    <Tooltip title={t('lang_delete')} arrow>
                                      <button className=''>
                                        <Trash className='w-[17px] h-[17px]' />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </ReactDragListView>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {Product?.data?.totalPages > 1 && (
                  <div className='bg-white border border-gray-300 flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <div className='hidden sm:block'>
                      <p className='flex w-full  space-x-4'>
                        <span className='text-sm font-medium'>
                          {limit === 'all'
                            ? `${
                                openTab === 'all'
                                  ? allProductCount
                                  : openTab === 'publish'
                                  ? publishProductCount
                                  : draftProductCount
                              }` +
                              ' ' +
                              `${t('lang_records')}`
                            : `${paginationRecord?.startRecord} - ${
                                paginationRecord?.endRecord
                              } of ${
                                openTab === 'all'
                                  ? allProductCount
                                  : openTab === 'publish'
                                  ? publishProductCount
                                  : draftProductCount
                              } ${t('lang_records')}`}
                        </span>
                      </p>
                    </div>
                    <div>
                      {Product?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={Product?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl2}
                onClose={() => {
                  setFilter(false);
                  setAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px', marginTop: '10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                  <p className='flex font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5 table-text'>
                    <div>
                      <span className='text-sm capitalize font-medium mb-1 inline-block'>
                        {t('lang_search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full  block border secondary-bg-color main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                          placeholder='Search Products'
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm  table-text font-medium mb-1 inline-block'>
                        {t('lang_filter_by')} {t('lang_category')}
                      </span>
                      <Autocomplete
                        className='autocomplete'
                        options={categories?.records ? categories.records : []}
                        getOptionLabel={(category) => category.lowercase_category}
                        onChange={(e, v) => {
                          v ? setfiltercategory([v._id]) : setfiltercategory();
                          setPage(1);
                        }}
                        filterOptions={filterOptionsForCategory}
                        noOptionsText={
                          categories?.records?.length > 0 && !autocompleteSearch
                            ? t('lang_enter_three_characters')
                            : t('lang_no_option_found')
                        }
                        renderInput={(Categories) => (
                          <TextField
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            value={Categories?.inputValue}
                            onChange={(e) => {
                              const newInputValue = e.target.value;
                              if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                                setAutocompleteSearch(newInputValue);
                              } else {
                                setAutocompleteSearch();
                              }
                            }}
                            placeholder={`${t('lang_select')} ${t('lang_category')}`}
                            className='cursor-pointer table-text'
                            {...Categories}
                          />
                        )}
                      />
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                            setPage(1);
                          }}
                        >
                          {Product?.data?.totalRecords > 10 ? (
                            <>
                              <option value='10' className='table-text'>
                                10
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                          {Product?.data?.totalRecords > 20 ? (
                            <option value='20' className='table-text'>
                              20
                            </option>
                          ) : (
                            <></>
                          )}
                          {Product?.data?.totalRecords > 50 ? (
                            <option value='50' className='table-text'>
                              50
                            </option>
                          ) : (
                            <></>
                          )}
                          <option value='all' className='table-text capitalize'>
                            {t('lang_all')}
                          </option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductTable;
