import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import {
    getAuth,
    GoogleAuthProvider,
    FacebookAuthProvider,
  } from "firebase/auth";
import API from './API';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const auth = getAuth(app);

// Initialize Authentication Providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

// Export the necessary Firebase services and providers
export { auth, googleProvider, facebookProvider, messaging, getToken };

export const generateToken = async () => {
    try {
        const permission = await Notification?.requestPermission();
        if (permission === "granted") {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY })
            localStorage.setItem('fcmToken', token);
            await API.post('/admin/user/addFCM', {
                fcmToken: token
            })
        }
    } catch (error) {
        console.error(error);
    }
}
