import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import Chart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
const GoogleRealTime = () => {

  const [countryName, setCountryName] = useState([]);
  const [activeUser, setActiveUser] = useState([]);
  const isMounted = useRef(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const GetRealTimeChart = async () => {
    if (isMounted.current) {
      try {
        const ApiCall = await API.get(`/admin/googleanalytics/runRealtimeReport`);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const result = decryptData(ApiCall?.data)
          setCountryName(result?.responcedata?.map(row => row.dimensionValues[1].value));
          setActiveUser(result?.responcedata?.map(row => row.metricValues[0].value));
        }
      } catch (error) {
        setIsNotification(true)
        setNotificationMsg(error)
        setSeverity("error")
      }
    }
  };
  useEffect(() => {
    GetRealTimeChart();
    window.dispatchEvent(new Event("resize"));

    return () => {
      isMounted.current = false;
    };

  }, []);

  const options = {
    chart: {
      id: "apexchart-example",
      type: "line",
    },
    xaxis: {
      categories: countryName,
    },
    yaxis: {
      title: "Active Users",
    },
    dataLabels: {
      enabled: false,
    },
  };
  const series = [
    {
      name: "Active Users",
      data: activeUser,
    },
  ];
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: 'Dashboard', path: `/dashboard` },
              { name: 'Google real time report', path: '/googlerealtime' }
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Google_Real_Time_Report')}</h4>
          </div>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-1">
          <div className="secondary-bg-color border mb-4 main-border-color overflow-x-auto overflow-y-hidden">
            <h6 className="border-b main-border-color py-2 px-4 text-base font-medium">{t('lang_Real_Time_Report')}</h6>
            <div className="py-5 max-w-full overflow-x-auto">
              <Chart options={options} series={series} type="line" height={300} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GoogleRealTime;