import { Checkbox, FormControlLabel, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReactDragListView from 'react-drag-listview';

function MyBlogs() {
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [clearRow, setClearRow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [blogData, setBlogData] = useState();
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [allBlog, setAllBlog] = useState();
  const [publishBlog, setPublishBlog] = useState();
  const [draftBlog, setDraftBlog] = useState();
  const [openTab, setOpenTab] = useState(getQueryParams());
  const { setPlanPermission } = useContext(PlanPermission);
  const [blogId, setBlogId] = useState(null);

  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetBlogs();
  };

  var startRecord = (blogData?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(blogData?.data?.currentPage * limit, blogData?.data?.totalRecords);
  useEffect(() => {
    GetBlogs();
    setClearRow(!clearRow);
  }, [search, Page, limit, openTab]);
  const GetBlogs = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/blog/getBlogs?page=${Page}&limit=${limit}&searchkey=${
          search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}${openTab === 'draft' ? '&is_draft=true' : openTab === 'publish' ? '&is_draft=false' : ''}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        const totalRecords = result?.data?.totalRecords || 0;
        const formattedRecords = totalRecords.toString().padStart(2, '0');
        setBlogData(result);
        if(openTab === 'all') {
          setAllBlog(formattedRecords);
          setPublishBlog(result?.data?.publishCount.toString().padStart(2, '0'))
          setDraftBlog(result?.data?.draftCount.toString().padStart(2, '0'))
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'blog' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [openTab]);
  const handlePublishBlog = async (id, value) => {
    try {
      const payload = encryptData({
        published: value,
      });
      await API.put(`/admin/blog/updateBlog/${id}`, payload);
      GetBlogs();
      setIsNotification(true);
      setNotificationMsg(value === true ? 'Blog is set to active' : 'Blog is set to hidden');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex:
          index === 0 && Page == 1
            ? 0
            : Page != 1
            ? Number(index) + Number(limit) * Number(Page - 1)
            : Number(index),
        // newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/blog/updateBlog/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(blogData?.data?.records);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setBlogData({ data: { ...blogData?.data, records: items } });
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...blogData?.data?.records];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setBlogData({ data: { ...blogData?.data, records: newItems } });
      sortProduct(toIndex, blogId);
      setBlogId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const [selectedRowsId, setSelectedRowsId] = useState([]);
  //   Blog Crud

  const EditBlog = (elem) => {
    navigate(`/blog-post/${elem?.blog_slug_name}`);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const RemoveBlog = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/blog/deleteBlog`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetBlogs();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = blogData?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = blogData?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const final = blogData?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !final.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [...new Set([...prev, ...final.map((item) => item._id)])]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(blogData?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  const updateQueryParams = (params) => {
    const currentUrl = new URLSearchParams(window.location.search);
    currentUrl.set('type', params);
    window.history.pushState({}, '', window.location.pathname + '?' + currentUrl.toString());
  };
  function getQueryParams() {
    const urlParams = new URLSearchParams(window.location.search);
    const initial = urlParams.get('type');
    return initial ? initial : 'all'; // Adjust for zero-based indexing
  }
  useEffect(() => {
    updateQueryParams(openTab);
  }, [openTab]);
  return (
    <>
      <ConfirmationPopup
        heading='Delete Blog Post'
        subheading='Do you really want to delete this blog post from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={RemoveBlog}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto overflow-x-hidden pb-[64px]'>
        <div className='flex items-end justify-between pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Blog_Post'), path: '/blog-post' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Blog_Post')}
              </h4>
            </div>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/blog-post/add-blog-post')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_Add_New_Blog_Post')}
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between'>
              {/* <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>{t('lang_delete_selection')}</span>
                    </button>
                  </>
                )}
              </div> */}
              {/* <div
                className='filter-btn'
                onClick={(e) => {
                  setFilter(!filter);
                  setAnchorEl(e.currentTarget);
                }}
              >
                <Tooltip title='Filter' arrow>
                  <button className=''>
                    <Filter className='h-5 w-5' />
                  </button>
                </Tooltip>
              </div> */}
            </div>
            <div className={'flex w-full h-full overflow-hidden relative'}>
              <div className='w-full'>
                <div className='flex justify-between'>
                  <div className='flex mb-2'>
                    {allBlog > 0 && <div class='grid w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1'>
                      <a
                        className={`tab ${openTab === 'all' ? 'focus-tab' : 'bg-transparent'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('all');
                          updateQueryParams('all');
                        }}
                        data-toggle='tab'
                        href='#link1'
                      >
                        {t('lang_all')}
                          {allBlog > 0 && <span
                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                              openTab === 'all' ? "secondary-bg-color" : "primary-bg-color"
                            }`}
                          >
                            {allBlog}
                          </span>}
                      </a>
                      {publishBlog > 0 && <a
                        className={`tab ${openTab === 'publish' ? 'focus-tab' : 'bg-transparent'}`}
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('publish');
                          updateQueryParams('publish');
                        }}
                        data-toggle='tab'
                        href='#link1'
                      >
                        {t('lang_published')}
                        {publishBlog > 0 && (
                          <span
                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                              openTab === 'publish' ? "secondary-bg-color" : "primary-bg-color"
                            }`}
                          >
                            {publishBlog}
                          </span>
                        )}
                      </a>}
                      {draftBlog > 0 && (
                        <a
                          className={`tab ${openTab === 'draft' ? 'focus-tab' : 'bg-transparent'}`}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab('draft');
                            updateQueryParams('draft');
                          }}
                          data-toggle='tab'
                          href='#link1'
                        >
                          {t('lang_draft')}
                          {draftBlog > 0 && (
                            <span
                              className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                                openTab === 'draft' ? "secondary-bg-color" : "primary-bg-color"
                              }`}
                            >
                              {draftBlog}
                            </span>
                          )}
                        </a>
                      )}
                    </div>}
                    <div className='flex items-center'>
                      {selectedRowsId && selectedRowsId?.length > 0 && (
                        <>
                          <span className='text-sm px-3'>
                            {selectedRowsId?.length} {t('lang_selected')}
                          </span>
                          |
                          <button
                            className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                            onClick={(e) => {
                              e.preventDefault();
                              setConfirmationPopup(true);
                            }}
                          >
                            <Trash2 className='w-3.5 h-3.5' />
                            <span>{t('lang_delete_selection')}</span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  {allBlog > 0 && <Tooltip title='Filter' arrow>
                    <div
                      className='filter-btn'
                      onClick={(e) => {
                        setFilter(!filter);
                        setAnchorEl(e.currentTarget);
                      }}
                    >
                        <button className=''>
                          <Filter className='h-5 w-5' />
                        </button>
                    </div>
                  </Tooltip>}
                </div>
                {dataLoading && !blogData ? (
                  <TableSkeleton forTable='blogs' />
                ) : blogData?.data?.records?.length > 0 ? (
                  <div className='w-full  transition-all duration-100 mb-1 blog-table inline-block h-auto me-4 border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === blogData?.data?.records?.length}
                              indeterminate={
                                selectedRowsId?.length !== blogData?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='thead-image min-w-[80px]'>{t('lang_image')}</td>
                          <td className='min-w-[300px]'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'title' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('title');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('title')}
                              >
                                {t('lang_Blog_Title')}
                                {iconVisible && sortBy === `title` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='w-full px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'blogcategory' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('blogcategory');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('blogcategory')}
                              >
                                {t('lang_Blog_Category_Name')}
                                {iconVisible && sortBy === `blogcategory` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td
                            className={`w-[${
                              openTab === 'publish' ? '150px' : '0px'
                            }] px-3 text-right`}
                          >
                            <span className='w-full block text-right'>
                              {openTab === 'publish' ? 'Active' : ''}
                            </span>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <ReactDragListView {...dragProps}>
                          {blogData?.data?.records?.map((elem, index) => (
                            <tr
                              style={{
                                // boxShadow: snapshot.isDragging
                                //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                //   : 'none',
                                background: 'white',
                              }}
                              key={index}
                              onClick={() => EditBlog(elem)}
                              onDragStart={() => setBlogId(elem?._id)}
                              className={`${
                                selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                              } select-none flex w-full`}
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, elem?._id)}
                                />
                              </td>
                              <td className='table-image min-w-[80px]'>
                                <img
                                  width={40}
                                  height={50}
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem.image || NOPHOTO_IMAGE}
                                  className='p-1 max-h-full'
                                  alt={elem?.title}
                                />
                              </td>
                              <td className='min-w-[300px] max-w-[300px]'>
                                <p className='line-clamp-2 text-xs'>{elem.title || '-'}</p>
                              </td>
                              <td className='w-full px-3'>
                                <div className='flex items-center'>
                                  {elem?.blogcategory?.map((item, index) => {
                                    return (
                                      <span
                                        key={index}
                                        className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                                      >
                                        <span className='line-clamp-1 table-text'>
                                          {item?.blogcategory_name}
                                        </span>
                                      </span>
                                    );
                                  })}
                                </div>
                              </td>
                              <td className='px-3 min-w-[150px] max-w-[150px] text-right' onClick={(e) => e.stopPropagation()}>
                                {openTab === 'publish' ? (
                                  elem?.blog_publish_date &&
                                  new Date(elem?.blog_publish_date) >= new Date() ? (
                                    <div>
                                      <span className='block table-text text-right mb-0.5 font-medium'>
                                        {t('lang_active')}
                                      </span>
                                      <p className='flex table-text text-xs items-center justify-end'>
                                        {formatDate(elem?.blog_publish_date)}
                                      </p>
                                    </div>
                                  ) : (
                                    <label className='relative flex justify-end w-full'>
                                      <FormControlLabel
                                        checked={elem.published === true}
                                        control={<MuiSwitch />}
                                        onChange={() => {
                                          handlePublishBlog(elem?._id, !elem?.published);
                                        }}
                                      />
                                    </label>
                                  )
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td className='px-3 min-w-[120px] max-w-[120px] text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='flex items-center space-x-2 justify-end w-full'>
                                  <Tooltip title='Edit' arrow>
                                    <button className=''>
                                      <Edit
                                        className='w-[17px] h-[17px] text-black-500'
                                        onClick={() => EditBlog(elem)}
                                      />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title='Delete' arrow>
                                    <button className=''>
                                      <Trash
                                        className='w-[17px] h-[17px] text-red-500'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setConfirmationPopup(true);
                                          setSelectedRowsId([elem?._id]);
                                        }}
                                      />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </ReactDragListView>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {blogData?.data ? (
                  <div className='secondary-bg-color flex border main-border-color flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${blogData?.data?.totalRecords}` + ' ' + 'Records'
                        : `${startRecord} - ${endRecord} of ${blogData?.data?.totalRecords} Records`}
                    </span>
                    <div>
                      {blogData.data && blogData?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={blogData?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => {
                  setFilter(false);
                  setAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px', marginTop: '10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                  <p className='flex table-text font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5'>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_Search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full placeholder block secondary-bg-color border main-border-color rounded-[6px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                          placeholder='Search Blogs'
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          }}
                        >
                          {blogData?.data?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {blogData?.data?.totalRecords > 20 ? (
                            <option value='20'>20</option>
                          ) : (
                            <></>
                          )}
                          {blogData?.data?.totalRecords > 50 ? (
                            <option value='50'>50</option>
                          ) : (
                            <></>
                          )}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyBlogs;
