import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoDataFound from '../Main/NoDataFound';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const BlogCategories = () => {
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [BlogCategory, setAllBlogCategory] = useState();
  const [Page, setPage] = useState(getInitialPage());
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
  const [isNotification, setIsNotification] = useState(false);
  const [clearRow, setClearRow] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  var startRecord = (BlogCategory?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    BlogCategory?.data?.currentPage * limit,
    BlogCategory?.data?.totalRecords,
  );
  // Sorting Data
  const SortData = (sort_by) => {
    setSortBy(sort_by);
    setIconVisible(true);
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
  };
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);

  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetAllBlogCategories();
    }
  }, [search]);

  const GetAllBlogCategories = async () => {
    setDataLoading(true);
    try {
      const GetCategories = await API.get(
        `/admin/blogcategory/GetBlogCategoriesWithParent?page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (GetCategories.status === 200 || GetCategories.status === 304) {
        const result = decryptData(GetCategories?.data)
        setAllBlogCategory(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Blog' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetAllBlogCategories();
    setClearRow(!clearRow);
  }, [Page, limit, orderBy, sortBy]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);

  // Api call For delete  Product
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/blogcategory/deleteBlogCategory`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Blog Category Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetAllBlogCategories();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = BlogCategory?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = BlogCategory?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = BlogCategory?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => (!item.default ? item._id : ''))]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(BlogCategory?.data?.records?.map((x) => (!x?.default ? x?._id : '')));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Blog Category'
        subheading='Do you really want to delete this blog category from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[64px]'>
          <div className='flex items-end justify-between w-full overflow-auto pb-[30px]'>
            <div className='flex items-center space-x-2'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/dashboard');
                }}
                className='back-arrow'
              >
                <ArrowLeft />
              </button>
              <div>
                <Breadcrumbs
                  crumbs={[
                    { name: t('lang_dashboard'), path: `/dashboard` },
                    { name: t('lang_Blog_Categories'), path: '/blog-categories' },
                  ]}
                />
                <div className='flex items-end'>
                  <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                    {t('lang_Blog_Categories')}{' '}
                  </h4>
                </div>
              </div>
            </div>
            <div></div>
            <div className='flex items-center'>
              <button
                className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
                onClick={() => navigate('/blog-categories/add-blog-category')}
              >
                <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />

                {t('lang_Add_Blog_Category')}
              </button>
            </div>
          </div>
          <div className='w-full h-full'>
            <div className='flex justify-between space-x-4 w-full relative'>
              <div className='w-full space-y-2'>
                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    {selectedRowsId && selectedRowsId?.length > 0 && (
                      <>
                        <span className='text-sm px-3'>{selectedRowsId?.length} Selected</span>|
                        <button
                          className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                          onClick={(e) => {
                            e.preventDefault();
                            setConfirmationPopup(true);
                          }}
                        >
                          <Trash2 className='w-3.5 h-3.5' />
                          <span>{t('lang_delete_selection')}</span>
                        </button>
                      </>
                    )}
                  </div>
                    {BlogCategory?.data?.totalRecords > 0 && <Tooltip title='Filter' arrow>
                      <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                        <button>
                          <Filter className='w-5 h-5' />
                        </button>
                      </div>
                    </Tooltip>}
                </div>
                <div className='flex w-full'>
                  <div className='w-full'>
                    {dataLoading && !BlogCategory ? (
                      <TableSkeleton forTable='order' />
                    ) : BlogCategory?.data?.records?.length > 0 ? (
                      <div className='w-full  transition-all duration-100 mb-1 blog-table inline-block h-auto border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                        <table className='w-full'>
                          <thead>
                            <tr>
                              <td className='px-3 table-head-checkbox'>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length === BlogCategory?.data?.records?.length
                                  }
                                  indeterminate={
                                    selectedRowsId?.length !==
                                    BlogCategory?.data?.records?.length &&
                                    selectedRowsId?.length > 0
                                  }
                                  onChange={(e) => {
                                    handleSelectRow(e, 'all');
                                  }}
                                />
                              </td>
                              <td className='min-w-[280px] max-w-[280px] px-3'>
                                <div className='flex items-center'>
                                  <button
                                    className={`uppercase flex ${iconVisible && sortBy === 'parent_category'
                                      ? 'text-gray-700'
                                      : ''
                                      }`}
                                    onMouseEnter={() => {
                                      setIconVisible(true);
                                    }}
                                    onMouseLeave={() => {
                                      setIconVisible(false);
                                    }}
                                    onClick={() => {
                                      setSortBy('parent_category');
                                      SortData('parent_category');
                                    }}
                                  >
                                    {t('lang_Blog_Category')}
                                    {iconVisible && sortBy === 'parent_category' ? (
                                      !orderBy.isDecending ? (
                                        <CaretUpFill className='ms-2' />
                                      ) : (
                                        <CaretDownFill className='ms-2' />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </button>
                                </div>
                              </td>
                              <td className='w-full px-3 uppercase'>{t('lang_description')}</td>
                              <td className='w-[120px] px-3 text-right uppercase'>
                                {t('lang_action')}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {BlogCategory?.data?.records?.map((elem, index) => {
                              return (
                                <tr
                                  key={index}
                                  className={`${selectedRowsId?.find((x) => x === elem?._id)
                                    ? 'selected-row'
                                    : ''
                                    }`}
                                  onClick={() => navigate(`/blog-categories/${elem._id}`)}
                                >
                                  <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                      disabled={elem?.default}
                                      checked={
                                        selectedRowsId?.length > 0 &&
                                        selectedRowsId?.find((x) => x === elem?._id)
                                      }
                                      onChange={(e) => handleSelectRow(e, elem?._id)}
                                    />
                                  </td>
                                  <td className='min-w-[280px] max-w-[280px] px-3'>
                                    <span className='capitalize'>
                                      {elem.parent_category || '-'}
                                    </span>
                                  </td>
                                  <td className='w-full px-3'>
                                    <span className='capitalize line-clamp-2'>
                                      {elem?.description
                                        ? elem.description.replace(/<[^>]*>/g, ' ')
                                        : '-'}
                                    </span>
                                  </td>
                                  <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                    <div className='flex items-center w-full justify-end space-x-4'>
                                      <Tooltip title='Edit' arrow>
                                        <button
                                          onClick={() => navigate(`/blog-categories/${elem._id}`)}
                                          className='text-xs font-thin text-black-500 hover:text-black-600'
                                        >
                                          <Edit className='w-[17px] h-[17px]' />
                                        </button>
                                      </Tooltip>
                                      {elem?.default !== true ? (
                                        <Tooltip title='Delete' arrow>
                                          <button
                                            onClick={(e) => {
                                              {
                                                e.preventDefault();
                                                setConfirmationPopup(true);
                                                setSelectedRowsId([elem?._id]);
                                              }
                                            }}
                                            className='text-xs font-thin text-red-500 hover:text-red-600'
                                          >
                                            <Trash className='w-[17px] h-[17px]' />
                                          </button>
                                        </Tooltip>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <NoRecords />
                    )}
                    {/* Paginator */}
                    {BlogCategory?.data ? (
                      <div className='secondary-bg-color hidden border main-border-color sm:flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                        <div className='hidden sm:block'>
                          <p className='flex w-full  space-x-4'>
                            <span className='text-sm font-medium'>
                              {limit === 'all'
                                ? `${BlogCategory?.data?.totalRecords}` + ' ' + 'Records'
                                : `${startRecord} - ${endRecord} of ${BlogCategory?.data?.totalRecords} Records`}
                            </span>
                          </p>
                        </div>

                        <div>
                          {BlogCategory?.data && BlogCategory?.data?.totalPages !== 1 ? (
                            <Pagination
                              count={BlogCategory?.data?.totalPages}
                              page={Page}
                              onChange={(e, v) => setPage(v)}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <Popover
                    open={filter}
                    anchorEl={anchorEl}
                    onClose={() => { setFilter(false); setAnchorEl(null) }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                    }}
                    className='rounded-[6px]'
                  >
                    <div className='p-[20px] filter'>
                      <p className='flex items-center  text-primary font-semibold uppercase text-sm m-0'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='me-1 w-[18px] h-[18px]'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z'
                          />
                        </svg>

                        {t('lang_filter')}
                      </p>
                      <hr className='main-border-color my-3'></hr>
                      <div className='grid gap-y-5'>
                        <div>
                          <span className='text-sm table-text font-medium mb-1 inline-block'>
                            {t('lang_Search')}
                          </span>
                          <label className='relative block w-full'>
                            <input
                              className='w-full placeholder block secondary-bg-color border main-border-color rounded-[6px] py-1.5 pl-3 pr-12 focus:outline-none text-sm'
                              placeholder='Search Blog Categories'
                              type='text'
                              name='search'
                              value={search}
                              onChange={(e) => {
                                setPage(1);
                                setSearch(e.target.value);
                              }}
                            />
                          </label>
                        </div>
                        <div>
                          <span className='text-sm table-text font-medium mb-1 inline-block'>
                            {t('lang_set_items_limit')}
                          </span>
                          <div className='relative'>
                            <select
                              className='outline-none rounded-[6px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                              name='limit'
                              value={limit}
                              onChange={(e) => {
                                setPage(1);
                                e.target.value === 'all'
                                  ? setLimit('all')
                                  : setLimit(e.target.value);
                              }}
                            >
                              {BlogCategory?.data?.totalRecords > 10 ? (
                                <>
                                  <option value='10'>10</option>
                                </>
                              ) : (
                                <></>
                              )}
                              {BlogCategory?.data?.totalRecords > 20 ? (
                                <option value='20'>20</option>
                              ) : (
                                <></>
                              )}
                              {BlogCategory?.data?.totalRecords > 50 ? (
                                <option value='50'>50</option>
                              ) : (
                                <></>
                              )}
                              <option value='all'>All</option>
                            </select>
                            <div className='select-arrow'></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlogCategories;
