import { SwipeableDrawer } from '@mui/material';
import { ChevronRight } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { BellFill, CheckAll, X } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { decryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
import NotificationGroup from './NotificationGroup';

const GetClientNotification = ({ notify, closeNotification }) => {
  const [openTab, setOpenTab] = useState(1);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [notificationData, setNotificationData] = useState();
  const [spinner, setSpinner] = useState(false);
  const GetNotifications = async () => {
    setSpinner(true);
    try {
      if (openTab === 1) {
        let ApiCall = await API.get('/admin/notifications/getNotification?limit=10');
        const res = decryptData(ApiCall?.data)
        setNotificationData(res);
        setSpinner(false);
      } else {
        let ApiCall = await API.get('/admin/notifications/getNotification?read=1&limit=10');
        const res = decryptData(ApiCall?.data)
        setNotificationData(res);
        setSpinner(false);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetNotifications();
  }, [openTab]);
  const handleMarkRead = async () => {
    try {
      await API.post(`/admin/notifications/updateallNotification`);
      GetNotifications();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handlePerticularRead = async (id) => {
    try {
      await API.put(`/admin/notifications/updateNotification/${id}`);
      GetNotifications();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const getStartOfPeriod = (date, period) => {
    const d = moment(date);
    if (period === 'day') {
      return d.startOf('day');
    } else if (period === 'week') {
      return d.startOf('isoWeek');
    } else if (period === 'month') {
      return d.startOf('month');
    } else if (period === 'year') {
      return d.startOf('year');
    }
    return d;
  };
  const [storeNotification, setStoreNotification] = useState([
    { today: [] },
    { yesterday: [] },
    { thisWeek: [] },
    { thisMonth: [] },
    { thisYear: [] },
    { older: [] },
  ]);
  const groupNotificationsByDate = (records) => {
    const groups = {
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      thisYear: [],
      older: [],
    };

    const now = moment();
    const today = getStartOfPeriod(now, 'day');
    const yesterday = moment(today).subtract(1, 'days');
    const startOfWeek = getStartOfPeriod(now, 'week');
    const startOfMonth = getStartOfPeriod(now, 'month');
    const startOfYear = getStartOfPeriod(now, 'year');

    records?.forEach((record) => {
      const createdAt = moment(record.createdAt);
      if (createdAt.isSameOrAfter(today)) {
        groups.today.push(record);
      } else if (createdAt.isSameOrAfter(yesterday) && createdAt.isBefore(today)) {
        groups.yesterday.push(record);
      } else if (createdAt.isSameOrAfter(startOfWeek)) {
        groups.thisWeek.push(record);
      } else if (createdAt.isSameOrAfter(startOfMonth)) {
        groups.thisMonth.push(record);
      } else if (createdAt.isSameOrAfter(startOfYear)) {
        groups.thisYear.push(record);
      } else {
        groups.older.push(record);
      }
    });

    setStoreNotification(groups);
  };
  useEffect(() => {
    groupNotificationsByDate(notificationData?.notification?.records);
  }, [notificationData?.notification?.records]);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <SwipeableDrawer
        anchor='right'
        open={notify}
        onOpen={() => {
          closeNotification(true);
        }}
        onClose={() => {
          closeNotification(false);
        }}
        className='notification w-[350px] sm:w-[450px]'
      >
        <div className='h-screen secondary-bg-color w-[350px] sm:w-[450px] rounded-[6px] overflow-hidden'>
          <div className='flex items-center justify-between  border-b p-5 main-border-color text-lg font-semibold leading-4 text-gray-900'>
            <div className='flex items-center gap-2'>
              <BellFill className='w-[18px] h-[18px] table-text hover: ' />
              <p className='text-lg text-primary capitalize'>{t('lang_notifications')}</p>
            </div>
            <div className='flex'>
              <button
                aria-label='mincart'
                type='button'
                className='close-btn'
                onClick={() => {
                  closeNotification(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
          </div>
          <div className='flex items-center justify-between border-b main-border-color'>
            <div className='flex px-3 py-2 items-center w-full justify-between'>
              <ul className='flex justify-start mb-0 list-none flex-nowrap overflow-x-auto overflow-y-hidden md:overflow-hidden'>
                <a
                  aria-label='tab1'
                  className={`tab ${(openTab === 1 ? 'focus-tab' : 'bg-transparent')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle='tab'
                  href='#link1'
                >
                  {t('lang_all')}
                </a>
                <a
                  aria-label='tab1'
                  className={`tab ${(openTab === 2 ? 'focus-tab': 'bg-transparent')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle='tab'
                  href='#link1'
                >
                  {t('lang_unread')}
                </a>
              </ul>
              {notificationData?.notification?.records?.length > 0 && openTab === 2 && (
                <button
                  className='flex items-center me-5 text-sm text-link'
                  onClick={() => handleMarkRead()}
                >
                  <CheckAll className='me-1 w-4 h-4' />
                  {t('lang_Mark_All_As_Read')}
                </button>
              )}
            </div>
          </div>
          <div className='flex items-center w-full justify-between px-5 py-3 border-b main-border-color'>
            <h6 className='font-medium m-0 text-sm inline-block text-primary capitalize'>{t('lang_new')}</h6>
            <button
              className='flex items-center text-link text-sm font-medium justify-end w-[100px]'
              onClick={() => {
                openTab === 1
                  ? navigate('/notifications?type=all')
                  : navigate('/notifications?type=unread');
                closeNotification(false);
              }}
            >
              {t('lang_View_All')} <ChevronRight className='h-4 w-4' />
            </button>
          </div>
          <ul className='divide-y h-[82%] list-none overflow-y-auto w-full'>
            {spinner ? (
              <div className='w-full h-full flex justify-center'>
                <div className='data-loader my-4'></div>
              </div>
            ) : notificationData?.notification?.records?.length > 0 ? (
              <div className='p-5 space-y-3'>
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='Today'
                  notifications={storeNotification?.today}
                  handlePerticularRead={handlePerticularRead}
                />
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='Yesterday'
                  notifications={storeNotification?.yesterday}
                  handlePerticularRead={handlePerticularRead}
                />
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='This Week'
                  notifications={storeNotification?.thisWeek}
                  handlePerticularRead={handlePerticularRead}
                />
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='This Month'
                  notifications={storeNotification?.thisMonth}
                  handlePerticularRead={handlePerticularRead}
                />
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='This Year'
                  notifications={storeNotification?.thisYear}
                  handlePerticularRead={handlePerticularRead}
                />
                <NotificationGroup
                  closeNotification={closeNotification}
                  title='Older'
                  notifications={storeNotification?.older}
                  handlePerticularRead={handlePerticularRead}
                />
              </div>
            ) : (
              <p className=' text-center w-full p-10 text-base'>{t('lang_No_new_notifications')}</p>
            )}
          </ul>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default GetClientNotification;
