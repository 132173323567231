import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  Dialog,
  Skeleton,
  TextField,
  Tooltip
} from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Code,
  Edit,
  Trash2,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { GenerateSlugName } from '../../controllers/GenerateSlugName';
import { clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import { Transition } from '../../controllers/Transition';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
import { BardSvgLight } from '../../Assets/StaticData/BardSvgLight';
const UpdateCategory = () => {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const params = useParams();
  const [nodata, setNodata] = useState(false);
  const [warn, setwarn] = useState(false);
  const [categoryData, setCategoryData] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [enableSlug, setEnableSlug] = useState(false);
  const [slugEdited, setSlugEdited] = useState(false);
  const [severity, setSeverity] = useState();
  const [CatData, setCatData] = useState({
    category_name: '',
    description: '',
    image: '',
    meta_tag_title: '',
    meta_tag_description: '',
    keyword: '',
  });
  const [selected, setSelected] = useState('');
  const [image, setimage] = useState();
  const [description, setDescription] = useState();
  const [slugName, setSlugName] = useState();
  const [formSpinner, setFormSpinner] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const clientInfo = clientData;
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/category`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  useEffect(() => {
    getMetaField();
  }, [])
  const getCategoryBySlugName = async () => {
    setFormSpinner(true);
    try {
      const apiCall = await API.get(`admin/category/getCategory/${params?.slugname}`);
      const result = decryptData(apiCall?.data)
      setCategoryData(result?.data);
      setCatData({
        category_name: `${result?.data?.category_name}`,
        description: `${result?.data?.description}`,
        image: `${result?.data?.image}`,
        meta_tag_title: `${result?.data?.meta_tag_title}`,
        meta_tag_description: `${result?.data?.meta_tag_description}`,
        keyword: `${result?.data?.keyword}`,
      });
      setSelected(result?.data?.parent_category?._id);
      setDescription(result?.data?.description);
      setimage(result?.data?.image);
      setSlugName(
        result?.data?.category_slug_name
          ? result?.data?.category_slug_name
          : result?.data?.category_slug_name,
      );
      setFormSpinner(false);
      handleGetSelectedProduct(result?.data?._id);
      setmeta_fields(result?.data?.custom_fields);
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getCategoryBySlugName();
  }, [params?.slugname]);

  const { category_name, meta_tag_title, meta_tag_description, keyword } = CatData;
  const [isChange, setIsChange] = useState(false);
  const HandleInput = (e) => {
    setIsChange(true);
    setCatData({ ...CatData, [e.target.name]: e.target.value });
  };

  // get Parents Category
  const [categoryWithParent, setAllCategoryWithParent] = useState([]);

  const GetCategoriesWithAllParent = async () => {
    try {
      if (categoryData) {
        const GetCategories = await API.get(
          `/admin/category/GetCategoriesWithAllParent?default=true`,
        );
        if (GetCategories.status === 200 || GetCategories.status === 304) {
          const result = decryptData(GetCategories?.data)
          const filterCategory = await result?.data?.records?.filter(
            (x) => x._id !== categoryData?._id,
          );
          setAllCategoryWithParent(filterCategory);
        }
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetCategoriesWithAllParent();
  }, [categoryData]);
  // image upload logic
  const [loader, setLoader] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setIsChange(true);
    setimage(value);
  };
  const [AiLoader, setAiloader] = useState(false);
  const [generateAiPopup, setGenareteAiPopup] = useState(false);
  const [gerenateAiData, setGenerateDataAi] = useState({
    tone: 'Expert',
    write_style: 'Descriptive',
    features: '',
  });
  const handleChangeAiData = (e) => {
    setGenerateDataAi({ ...gerenateAiData, [e.target.name]: e.target.value });
  };

  const generateDesc = async (gerenateAiData) => {
    try {
      setAiloader(true);
      const payload = encryptData({
        name: category_name,
        features: gerenateAiData?.features,
        write_style: gerenateAiData?.write_style,
        tone: gerenateAiData?.tone,
      })
      let ApiCall = await API.post('/admin/ai/short_description', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        let cleanedData = result?.data?.replace(/\n/g, '');
        setDescription(cleanedData);
      }
    } catch (error) {
      if (error?.response?.status === 429) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Hit_chatgpt_limit'));
        setSeverity('warning');
      } else {
        setIsNotification(true);
        setNotificationMsg(error?.response?.data?.message);
        setSeverity('error');
      }
    } finally {
      setAiloader(false);
    }
  };

  const handleSubmitAiData = (e) => {
    e.preventDefault();
    generateDesc(gerenateAiData);
    setGenareteAiPopup(false);
    setGenerateDataAi({
      tone: '',
      write_style: '',
      features: '',
    });
  };
  const handleGetSelectedProduct = async (id) => {
    try {
      let categoryProduct = await API.get(
        `/admin/product/GetProductsForCategory?categories=${id}&categories_exist=1`,
      );
      const result = decryptData(categoryProduct?.data)
      setSelectedProduct(
        result?.data?.records?.length > 0 &&
        result?.data?.records?.map((x) => {
          return { name: x?.product_name, product: x?._id, image: x?.image };
        }),
      );
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const [selectedRemove, setSelectedRemove] = useState([]);
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    setSelectedRemove([...selectedRemove, id]);
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  useEffect(() => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: false,
        backLink: '/categories',
        title: 'edit category',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/categories',
        title: 'edit category',
      });
    } else {
      navigate('/categories');
    }
  };
  const handleAddProduct = async (e) => {
    e.preventDefault();
    removeCategoryInProduct(e);
    try {
      const payload = encryptData({
        id: selectedProduct?.length > 0 ? selectedProduct?.map((x) => x?.product) : [],
        categories: categoryData?._id,
      })
      await API.post(`/admin/product/addCategoryInProduct`, payload);
    } catch (error) {
      console.log(error);
    }
  };
  const removeCategoryInProduct = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        categories: categoryData?._id,
        id: selectedRemove?.map((x) => x),
      })
      await API.post(`/admin/product/removeCategoryInProduct`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    handleAddProduct(e);
    setBtnLoading(true);
    try {
      let updatebody = {
        ...CatData,
        parent_category: selected,
        image: image,
        description: description,
        meta_tag_title,
        meta_tag_description,
        custom_fields: meta_fields,
      };

      if (slugEdited && slugName) {
        updatebody = {
          ...updatebody,
          category_slug_name: slugName,
        };
      }
      const payload = encryptData(updatebody)
      let SubmitData = await API.put(
        `/admin/category/updateCategory/${categoryData?._id}`,
        payload,
      );
      if (SubmitData.status === 200 || SubmitData.status === 304) {
        setErr(false);
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/categories',
          title: 'edit category',
        });
        navigate('/categories');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const generateMetaData = async (data, key) => {
    if (description?.length === 0) {
      setErr(true);
    } else {
      setAiloader(true);
      setErr(false);
      try {
        const payload = encryptData({
          name: data,
        })
        if (key === 'description') {
          let ApiCall = await API.post('/admin/ai/category_meta_description', payload);
          const result = decryptData(ApiCall?.data)
          setCatData({ ...CatData, meta_tag_description: result?.data });
        } else {
          let ApiCall = await API.post('/admin/ai/category_meta_title', payload);
          const result = decryptData(ApiCall?.data)
          setCatData({ ...CatData, meta_tag_title: result?.data });
        }
        setAiloader(false);
      } catch (error) {
        if (error?.response?.status === 429) {
          setIsNotification(true);
          setNotificationMsg(t('lang_Hit_chatgpt_limit'));
          setSeverity('warning');
        } else {
          setIsNotification(true);
          setNotificationMsg(error?.response?.data?.message);
          setSeverity('error');
        }
      } finally {
        setAiloader(false);
      }
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  // MUI Filter Option for category
  const filterOptionsForCategory = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    if (normalizedInputValue?.length < 3) {
      return []; // No options displayed until at least 3 characters are entered
    }
    return options.filter((option) => {
      const combinedFields = `${option?.parent_category?.toLowerCase()} ${option?.category_slug_name?.toLowerCase()}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const [autocompleteSearch, setAutocompleteSearch] = useState();

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
    setIsChange(true);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/product/GetProductsForCategory?`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      {AiLoader && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={AiLoader}
          className='flex flex-col'
        >
          <CircularProgress color='inherit' />
          <p>{t('lang_ai_generate_content_loader')}</p>
        </Backdrop>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_categories'), path: '/categories' },
                {
                  name: t('lang_update_module', { module: 'Category' }),
                  path: '/categories/edit-category',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_update_module', { module: 'Category' })} - {categoryData?.category_name}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          >
            {formSpinner ? (
              <div className='space-y-4'>
                <div className=' secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <div className=' secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div>
                    {t('lang_media')}
                    <p className='text-xs table-text font-normal'>{t('lang_media_details')}</p>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[130px]' variant='rounded-[6px]' />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className=' secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <div className='w-full mb-4'>
                    <label htmlFor='category_name' className='flex items-center'>
                      {t('lang_category_name')} <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className={
                        category_name === '' && err
                          ? 'w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none'
                          : 'w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      }
                      type='text'
                      id='category_name'
                      name='category_name'
                      value={category_name}
                      onChange={HandleInput}
                      placeholder='e.g. Tech'
                      onBlur={() => setCatData({ ...CatData, meta_tag_title: category_name })}
                    />
                    <div className='w-full flex justify-between'>
                      <p className='text-xs mt-0 table-text'>
                        {t('lang_recommend_character')} {`${category_name?.length} ${t('lang_characters')}`}
                      </p>
                    </div>
                    {warn && (
                      <p className='text-sm text-red-500'>
                        {t('lang_enter_name_for_generate_description')}
                      </p>
                    )}
                  </div>
                  <div className='w-full mb-4'>
                    <label htmlFor='category_slug_name'>
                      {t('lang_category')} {t('lang_slug')}
                    </label>
                    <div
                      className={`relative flex items-center justify-between w-full border ${err ? 'border-red-500' : 'main-border-color'
                        } rounded-[4px]`}
                    >
                      <input
                        className='w-full px-3 py-2 rounded-[6px] focus:outline-none'
                        type='text'
                        name='category_slug_name'
                        placeholder='Enter slug name'
                        value={slugName}
                        disabled={!enableSlug}
                        onChange={(e) => {
                          setSlugName(e.target.value);
                          setIsChange(true);
                        }}
                      />
                      <Tooltip
                        title={`${enableSlug ? t('lang_save') : t('lang_edit') }`}
                        arrow
                        placement='bottom'
                        className='ml-2 -tracking-tighter capitalize'
                      >
                        <button type='button' className='slug-edit w-8 h-8'>
                          {enableSlug ? (
                            <Check
                              onClick={async () => {
                                if (slugName?.length > 0) {
                                  setSlugName(await GenerateSlugName(slugName));
                                  setEnableSlug(!enableSlug);
                                  setErr(false);
                                  setSlugEdited(true);
                                } else {
                                  setIsNotification(true);
                                  setNotificationMsg('Slug can not be blank');
                                  setSeverity('warning');
                                }
                              }}
                              className='text-green-500 p-1 h-8 w-8 cursor-pointer'
                            />
                          ) : (
                            <Edit
                              onClick={() => setEnableSlug(!enableSlug)}
                              className='text-black-500 p-1 h-7 w-7 cursor-pointer'
                            />
                          )}
                        </button>
                      </Tooltip>
                    </div>
                    {err && <p className='text-xsm text-red-500'>Please add slug name </p>}
                    <p className='w-full text-xs mt-0 table-text'>{`Preview: ${clientInfo?.custom_domain
                      ? clientInfo?.custom_domain
                      : clientInfo?.customer_domain
                      }/category/${slugName}`}</p>
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='description'>{t('lang_parent_category')}</label>
                    <Autocomplete
                      className='autocomplete'
                      filterOptions={filterOptionsForCategory}
                      options={categoryWithParent ? categoryWithParent : []}
                      getOptionLabel={(category) => category?.parent_category}
                      onChange={(e, v) =>
                        v ? (setSelected(v._id), setIsChange(true)) : setSelected('')
                      }
                      noOptionsText={
                        categoryWithParent?.length > 0 && !autocompleteSearch
                          ? t('lang_enter_three_characters')
                          : t('lang_no_option_found')
                      }
                      value={
                        categoryWithParent?.find(
                          (category) => category._id === categoryData?.parent_category?._id,
                        ) || null
                      }
                      renderInput={(category) => (
                        <TextField
                          placeholder={t('lang_select_module', { module: 'Parent Category' })}
                          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                          value={category?.inputValue}
                          onChange={(e) => {
                            const newInputValue = e.target.value;
                            if (typeof newInputValue === 'string' && newInputValue?.length >= 3) {
                              setAutocompleteSearch(newInputValue);
                            } else {
                              setAutocompleteSearch();
                            }
                          }}
                          className='cursor-pointer '
                          {...category}
                        />
                      )}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='flex space-x-3 justify-between items-center mb-1'>
                      <label htmlFor='Description'>{t('lang_description')}</label>
                      <div className='flex items-center'>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={() => {
                            !category_name ? setwarn(true) : (setGenareteAiPopup(true), setwarn(false));
                          }}
                        >
                          <BardSvg /> {t('lang_get_description')}
                        </span>
                        <span
                          className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                          onClick={handleViewHtml}
                        >
                          <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                        </span>
                      </div>
                    </div>
                    <ReactQuill
                      modules={module}
                      ref={quillRef}
                      className={
                        err && description?.length === 0
                          ? 'border rounded-[6px] border-red-500 w-full h-auto'
                          : 'w-full h-auto'
                      }
                      theme='snow'
                      name='description'
                      value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                      onChange={handleQuillChange}
                      onBlur={() =>
                        setCatData({
                          ...CatData,
                          meta_tag_description:
                            description
                              ?.replace(/<[^>]*>/g, ' ')
                              ?.split(' ')
                              .slice(0, 50)
                              .join(' ') || '',
                        })
                      }
                      placeholder='enter description'
                    />
                    <div className='flex items-center justify-between'>
                      <p className='text-xs mt-0 table-text'>{t('lang_Only_Words_allowed', { words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280` })}</p>
                    </div>
                  </div>
                </div>
                <details
                  open
                  className=' secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'
                >
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      <span>{t('lang_media')}</span>
                      <p className='text-xs table-text font-normal'>
                        {t('lang_media_details', { module: 'category' })}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <div className='mt-4 sm:mt-[25px]'>
                    <ImageUploader
                      alreadyImage={image}
                      handleSetImage={handleSetImage}
                      folder='category'
                      format='image'
                    />
                  </div>
                </details>
                <details className=' secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
                  <summary className='text-base flex items-center justify-between font-medium '>
                    <div>
                      {t('lang_products')}
                      <p className='text-xs table-text font-normal'>
                        {t('lang_select_product_for_category')}
                      </p>
                    </div>
                    <span className='transition group-open:rotate-180'>
                      <ChevronDown className=' w-4 h-5' />
                    </span>
                  </summary>
                  <hr className='mt-4 main-border-color' />
                  <div className='w-full text-center'>
                    {selectedProduct?.length > 0 ? (
                      <div className='max-h-[450px] overflow-y-auto divide-y'>
                        {selectedProduct?.map((elem, index) => {
                          return (
                            <div
                              key={index}
                              className='flex main-border-color items-center py-2 space-x-3'
                            >
                              <button
                                onClick={(e) => removeSelectProduct(e, elem?.product)}
                                className='close-btn'
                              >
                                <X className='w-[14px] h-[14px] text-red-600' />
                              </button>
                              <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                                <img
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem?.image || NOPHOTO_IMAGE}
                                  className='max-w-full max-h-full'
                                  alt='product image'
                                />
                              </div>
                              <p className='text-sm'>{elem?.name || elem?.product_name}</p>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <button
                        className='bulk-btn mt-4'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSelectProduct(true);
                        }}
                      >
                        {t('lang_select_module', { module: 'Products' })}
                      </button>
                    )}
                    {selectedProduct?.length > 0 && (
                      <>
                        <div className='flex items-center border-t main-border-color justify-between pt-2'>
                          <button
                            className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setIsSelectProduct(true);
                            }}
                          >
                            + {t('lang_select_more_product')}
                          </button>
                          <button
                            className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedRemove(selectedProduct?.map((x) => x?.product));
                              setSelectedProduct([]);
                            }}
                          >
                            <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                            {t('lang_remove_all_product')}
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </details>
                <details className=' secondary-bg-color group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                  <summary
                    htmlFor='Meta Tag Title'
                    className='text-base w-full flex flex-col items-center font-medium '
                  >
                    <div className='flex w-full items-center justify-between'>
                      <div>
                        <span>{t('lang_search_engine_listing')}</span>
                        <p className='text-xs table-text font-normal'>
                          {t('lang_search_engine_listing_detail')}
                        </p>
                      </div>
                      <span className='transition group-open:rotate-180'>
                        <ChevronDown className=' w-4 h-5' />
                      </span>
                    </div>
                    {CatData?.meta_tag_title?.length > 0 && (
                      <div className='text-left font-normal mt-2 w-full'>
                        <p className='text-blue-700 text-base'>{meta_tag_title}</p>
                        <p className='flex text-xs text-green-700'>
                          {`${clientInfo?.custom_domain
                            ? clientInfo?.custom_domain
                            : clientInfo?.customer_domain
                            }/category/${slugName}`}{' '}
                        </p>
                        <p
                          className='table-text text-sm'
                          dangerouslySetInnerHTML={{ __html: meta_tag_description }}
                        ></p>
                      </div>
                    )}
                  </summary>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label className='flex items-center justify-between' htmlFor='Meta Tag Title'>
                        <div className='flex items-center'>
                          {t('lang_meta_title')}
                          <Tooltip
                            title={t('lang_meta_tooltip', { type: 'title' })}
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                      </label>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => {
                          generateMetaData(description, 'title');
                        }}
                      >
                        <BardSvg />
                        {t('lang_get_meta_title')}
                      </span>
                    </div>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='Meta Tag Title'
                      name='meta_tag_title'
                      value={meta_tag_title === 'undefined' ? '' : meta_tag_title}
                      onChange={HandleInput}
                      placeholder={t('lang_placeholder', { input: 'meta title' })}
                    />
                  </div>
                  <div className='mb-4'>
                    <div className='flex items-center justify-between'>
                      <label
                        className='flex items-center justify-between'
                        htmlFor='Meta Tag Description'
                      >
                        <div className='flex items-center'>
                          {t('lang_meta_description')}
                          <Tooltip
                            title={t('lang_meta_tooltip', { type: 'description' })}
                            arrow
                            placement='right'
                            className='ml-2 -tracking-tighter'
                          >
                            <QuestionCircleFill className='info-link cursor-pointer' />
                          </Tooltip>
                        </div>
                      </label>
                      <span
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={() => {
                          generateMetaData(description, 'description');
                        }}
                      >
                        <BardSvg />
                        {t('lang_get_meta_description')}
                      </span>
                    </div>
                    <textarea
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='Meta Tag Description'
                      name='meta_tag_description'
                      value={
                        meta_tag_description === 'undefined'
                          ? ''
                          : meta_tag_description?.replace(/<[^>]+>/g, '')
                      }
                      onChange={HandleInput}
                      placeholder={t('lang_placeholder', { input: 'meta description' })}
                    />
                  </div>
                  <div className='mb-4'>
                    <label htmlFor='keyword'>{t('lang_meta_keywords')}</label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='keyword'
                      name='keyword'
                      value={keyword === 'undefined' ? '' : keyword}
                      onChange={HandleInput}
                      placeholder={t('lang_placeholder', { input: 'meta keywords' })}
                    />
                  </div>
                </details>
                {isCustomField && <UpdateCustomMetaField
                  meta_fields={meta_fields}
                  setmeta_fields={setmeta_fields}
                  heading='category'
                />}
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_back_page_link', { page: 'categories' })}
              </Link>
              <div>
                {category_name === '' || slugName === '' ? (
                  <button
                    type='button'
                    disabled
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_update_module', { module: 'Category' })}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    disabled
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_Saving')}</span>
                  </button>
                ) : (
                  <button
                    type='button'
                    onClick={(e) => HandleSubmit(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_update_module', { module: 'Category' })}</span>
                  </button>
                )}
              </div>
          </div>
        </div>
      </div>
      <Dialog
        open={generateAiPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setGenareteAiPopup(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='w-[600px] max-h-full'>
          <div className='relative secondary-bg-color shadow dark:bg-gray-700 p-5'>
            <div className='flex items-center justify-between border-b main-border-color mb-3 pb-3 rounded-t dark:border-gray-600'>
              <h6 className='flex items-center justify-between font-medium  text-primary gap-1'>
                <BardSvg />  {t('lang_generate_category_description')}
              </h6>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setGenareteAiPopup(false);
                }}
              >
                <X className='h-4 w-4' aria-hidden='true' />
              </button>
            </div>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={handleSubmitAiData}
            >
              <div className='mb-4'>
                <label className='flex' htmlFor='SKU ID'>
                  {t('lang_features_and_keywords')}
                  <Tooltip
                    title='Add features and keywords for better description'
                    arrow
                    placement='right'
                    className='ml-2 -tracking-tighter'
                  >
                    <QuestionCircleFill className='info-link cursor-pointer' />
                  </Tooltip>
                </label>
                <textarea
                  value={gerenateAiData?.features}
                  name='features'
                  onChange={handleChangeAiData}
                  className='w-full border main-border-color rounded-[6px] px-3 py-1.5 outline-none text-sm'
                  placeholder='e.g. Organic cotten, relaxed fit'
                ></textarea>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_tone_of_voice')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.tone}
                    onChange={handleChangeAiData}
                    name='tone'
                    className='w-full border main-border-color rounded-[6px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Expert'>{t('lang_expert')}</option>
                    <option value='Custom'>{t('lang_custom')}</option>
                    <option value='Daring'>{t('lang_daring')}</option>
                    <option value='Playful'>{t('lang_playful')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Supportive'>{t('lang_supportive')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='SKU ID'>{t('lang_writing_style')}</label>
                <div className='relative'>
                  <select
                    value={gerenateAiData?.write_style}
                    onChange={handleChangeAiData}
                    name='write_style'
                    className='w-full border main-border-color rounded-[6px] px-3 py-2 outline-none text-sm'
                  >
                    <option value='Descriptive'>{t('lang_descriptive')}</option>
                    <option value='Persuasive'>{t('lang_persuasive')}</option>
                    <option value='Conversational'>{t('lang_conversational')}</option>
                    <option value='Technical'>{t('lang_technical')}</option>
                    <option value='Sophisticated'>{t('lang_sophisticated')}</option>
                    <option value='Storytelling'>{t('lang_storytelling')}</option>
                    <option value='SEO-Optimized'>{t('lang_seo_optimized')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <button type='submit' className='flex items-center justify-between btn px-5 py-2 text-white text-sm rounded-[6px] gap-1'>
                <BardSvgLight /> {t('lang_generate_description')}
              </button>
            </form>
          </div>
        </div>
      </Dialog>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
};

export default UpdateCategory;
