import { Dialog } from '@mui/material';
import React, { useContext } from 'react'
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { ChangePathContext } from '../App';
import { useNavigate } from 'react-router-dom';

const SaveChangesPopup = () => {
    const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
    const navigate = useNavigate();
    return (
        <Dialog
            open={saveChanges?.showChangesPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setSaveChanges({ ...saveChanges, showChangesPopup: false,isUnsaveChanges:true })}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='w-full secondary-bg-color sm:w-[500px] p-6 rounded-[6px]'>
                <div className='flex text-primary items-center justify-between'>
                    <p className='text-lg font-medium'>Unsaved changes</p>
                    <button onClick={(e) => { e.preventDefault; setSaveChanges({ ...saveChanges, showChangesPopup: false, isUnsaveChanges:true }) }} className='close-btn'>
                        <X className="w-4 h-4" />
                    </button>
                </div>
                <hr className='my-4 main-border-color'></hr>
                <p className='text-base table-text'>You have unsaved changes. Do you want to leave and discard these changes, or stay on this page?</p>
                <button className='bulk-btn px-[20px] py-[8px] mt-4 me-2 text-[13px]' onClick={(e) => { e.preventDefault(); navigate(saveChanges?.backLink); setSaveChanges({ ...saveChanges, isUnsaveChanges: false, showChangesPopup: false }) }}>Leave page</button>
                <button className='btn mt-4 text-white text-[13px]' onClick={(e) => {
                    e.preventDefault(); setSaveChanges({
                        ...saveChanges,isUnsaveChanges: true, showChangesPopup: false
                    })
                }}>Stay on page</button>
            </div>
        </Dialog>
    )
}

export default SaveChangesPopup