import { Tooltip } from '@mui/material';
import { Edit, Trash } from 'feather-icons-react';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import { encryptData } from '../../controllers/encryptionUtils';
import { GetClient, clientData } from '../../controllers/GetClient';
import Notification from '../Notification';
const BusinessProfile = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [m_mode, setm_mode] = useState();
  const clientInfo = clientData;
  useEffect(() => {
    setm_mode(clientInfo?.maintenance_mode);
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // Handle Maintenance Mode
  const HandleMaintenanceMode = async (value) => {
    try {
      const payload = encryptData({
        clientInfo: {
          maintenance_mode: value,
        },
      })
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetClient();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleEditField = (data) => {
    navigate('/store-profile/editCustomField', { state: { data } });
  };
  const handleEditChackOutField = (data) => {
    navigate('/store-profile/editCheckoutField', { state: { data } });
  };
  const handleDeleteField = async (id) => {
    if (window.confirm('Delete field?')) {
      try {
        let Delete = await API.delete(`/admin/client/DeleteCustomField/${id}`);
        if (Delete.status === 200) {
          setIsNotification(true);
          setNotificationMsg('Field Removed Successfully');
          setSeverity('success');
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  const handleDeleteCheckoutField = async (id) => {
    if (window.confirm('Delete field?')) {
      try {
        let Delete = await API.delete(`/admin/client/DeleteFieldCheckOut/${id}`);
        if (Delete.status === 200) {
          setIsNotification(true);
          setNotificationMsg('Field Removed Successfully');
          setSeverity('success');
          GetClient();
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <h4 className='text-xl md:text-2xl font-semibold ps-3 text-primary'>
          {t('lang_setting')}{' '}
        </h4>
        <div className='p-4'>
          <div className='flex flex-wrap space-y-2 lg:space-y-0 items-center justify-between mb-3'>
            <h1 className='text-2xl font-semibold text-center text-primary'>
              {' '}
              {t('lang_Business_Profile')}
            </h1>
            <button
              className='inline-block btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/store-profile', { state: { ...clientInfo.data } })}
            >
              {t('lang_Edit_Business_Profile')}
            </button>
          </div>
          <div className='bg-white p-3 shadow-sm rounded-[6px] w-full'>
            <div className='text-gray-700'>
              <div className='flex items-center justify-between px-4 py-2'>
                <div className=''>
                  <img
                    className='w-32'
                    onError={(e) => {
                      e.target.src = NOPHOTO_IMAGE;
                    }}
                    src={clientInfo?.data?.business_logo || NOPHOTO_IMAGE}
                    alt='image'
                  />
                </div>
                <div className='flex'>
                  <span className='block text-gray-700 text-sm font-bold mb-2'>
                    {t('lang_Maintenance_Mode')}
                  </span>
                  <input
                    className="mx-2 relative h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-400 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[2] checked:bg-blue-300 checked:after:bg-blue-500 checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                    type='checkbox'
                    role='switch'
                    checked={m_mode === true}
                    name='newsletter'
                    value={m_mode}
                    onChange={() => {
                      HandleMaintenanceMode(!m_mode);
                    }}
                  />
                </div>
              </div>
              <div className='grid md:grid-cols-2 text-sm w-[80%]'>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Name')}:</div>
                  <div className='px-4 py-2'>
                    {clientInfo.data && clientInfo.data.business_name}
                  </div>
                </div>

                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Category')}:</div>
                  <div className='px-4 py-2'>
                    {clientInfo.data && clientInfo.data.business_category}
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Email')}:</div>
                  <div className='px-4 py-2'>
                    {clientInfo.data && clientInfo.data.business_email}
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Phone')}:</div>
                  <div className='px-4 py-2'>
                    {clientInfo.data && clientInfo.data.business_phone}
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Order_Prefix')}:</div>
                  <div className='px-4 py-2'>{clientInfo.data && clientInfo.data.order_prefix}</div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Invoice_Prefix')}:</div>
                  <div className='px-4 py-2'>
                    {clientInfo.data && clientInfo.data.invoice_prefix}
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Address')}:</div>
                  <div className='px-4 py-2'>
                    <h1>
                      {clientInfo.data && clientInfo.data.business_address.addressLine1},
                      {clientInfo.data && clientInfo.data.business_address.addressLine2}
                      {clientInfo.data && clientInfo.data.business_address.city}
                    </h1>
                    <h1>
                      {clientInfo.data && clientInfo.data.business_address.pincode},
                      {clientInfo.data && clientInfo.data.business_address.state}
                    </h1>
                  </div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_report_status')}</div>
                </div>
                <div className='grid grid-cols-2'>
                  <div className='px-4 py-2 font-semibold'>{t('lang_Business_Description')}:</div>
                  <div className='px-4 py-2 '>
                    <p
                      className='line-clamp-3'
                      dangerouslySetInnerHTML={{
                        __html: clientInfo.data && clientInfo.data.business_description,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {/ Social Link /} */}
          <div className='px-1 flex flex-wrap space-y-2 lg:space-y-0 items-center justify-between mb-3 mt-4'>
            <h1 className='text-2xl font-semibold text-center text-primary'>
              {' '}
              {t('lang_Social_Links')}{' '}
            </h1>
            <button
              className='inline-block btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                navigate('/store-profile/AddsocialLinks', {
                  state: { ...clientInfo.data.social_media },
                });
              }}
            >
              {t('lang_Add_social_links')}
            </button>
          </div>
          {clientInfo.data && clientInfo.data.social_media ? (
            <>
              <div className='bg-white mx-1 p-3 space-x-28 shadow-sm rounded-[6px]'>
                <div className='text-gray-700'>
                  <div className='grid md:grid-cols-2 text-sm'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>{t('lang_Facebook')}:</div>
                      <div className='px-4 py-2'>
                        {clientInfo.data.social_media &&
                          clientInfo.data.social_media.facebook === '' ? (
                          <>{t('lang_Not_Set_Yet')}</>
                        ) : (
                          <h1 className='inline-flex flex-end'>
                            {clientInfo.data.social_media && clientInfo.data.social_media.facebook}
                          </h1>
                        )}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>{t('lang_Instagram')}:</div>
                      <div className='px-4 py-2'>
                        {clientInfo.data.social_media &&
                          clientInfo.data.social_media.instagram === '' ? (
                          <>{t('lang_Not_Set_Yet')}</>
                        ) : (
                          <h1 className='inline-flex flex-end'>
                            {clientInfo.data.social_media && clientInfo.data.social_media.instagram}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='grid md:grid-cols-2 text-sm'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>{t('lang_LinkedIn')}:</div>
                      <div className='px-4 py-2'>
                        {clientInfo.data.social_media &&
                          clientInfo.data.social_media.linkedin === '' ? (
                          <>{t('lang_Not_Set_Yet')}</>
                        ) : (
                          <h1 className='inline-flex flex-end'>
                            {clientInfo.data.social_media && clientInfo.data.social_media.linkedin}
                          </h1>
                        )}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>{t('lang_Twitter')}:</div>
                      <div className='px-4 py-2'>
                        {clientInfo.data.social_media &&
                          clientInfo.data.social_media.twitter === '' ? (
                          <>{t('lang_Not_Set_Yet')}</>
                        ) : (
                          <h1 className='inline-flex flex-end'>
                            {clientInfo.data.social_media && clientInfo.data.social_media.twitter}
                          </h1>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='grid md:grid-cols-2 text-sm'>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>{t('lang_Youtube')}:</div>
                      <div className='px-4 py-2'>
                        {clientInfo.data.social_media &&
                          clientInfo.data.social_media.youtube === '' ? (
                          <>{t('lang_Not_Set_Yet')}</>
                        ) : (
                          <h1 className='inline-flex flex-end'>
                            {clientInfo.data.social_media && clientInfo.data.social_media.youtube}
                          </h1>
                        )}
                      </div>
                    </div>
                    <div className='grid grid-cols-2'>
                      <div className='px-4 py-2 font-semibold'>
                        {' '}
                        {t('lang_More_Links_coming_Soon')}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className='px-1 flex flex-wrap space-y-2 lg:space-y-0 items-center justify-between mb-3 mt-4'>
            <h1 className='text-2xl font-semibold text-center text-primary'>
              {' '}
              {t('lang_Custom_fields')}{' '}
            </h1>
            <button
              className='inline-block btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                navigate('/store-profile/addcustomField', {
                  state: { ...clientInfo.data.social_media },
                });
              }}
            >
              {t('lang_Add_custom_fields')}
            </button>
          </div>
          <>
            <ul className='bg-white mx-1 p-6 flex flex-wrap lg:flex-nowrap justify-between lg:space-x-10 shadow-sm rounded-[6px]'>
              <div className='w-full lg:w-1/2'>
                <h6 className='text-lg font-medium mb-3.5'>
                  {t('lang_Registration_custom_fields')}
                </h6>
                <div className='space-y-4'>
                  {clientInfo?.data?.registration_custom_fields?.map((field, index) => {
                    return (
                      <li className='flex w-full' key={index}>
                        <div className='flex items-center w-full justify-between border py-2 px-3 space-x-4 text-sm'>
                          <div className='flex items-center space-x-8'>
                            <p>
                              <span className='font-medium'>{t('lang_Field_Name')} :</span>
                              <span className='ms-2'>{field.custom_field_name}</span>
                            </p>
                            <p>
                              <span className='font-medium'>{t('lang_Field_Type')} :</span>
                              <span className='ms-2'>{field.custom_field_type}</span>
                            </p>
                          </div>
                          <div className='flex items-center space-x-4'>
                            <Tooltip title='Edit' arrow>
                              <button onClick={() => handleEditField(field)}>
                                <Edit className='w-[17px] h-[17px] text-black-500' />
                              </button>
                            </Tooltip>
                            <Tooltip title='Delete' arrow>
                              <button onClick={() => handleDeleteField(field._id)}>
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className='w-full lg:w-1/2 mt-10 lg:mt-0'>
                <h6 className='text-lg font-medium mb-3.5'>{t('lang_Checkout_custom_fields')}</h6>
                <div className='space-y-4'>
                  {clientInfo?.data?.checkout_custom_fields?.map((field, index) => {
                    return (
                      <li className='flex w-full' key={index}>
                        <div className='flex items-center w-full justify-between border py-2 px-3 space-x-4 text-sm'>
                          <div className='flex items-center space-x-8'>
                            <p>
                              <span className='font-medium'>{t('lang_Field_Name')} :</span>
                              <span className='ms-2'>{field.custom_field_name}</span>
                            </p>
                            <p>
                              <span className='font-medium'>{t('lang_Field_Type')} :</span>
                              <span className='ms-2'>{field.custom_field_type}</span>
                            </p>
                          </div>
                          <div className='flex items-center space-x-4'>
                            <Tooltip title='Edit' arrow>
                              <button onClick={() => handleEditChackOutField(field)}>
                                <Edit className='w-[17px] h-[17px] text-black-500' />
                              </button>
                            </Tooltip>
                            <Tooltip title='Delete' arrow>
                              <button onClick={() => handleDeleteCheckoutField(field._id)}>
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </div>
              </div>
            </ul>
          </>
          {/* {/ customaization /} */}
          <div className='px-1 flex flex-wrap space-y-2 lg:space-y-0 items-center justify-between mb-3 mt-4'>
            <h1 className='text-2xl font-semibold text-center text-primary'>
              {t('lang_Customization')}
            </h1>
            <button
              className='inline-block btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => {
                navigate('/store-profile/product-catalog', {
                  state: {
                    products_per_row: clientInfo?.data?.products_per_row,
                    rows_per_page: clientInfo?.data?.rows_per_page,
                  },
                });
              }}
            >
              {t('lang_Add_Customization')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessProfile;
