import { t } from 'i18next';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

function ProductCatalog() {
  const [productInfo, setProductInfo] = useState(false);
  const [rowInfo, setRowInfo] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [productCatalogData, setProductCatalogData] = useState({
    products_per_row: location?.state?.products_per_row ? location?.state?.products_per_row : 3,
    rows_per_page: location?.state?.rows_per_page ? location?.state?.rows_per_page : 2,
  });
  const HandleInput = (e) => {
    setProductCatalogData({ ...productCatalogData, [e.target.name]: Number(e.target.value) });
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({
        clientInfo: {
          products_per_row: productCatalogData?.products_per_row,
          rows_per_page: productCatalogData?.rows_per_page,
        },
      })
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        navigate('/store-profile');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <h4 className='text-xl md:text-2xl font-semibold pb-4 text-primary'>
          {t('lang_Product_catalog')}
        </h4>
        <div className='p-4'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='bg-white p-[25px] shadow rounded-[6px] w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
            onSubmit={HandleSubmit}
          >
            <div className='mb-4'>
              <div className='flex items-center justify-between mb-1'>
                <label htmlFor='instagram'>{t('lang_Product_per_row')}</label>
                {productInfo && (
                  <p className='text-gray-500 font-medium text-xs'>
                    {t('lang_How_many_products_should_be_shown_per_page')}
                  </p>
                )}
              </div>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                type='number'
                name='products_per_row'
                value={productCatalogData?.products_per_row}
                onChange={HandleInput}
                onFocus={() => setProductInfo(true)}
                onBlur={() => setProductInfo(false)}
              />
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between mb-1'>
                <label htmlFor='instagram'>{t('lang_Rows_per_page')}</label>
                {rowInfo && (
                  <p className='text-gray-500 font-medium text-xs'>
                    {t('lang_How_Many_Products_Should_Be_Shown_Per_Row')}
                  </p>
                )}
              </div>
              <input
                className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                type='number'
                name='rows_per_page'
                min={1}
                value={productCatalogData?.rows_per_page}
                onChange={HandleInput}
                onFocus={() => setRowInfo(true)}
                onBlur={() => setRowInfo(false)}
              />
            </div>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
           <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <button
              type='submit'
              className='w-full block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
            >
              {t('lang_Submit')}
            </button>
           </div>
           </div>
      </div>
    </>
  );
}

export default ProductCatalog;
