// import { getOrders } from "../../redux/Actions/orders.action";
import API from "../../API";
import { decryptData, encryptData } from "../../controllers/encryptionUtils";
async function RazorpayPop(data, userId) {
  let isPaymentProcessing = false;
  let options = {
    key: process.env.REACT_APP_RAZORPAY_KEY_ID,
    name: "",
    description: "",
    order_id: "",
    handler: async function (response) {
      // Set processing state to true when payment is processing
      isPaymentProcessing = true;
      let values = {
        razorpay_signature: response?.razorpay_signature,
        razorpay_order_id: response?.razorpay_order_id,
        transaction_id: response?.razorpay_payment_id,
        transaction_amount: data?.total_price?.toString(),
        order: data?._id,
      };
      try {
        const payload = encryptData(values)
        const res = await API.post("/admin/payment/makePayment", payload);
        if (res.status === 200) {
          // window.location.reload(true);
          window.location.href = "/orders"
        }
      } catch (error) {
        console.log(error);
      } finally {
        isPaymentProcessing = false;
      }
    },
    modal: {
      ondismiss: async () => {
        try {
          if (!isPaymentProcessing) {
            const payload = encryptData({ order: data._id })
            await API.post(`/admin/order/revertPlacedOrder/${userId}`, payload);
            // dispatch(getOrders())
            // window.location.reload(true);
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
    prefill: {
      name: data.user.name ? data.user.name : "",
      email: data.user.email ? data.user.email : "",
      contact: data.user.phone ? data.user.phone : "",
    },
    notes: {
      order: data.order_id,
    },
    theme: {
      color: "#1256ff",
    },
  };

  try {
    const payload = encryptData({
      amount: data?.total_price,
      order_number: data?.order_id,
      order: data?._id
    })
    const createOrderPayment = await API.post("/admin/payment/createOrderPayment", payload);
    if (createOrderPayment?.data) {
      const result = decryptData(createOrderPayment?.data)
      options.order_id = result?.id;
      options.amount = result?.amount;
      var rzp1 = new window.Razorpay(options);
      await rzp1.open();
      rzp1.on(`payment.failed`, async () => {
        try {
          const payload = encryptData({
            order: data?._id,
          })
          await API.post(`/admin/order/revertPlacedOrder/${userId}`, payload);
        } catch (error) {
          console.log(error);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export default RazorpayPop;
