import { Menu, MenuItem, Tooltip } from '@mui/material';
import { ChevronDown, Link } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import ColorPicker from '../../../Color/ColorPicker';

const EditProductBuyButtonStyleSection = ({
  productButButtonDesign,
  setProductBuyButtonDesign,
  handleUpdateCss,
  visualData,
}) => {
  const [openState, setOpenState] = useState('');
  const [value, setValue] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleMarginChange = (value) => {
    if (productButButtonDesign?.margin_all === 'block') {
      setProductBuyButtonDesign({
        ...productButButtonDesign,
        margin_top: value,
        margin_right: value,
        margin_bottom: value,
        margin_left: value,
      });
    } else {
      setProductBuyButtonDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handlePaddingChange = (value) => {
    if (productButButtonDesign?.padding_all === 'block') {
      setProductBuyButtonDesign({
        ...productButButtonDesign,
        padding_top: value,
        padding_right: value,
        padding_bottom: value,
        padding_left: value,
      });
    } else {
      setProductBuyButtonDesign((prevState) => ({
        ...prevState,
        ...value,
      }));
    }
  };

  const handleColorChange = (value) => {
    if (openState === 'font_color') {
      setProductBuyButtonDesign({ ...productButButtonDesign, font_color: value });
    } else if (openState === 'border_color') {
      setProductBuyButtonDesign({ ...productButButtonDesign, border_color: value });
    }else if (openState === 'font_color_description') {
        setProductBuyButtonDesign({ ...productButButtonDesign, font_color_description: value });
      }
  };

  const handleCloseColor = () => {
    setOpenState('');
  };

  const gridColsClass =
    visualData?.manufacture?.section?.carousel_status !== false ? 'grid-cols-2' : 'grid-cols-3';

  return (
    <div className='relative'>
      <div className='editor-details py-3'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-[13px] font-semibold editor-heading'>{t('lang_spacing')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div className='2xl:flex justify-between mt-2 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_margin')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.margin_top}
                  onChange={(e) =>
                    handleMarginChange(
                      productButButtonDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.margin_right}
                  onChange={(e) =>
                    handleMarginChange(
                      productButButtonDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.margin_bottom}
                  onChange={(e) =>
                    handleMarginChange(
                      productButButtonDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.margin_left}
                  onChange={(e) =>
                    handleMarginChange(
                      productButButtonDesign?.margin_all === 'block'
                        ? e.target.value
                        : { margin_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productButButtonDesign?.margin_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductBuyButtonDesign((prevState) => ({
                      ...prevState,
                      margin_all: prevState.margin_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
          <div className='2xl:flex justify-between mt-3 items-center'>
            <p className='text-[#555555] font-medium text-[12px] table-text'>{t('lang_padding')}</p>
            <div className='grid grid-cols-5 items-center w-full 2xl:w-[70%] text-center mt-1 2xl:mt-0'>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input table-text primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.padding_top}
                  onChange={(e) =>
                    handlePaddingChange(
                      productButButtonDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_top: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_top')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.padding_right}
                  onChange={(e) =>
                    handlePaddingChange(
                      productButButtonDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_right: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Right')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.padding_bottom}
                  onChange={(e) =>
                    handlePaddingChange(
                      productButButtonDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_bottom: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_bottom')}</p>
              </div>
              <div className='w-full'>
                <input
                  type='number'
                  className='unit-input primary-bg-color border focus:outline-none sm:text-sm text-center'
                  value={productButButtonDesign?.padding_left}
                  onChange={(e) =>
                    handlePaddingChange(
                      productButButtonDesign?.padding_all === 'block'
                        ? e.target.value
                        : { padding_left: e.target.value },
                    )
                  }
                />
                <p className='text-[#555555] mt-0.5 text-[9px] table-text'>{t('lang_Left')}</p>
              </div>
              <div className='w-full -mt-2.5'>
                <button
                  className={`unit-input border focus:outline-none sm:text-sm text-center ${
                    productButButtonDesign?.padding_all === 'block'
                      ? 'bg-[#555555] text-white'
                      : 'primary-bg-color'
                  }`}
                  onClick={() =>
                    setProductBuyButtonDesign((prevState) => ({
                      ...prevState,
                      padding_all: prevState.padding_all === 'block' ? '' : 'block',
                    }))
                  }
                >
                  <Link className='w-4 h-4 mx-auto' />
                </button>
              </div>
            </div>
          </div>
        </details>
      </div>

      <hr className='main-border-color' />

      <div className='editor-details py-4'>
        <details open className='group p-0 '>
          <summary className='text-base flex items-center justify-between font-medium detail-container'>
            <div>
              <h6 className='text-sm font-bold editor-heading'>{t('lang_border')}</h6>
            </div>
            <span className='transition group-open:rotate-180'>
              <ChevronDown className=' w-4 h-5' />
            </span>
          </summary>
          <div>
            <div className='flex items-center mt-2'>
              <p className='text-[#555555] font-medium text-base w-[25%] table-text editor-title'>
                {t('lang_width')}
              </p>
              <div className='relative w-full'>
                <input
                  type='number'
                  className='w-full block border focus:border-gray-500 primary-bg-color edit-border rounded-[6px] edit-input focus:outline-none sm:text-sm text-left'
                  value={productButButtonDesign?.border_width}
                  onChange={(e) =>
                    setProductBuyButtonDesign({ ...productButButtonDesign, border_width: e.target.value })
                  }
                />
              </div>
            </div>

            <div className='flex mb-2 mt-2 justify-between items-center'>
              <p className='text-[#555555] font-medium text-[13px] table-text editor-title'>
                {t('lang_style')}
              </p>
              <ul className='grid grid-cols-4 text-sm font-medium text-center text-white bg-[#F6F6F6] p-0.5 w-[80%] primary-bg-color content-center items-center gap-1'>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5  text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center capitalize ${
                      productButButtonDesign?.border_style === 'none'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductBuyButtonDesign({ ...productButButtonDesign, border_style: 'none' })
                    }
                  >
                    {t('lang_none')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productButButtonDesign?.border_style === 'solid'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductBuyButtonDesign({ ...productButButtonDesign, border_style: 'solid' })
                    }
                  >
                    {t('lang_solid')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productButButtonDesign?.border_style === 'dashed'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductBuyButtonDesign({ ...productButButtonDesign, border_style: 'dashed' })
                    }
                  >
                    {t('lang_dashed')}
                  </a>
                </li>
                <li className='w-full'>
                  <a
                    className={`px-3 w-full transition-all duration-150 py-0.5 text-[12px] text-[#555555] rounded-[6px] hover:text-white hover:bg-[#555555] cursor-pointer flex justify-center items-center ${
                      productButButtonDesign?.border_style === 'dotted'
                        ? 'active bg-[#555555] text-white'
                        : 'hover:text-white'
                    }`}
                    onClick={() =>
                      setProductBuyButtonDesign({ ...productButButtonDesign, border_style: 'dotted' })
                    }
                  >
                    {t('lang_dotted')}
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-full flex mt-2'>
              <p className='text-[#555555] font-medium text-base table-text editor-title'>
                {t('lang_Color')}
              </p>
              <div className='relative w-full ml-5'>
                <div
                  onClick={() => setOpenState('border_color')}
                  className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2 h-[30px]'
                >
                  <div
                    className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                    style={{
                      backgroundColor: productButButtonDesign?.border_color,
                      height: '18px',
                      width: '29px',
                    }}
                  ></div>
                  <span className='text-sm font-medium table-text'>
                    {productButButtonDesign?.border_color}
                  </span>
                </div>
                {openState === 'border_color' && (
                  <ColorPicker
                    handleChange={handleColorChange}
                    setting={productButButtonDesign?.border_color}
                    handleCloseColor={handleCloseColor}
                  />
                )}
              </div>
            </div>
          </div>
        </details>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          // onClick={() => handleUpdateCss('manufacture')}
          onClick={handleUpdateCss}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductBuyButtonStyleSection;
