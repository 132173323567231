import React, { useState } from 'react';
import API from '../../API';
import Notification from '../Notification';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { GetClient, clientData } from '../../controllers/GetClient';
import { t } from 'i18next';
import { encryptData } from '../../controllers/encryptionUtils';

const ProductReview = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const clientInfo = clientData;
  const [review_option, setreviewOption] = useState(clientInfo?.review_option);
  const HandleSubmit = async () => {
    let body;
    try {
      body = {
        clientInfo: {
          review_option,
        },
      };
      const payload = encryptData(body);
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Profile Updated SuccessFully');
        setSeverity('success');
        GetClient();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Product_Review')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Manage_who_can_add_review_in_product')}
            </p>
          </div>
        </div>
        <>
          <div className='text-sm table-text w-full'>
            <label className='flex cursor-pointer justify-between items-center px-3 py-2 border main-border-color rounded-[6px]'>
              <p className='text-md me-2'>{t('lang_Logged_in_user_can_review')} </p>
              <input
                type='radio'
                id='flexSwitchCheckDefault'
                checked={review_option === 'logged_user'}
                name='logged_user'
                value={review_option}
                onChange={(e) => {
                  setreviewOption('logged_user');
                  // HandleSubmit(e);
                }}
              />
            </label>
            <label className='flex cursor-pointer px-3 py-2 border main-border-color rounded-[6px] justify-between items-center mt-4'>
              <p className='text-md me-2'>{t('lang_User_who_purchased_product_can_review')} </p>
              <input
                type='radio'
                id='flexSwitchCheckDefault'
                checked={review_option === 'product_purchased_user'}
                name='product_purchased_user'
                value={review_option}
                onChange={(e) => {
                  setreviewOption('product_purchased_user');
                  // HandleSubmit(e);
                }}
              />
            </label>
            <label className='flex cursor-pointer px-3 py-2 border main-border-color rounded-[6px] justify-between items-center mt-4'>
              <p className='text-md me-2'>{t('lang_Allow_all_review')} </p>
              <input
                type='radio'
                id='flexSwitchCheckDefault'
                checked={review_option === 'all_user'}
                name='all_user'
                value={review_option}
                onChange={(e) => {
                  setreviewOption('all_user');
                  // HandleSubmit(e);
                }}
              />
            </label>
            <div className='w-full flex justify-end'>
              <button
                type='button'
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white mt-4 text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              >
                {t('lang_Submit')}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ProductReview;
