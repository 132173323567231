import { Dialog, FormControlLabel } from '@mui/material';
import { Code, Edit, PlusCircle, Trash, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import API from '../../../API';
import ConfirmationPopup from '../../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import ImageUploader from '../../../controllers/ImageUploader';
import MuiSwitch from '../../../controllers/MuiSwitch';
import module, { formatCode } from '../../../controllers/QuillToolBar';
import SourceCode from '../../../controllers/SourceCode';
import { Transition } from '../../../controllers/Transition';
import ColorPicker from '../../Color/ColorPicker';
import NoDataFound from '../../Main/NoDataFound';
import NoRecords from '../../Main/NoRecords';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';
import ReactDragListView from 'react-drag-listview';

const EditBannerSection = ({ section_id, getSectionData, section_type }) => {
  const [nodata, setNodata] = useState(false);
  const [bannerParallax, setBannerParallax] = useState(false);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [status, setstatus] = useState(true);
  const [groupTitle, setGroupTitle] = useState();
  const [image, setimage] = useState();
  const [value, setValue] = useState();
  const [isVisibleButton, setIsVisibleButton] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [bannerLimit, setBannerLimit] = useState(1);
  const [addBanner, setAddBanner] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [storeBanners, setStoreBanners] = useState([]);
  const [bannerData, setBannerData] = useState({
    banner_button_color: '#818181',
    banner_button_text: '',
    banner_button_text_color: '#f1f1f1',
    banner_button_link: '',
    banner_button_hover_color: '#f1f1f1',
    banner_button_hover_text_color: '#818181',
    banner_button_redius: '',
    text_position: 'left',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularBannerGroup();
    }
  }, [section_id]);
  const handleGetPerticularBannerGroup = async () => {
    try {
      setNodata(false);
      const apiCall = await API.get(`/admin/banner/getBannerGroup/${section_id}`);
      const result = decryptData(apiCall?.data);
      setGroupTitle(result?.data?.banner_title);
      setBannerLimit(result?.data?.banner_limit);
      setBannerParallax(result?.data?.banner_parallax === 'true' ? true : false);
      setstatus(result?.data?.status);
      setStoreBanners(result?.data?.banners);
    } catch (error) {
      setNodata(true);
    }
  };
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          banner_limit: bannerLimit,
          banner_parallax: bannerParallax,
          banner_title: groupTitle,
          banners: storeBanners?.length && storeBanners?.map((x) => x),
        },
        status: status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [bannerLimit, groupTitle, storeBanners]);
  const [dataErr, setDataErr] = useState(false);
  // image upload logic
  const handleSetImage = (value) => {
    setimage(value);
  };
  const handleAddBanner = async () => {
    if (!image) {
      setDataErr(true);
    } else {
      setDataErr(false);
      let bannerBody;
      if (isVisibleButton) {
        bannerBody = {
          banner_text: value,
          banner_img: image,
          banner_button_color: bannerData?.banner_button_color,
          banner_button_text: bannerData?.banner_button_text,
          banner_button_text_color: bannerData?.banner_button_text_color,
          banner_button_link: bannerData?.banner_button_link,
          banner_button_hover_color: bannerData?.banner_button_hover_color,
          banner_button_hover_text_color: bannerData?.banner_button_hover_text_color,
          banner_button_redius: bannerData?.banner_button_redius,
          text_position: bannerData?.text_position,
        };
      } else {
        bannerBody = {
          banner_text: value,
          banner_img: image,
          text_position: bannerData?.text_position,
        };
      }
      if (!isEdit) {
        try {
          const payload = encryptData({
            banner_group_id: section_id,
            banner: bannerBody,
          });
          await API.post(`/admin/banner/addBanner`, payload);
          setBannerData({
            banner_button_color: '#818181',
            banner_button_text: '',
            banner_button_text_color: '#f1f1f1',
            banner_button_link: '',
            banner_button_hover_color: '#f1f1f1',
            banner_button_hover_text_color: '#818181',
            banner_button_redius: '',
            text_position: 'left',
          });
          setValue();
          setimage();
          setIsVisibleButton(false);
          setAddBanner(false);
          handleChange();
          handleGetPerticularBannerGroup();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          const payload = encryptData(bannerBody);
          await API.put(`/admin/banner/updateBanner/${editId}`, payload);
          setIsEdit(false);
          setAddBanner(false);
          setBannerData({
            banner_button_color: '#818181',
            banner_button_text: '',
            banner_button_text_color: '#f1f1f1',
            banner_button_link: '',
            banner_button_hover_color: '#f1f1f1',
            banner_button_hover_text_color: '#818181',
            banner_button_redius: '',
            text_position: 'left',
          });
          setNodata(true);
          setIsVisibleButton(false);
          setValue();
          setimage();
          handleGetPerticularBannerGroup();
          handleChange();
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  const handleEditBanner = async (e, elem) => {
    e.preventDefault();
    setEditId(elem?._id);
    setValue(elem?.banner_text);
    setimage(elem?.banner_img);
    setBannerData({
      banner_button_color: elem?.banner_button_color,
      banner_button_text: elem?.banner_button_text,
      banner_button_link: elem?.banner_button_link,
      banner_button_text_color: elem?.banner_button_text_color,
      banner_button_hover_color: elem?.banner_button_hover_color,
      banner_button_hover_text_color: elem?.banner_button_hover_text_color,
      banner_button_redius: elem?.banner_button_redius,
      text_position: elem?.text_position,
    });
    setIsVisibleButton(elem?.banner_button_text?.length > 0 ? true : false);
    setAddBanner(true);
    setIsEdit(true);
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const handleEditBannerGroup = async (e, bannerValue, statusVal, key) => {
    e.preventDefault();
    if (!key) {
      setBtnLoading(true);
    }
    try {
      const payload = encryptData({
        banner_title: groupTitle,
        banner_limit: bannerLimit,
        banner_parallax: bannerValue,
        banners: storeBanners,
        status: statusVal,
      });
      await API.put(`/admin/banner/updateBannerGroup/${section_id}`, payload);
      handleGetPerticularBannerGroup();
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/banner/deleteBanner`, payload);
      if (data.status === 200 || data.status === 304) {
        setConfirmationPopup(false);
        setIsNotification(true);
        setNotificationMsg('Banner Removed SuccesFully!');
        setSeverity('success');
        handleGetPerticularBannerGroup();
        handleChange();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const UpdateBannerStatus = async (id, value) => {
    try {
      const payload = encryptData({
        banner_status: value,
      });
      const data = await API.put(`/admin/banner/updateBanner/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetPerticularBannerGroup();
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const sortBanner = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/banner/updateBanner/${id}`, payload);
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();

  // const HandleSort = (result) => {
  //   const items = Array.from(storeBanners);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreBanners(items);
  //   sortBanner(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeBanners];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreBanners(newItems);
      sortBanner(toIndex, filterId);
      setFilterId(null);
      setHoverId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setValue(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setValue(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  const [openState, setOpenState] = useState();
  const handleColorChange = (value) => {
    if (openState === 'banner_button_color') {
      setBannerData({ ...bannerData, banner_button_color: value });
    } else if (openState === 'banner_button_text_color') {
      setBannerData({ ...bannerData, banner_button_text_color: value });
    } else if (openState === 'banner_button_hover_color') {
      setBannerData({ ...bannerData, banner_button_hover_color: value });
    } else if (openState === 'banner_button_hover_text_color') {
      setBannerData({ ...bannerData, banner_button_hover_text_color: value });
    }
  };
  const handleCloseColor = () => {
    setOpenState('');
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Banner Group'
        subheading='Do you really want to delete this banner group from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto overflow-y-auto pb-[130px]'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            className='w-full mx-auto'
          >
            <div className='secondary-bg-color w-full space-y-4'>
              <div className='mb-4'>
                <label htmlFor='group title' className='flex justify-between'>
                  <label htmlFor='image'>{t('lang_Banner_Group_Title')}</label>
                  <GenerateLiveEditiorTitle
                    getAiGeneratedTitle={getAiGeneratedTitle}
                    section_type={section_type}
                  />
                </label>
                <input
                  type='text'
                  className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm'
                  placeholder='Banner group title'
                  value={groupTitle}
                  onChange={(e) => {
                    setGroupTitle(e.target.value);
                    handleChange();
                  }}
                />
                {dataErr && (
                  <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Name')}</p>
                )}
              </div>
              <div className='grid w-full grid-cols-1 mb-4 gap-5'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='image'>{t('lang_Banner_Parallax')} :</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={bannerParallax}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        !bannerParallax || (bannerParallax === 'false' && setBannerLimit(1));
                        handleEditBannerGroup(e, !bannerParallax, status, 'status');
                        handleChange();
                      }}
                    />
                  </label>
                </div>
                <div className='w-full'>
                  <label htmlFor='image'>{t('lang_Banner_Limit')}</label>
                  <div className='relative'>
                    <select
                      disabled={bannerParallax}
                      value={bannerLimit}
                      onChange={(e) => {
                        setBannerLimit(e.target.value);
                        handleChange();
                      }}
                      className='w-full border main-border-color focus:border-gray-500 rounded-[6px] py-1 outline-none px-3'
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>
                    <div className='select-arrow'></div>
                  </div>
                </div>
              </div>
              <div className='mb-4 flex items-center justify-between'>
                <label htmlFor='image'>{t('lang_status')} :</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={status === true}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      handleEditBannerGroup(e, bannerParallax, !status, 'status');
                      handleChange();
                    }}
                  />
                </label>
              </div>
              <hr className='border-t main-border-color my-4'></hr>
              <div className='flex items-end justify-between'>
                <button
                  className='text-link inline-flex items-center  mb-0 underline text-right font-medium text-sm text-white'
                  onClick={(e) => {
                    e.preventDefault();
                    setAddBanner(true);
                  }}
                >
                  <PlusCircle className='w-4 me-2 h-4' />
                  {t('lang_Add_Banner')}
                </button>
              </div>
              {storeBanners?.length > 0 ? (
                <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                  <table className='w-full'>
                    <thead>
                      <tr className='w-full'>
                        <td className='w-full px-3'>{t('lang_Banner_Text')}</td>
                        <td className='w-[120px] px-3 text-right capitalize'>{t('lang_action')}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <ReactDragListView {...dragProps}>
                        {storeBanners?.map((row, index) => (
                          <tr
                            style={{
                              boxShadow:
                                hoverId === index ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                              background: 'white',
                            }}
                            className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                              } select-none flex w-full`}
                            onDragStart={() => setFilterId(row?._id)}
                            onDragOver={() => setHoverId(index)}
                            onDragLeave={(e) => {
                              e.preventDefault();
                              setHoverId(null);
                            }}
                          >
                            <td className='w-full px-3'>
                              <span className='line-clamp-2'>
                                {row?.banner_text ? row.banner_text.replace(/<[^>]*>/g, ' ') : '-'}
                              </span>
                            </td>
                            <td className='px-3 w-[120px] text-right'>
                              <div className='space-x-2 w-full text-right'>
                                <button onClick={(e) => handleEditBanner(e, row)}>
                                  <Edit className='w-[17px] h-[17px] text-black-500' />
                                </button>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedRowsId([row?._id]);
                                    setConfirmationPopup(true);
                                  }}
                                >
                                  <Trash className='w-[17px] h-[17px] text-red-500' />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </ReactDragListView>
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoRecords />
              )}
            </div>
          </form>
          {!html?.htmlPP ? (
            <Dialog
              open={addBanner}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => {
                setBannerData({
                  banner_button_color: '#818181',
                  banner_button_text: '',
                  banner_button_text_color: '#f1f1f1',
                  banner_button_link: '',
                  banner_button_hover_color: '#f1f1f1',
                  banner_button_hover_text_color: '#818181',
                  banner_button_redius: '',
                  text_position: 'left',
                });
                setValue();
                setimage();
                setIsVisibleButton(false);
                setIsEdit(false);
                setAddBanner(false);
              }}
              aria-describedby='alert-dialog-slide-description'
            >
              <form
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5'
              >
                <div className='flex text-primary border-b main-border-color pb-3 items-center justify-between'>
                  <h6 className='font-medium text-xl'>{t('lang_Add_Banner')}</h6>
                  <button
                    className='close-btn'
                    onClick={(e) => {
                      e.preventDefault();
                      setBannerData({
                        banner_button_color: '#818181',
                        banner_button_text: '',
                        banner_button_text_color: '#f1f1f1',
                        banner_button_link: '',
                        banner_button_hover_color: '#f1f1f1',
                        banner_button_hover_text_color: '#818181',
                        banner_button_redius: '',
                        text_position: 'left',
                      });
                      setValue();
                      setimage();
                      setIsVisibleButton(false);
                      setIsEdit(false);
                      setAddBanner(false);
                    }}
                  >
                    <X className='w-4 h-4' />
                  </button>
                </div>
                <div className='mb-4'>
                  <label htmlFor='image'>{t('lang_Banner_Image')}</label>
                  <ImageUploader
                    alreadyImage={image}
                    handleSetImage={handleSetImage}
                    folder='banner'
                    format='image'
                  />
                </div>
                <div className='mb-4'>
                  <div className='flex justify-between items-center'>
                    <label htmlFor='image' className='capitalize'>
                      {t('lang_Banner_Text')}
                    </label>
                    <span
                      className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                      onClick={handleViewHtml}
                    >
                      <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                    </span>
                  </div>

                  <ReactQuill
                    modules={module}
                    ref={quillRef}
                    className='w-full h-auto'
                    theme='snow'
                    value={value}
                    onChange={handleQuillChange}
                  />
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 w-full mb-4'>
                  <div className='border w-full main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-1 text-sm'>
                    <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
                      {t('lang_Text_Position')}
                    </label>
                    <div className='flex w-full table-text items-center text-sm justify-between px-4'>
                      <span
                        className={`${bannerData?.text_position === 'left'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'left' })}
                      >
                        {t('lang_Left')}
                      </span>
                      <span
                        className={`${bannerData?.text_position === 'center'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'center' })}
                      >
                        {t('lang_Center')}
                      </span>
                      <span
                        className={`${bannerData?.text_position === 'right'
                            ? 'primary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                            : 'cursor-pointer block w-full text-center py-1.5 text-xs'
                          }`}
                        onClick={() => setBannerData({ ...bannerData, text_position: 'right' })}
                      >
                        {t('lang_Right')}
                      </span>
                    </div>
                  </div>
                  <div className='w-full border main-border-color flex items-center space-x-2 rounded-[6px] px-4 py-2 text-sm'>
                    <p className='text-[13px] font-[500] table-text capitalize'>
                      {t('lang_Visible_Banner_Button')} -
                    </p>
                    <label className='relative flex ms-2 items-center cursor-pointer'>
                      <FormControlLabel
                        checked={isVisibleButton === true}
                        control={<MuiSwitch />}
                        onChange={() => setIsVisibleButton(!isVisibleButton)}
                      />
                    </label>
                  </div>
                </div>
                {isVisibleButton ? (
                  <>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Text')}</label>
                        <input
                          type='text'
                          name='banner_button_text'
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          value={bannerData?.banner_button_text}
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_text: e.target.value })
                          }
                          placeholder='e.g. Click here'
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Link')}</label>
                        <input
                          type='text'
                          name='banner_button_link'
                          value={bannerData?.banner_button_link}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          placeholder='Add link'
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_link: e.target.value })
                          }
                        />
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Border_Radius')}</label>
                        <input
                          type='number'
                          name='banner_button_link'
                          value={bannerData?.banner_button_redius}
                          className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                          placeholder='e.g. 4'
                          onChange={(e) =>
                            setBannerData({ ...bannerData, banner_button_redius: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_color}
                            </span>
                          </div>
                          {openState === 'banner_button_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_text_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_text_color}
                            </span>
                          </div>
                          {openState === 'banner_button_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex space-x-4 w-full mb-4'>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Banner_Button_Hover_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_hover_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{ backgroundColor: bannerData?.banner_button_hover_color }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_hover_color}
                            </span>
                          </div>
                          {openState === 'banner_button_hover_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_hover_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                      <div className='w-full'>
                        <label htmlFor='image'>{t('lang_Button_Hover_Text_Color')}</label>
                        <div className='relative'>
                          <div
                            onClick={() => setOpenState('banner_button_hover_text_color')}
                            className='cursor-pointer flex items-center border main-border-color p-1.5 mb-2 rounded-[6px] space-x-2'
                          >
                            <div
                              className='w-[30px] shadow h-[30px] rounded-[6px] cursor-pointer'
                              style={{
                                backgroundColor: bannerData?.banner_button_hover_text_color,
                              }}
                            ></div>
                            <span className='text-sm font-medium table-text'>
                              {bannerData?.banner_button_hover_text_color}
                            </span>
                          </div>
                          {openState === 'banner_button_hover_text_color' && (
                            <ColorPicker
                              handleChange={handleColorChange}
                              setting={bannerData?.banner_button_hover_text_color}
                              handleCloseColor={handleCloseColor}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <hr className='border-t main-border-color mt-3'></hr>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBanner();
                  }}
                  className='btn text-sm text-white'
                >
                  {isEdit ? 'Edit banner' : 'Add banner'}
                </button>
              </form>
            </Dialog>
          ) : (
            html?.htmlPP && (
              <SourceCode
                open={html?.htmlPP}
                htmlCode={html?.code}
                closeCode={closeCode}
                saveHTML={saveHTML}
              />
            )
          )}
          <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
            <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
              <div>
                {!groupTitle || groupTitle === '' ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Apply_Changes')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleEditBannerGroup(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Apply_Changes')}</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditBannerSection;
