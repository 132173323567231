import { Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

const AddManufactureGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  const [brandGroupTitle, setBrandGroupTitle] = useState('');
  const [brandData, setBrandData] = useState({
    brand_limit: 5,
    carousel_status: true,
    status: true,
    brand_name_display: true,
    brand_title_display: true,
    brand_image_width: '',
    brand_image_height: '',
    carousel_speed_ms: '',
    loop_forever: true,
    auto_play: false,
    pause_on_hover: false,
    button_text: '',
    button_link: '/manufacturers',
  });
  const [addBrand, setAddBrand] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddBrand = () => {
    setAddBrand(false);
  };
  const [selectedBrand, setSelectedBrand] = useState([]);
  const handleSelectBrand = (brand, name, logo) => {
    if (brand) {
      const existBrand = selectedBrand.find((x) => x?.brand === brand);
      if (!existBrand) {
        setSelectedBrand([...selectedBrand, { brand, name, logo }]);
      } else {
        const removeSelected = selectedBrand.filter((x) => x?.brand !== brand);
        setSelectedBrand(removeSelected);
      }
    }
  };
  const [allBrands, setAllBrands] = useState([]);
  const [search, setSearch] = useState('');
  const GetManufactures = async () => {
    try {
      const apiCall = await API.get(
        `/admin/manufactures/GetManufacturerWithProductCount?searchkey=${search}&is_selected=${true}`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data)
        setAllBrands(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addBrand === true && (search?.length === 0 || search?.length >= 3)) {
      GetManufactures();
    }
  }, [addBrand, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBrandGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      let body = {
        brand_section_title: brandGroupTitle,
        button_text: brandData?.button_text,
        button_link: brandData?.button_link,
        brand_limit: brandData?.brand_limit,
        carousel_status: brandData?.carousel_status,
        status: brandData?.status,
        brand_name_display: brandData?.brand_name_display,
        brand_title_display: brandData?.brand_title_display,
        carousel_speed_ms: brandData?.carousel_speed_ms,
        loop_forever: brandData?.loop_forever,
        auto_play: brandData?.auto_play,
        pause_on_hover: !brandData?.auto_play ? false : brandData?.pause_on_hover,
        brands: selectedBrand,
      };
      if (brandData?.brand_image_height?.length > 0 || brandData?.brand_image_width?.length > 0) {
        body = {
          ...body,
          brand_image_width: brandData?.brand_image_width,
          brand_image_height: brandData?.brand_image_height,
        };
      }
      const payload = encryptData(body)
      await API.post(`/admin/dynamicBrand/addBrandGroup`, payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/manufacture-group',
        title: 'add manufacture group',
      });
      navigate('/module-setting/manufacture-group');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    if (
      selectedBrand?.length > 0 ||
      brandData?.carousel_speed_ms?.length > 0 ||
      brandData?.button_text?.length > 0 ||
      brandGroupTitle?.length > 0 ||
      brandData?.brand_image_height?.length > 0 ||
      brandData?.brand_image_width?.length > 0 ||
      brandData?.loop_forever === false ||
      brandData?.brand_title_display === false ||
      brandData?.brand_name_display === false ||
      brandData?.status === false ||
      brandData?.carousel_status === false ||
      brandData?.auto_play === true ||
      brandData?.brand_limit !== 5 ||
      brandData?.pause_on_hover === true ||
      brandData?.button_link !== '/manufacturers'
    ) {
      setSaveChanges({
        backLink: '/module-setting/manufacture-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add manufacture group',
      });
    } else {
      setSaveChanges({
        backLink: '/module-setting/manufacture-group',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add manufacture group',
      });
    }
  }, [selectedBrand, brandData, brandGroupTitle]);
  const checkChanges = () => {
    if (
      selectedBrand?.length > 0 ||
      brandData?.carousel_speed_ms?.length > 0 ||
      brandData?.button_text?.length > 0 ||
      brandGroupTitle?.length > 0 ||
      brandData?.brand_image_height?.length > 0 ||
      brandData?.brand_image_width?.length > 0 ||
      brandData?.loop_forever === false ||
      brandData?.brand_title_display === false ||
      brandData?.brand_name_display === false ||
      brandData?.status === false ||
      brandData?.carousel_status === false ||
      brandData?.auto_play === true ||
      brandData?.brand_limit !== 5 ||
      brandData?.pause_on_hover === true ||
      brandData?.button_link !== '/manufacturers'
    ) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/manufacture-group',
      });
    } else {
      navigate('/module-setting/manufacture-group');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Manufacture_Group'), path: '/module-setting/manufacture-group' },
                {
                  name: t('lang_Add_New_Manufacturer_Group'),
                  path: '/module-setting/manufacture-group/add-manufacture-group',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Manufacturer_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 sm:w-[80%] xl:w-[70%] mx-auto'
        >
          <div className='secondary-bg-color w-full border  main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Manufacturer_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={brandGroupTitle}
                onChange={(e) => setBrandGroupTitle(e.target.value)}
                placeholder='Enter group title'
              />
              {dataErr && brandGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-5 w-full'>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Group_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, status: !brandData?.status });
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Manufacture_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={brandData?.brand_limit}
                    onChange={(e) => setBrandData({ ...brandData, brand_limit: e.target.value })}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Name_Display')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_name_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_name_display: !brandData?.brand_name_display,
                      });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Manufacture_Title_Display')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.brand_title_display}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({
                        ...brandData,
                        brand_title_display: !brandData?.brand_title_display,
                      });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, carousel_status: !brandData?.carousel_status });
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    value={brandData?.carousel_speed_ms}
                    disabled={brandData?.carousel_status === 'disabled'}
                    onChange={(e) =>
                      setBrandData({ ...brandData, carousel_speed_ms: e.target.value })
                    }
                    className='w-full border bg-transparent main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Carousel_Loop')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.loop_forever}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, loop_forever: !brandData?.loop_forever });
                    }}
                  />
                </label>
              </div>
              <div className='mb-4 flex items-center gap-2'>
                <label>{t('lang_Autoplay')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={brandData?.auto_play}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBrandData({ ...brandData, auto_play: !brandData?.auto_play });
                    }}
                  />
                </label>
              </div>
              {brandData?.auto_play && (
                <div className='mb-4 flex items-center gap-2'>
                  <label>{t('lang_Pause_On_Hover')}</label>
                  <label className='relative flex ms-2 items-center cursor-pointer'>
                    <FormControlLabel
                      checked={brandData?.pause_on_hover}
                      control={<MuiSwitch />}
                      onChange={(e) => {
                        setBrandData({ ...brandData, pause_on_hover: !brandData?.pause_on_hover });
                      }}
                    />
                  </label>
                </div>
              )}
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  value={brandData?.button_text}
                  className='w-full bg-transparent border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => setBrandData({ ...brandData, button_text: e.target.value })}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  value={brandData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 bg-transparent rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => setBrandData({ ...brandData, button_link: e.target.value })}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    value={brandData?.brand_image_width}
                    onChange={(e) =>
                      setBrandData({ ...brandData, brand_image_width: e.target.value })
                    }
                    className='w-full border bg-transparent main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 100'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Manufacture_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>{' '}
                </label>
                <div className='relative'>
                  <input
                    value={brandData?.brand_image_height}
                    onChange={(e) =>
                      setBrandData({ ...brandData, brand_image_height: e.target.value })
                    }
                    className='w-full border bg-transparent main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 150'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex flex-wrap items-center justify-between w-full text-sm'>
              <div>
                {t('lang_Select_Manufature_For_Show_In_This_Group')}
                <span className='text-red-500 ms-1'>*</span>
              </div>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setAddBrand(true);
                }}
                className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
              >
                {t('lang_Select_Manufactures')}
              </span>
            </div>
            {selectedBrand?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_manufacture_name')}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {!addBrand &&
                      selectedBrand?.map((row) => {
                        return (
                          <tr>
                            <td className='table-image px-3 min-w-[80px]'>
                              <img
                                width={80}
                                height={50}
                                src={row.logo}
                                className='p-1 max-w-full max-h-full'
                                alt={row.name.replace(/<[^>]*>/g, ' ')}
                              />
                            </td>
                            <td className='w-full px-3'>
                              <span className='line-clamp-2'>
                                {row?.name ? row.name.replace(/<[^>]*>/g, ' ') : '-'}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>
        </form>
        <Dialog
          open={addBrand}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddBrand(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl text-primary'>{t('lang_Select_Manufactures')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setAddBrand(false);
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 bg-transparent text-sm'
              placeholder='Search manufacture..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allBrands?.map((elem) => {
                return (
                  <label className='flex py-2 items-start main-border-color justify-between'>
                    <div className='flex text-primary'>
                      <input
                        type='checkbox'
                        checked={selectedBrand?.some((x) => x.brand === elem?._id)}
                        value={elem?._id}
                        onClick={(e) =>
                          handleSelectBrand(elem?._id, elem?.brand_name, elem?.brand_logo)
                        }
                        className='block mt-1 w-4 h-4 me-4'
                      />
                      <div className='w-[60px] h-[60px] p-1 me-2 border main-border-color flex items-center justify-center'>
                        <img
                          src={elem?.brand_logo}
                          className='max-w-full max-h-full'
                          alt={elem?.brand_name}
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.brand_name}</p>
                    </div>
                    <p className='me-2 text-base '>({elem?.product_count})</p>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddBrand()}>
              {t('lang_add_manufacture')}
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-end lg:w-[70%] xl:w-[68%] mx-auto'>
            <div>
              {brandGroupTitle?.length === 0 || selectedBrand?.length === 0 ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Manufacture_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[161px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBrandGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Add_Manufacture_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddManufactureGroup;
