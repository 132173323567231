import { ArrowLeft } from 'feather-icons-react';
import { t } from 'i18next';
import React from 'react';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../controllers/breadCrumbs';
import EarningChart from './EarningChart';
import OrdersChart from './OrdersChart';
import PriceChart from './PriceChart';
import TrafficChart from './Quantity';
const Analytics = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: t('lang_analytics'), path: '/analytics' }
            ]} />
            <div className='flex items-end'>
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_analytics')} </h4>
              <a target='blank' href='https://docs.shopeasy.ai/analytics' className='how-it-works'><QuestionCircleFill className='me-2' />{t('lang_how_to_use')}</a>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 gap-5 xl:grid-cols-2'>
          <OrdersChart />
          <EarningChart />
          <TrafficChart />
          <PriceChart />
        </div>
      </div>
    </>
  );
};

export default Analytics;
