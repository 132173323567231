import React, { useEffect, useState } from 'react';
import { Dialog, Pagination } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import API from '../API';
import { NOPHOTO_IMAGE } from '../Assets/StaticData/StaticImage';
import { t } from 'i18next';
import { decryptData } from './encryptionUtils';

const SelectCategories = ({
  openCategoryOptions,
  closeCategoryModal,
  api,
  alreadySelectedCategories,
  getSelectedCategory,
}) => {
  const [selectedCategories, setSelectedCategories] = useState(
    alreadySelectedCategories ? alreadySelectedCategories : [],
  );
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [allCategories, setAllCategories] = useState([]);
  const getCategory = async () => {
    if (search?.length >= 3) {
      try {
        const data = await API.get(
          `${api}?page=${page}&limit=15&searchkey=${search}&sort_by=&order_by=ASC`,
        );
        const result = decryptData(data?.data)
        setAllCategories(result?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleSelectCategory = (id, name, image) => {
    if (id) {
      const cat = selectedCategories?.length > 0 && selectedCategories?.find((x) => x?._id === id);
      if (!cat) {
        setSelectedCategories([...selectedCategories, { _id: id, category_name: name, image }]);
      } else {
        const removeSelected = selectedCategories?.filter((x) => x?._id !== id);
        setSelectedCategories(removeSelected);
      }
    }
  };
  useEffect(() => {
    getCategory();
  }, [search, page]);
  return (
    <Dialog
      open={openCategoryOptions}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeCategoryModal}
      aria-describedby='alert-dialog-slide-description'
    >
      <div className='secondary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
        <div className='flex border-b main-border-color pb-3 items-center justify-between'>
          <h6 className='font-medium text-xl'>Select Categories</h6>
          <button className='close-btn' onClick={closeCategoryModal}>
            <X className='w-4 h-4' />
          </button>
        </div>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 bg-transparent text-sm'
          placeholder='Search categories..'
        />

        <div className='h-[450px] overflow-y-auto divide-y'>
          {allCategories &&
            allCategories?.records?.length > 0 && search?.length >= 3 ?
            allCategories?.records?.map((item, index) => {
              return (
                <label key={index} className='flex main-border-color items-center py-2 space-x-3'>
                  <input
                    type='checkbox'
                    checked={
                      selectedCategories?.length > 0 &&
                      selectedCategories?.find((x) => x._id === item?._id) !== undefined
                    }
                    value={item?._id}
                    onChange={() =>
                      handleSelectCategory(item._id, item.lowercase_category, item.image)
                    }
                  />
                  <div className='min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                    <img
                      onError={(e) => {
                        e.target.src = NOPHOTO_IMAGE;
                      }}
                      src={item?.image || NOPHOTO_IMAGE}
                      className='max-w-full max-h-full'
                      alt='category image'
                    />
                  </div>
                  <p className='text-sm'>{item?.lowercase_category}</p>
                </label>
              );
            })
            : search?.length <= 3 ?
                <div className='w-full flex items-center justify-center border px-2 py-2'>
                    {t('lang_enter_three_characters')}
                </div>
            :
            <div className='w-full flex items-center justify-center border px-2 py-2'>
              {t('lang_Data_not_found')}
            </div>
            }
        </div>
        <div className='flex justify-between pagination items-center flex-wrap'>
          <button
            className={`flex px-5 py-2 btn rounded-s text-sm items-center text-white transition duration-300 ${selectedCategories?.length <= 0 && 'cursor-not-allowed bg-btn'}`}
            disabled={selectedCategories?.length <= 0}
            onClick={(e) => {
              e.preventDefault();
              getSelectedCategory(selectedCategories);
              closeCategoryModal();
            }}
          >
            {`${t('lang_select')} ${t('lang_categories')}`}
          </button>
          <Pagination
            count={allCategories?.totalPages}
            page={page}
            onChange={(e, v) => setPage(v)}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default SelectCategories;
