import { FormControlLabel } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../../API';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import MuiSwitch from '../../../controllers/MuiSwitch';
import Notification from '../../Notification';
import GenerateLiveEditiorTitle from '../../../controllers/GenerateLiveEditiorTitle';

const EditBlogSection = ({ section_id, getSectionData, section_type }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [storeBlog, setStoreBlog] = useState([]);
  const [blogGroupTitle, setBlogGroupTitle] = useState('');
  const [blogData, setBlogData] = useState({
    blog_limit: 3,
    blog_display_grid: true,
    carousel_status: true,
    status: true,
    blog_image_width: '',
    blog_image_height: '',
    carousel_speed_ms: '',
    button_text: '',
    button_link: '',
  });
  const handleGetPerticularBlogGroup = async () => {
    try {
      const apiCall = await API.get(`/admin/dynamicBlog/getBlogGroupById/${section_id}`);
      const result = decryptData(apiCall?.data);
      setBlogData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        blog_limit: result?.data[0]?.blog_limit,
        blog_display_grid: result?.data[0]?.blog_display_grid,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        blog_image_width: result?.data[0]?.blog_image_width,
        blog_image_height: result?.data[0]?.blog_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
      });
      setBlogGroupTitle(result?.data[0]?.blog_section_title);
      setStoreBlog(result?.data[0]?.blogs);
    } catch (error) {
      console.log(error);
    }
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleChange = () => {
    windowWidth > 768 &&
      getSectionData({
        section: {
          button_text: blogData?.button_text,
          button_link: blogData?.button_link,
          blog_limit: blogData?.blog_limit,
          blog_display_grid: blogData?.blog_display_grid,
          carousel_status: blogData?.carousel_status,
          status: blogData?.status,
          blog_image_width: Number(blogData?.blog_image_width),
          blog_image_height: Number(blogData?.blog_image_height),
          carousel_speed_ms: blogData?.carousel_speed_ms,
          blog_section_title: blogGroupTitle,
          blogs: storeBlog,
        },
        status: blogData?.status,
      });
  };
  useEffect(() => {
    handleChange();
  }, [blogData, blogGroupTitle, storeBlog]);
  useEffect(() => {
    if (section_id) {
      handleGetPerticularBlogGroup();
    }
  }, [section_id]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBlogGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        blog_section_title: blogGroupTitle,
        button_text: blogData?.button_text,
        button_link: blogData?.button_link,
        blog_limit: blogData?.blog_limit,
        blog_display_grid: blogData?.blog_display_grid,
        carousel_status: blogData?.carousel_status,
        status: blogData?.status,
        blog_image_width: blogData?.blog_image_width,
        blog_image_height: blogData?.blog_image_height,
        carousel_speed_ms: blogData?.carousel_speed_ms,
      });
      await API.put(`/admin/dynamicBlog/updateBlogGroup/${section_id}`, payload);
      getSectionData('call layout');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const getAiGeneratedTitle = (value) => {
    setBlogGroupTitle(value);
  };
  return (
    <>
      {/* <ConfirmationPopup heading="Delete Blog" subheading="Do you really want to delete this blog from this blog group?" confirmationPopup={confirmationPopup} handleCloseConfirm={handleCloseConfirm} runFunction={handleDelete} /> */}
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto'>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full space-y-4 mx-auto'
        >
          <div className='w-full space-y-4'>
            <div className='w-full mb-4'>
              <label htmlFor='group title' className='flex justify-between'>
                <label htmlFor='group title'>{t('lang_Blog_Group_Title')}</label>
                <GenerateLiveEditiorTitle
                  getAiGeneratedTitle={getAiGeneratedTitle}
                  section_type={section_type}
                />
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={blogGroupTitle}
                onChange={(e) => {
                  setBlogGroupTitle(e.target.value);
                  // handleChange();
                }}
                placeholder='Enter title....'
              />
            </div>
            <div className='grid grid-cols-1 gap-5 w-full'>
              <div className='w-full'>
                <label>{t('lang_Blog_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_limit}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_limit: e.target.value });
                      // handleChange();
                    }}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full'>
                <label>{t('lang_Blog_Display')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_display_grid}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_display_grid: e.target.value });
                      // handleChange();
                    }}
                  >
                    <option value={true}>{t('lang_Grid')}</option>
                    <option value={false}>{t('lang_List')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.carousel_speed_ms}
                    disabled={blogData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setBlogData({ ...blogData, carousel_speed_ms: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={blogData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => {
                    setBlogData({ ...blogData, button_text: e.target.value });
                    // handleChange();
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={blogData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => {
                    setBlogData({ ...blogData, button_link: e.target.value });
                    // handleChange();
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Width')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_width}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_image_width: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 318'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Height')}{' '}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_height}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_image_height: e.target.value });
                      // handleChange();
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 192'
                  />
                </div>
              </div>
              <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text font-medium text-[13px]'>{t('lang_Blog_Group_Status')}</p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={blogData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, status: !blogData?.status });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
              <div className='flex justify-between w-full border main-border-color  px-4 py-2 rounded-[6px] items-center'>
                <p className='table-text font-medium text-[13px]'>{t('lang_Carousel_Status')}</p>
                <label className='relative w-[20%] inline-flex items-center justify-end cursor-pointer'>
                  <FormControlLabel
                    checked={blogData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, carousel_status: !blogData?.carousel_status });
                      // handleChange();
                    }}
                  />
                </label>
              </div>
            </div>
            <hr className='border-t main-border-color'></hr>
            <div>
              {!blogGroupTitle || blogGroupTitle === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[130px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBlogGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Apply_Changes')}</span>
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditBlogSection;
