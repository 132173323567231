import { t } from 'i18next';
import React from 'react';

const ImageSettingSection = ({
  handleImageUpload,
  previewImage,
  section_id,
  handleImageSizeChange,
  currentImageSize,
  handleImageLinkChange,
  currentImageLink,
  saveElementorData,
  btnLoading,
}) => {
  const dropDownValue = [
    { value: { width: '150', height: '150' }, label: 'Thumbnail - 150 X 150' },
    { value: { width: '300', height: '300' }, label: 'Medium - 300 X 300' },
    { value: { width: '768', height: '768' }, label: 'Medium Large - 768 X 768' },
    { value: { width: '1024', height: '1024' }, label: 'Large - 1024 X 1024' },
  ];

  const handleSizeChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    handleImageSizeChange(section_id, selectedSize);
  };

  const currentImageSizeString = JSON.stringify(currentImageSize);

  const handleLinkChange = (e) => {
    const selectLink = e.target.value;
    handleImageLinkChange(section_id, selectLink);
  };

  return (
    <div>
      {previewImage && (
        <img
          src={previewImage?.url}
          alt='Uploaded Preview'
          className='w-full mb-5 max min-h-28 max-h-72 object-contain'
        />
      )}

      <input
        type='file'
        accept='image/*'
        name='url'
        onChange={(e) => handleImageUpload(e, section_id)}
        className='truncate max-w-full overflow-hidden block mb-5'
      />

      <div className='w-full'>
        <label>Image Resolution</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            onChange={handleSizeChange}
            value={currentImageSizeString}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4'>
        <label htmlFor='group title'>Link</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='group title'
          placeholder='Paste URL here...'
          onChange={handleLinkChange}
          value={currentImageLink}
        />
      </div>
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageSettingSection;
