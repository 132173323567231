import { Autocomplete, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import API from '../../API';
import { Trash2 } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const BankDetails = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [details, settDetails] = useState({
    account_number: '',
    IFSC_code: '',
    account_holder_name: '',
    swift_code: '',
    country: '',
  });
  const [isAdd, setIsAdd] = useState(false);
  const [editId, setEditId] = useState();
  const [isRemove, setIsRemove] = useState();
  const getBankData = async () => {
    try {
      const ApiCall = await API.get(`/admin/client/getBankDetail`);
      const result = decryptData(ApiCall?.data)
      if (result?.data) {
        setIsAdd(false);
        settDetails({
          account_number: result?.data?.account_number,
          IFSC_code: result?.data?.IFSC_code,
          account_holder_name: result?.data?.account_holder_name,
          swift_code: result?.data?.swift_code,
          country: result?.data?.country,
        });
        setEditId(result?.data?._id);
        setIsRemove(true);
      } else {
        setIsAdd(true);
      }
    } catch (error) {
      setIsAdd(true);
      console.log(error);
    }
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const addBankData = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    if (isAdd === true) {
      try {
        const payload = encryptData(details)
        await API.post(`/admin/client/addBankDetail`, payload);
        setIsNotification(true);
        setNotificationMsg('Bank details added successfully!');
        setSeverity('success');
        getBankData();
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const payload = encryptData(details)
        await API.put(`/admin/client/updateBankDetail/${editId}`, payload);
        setIsNotification(true);
        setNotificationMsg('Bank Data updated successfully!');
        setSeverity('success');
        getBankData();
      } catch (error) {
        console.log(error);
      }
    }
    setBtnLoading(false);
  };
  useEffect(() => {
    getBankData();
  }, []);
  const handleRemoveBankDetails = async (e) => {
    e.preventDefault();
    try {
      const ApiCall = await API.delete(`/admin/client/deleteBankDetail/${editId}`);
      setIsNotification(true);
      setNotificationMsg('bank data removed successfully!');
      setSeverity('success');
      settDetails({
        account_number: '',
        IFSC_code: '',
        account_holder_name: '',
        swift_code: '',
        country: '',
      });
      setEditId();
      setIsRemove(false);
      getBankData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className='grid grid-cols-2 w-full gap-5'>
        <div className='w-full'>
          <label htmlFor='addressLine1'>
            {t('lang_Account_Number')}
            <span className='text-red-500 ms-1'>*</span>
          </label>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='invoice_prefix'
            name='account_number'
            value={details?.account_number}
            onChange={(e) => settDetails({ ...details, account_number: e.target.value })}
            placeholder='e.g. NS'
          />
        </div>
        <div className='w-full'>
          <label htmlFor='addressLine1'>
            {t('lang_IFSC_Code')}
            <span className='text-red-500 ms-1'>*</span>
          </label>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='invoice_prefix'
            name='IFSC_code'
            value={details?.IFSC_code}
            onChange={(e) => settDetails({ ...details, IFSC_code: e.target.value })}
            placeholder='e.g. NS'
          />
        </div>
        <div className='w-full'>
          <label htmlFor='addressLine1'>
            {t('lang_Account_holder_name')}
            <span className='text-red-500 ms-1'>*</span>
          </label>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='invoice_prefix'
            name='account_holder_name'
            value={details?.account_holder_name}
            onChange={(e) => settDetails({ ...details, account_holder_name: e.target.value })}
            placeholder='e.g. NS'
          />
        </div>
        <div className='w-full'>
          <label htmlFor='addressLine1' className='flex items-center justify-between '>
           <p className='text-xs/[13px] mb-1'>{t('lang_swift_code')}</p>
           <a
            className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
            href='https://bank.codes/swift-code-search/'
            rel='noreferrer'
            target='_blank'
          >
            Get SWIFT code
          </a>
          </label>
          <input
            className='w-full mb-1 px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='invoice_prefix'
            name='swift_code'
            value={details?.swift_code}
            onChange={(e) => settDetails({ ...details, swift_code: e.target.value })}
            placeholder='e.g. NS'
          />
        </div>
        <div className='w-full'>
          <label htmlFor='addressLine1'>
            {t('lang_Country')}
            <span className='text-red-500 ms-1'>*</span>
          </label>
          <Autocomplete
            className='autocomplete'
            options={CountryCodes ? CountryCodes : []}
            getOptionLabel={(x) => x.name}
            disableClearable
            onChange={(e, v) => {
              settDetails({ ...details, country: v?.name });
            }}
            value={
              CountryCodes.find((country_code) => country_code?.name === details?.country) || null
            }
            renderInput={(name) => (
              <TextField
                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                placeholder='Select country'
                className='select-none cursor-pointer border-black'
                {...name}
              />
            )}
          />
        </div>
      </div>
      <div className='flex items-center justify-between'>
        {details?.country === '' ||
          details?.account_holder_name === '' ||
          details?.IFSC_code === '' ||
          details?.account_number === '' ? (
          <button
            disabled
            type='button'
            className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          >
            <span>
              {isAdd ? 'Add' : 'Update'} {t('lang_Bank_Details')}
            </span>
          </button>
        ) : btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[166px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            onClick={(e) => {
              addBankData(e);
            }}
            className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          >
            <span>
              {isAdd ? 'Add' : 'Update'} {t('lang_Bank_Details')}
            </span>
          </button>
        )}
        {isRemove && (
          <button
            type='button'
            onClick={(e) => handleRemoveBankDetails(e)}
            className='inline-flex items-center text-red-500 text-[13px] font-medium hover:underline transition duration-300'
          >
            <Trash2 className='w-4 h-4 me-1' /> {t('lang_Remove_Bank_Details')}
          </button>
        )}
      </div>
    </>
  );
};

export default BankDetails;
