import { Pagination } from '@mui/material';
import { ArrowLeft, Eye } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import Loader from '../Loader/Loader';
import Notification from '../Notification';

const PayoutHistory = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [settledAmounts, setSettledAmounts] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  var startRecord = (settledAmounts?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(settledAmounts?.currentPage * limit, settledAmounts?.totalRecords);
  const getSettledPayouts = async () => {
    try {
      let ApiCall = await API.post(`/admin/settlement/getSattlements`);
      const result = decryptData(ApiCall?.data)
      setSettledAmounts(result?.data);
      if (Number(startRecord) > Number(result?.data?.totalRecords)) {
        setPage(Page - 1);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getSettledPayouts();
  }, []);
  const [Page, setPage] = useState(getInitialPage());
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);

  // Table Data
  const columns = [
    {
      name: 'Received Amount',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>
          {FormatAmount(row?.pay_out_data?.payable_amount)}
        </p>
      ),
    },
    {
      name: 'Order Amount',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>
          {FormatAmount(row?.pay_out_data?.total_orders_amount)}
        </p>
      ),
    },
    {
      name: 'Shipping charges',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>
          {FormatAmount(
            row?.pay_out_data?.total_shipping_charges
              ? row?.pay_out_data?.total_shipping_charges
              : 0,
          )}
        </p>
      ),
    },
    {
      name: 'Transaction  charges',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>
          {FormatAmount(
            row?.pay_out_data?.transaction_charge ? row?.pay_out_data?.transaction_charge : 0,
          )}
        </p>
      ),
    },
    {
      name: 'Service Fee',
      cell: (row) => (
        <p className='line-clamp-2 text-center'>
          {FormatAmount(
            row?.pay_out_data?.shopeasy_service_fee ? row?.pay_out_data?.shopeasy_service_fee : 0,
          )}
        </p>
      ),
    },
    {
      name: 'Date',
      right: true,
      cell: (row) => <p className='line-clamp-2 text-center'>{formatDate(row?.createdAt)}</p>,
    },
    {
      name: 'Action',
      right: true,
      cell: (row) => (
        <Eye
          className='w-4 h-4 cursor-pointer'
          onClick={() => navigate('/payouts/payoutdetails', { state: row?._id })}
        />
      ),
    },
  ];
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/payouts');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_payouts'), path: '/payouts' },
                { name: t('lang_Payouts_History'), path: '/payouts/payout-history' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Payouts_History')}
            </h4>
          </div>
        </div>
        <div className='w-full mb-2.5 border border-gray-300 category-table inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
          <DataTable
            className='order-table'
            columns={columns}
            data={settledAmounts?.records}
            highlightOnHover
            progressComponent={<Loader />}
          />
        </div>
        {/* Paginator */}
        {settledAmounts?.records ? (
          <div className='bg-white border border-gray-300 flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
            <div className='hidden sm:block'>
              <p className='flex w-full  space-x-4'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${settledAmounts?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${settledAmounts?.totalRecords} Records`}
                </span>
              </p>
            </div>
            <div>
              {settledAmounts !== 1 ? (
                <Pagination
                  count={settledAmounts?.totalPages}
                  page={Page}
                  onChange={(e, v) => setPage(v)}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default PayoutHistory;
