import React from 'react';
import EditSpacerStyleContainer from './EditSpacerStyleContainer';

const EditSpacerStyleSection = ({
  previewImage,
  spacerDesign,
  setSpacerDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditSpacerStyleContainer
        spacerDesign={spacerDesign}
        setSpacerDesign={setSpacerDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditSpacerStyleSection;
