import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';

function EditCustomField() {
  const location = useLocation();
  const [fieldData, setFieldData] = useState({
    field_name: location?.state?.data?.custom_field_name,
    field_type: location?.state?.data?.custom_field_type,
  });
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleChange = (e) => {
    setFieldData({ ...fieldData, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        custom_field_name: fieldData?.field_name,
        custom_field_type: fieldData?.field_type,
      })
      const ApiCall = await API.put(
        `/admin/client/UpdateCustomField/${location?.state?.data?._id}`,
        payload
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        navigate('/store-setting');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/store-setting');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Store_Setting'), path: '/store-setting' },
                { name: t('lang_Edit_custom_field'), path: '/store-setting/editCustomField' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_custom_field')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly p-5'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-[90%] lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color p-[25px] shadow rounded-[6px]'>
              <div className='mb-4'>
                <label htmlFor='title'>
                  {t('lang_Custom_field_name')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-md focus:outline-none'
                  type='text'
                  name='field_name'
                  value={fieldData.field_name}
                  placeholder='Field name'
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div>
                <label htmlFor='title'>
                  {t('lang_Custom_field_type')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <div className='relative'>
                  <select
                    className='w-full ps-3 pe-4 py-1.5 border main-border-color rounded-md focus:outline-none'
                    name='field_type'
                    onChange={(e) => handleChange(e)}
                    value={fieldData.field_type}
                  >
                    <option value='select-type' selected>
                      {t('lang_Select_field_type')}
                    </option>
                    <option value='file' className='capitalize'>
                      {t('lang_file')}
                    </option>
                    <option value='text' className='capitalize'>
                      {t('lang_text')}
                    </option>
                    <option value='date' className='capitalize'>
                      {t('lang_date')}
                    </option>
                    <option value='number' className='capitalize'>
                      {t('lang_Number')}
                    </option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <Link
              to={`/store-profile`}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_store_setting')}
            </Link>
            <div>
              {fieldData?.field_name === '' || fieldData?.field_type === '' ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Registration_Field')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[235px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Registration_Field')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCustomField;
