import { Autocomplete, Box, Checkbox, Pagination, Popover, TextField, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

function Faqs() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPlanPermission } = useContext(PlanPermission);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [faqFilter, setFaqFilter] = useState('');
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [selectOption, setSelectOption] = useState();
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [FAQProducts, setFAQProducts] = useState([]);
  const [FAQCategory, setFAQCategory] = useState([]);
  const [tableData, setTableData] = useState();
  const [searchedProductID, setSearchedProductID] = useState('');
  const [clearRow, setClearRow] = useState(false);

  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetAllFaqs();
  };

  // Get Product FAQs
  const getProductFAQs = async (id) => {
    setSearchedProductID(id);
    if (faqFilter === 'product') {
      try {
        let ProductFAQ = await API.get(`/admin/faq/getFaqs?faq_type=product&product=${id}`);
        const result = decryptData(ProductFAQ?.data);
        setTableData(result?.data);
      } catch (error) {
        if (error?.response?.status === 882) {
          setPlanPermission({ isPlanPermissions: false, forModule: 'faq' });
        }
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
    if (faqFilter === 'category') {
      try {
        let categoryFAQ = await API.get(`/admin/faq/getFaqs?faq_type=category&categories=${id}`);
        const result = decryptData(categoryFAQ?.data);
        setTableData(result?.data);
      } catch (error) {
        if (error?.response?.status === 882) {
          setPlanPermission({ isPlanPermissions: false, forModule: 'faq' });
        }
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  const getFAQProducts = async () => {
    try {
      let FAQProducts = await API.get(`/admin/faq/GetFaqProduct`);
      const result = decryptData(FAQProducts?.data);
      setFAQProducts(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getFAQCategory = async () => {
    try {
      let faqCategory = await API.get(`/admin/faq/GetFaqCategory`);
      const result = decryptData(faqCategory?.data);
      setFAQCategory(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // paginate
  const [Page, setPage] = useState(getInitialPage());
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);

  var startRecord = (tableData?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(tableData?.currentPage * limit, tableData?.totalRecords);

  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetAllFaqs();
    }
  }, [search]);
  const GetAllFaqs = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/faq/getFaqs?page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
        }&faq_type=${faqFilter}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setTableData(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'faq' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetAllFaqs();
    setClearRow(!clearRow);
  }, [limit, Page, faqFilter]);
  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/faq/updateFaq/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = tableData?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = tableData?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = tableData?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => item._id)]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(tableData?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  // Api call For delete  Product
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      const data = await API.post(`/admin/faq/daleteFaq`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetAllFaqs();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  // Edit Request
  const Handleedit = (elem) => {
    if ((faqFilter === 'product' || faqFilter === 'category') && searchedProductID !== '') {
      navigate(`/faqs/update-faq/${elem._id}/true/${searchedProductID}`);
    } else {
      navigate(`/faqs/update-faq/${elem._id}/false/null`);
    }
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete FAQ'
        subheading='Do you really want to delete this FAQ from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_faqs'), path: '/faqs' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_faqs')} </h4>
            </div>
          </div>
          <div></div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/faqs/add-faq')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_Add_New_FAQ')}
            </button>
          </div>
        </div>
        <div className='w-full'>
          <div className='flex justify-between space-x-4 w-full relative'>
            <div className='w-full space-y-2'>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  {selectedRowsId && selectedRowsId?.length > 0 && (
                    <>
                      <span className='text-sm px-3'>
                        {selectedRowsId?.length} {t('lang_selected')}
                      </span>
                      |
                      <button
                        className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmationPopup(true);
                        }}
                      >
                        <Trash2 className='w-3.5 h-3.5' />
                        <span>{t('lang_delete_selection')}</span>
                      </button>
                    </>
                  )}
                </div>
                  {tableData?.totalRecords > 0 && <Tooltip title='Filter' arrow>
                    <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                      <button className=''>
                        <Filter className='h-5 w-5' />
                      </button>
                    </div>
                  </Tooltip>}
              </div>
              <div
                className={'flex w-full h-full overflow-hidden relative'}
              >
                <div className='w-full'>
                  {dataLoading && !tableData ? (
                    <TableSkeleton forTable='order' />
                  ) : tableData?.records?.length > 0 ? (
                    <div className='w-full blog-table  transition-all duration-100 inline-block mb-1 border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                      <>
                        <table className='w-full'>
                          <thead>
                            <tr>
                              <td className='table-head-checkbox px-3'>
                                <Checkbox
                                  checked={selectedRowsId?.length === tableData?.records?.length}
                                  indeterminate={
                                    selectedRowsId?.length !== tableData?.records?.length &&
                                    selectedRowsId?.length > 0
                                  }
                                  onChange={(e) => {
                                    handleSelectRow(e, 'all');
                                  }}
                                />
                              </td>
                              <td className='min-w-[300px] max-w-[300px] px-3'>
                                <div className='flex items-center'>
                                  <button
                                    className={`uppercase flex ${iconVisible && sortBy === 'question' ? 'text-gray-700' : ''
                                      }`}
                                    onMouseEnter={() => {
                                      setIconVisible(true);
                                      setSortBy('question');
                                    }}
                                    onMouseLeave={() => {
                                      setIconVisible(false);
                                      setSortBy('');
                                    }}
                                    onClick={() => SortData('question')}
                                  >
                                    {t('lang_Question')}
                                    {iconVisible && sortBy === `question` ? (
                                      !orderBy.isDecending ? (
                                        <CaretUpFill className='ms-2' />
                                      ) : (
                                        <CaretDownFill className='ms-2' />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </button>
                                </div>
                              </td>
                              <td className='w-full px-3'>
                                <div className='flex items-center'>
                                  <button
                                    className={`uppercase flex ${iconVisible && sortBy === 'answer' ? 'text-gray-700' : ''
                                      }`}
                                    onMouseEnter={() => {
                                      setIconVisible(true);
                                      setSortBy('answer');
                                    }}
                                    onMouseLeave={() => {
                                      setIconVisible(false);
                                      setSortBy('');
                                    }}
                                    onClick={() => SortData('answer')}
                                  >
                                    {t('lang_Answer')}
                                    {iconVisible && sortBy === `answer` ? (
                                      !orderBy.isDecending ? (
                                        <CaretUpFill className='ms-2' />
                                      ) : (
                                        <CaretDownFill className='ms-2' />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3'>
                                <div className='flex items-center'>
                                  <button
                                    className={`uppercase flex ${iconVisible && sortBy === 'faq_type' ? 'text-gray-700' : ''
                                      }`}
                                    onMouseEnter={() => {
                                      setIconVisible(true);
                                      setSortBy('faq_type');
                                    }}
                                    onMouseLeave={() => {
                                      setIconVisible(false);
                                      setSortBy('');
                                    }}
                                    onClick={() => SortData('faq_type')}
                                  >
                                    {t('lang_type')}
                                    {iconVisible && sortBy === `faq_type` ? (
                                      !orderBy.isDecending ? (
                                        <CaretUpFill className='ms-2' />
                                      ) : (
                                        <CaretDownFill className='ms-2' />
                                      )
                                    ) : (
                                      <></>
                                    )}
                                  </button>
                                </div>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3 text-right uppercase'>
                                {t('lang_action')}
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {tableData?.records?.map((elem, index) => (
                              <tr
                                className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                                  }`}
                                style={{
                                  // boxShadow: snapshot.isDragging
                                  //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                  //   : 'none',
                                  background: 'white',
                                }}
                                key={index}
                                onClick={() => Handleedit(elem)}
                              >
                                <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                  <Checkbox
                                    checked={
                                      selectedRowsId?.length > 0 &&
                                      selectedRowsId?.find((x) => x === elem?._id)
                                    }
                                    onChange={(e) => handleSelectRow(e, elem?._id)}
                                  />
                                </td>
                                <td className='min-w-[300px] max-w-[300px] px-3'>
                                  <span className='line-clamp-2'>{elem.question || '-'}</span>
                                </td>
                                <td className='w-full px-3'>
                                  <span className='line-clamp-2'>
                                    {elem.answer
                                      ? elem.answer?.length <= 30
                                        ? elem.answer.replace(/<[^>]*>/g, ' ')
                                        : elem.answer.replace(/<[^>]*>/g, ' ').substring(0, 30) +
                                        '...'
                                      : '-'}
                                  </span>
                                </td>
                                <td className='min-w-[150px] max-w-[150px] px-3'>
                                  {elem?.faq_type === 'product' ? (
                                    <span className='relative capitalize inline-block px-4 py-1 font-medium rounded-md  text-black-500 bg-blue-100 leading-tight'>
                                      {elem.faq_type}
                                    </span>
                                  ) : elem?.faq_type === 'category' ? (
                                    <span className='relative capitalize inline-block px-4 py-1 font-medium rounded-md  text-orange-500 bg-orange-100 leading-tight'>
                                      {elem.faq_type}
                                    </span>
                                  ) : elem?.faq_type === 'store' ? (
                                    <span className='relative capitalize inline-block px-4 py-1 font-medium rounded-md  text-green-600 bg-green-100 leading-tight'>
                                      {elem.faq_type}
                                    </span>
                                  ) : (
                                    <span className='relative capitalize inline-block px-4 py-1 font-medium rounded-md  text-yellow-600 bg-yellow-100 leading-tight'>
                                      {elem.path}
                                    </span>
                                  )}
                                </td>
                                <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                  <div className='space-x-2 w-full flex items-center justify-end'>
                                    <Tooltip title='Edit' arrow>
                                      <button
                                        href='#'
                                        className='text-xs font-thin text-black-500 hover:'
                                        onClick={() => {
                                          Handleedit(elem);
                                        }}
                                      >
                                        <Edit className='w-[17px] h-[17px]' />
                                      </button>
                                    </Tooltip>
                                    <Tooltip title='Delete' arrow>
                                      <button
                                        href='#'
                                        className='text-xs font-thin text-red-500 hover:text-red-600'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setConfirmationPopup(true);
                                          setSelectedRowsId([elem?._id]);
                                        }}
                                      >
                                        <Trash className='w-[17px] h-[17px]' />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    </div>
                  ) : (
                    <NoRecords />
                  )}
                  {/* Paginator */}
                  {tableData?.records?.length ? (
                    <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                      <span className='text-sm font-medium'>
                        {limit === 'all'
                          ? `${tableData?.totalRecords}` + ' ' + 'Records'
                          : `${startRecord} - ${endRecord} of ${tableData?.totalRecords} Records`}
                      </span>
                      <div>
                        {tableData?.totalPages !== 1 ? (
                          <Pagination
                            count={tableData?.totalPages}
                            page={Page}
                            onChange={(e, v) => setPage(v)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <Popover
                  open={filter}
                  anchorEl={anchorEl}
                  onClose={() => { setFilter(false); setAnchorEl(null) }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                  }}
                  className='rounded-[6px]'
                >
                  <div className='p-[20px] filter'>
                  <p className='flex text-primary font-medium uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5'>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_FAQ_Type')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-md w-full table-text font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={faqFilter}
                          onChange={(e) => {
                            setFaqFilter(e.target.value);
                            setPage(1);
                            e.target.value === 'product'
                              ? getFAQProducts()
                              : e.target.value === 'category'
                                ? getFAQCategory()
                                : '';
                          }}
                        >
                          <option value='' className='capitalize'>
                            {t('lang_all')}
                          </option>
                          <option value='store'>{t('lang_store')}</option>
                          <option value='product' className='capitalize'>
                            {t('lang_product')}
                          </option>
                          <option value='category'>{t('lang_category')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                    {faqFilter === 'product' && (
                      <div>
                        <span className='text-sm table-text font-medium mb-1 inline-block'>
                          {t('lang_FAQ_Type')}
                        </span>
                        <Autocomplete
                          className='autocomplete'
                          options={FAQProducts?.records?.length ? FAQProducts?.records : []}
                          getOptionLabel={(product) => product.product_name}
                          onChange={(e, v) =>
                            v ? getProductFAQs(v.product_id, 'product') : GetAllFaqs()
                          }
                          onHighlightChange={(event, option) =>
                            option ? setSelectOption(option?._id) : setSelectOption()
                          }
                          renderOption={(props, option) => (
                            <Box
                              component='li'
                              {...props}
                              className={`flex w-full space-x-2 border-b p-2 items-center cursor-pointer ${option?._id === selectOption
                                  ? 'primary-bg-color'
                                  : 'hover:bg-[#F0F0F0]'
                                }`}
                            >
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={option?.image || NOPHOTO_IMAGE}
                                className='object-contain border p-0.5'
                                style={{ width: '50px', height: '50px' }}
                                alt='product image'
                              />
                              <div>
                                <p>{option?.product_name}</p>
                                <p className='text-xs'>({option?.product_slug_name})</p>
                              </div>
                            </Box>
                          )}
                          renderInput={(products) => (
                            <TextField
                              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                              placeholder='Select product'
                              className='cursor-pointer'
                              {...products}
                            />
                          )}
                        />
                      </div>
                    )}
                    {faqFilter === 'category' && (
                      <div>
                        <span className='text-sm table-text font-medium mb-1 inline-block'>
                          {t('lang_categories')}
                        </span>
                        <Autocomplete
                          className='autocomplete'
                          options={FAQCategory?.records?.length ? FAQCategory?.records : []}
                          getOptionLabel={(category) => category.category_name}
                          onChange={(e, v) => (v ? getProductFAQs(v.category_id) : GetAllFaqs())}
                          onHighlightChange={(event, option) =>
                            option ? setSelectOption(option?._id) : setSelectOption()
                          }
                          renderOption={(props, option) => (
                            <Box
                              component='li'
                              {...props}
                              className={`flex w-full space-x-2 border-b p-2 items-center cursor-pointer ${option?._id === selectOption
                                  ? 'primary-bg-color'
                                  : 'hover:bg-[#F0F0F0]'
                                }`}
                            >
                              <img
                                onError={(e) => {
                                  e.target.src = NOPHOTO_IMAGE;
                                }}
                                src={option?.image || NOPHOTO_IMAGE}
                                className='object-contain border p-0.5'
                                style={{ width: '50px', height: '50px' }}
                                alt='category image'
                              />
                            </Box>
                          )}
                        />
                      </div>
                    )}

                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full  block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                          placeholder='Search FAQs'
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          }}
                        >
                          {tableData?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {tableData?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                          {tableData?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                          <option value='all'>All</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                  </div>
                  </Popover>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
