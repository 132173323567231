import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-apexcharts';
import API from '../../API';
import moment from 'moment-timezone';
import Notification from '../Notification';
import DatePicker from 'react-datepicker';
import { CalendarFill } from 'react-bootstrap-icons';
import { PlanPermission } from '../../App';
import { t } from 'i18next';
import { decryptData } from '../../controllers/encryptionUtils';
const EarningChart = () => {
  const originalDate = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [status, setStatus] = useState(2);
  const [AllDates, setAllDates] = useState([]);
  const [AllCount, setAllCount] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { setPlanPermission } = useContext(PlanPermission);
  const GetTotalBalance = async () => {
    try {
      let TotalBalance = await API.get(
        `/admin/analytics/totalEarning?status=${status}${
          startDate?.toString()?.length > 0
            ? `&startdate=${moment(startDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`
            : ''
        }${
          endDate?.toString()?.length > 0
            ? `&enddate=${moment(endDate).tz('Asia/Kolkata').format('YYYY-MM-DD')}`
            : ''
        } `,
      );
      const result = decryptData(TotalBalance?.data);
      setAllDates(result?.data?.prices?.map((x) => x?.date));
      setAllCount(result?.data?.prices?.map((x) => x?.price));
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Orders' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetTotalBalance();
  }, [startDate, endDate, status]);
  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      type: 'datetime',
      categories: AllDates?.length > 0 ? AllDates : [],
    },
    chart: {
      width: '100%',
      type: 'area',
    },
    stroke: {
      curve: 'smooth',
      width: [3],
    },
    colors: ['#bdc0c5'],
    tooltip: {
      x: {
        format: 'yy/MM/dd ',
      },
    },
  };
  const series = [
    {
      name: 'Earning',
      data: AllCount?.length > 0 ? AllCount : [],
    },
  ];
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color border main-border-color rounded-[6px] overflow-hidden'>
        <h6 className='border-b main-border-color font-medium py-3 px-4 text-lg'>
          {t('lang_Earnings')}
        </h6>
        <div className='display-grid py-3'>
          <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 px-4'>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>From</span>
              <label className='relative w-full block cursor-pointer'>
                <DatePicker
                  className={`${
                    startDate && 'bold-date'
                  } border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='w-full'>
              <span className='block text-xs mb-1 font-medium table-text'>{t('lang_to')}</span>
              <label className='relative block w-full cursor-pointer'>
                <DatePicker
                  className={`${
                    endDate && 'bold-date'
                  } border cursor-pointer w-full focus:border-gray-400 main-border-color outline-none`}
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  dateFormat='dd-MM-yyyy'
                />
                <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'>
                  <CalendarFill className='text-gray-600 w-3 h-3' />
                </span>
              </label>
            </div>
            <div className='max-w-full'>
              <label htmlFor='product' className='block text-xs mb-1 font-medium table-text'>
                {t('lang_filter_by')}
              </label>
              <div class='grid mb-1 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1'>
                <button
                  className={`tab ${status === 4 ? 'focus-tab' : 'bg-transparent'}`}
                  onClick={() => setStatus(4)}
                >
                  1 W
                </button>
                <button
                  className={`tab ${status === 1 ? 'focus-tab' : 'bg-transparent'}`}
                  onClick={() => setStatus(1)}
                >
                  1 M
                </button>
                <button
                  className={`tab ${status === 2 ? 'focus-tab' : 'bg-transparent'}`}
                  onClick={() => setStatus(2)}
                >
                  1 Y
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 max-w-full'>
          <Chart options={options} series={series} type='line' height={300} />
        </div>
      </div>
    </>
  );
};

export default EarningChart;
