import React from 'react';
import EditHeadingStyleContainer from './EditHeadingContainer';

const EditHeadingStyleSection = ({
  previewImage,
  headingDesign,
  setHeadingDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditHeadingStyleContainer
        headingDesign={headingDesign}
        setHeadingDesign={setHeadingDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditHeadingStyleSection;
