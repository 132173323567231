import { Checkbox, FormControlLabel, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import ReactDragListView from 'react-drag-listview';

const AllManufacturer = () => {
  const navigate = useNavigate();
  const [manData, setManData] = useState();
  const location = useLocation();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { setPlanPermission } = useContext(PlanPermission);
  const [page, setPage] = useState(getInitialPage());
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: 'ASC', isDecending: false });
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [clearRow, setClearRow] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [manufactureId, setManufactureId] = useState(null);
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  useEffect(() => {
    replacePageInUrl(page);
  }, [page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetManufacturers();
  };
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  var startRecord = (manData?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(manData?.data?.currentPage * limit, manData?.data?.totalRecords);
  useEffect(() => {
    if (search?.length > 0 || search?.length > 2) {
      GetManufacturers();
    }
  }, [search]);

  const GetManufacturers = async () => {
    setDataLoading(true);
    try {
      const data = await API.get(
        `/admin/manufactures/getManufacturers?page=${page}&limit=${limit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setManData(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'brand' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    GetManufacturers();
    setClearRow(!clearRow);
  }, [page, limit]);

  // Drag And Drop Functionality
  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex:
          index === 0 && page == 1
            ? 0
            : page != 1
            ? Number(index) + Number(limit) * Number(page - 1)
            : Number(index),
      });
      await API.put(`/admin/manufactures/updateManufacturer/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // const HandleSort = (result) => {
  //   const items = Array.from(manData?.data?.records);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setManData({ data: { ...manData?.data, records: items } });
  //   sortProduct(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...manData.data.records];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setManData({ data: { ...manData?.data, records: newItems } });
      sortProduct(toIndex, manufactureId);
      setManufactureId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = manData?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = manData?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const final = manData?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !final.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [...new Set([...prev, ...final.map((item) => item._id)])]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(manData?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  // delete Manufacturer
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async (id, b_name) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      await API.post(`/admin/manufactures/deleteManufacturer`, payload);
      GetManufacturers();
      setSelectedRowsId([]);
      setClearRow(!clearRow);
      // setPage(Number(page)-1)
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const SelectManufacturer = async (id, action) => {
    try {
      const payload = encryptData({
        is_selected: action === 'add' ? true : false,
      });
      const data = await API.put(`/admin/manufactures/updateManufacturer/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        GetManufacturers();
        setIsNotification(true);
        setNotificationMsg(
          action === 'add' ? 'Manufacture is set to active' : 'Manufacture is set to hidden',
        );
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Manufacture'
        subheading='Do you really want to delete this manufacture from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex flex-wrap items-end justify-between w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_Manufacturers_Dashboard'), path: `/dashboard` },
                  { name: t('lang_Manufacturers'), path: '/manufacturers' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Manufacturers')}{' '}
              </h4>
            </div>
          </div>
          <div></div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/manufacturers/add-manufacturer')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_Add_New_Manufacturer')}
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-3'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length}
                      {t('Selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>{t('lang_delete_selection')}</span>
                    </button>
                  </>
                )}
              </div>
                {manData?.data?.totalRecords > 0 && <Tooltip title='Filter' arrow>
                  <button className='filter-btn' onClick={(e) => {setFilter(!filter);setAnchorEl(e.currentTarget)}}>
                    <button className=''>
                      <Filter className='h-5 w-5' />
                    </button>
                  </button>
                </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full'>
                {dataLoading && !manData ? (
                  <TableSkeleton forTable='manufacture' />
                ) : manData?.data?.records?.length > 0 ? (
                  <div className='w-full  transition-all duration-100 sticky-table inline-block mb-2 border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr className='w-full'>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === manData?.data?.records?.length}
                              indeterminate={
                                selectedRowsId?.length !== manData?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='thead-image min-w-[80px]'>{t('lang_image')}</td>
                          <td className='min-w-[250px]'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${
                                  iconVisible && sortBy === 'brand_name' ? 'text-gray-700' : ''
                                }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('brand_name');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('brand_name')}
                              >
                                {t('lang_name')}
                                {iconVisible && sortBy === 'brand_name' ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='w-full px-3'>{t('lang_description')}</td>
                          <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                            {t('lang_active')}
                          </td>
                          <td className='px-3 min-w-[120px] max-w-[120px] text-right'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <ReactDragListView {...dragProps}>
                          {manData?.data?.records?.map((elem, index) => (
                            <tr
                              key={index}
                              style={{
                                // boxShadow: snapshot.isDragging
                                //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                                //   : 'none',
                                background: 'white',
                              }}
                              onDragStart={() => setManufactureId(elem?._id)}
                              className={`${
                                selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                              } select-none flex w-full`}
                              onClick={() => {
                                navigate(`/manufacturers/${elem?.brand_slug_name}`, {
                                  state: { elem },
                                });
                              }}
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  className='inline-block'
                                  onChange={(e) => handleSelectRow(e, elem?._id)}
                                />
                              </td>
                              <td className='table-image min-w-[80px]'>
                                <img
                                  width={50}
                                  height={50}
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  src={elem.brand_logo || NOPHOTO_IMAGE}
                                  alt={elem.brand_name}
                                />
                              </td>
                              <td className='min-w-[250px] max-w-[250px]'>
                                <span className='line-clamp-2 capitalize'>
                                  {elem.brand_name || '-'}
                                </span>
                              </td>
                              <td className='w-full px-3'>
                                <p className='line-clamp-2 text-xs'>
                                  {elem?.description
                                    ? elem?.description?.replace(/<[^>]*>/g, ' ')
                                    : '-'}
                                </p>
                              </td>
                              <td className='px-3 min-w-[120px] max-w-[120px] text-right' onClick={(e) => e.stopPropagation()}>
                                <label className='relative flex justify-end w-full'>
                                  <FormControlLabel
                                    checked={elem?.is_selected}
                                    control={<MuiSwitch />}
                                    onChange={() =>
                                      SelectManufacturer(
                                        elem._id,
                                        elem?.is_selected ? 'remove' : 'add',
                                      )
                                    }
                                  />
                                </label>
                              </td>
                              <td className='px-3 min-w-[120px] max-w-[120px] text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-2 flex w-full justify-end '>
                                  <div>
                                    <button
                                      href='#'
                                      className='text-xs font-thin text-black-500 hover:text-black-600'
                                      onClick={() => {
                                        navigate(`/manufacturers/${elem?.brand_slug_name}`, {
                                          state: { elem },
                                        });
                                      }}
                                    >
                                      <div>
                                        <Tooltip title='Edit' arrow>
                                          <button className=''>
                                            <Edit className='w-[17px] h-[17px]' />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </button>
                                  </div>
                                  <div>
                                    <button
                                      href='#'
                                      className='text-xs font-thin text-red-500 hover:text-red-600'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationPopup(true);
                                        setSelectedRowsId([elem?._id]);
                                      }}
                                    >
                                      <div>
                                        <Tooltip title='Delete' arrow>
                                          <button className=''>
                                            <Trash className='w-[17px] h-[17px]' />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </ReactDragListView>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {manData?.data ? (
                  <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${manData?.data?.totalRecords}` + ' ' + 'Records'
                        : `${startRecord} - ${endRecord} of ${manData?.data?.totalRecords} Records`}
                    </span>
                    <div>
                      {manData?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={manData?.data?.totalPages}
                          page={page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t('lang_filter')}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_search')}
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Manufacturers'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setPage(1);
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_set_items_limit')}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          setPage(1);
                        }}
                      >
                        {manData?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {manData?.data?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                        {manData?.data?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
                </div>
                </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllManufacturer;
