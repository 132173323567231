import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import { ChevronDown, Edit, Trash } from 'feather-icons-react/build/IconComponents';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import Notification from '../Notification';
import EditAdditionalLicencesField from './EditAdditionalLicencesField';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { CloudUpload } from 'react-bootstrap-icons';
import GenerateUniqueFilename from '../../controllers/GenerateUniqueFilename';
import { clientData, GetClient } from '../../controllers/GetClient';

const AdditionalLicensesField = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [severity, setSeverity] = useState();
  const [additionalLicensesField, setAdditionalLicensesField] = useState({
    field_name: '',
    value: '',
  });
  const [editAdditionalLicensesField, setEditAdditionalLicensesField] = useState({
    field_name: '',
    value: '',
  });
  const [storeAdditionalLicensesField, setStoreAdditionalLicensesField] = useState([]);
  const [openEditField, setOpenEditField] = useState(false);
  const [editId, setEditId] = useState();
  const [deleteId, setDeleteId] = useState();
  const handleChange = (e) => {
    setAdditionalLicensesField({ ...additionalLicensesField, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId?.length > 0) {
        const payload = encryptData(editAdditionalLicensesField);
        const apiCall = await API.put(
          `/admin/client/updateAdditionalLicensesFields/${editId}`,
          payload,
        );
        if (apiCall.status === 200) {
          getAdditionalLicensesField();
          setIsNotification(true);
          setNotificationMsg('Field update successfully!');
          setSeverity('success');
          setEditAdditionalLicensesField({
            field_name: '',
            value: '',
          });
          setOpenEditField(false);
          setEditId();
        }
      } else {
        const payload = await encryptData({ additional_licenses: additionalLicensesField });
        const apiCall = await API.post('/admin/client/addAdditionalLicensesFields', payload);
        if (apiCall.status === 200) {
          getAdditionalLicensesField();
          setIsNotification(true);
          setNotificationMsg('Field added successfully!');
          setSeverity('success');
          setAdditionalLicensesField({
            field_name: '',
            value: '',
          });
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getAdditionalLicensesField = async () => {
    try {
      const apiCall = await API.get('/admin/client/GetAdditionalLicensesFields');
      const result = decryptData(apiCall?.data);
      setStoreAdditionalLicensesField(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleEditField = async (data) => {
    setOpenEditField(true);
    setEditId(data?._id);
    setEditAdditionalLicensesField({
      field_name: data?.field_name,
      value: data?.value,
    });
  };
  const handleDeleteField = async () => {
    try {
      const apiCall = await API.delete(`/admin/client/deleteAdditionalLicensesFields/${deleteId}`);
      if (apiCall.status === 200) {
        getAdditionalLicensesField();
        setIsNotification(true);
        setNotificationMsg('Field delete successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const [Documentationloader, setDocumentationLoader] = useState(false);
  const [Documentationimage, setDocumentationimage] = useState();
  const [documentationErr, setdocumentationErr] = useState(false);
  useEffect(() => {
    getAdditionalLicensesField();
    if(clientData?.manufacturer_license) {
      setDocumentationimage(clientData?.manufacturer_license?.document)
    }
  }, []);
  const HandleDocumentation = async (file) => {
    setDocumentationLoader(true);
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    let parts = file?.name?.split('.');
    let Ext = parts ? parts[parts?.length - 1] : 'jpg';
    if (
      Ext === 'pdf' ||
      Ext === 'zip' ||
      Ext === 'csv' ||
      Ext === 'html' ||
      Ext === 'docx' ||
      Ext === 'xlsx' ||
      (Ext === 'pdf' && file.size < 200000000)
    ) {
      const formData = new FormData();
      formData.append('file', file, fileName);
      formData.append('folder', 'product');
      try {
        // const payload = encryptData(formData)
        const res = await API.post('/admin/fileupload/upload', formData);
        const result = decryptData(res?.data);
        setDocumentationimage(result?.data[0]);
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
      setdocumentationErr(false);
    } else {
      setdocumentationErr(true);
    }
    setDocumentationLoader(false);
  };
  const handleManufacturerLicense = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        clientInfo: {
          manufacturer_license: {
            document: Documentationimage,
            status: 'pending'
          }
        }
      }
      const ApiCall = await API.put('/admin/client/updateClient', payload);
      if(ApiCall?.status === 200 || ApiCall?.status === 304) {
        console.log({ApiCall});
        GetClient()
        setIsNotification(true);
        setNotificationMsg("Manufacturer license updated");
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Additional_Licenses_Fields')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_additional_licenses_fields_for_show_information')}
            </p>
          </div>
        </div>
        <>
          <form
            method='post'
            className='w-full text-sm mx-auto rounded-[6px] pb-[25px]'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className='mb-4'>
              <label htmlFor='title'>{`${t('lang_Additional_Licenses')} ${t('lang_Field_Name')}`}</label>
              <input
                className={`w-full px-3 py-2 border ${
                  additionalLicensesField?.field_name?.split(' ').length < 60
                    ? 'main-border-color'
                    : 'border-red-500'
                } rounded-[6px] focus:outline-none`}
                type='text'
                maxLength={40}
                value={
                  additionalLicensesField?.field_name?.length
                    ? additionalLicensesField?.field_name?.split(' ').slice(0, 40).join(' ')
                    : ''
                }
                name='field_name'
                placeholder='Enter field name'
                onChange={(e) => {
                  handleChange(e);
                }}
                required
              />
              <p className='w-full text-xs mt-0 text-gray-400'>
                {t('lang_Only_Character_Allowed', {
                  character: `${additionalLicensesField?.field_name?.length}/40`,
                })}
              </p>
            </div>
            <div className='mb-4'>
              <div className='flex items-center justify-between'>
                <label htmlFor='instagram' className='flex items-center justify-between'>
                  {`${t('lang_Additional_Licenses')} ${t('lang_value')}`}
                </label>
              </div>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='url'
                value={additionalLicensesField?.value}
                name='value'
                placeholder='Enter value'
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div className='w-full flex justify-end'>
              <button
                type='submit'
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              >
                {t('lang_Add_Additional_Licenses_Field')}
              </button>
            </div>
          </form>
          {storeAdditionalLicensesField?.length > 0 ? (
            <>
              <h6 className='text-lg font-medium my-4'>
                {t('lang_Additional_Licenses_Fields')}
              </h6>
              <div className='space-y-4'>
                {storeAdditionalLicensesField?.map((field, index) => {
                  return (
                    <div className=' w-full h-auto ' key={index}>
                      <div className='flex items-center w-full justify-between rounded-[6px] border main-border-color py-2 px-3 space-x-4 text-sm'>
                        <div className='grid table-text grid-cols-1 md:grid-cols-2 w-full gap-5'>
                          <p className='me-8 overflow-auto'>
                            <span className='font-medium'>{t('lang_Field_Name')} :</span>
                            <span className='ms-2 '>{field.field_name}</span>
                          </p>
                          <p className='overflow-auto'>
                            <span className='font-medium'>{t('lang_value')} :</span>
                            <span className='ms-2'>{field.value}</span>
                          </p>
                        </div>
                        <div className='flex mx-4 items-center space-x-4'>
                          <Tooltip title='Edit' arrow>
                            <button onClick={() => handleEditField(field)}>
                              <Edit className='w-4 h-4 text-black-500' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                setDeleteId(field?._id);
                                setConfirmationPopup(true);
                              }}
                            >
                              <Trash className='w-4 h-4 text-red-500' />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      </div>
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px] mt-4'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Additional_Licenses_Fields')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_additional_licenses_fields_for_show_information')}
            </p>
          </div>
        </div>
        <>
          <form
            method='put'
            className='w-full text-sm mx-auto rounded-[6px] pb-[25px]'
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            onSubmit={(e) => handleManufacturerLicense(e)}
          >
            <div className='w-full'>
                <label htmlFor='lang_Product_Documentation'>{t('lang_Product_Documentation')}</label>
                <label
                  htmlFor='lang_Product_Documentation'
                  className={`${!Documentationimage && !Documentationloader && 'file-tag-hover'} flex items-center h-[200px] min-w-full rounded px-4`}
                >
                  <label className='text-center w-full block font-medium text-sm cursor-pointer'>
                    <input
                      type='file'
                      name='image'
                      onChange={(e) => HandleDocumentation(e.target.files[0])}
                      className='hidden'
                    />
                    <div className='items-center gap-1 flex flex-col w-full'>
                      {!Documentationimage && !Documentationloader ? (
                        <>
                          <CloudUpload className='w-9 mb-1 inline-block mx-auto h-9' />
                          <p className='text-sm block font-medium'>
                            <span className='font-medium'>{t('lang_Click_To_Upload')}</span>
                          </p>
                          <div className='flex flex-wrap gap-1'>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_PDF')}</span>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_ZIP')}</span>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_HTML')}</span>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_DOCX')}</span>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_CSV')}</span>
                            <span className='tab focus-tab inline-block rounded-full'>{t('lang_XLSX')}</span>
                          </div>
                        </>
                      ) : Documentationloader === true ? (
                        <div className='nb-spinner'></div>
                      ) : (
                        <div className='text-left'>
                          <p className='font-medium inline-block max-w-full text-gray-600'>
                            {Documentationimage} {t('lang_uploaded')}
                          </p>
                          <button
                            className='ml-2 cursor-pointer inline-block text-link hover:underline text-md font-medium transition duration-300'
                            onClick={() => { setDocumentationimage('') }}
                          >
                            {t('lang_Replace_Documentation')}
                          </button>
                        </div>
                      )}
                    </div>
                  </label>
                  {documentationErr && (
                    <span className='text-sm bg-yellow-100 p-1 rounded-[6px]'>
                      <b className='uppercase'>{t('lang_note')}: </b> {t('lang_File_Details')}
                    </span>
                  )}
                </label>
              </div>
            <div className='w-full flex justify-end'>
              <button
                type='submit'
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              >
                {Documentationimage ? t('lang_Edit_Manufacture_Licenses_Field') : t('lang_Add_Manufacture_Licenses_Field')}
              </button>
            </div>
          </form>
        </>
      </div>
      {confirmationPopup && (
        <ConfirmationPopup
          heading='Delete Additional Licenses Field'
          subheading='Do you really want to delete this additional licenses field from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={handleDeleteField}
        />
      )}
      {openEditField && (
        <EditAdditionalLicencesField
          openEditField={openEditField}
          setOpenEditField={setOpenEditField}
          editAdditionalLicensesField={editAdditionalLicensesField}
          setEditAdditionalLicensesField={setEditAdditionalLicensesField}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default AdditionalLicensesField;
