import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';

const StaticSecondCtaSection = ({
  section_id,
  section_type,
  saveElementorData,
  btnLoading,
  sectionContent,
  descriptionContent,
  handleSecondCtaTitleValueChange,
  handleSecondCtaTagChange,
  headingLevel,
  handleSecondCtaDescriptionChange,
  formTitleValue,
  handleSecondCtaFormTitleValueChange,
  noteTitleValue,
  handleSecondCtaFormNoteValueChange,
  formTitleTag,
  handleSecondCtaFormTitleTagChange,
  urlValueContent,
  handleSecondCtaShortCodeValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [shortdescription, setShortDescription] = useState(descriptionContent);
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [formTitle, setFormTitle] = useState(formTitleValue);
  const [noteValue, setNoteValue] = useState(noteTitleValue);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [selectedFormTitleTag, setselectedFormTitleTag] = useState(formTitleTag || 'h2');
  const [shortCodeValue, setShortCodeValue] = useState(urlValueContent);

  const ShortquillRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setFormTitle(formTitleValue);
    setNoteValue(noteTitleValue);
    setShortDescription(descriptionContent);
    setselectedFormTitleTag(formTitleTag);
    setShortCodeValue(urlValueContent);
  }, [
    sectionContent,
    formTitleValue,
    noteTitleValue,
    descriptionContent,
    formTitleTag,
    urlValueContent,
  ]);  

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSecondCtaTitleValueChange(section_id, newValue);
  };

  const formTitleValueChange = (e) => {
    const newValue = e.target.value;
    setFormTitle(newValue);
    handleSecondCtaFormTitleValueChange(section_id, newValue);
  };

  const formNoteValueChange = (e) => {
    const newValue = e.target.value;
    setNoteValue(newValue);
    handleSecondCtaFormNoteValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSecondCtaTitleValueChange(section_id, value);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSecondCtaTagChange(section_id, newTag);
  };

  const handleHeadingFormTitleTagChange = (e) => {
    const newTag = e.target.value;
    setselectedFormTitleTag(newTag);
    handleSecondCtaFormTitleTagChange(section_id, newTag);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSecondCtaDescriptionChange(section_id, value);
  };

  const onShortCodeValueChange = (e) => {
    const newValue = e.target.value;
    setShortCodeValue(newValue);
  };

  const handleApplyChanges = () => {
    handleSecondCtaShortCodeValueChange(section_id, shortCodeValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div>
        <label>Enter Your Short Code</label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your short code here...'
          value={shortCodeValue}
          onChange={onShortCodeValueChange}
        />
      </div>
      <div className='flex justify-between mt-2 items-center'>
        <p>Update changes to form</p>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[12px] font-medium py-1 px-3 rounded-[4px] transition duration-300'
          onClick={handleApplyChanges}
        >
          Apply
        </button>
      </div>

      <div className='mb-4 mt-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='w-full mb-4'>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>

      <div className='w-full mb-4'>
        <label>Form title</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={formTitle}
          onChange={formTitleValueChange}
        />
      </div>

      <div className='w-full mb-4'>
        <label>Form Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedFormTitleTag}
            onChange={handleHeadingFormTitleTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='w-full mb-4'>
        <label>Form note</label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={noteValue}
          onChange={formNoteValueChange}
        />
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticSecondCtaSection;
