import React, { useEffect } from 'react';
import AceEditor from 'react-ace';

// Import necessary modes and themes
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/ext-language_tools.js';
import 'ace-builds/src-noconflict/theme-monokai';
import { t } from 'i18next';
// import 'ace-builds/src-noconflict/theme-solarized_dark';
// import 'ace-builds/src-noconflict/theme-github';
// import 'ace-builds/src-noconflict/theme-dracula';

const ace = require('ace-builds/src-noconflict/ace');
ace.config.set('basePath', 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/');
ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.3/src-noconflict/worker-javascript.js',
);

const HtmlSettingSection = ({ section_id, htmlValue, setHtmlValue, handleHtmlChange,saveElementorData,
  btnLoading, }) => {
  const isValidHtml = (value) => {
    const tagPattern = /<\/?[a-z][\s\S]*>/i;
    const isMalformed = /<\s*[^>]*[^\/]$/.test(value) || /<\s*[^>]*\s*\/\s*>/.test(value);
    return tagPattern.test(value) && !isMalformed;
  };

  const handleEditorChange = (value) => {
    setHtmlValue(value);
    handleHtmlChange(section_id, value);
  };

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
    .ace_editor, .ace_editor * {
      font-family: "Monaco", "Menlo", "Ubuntu Mono", "Droid Sans Mono", "Consolas", monospace !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      letter-spacing: 0 !important;
    }
  `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <div>
        <div className='w-full mb-4 h-44 mt-2'>
          <label htmlFor='group title'>HTML Code</label>
          <AceEditor
            mode='html'
            width='100%'
            height='100%'
            theme='monokai'
            onChange={handleEditorChange}
            line-height={1}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={htmlValue}
            wrapEnabled={true}
            setOptions={{
              useWorker: false,
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              foldStyle: 'manual',
            }}
            placeholder='Write Your Code Here...'
            editorProps={{ $blockScrolling: true }}
          />
        </div>
        <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
      </div>
    </>
  );
};

export default HtmlSettingSection;
