import { Tooltip } from '@mui/material';
import { ArrowRight, ChevronRight, RefreshCw, TrendingDown, TrendingUp, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { BagFill, CalendarFill, CreditCardFill, CurrencyDollar, CurrencyRupee, PeopleFill, PersonFill, TagFill, TagsFill, WalletFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import { clientData } from '../../controllers/GetClient';
import Loader from '../Loader/Loader';
import Notification from '../Notification';
import SkeletonLoader from '../Skeleton/SkeletonLoader';
import LightHouseData from './LightHouseData';
import PermissionDenied from './PermissionDenied';
import Wizard from './Wizard';
export const ProfileContext = createContext();
const DashBoard = () => {
  const [spinner, setSpinner] = useState(true);
  const [Permissions, setPermissions] = useState(false);
  const GetUserPermissions = async () => {
    try {
      let ApiCall = await API.get('/admin/role/getLoginUserRole');
      const result = decryptData(ApiCall?.data)
      setPermissions(result?.data?.permissions?.includes('dashboard') || result?.data?.permissions === 'all', 'check');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    GetUserPermissions();
  }, []);
  const [orderStatusVal, setOrderStatusVal] = useState('');
  const [productPending, setProductPending] = useState(true);
  const [orderPending, setOrderPending] = useState(true);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const token = localStorage.getItem('token');
  const [stockPending, setStockPending] = useState(true);
  const [customerpending, setcustomerpending] = useState(true);
  const originalDate = new Date();
  const [startDate, setStartDate] = useState(
    new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()),
  );
  const { setPlanPermission } = useContext(PlanPermission);
  const [endDate, setEndDate] = useState(new Date());
  const orderStatus = [
    //commented Status Are Our Old Status
    t('lang_return'), // return
    `${t('lang_return')} ${t('lang_declined')}`, //return declined
    `${t('lang_order')} ${t('lang_placed')}`, // 'order placed',
    t('lang_in_process'), //in process
    t('lang_ready_to_ship'),
    t('lang_refunded'), // 'refunded',
    t('lang_out_for_pickup'),
    t('lang_in_transit'),
    t('lang_reached_at_destination_hub'),
    t('lang_out_for_delivery'),
    t('lang_shipped'), // 'shipped',
    t('lang_delivered'), // 'delivered',
    t('lang_payment_pending'), // 'payment pending',
    t('lang_payment_failed'), // 'payment failed',
    t('lang_canceled'), // 'canceled',
    t('lang_denied'), // 'denied',
  ];
  const [isComplete, setIsComplete] = useState();
  const StoreComplete = async () => {
    setSpinner(true);
    try {
      const storecom = await API.get(`/admin/client/storeComplete`);
      if (storecom.status === 200 || storecom.status === 304) {
        const result = decryptData(storecom?.data)
        setIsComplete(result);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setSpinner(false);
  };
  const navigate = useNavigate();
  const clientInfo = clientData;

  useEffect(() => {
    localStorage.setItem('limit', 10);
  }, []);

  const highlightWithBold = (date) => {
    if (startDate && date.getTime() === startDate.getTime()) {
      return 'bold-date'; // Class for start date
    }
    if (endDate && date.getTime() === endDate.getTime()) {
      return 'bold-date'; // Class for end date
    }
    return '';
  };

  // Analytics For Dashboard
  // get customers
  const [customers, setCustomers] = useState([]);
  const Getcustomers = async () => {
    try {
      const getcus = await API.get(
        `/admin/analytics/totalcustomers?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (getcus.status === 200 || getcus.status === 304) {
        const result = decryptData(getcus?.data)
        setCustomers(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setcustomerpending(false);
    }
  };
  const customerTableData = customers?.data?.totalcustomers;
  const customerColumns = [
    {
      name: t('lang_name'),
      width: '180px',
      cell: (row) => <span className='capitalize'>{row?.name || '-'}</span>,
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: t('lang_email'),
      cell: (row) => <span className='line-clamp-2'>{row?.email || '-'}</span>,
      minWidth: '150px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_mobile_number')}</span>,
      width: '150px',
      cell: (row) => <span className='w-full block text-right pe-1'>{row?.phone || '-'}</span>,
    },
  ];
  //Latest Orders
  const [latestOrders, setLatestOrders] = useState([]);
  const GetLatestOrders = async () => {
    try {
      const glo = await API.get(
        `/admin/analytics/latestOrders?status=${orderStatusVal}&startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (glo.status === 200 || glo.status === 304) {
        const result = decryptData(glo?.data)
        setLatestOrders(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setOrderPending(false);
    }
  };
  const [dashboardData, setDashboardData] = useState({});

  const getDashBoardData = async () => {
    try {
      const apiCall = await API.get(`/admin/analytics/dashboardData?status=${orderStatusVal}&startdate=${moment(startDate)
        .tz('Asia/Kolkata')
        .format('YYYY-MM-DD')}&enddate=${moment(endDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}`)
      const result = decryptData(apiCall?.data)
      setDashboardData(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  }
  const latestOrderstableData = latestOrders?.data;
  const latestOrderColums = [
    {
      name: t('lang_id'),
      cell: (row) => <span>{row.order_id || '-'}</span>,
      sortable: true,
      selector: (row) => row.order_id,
      width: '80px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_date')}</span>,
      cell: (row) => (
        <div className='text-right w-full block pe-3'>
          {moment(row.string_order_date).format('DD-MM-YYYY')}
        </div>
      ),
      selector: (row) => row.string_order_date,
      sortable: true,
      width: '150px',
    },
    {
      name: t('lang_name'),
      cell: (row) => <span className='capitalize line-clamp-1'>{row.user?.name || '-'}</span>,
      sortable: true,
      selector: (row) => row.user?.name,
      width: '130px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_product')}</span>,
      cell: (row) => (
        <span className='capitalize w-full text-right pe-4'>{row.products.length || '-'}</span>
      ),
      selector: (row) => row.products.length,
      sortable: true,
    },
    {
      name: t('lang_status'),
      right: true,
      cell: (row) => (
        <div className='w-full -me-2 flex items-center justify-end text-right whitespace-nowrap'>
          {row.status === 'payment failed' ||
            row.status === 'denied' ||
            row.status === 'return declined' ? (
            <span className='status-bedge text-red-500 bg-red-100'>
              {row.status}
            </span>
          ) : row.status === 'canceled' ? (
            <>
              <span className='status-bedge text-gray-600 bg-gray-200'>
                {row.status}
              </span>
            </>
          ) : row.status === 'refunded' || row.status === 'order placed' ? (
            <>
              <span className='status-bedge text-sky-500 bg-sky-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'delivered' ? (
            <>
              <span className='status-bedge text-green-500 bg-green-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'in process' || row.status === 'in transit' ? (
            <>
              <span className='status-bedge ms-auto text-purple-500 bg-purple-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'ready to ship' ||
            row.status === 'reached at destination hub' ||
            row.status === 'out for pickup' ||
            row.status === 'out for delivery' ? (
            <>
              <span className='status-bedge text-black-500 bg-blue-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'shipped' ? (
            <>
              <span className='status-bedge text-orange-500 bg-orange-100'>
                {row.status}
              </span>
            </>
          ) : (
            <>
              <span className='status-bedge text-yellow-500 bg-yellow-100'>
                {row.status}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      name: <span className='text-right w-full block'>{t('lang_price')}</span>,
      cell: (row) => (
        <span className='capitalize w-full block text-right pe-3'>
          {FormatAmount(row.total_price)}
        </span>
      ),
      selector: (row) => row.total_price,
      sortable: true,
      width: '130px',
    },
  ];
  // top selling Products data
  const [TopProducts, setTopProducts] = useState([]);
  const GetTopProducts = async () => {
    try {
      const gtp = await API.get(
        `/admin/analytics/topsellingproducts?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}`,
      );
      if (gtp.status === 200 || gtp.status === 304) {
        const result = decryptData(gtp?.data)
        setTopProducts(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setProductPending(false);
    }
  };

  const topProductTableData = TopProducts?.data;
  const topProductColums = [
    {
      name: t('lang_product'),
      cell: (row) => <span className='line-clamp-2'>{row.product_name || '-'}</span>,
      sortable: true,
      selector: (row) => row.product_name,
      width: '200px',
    },
    {
      name: <span className='text-right w-full block'>{t('lang_sold')}</span>,
      cell: (row) => <span className='w-full block text-right pe-4'>{row.sold_count || '-'}</span>,
      selector: (row) => row.sold_count,
      sortable: true,
    },
    {
      name: t('lang_stock_status'),
      right: true,
      cell: (row) => (
        <div className='text-xs flex items-center justify-end whitespace-nowrap'>
          {row.quantity > 0 ? (
            <span className='status-bedge text-green-500 bg-green-100'>
              {t('lang_in_stock')}
            </span>
          ) : (
            <span className='status-bedge text-red-500 bg-red-100'>
              {t('lang_out_of_stock')}
            </span>
          )}
        </div>
      ),
      width: '150px',
    },
    {
      name: t('lang_model'),
      cell: (row) => <span className='line-clamp-2'>{row.model || '-'}</span>,
      selector: (row) => row.model,
      sortable: true,
    },
    {
      name: <span className='text-right w-full block'>{t('lang_price')}</span>,
      cell: (row) => (
        <span className='block w-full text-right pe-4'>{FormatAmount(row?.selling_price)}</span>
      ),
      selector: (row) => row.selling_price,
      sortable: true,
    },
  ];
  // Stock Reports Data
  const [stockReport, setStockReport] = useState([]);
  const GetstockInfo = async () => {
    try {
      const gsi = await API.get(
        `/admin/analytics/stockreport?startdate=${moment(startDate)
          .tz('Asia/Kolkata')
          .format('YYYY-MM-DD')}&enddate=${moment(endDate)
            .tz('Asia/Kolkata')
            .format('YYYY-MM-DD')}&limit=5`,
      );
      if (gsi.status === 200 || gsi.status === 304) {
        const result = decryptData(gsi?.data)
        setStockReport(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Dashboard' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setStockPending(false);
    }
  };
  const stockTableData = stockReport?.data;
  const stockColums = [
    {
      name: t('lang_product'),
      cell: (row) => <span className='line-clamp-2'>{row.product_name}</span>,
      sortable: true,
      selector: (row) => row.product_name,
      minWidth: '270px',
    },
    {
      name: <span className='w-full block text-right'>{t('lang_quantity')}</span>,
      cell: (row) => (
        <span className='block w-full text-right pe-4'>
          {row.quantity >= 5 && row.quantity <= 10 ? (
            <span className='text-yellow-600 font-medium'>
              {row.quantity}
            </span>
          ) : (row.quantity >= 0 && row.quantity <= 5) || !row?.quantity ? (
            <span className='text-red-600 font-medium'>
              {!row?.quantity ? 0 : row.quantity}
            </span>
          ) : (
            <span>{row.quantity}</span>
          )}
        </span>
      ),
      sortable: true,
      selector: (row) => row.quantity,
      minWidth: '150px',
    },
    {
      name: t('lang_price'),
      cell: (row) => (
        <span className='block text-right w-full'>{FormatAmount(row?.selling_price)}</span>
      ),
      right: true,
      sortable: true,
      width: '150px',
      selector: (row) => row.selling_price,
    },
    {
      name: <span className='w-full block text-right'>{t('lang_stock_status')}</span>,
      right: true,
      cell: (row) => (
        <div className='text-xs flex items-center justify-end text-right w-full'>
          {row.stock_status === 'in_stock' ? (
            <span className='status-bedge text-green-500 bg-green-100'>
              {t('lang_in_stock')}
            </span>
          ) : (
            <span className='status-bedge text-red-500 bg-red-100'>
              {t('lang_out_of_stock')}
            </span>
          )}
        </div>
      ),
      minWidth: '150px',
    },
  ];
  // const GetAnnouncemnet
  useEffect(() => {
    Getcustomers();
    GetTopProducts();
    GetstockInfo();
    getDashBoardData();
  }, [startDate, endDate]);
  useEffect(() => {
    GetLatestOrders();
  }, [orderStatusVal, endDate, startDate]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  let skip;
  const [skipWizard, setSkipWizard] = useState();
  useEffect(() => {
    StoreComplete();
    skip = sessionStorage.getItem('skipWizard');
    if (skip === 'true') {
      setSkipWizard(true);
    } else {
      setSkipWizard(false);
    }
  }, []);
  const handleSkipWizard = (value) => {
    setSkipWizard(value);
  };
  const handleReadAnnouncement = async (value) => {
    try {
      const payload = encryptData({
        id: value,
      })
      await API.post('/admin/announcement/UserReadAnnouncement', payload );
      getDashBoardData();
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <ProfileContext.Provider value={clientInfo}>
        {spinner ? (
          <SkeletonLoader />
        ) : (!isComplete?.data?.business_profile && !skipWizard) ||
          (!isComplete?.data?.payment_method && !skipWizard) ||
          (!isComplete?.data?.product && !skipWizard) ||
          (!isComplete?.data?.shipping_method && !skipWizard) ||
          (!isComplete?.data?.terms_and_conditions && !skipWizard) ||
          (!isComplete?.data?.privacy_policy && !skipWizard) ? (
          <Wizard handleSkipWizard={handleSkipWizard} />
        ) : Permissions ? (
          <div className='h-auto gap-[30px] flex flex-col overflow-hidden pb-[65px]'>
            <div className='flex gap-4 flex-wrap items-center justify-between'>
              <div>
                <Breadcrumbs crumbs={[{ name: t('lang_dashboard'), path: `/dashboard` }]} />
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_dashboard')}
                </h4>
              </div>
              <div className='flex gap-2 flex-wrap md:flex-nowrap items-center primary-bg-color'>
                <label className='flex items-center w-full cursor-pointer'>
                  <span className='text-sm font-medium table-text me-1 capitalize'>
                    {t('lang_from')}
                  </span>
                  <div className='relative'>
                  <DatePicker
                    className={`${startDate && "bold-date"} me-2 border table-text cursor-pointer focus:border-gray-400 border-gray-300 outline-none`}
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date()}
                    dayClassName={highlightWithBold}
                    label='from'
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='w-3 h-3 table-text' />
                  </span>
                  </div>
                </label>
                <label className='table-text flex items-center w-full cursor-pointer'>
                  <span className='text-sm font-medium me-1 capitalize'>{t('lang_to')}</span>
                  <div className='relative'>
                  <DatePicker
                    className={` ${endDate && "bold-date"} border cursor-pointer table-text focus:border-gray-400 border-gray-300 outline-none`}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    dayClassName={highlightWithBold}
                    dateFormat='dd-MM-yyyy'
                  />
                  <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                    <CalendarFill className='w-3 h-3 table-text' />
                  </span>
                  </div>
                </label>
              </div>
            </div>
            {dashboardData?.announcements?.length > 0 &&
              dashboardData?.announcements?.map((elem, index) => (
                <div className='banner min-h-[230px] p-[30px] flex flex-wrap justify-between secondary-bg-color rounded-[6px]'>
                  <div className='w-[50%] flex flex-col justify-between'>
                    <div>
                      <h5 className='font-semibold'>{elem?.announcement_title}</h5>                      
                      <p className='table-text text-[16px] font-medium mt-1'>{elem?.short_description}</p>
                    </div>
                    <div>
                      <button type='button' onClick={() => { handleReadAnnouncement(elem?._id); navigate('/announcement/viewannouncement',{ state: elem?._id })}} className='btn inline-block text-white text-sm'>{t('lang_Read_More')}</button>
                    </div>
                  </div>
                  <div>
                    <button type='button' onClick={() => handleReadAnnouncement(elem?._id)}>
                      <X />
                    </button>
                  </div>
                </div>
              ))
            }
            {/* <div className='flex items-center justify-between w-full'>
              <div>
                <Breadcrumbs crumbs={[{ name: t('lang_dashboard'), path: `/dashboard` }]} />
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_dashboard')}
                </h4>
              </div>
              <div className='hidden sm:flex justify-end'>
                <div className='flex items-center primary-bg-color'>
                  <label className='relative flex items-center w-full cursor-pointer'>
                    <span className='text-sm font-medium table-text me-1 capitalize'>
                      {t('lang_from')}
                    </span>
                    <DatePicker
                      className='me-2 border table-text cursor-pointer focus:border-gray-400 border-gray-300 outline-none'
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={new Date()}
                      label='from'
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='w-3 h-3 table-text' />
                    </span>
                  </label>
                  <label className='relative table-text flex items-center w-full cursor-pointer'>
                    <span className='text-sm font-medium me-1 capitalize'>{t('lang_to')}</span>
                    <DatePicker
                      className='border cursor-pointer table-text focus:border-gray-400 border-gray-300 outline-none'
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      dateFormat='dd-MM-yyyy'
                    />
                    <span className='absolute top-[50%] cursor-pointer -translate-y-[50%] inline-block right-5'>
                      <CalendarFill className='w-3 h-3 table-text' />
                    </span>
                  </label>
                </div>
              </div>
            </div> */}
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[30px]'>
              <div className='secondary-bg-color min-h-[160px] flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group gap-5'>
                <div className='flex items-center w-full justify-between'>
                  <div>
                    <span className='font-semibold text-base capitalize'>{`${t(
                      'lang_customers',
                    )}`}</span>
                    <p className='text-2xl font-bold text-primary'>{dashboardData?.totalcustomers || 0}</p>
                  </div><div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                    <PeopleFill className='w-6 h-6' />
                  </div>
                </div>
                <p className='flex flex-wrap items-center font-semibold'>
                  <span className={`${Math.sign(dashboardData?.percentageCustomer) === -1 ? 'text-red-600' : 'text-green-600'} flex items-center me-1`}>
                  {Math.sign(dashboardData?.percentageCustomer) === -1 ? <TrendingDown className="w-4 h-4 me-2" /> : <TrendingUp className="w-4 h-4 me-2" />}{dashboardData?.percentageCustomer}%
                  </span> {t('lang_From_Previous_Filter', {key: Math.sign(dashboardData?.percentageCustomer) === -1 ? 'Down' : 'Up'})}
                </p>
              </div>
              <div className='secondary-bg-color min-h-[160px] flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
                <div className='flex items-center w-full justify-between'>
                  <div>
                    <p className='font-semibold text-base capitalize'>{` ${t(
                      'lang_orders',
                    )}`}</p>
                    <p className='text-2xl font-bold text-primary'>{dashboardData?.totalorders || 0}</p>
                  </div>
                  <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                    <BagFill className='w-6 h-6' />
                  </div>
                </div>
                <p className='flex flex-wrap items-center font-semibold'>
                  <span className={`${Math.sign(dashboardData?.percentageTotalorders) === -1 ? 'text-red-600' : 'text-green-600'} flex items-center me-1`}>
                    {Math.sign(dashboardData?.percentageTotalorders) === -1 ? <TrendingDown className="w-4 h-4 me-2" /> : <TrendingUp className="w-4 h-4 me-2" />}{dashboardData?.percentageTotalorders}%
                  </span> {t('lang_From_Previous_Filter', {key: Math.sign(dashboardData?.percentageTotalorders) === -1 ? 'Down' : 'Up'})}
                </p>
              </div>
              <div className='secondary-bg-color min-h-[160px] flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
                <div className='flex items-center justify-between'>
                  <div>
                    <p className='font-semibold text-base capitalize'>{`${t(
                      'lang_products',
                    )}`}</p>
                    <p className='text-2xl font-bold text-primary'>{dashboardData?.totalproducts || 0}</p>
                  </div>
                  <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                    <TagFill className='w-6 h-6' />
                  </div>
                </div>
                <p className='flex flex-wrap items-center font-semibold'>
                  <span className={`${Math.sign(dashboardData?.percentageProduct) === -1 ? 'text-red-600' : 'text-green-600'} flex items-center me-1`}>
                    {Math.sign(dashboardData?.percentageProduct) === -1 ? <TrendingDown className="w-4 h-4 me-2" /> : <TrendingUp className="w-4 h-4 me-2" />}{dashboardData?.percentageProduct}%
                  </span> {t('lang_From_Previous_Filter', {key: Math.sign(dashboardData?.percentageProduct) === -1 ? 'Down' : 'Up'})}
                </p>
              </div>
              <div className='secondary-bg-color min-h-[160px] flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
                <div className='flex items-center justify-between'>
                  <div>
                    <p className='font-semibold text-base capitalize'>{`Credit`}</p>
                    <p className='text-2xl font-bold text-primary'>{FormatAmount(dashboardData?.credit) || 0}</p>
                  </div>
                  <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                    <CreditCardFill className='w-6 h-6' />
                  </div>
                </div>
                <p className='flex flex-wrap items-center font-semibold'>
                  <span className={`${Math.sign(dashboardData?.percentageCredit) === -1 ? 'text-red-600' : 'text-green-600'} flex items-center me-1`}>
                    {Math.sign(dashboardData?.percentageCredit) === -1 ? <TrendingDown className="w-4 h-4 me-2" /> : <TrendingUp className="w-4 h-4 me-2" />}{dashboardData?.percentageCredit}%
                  </span> {t('lang_From_Previous_Filter', {key: Math.sign(dashboardData?.percentageCredit) === -1 ? 'Down' : 'Up'})}
                </p>
              </div>
              <div className='secondary-bg-color min-h-[160px] flex-col shadow rounded-[6px] flex justify-between p-5 text-primary font-medium group'>
                <div className='flex flex-wrap items-center justify-between'>
                  <div>
                    <p className='font-semibold text-base capitalize'>{`Return`}</p>
                    <p className='text-2xl font-bold text-primary'>{FormatAmount(dashboardData?.refund) || 0}</p>
                  </div>
                  <div className='flex justify-center items-center w-14 h-14 primary-bg-color rounded-full transition-all duration-300 transform group-hover:rotate-12'>
                    <WalletFill className='w-6 h-6' />
                  </div>
                </div>
                <p className='flex flex-wrap items-center font-semibold'>
                  <span className={`${Math.sign(dashboardData?.percentageRefund) === -1 ? 'text-red-600' : 'text-green-600'} flex items-center me-1`}>
                    {Math.sign(dashboardData?.percentageRefund) === -1 ? <TrendingDown className="w-4 h-4 me-2" /> : <TrendingUp className="w-4 h-4 me-2" />}{dashboardData?.percentageRefund}%
                  </span> {t('lang_From_Previous_Filter', {key: Math.sign(dashboardData?.percentageRefund) === -1 ? 'Down' : 'Up'})}
              </p>
              </div>
            </div>
            <LightHouseData />
            {/* ./Statistics Cards */}
            <div className='dashboard grid grid-cols-1 xl:grid-cols-2 gap-[30px]'>
              {/* Recent Orders  */}
              {/* <SkeletonLoader /> */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[6px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-3'>
                    <h6 className='font-medium text-primary text-base capitalize dark:text-gray-50'>
                      {`${t('lang_recent')} ${t('lang_orders')}`}
                    </h6>
                    <button
                      className='text-primary flex items-center text-[14px] hover:underline font-semibold capitalize transition-all duration-200'
                      type='button'
                      onClick={() => navigate(`/orders`, { state: { orderStatusVal } })}
                    >
                      <span className='inline-block me-1'>{`${t('lang_view')} ${t('lang_more')}`}</span>
                      <ChevronRight className='w-4 h-4' />
                    </button>
                  </div>
                  <div className='flex items-center overflow-x-auto'>
                    <DataTable
                      columns={latestOrderColums}
                      data={latestOrderstableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={orderPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Recent Orders*/}
              {/* Top Selling Products */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[6px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-3'>
                    <h6 className='font-medium text-primary text-base capitalize dark:text-gray-50'>
                      {`${t('lang_top')} ${t('lang_selling')} ${t('lang_products')}`}
                    </h6>
                    <button
                      className='text-primary flex items-center text-[14px] hover:underline font-semibold capitalize transition-all duration-200'
                      type='button'
                      onClick={() =>
                        navigate('/products', { state: { accending: true, field: 'sold_count' } })
                      }
                    >
                      <span className='inline-block me-1'>{`${t('lang_view')} ${t('lang_more')}`}</span>
                      <ChevronRight className='w-4 h-4' />
                    </button>
                  </div>
                  <div className='block w-full overflow-x-auto'>
                    <DataTable
                      columns={topProductColums}
                      data={topProductTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={productPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Top Selling Products */}
              {/* Stock Report */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[6px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-2'>
                    <h6 className='font-medium text-primary text-base capitalize  dark:text-gray-50'>
                      {`${t('lang_stock')} ${t('lang_report')}`}
                    </h6>
                    <button
                      className='text-primary flex items-center text-[14px] hover:underline font-semibold capitalize transition-all duration-200'
                      type='button'
                      onClick={() =>
                        navigate('/products', { state: { accending: true, field: 'quantity' } })
                      }
                    >
                      <span className='inline-block me-1'>{`${t('lang_view')} ${t('lang_more')}`}</span>
                      <ChevronRight className='w-4 h-4' />
                    </button>
                  </div>
                  <div className='block w-full overflow-x-auto'>
                    <DataTable
                      columns={stockColums}
                      data={stockTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={stockPending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
              {/* ./Stock Report */}
              {/* Customers */}
              <div className='relative shadow flex flex-col min-w-0 break-words secondary-bg-color dark:bg-gray-800 w-full rounded-[6px]'>
                <div className='rounded-t mb-0 px-0 border-0'>
                  <div className='flex flex-wrap items-center justify-between px-4 py-2'>
                    <h6 className='font-medium text-primary text-base  dark:text-gray-50'>
                      {t('lang_customers')}
                    </h6>
                    <button
                      className='text-primary flex items-center text-[14px] hover:underline font-semibold capitalize transition-all duration-200'
                      type='button'
                      onClick={() => navigate('/customers')}
                    >
                      <span className='inline-block me-1'>{`${t('lang_view')} ${t('lang_more')}`}</span>
                      <ChevronRight className='w-4 h-4' />
                    </button>
                  </div>
                  <div className='block w-full overflow-x-auto rounded-[6px]'>
                    <DataTable
                      columns={customerColumns}
                      data={customerTableData}
                      fixedHeader
                      highlightOnHover
                      progressPending={customerpending}
                      progressComponent={<Loader />}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <PermissionDenied />
        )}
      </ProfileContext.Provider>
    </>
  );
};

export default DashBoard;
