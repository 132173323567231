import { ArrowLeft, Search } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModuleData } from '../../Assets/StaticData/ModuleData';
import Breadcrumbs from '../../controllers/breadCrumbs';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

function ModuleSetting() {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [search, setSearch] = useState('');
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const navigate = useNavigate();
  let filteredModules = [];
  filteredModules = ModuleData.filter((module) => {
    const regex = new RegExp(search, 'i'); // 'i' flag for case-insensitive search
    return module.name.match(regex) || module.discripion.match(regex);
  });
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Module_Setting'), path: '/module-setting' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold block text-primary'>
              {t('lang_Module_Setting')}
            </h4>
          </div>
        </div>
        <div className='w-full flex justify-end'>
          <div className='relative w-full lg:w-[50%] secondary-bg-color mb-4'>
            <input
              type='text'
              className='w-full outline-none focus:border-gray-500 border main-border-color rounded-[6px]'
              placeholder='Search modules...'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Search className='w-4 h-4 absolute right-3 top-[50%] -translate-y-[50%]' />
          </div>
        </div>
        {filteredModules?.length > 0 ? (
          <div className='w-full module-table inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
            <table className='w-full h-full'>
              <thead className='w-full'>
                <tr className='h-[50px]'>
                  <td className='px-4 min-w-[400px]'>{t('lang_Module_Name')}</td>
                  <td className='px-4 w-full text-right'>{t('lang_action')}</td>
                </tr>
              </thead>
              <tbody>
                {filteredModules?.map((elem, index) => {
                  return (
                    <tr
                      key={index}
                      className='w-full border-b main-border-color cursor-pointer secondary-bg-color hover:bg-[#fafafa]'
                      onClick={() => navigate(elem?.redirect)}
                    >
                      <td className='w-full p-5'>
                        <span className='text-base'>{elem?.name}</span>
                        <p className='table-text text-[13px]'>{elem?.discripion}</p>
                      </td>
                      <td className='w-full text-right p-5'>
                        <button className='bulk-btn'>{t('lang_Configure')}</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <NoRecords />
        )}
      </div>
    </>
  );
}

export default ModuleSetting;
