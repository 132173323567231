import { Dialog, Skeleton } from '@mui/material';
import { ChevronDown, ChevronLeft, PlusCircle, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import API from '../../../API';
import { Extention } from '../../../App';
import AddSectionElementor from '../../../controllers/AddSectionElementor';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import { clientData, GetClient } from '../../../controllers/GetClient';
import SelectedStructureSection from '../../../controllers/SelectedStructureSection';
import { Transition } from '../../../controllers/Transition';
import '../../../EditSectionStyle.css';
import AddLayout from '../../Layout/AddLayout';
import AnnouncementSection from '../../Layout/AnnouncementBar/AnnouncementSection';
import VisualAnnounccementBar from '../../Layout/AnnouncementBar/VisualAnnounccementBar';
import EditBannerSection from '../../Layout/EditSection/EditBannerSection';
import EditBannerStyleSection from '../../Layout/EditSection/EditBannerStyleSection/EditBannerStyleSection';
import EditBlogSection from '../../Layout/EditSection/EditBlogSection';
import EditBlogStyleSection from '../../Layout/EditSection/EditBlogStyleSection/EditBlogStyleSection';
import EditCategorySection from '../../Layout/EditSection/EditCategorySection';
import EditCategoryStyleSection from '../../Layout/EditSection/EditCategoryStyleSection/EditCategoryStyleSection';
import EditCmsOfferStyleSection from '../../Layout/EditSection/EditCmsOfferStyleSection/EditCmsOfferStyleSection';
import EditCmsSection from '../../Layout/EditSection/EditCmsSection';
import EditManufactureSection from '../../Layout/EditSection/EditManufactureSection';
import EditManufactureStyleSection from '../../Layout/EditSection/EditManufactureStyleSection/EditManufactureStyleSection';
import EditProductSection from '../../Layout/EditSection/EditProductSection';
import EditProductStyleSection from '../../Layout/EditSection/EditProductStyleSection/EditProductStyleSection';
import EditReassuranceSection from '../../Layout/EditSection/EditReassuranceSection';
import EditReassuranceStyleSection from '../../Layout/EditSection/EditReassuranceStyleSection/EditReassuranceStyleSection';
import EditSliderSection from '../../Layout/EditSection/EditSliderSection';
import EditSliderStyleSection from '../../Layout/EditSection/EditSliderStyleSection/EditSliderStyleSection';
import FooterSection from '../../Layout/FooterSection';
import HeaderSection from '../../Layout/HeaderSection';
import HomePageSection from '../../Layout/LiveEditor/HomePageSection';
import HomePageSectionList from '../../Layout/SectionList/HomePageSectionList';
import Footer from '../../Layout/VisualLayout/Footer';
import StoreNavbar from '../../Layout/VisualLayout/StoreNavbar';
import Notification from '../../Notification';
import AddElementsStyleSection from '../../Layout/EditSection/AddElements/AddElementsStyleSection';
// import bgImageDefault from '../../../Assets/dummy-img.svg';
import placeholder from '../../../Assets/placeholder.png';
import defaultVideo from '../../../Assets/defaultVideo.mp4';
import defaultIcon from '../../../Assets/default-icon.svg';
import listItem1 from '../../../Assets/list-item-1.svg';
import listItem2 from '../../../Assets/list-item-2.svg';
import listItem3 from '../../../Assets/list-item-3.svg';
import facebookIcon from '../../../Assets/facebook-icon.svg';
import twitter from '../../../Assets/twitter.svg';
import youtube from '../../../Assets/youtube.svg';
import { styleSection } from '../../../Assets/StaticData/AddStyleSection';
import HeadingSettingSection from '../../Layout/EditSection/EditElementorSection/HeadingSettingSection';
import ImageSettingSection from '../../Layout/EditSection/EditElementorSection/ImageSettingSection';
import TextEditorSettingSection from '../../Layout/EditSection/EditElementorSection/TextEditorSettingSection';
import ButtonSettingSection from '../../Layout/EditSection/EditElementorSection/ButtonSettingSection';
import DividerSettingSection from '../../Layout/EditSection/EditElementorSection/DividerSettingSection';
import SpacerSettingSection from '../../Layout/EditSection/EditElementorSection/SpacerSettingSection';
import GoogleMapSettingSection from '../../Layout/EditSection/EditElementorSection/GoogleMapSettingSection';
import IconSettingSection from '../../Layout/EditSection/EditElementorSection/IconSettingSection';
import ImageBoxSettingSection from '../../Layout/EditSection/EditElementorSection/ImageBoxSettingSection';
import ImageIconSettingSection from '../../Layout/EditSection/EditElementorSection/ImageIconSettingSection';
import IconListSettingApply from '../../Layout/EditSection/EditElementorSection/IconListSettingApply';
import CounterSettingSection from '../../Layout/EditSection/EditElementorSection/CounterSettingSection';
import TestimonialSetting from '../../Layout/EditSection/EditElementorSection/TestimonialSetting';
import TabSettingSection from '../../Layout/EditSection/EditElementorSection/TabSettingSection';
import AccordionSettingSection from '../../Layout/EditSection/EditElementorSection/AccordionSettingSection';
import AlertSettingSection from '../../Layout/EditSection/EditElementorSection/AlertSettingSection';
import SocialIconSettingSection from '../../Layout/EditSection/EditElementorSection/SocialIconSettingSection';
import HtmlSettingSection from '../../Layout/EditSection/EditElementorSection/HtmlSettingSection';
import RatingSettingSection from '../../Layout/EditSection/EditElementorSection/RatingSettingSection';
import VideoSettingSection from '../../Layout/EditSection/EditElementorSection/VideoSettingSection';
import EditHeadingStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditHeadingStyleSection/EditHeadingStyleSection';
import EditImageStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditImageStyleSection/EditImageStyleSection';
import EditTextEditorStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTextEditorStyleSection/EditTextEditorStyleSection';
import EditVideoStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditVideoStyleSection/EditVideoStyleSection';
import EditButtonStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditButtonStyleSection/EditButtonStyleSection';
import EditDividerStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditDividerStyleSection/EditDividerStyleSection';
import EditImageBoxStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditImageBoxStyleSection/EditImageBoxStyleSection';
import EditMapStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditMapStyleSection/EditMapStyleSection';
import EditIconStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconStyleSection/EditIconStyleSection';
import EditIconBoxStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconBoxStyleSection/EditIconBoxStyleSection';
import EditIconListStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditIconListStyleSection/EditIconListStyleSection';
import EditCounterStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditCounterStyleSection/EditCounterStyleSection';
import EditTabStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTabStyleSection/EditTabStyleSection';
import EditSpacerStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditSpacerStyleSection/EditSpacerStyleSection';
import EditTestiMonialStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditTestiMonialStyleSection/EditTestiMonialStyleSection';
import EditAccordianStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditAccordianStyleSection/EditAccordianStyleSection';
import EditRatingStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditRatingStyleSection/EditRatingStyleSection';
import EditSocialIconStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditSocialIconStyleSection/EditSocialIconStyleSection';
import EditAlertStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditAlertStyleSection/EditAlertStyleSection';
import EditHtmlStyleSection from '../../Layout/EditSection/EditElementorStyleSection/EditHtmlStyleSection/EditHtmlStyleSection';
import StaticFirstContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstContentSection';
import EditFirstContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstContentStyleContainer';
import StaticSecondContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondContentSection';
import EditSecondContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondContentStyleContainer';
import StaticThirdContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdContentSection';
import EditThirdContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdContentStyleContainer';
import StaticForthContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthContentSection';
import EditForthContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthContentStyleContainer';
import StaticFifthContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFifthContentSection';
import EditFifthContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFifthContentStyleContainer';
import StaticSixContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSixContentSection';
import EditSixContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSixContentStyleContainer';
import StaticSevenContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSevenContentSection';
import EditSevenContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSevenContentStyleContainer';
import StaticEightContentSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticEightContentSection';
import EditEightContentStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditEightContentStyleContainer';
import StaticFirstFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstFeatureSection';
import EditFirstFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstFeatureStyleContainer';
import StaticSecondFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondFeatureSection';
import EditSecondFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondFeatureStyleContainer';
import StaticThirdFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdFeatureSection';
import EditThirdFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdFeatureStyleContainer';
import StaticForthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthFeatureSection';
import EditForthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthFeatureStyleContainer';
import StaticFifthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFifthFeatureSection';
import EditFifthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFifthFeatureStyleContainer';
import StaticSixthFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSixthFeatureSection';
import EditSixthFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSixthFeatureStyleContainer';
import StaticSevenFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSevenFeatureSection';
import EditSevenFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSevenFeatureStyleContainer';
import StaticEightFeatureSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticEightFeatureSection';
import EditEightFeatureStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditEightFeatureStyleContainer';
import StaticFirstStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstStepSection';
import EditFirstStepStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstStepStyleContainer';
import StaticThirdStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdStepSection';
import EditThirdStepStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdStepStyleContainer';
import StaticFirstTeamSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstTeamSection';
import EditFirstTeamStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstTeamStyleContainer';
import StaticSecondStepSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondStepSection';
import StaticSecondTeamSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondTeamSection';
import EditSecondTeamStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondTeamStyleContainer';
import StaticThirdTeamSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdTeamSection';
import EditThirdTeamStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdTeamStyleContainer';
import StaticFirstTestimonialSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstTestimonialSection';
import EditFirstTestimonialStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstTestimonialStyleContainer';
import StaticSecondTestimonialSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondTestimonialSection';
import EditSecondTestimonialStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondTestimonialStyleContainer';
import StaticThirdTestimonialSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdTestimonialSection';
import EditThirdTestimonialStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdTestimonialStyleContainer';
import StaticFirstStatisticSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstStatisticSection';
import EditFirstStatisticStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstStatisticStyleContainer';
import StaticSecondStatisticSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondStatisticSection';
import EditSecondStatisticStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondStatisticStyleContainer';
import StaticThirdStatisticSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdStatisticSection';
import EditThirdStatisticStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdStatisticStyleContainer';
import EditSecondStepStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondStepStyleContainer';
import StaticFirstContactSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstContactSection';
import EditFirstContactStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstContactStyleContainer';
import StaticSecondContactSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondContactSection';
import EditSecondContactStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondContactStyleContainer';
import StaticThirdContactSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdContactSection';
import EditThirdContactStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdContactStyleContainer';
import StaticFirstCtaSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstCtaSection';
import EditFirstCtaStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstCtaStyleContainer';
import StaticSecondCtaSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondCtaSection';
import EditSecondCtaStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondCtaStyleContainer';
import StaticThirdCtaSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdCtaSection';
import EditThirdCtaStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdCtaStyleContainer';
import ForthStaticCtaSection from '../../Layout/EditSection/DynamicSectionEditor/StaticContentSection/ForthStaticCtaSection';
import StaticForthCtaSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthCtaSection';
import EditForthCtaStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthCtaStyleContainer';
import StaticFirstHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstHeroSection';
import EditFirstHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstHeroStyleContainer';
import StaticSecondHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondHeroSection';
import EditSecondHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondHeroStyleContainer';
import StaticThirdHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticThirdHeroSection';
import EditThirdHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditThirdHeroStyleContainer';
import StaticForthHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticForthHeroSection';
import EditForthHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditForthHeroStyleContainer';
import StaticFifthHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFifthHeroSection';
import EditFifthHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFifthHeroStyleContainer';
import EditSixthHeroStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSixthHeroStyleContainer';
import StaticSixthHeroSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSixthHeroSection';
import GenerateUniqueFilename from '../../../controllers/GenerateUniqueFilename';
import StaticFirstPricingSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticFirstPricingSection';
import EditFirstPricingStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditFirstPricingStyleContainer';
import StaticSecondPricingSection from '../../Layout/EditSection/EditElementorSection/StaticComponmentSettingSection/StaticSecondPricingSection';
import EditSecondPricingStyleContainer from '../../Layout/EditSection/EditElementorStyleSection/EditStaticComponmentStyle/EditSecondPricingStyleContainer';
import DynamicStyleState from '../../../controllers/DynamicStyleState';
import DynamicStateAndEvent from '../../../controllers/DynamicStateAndEvent';

const HomePageEditor = ({ commonSetting }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [openTab, setOpenTab] = useState('setting');
  const [groupHeaderData, setGroupHeaderData] = useState();
  const isChangeHeader = (value) => {
    setGroupHeaderData(value);
  };
  const [groupFooterData, setGroupFooterData] = useState();
  const isChangeFooter = (value) => {
    setGroupFooterData(value);
  };
  const [open, setOpen] = useState(false);
  const { installedExtensions } = useContext(Extention);
  const [btnLoading, setBtnLoading] = useState(false);

  const ultimatorKitExtension = installedExtensions?.find(
    (extension) => extension?.extension_name === 'Ultimator Kit',
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [getLayout, setGetLayout] = useState([]);
  const [editProductOtherSection, setEditProductOtherSection] = useState('');
  const [editSection, setEditSection] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
  });
  console.log(editSection, 'editSection--=-=->>');
  const [selectContextItem, setSelectContextItem] = useState({
    section_type: '',
    section_id: '',
    section_index: '',
    copy_type: '',
  });
  const [filterId, setFilterId] = useState(null);
  const [hoverId, setHoverId] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [prevSecId, setPrevSecId] = useState(null);
  const [elementorId, setElementorId] = useState(null);
  console.log(elementorId, 'elementorId=-=-=->>>');
  const selectSection = (e, id, type, index) => {
    e.preventDefault();
    e.stopPropagation();
    setEditSection({
      section_id: id,
      section_type: type,
      section_index: index,
    });
    setSelectedItem(null);
    setTabIndex(null);
    const section = findSectionById(id);
    if (section) {
      setHeadingSetting({
        title: section?.content,
        htmlTag: section?.content?.htmlTag || section?.content,
      });
    }
    const selectedSection = findSectionById(id);
    if (selectedSection) {
      setBgImage({
        url: selectedSection?.content?.url || '',
        imageSize: selectedSection?.content?.imageSize || { width: '', height: '' },
        link: selectedSection?.content?.link || '',
      });
    }

    const selectedTextEditorSection = findSectionById(id);
    if (selectedTextEditorSection) {
      setTextEditorValue({
        textEditorValue: selectedTextEditorSection?.content?.textEditorValue || '',
      });
    }

    const selectedVideoSection = findSectionById(id);
    if (selectedVideoSection) {
      setVideoValue({
        source: selectedVideoSection?.content?.item?.source || '',
        link: selectedVideoSection?.content?.item?.link || '',
        startTime: selectedVideoSection?.content?.item?.startTime || '',
        endTime: selectedVideoSection?.content?.item?.endTime || '',
        autoPlay: selectedVideoSection?.content?.item?.autoPlay || '',
        mute: selectedVideoSection?.content?.item?.mute || '',
        loop: selectedVideoSection?.content?.item?.loop || '',
        playerControl: selectedVideoSection?.content?.item?.playerControl || '',
        modestBranding: selectedVideoSection?.content?.item?.modestBranding || '',
        privacyMode: selectedVideoSection?.content?.item?.privacyMode || '',
        lazyLoad: selectedVideoSection?.content?.item?.lazyLoad || '',
        introTitle: selectedVideoSection?.content?.item?.introTitle || '',
        introPortrait: selectedVideoSection?.content?.item?.introPortrait || '',
        introByLine: selectedVideoSection?.content?.item?.introByLine || '',
      });
    }

    const selectedButtonSection = findSectionById(id);
    if (selectedButtonSection) {
      setButtonValue({
        text: selectedButtonSection?.content?.text || '',
        link: selectedButtonSection?.content?.link || '',
        icon: selectedButtonSection?.content?.icon || '',
      });
    }

    const selectedDividerSection = findSectionById(id);
    if (selectedDividerSection) {
      setDividerValue({
        style: selectedDividerSection?.content?.style || '',
        width: selectedDividerSection?.content?.width || '',
        align: selectedDividerSection?.content?.align || '',
        element: selectedDividerSection?.content?.element || '',
        text: selectedDividerSection?.content?.text || '',
        icon: selectedDividerSection?.content?.icon || '',
      });
    }

    const selectedSpacerSection = findSectionById(id);
    if (selectedSpacerSection) {
      setSpacerValue({
        width: selectedSpacerSection?.content?.width || '',
      });
    }

    const selectedLocationSection = findSectionById(id);
    if (selectedLocationSection) {
      setLocationValue({
        location: selectedLocationSection?.content?.location || '',
        longitude: selectedLocationSection?.content?.longitude || '',
        latitude: selectedLocationSection?.content?.latitude || '',
      });
    }

    const selectedIconSection = findSectionById(id);
    if (selectedIconSection) {
      setIconValue({
        icon: selectedIconSection?.content?.icon || '',
        link: selectedIconSection?.content?.link || '',
      });
    }

    const selectedImageBoxSection = findSectionById(id);
    if (selectedImageBoxSection) {
      setImageBox({
        url: selectedImageBoxSection?.content?.url || '',
        imageSize: selectedImageBoxSection?.content?.imageSize || '',
        link: selectedImageBoxSection?.content?.link || '',
        title: selectedImageBoxSection?.content?.title || '',
        htmlTag: selectedImageBoxSection?.content?.htmlTag || '',
        description: selectedImageBoxSection?.content?.description || '',
      });
    }

    const selectedImageIconSection = findSectionById(id);
    if (selectedImageIconSection) {
      setImageIcon({
        icon: selectedImageIconSection?.content?.icon || '',
        link: selectedImageIconSection?.content?.link || '',
        title: selectedImageIconSection?.content?.title || '',
        htmlTag: selectedImageIconSection?.content?.htmlTag || '',
        description: selectedImageIconSection?.content?.description || '',
      });
    }

    const selectedIconListSection = findSectionById(id);
    if (selectedIconListSection) {
      setIconListData({
        item: selectedIconListSection?.content?.item || '',
      });
    }

    const selectedSocialIconSection = findSectionById(id);
    if (selectedSocialIconSection) {
      setsocialIconData({
        item: selectedSocialIconSection?.content?.item || '',
      });
    }

    const selectedCounterSection = findSectionById(id);
    if (selectedCounterSection) {
      setNumberCounter({
        start: selectedCounterSection?.content?.start || 0,
        end: selectedCounterSection?.content?.end || '',
        duration: selectedCounterSection?.content?.duration || '',
        title: selectedCounterSection?.content?.title || '',
        htmlTag: selectedCounterSection?.content?.htmlTag || '',
        prefix: selectedCounterSection?.content?.prefix || '',
        suffix: selectedCounterSection?.content?.suffix || '',
      });
    }

    const selectedTestiMonialSection = findSectionById(id);
    if (selectedTestiMonialSection) {
      setTestiMonialValue({
        url: selectedTestiMonialSection?.content?.url || '',
        imageSize: selectedTestiMonialSection?.content?.imageSize || '',
        name: selectedTestiMonialSection?.content?.name || '',
        title: selectedTestiMonialSection?.content?.title || '',
        position: selectedTestiMonialSection?.content?.position || '',
        alignment: selectedTestiMonialSection?.content?.alignment || '',
        description: selectedTestiMonialSection?.content?.description || '',
      });
    }

    const selectedRatingSection = findSectionById(id);
    if (selectedRatingSection) {
      setRatingValue({
        icon: selectedRatingSection?.content?.icon || '',
        totalRate: selectedRatingSection?.content?.totalRate || '',
        initialRate: selectedRatingSection?.content?.initialRate || '',
      });
    }

    const selectedTabSection = findSectionById(id);
    if (selectedTabSection) {
      setTabValue({
        item: selectedIconListSection?.content?.item || '',
      });
    }

    const selectedAccordionSection = findSectionById(id);
    if (selectedTabSection) {
      setAccordionValue({
        item: selectedAccordionSection?.content?.item || '',
      });
    }

    const selectedAlertSection = findSectionById(id);
    if (selectedAlertSection) {
      setAlertValue({
        alertType: selectedAlertSection?.content?.alertType || '',
        title: selectedAlertSection?.content?.title || '',
        content: selectedAlertSection?.content?.content || '',
        showIcon: selectedAlertSection?.content?.showIcon || '',
        icon: selectedAlertSection?.content?.icon || '',
      });
    }

    const selectedHtmlSection = findSectionById(id);
    if (selectedHtmlSection) {
      setHtmlValue(selectedHtmlSection?.content?.item || '');
    }

    const headingSection = findSectionById(id);
    if (headingSection) {
      setHeadingDesign(headingSection?.style);
    }

    const imageSection = findSectionById(id);
    if (imageSection) {
      setImageDesign(imageSection?.style);
    }

    const textEditorSection = findSectionById(id);
    if (textEditorSection) {
      setTextEditorDesign(textEditorSection?.style);
    }

    const videoSection = findSectionById(id);
    if (videoSection) {
      setVideoDesign(videoSection?.style);
    }

    const buttonSection = findSectionById(id);
    if (buttonSection) {
      setButtonDesign(buttonSection?.style);
    }

    const dividerSection = findSectionById(id);
    if (dividerSection) {
      setDividerDesign(dividerSection?.style);
    }

    const spacerSection = findSectionById(id);
    if (spacerSection) {
      setSpacerDesign(spacerSection?.style);
    }

    const mapSection = findSectionById(id);
    if (mapSection) {
      setMapDesign(mapSection?.style);
    }

    const iconSection = findSectionById(id);
    if (iconSection) {
      setIconDesign(iconSection?.style);
    }

    const imageBoxSection = findSectionById(id);
    if (imageBoxSection) {
      setImageBoxDesign(imageBoxSection?.style);
    }

    const iconBoxSection = findSectionById(id);
    if (iconBoxSection) {
      setIconBoxDesign(iconBoxSection?.style);
    }

    const iconListSection = findSectionById(id);
    if (iconListSection) {
      setIconListDesign(iconListSection?.style);
    }

    const counterSection = findSectionById(id);
    if (counterSection) {
      setCounterDesign(counterSection?.style);
    }

    const testiminialSection = findSectionById(id);
    if (testiminialSection) {
      setTestiMonialDesign(testiminialSection?.style);
    }

    const tabSection = findSectionById(id);
    if (tabSection) {
      setTabDesign(tabSection?.style);
    }

    const accordianSection = findSectionById(id);
    if (accordianSection) {
      setAccordianDesign(accordianSection?.style);
    }

    const socialIconSection = findSectionById(id);
    if (socialIconSection) {
      setSocialIconDesign(socialIconSection?.style);
    }

    const alertSection = findSectionById(id);
    if (alertSection) {
      setAlertDesign(alertSection?.style);
    }

    const htmlSection = findSectionById(id);
    if (htmlSection) {
      setHtmlDesign(htmlSection?.style);
    }

    const ratingSection = findSectionById(id);
    if (ratingSection) {
      setRatingDesign(ratingSection?.style);
    }

    const firstContentSection = findSectionById(id);
    if (firstContentSection) {
      setFirstContentDesign(firstContentSection?.style);
    }

    const secondContentSection = findSectionById(id);
    if (secondContentSection) {
      setSecondContentDesign(secondContentSection?.style);
    }

    const thirdContentSection = findSectionById(id);
    if (thirdContentSection) {
      setThirdContentDesign(thirdContentSection?.style);
    }

    const forthContentSection = findSectionById(id);
    if (forthContentSection) {
      setForthContentDesign(forthContentSection?.style);
    }

    const fifthContentSection = findSectionById(id);
    if (fifthContentSection) {
      setFifthContentDesign(fifthContentSection?.style);
    }

    const sixContentSection = findSectionById(id);
    if (sixContentSection) {
      setSixContentDesign(sixContentSection?.style);
    }

    const sevenContentSection = findSectionById(id);
    if (sevenContentSection) {
      setSevenContentDesign(sevenContentSection?.style);
    }

    const eightContentSection = findSectionById(id);
    if (eightContentSection) {
      setEightContentDesign(eightContentSection?.style);
    }

    const firstFeatureSection = findSectionById(id);
    if (firstFeatureSection) {
      setFirstFeatureDesign(firstFeatureSection?.style);
    }

    const secondFeatureSection = findSectionById(id);
    if (secondFeatureSection) {
      setSecondFeatureDesign(secondFeatureSection?.style);
    }

    const thirdFeatureSection = findSectionById(id);
    if (thirdFeatureSection) {
      setThirdFeatureDesign(thirdFeatureSection?.style);
    }

    const forthFeatureSection = findSectionById(id);
    if (forthFeatureSection) {
      setForthFeatureDesign(forthFeatureSection?.style);
    }

    const fifthFeatureSection = findSectionById(id);
    if (fifthFeatureSection) {
      setFifthFeatureDesign(fifthFeatureSection?.style);
    }

    const sixthFeatureSection = findSectionById(id);
    if (sixthFeatureSection) {
      setSixthFeatureDesign(sixthFeatureSection?.style);
    }

    const sevenFeatureSection = findSectionById(id);
    if (sevenFeatureSection) {
      setSevenFeatureDesign(sevenFeatureSection?.style);
    }

    const eightFeatureSection = findSectionById(id);
    if (eightFeatureSection) {
      setEightFeatureDesign(eightFeatureSection?.style);
    }

    const firstStepSection = findSectionById(id);
    if (firstStepSection) {
      setFirstStepDesign(firstStepSection?.style);
    }

    const secondStepSection = findSectionById(id);
    if (secondStepSection) {
      setSecondStepDesign(secondStepSection?.style);
    }

    const thirdStepSection = findSectionById(id);
    if (thirdStepSection) {
      setThirdStepDesign(thirdStepSection?.style);
    }

    const firstTeamSection = findSectionById(id);
    if (firstTeamSection) {
      setFirstTeamDesign(firstTeamSection?.style);
    }

    const secondTeamSection = findSectionById(id);
    if (secondTeamSection) {
      setSecondTeamDesign(secondTeamSection?.style);
    }

    const thirdTeamSection = findSectionById(id);
    if (thirdTeamSection) {
      setThirdTeamDesign(thirdTeamSection?.style);
    }

    const firstTestimonialSection = findSectionById(id);
    if (firstTestimonialSection) {
      setFirstTestimonialDesign(firstTestimonialSection?.style);
    }

    const secondTestimonialSection = findSectionById(id);
    if (secondTestimonialSection) {
      setSecondTestimonialDesign(secondTestimonialSection?.style);
    }

    const thirdTestimonialSection = findSectionById(id);
    if (thirdTestimonialSection) {
      setThirdTestimonialDesign(thirdTestimonialSection?.style);
    }

    const firstStatisticSection = findSectionById(id);
    if (firstStatisticSection) {
      setFirstStatisticDesign(firstStatisticSection?.style);
    }

    const secondStatisticSection = findSectionById(id);
    if (secondStatisticSection) {
      setSecondStatisticDesign(secondStatisticSection?.style);
    }

    const thirdStatisticSection = findSectionById(id);
    if (thirdStatisticSection) {
      setThirdStatisticDesign(thirdStatisticSection?.style);
    }

    const firstContactSection = findSectionById(id);
    if (firstContactSection) {
      setFirstContactDesign(firstContactSection?.style);
    }

    const secondContactSection = findSectionById(id);
    if (secondContactSection) {
      setSecondContactDesign(secondContactSection?.style);
    }

    const thirdContactSection = findSectionById(id);
    if (thirdContactSection) {
      setThirdContactDesign(thirdContactSection?.style);
    }

    const firstCtaSection = findSectionById(id);
    if (firstCtaSection) {
      setFirstCtaDesign(firstCtaSection?.style);
    }

    const secondCtaSection = findSectionById(id);
    if (secondCtaSection) {
      setSecondCtaDesign(secondCtaSection?.style);
    }

    const thirdCtaSection = findSectionById(id);
    if (thirdCtaSection) {
      setThirdCtaDesign(thirdCtaSection?.style);
    }

    const forthCtaSection = findSectionById(id);
    if (forthCtaSection) {
      setForthCtaDesign(forthCtaSection?.style);
    }

    const firstHeroSection = findSectionById(id);
    if (firstHeroSection) {
      setFirstHeroDesign(firstHeroSection?.style);
    }

    const secondHeroSection = findSectionById(id);
    if (secondHeroSection) {
      setSecondHeroDesign(secondHeroSection?.style);
    }

    const thirdHeroSection = findSectionById(id);
    if (thirdHeroSection) {
      setThirdHeroDesign(thirdHeroSection?.style);
    }

    const forthHeroSection = findSectionById(id);
    if (forthHeroSection) {
      setForthHeroDesign(forthHeroSection?.style);
    }

    const fifthHeroSection = findSectionById(id);
    if (fifthHeroSection) {
      setFifthHeroDesign(fifthHeroSection?.style);
    }

    const sixthHeroSection = findSectionById(id);
    if (sixthHeroSection) {
      setSixthHeroDesign(sixthHeroSection?.style);
    }

    const firstPricingSection = findSectionById(id);
    if (firstPricingSection) {
      setFirstPricingDesign(firstPricingSection?.style);
    }

    const secondPricingSection = findSectionById(id);
    if (secondPricingSection) {
      setSecondPricingDesign(secondPricingSection?.style);
    }

    const selectedFirstContentSection = findSectionById(id);
    if (selectedFirstContentSection) {
      setFirstContentValue({
        item: selectedFirstContentSection?.content?.item || '',
        title: selectedFirstContentSection?.content?.title || '',
        description: selectedFirstContentSection?.content?.description || '',
        htmlTag: selectedFirstContentSection?.content?.htmlTag || '',
        gridValue: selectedFirstContentSection?.content?.gridValue || '',
        subTitle: selectedFirstContentSection?.content?.subTitle || '',
        subHtmlTag: selectedFirstContentSection?.content?.subHtmlTag || '',
        subDescription: selectedFirstContentSection?.content?.subDescription || '',
        icon: selectedFirstContentSection?.content?.icon || '',
        link: selectedFirstContentSection?.content?.link || '',
      });
    }

    const selectedSecondContentSection = findSectionById(id);
    if (selectedSecondContentSection) {
      setSecondContentValue({
        item: selectedSecondContentSection?.content?.item || '',
        title: selectedSecondContentSection?.content?.title || '',
        description: selectedSecondContentSection?.content?.description || '',
        htmlTag: selectedSecondContentSection?.content?.htmlTag || '',
        gridValue: selectedSecondContentSection?.content?.gridValue || '',
        image: selectedSecondContentSection?.content?.image || '',
        objectFit: selectedSecondContentSection?.content?.objectFit || '',
      });
    }

    const selectedThirdContentSection = findSectionById(id);
    if (selectedThirdContentSection) {
      setThirdContentValue({
        item: selectedThirdContentSection?.content?.item || '',
        title: selectedThirdContentSection?.content?.title || '',
        description: selectedThirdContentSection?.content?.description || '',
        htmlTag: selectedThirdContentSection?.content?.htmlTag || '',
        gridValue: selectedThirdContentSection?.content?.gridValue || '',
        button: selectedThirdContentSection?.content?.button || '',
        icon: selectedThirdContentSection?.content?.icon || '',
        link: selectedThirdContentSection?.content?.link || '',
      });
    }

    const selectedForthContentSection = findSectionById(id);
    if (selectedForthContentSection) {
      setForthContentValue({
        item: selectedForthContentSection?.content?.item || '',
        title: selectedForthContentSection?.content?.title || '',
        description: selectedForthContentSection?.content?.description || '',
        htmlTag: selectedForthContentSection?.content?.htmlTag || '',
        gridValue: selectedForthContentSection?.content?.gridValue || '',
        button: selectedForthContentSection?.content?.button || '',
      });
    }

    const selectedFifthContentSection = findSectionById(id);
    if (selectedFifthContentSection) {
      setFifthContentValue({
        title: selectedFifthContentSection?.content?.title || '',
        description: selectedFifthContentSection?.content?.description || '',
        htmlTag: selectedFifthContentSection?.content?.htmlTag || '',
        button_link: selectedFifthContentSection?.content?.button_link || '',
        button: selectedFifthContentSection?.content?.button || '',
        icon: selectedFifthContentSection?.content?.icon || '',
        link: selectedFifthContentSection?.content?.link || '',
        isFill: selectedFifthContentSection?.content?.isFill || '',
      });
    }

    const selectedSixContentSection = findSectionById(id);
    if (selectedSixContentSection) {
      setSixContentValue({
        title: selectedSixContentSection?.content?.title || '',
        description: selectedSixContentSection?.content?.description || '',
        htmlTag: selectedSixContentSection?.content?.htmlTag || '',
        button_link: selectedSixContentSection?.content?.button_link || '',
        content: selectedSixContentSection?.content?.content || '',
        link_url: selectedSixContentSection?.content?.link_url || '',
        image: selectedSixContentSection?.content?.image || '',
        profile: selectedSixContentSection?.content?.profile || '',
        objectFit: selectedSixContentSection?.content?.objectFit || '',
        profileFill: selectedSixContentSection?.content?.profileFill || '',
        icon: selectedSixContentSection?.content?.icon || '',
        isFill: selectedSixContentSection?.content?.isFill || '',
      });
    }

    const selectedSevenContentSection = findSectionById(id);
    if (selectedSevenContentSection) {
      setSevenContentValue({
        item: selectedSevenContentSection?.content?.item || '',
        gridValue: selectedSevenContentSection?.content?.gridValue || '',
        image: selectedSevenContentSection?.content?.image || '',
        objectFit: selectedSevenContentSection?.content?.objectFit || '',
      });
    }

    const selectedEightContentSection = findSectionById(id);
    if (selectedEightContentSection) {
      setEightContentValue({
        item: selectedEightContentSection?.content?.item || '',
        gridValue: selectedEightContentSection?.content?.gridValue || '',
        title: selectedEightContentSection?.content?.title || '',
        description: selectedEightContentSection?.content?.description || '',
        htmlTag: selectedEightContentSection?.content?.htmlTag || '',
        image: selectedEightContentSection?.content?.image || '',
        icon: selectedEightContentSection?.content?.icon || '',
        objectFit: selectedEightContentSection?.content?.objectFit || '',
      });
    }

    const selectedFirstFeatureSection = findSectionById(id);
    if (selectedFirstFeatureSection) {
      setFirstFeatureValue({
        item: selectedFirstFeatureSection?.content?.item || '',
        gridValue: selectedFirstFeatureSection?.content?.gridValue || '',
        title: selectedFirstFeatureSection?.content?.title || '',
        htmlTag: selectedFirstFeatureSection?.content?.htmlTag || '',
      });
    }

    const selectedSecondFeatureSection = findSectionById(id);
    if (selectedSecondFeatureSection) {
      setSecondFeatureValue({
        item: selectedSecondFeatureSection?.content?.item || '',
        gridValue: selectedSecondFeatureSection?.content?.gridValue || '',
        title: selectedSecondFeatureSection?.content?.title || '',
        htmlTag: selectedSecondFeatureSection?.content?.htmlTag || '',
        description: selectedSecondFeatureSection?.content?.description || '',
        button: selectedSecondFeatureSection?.content?.button || '',
      });
    }

    const selectedThirdFeatureSection = findSectionById(id);
    if (selectedThirdFeatureSection) {
      setThirdFeatureValue({
        item: selectedThirdFeatureSection?.content?.item || '',
        image: selectedThirdFeatureSection?.content?.image || '',
        objectFit: selectedThirdFeatureSection?.content?.objectFit || '',
      });
    }

    const selectedFirstStepSection = findSectionById(id);
    if (selectedFirstStepSection) {
      setFirstStepValue({
        item: selectedFirstStepSection?.content?.item || '',
        image: selectedFirstStepSection?.content?.image || '',
        objectFit: selectedFirstStepSection?.content?.objectFit || '',
      });
    }

    const selectedSecondStepSection = findSectionById(id);
    if (selectedSecondStepSection) {
      setSecondStepValue({
        item: selectedSecondStepSection?.content?.item || '',
      });
    }

    const selectedThirdStepSection = findSectionById(id);
    if (selectedThirdStepSection) {
      setThirdStepValue({
        item: selectedThirdStepSection?.content?.item || '',
      });
    }
    const selectedForthFeatureSection = findSectionById(id);
    if (selectedForthFeatureSection) {
      setForthFeatureValue({
        item: selectedForthFeatureSection?.content?.item || '',
      });
    }

    const selectedFifthFeatureSection = findSectionById(id);
    if (selectedFifthFeatureSection) {
      setFifthFeatureValue({
        item: selectedFifthFeatureSection?.content?.item || '',
        gridValue: selectedFifthFeatureSection?.content?.gridValue || '',
        title: selectedFifthFeatureSection?.content?.title || '',
        htmlTag: selectedFifthFeatureSection?.content?.htmlTag || '',
        button: selectedFifthFeatureSection?.content?.button || '',
        subTitle: selectedFifthFeatureSection?.content?.subTitle || '',
        subHtmlTag: selectedFifthFeatureSection?.content?.subHtmlTag || '',
      });
    }

    const selectedSixthFeatureSection = findSectionById(id);
    if (selectedSixthFeatureSection) {
      setSixthFeatureValue({
        item: selectedSixthFeatureSection?.content?.item || '',
        button: selectedSixthFeatureSection?.content?.button || '',
      });
    }

    const selectedSevenFeatureSection = findSectionById(id);
    if (selectedSevenFeatureSection) {
      setSevenFeatureValue({
        item: selectedSevenFeatureSection?.content?.item || '',
        gridValue: selectedSevenFeatureSection?.content?.gridValue || '',
        title: selectedSevenFeatureSection?.content?.title || '',
        htmlTag: selectedSevenFeatureSection?.content?.htmlTag || '',
        button: selectedSevenFeatureSection?.content?.button || '',
        description: selectedSevenFeatureSection?.content?.description || '',
      });
    }

    const selectedEightFeatureSection = findSectionById(id);
    if (selectedEightFeatureSection) {
      setEightFeatureValue({
        item: selectedEightFeatureSection?.content?.item || '',
        gridValue: selectedEightFeatureSection?.content?.gridValue || '',
        title: selectedEightFeatureSection?.content?.title || '',
        htmlTag: selectedEightFeatureSection?.content?.htmlTag || '',
        button: selectedEightFeatureSection?.content?.button || '',
        description: selectedEightFeatureSection?.content?.description || '',
      });
    }

    const selectedFirstTeamSection = findSectionById(id);
    if (selectedFirstTeamSection) {
      setFirstTeamValue({
        item: selectedFirstTeamSection?.content?.item || '',
        gridValue: selectedFirstTeamSection?.content?.gridValue || '',
        title: selectedFirstTeamSection?.content?.title || '',
        htmlTag: selectedFirstTeamSection?.content?.htmlTag || '',
        description: selectedFirstTeamSection?.content?.description || '',
      });
    }

    const selectedSecondTeamSection = findSectionById(id);
    if (selectedSecondTeamSection) {
      setSecondTeamValue({
        item: selectedSecondTeamSection?.content?.item || '',
        gridValue: selectedSecondTeamSection?.content?.gridValue || '',
        title: selectedSecondTeamSection?.content?.title || '',
        htmlTag: selectedSecondTeamSection?.content?.htmlTag || '',
        description: selectedSecondTeamSection?.content?.description || '',
      });
    }

    const selectedThirdTeamSection = findSectionById(id);
    if (selectedThirdTeamSection) {
      setThirdTeamValue({
        item: selectedThirdTeamSection?.content?.item || '',
        gridValue: selectedThirdTeamSection?.content?.gridValue || '',
        title: selectedThirdTeamSection?.content?.title || '',
        htmlTag: selectedThirdTeamSection?.content?.htmlTag || '',
        description: selectedThirdTeamSection?.content?.description || '',
      });
    }

    const selectedFirstTestimonialSection = findSectionById(id);
    if (selectedFirstTestimonialSection) {
      setFirstTestimonialValue({
        title: selectedFirstTestimonialSection?.content?.title || '',
        htmlTag: selectedFirstTestimonialSection?.content?.htmlTag || '',
        gridValue: selectedFirstTestimonialSection?.content?.gridValue || '',
        item: selectedFirstTestimonialSection?.content?.item || '',
      });
    }

    const selectedSecondTestimonialSection = findSectionById(id);
    if (selectedSecondTestimonialSection) {
      setSecondTestimonialValue({
        title: selectedSecondTestimonialSection?.content?.title || '',
        htmlTag: selectedSecondTestimonialSection?.content?.htmlTag || '',
        description: selectedSecondTestimonialSection?.content?.description || '',
        role: selectedSecondTestimonialSection?.content?.role || '',
        role_htmlTag: selectedSecondTestimonialSection?.content?.role_htmlTag || '',
        icon: selectedSecondTestimonialSection?.content?.icon || '',
        isFill: selectedSecondTestimonialSection?.content?.isFill || '',
      });
    }

    const selectedThirdTestimonialSection = findSectionById(id);
    if (selectedThirdTestimonialSection) {
      setThirdTestimonialValue({
        gridValue: selectedThirdTestimonialSection?.content?.gridValue || '',
        item: selectedThirdTestimonialSection?.content?.item || '',
      });
    }

    const selectedFirstStatisticSection = findSectionById(id);
    if (selectedFirstStatisticSection) {
      setFirstStatisticValue({
        gridValue: selectedFirstStatisticSection?.content?.gridValue || '',
        item: selectedFirstStatisticSection?.content?.item || '',
      });
    }

    const selectedSecondStatisticSection = findSectionById(id);
    if (selectedSecondStatisticSection) {
      setSecondStatisticValue({
        gridValue: selectedSecondStatisticSection?.content?.gridValue || '',
        item: selectedSecondStatisticSection?.content?.item || '',
        title: selectedSecondStatisticSection?.content?.title || '',
        description: selectedSecondStatisticSection?.content?.description || '',
        htmlTag: selectedSecondStatisticSection?.content?.item || '',
        image: selectedSecondStatisticSection?.content?.image || '',
        objectFit: selectedSecondStatisticSection?.content?.objectFit || '',
      });
    }

    const selectedThirdStatisticSection = findSectionById(id);
    if (selectedThirdStatisticSection) {
      setThirdStatisticValue({
        gridValue: selectedThirdStatisticSection?.content?.gridValue || '',
        item: selectedThirdStatisticSection?.content?.item || '',
        title: selectedThirdStatisticSection?.content?.title || '',
        description: selectedThirdStatisticSection?.content?.description || '',
        htmlTag: selectedThirdStatisticSection?.content?.htmlTag || '',
      });
    }

    const selectedFirstContactSection = findSectionById(id);
    if (selectedFirstContactSection) {
      setFirstContactValue({
        title: selectedFirstContactSection?.content?.title || '',
        description: selectedFirstContactSection?.content?.description || '',
        htmlTag: selectedFirstContactSection?.content?.htmlTag || '',
        note: selectedFirstContactSection?.content?.note || '',
        shortCode: selectedFirstContactSection?.content?.shortCode || '',
      });
    }

    const selectedSecondContactSection = findSectionById(id);
    if (selectedSecondContactSection) {
      setSecondContactValue({
        title: selectedSecondContactSection?.content?.title || '',
        description: selectedSecondContactSection?.content?.description || '',
        htmlTag: selectedSecondContactSection?.content?.item || '',
        note: selectedSecondContactSection?.content?.note || '',
        address_title: selectedSecondContactSection?.content?.address_title || '',
        address_title_htmlTag: selectedSecondContactSection?.content?.address_title_htmlTag || '',
        address_value: selectedSecondContactSection?.content?.address_value || '',
        email_title: selectedSecondContactSection?.content?.email_title || '',
        email_title_htmlTag: selectedSecondContactSection?.content?.email_title_htmlTag || '',
        email_value: selectedSecondContactSection?.content?.email_value || '',
        phone_title: selectedSecondContactSection?.content?.phone_title || '',
        phone_title_htmlTag: selectedSecondContactSection?.content?.phone_title_htmlTag || '',
        phone_value: selectedSecondContactSection?.content?.phone_value || '',
        shortCode: selectedFirstContactSection?.content?.shortCode || '',
      });
    }

    const selectedThirdContactSection = findSectionById(id);
    if (selectedThirdContactSection) {
      setThirdContactValue({
        title: selectedThirdContactSection?.content?.title || '',
        description: selectedThirdContactSection?.content?.description || '',
        htmlTag: selectedThirdContactSection?.content?.item || '',
        address_value: selectedThirdContactSection?.content?.address_value || '',
        email_value: selectedThirdContactSection?.content?.email_value || '',
        iconArray: selectedThirdContactSection?.content?.iconArray || '',
        shortCode: selectedFirstContactSection?.content?.shortCode || '',
      });
    }

    const selectedFirstCtaSection = findSectionById(id);
    if (selectedFirstCtaSection) {
      setFirstCtaValue({
        description: selectedFirstCtaSection?.content?.description || '',
        button: selectedFirstCtaSection?.content?.button || '',
      });
    }

    const selectedSecondCtaSection = findSectionById(id);
    if (selectedSecondCtaSection) {
      setSecondCtaValue({
        description: selectedSecondCtaSection?.content?.description || '',
        title: selectedSecondCtaSection?.content?.title || '',
        htmlTag: selectedSecondCtaSection?.content?.htmlTag || '',
        form_title: selectedSecondCtaSection?.content?.form_title || '',
        form_title_htmlTag: selectedSecondCtaSection?.content?.form_title_htmlTag || '',
        note: selectedSecondCtaSection?.content?.note || '',
        shortCode: selectedSecondCtaSection?.content?.shortCode || '',
      });
    }

    const selectedThirdCtaSection = findSectionById(id);
    if (selectedThirdCtaSection) {
      setThirdCtaValue({
        description: selectedThirdCtaSection?.content?.description || '',
        title: selectedThirdCtaSection?.content?.title || '',
        htmlTag: selectedThirdCtaSection?.content?.htmlTag || '',
        shortCode: selectedSecondCtaSection?.content?.shortCode || '',
      });
    }

    const selectedForthCtaSection = findSectionById(id);
    if (selectedForthCtaSection) {
      setForthCtaValue({
        description: selectedForthCtaSection?.content?.description || '',
        title: selectedForthCtaSection?.content?.title || '',
        htmlTag: selectedForthCtaSection?.content?.htmlTag || '',
        first_btn_title: selectedForthCtaSection?.content?.first_btn_title || '',
        first_btn_sub_title: selectedForthCtaSection?.content?.first_btn_sub_title || '',
        first_btn_link: selectedForthCtaSection?.content?.first_btn_link || '',
        second_btn_title: selectedForthCtaSection?.content?.second_btn_title || '',
        second_btn_sub_title: selectedForthCtaSection?.content?.second_btn_sub_title || '',
        second_btn_link: selectedForthCtaSection?.content?.second_btn_link || '',
      });
    }

    const selectedFirstHeroSection = findSectionById(id);
    if (selectedFirstHeroSection) {
      setFirstHeroValue({
        description: selectedFirstHeroSection?.content?.description || '',
        title: selectedFirstHeroSection?.content?.title || '',
        htmlTag: selectedFirstHeroSection?.content?.htmlTag || '',
        first_btn: selectedFirstHeroSection?.content?.first_btn || '',
        second_btn: selectedFirstHeroSection?.content?.second_btn || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
      });
    }

    const selectedSecondHeroSection = findSectionById(id);
    if (selectedSecondHeroSection) {
      setSecondHeroValue({
        description: selectedSecondHeroSection?.content?.description || '',
        title: selectedSecondHeroSection?.content?.title || '',
        htmlTag: selectedSecondHeroSection?.content?.htmlTag || '',
        first_btn: selectedSecondHeroSection?.content?.first_btn || '',
        second_btn: selectedSecondHeroSection?.content?.second_btn || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
      });
    }

    const selectedThirdHeroSection = findSectionById(id);
    if (selectedThirdHeroSection) {
      setThirdHeroValue({
        description: selectedThirdHeroSection?.content?.description || '',
        title: selectedThirdHeroSection?.content?.title || '',
        htmlTag: selectedThirdHeroSection?.content?.htmlTag || '',
        first_btn: selectedThirdHeroSection?.content?.first_btn || '',
        second_btn: selectedThirdHeroSection?.content?.second_btn || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
      });
    }

    const selectedForthHeroSection = findSectionById(id);
    if (selectedForthHeroSection) {
      setForthHeroValue({
        description: selectedForthHeroSection?.content?.description || '',
        title: selectedForthHeroSection?.content?.title || '',
        htmlTag: selectedForthHeroSection?.content?.htmlTag || '',
        first_btn_title: selectedForthHeroSection?.content?.first_btn_title || '',
        first_btn_sub_title: selectedForthHeroSection?.content?.first_btn_sub_title || '',
        first_btn_link: selectedForthHeroSection?.content?.first_btn_link || '',
        second_btn_title: selectedForthHeroSection?.content?.second_btn_title || '',
        second_btn_sub_title: selectedForthHeroSection?.content?.second_btn_sub_title || '',
        second_btn_link: selectedForthHeroSection?.content?.second_btn_link || '',
        note: selectedForthHeroSection?.content?.note || '',
        shortCode: selectedForthHeroSection?.content?.shortCode || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
        first_icon: selectedFirstHeroSection?.content?.first_icon || '',
        second_icon: selectedFirstHeroSection?.content?.second_icon || '',
      });
    }

    const selectedFifthHeroSection = findSectionById(id);
    if (selectedFifthHeroSection) {
      setFifthHeroValue({
        description: selectedFifthHeroSection?.content?.description || '',
        title: selectedFifthHeroSection?.content?.title || '',
        htmlTag: selectedFifthHeroSection?.content?.htmlTag || '',
        first_btn_title: selectedFifthHeroSection?.content?.first_btn_title || '',
        first_btn_sub_title: selectedFifthHeroSection?.content?.first_btn_sub_title || '',
        first_btn_link: selectedFifthHeroSection?.content?.first_btn_link || '',
        second_btn_title: selectedFifthHeroSection?.content?.second_btn_title || '',
        second_btn_sub_title: selectedFifthHeroSection?.content?.second_btn_sub_title || '',
        second_btn_link: selectedFifthHeroSection?.content?.second_btn_link || '',
        note: selectedFifthHeroSection?.content?.note || '',
        shortCode: selectedFifthHeroSection?.content?.shortCode || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
        first_icon: selectedFirstHeroSection?.content?.first_icon || '',
        second_icon: selectedFirstHeroSection?.content?.second_icon || '',
      });
    }

    const selectedSixthHeroSection = findSectionById(id);
    if (selectedSixthHeroSection) {
      setSixthHeroValue({
        description: selectedSixthHeroSection?.content?.description || '',
        title: selectedSixthHeroSection?.content?.title || '',
        htmlTag: selectedSixthHeroSection?.content?.htmlTag || '',
        first_btn_title: selectedSixthHeroSection?.content?.first_btn_title || '',
        first_btn_sub_title: selectedSixthHeroSection?.content?.first_btn_sub_title || '',
        first_btn_link: selectedSixthHeroSection?.content?.first_btn_link || '',
        second_btn_title: selectedSixthHeroSection?.content?.second_btn_title || '',
        second_btn_sub_title: selectedSixthHeroSection?.content?.second_btn_sub_title || '',
        second_btn_link: selectedSixthHeroSection?.content?.second_btn_link || '',
        note: selectedSixthHeroSection?.content?.note || '',
        shortCode: selectedSixthHeroSection?.content?.shortCode || '',
        image: selectedFirstHeroSection?.content?.image || '',
        objectFit: selectedFirstHeroSection?.content?.objectFit || '',
        first_icon: selectedFirstHeroSection?.content?.first_icon || '',
        second_icon: selectedFirstHeroSection?.content?.second_icon || '',
      });
    }

    const selectedFirstPricingSection = findSectionById(id);
    if (selectedFirstPricingSection) {
      setFirstPricingValue({
        description: selectedFirstPricingSection?.content?.description || '',
        title: selectedFirstPricingSection?.content?.title || '',
        htmlTag: selectedFirstPricingSection?.content?.htmlTag || '',
        gridValue: selectedFirstPricingSection?.content?.gridValue || '',
        item: selectedFirstPricingSection?.content?.item || '',
      });
    }

    const selectedSecondPricingSection = findSectionById(id);
    if (selectedSecondPricingSection) {
      setSecondPricingValue({
        description: selectedSecondPricingSection?.content?.description || '',
        title: selectedSecondPricingSection?.content?.title || '',
        htmlTag: selectedSecondPricingSection?.content?.htmlTag || '',
        gridValue: selectedSecondPricingSection?.content?.gridValue || '',
        link: selectedSecondPricingSection?.content?.link || '',
        link_url: selectedSecondPricingSection?.content?.link_url || '',
        button: selectedSecondPricingSection?.content?.button || '',
        button_url: selectedSecondPricingSection?.content?.button_url || '',
        item: selectedSecondPricingSection?.content?.item || '',
      });
    }
  };

  const getContextMenuItem = (e, id, type, index, copyType) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectContextItem({
      section_id: id,
      section_type: type,
      section_index: index,
      copy_type: copyType,
    });
  };

  const findSectionById = (id) => {
    const allSections = Object.values(sectionAdd).flat(2);
    return allSections.find((section) => section?.id === id);
  };

  const updateSectionStyle = (section_id, newContent) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      Object.keys(updatedSections).forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey]?.map((sectionArray) => {
            if (Array.isArray(sectionArray)) {
              return sectionArray?.map((section) => {
                if (section.id === section_id) {
                  return { ...section, style: { ...section?.style, ...newContent } };
                }
                return section;
              });
            }
            return sectionArray;
          });
        }
      });

      return updatedSections;
    });
  };

  const handleImageUpload = async (event, section_id) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      setBgImage({ url: result?.data[0] });
      updateSectionContent(section_id, { url: result?.data[0] });
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleBackgroundImageUpload = async (event) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      setIsNotification(true);
      setNotificationMsg(result?.message);
      setSeverity('success');
      return result?.data[0];
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleSettingImageUpload = async (event) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      setIsNotification(true);
      setNotificationMsg(result?.message);
      setSeverity('success');
      return result?.data[0];
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const handleIconBoxImageUpload = async (event, section_id) => {
    const file = event.target.files[0];
    let fileName = file?.name;
    if (!fileName) {
      fileName = await GenerateUniqueFilename();
    }
    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('folder', 'images');
    try {
      const res = await API.post('/admin/fileupload/upload', formData);
      const result = decryptData(res?.data);
      console.log('setBgImage', result);
      setImageBox({ url: result?.data[0] });
      updateSectionContent(section_id, { url: result?.data[0] });
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const [pending, setPending] = useState(true);
  const [isAddSection, setIsAddSection] = useState(false);
  const closeAddSection = () => {
    setIsAddSection(false);
  };

  const {
    headingDesign,
    setHeadingDesign,
    mapDesign,
    setMapDesign,
    spacerDesign,
    setSpacerDesign,
    testiMonialDesign,
    setTestiMonialDesign,
    socialIconDesign,
    setSocialIconDesign,
    alertDesign,
    setAlertDesign,
    htmlDesign,
    setHtmlDesign,
    iconDesign,
    setIconDesign,
    iconBoxDesign,
    setIconBoxDesign,
    iconListDesign,
    setIconListDesign,
    imageDesign,
    setImageDesign,
    textEditorDesign,
    setTextEditorDesign,
    videoDesign,
    setVideoDesign,
    buttonDesign,
    setButtonDesign,
    dividerDesign,
    setDividerDesign,
    imageBoxDesign,
    setImageBoxDesign,
    counterDesign,
    setCounterDesign,
    tabDesign,
    setTabDesign,
    accordianDesign,
    setAccordianDesign,
    ratingDesign,
    setRatingDesign,
    firstContentDesign,
    setFirstContentDesign,
    secondCtaDesign,
    setSecondCtaDesign,
    thirdCtaDesign,
    setThirdCtaDesign,
    forthCtaDesign,
    setForthCtaDesign,
    firstCtaDesign,
    setFirstCtaDesign,
    secondContentDesign,
    setSecondContentDesign,
    thirdContentDesign,
    setThirdContentDesign,
    forthContentDesign,
    setForthContentDesign,
    fifthContentDesign,
    setFifthContentDesign,
    sixContentDesign,
    setSixContentDesign,
    sevenContentDesign,
    setSevenContentDesign,
    eightContentDesign,
    setEightContentDesign,
    firstFeatureDesign,
    setFirstFeatureDesign,
    secondFeatureDesign,
    setSecondFeatureDesign,
    sevenFeatureDesign,
    setSevenFeatureDesign,
    eightFeatureDesign,
    setEightFeatureDesign,
    firstStepDesign,
    setFirstStepDesign,
    secondStepDesign,
    setSecondStepDesign,
    thirdStepDesign,
    setThirdStepDesign,
    thirdFeatureDesign,
    setThirdFeatureDesign,
    forthFeatureDesign,
    setForthFeatureDesign,
    fifthFeatureDesign,
    setFifthFeatureDesign,
    sixthFeatureDesign,
    setSixthFeatureDesign,
    firstTeamDesign,
    setFirstTeamDesign,
    secondTeamDesign,
    setSecondTeamDesign,
    thirdTeamDesign,
    setThirdTeamDesign,
    firstTestimonialDesign,
    setFirstTestimonialDesign,
    secondTestimonialDesign,
    setSecondTestimonialDesign,
    thirdTestimonialDesign,
    setThirdTestimonialDesign,
    firstStatisticDesign,
    setFirstStatisticDesign,
    secondStatisticDesign,
    setSecondStatisticDesign,
    thirdStatisticDesign,
    setThirdStatisticDesign,
    firstContactDesign,
    setFirstContactDesign,
    firstHeroDesign,
    setFirstHeroDesign,
    secondHeroDesign,
    setSecondHeroDesign,
    thirdHeroDesign,
    setThirdHeroDesign,
    forthHeroDesign,
    setForthHeroDesign,
    fifthHeroDesign,
    setFifthHeroDesign,
    sixthHeroDesign,
    setSixthHeroDesign,
    firstPricingDesign,
    setFirstPricingDesign,
    secondPricingDesign,
    setSecondPricingDesign,
    secondContactDesign,
    setSecondContactDesign,
    thirdContactDesign,
    setThirdContactDesign,
  } = DynamicStyleState();

  const {
    sectionAdd,
    setSectionAdd,
    isOpen,
    setIsOpen,
    tabIndex,
    setTabIndex,
    updateSectionContent,
    handleContentChange,
    handleHtmlTagChange,
    handleImageSizeChange,
    handleImageLinkChange,
    handleTextEditorChange,
    handleVideoChange,
    handleButtonChange,
    handleButtonLinkChange,
    handleIconSelectValue,
    handleDividerStyleChange,
    handleDividerWidthChange,
    handleAlignmentChange,
    handleAddElementChange,
    handleAddElementValue,
    handleSpacerWidthChange,
    handleLocationChange,
    handleIconElementorValue,
    handleIconLinkChange,
    handleImageBoxImageSizeChange,
    handleImageBoxTitleChange,
    handleImageBoxHtmlTagChange,
    handleImageBoxDescriptionChange,
    handleImageBoxImageLinkChange,
    handleImageIconTitleChange,
    handleImageIconHtmlTagChange,
    handleImageIconDescriptionChange,
    handleImageIconImageLinkChange,
    handleIconBoxImageValue,
    handleIconListDataValue,
    handleIconListDataRemoveValue,
    handleIconListDataCopyValue,
    handleIconListValue,
    handleIconListIconValue,
    handleCounterChange,
    HandleTestiMonialChange,
    handleTabValue,
    handleAddTabsItem,
    handleCopyTabsItem,
    handleTabsDataRemoveValue,
    handleAccordionValue,
    handleAddAccordionItem,
    handleCopyAccordionItem,
    handleAccordionDataRemoveValue,
    handleAlertChange,
    handleSocialIconDataValue,
    handleSocialIconDataRemoveValue,
    handleSocialIconCopyValue,
    handleSocialIconListValue,
    handleSocialIconValue,
    handleHtmlChange,
    HandleRatingChange,
    handleRatingIconSelectValue,
    headingSetting,
    setHeadingSetting,
    bgImageDefault,
    bgImage,
    setBgImage,
    textEditorValue,
    setTextEditorValue,
    videoValue,
    setVideoValue,
    buttonValue,
    setButtonValue,
    dividerValue,
    setDividerValue,
    spacerValue,
    setSpacerValue,
    locationValue,
    setLocationValue,
    imageBox,
    setImageBox,
    iconValue,
    setIconValue,
    imageIcon,
    setImageIcon,
    iconListData,
    setIconListData,
    socialIconData,
    setsocialIconData,
    numberCounter,
    setNumberCounter,
    testiMonialValue,
    setTestiMonialValue,
    ratingValue,
    setRatingValue,
    tabValue,
    setTabValue,
    accordionValue,
    setAccordionValue,
    alertValue,
    setAlertValue,
    htmlValue,
    setHtmlValue,
    firstContentValue,
    setFirstContentValue,
    secondContentValue,
    setSecondContentValue,
    thirdContentValue,
    setThirdContentValue,
    forthContentValue,
    setForthContentValue,
    fifthContentValue,
    setFifthContentValue,
    sixContentValue,
    setSixContentValue,
    sevenContentValue,
    setSevenContentValue,
    eightContentValue,
    setEightContentValue,
    firstFeatureValue,
    setFirstFeatureValue,
    secondFeatureValue,
    setSecondFeatureValue,
    thirdFeatureValue,
    setThirdFeatureValue,
    forthFeatureValue,
    setForthFeatureValue,
    fifthFeatureValue,
    setFifthFeatureValue,
    sixthFeatureValue,
    setSixthFeatureValue,
    sevenFeatureValue,
    setSevenFeatureValue,
    eightFeatureValue,
    setEightFeatureValue,
    secondStepValue,
    setSecondStepValue,
    firstStepValue,
    setFirstStepValue,
    thirdStepValue,
    setThirdStepValue,
    firstTeamValue,
    setFirstTeamValue,
    secondTeamValue,
    setSecondTeamValue,
    thirdTeamValue,
    setThirdTeamValue,
    firstTestimonialValue,
    setFirstTestimonialValue,
    secondTestimonialValue,
    setSecondTestimonialValue,
    thirdTestimonialValue,
    setThirdTestimonialValue,
    firstStatisticValue,
    setFirstStatisticValue,
    secondStatisticValue,
    setSecondStatisticValue,
    thirdStatisticValue,
    setThirdStatisticValue,
    firstContactValue,
    setFirstContactValue,
    secondContactValue,
    setSecondContactValue,
    thirdContactValue,
    setThirdContactValue,
    firstCtaValue,
    setFirstCtaValue,
    secondCtaValue,
    setSecondCtaValue,
    thirdCtaValue,
    setThirdCtaValue,
    forthCtaValue,
    setForthCtaValue,
    firstHeroValue,
    setFirstHeroValue,
    secondHeroValue,
    setSecondHeroValue,
    thirdHeroValue,
    setThirdHeroValue,
    forthHeroValue,
    setForthHeroValue,
    fifthHeroValue,
    setFifthHeroValue,
    sixthHeroValue,
    setSixthHeroValue,
    firstPricingValue,
    setFirstPricingValue,
    secondPricingValue,
    setSecondPricingValue,
    handleFirstContentValue,
    handleAddFirstContentItem,
    handleCopyFirstContentItem,
    handleCopyFifthFeatureItem,
    handleCopySixthFeatureItem,
    handleCopySecondContentItem,
    handleCopyFirstPricingItem,
    handleFirstPricingDataRemoveValue,
    handleCopySecondPricingItem,
    handleCopyThirdContentItem,
    handleCopyForthContentItem,
    handleCopySevenContentItem,
    handleCopyEightContentItem,
    handleCopySecondFeatureItem,
    handleCopySevenFeatureItem,
    handleCopyEightFeatureItem,
    handleCopyFirstStepItem,
    handleCopySecondStepItem,
    handleCopyThirdStepItem,
    handleCopyFirstTeamItem,
    handleCopySecondTeamItem,
    handleCopyThirdTeamItem,
    handleCopyFirstTestimonialItem,
    handleCopyThirdTestimonialItem,
    handleCopyFirstStatisticItem,
    handleCopySecondStatisticItem,
    handleCopyThirdStatisticItem,
    handleCopyThirdFeatureItem,
    handleCopyForthFeatureItem,
    handleSecondCtaFormTitleTagChange,
    handleSixContentProfileChange,
    handleCopyFirstFeatureItem,
    handleAddFirstTeamItem,
    handleFirstContentDataRemoveValue,
    handleFifthFeatureDataRemoveValue,
    handleSixthFeatureDataRemoveValue,
    handleSecondContentDataRemoveValue,
    handleSecondPricingDataRemoveValue,
    handleThirdContentDataRemoveValue,
    handleForthContentDataRemoveValue,
    handleSevenContentDataRemoveValue,
    handleEightContentDataRemoveValue,
    handleFirstFeatureDataRemoveValue,
    handleSecondFeatureDataRemoveValue,
    handleSevenFeatureDataRemoveValue,
    handleEightFeatureDataRemoveValue,
    handleFirstStepDataRemoveValue,
    handleThirdStepDataRemoveValue,
    handleFirstTeamDataRemoveValue,
    handleSecondTeamDataRemoveValue,
    handleSecondStepDataRemoveValue,
    handleThirdTeamDataRemoveValue,
    handleFirstTestimonialDataRemoveValue,
    handleThirdTestimonialDataRemoveValue,
    handleFirstStatisticDataRemoveValue,
    handleSecondStatisticDataRemoveValue,
    handleThirdStatisticDataRemoveValue,
    handleThirdFeatureDataRemoveValue,
    handleForthFeatureDataRemoveValue,
    handlefifthFeatureValue,
    handleSixthFeatureValue,
    handleSecondContentValue,
    handleFirstPricingValue,
    handleSecondPricingValue,
    handleThirdContentValue,
    handleForthContentValue,
    handleSevenContentValue,
    handleEightContentValue,
    handleFirstFeatureValue,
    handleSecondFeatureValue,
    handleSevenFeatureValue,
    handleEightFeatureValue,
    handleFirstStepValue,
    handleSecondStepValue,
    handleThirdStepValue,
    handleFirstTeamValue,
    handleSecondTeamValue,
    handleThirdTeamValue,
    handleFirstTestimonialValue,
    handleThirdTestimonialValue,
    handleFirstStatisticValue,
    handleSecondStatisticValue,
    handleFirstHeroValue,
    handleSecondHeroValue,
    handleThirdHeroValue,
    handleForthHeroValue,
    handleFifthHeroValue,
    handleSixthHeroValue,
    handleThirdStatisticValue,
    handleThirdFeatureValue,
    handleThirdFeatureImageValue,
    handleThirdFeatureObjectFitValue,
    handleForthFeatureValue,
    handleAddFifthFeatureItem,
    handleAddSixthFeatureItem,
    handleAddSecondContentItem,
    handleAddFirstPricingItem,
    handleAddSecondPricingItem,
    handleAddThirdContentItem,
    handleAddForthContentItem,
    handleSevenContentItem,
    handleAddEightContentItem,
    handleAddFirstFeatureItem,
    handleAddSecondFeatureItem,
    handleAddSevenFeatureItem,
    handleAddEightFeatureItem,
    handleAddFirstStepItem,
    handleAddSecondStepItem,
    handleAddThirdStepItem,
    handleAddSecondTeamItem,
    handleAddThirdTeamItem,
    handleAddFirstTestimonialItem,
    handleAddThirdTestimonialItem,
    handleAddFirstStatisticItem,
    handleAddSecondStatisticItem,
    handleAddThirdStatisticItem,
    handleAddThirdFeatureItem,
    handleAddForthFeatureItem,
    handleFirstContentTagChange,
    handleFifthFeatureTagChange,
    handleSixthFeatureTagChange,
    handleSecondContentTagChange,
    handleFirstPricingTagChange,
    handleSecondPricingTagChange,
    handleThirdContentTagChange,
    handleFirstTeamTagChange,
    handleSecondTeamTagChange,
    handleThirdTeamTagChange,
    handleSecondStatisticTagChange,
    handleThirdStatisticTagChange,
    handleFirstContactTagChange,
    handleSecondContactTagChange,
    handleThirdContactTagChange,
    handleSecondCtaTagChange,
    handleThirdCtaTagChange,
    handleForthCtaTagChange,
    handleFirstHeroTagChange,
    handleSecondHeroTagChange,
    handleThirdHeroTagChange,
    handleForthHeroTagChange,
    handleFifthHeroTagChange,
    handleSixthHeroTagChange,
    handleSecondContactAddressTitleTagChange,
    handleSecondContactEmailTitleTagChange,
    handleSecondContactPhoneTitleTagChange,
    handleFirstTestimonialTagChange,
    handleSecondTestimonialTagChange,
    handleSecondTestimonialRoleTagChange,
    handleForthContentTagChange,
    handleFifthContentTagChange,
    handleSixContentTagChange,
    handleFirstContentSubTitleTagChange,
    handleFifthFeatureSubTitleTagChange,
    handleEightContentTitleTagChange,
    handleFirstFeatureTitleTagChange,
    handleSecondFeatureTitleTagChange,
    handleSevenFeatureTitleTagChange,
    handleEightFeatureTitleTagChange,
    handleEightContentDescriptionChange,
    handleSecondFeatureDescriptionChange,
    handleSevenFeatureDescriptionChange,
    handleEightFeatureDescriptionChange,
    handleFirstContentButtonValueChange,
    handleSecondFeatureButtonValueChange,
    handleSevenFeatureButtonValueChange,
    handleEightFeatureButtonValueChange,
    handleThirdContentButtonValueChange,
    handleForthContentButtonValueChange,
    handleFifthContentButtonValueChange,
    handleForthContentSubTitleValueChange,
    handleFifthContentSubButtonValueChange,
    handleFifthContentIconValueChange,
    handleFifthContentIconLinkValueChange,
    handleFifthContentIconFillValueChange,
    handleSixContentSubButtonValueChange,
    handleSixContentButtonLinkChange,
    handleSixContentImageChange,
    handleSixContentObjectFitChange,
    handleSixContentProfileFillValueChange,
    handleSixContentIconFillValueChange,
    handleSixContentIconChange,
    handleFirstContentGridValueChange,
    handleFirstTeamGridValueChange,
    handleSecondTeamGridValueChange,
    handleThirdTeamGridValueChange,
    handleFirstTestimonialGridValueChange,
    handleThirdTestimonialGridValueChange,
    handleFirstStatisticGridValueChange,
    handleSecondStatisticGridValueChange,
    handleThirdStatisticGridValueChange,
    handleFifthFeatureGridValueChange,
    handleSecondContentGridValueChange,
    handleFirstPricingGridValueChange,
    handleThirdContentGridValueChange,
    handleForthContentGridValueChange,
    handleSevenContentGridValueChange,
    handleEightContentGridValueChange,
    handleFirstFeatureGridValueChange,
    handleSecondFeatureGridValueChange,
    handleSevenFeatureGridValueChange,
    handleEightFeatureGridValueChange,
    handleFirstContentTitleValueChange,
    handleFifthFeatureTitleValueChange,
    handleEightContentTitleValueChange,
    handleFirstFeatureTitleValueChange,
    handleSecondFeatureTitleValueChange,
    handleSevenFeatureTitleValueChange,
    handleEightFeatureTitleValueChange,
    handleSecondContentTitleValueChange,
    handleFirstPricingTitleValueChange,
    handleSecondPricingTitleValueChange,
    handleSecondPricingLinkValueChange,
    handleSecondPricingButtonValueChange,
    handleSecondPricingButtonUrlValueChange,
    handleSecondPricingLinkUrlValueChange,
    handleThirdContentTitleValueChange,
    handleFirstTeamTitleValueChange,
    handleSecondTeamTitleValueChange,
    handleSecondStatisticTitleValueChange,
    handleThirdTeamTitleValueChange,
    handleThirdStatisticTitleValueChange,
    handleFirstContactTitleValueChange,
    handleSecondContactTitleValueChange,
    handleThirdContactTitleValueChange,
    handleSecondCtaTitleValueChange,
    handleThirdCtaTitleValueChange,
    handleForthCtaTitleValueChange,
    handleFisrtHeroTitleValueChange,
    handleSecondHeroTitleValueChange,
    handleThirdHeroTitleValueChange,
    handleForthHeroTitleValueChange,
    handleFifthHeroTitleValueChange,
    handleSixthHeroTitleValueChange,
    handleFisrtHeroFirstBtnValueChange,
    handleSecondHeroFirstBtnValueChange,
    handleThirdHeroFirstBtnValueChange,
    handleForthHeroFirstBtnValueChange,
    handleFifthHeroFirstBtnValueChange,
    handleSixthHeroFirstBtnValueChange,
    handleForthHeroNoteValueChange,
    handleFifthHeroNoteValueChange,
    handleSixthHeroNoteValueChange,
    handleForthHeroFirstBtnSubTitleValueChange,
    handleFifthHeroFirstBtnSubTitleValueChange,
    handleSixthHeroFirstBtnSubTitleValueChange,
    handleForthCtaFirstBtnTitleValueChange,
    handleForthCtaFirstBtnSubTitleValueChange,
    handleForthCtaSecondBtnTitleValueChange,
    handleForthCtaSecondBtnSubTitleValueChange,
    handleForthHeroSecondBtnSubTitleValueChange,
    handleFifthHeroSecondBtnSubTitleValueChange,
    handlSixthHeroSecondBtnSubTitleValueChange,
    handleForthHeroFirstBtnLinkValueChange,
    handleFifthHeroFirstBtnLinkValueChange,
    handleSixthHeroFirstBtnLinkValueChange,
    handleForthCtaFirstBtnLinkValueChange,
    handleForthCtaSecondBtnLinkValueChange,
    handleForthHeroSecondBtnLinkValueChange,
    handleFifthHeroSecondBtnLinkValueChange,
    handleSixthHeroSecondBtnLinkValueChange,
    handleFisrtHeroSecondBtnValueChange,
    handleSecondHeroSecondBtnValueChange,
    handleThirdHeroSecondBtnValueChange,
    handleForthHeroSecondBtnValueChange,
    handleFifthHeroSecondBtnValueChange,
    handleSixthHeroSecondBtnValueChange,
    handleSecondCtaFormTitleValueChange,
    handleSecondCtaFormNoteValueChange,
    handleFirstContactNoteValueChange,
    handleFirstContentSubTitleValueChange,
    handleFifthFeatureSubTitleValueChange,
    handleFirstContentDescriptionChange,
    handleSecondContentDescriptionChange,
    handleFirstPricingDescriptionChange,
    handleSecondPricingDescriptionChange,
    handleThirdContentDescriptionChange,
    handleFirstTeamDescriptionChange,
    handleSecondTeamDescriptionChange,
    handleThirdTeamDescriptionChange,
    handleSecondStatisticDescriptionChange,
    handleThirdStatisticDescriptionChange,
    handleFirstContactDescriptionChange,
    handleSecondContactDescriptionChange,
    handleThirdContactDescriptionChange,
    handleSecondCtaDescriptionChange,
    handleThirdCtaDescriptionChange,
    handleForthCtaDescriptionChange,
    handleFirstHeroDescriptionChange,
    handleSecondHeroDescriptionChange,
    handleThirdHeroDescriptionChange,
    handleForthHeroDescriptionChange,
    handleFifthHeroDescriptionChange,
    handleSixthHeroDescriptionChange,
    handleFirstCtaDescriptionChange,
    handleFirstTestimonialDescriptionChange,
    handleSecondTestimonialDescriptionChange,
    handleForthContentDescriptionChange,
    handleFifthContentDescriptionChange,
    handleSixContentDescriptionChange,
    handleForthContentTitleValueChange,
    handleFifthContentTitleValueChange,
    handleSixContentTitleValueChange,
    handleSixContentContentChange,
    handleSecondTestimonialTitleValueChange,
    handleSecondTestimonialRoleValueChange,
    handleSecondTestimonialIconFillValueChange,
    handleSecondTestimonialIconChange,
    handleFirstTestimonialTitleValueChange,
    handleFirstContactShortCodeValueChange,
    handleSecondContactShortCodeValueChange,
    handleThirdContactShortCodeValueChange,
    handleSecondCtaShortCodeValueChange,
    handleForthHeroShortCodeValueChange,
    handleFifthHeroShortCodeValueChange,
    handleSixthHeroShortCodeValueChange,
    handleThirdCtaShortCodeValueChange,
    handleSecondContactNoteValueChange,
    handleSecondContactAddressTitleValueChange,
    handleSecondContactAddressValueChange,
    handleThirdContactAddressValueChange,
    handleSecondContactEmailTitleChange,
    handleSecondContactEmailValueChange,
    handleThirdContactValueChange,
    handleThirdContactEmailValueChange,
    handleSecondContactPhoneTitleChange,
    handleSecondContactPhoneValueChange,
    handleFirstCtaButtonValueChange,
    isNotification,
    setIsNotification,
    notificationMsg,
    setNotificationMsg,
    severity,
    setSeverity,
  } = DynamicStateAndEvent();

  const params = useParams();
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data);
      const sections = result?.data?.sections || [];

      setFlexBoxValue([]);
      setSectionAdd({
        firstGrid: [],
        secondGridRow: [],
        secondGrid: [],
        thirdGrid: [],
        forthGrid: [],
        fifthGrid: [],
        sixGrid: [],
        subFirstGrid: [],
        subSecondGrid: [],
        subThirdGrid: [],
        subForthGrid: [],
        subFifthGrid: [],
        subSixGrid: [],
        subSecondSectionFirstGrid: [],
        subSecondSectionSecondGrid: [],
        subSecondSectionThirdGrid: [],
        subSecondSectionForthGrid: [],
        subSecondSectionFifthGrid: [],
        subSecondSectionSixGrid: [],
        subThirdSectionFirstGrid: [],
        subThirdSectionSecondGrid: [],
        subThirdSectionThirdGrid: [],
        subThirdSectionForthGrid: [],
        subThirdSectionFifthGrid: [],
        subThirdSectionSixGrid: [],
        subForthSectionFirstGrid: [],
        subForthSectionSecondGrid: [],
        subForthSectionThirdGrid: [],
        subForthSectionForthGrid: [],
        subForthSectionFifthGrid: [],
        subForthSectionSixGrid: [],
        subFifthSectionFirstGrid: [],
        subFifthSectionSecondGrid: [],
        subFifthSectionThirdGrid: [],
        subFifthSectionForthGrid: [],
        subFifthSectionFifthGrid: [],
        subFifthSectionSixGrid: [],
        subSixSectionFirstGrid: [],
        subSixSectionSecondGrid: [],
        subSixSectionThirdGrid: [],
        subSixSectionForthGrid: [],
        subSixSectionFifthGrid: [],
        subSixSectionSixGrid: [],
        firstContentComponment: [],
        secondContentComponment: [],
        thirdContentComponment: [],
        forthContentComponment: [],
        fifthContentComponment: [],
        sixContentComponment: [],
        sevenContentComponment: [],
        eightContentComponment: [],
        firstFeatureComponment: [],
        secondFeatureComponment: [],
        thirdFeatureComponment: [],
        forthFeatureComponment: [],
        fifthFeatureComponment: [],
        sixFeatureComponment: [],
        sevenFeatureComponment: [],
        eightFeatureComponment: [],
        firstStepComponment: [],
        secondStepComponment: [],
        thirdStepComponment: [],
        firstTeamComponment: [],
        secondTeamComponment: [],
        thirdTeamComponment: [],
        firstTestimonialComponment: [],
        secondTestimonialComponment: [],
        thirdTestimonialComponment: [],
        firstStatisticComponment: [],
        secondStatisticComponment: [],
        thirdStatisticComponment: [],
        firstContactComponment: [],
        secondContactComponment: [],
        thirdContactComponment: [],
        firstCtaComponment: [],
        secondCtaComponment: [],
        thirdCtaComponment: [],
        forthCtaComponment: [],
        firstHeroComponment: [],
        secondHeroComponment: [],
        thirdHeroComponment: [],
        forthHeroComponment: [],
        fifthHeroComponment: [],
        sixHeroComponment: [],
        firstPricingComponment: [],
        secondPricingComponment: [],
      });
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };
        const matchedItems = sections?.filter(
          (item) =>
            item.section_type === 'flex_cols' ||
            item.section_type === 'flex_rows' ||
            item.section_type === 'flex_two_grid' ||
            item.section_type === 'static_content-1' ||
            item.section_type === 'static_content-2' ||
            item.section_type === 'static_content-3' ||
            item.section_type === 'static_content-4' ||
            item.section_type === 'static_content-5' ||
            item.section_type === 'static_content-6' ||
            item.section_type === 'static_content-7' ||
            item.section_type === 'static_content-8' ||
            item.section_type === 'static_feature-1' ||
            item.section_type === 'static_feature-2' ||
            item.section_type === 'static_feature-3' ||
            item.section_type === 'static_feature-4' ||
            item.section_type === 'static_feature-5' ||
            item.section_type === 'static_feature-6' ||
            item.section_type === 'static_feature-7' ||
            item.section_type === 'static_feature-8' ||
            item.section_type === 'static_step-1' ||
            item.section_type === 'static_step-2' ||
            item.section_type === 'static_step-3' ||
            item.section_type === 'static_team-1' ||
            item.section_type === 'static_team-2' ||
            item.section_type === 'static_team-3' ||
            item.section_type === 'static_testimonial-1' ||
            item.section_type === 'static_testimonial-2' ||
            item.section_type === 'static_testimonial-3' ||
            item.section_type === 'static_statistic-1' ||
            item.section_type === 'static_statistic-2' ||
            item.section_type === 'static_statistic-3' ||
            item.section_type === 'static_contact-1' ||
            item.section_type === 'static_contact-2' ||
            item.section_type === 'static_contact-3' ||
            item.section_type === 'static_cta-1' ||
            item.section_type === 'static_cta-2' ||
            item.section_type === 'static_cta-3' ||
            item.section_type === 'static_cta-4' ||
            item.section_type === 'static_hero-1' ||
            item.section_type === 'static_hero-2' ||
            item.section_type === 'static_hero-3' ||
            item.section_type === 'static_hero-4' ||
            item.section_type === 'static_hero-5' ||
            item.section_type === 'static_hero-6' ||
            item.section_type === 'static_pricing-1' ||
            item.section_type === 'static_pricing-2' ||
            item.section_type === 'flex_grid_two' ||
            item.section_type === 'flex_grid_two' ||
            item.section_type === 'flex_four_grid' ||
            item.section_type === 'flex_three_grid_three' ||
            item.section_type === 'flex_four_grid_four' ||
            item.section_type === 'flex_two_grid_one' ||
            item.section_type === 'flex_one_grid_two' ||
            item.section_type === 'flex_six_grid_six' ||
            item.section_type === 'flex_five_grid' ||
            item.section_type === 'flex_two_grid_two' ||
            item.section_type === 'grid_two_rows' ||
            item.section_type === 'grid_two_cols' ||
            item.section_type === 'grid_three_cols' ||
            item.section_type === 'grid_three_rows' ||
            item.section_type === 'grid_two_for_two' ||
            item.section_type === 'grid_three_for_three',
        );
        const matchedSectionIds = matchedItems?.map((item) => item?.section_id);
        matchedItems?.forEach((item, index) => {
          const { section_id, section_type } = item;
          const section = item?.section;
          setFlexBoxValue((prevFlexBoxValue) => {
            const filteredFlexBoxValue = prevFlexBoxValue?.filter(
              (value) => !(value?.type === section_type && value?.id === section_id),
            );
            return [...filteredFlexBoxValue, { type: section_type, id: section_id }];
          });

          for (const key in section) {
            if (!updatedSections[key]) {
              updatedSections[key] = [];
            }
            updatedSections[key][index] = section[key];
          }
        });
        return updatedSections;
      });
      // setGetLayout(result?.data?.sections);
      setGetLayout(() => {
        try {
          return sections?.map((item) => {
            if (typeof item?.section === 'string') {
              item.section = JSON.parse(item.section);
            }
            return item;
          });
        } catch (error) {
          console.error('Error parsing sections:', error);
          return [];
        }
      });
      closeAddSection();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    handleGetLayout();
  }, [params?.layoutName]);
  const [loadingSections, setLoadingSections] = useState({});

  const UpdateSectionStatus = async (e, id, value) => {
    e.stopPropagation();
    setLoadingSections((prev) => ({ ...prev, [id]: true }));
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data?.status === 200 || data?.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setLoadingSections((prev) => ({ ...prev, [id]: false }));
    }
  };

  // const handleSort = (result) => {
  //   const newSections = [...getLayout];
  //   const [removed] = newSections?.splice(result?.source?.index, 1);
  //   newSections?.splice(result?.destination?.index, 0, removed);
  //   newSections?.forEach((section, index) => {
  //     section.sort_order = index;
  //   });
  //   setGetLayout(newSections);
  //   sortSection(result?.destination?.index, result?.draggableId);
  // };

  const handleSort = {
    onDragEnd(fromIndex, toIndex) {
      try {
        console.log('handleSort', fromIndex, toIndex);
        const newItems = [...getLayout];
        const [movedItem] = newItems?.splice(fromIndex, 1);
        newItems?.splice(toIndex, 0, movedItem);
        newItems?.forEach((section, index) => {
          section.sort_order = index;
        });
        setGetLayout(newItems);
        if (filterId) {
          sortSection(toIndex, filterId);
        }
        setFilterId(null);
        setHoverId(null);
        setDestinationId(null);
        setDropPosition(null);
      } catch (error) {
        console.error('Error in handleSort:', error);
      }
    },
    nodeSelector: '.draggable-main-item',
    handleSelector: '.draggable-main-item',
    scrollSpeed: 5,
  };

  const [subSecId, setSubSecId] = useState(null);

  // const handleSubItemSort = {
  //   async onDragEnd(fromIndex, toIndex) {
  //     const sectionTypes = [
  //       'flex_cols',
  //       'flex_rows',
  //       'flex_two_grid',
  //       'flex_grid_two',
  //       'flex_four_grid',
  //       'static_content-1',
  //       'static_content-2',
  //       'static_content-3',
  //       'flex_three_grid_three',
  //       'flex_four_grid_four',
  //       'flex_two_grid_one',
  //       'flex_one_grid_two',
  //       'flex_six_grid_six',
  //       'flex_five_grid',
  //       'flex_two_grid_two',
  //       'grid_two_rows',
  //       'grid_two_cols',
  //       'grid_three_cols',
  //       'grid_three_rows',
  //       'grid_two_for_two',
  //       'grid_three_for_three',
  //     ];
  //     const matchedItems = [];

  //     // Get the new layout, preserving existing structure
  //     const newItems = getLayout.map((elem) => {
  //       const filteredBlocks = Object.keys(sectionAdd).flatMap((key) => {
  //         const blocks =
  //           elem?.section?.[key]?.filter((block) => sectionTypes.includes(block.type)) || [];
  //         const matchingBlocks = blocks.filter((block) => block.sectionId === subSecId);
  //         matchedItems.push(...matchingBlocks);
  //         return blocks;
  //       });

  //       return {
  //         ...elem,
  //       };
  //     });

  //     if (matchedItems.length === 0) {
  //       console.log('No items matched for reordering');
  //       return newItems;
  //     }

  //     const reorderedMatchedItems = [...matchedItems];
  //     const [movedItem] = reorderedMatchedItems.splice(fromIndex, 1);
  //     reorderedMatchedItems.splice(toIndex, 0, movedItem);

  //     const updatedLayout = newItems.map((elem) => {
  //       Object.keys(sectionAdd).forEach((key) => {
  //         const sectionBlocks = elem.section[key];

  //         if (sectionBlocks) {
  //           elem.section[key] = sectionBlocks.map((block) => {
  //             if (block.sectionId === subSecId) {
  //               return reorderedMatchedItems.shift();
  //             }
  //             return block;
  //           });
  //         }
  //       });
  //       return elem;
  //     });

  //     const modifiedItems = updatedLayout.find((item) => item.section_id === subSecId);
  //     console.log('Specific Updated Item:', modifiedItems?.section?.[gridValue]);
  //     console.log('Updated Layout:', updatedLayout);
  //     setSectionAdd((prevState) => {
  //       const newState = { ...prevState };
  //       newState[gridValue][editSection?.section_index] = modifiedItems?.section?.[gridValue];
  //       return newState;
  //     });
  //     // Uncomment and use this API call if necessary
  //     // await API.post(`/admin/layout/addSection`, {
  //     //   layout: 'home',
  //     //   section:[modifiedItems],
  //     // });
  //     //  handleGetLayout()
  //     setSubSecId(null);
  //     return updatedLayout;
  //   },
  //   nodeSelector: '.draggable-subMenu-item',
  //   handleSelector: '.draggable-subMenu-item',
  // };

  const handleSubItemSort = {
    onDragEnd(fromIndex, toIndex) {
      if (fromIndex === toIndex) return;
      const sectionTypes = [
        'flex_cols',
        'flex_rows',
        'flex_two_grid',
        'flex_grid_two',
        'flex_four_grid',
        'static_content-1',
        'static_content-2',
        'static_content-3',
        'flex_three_grid_three',
        'flex_four_grid_four',
        'flex_two_grid_one',
        'flex_one_grid_two',
        'flex_six_grid_six',
        'flex_five_grid',
        'flex_two_grid_two',
        'grid_two_rows',
        'grid_two_cols',
        'grid_three_cols',
        'grid_three_rows',
        'grid_two_for_two',
        'grid_three_for_three',
      ];

      let matchedItems = [];

      const newItems = getLayout?.map((elem) => {
        Object.keys(sectionAdd)?.forEach((key) => {
          if (key === gridValue) {
            const blocks = elem?.section?.[key]?.filter(
              (block) => sectionTypes?.includes(block?.type) && block?.sectionId === subSecId,
            );
            matchedItems = [...matchedItems, ...(blocks || [])];
          }
        });
        return { ...elem };
      });

      if (matchedItems?.length === 0) {
        console.log('No items matched for reordering');
        return newItems;
      }

      const reorderedItems = [...matchedItems];
      const [movedItem] = reorderedItems?.splice(fromIndex, 1);
      reorderedItems?.splice(toIndex, 0, movedItem);
      console.log('Reordered Items:', reorderedItems);

      setSectionAdd((prevState) => {
        const newState = { ...prevState };
        newState[gridValue][editSection?.section_index] = reorderedItems;
        return newState;
      });
      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout?.map((item) => {
          if (item?.section_id === reorderedItems[0]?.sectionId) {
            return {
              ...item,
              section: {
                ...item?.section,
                [gridValue]: reorderedItems,
              },
            };
          }
          return item;
        });
        return updatedLayout;
      });

      setSubSecId(null);
    },
    nodeSelector: '.draggable-subMenu-item',
    handleSelector: '.draggable-subMenu-item',
  };

  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/layout/updateSection/${id}`, payload);
      handleGetLayout();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const [announcement, setAnnouncement] = useState();
  const handleChangeAnnouncement = (value) => {
    setAnnouncement(value);
  };
  const [visualData, setVisualData] = useState({
    product: null,
    blog: null,
    category: null,
    manufacture: null,
    reassurance: null,
    cmsoffer: null,
    slider: null,
    banner: null,
  });
  const getSectionData = (value) => {
    if (value === 'call layout') {
      handleGetLayout();
      setVisualData({
        product: null,
        blog: null,
        category: null,
        manufacture: null,
        reassurance: null,
        cmsoffer: null,
        slider: null,
        banner: null,
      });
      setEditSection({ section_id: '', section_type: '' });
      setOpen(false);
    } else if (value === 'call common setting') {
      getSettingData();
    } else if (value === 'get client') {
      GetClient();
    } else {
      if (editSection?.section_type === 'product') {
        setVisualData({
          ...visualData,
          product: value,
        });
      } else if (editSection?.section_type === 'blog') {
        setVisualData({
          ...visualData,
          blog: value,
        });
      } else if (editSection?.section_type === 'category') {
        setVisualData({
          ...visualData,
          category: value,
        });
      } else if (editSection?.section_type === 'manufacture') {
        setVisualData({
          ...visualData,
          manufacture: value,
        });
      } else if (editSection?.section_type === 'reassurance') {
        setVisualData({
          ...visualData,
          reassurance: value,
        });
      } else if (editSection?.section_type === 'cmsoffer') {
        setVisualData({
          ...visualData,
          cmsoffer: value,
        });
      } else if (editSection?.section_type === 'slider') {
        setVisualData({
          ...visualData,
          slider: value,
        });
      } else if (editSection?.section_type === 'banner') {
        setVisualData({
          ...visualData,
          banner: value,
        });
      }
    }
  };

  const [manufactureDesign, setManufactureDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [categoryDesign, setCategoryDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [productDesign, setProductDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
    font_weight_subtitle: '',
    font_size_subtitle: '',
    font_height_subtitle: '',
    font_color_subtitle: '',
    font_align_subtitle: '',
    font_style_subtitle: '',
    font_decoration_subtitle: '',
    font_capitalize_subtitle: '',
    font_weight_mrp: '',
    font_size_mrp: '',
    font_height_mrp: '',
    font_color_mrp: '',
    font_style_mrp: '',
    font_decoration_mrp: '',
    font_capitalize_mrp: '',
  });

  const [reassuranceDesign, setReassuranceDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    font_weight_description: '',
    font_size_description: '',
    font_height_description: '',
    font_color_description: '',
    font_align_description: '',
    font_style_description: '',
    font_decoration_description: '',
    font_capitalize_description: '',
    font_break_description: '',
    font_wrap_description: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [bannerDesign, setBannerDesign] = useState({
    display: '',
    direction: '',
    align_item: '',
    gap: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [sliderDesign, setSliderDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [cmsOfferDesign, setCmsOfferDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    float: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  const [blogDesign, setBlogDesign] = useState({
    // display: 'flex',
    // direction: 'column',
    // align_item: '',
    // gap: 0,
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    height: '',
    width: '',
    width_unit: '',
    height_unit: '',
    min_width_unit: '',
    min_height_unit: '',
    max_width_unit: '',
    max_height_unit: '',
    min_height: '',
    min_width: '',
    max_height: '',
    max_width: '',
    overflow: '',
    fit: '',
    position: '',
    float: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_width: '',
    border_color: '',
  });

  useEffect(() => {
    try {
      if (clientData && clientData?.home_layout_css) {
        const clientDetails = JSON.parse(clientData?.home_layout_css);

        const applyClientDetails = () => {
          setManufactureDesign(clientDetails?.manufactureDesign);
          setCategoryDesign(clientDetails?.categoryDesign);
          setProductDesign(clientDetails?.productDesign);
          setReassuranceDesign(clientDetails?.reassuranceDesign);
          setBannerDesign(clientDetails?.bannerDesign);
          setSliderDesign(clientDetails?.sliderDesign);
          setCmsOfferDesign(clientDetails?.cmsOfferDesign);
          setBlogDesign(clientDetails?.blogDesign);
        };

        applyClientDetails();
      } else {
        console.warn('clientData or home_layout_css is undefined');
      }
    } catch (error) {
      console.error('Error parsing JSON: ', error.message);
    }
  }, []);

  const handleUpdateCss = async () => {
    const payload = {
      manufactureDesign,
      categoryDesign,
      productDesign,
      reassuranceDesign,
      bannerDesign,
      sliderDesign,
      cmsOfferDesign,
      blogDesign,
    };

    try {
      const body = encryptData({
        clientInfo: {
          home_layout_css: JSON.stringify(payload),
        },
      });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Style Updated Successfully!');
      setSeverity('success');
      GetClient();
    } catch (error) {
      console.log(error);
    }
  };

  const Puller = styled('div')(() => ({
    width: 30,
    height: 6,
    backgroundColor: 'gray',
    borderRadius: 3,
    position: 'absolute',
    top: 10,
    left: 'calc(50% - 15px)',
  }));

  const [flexBoxValue, setFlexBoxValue] = useState([]);
  const [removedIndices, setRemovedIndices] = useState(new Set());
  const [previewData, setPreviewData] = useState([]);
  const generateCombinedPayloads = () => {
    const updatedPreviewData = [...previewData]; // Clone previewData to work with

    Object.keys(sectionAdd)?.forEach((key) => {
      const gridData = sectionAdd[key];

      if (Array.isArray(gridData) && gridData?.length > 0) {
        gridData?.forEach((gridItems, index) => {
          gridItems?.forEach((gridItem) => {
            const sectionId = gridItem?.sectionId;
            const gridType = gridItem?.type;
            const flexBoxIndex = index;
            // Find the existing section by sectionId
            const existingSectionIndex = updatedPreviewData?.findIndex(
              (previewSection) => previewSection?.section_id === sectionId,
            );

            if (existingSectionIndex !== -1) {
              // If sectionId exists, replace or append data to the existing section
              const existingSection = updatedPreviewData[existingSectionIndex];
              const existingGridItems = existingSection[key] || [];

              const itemExists = existingGridItems?.some((item) => item?.id === gridItem?.id);

              if (!itemExists) {
                // Append new grid items if they don't already exist
                updatedPreviewData[existingSectionIndex] = {
                  ...existingSection,
                  [key]: [...existingGridItems, gridItem], // Append new gridItem to existing items
                };
              } else {
                // Replace the section data with the new gridItem
                updatedPreviewData[existingSectionIndex] = {
                  section_id: sectionId,
                  gridType: gridType,
                  flexBoxIndex: flexBoxIndex,
                  [key]: [gridItem], // Replace with new gridItem
                };
              }
            } else {
              // If sectionId not found, add a new section
              updatedPreviewData?.push({
                section_id: sectionId,
                gridType: gridType,
                flexBoxIndex: flexBoxIndex,
                [key]: [gridItem],
              });
            }
          });
        });
      }
    });
    updatedPreviewData?.sort((a, b) => a?.flexBoxIndex - b?.flexBoxIndex);
    const finalPayload = {
      layout: params?.layoutName,
      section: updatedPreviewData?.map((data) => {
        const sectionContent = Object.keys(data)?.reduce((acc, gridKey) => {
          if (Array.isArray(data[gridKey])) {
            acc[gridKey] = data[gridKey];
          }
          return acc;
        }, {});
        const matchingLayout = getLayout?.find((layout) => layout?.section_id === data?.section_id);
        return {
          section_type: data?.gridType,
          section_id: data?.section_id || '',
          flexBoxIndex: data?.flexBoxIndex,
          section: JSON.stringify(sectionContent),
          status: matchingLayout?.status || false,
        };
      }),
    };

    setPreviewData(updatedPreviewData);

    console.log('Final Payload:', finalPayload);
    return finalPayload;
  };

  const saveElementorData = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const availableKeys = generateCombinedPayloads(sectionAdd, flexBoxValue, prevSecId);
      console.log(availableKeys, 'Available keys with data:');
      const payload = encryptData(availableKeys)
      await API.post(`/admin/layout/addSection`, payload);
      handleGetLayout();
      setPreviewData([]);
    } catch (error) {
      console.log(error);
    }
    setBtnLoading(false);
  };

  console.log(sectionAdd, 'sectionAdd=-=-=-=>>');
  console.log(flexBoxValue, 'flexBox=-=-=-=>>');

  const [activeIndex, setActiveIndex] = useState();
  const [selectedIndex, setSelectedIndex] = useState();
  const [gridValue, setGridValue] = useState();
  const [activeHeadingIndex, setActiveHeadingIndex] = useState(null);
  const [isFlexBoxVisible, setIsFlexBoxVisible] = useState(false);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isPlusButtonVisible, setIsPlusButtonVisible] = useState(true);
  const [isLayoutVisible, setIsLayoutVisible] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);

  useEffect(() => {
    // Check if clickedItem is not null and activeIndex is valid before executing logic
    if (clickedItem && activeIndex !== null) {
      handleFlexBoxClick(clickedItem.event, clickedItem.item?.type, activeIndex); //

      //  setTimeout(()=>{
      //   handleAddSection(activeIndex, clickedItem.item?.blockName);
      //  },300)
      setClickedItem(null);
    }
  }, [clickedItem, activeIndex]);

  const handleItemClick = (e, item, index, value) => {
    e.stopPropagation();

    console.log(item, 'value-=-=-=->>>>');

    setClickedItem({ event: e, item });
    setActiveIndex(index);
    setGridValue(value);
    setOpenTab('setting');
    // handleFlexBoxClick(e,item?.blockName,index,);
  };

  const handleAddSection = (index, sectionType) => {
    console.log(flexBoxValue, 'flexBoxValue=-=-=->>>');
    console.log(sectionType, 'sectionType=-=-=->>>');

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);

    let newSection;

    if (sectionType === 'heading') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'grid') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: '',
        blockName: 'grid',
        sectionId: elementorId,
      };
    } else if (sectionType === 'image') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
        },
        blockName: 'image',
        sectionId: elementorId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'textEditor') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          textEditorValue:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
        },
        blockName: 'textEditor',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'video') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      };
    } else if (sectionType === 'button') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'divider') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
        },
      };
    } else if (sectionType === 'spacer') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: { width: '40' },
        blockName: 'spacer',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'googleMap') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      };
    } else if (sectionType === 'icon') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      };
    } else if (sectionType === 'imageBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'generalIconList') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'counter') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          number_shadow_color: '',
          number_shadow_blur: '',
          number_shadow_horizontal: '',
          number_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          counter_font_weight: '',
          counter_font_size: '',
          counter_font_height: '',
          counter_font_color: '',
          counter_font_align: '',
          counter_font_style: '',
          counter_font_decoration: '',
          counter_font_capitalize: '',
          counter_font_break: '',
          counter_font_wrap: '',
          counter_letter_spacing: '',
          counter_word_spacing: '',
          counter_text_stroke: '',
          counter_stroke_color: '',
          title_position: '',
          title_horizontal_alignment: '',
          title_vertical_alignment: '',
          number_position: '',
          title_spacing: '',
        },
      };
    } else if (sectionType === 'testimonial') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      };
    } else if (sectionType === 'tabs') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'accordion') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      };
    } else if (sectionType === 'socialIcon') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      };
    } else if (sectionType === 'alert') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      };
    } else if (sectionType === 'html') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'rating') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          icon_size: '',
          icon_spacing: '',
        },
      };
    } else if (sectionType === 'textPath') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textPath',
      };
    } else if (sectionType === 'mediaImage') {
      newSection = {
        id: id,
        type: selectedIndex,
        sectionId: elementorId,

        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaImage',
      };
    } else if (sectionType === 'mediaVideo') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'mediaVideo',
      };
    } else if (sectionType === 'youtube') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'youtube',
      };
    } else if (sectionType === 'formBlock') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formBlock',
      };
    } else if (sectionType === 'label') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'label',
      };
    } else if (sectionType === 'input') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'input',
      };
    } else if (sectionType === 'fileUpload') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'fileUpload',
      };
    } else if (sectionType === 'textArea') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'textArea',
      };
    } else if (sectionType === 'checkBox') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'checkBox',
      };
    } else if (sectionType === 'radioButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'radioButton',
      };
    } else if (sectionType === 'select') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'select',
      };
    } else if (sectionType === 'formButton') {
      newSection = {
        id: id,
        type: selectedIndex,
        content: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        blockName: 'formButton',
      };
    }

    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      console.log(updatedSections, 'updatedSections=-=-=->>');
      console.log(gridValue, 'updatedSections=-=-=->>');

      if (!updatedSections[gridValue]) {
        updatedSections[gridValue] = [];
      }

      if (!updatedSections[gridValue][index]) {
        updatedSections[gridValue][index] = [];
      }
      const sectionArray = updatedSections[gridValue][index];
      const newSectionWithProps = {
        ...newSection,
        orderId: sectionArray?.length + 1,
      };

      if (sectionType === 'grid') {
        //one section add only
        // const existingSections = updatedSections[gridValue][index];
        // const hasHeading = existingSections?.some(section => section.blockName === 'heading');
        // if (hasHeading) {
        //   if (sectionType !== 'heading') {
        //     return prevSections;
        //   }
        // }
        const gridSectionExists = updatedSections[gridValue][index]?.some(
          (section) => section?.blockName === 'grid',
        );

        if (!gridSectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else if (
        selectedIndex === 'grid_two_rows' ||
        selectedIndex === 'grid_two_cols' ||
        selectedIndex === 'grid_three_cols' ||
        selectedIndex === 'grid_three_rows' ||
        selectedIndex === 'grid_two_for_two' ||
        selectedIndex === 'grid_three_for_three'
      ) {
        const sectionExists = updatedSections[gridValue][index]?.some(
          (section) =>
            section?.type === 'grid_two_rows' ||
            section?.type === 'grid_two_cols' ||
            section?.type === 'grid_three_cols' ||
            section?.type === 'grid_three_rows' ||
            section?.type === 'grid_two_for_two' ||
            section?.type === 'grid_three_for_three',
        );

        if (!sectionExists) {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }
      } else {
        updatedSections[gridValue][index]?.push(newSectionWithProps);
      }

      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout.map((item) => {
          if (item.section_id === newSectionWithProps.sectionId) {
            return {
              ...item,
              section: {
                ...item.section,
                [gridValue]: item.section?.[gridValue]
                  ? [...item.section[gridValue], newSectionWithProps]
                  : [newSectionWithProps],
              },
            };
          }
          return item;
        });

        return updatedLayout;
      });

      return updatedSections;
    });
  };

  const handleApplyChangesElementor = async (section_id, value) => {
    const payload = {
      blogDesign,
    };

    updateSectionStyle(section_id, value);

    // try {
    //   const body = encryptData({
    //     clientInfo: {
    //       home_layout_css: JSON.stringify(payload),
    //     },
    //   });
    //   await API.put('/admin/client/updateClient', body);
    //   setIsNotification(true);
    //   setNotificationMsg('Style Updated Successfully!');
    //   setSeverity('success');
    //   GetClient();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const removeSection = async (sectionIndex, sectionType) => {
    setSectionAdd((prevSections) => {
      const updatedSections = { ...prevSections };

      let gridKeys = [];
      switch (sectionType) {
        case 'flex_cols':
          gridKeys?.push('firstGrid');
          break;
        case 'flex_rows':
          gridKeys?.push('secondGridRow');
          break;
        case 'flex_two_grid':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'flex_four_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_three_grid_three':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_four_grid_four':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'flex_two_grid_one':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_one_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'flex_six_grid_six':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        case 'flex_five_grid':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid', 'fifthGrid');
          break;
        case 'flex_two_grid_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_two_rows':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_two_cols':
          gridKeys?.push('firstGrid', 'secondGrid');
          break;
        case 'grid_three_cols':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_three_rows':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid');
          break;
        case 'grid_two_for_two':
          gridKeys?.push('firstGrid', 'secondGrid', 'thirdGrid', 'forthGrid');
          break;
        case 'grid_three_for_three':
          gridKeys?.push(
            'firstGrid',
            'secondGrid',
            'thirdGrid',
            'forthGrid',
            'fifthGrid',
            'sixGrid',
          );
          break;
        default:
          return prevSections;
      }

      gridKeys?.forEach((gridKey) => {
        if (Array.isArray(updatedSections[gridKey])) {
          updatedSections[gridKey] = updatedSections[gridKey]?.map((sections, idx) => {
            if (idx === sectionIndex) {
              return sections?.filter((section) => section?.type !== sectionType);
            }
            return sections;
          });

          if (updatedSections[gridKey]?.every((sections) => sections?.length === 0)) {
            delete updatedSections[gridKey];
          }
        }
      });
      return updatedSections;
    });

    setFlexBoxValue((prevValues) => {
      const updatedValues = prevValues.map((value, index) => {
        return index === sectionIndex ? [] : value;
      });
      const allEmpty = updatedValues.every((value) => value.length === 0);
      return allEmpty ? [] : updatedValues;
    });

    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout.filter((layoutItem) => {
        return !(
          layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex
        );
      });

      const removedItem = prevLayout.find((layoutItem) => {
        return layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex;
      });
      // if (removedItem) {
      //   setFlexBoxValue((prevValues) => {
      //     // Ensure we have a valid index to remove
      //     if (sectionIndex < prevValues.length) {
      //       const updatedValues = prevValues.filter((_, index) => index !== sectionIndex); // Remove by index
      //       return updatedValues;
      //     }
      //     return prevValues; // Return previous values if index is out of range
      //   });
      // }
      return updatedLayout;
    });
    const removedItem = getLayout.find((layoutItem) => {
      return layoutItem.section_type === sectionType && layoutItem.flexBoxIndex === sectionIndex;
    });
    if (removedItem?._id) {
      try {
        const payload = encryptData({ id: [removedItem._id] });
        const deleteResponse = await API.post(`/admin/layout/deleteSection`, payload);
        if (deleteResponse.status === 200 || deleteResponse.status === 304) {
          handleGetLayout();
        }
      } catch (error) {
        console.error('Error deleting section:', error);
      }
    }

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      newSet?.add(sectionIndex);
      return newSet;
    });
  };

  const getPrevSecId = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    setPrevSecId(id);
  };

  const sectionMap = {
    flex_cols: 'firstGrid',
    flex_rows: 'secondGridRow',
    flex_two_grid_firstGrid: 'firstGrid',
    flex_two_grid_secondGrid: 'secondGrid',
    flex_grid_two_firstGrid: 'firstGrid',
    flex_grid_two_secondGrid: 'secondGrid',
    flex_four_grid_firstGrid: 'firstGrid',
    flex_four_grid_secondGrid: 'secondGrid',
    flex_four_grid_thirdGrid: 'thirdGrid',
    flex_four_grid_forthGrid: 'forthGrid',
    flex_three_grid_three_firstGrid: 'firstGrid',
    flex_three_grid_three_secondGrid: 'secondGrid',
    flex_three_grid_three_thirdGrid: 'thirdGrid',
    flex_four_grid_four_firstGrid: 'firstGrid',
    flex_four_grid_four_secondGrid: 'secondGrid',
    flex_four_grid_four_thirdGrid: 'thirdGrid',
    flex_four_grid_four_forthGrid: 'forthGrid',
    flex_two_grid_one_firstGrid: 'firstGrid',
    flex_two_grid_one_secondGrid: 'secondGrid',
    flex_two_grid_one_thirdGrid: 'thirdGrid',
    flex_one_grid_two_firstGrid: 'firstGrid',
    flex_one_grid_two_secondGrid: 'secondGrid',
    flex_one_grid_two_thirdGrid: 'thirdGrid',
    flex_six_grid_six_firstGrid: 'firstGrid',
    flex_six_grid_six_secondGrid: 'secondGrid',
    flex_six_grid_six_thirdGrid: 'thirdGrid',
    flex_six_grid_six_forthGrid: 'forthGrid',
    flex_six_grid_six_fifthGrid: 'fifthGrid',
    flex_six_grid_six_sixGrid: 'sixGrid',
    flex_five_grid_firstGrid: 'firstGrid',
    flex_five_grid_secondGrid: 'secondGrid',
    flex_five_grid_thirdGrid: 'thirdGrid',
    flex_five_grid_forthGrid: 'forthGrid',
    flex_five_grid_fifthGrid: 'fifthGrid',
    flex_two_grid_two_firstGrid: 'firstGrid',
    flex_two_grid_two_secondGrid: 'secondGrid',
    flex_two_grid_two_thirdGrid: 'thirdGrid',
    flex_two_grid_two_forthGrid: 'forthGrid',
    subFirstGrid: 'subFirstGrid',
    subSecondGrid: 'subSecondGrid',
    subThirdGrid: 'subThirdGrid',
    subForthGrid: 'subForthGrid',
    subFifthGrid: 'subFifthGrid',
    subSixGrid: 'subSixGrid',
    subSecondSectionFirstGrid: 'subSecondSectionFirstGrid',
    subSecondSectionSecondGrid: 'subSecondSectionSecondGrid',
    subSecondSectionThirdGrid: 'subSecondSectionThirdGrid',
    subSecondSectionForthGrid: 'subSecondSectionForthGrid',
    subSecondSectionFifthGrid: 'subSecondSectionFifthGrid',
    subSecondSectionSixGrid: 'subSecondSectionSixGrid',
    subThirdSectionFirstGrid: 'subThirdSectionFirstGrid',
    subThirdSectionSecondGrid: 'subThirdSectionSecondGrid',
    subThirdSectionThirdGrid: 'subThirdSectionThirdGrid',
    subThirdSectionForthGrid: 'subThirdSectionForthGrid',
    subThirdSectionFifthGrid: 'subThirdSectionFifthGrid',
    subThirdSectionSixGrid: 'subThirdSectionSixGrid',
    subForthSectionFirstGrid: 'subForthSectionFirstGrid',
    subForthSectionSecondGrid: 'subForthSectionSecondGrid',
    subForthSectionThirdGrid: 'subForthSectionThirdGrid',
    subForthSectionForthGrid: 'subForthSectionForthGrid',
    subForthSectionFifthGrid: 'subForthSectionFifthGrid',
    subForthSectionSixGrid: 'subForthSectionSixGrid',
    subFifthSectionFirstGrid: 'subFifthSectionFirstGrid',
    subFifthSectionSecondGrid: 'subFifthSectionSecondGrid',
    subFifthSectionThirdGrid: 'subFifthSectionThirdGrid',
    subFifthSectionForthGrid: 'subFifthSectionForthGrid',
    subFifthSectionFifthGrid: 'subFifthSectionFifthGrid',
    subFifthSectionSixGrid: 'subFifthSectionSixGrid',
    subSixSectionFirstGrid: 'subSixSectionFirstGrid',
    subSixSectionSecondGrid: 'subSixSectionSecondGrid',
    subSixSectionThirdGrid: 'subSixSectionThirdGrid',
    subSixSectionForthGrid: 'subSixSectionForthGrid',
    subSixSectionFifthGrid: 'subSixSectionFifthGrid',
    subSixSectionSixGrid: 'subSixSectionSixGrid',
    grid_two_rows_firstGrid: 'firstGrid',
    grid_two_rows_secondGrid: 'secondGrid',
    grid_two_cols_firstGrid: 'firstGrid',
    grid_two_cols_secondGrid: 'secondGrid',
    grid_three_cols_firstGrid: 'firstGrid',
    grid_three_cols_secondGrid: 'secondGrid',
    grid_three_cols_thirdGrid: 'thirdGrid',
    grid_three_rows_firstGrid: 'firstGrid',
    grid_three_rows_secondGrid: 'secondGrid',
    grid_three_rows_thirdGrid: 'thirdGrid',
    grid_two_for_two_firstGrid: 'firstGrid',
    grid_two_for_two_secondGrid: 'secondGrid',
    grid_two_for_two_thirdGrid: 'thirdGrid',
    grid_two_for_two_forthGrid: 'forthGrid',
    grid_three_for_three_firstGrid: 'firstGrid',
    grid_three_for_three_secondGrid: 'secondGrid',
    grid_three_for_three_thirdGrid: 'thirdGrid',
    grid_three_for_three_forthGrid: 'forthGrid',
    grid_three_for_three_fifthGrid: 'fifthGrid',
    grid_three_for_three_sixGrid: 'sixGrid',
  };

  const handleRemoveSingleItem = (item) => {
    const { obj, sectionArray } = item;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    console.log('sectionKey', sectionKey);
    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey].map((innerArray) =>
        innerArray.filter((item) => item.id !== obj?.id),
      );
      const isAllEmpty = updatedArray.every((innerArray) => innerArray.length === 0);
      return {
        ...prevState,
        [sectionKey]: isAllEmpty ? [] : updatedArray,
      };
    });
    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout.map((layoutItem) => {
        if (layoutItem.section_id === obj.sectionId) {
          return {
            ...layoutItem,
            section: {
              ...layoutItem.section,
              [sectionKey]: layoutItem.section[sectionKey]
                ? layoutItem.section[sectionKey].filter((sectionItem) => sectionItem.id !== obj?.id)
                : [],
            },
          };
        }
        return layoutItem;
      });
      return updatedLayout;
    });
  };

  const handleDuplicateSingleItem = (item) => {
    const { obj, sectionArray, itemIndex } = item;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);
      const randomValue = Math.random().toString(16).substr(2, 10);
      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    const copiedItem = { ...obj, id: id };
    const gridTypes = [
      'grid_two_rows',
      'grid_two_cols',
      'grid_three_cols',
      'grid_three_rows',
      'grid_two_for_two',
      'grid_three_for_three',
    ];

    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey]?.map((innerArray) => [...innerArray]);

      const targetArray =
        updatedArray?.find((innerArray) =>
          innerArray?.some((innerItem) => innerItem?.id === obj?.id),
        ) || [];

      const gridTypeExists = targetArray?.some((existingItem) =>
        gridTypes?.includes(existingItem?.type),
      );

      if (gridTypeExists) {
        return prevState;
      }

      if (itemIndex + 1 <= targetArray?.length) {
        targetArray?.splice(itemIndex + 1, 0, copiedItem);
      } else {
        targetArray?.push(copiedItem);
      }
      return {
        ...prevState,
        [sectionKey]: updatedArray,
      };
    });
    setGetLayout((prevLayout) =>
      prevLayout.map((layoutItem) => {
        if (layoutItem?.section_id === copiedItem?.sectionId) {
          const existingItems = layoutItem?.section[sectionKey] || [];
          const gridTypeExists = existingItems?.some((existingItem) =>
            gridTypes?.includes(existingItem?.type),
          );

          if (gridTypeExists) {
            return layoutItem;
          }

          const updatedItems = [...existingItems];
          const originalIndex = updatedItems?.findIndex(
            (existingItem) => existingItem?.id === obj?.id,
          );
          if (originalIndex !== -1) {
            updatedItems?.splice(originalIndex + 1, 0, copiedItem);
          } else {
            updatedItems?.push(copiedItem);
          }
          return {
            ...layoutItem,
            section: {
              ...layoutItem?.section,
              [sectionKey]: updatedItems,
            },
          };
        }
        return layoutItem;
      }),
    );
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleCopySingleItem = debounce((item) => {
    const { selectedSingleItem, type } = item;
    console.log('selectedSingleItem', type);
    const { obj } = selectedSingleItem;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    const newSectionType = selectContextItem?.copy_type;

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);
      const randomValue = Math.random().toString(16).substr(2, 10);
      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    const newItem = {
      ...obj,
      id: id,
      type: newSectionType,
      sectionId: selectContextItem?.section_id,
    };

    const gridTypes = [
      'grid_two_rows',
      'grid_two_cols',
      'grid_three_cols',
      'grid_three_rows',
      'grid_two_for_two',
      'grid_three_for_three',
    ];

    if (type === 'copy' || type === 'cut') {
      setSectionAdd((prevState) => {
        const updatedGrid = [...prevState[sectionKey]];
        for (let i = 0; i <= selectContextItem?.section_index; i++) {
          if (!updatedGrid[i]) {
            updatedGrid[i] = [];
          }
        }
        const sectionArray = updatedGrid[selectContextItem?.section_index] || [];

        const sectionExists = sectionArray?.some((existingItem) =>
          gridTypes?.includes(existingItem?.type),
        );

        if (sectionExists) {
          return prevState;
        }

        sectionArray?.push(newItem);
        updatedGrid[selectContextItem?.section_index] = sectionArray;
        return {
          ...prevState,
          [sectionKey]: updatedGrid,
        };
      });
      setGetLayout((prevLayout) =>
        prevLayout?.map((layoutItem) => {
          if (layoutItem?.section_id === newItem?.sectionId) {
            const existingItems = layoutItem?.section[sectionKey] || [];

            const gridTypeExists = existingItems?.some((existingItem) =>
              gridTypes?.includes(existingItem?.type),
            );
            if (gridTypeExists) {
              return layoutItem;
            }

            const itemExists = existingItems?.some(
              (existingItem) => existingItem?.id === newItem?.id,
            );
            if (!itemExists) {
              return {
                ...layoutItem,
                section: {
                  ...layoutItem.section,
                  [sectionKey]: [...existingItems, newItem],
                },
              };
            }
          }
          return layoutItem;
        }),
      );
    }
  }, 300);

  const handleCutSingleItem = (item) => {
    const { selectedSingleItem } = item;
    const { obj } = selectedSingleItem;
    const sectionKey = sectionMap[selectContextItem?.section_type];
    console.log('sectionKeysectionKey', selectContextItem?.section_type, sectionKey, obj);
    setSectionAdd((prevState) => {
      const updatedArray = prevState[sectionKey]?.map((innerArray) =>
        innerArray?.filter((item) => item?.id !== obj?.id),
      );
      const isAllEmpty = updatedArray?.every((innerArray) => innerArray?.length === 0);
      return {
        ...prevState,
        [sectionKey]: isAllEmpty ? [] : updatedArray,
      };
    });

    setGetLayout((prevLayout) => {
      const updatedLayout = prevLayout?.map((layoutItem) => {
        if (layoutItem?.section_id === obj?.sectionId) {
          return {
            ...layoutItem,
            section: {
              ...layoutItem.section,
              [sectionKey]: layoutItem?.section[sectionKey]
                ? layoutItem?.section[sectionKey]?.filter(
                    (sectionItem) => sectionItem.id !== obj?.id,
                  )
                : [],
            },
          };
        }
        return layoutItem;
      });
      return updatedLayout;
    });
  };

  const [activeHeading, setActiveHeading] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(null);

  const handleHeadingClick = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  const handleImageClick = (index) => {
    setActiveImageIndex(index);
  };

  const handleHeadingClickLocal = (e, sectionType, itemIndex, sectionIndex) => {
    e.stopPropagation();
    handleHeadingClick(e, sectionIndex, itemIndex, sectionIndex);
    setActiveHeading({ sectionType, itemIndex, sectionIndex });
  };

  const handleFlexBoxClick = (e, sectionType) => {
    console.log(sectionType, 'sectionType=-=-=>>');
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const flexId = generateCustomObjectId(24);
    console.log(sectionType, 'value=-=-=-=>>>');
    // console.log(flexId, 'value=-=-=-=>>>');
    e.stopPropagation();
    const newSections = [...flexBoxValue, { type: sectionType, id: flexId }];
    const index = newSections?.length - 1;
    console.log(newSections, 'newSections=-=-=-=>>>');
    setFlexBoxValue(newSections);
    setElementorId(flexId);
    const updatedGetLayout = [
      ...getLayout,
      {
        section_id: flexId,
        section_type: sectionType,
        status: true,
        sort_order: getLayout.length,
        section: { [gridValue]: [] },
        flexBoxIndex: newSections?.length - 1,
      },
    ];
    setGetLayout(updatedGetLayout);

    setRemovedIndices((prev) => {
      const newSet = new Set(prev);
      return newSet;
    });

    setEditSection({
      section_id: flexId,
      section_type: sectionType,
    });

    setOpenTab('setting');
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
    setIsPlusButtonVisible(true);

    setActiveIndex(newSections?.length - 1);
    setActiveHeadingIndex({ sectionIndex: null, itemIndex: null });

    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);
    let newSection;
    if (sectionType === 'static_content-1') {
      newSection = {
        id: id,
        type: 'static_content-1',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          button: 'Button',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.<p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                  viewBox='0 0 24 24'
                  >
                  <path d='M5 12h14M12 5l7 7-7 7'></path>
                </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagondisrupt edison bulbche.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                  </svg>`,
              link: '',
              isFill: false,
            },
          ],
        },
        blockName: 'content-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-2') {
      newSection = {
        id: id,
        type: 'static_content-2',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 4,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Chichen Itza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 2,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Colosseum Roma',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 3,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'Great Pyramid of Giza',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
            {
              id: 4,
              sub_title: 'SUBTITLE',
              sub_title_htmlTag: 'h3',
              title: 'San Francisco',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              image: 'https://dummyimage.com/720x400',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-3') {
      newSection = {
        id: id,
        type: 'static_content-3',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Button',
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                      >
                        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                    </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                     >
                        <circle cx="6" cy="6" r="3"></circle>
              <circle cx="6" cy="18" r="3"></circle>
              <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
                     </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 4,
              title: 'Melanchole',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                         stroke-linejoin='round'
                         stroke-width='2'
                        class='w-6 h-6'
                         viewBox='0 0 24 24'
                     >
                            <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1zM4 22v-7"></path>
                      </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 5,
              title: 'Bunker',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"></path>
                            </svg>`,
              link: '',
              isFill: false,
            },
            {
              id: 6,
              title: 'Ramona Falls',
              htmlTag: 'h2',
              description:
                '<p>Fingerstache flexitarian street art 8-bit waistcoat. Distillery hexagon disrupt edison bulbche.</p>',
              isEdit: false,
              icon: ` <svg
                              fill='none'
                              stroke='currentColor'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              stroke-width='2'
                              class='w-6 h-6'
                              viewBox='0 0 24 24'
                            >
                              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                            </svg>`,
              link: '',
              isFill: false,
            },
          ],
        },
        blockName: 'content-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-4') {
      newSection = {
        id: id,
        type: 'static_content-4',
        sectionId: flexId,
        content: {
          title: 'Pitchfork Kickstarter Taxidermy',
          htmlTag: 'h1',
          gridValue: 3,
          button: 'Learn more',
          description:
            '<p>Locavore cardigan small batch roof party blue bottle blog meggings sartorial jean shorts kickstarter migas sriracha church-key synth succulents. Actually taiyaki neutra, distillery gastropub pok pok ugh.</p>',
          sub_title: 'CATEGORIES',
          sub_title_htmlTag: 'h2',
          item: [
            {
              id: 1,
              link: 'First link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 2,
              link: 'Second link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 3,
              link: 'Third link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 4,
              link: 'Forth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 5,
              link: 'Fifth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 6,
              link: 'Sixth link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 7,
              link: 'Seventh link',
              link_url: '#',
              isEdit: false,
            },
            {
              id: 8,
              link: 'Eighth link',
              link_url: '#',
              isEdit: false,
            },
          ],
        },
        blockName: 'content-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-5') {
      newSection = {
        id: id,
        type: 'static_content-5',
        sectionId: flexId,
        content: {
          title: 'Kickstarter Actually Pinterest Brunch Bitters Occupy',
          htmlTag: 'h1',
          button: 'Button',
          description:
            '<p>Taxidermy bushwick celiac master cleanse microdosing seitan. Fashion axe four dollar toast truffaut, direct trade kombucha brunch williamsburg keffiyeh gastropub tousled squid meh taiyaki drinking vinegar tacos.</p>',
          button_link: 'Learn more',
          icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
              viewBox='0 0 24 24'
          >
           <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
          link: '',
          isFill: false,
        },
        blockName: 'content-5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-6') {
      newSection = {
        id: id,
        type: 'static_content-6',
        sectionId: flexId,
        content: {
          title: 'Phoebe Caulfield',
          htmlTag: 'h1',
          description:
            '<p>Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>',
          content:
            '<p>Meggings portland fingerstache lyft, post-ironic fixie man bun banh mi umami everyday carry hexagon locavore direct trade art party. Locavore small batch listicle gastropub farm-to-table lumbersexual salvia messenger bag. Coloring book flannel truffaut craft beer drinking vinegar sartorial, disrupt fashion axe normcore meh butcher. Portland 90 scenester vexillologist forage post-ironic asymmetrical, chartreuse disrupt butcher paleo intelligentsia pabst before they sold out four loko. 3 wolf moon brooklyn.</p>',
          button_link: 'Learn more',
          link_url: '#',
          image: 'https://dummyimage.com/1200x500',
          profile: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-10 h-10'
                      viewBox='0 0 24 24'
                      >
                        <path d='M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2'></path>
                      <circle cx='12' cy='7' r='4'></circle>
                    </svg>`,
          objectFit: 'fill',
          profileFill: false,
          icon: ` <svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                   >
                      <path d='M5 12h14M12 5l7 7-7 7'></path>
                   </svg>`,
          isFill: false,
        },
        blockName: 'content-6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          content_font_weight: '',
          content_font_size: '',
          content_font_height: '',
          content_font_color: '#6B7280',
          content_font_align: '',
          content_font_style: '',
          content_font_decoration: '',
          content_font_capitalize: '',
          content_font_break: '',
          content_font_wrap: '',
          content_letter_spacing: '',
          content_word_spacing: '',
          content_text_stroke: '',
          content_stroke_color: '',
          content_text_shadow_color: '',
          content_text_shadow_blur: '',
          content_text_shadow_horizontal: '',
          content_text_shadow_vertical: '',
          content_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_content-7') {
      newSection = {
        id: id,
        type: 'static_content-7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          item: [
            {
              id: 1,
              title: 'Buy YouTube Videos',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Williamsburg occupy sustainable snackwave gochujang. Pinterest cornhole brunch, slow-carb neutra</p>',
              button: 'Button',
              isEdit: false,
              image: 'https://dummyimage.com/1201x501',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'content-7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          button_link_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_content-8') {
      newSection = {
        id: id,
        type: 'static_content-8',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Space The Final Frontier',
          htmlTag: 'h2',
          description:
            '<p>Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p>',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Swag shoivdigoitch literally meditation subway tile tumblr cold-pressed. Gastropub street art beard dreamcatcher neutra, ethical XOXO lumbersexual.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              image: 'https://dummyimage.com/1203x503',
              icon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-4 h-4 ml-2'
                        viewBox='0 0 24 24'
                      >
                        <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'content-8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-1') {
      newSection = {
        id: id,
        type: 'static_feature-1',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                      <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
                  </svg>`,
              linkIcon: `<svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  class='w-4 h-4 ml-2'
                viewBox='0 0 24 24'
                >
                <path d='M5 12h14M12 5l7 7-7 7'></path>
              </svg>`,
              titleIconFill: false,
              linkIconFill: false,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-2') {
      newSection = {
        id: id,
        type: 'static_feature-2',
        sectionId: flexId,
        content: {
          gridValue: 3,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
          fill='none'
          stroke='currentColor'
          stroke-linecap='round'
          stroke-linejoin='round'
          stroke-width='2'
          class='w-4 h-4 ml-2'
        viewBox='0 0 24 24'
        >
        <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine, ramps microdosing banh mi pug VHS try-hard ugh iceland kickstarter tumblr live-edge tilde.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                        fill='none'
                        stroke='currentColor'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        stroke-width='2'
                        class='w-6 h-6'
                        viewBox='0 0 24 24'
                       >
                       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                     </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-3') {
      newSection = {
        id: id,
        type: 'static_feature-3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-6 h-6'
        viewBox='0 0 24 24'
      >
        <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
    </svg>`,
              linkIcon: `<svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
    </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-6 h-6'
                      viewBox='0 0 24 24'
                    >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
                  </svg>`,
              linkIcon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='w-4 h-4 ml-2'
                    viewBox='0 0 24 24'
                    >
                    <path d='M5 12h14M12 5l7 7-7 7'></path>
                    </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
          image: 'https://dummyimage.com/460x500',
          objectFit: 'fill',
        },
        blockName: 'feature-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-4') {
      newSection = {
        id: id,
        type: 'static_feature-4',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              titleIconFill: false,
              linkIconFill: false,
              link: '',
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button: 'Learn more',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-5') {
      newSection = {
        id: id,
        type: 'static_feature-5',
        sectionId: flexId,
        content: {
          title: 'ROOF PARTY POLAROID',
          htmlTag: 'h6',
          subTitle: 'Master Cleanse Reliac Heirloom',
          subHtmlTag: 'h2',
          gridValue: 3,
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
      <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              linkIcon: `<svg
      fill='none'
      stroke='currentColor'
      stroke-linecap='round'
      stroke-linejoin='round'
      stroke-width='2'
      class='w-4 h-4 ml-2'
      viewBox='0 0 24 24'
      >
      <path d='M5 12h14M12 5l7 7-7 7'></path>
      </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'Neptune',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature-5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          sub_title_font_weight: '500',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#6366f1',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '1.6',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-6') {
      newSection = {
        id: id,
        type: 'static_feature-6',
        sectionId: flexId,
        content: {
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M22 12h-4l-3 9L9 3l-3 9H2'></path>
          </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
             >
                <circle cx="6" cy="6" r="3"></circle>
      <circle cx="6" cy="18" r="3"></circle>
      <path d="M20 4L8.12 15.88M14.47 14.48L20 20M8.12 8.12L12 12"></path>
             </svg>`,
              linkIcon: `<svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-4 h-4 ml-2'
            viewBox='0 0 24 24'
            >
            <path d='M5 12h14M12 5l7 7-7 7'></path>
          </svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h2',
              description:
                '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>',
              button_name: 'Learn More',
              button_link: '#',
              isEdit: false,
              titleIcon: ` <svg
        fill='none'
        stroke='currentColor'
        stroke-linecap='round'
        stroke-linejoin='round'
        stroke-width='2'
        class='w-10 h-10'
        viewBox='0 0 24 24'
       >
       <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
<circle cx="12" cy="7" r="4"></circle>
     </svg>`,
              linkIcon: `<svg
fill='none'
stroke='currentColor'
stroke-linecap='round'
stroke-linejoin='round'
stroke-width='2'
class='w-4 h-4 ml-2'
viewBox='0 0 24 24'
>
<path d='M5 12h14M12 5l7 7-7 7'></path>
</svg>`,
              link: '',
              titleIconFill: false,
              linkIconFill: false,
            },
          ],
        },
        blockName: 'feature-6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-7') {
      newSection = {
        id: id,
        type: 'static_feature-7',
        sectionId: flexId,
        content: {
          gridValue: 2,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'Authentic Cliche Forage',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='3'
                            class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
                            viewBox='0 0 24 24'
                          >
                            <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
                            <path d='M22 4L12 14.01l-3-3'></path>
                          </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Kinfolk Chips Snackwave',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'Coloring Book Ethical',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Typewriter Polaroid Cray',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 5,
              title: 'Pack Truffaut Blue',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
            {
              id: 6,
              title: 'The Catcher In The Rye',
              htmlTag: 'h2',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='3'
              class='text-indigo-500 w-6 h-6 flex-shrink-0 mr-4'
              viewBox='0 0 24 24'
            >
              <path d='M22 11.08V12a10 10 0 11-5.93-9.14'></path>
              <path d='M22 4L12 14.01l-3-3'></path>
            </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'feature-7',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_feature-8') {
      newSection = {
        id: id,
        type: 'static_feature-8',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Raw Denim Heirloom Man Braid Selfies Wayfarers',
          htmlTag: 'h2',
          description:
            '<p>Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy.Gastropub indxgo juice poutine, ramps microdosing banh mi pug.</p>',
          button: 'Button',
          item: [
            {
              id: 1,
              title: 'SHOOTING STARS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                  iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
              ],
            },
            {
              id: 2,
              title: 'THE 400 BLOWS',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
              ],
            },
            {
              id: 3,
              title: 'THE CATALYZER',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
              ],
            },
            {
              id: 4,
              title: 'NEPTUNE',
              htmlTag: 'h2',
              isEdit: false,
              subItem: [
                {
                  subLink: 'First Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Second Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Third Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fourth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
                {
                  subLink: 'Fifth Link',
                  icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='3'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                    <path d='M20 6L9 17l-5-5'></path>
                  </svg>`,
                  isFill: false,
                   iconHeight:'',
                  iconWidth:''
                },
              ],
            },
          ],
        },
        blockName: 'feature-8',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#6B7280',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-1') {
      newSection = {
        id: id,
        type: 'static_step-1',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Step 1',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Step 2',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'Step 3',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Step 4',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              isFill: false,
            },
            {
              id: 5,
              title: 'FINISH',
              htmlTag: 'h5',
              description:
                'Pitchfork ugh tattooed scenester echo park gastropub whatever cold-pressed retro.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-5 h-5'
              viewBox='0 0 24 24'
            >
             <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
              <path d="M22 4L12 14.01l-3-3"></path>
           </svg>`,
              isFill: false,
            },
          ],
          image: 'https://dummyimage.com/1200x500',
          objectFit: 'fill',
        },
        blockName: 'step-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-2') {
      newSection = {
        id: id,
        type: 'static_step-2',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Master Cleanse Reliac Heirloom',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              iconTitle: 'Step 1',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              iconTitle: 'Step 2',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              iconTitle: 'Step 3',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'Neptune',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-6 h-6'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              iconTitle: 'Step 4',
              image: 'https://dummyimage.com/720x600',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'step-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_step-3') {
      newSection = {
        id: id,
        type: 'static_step-3',
        sectionId: flexId,
        content: {
          item: [
            {
              id: 1,
              title: 'Shooting Stars',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
              <path d='M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z'></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'The Catalyzer',
              htmlTag: 'h5',
              description:
                'Vice migas literally kitsch +1 pok pok. Truffaut hot chicken slow-carb health goth, vape typewriter.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 3,
              title: 'The 400 Blows',
              htmlTag: 'h5',
              description:
                'Coloring book nar whal glossier master cleanse umami. Salvia +1 master cleanse blog taiyaki.',
              isEdit: false,
              icon: `  <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <circle cx="12" cy="5" r="3"></circle>
              <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
           </svg>`,
              isFill: false,
            },
            {
              id: 4,
              title: 'Neptune',
              htmlTag: 'h5',
              description:
                'VHS cornhole pop-up, try-hard 8-bit iceland helvetica. Kinfolk bespoke try-hard cliche palo santo offal.',
              isEdit: false,
              icon: ` <svg
              fill='none'
              stroke='currentColor'
              stroke-linecap='round'
              stroke-linejoin='round'
              stroke-width='2'
              class='w-10 h-10'
              viewBox='0 0 24 24'
            >
             <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
           </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'step-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_team-1') {
      newSection = {
        id: id,
        type: 'static_team-1',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 3,
          description:
            'Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              description: 'UI Designer',
              isEdit: false,
              image: 'https://dummyimage.com/80x80',
              objectFit: 'fill',
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              description: 'CTO',
              isEdit: false,
              image: 'https://dummyimage.com/84x84',
              objectFit: 'fill',
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              description: 'Founder',
              isEdit: false,
              image: 'https://dummyimage.com/88x88',
              objectFit: 'fill',
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              description: 'DevOps',
              isEdit: false,
              image: 'https://dummyimage.com/90x90',
              objectFit: 'fill',
            },
            {
              id: 5,
              title: 'Martin Eden',
              htmlTag: 'h2',
              description: 'Software Engineer',
              isEdit: false,
              image: 'https://dummyimage.com/94x94',
              objectFit: 'fill',
            },
            {
              id: 6,
              title: 'Boris Kitua',
              htmlTag: 'h2',
              description: 'UX Researcher',
              isEdit: false,
              image: 'https://dummyimage.com/98x98',
              objectFit: 'fill',
            },
            {
              id: 7,
              title: 'Atticus Finch',
              htmlTag: 'h2',
              description: 'QA Engineer',
              isEdit: false,
              image: 'https://dummyimage.com/100x90',
              objectFit: 'fill',
            },
            {
              id: 8,
              title: 'Alper Kamu',
              htmlTag: 'h2',
              description: 'System',
              isEdit: false,
              image: 'https://dummyimage.com/104x94',
              objectFit: 'fill',
            },
            {
              id: 9,
              title: 'Rodrigo Monchi',
              htmlTag: 'h2',
              description: 'Product Manager',
              isEdit: false,
              image: 'https://dummyimage.com/108x98',
              objectFit: 'fill',
            },
          ],
        },
        blockName: 'team-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_team-2') {
      newSection = {
        id: id,
        type: 'static_team-2',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 2,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'UI Designer',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/200x200',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'CTO',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/201x201',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'Founder',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/204x204',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'DevOps',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/206x206',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'team-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_team-3') {
      newSection = {
        id: id,
        type: 'static_team-3',
        sectionId: flexId,
        content: {
          title: 'Our Team',
          htmlTag: 'h2',
          gridValue: 2,
          description:
            '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably have not heard of them.</p>',
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'UI Designer',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/200x200',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'CTO',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/201x201',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 3,
              title: 'Oskar Blinde',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'Founder',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/203x203',
              objectFit: 'fill',
              isFill: false,
            },
            {
              id: 4,
              title: 'John Doe',
              htmlTag: 'h2',
              role_htmlTag: 'h5',
              role: 'DevOps',
              description:
                '<p>DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware.</p>',
              isEdit: false,
              iconList: [
                {
                  id: 1,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                        </svg>`,
                },
                {
                  id: 2,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                        </svg>`,
                },
                {
                  id: 3,
                  icon: ` <svg
                            fill='none'
                            stroke='currentColor'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            stroke-width='2'
                            class='w-5 h-5'
                            viewBox='0 0 24 24'
                          >
                          <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                        </svg>`,
                },
              ],
              image: 'https://dummyimage.com/204x204',
              objectFit: 'fill',
              isFill: false,
            },
          ],
        },
        blockName: 'team-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_testimonial-1') {
      newSection = {
        id: id,
        type: 'static_testimonial-1',
        sectionId: flexId,
        content: {
          title: 'Testimonials',
          htmlTag: 'h2',
          gridValue: 2,
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'UI Designer',
              description:
                '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/106x106',
              profileFill: false,
              icon: ` <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            class='block w-5 h-5 text-gray-400 mb-4'
                            viewBox='0 0 975.036 975.036'
                          >
                            <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                          </svg>`,
              isFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Synth chartreuse iPhone lomo cray raw denim brunch everyday carry neutra before they sold out fixie 90s microdosing. Tacos pinterest fanny pack venmo, post-ironic heirloom try-hard pabst authentic iceland.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/107x107',
              profileFill: false,
              icon: ` <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='currentColor'
                            class='block w-5 h-5 text-gray-400 mb-4'
                            viewBox='0 0 975.036 975.036'
                          >
                            <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                          </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'testimonial-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_testimonial-2') {
      newSection = {
        id: id,
        type: 'static_testimonial-2',
        sectionId: flexId,
        content: {
          title: 'HOLDEN CAULFIELD',
          role: 'Senior Product Designer',
          htmlTag: 'h2',
          role_htmlTag: 'p',
          description:
            '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyaki taxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut adaptogen squid fanny pack vaporware. Man bun next level coloring book skateboard four loko knausgaard. Kitsch keffiyeh master cleanse direct trade indigo juice before they sold out gentrify plaid gastropub normcore XOXO 90s pickled cindigo jean shorts. Slow-carb next level shoindigoitch ethical authentic, yr scenester sriracha forage franzen organic drinking vinegar.</p>',
          icon: `  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='currentColor'
                    class='inline-block w-8 h-8 text-gray-400 mb-8'
                    viewBox='0 0 975.036 975.036'
                  >
                    <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                  </svg>`,
          isFill: false,
        },
        blockName: 'testimonial-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_testimonial-3') {
      newSection = {
        id: id,
        type: 'static_testimonial-3',
        sectionId: flexId,
        content: {
          gridValue: 3,
          item: [
            {
              id: 1,
              title: 'Holden Caulfield',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'UI Designer',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/302x302',
              profileFill: false,
            },
            {
              id: 2,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/303x303',
              profileFill: false,
            },
            {
              id: 3,
              title: 'Henry Letham',
              htmlTag: 'h6',
              role_htmlTag: 'p',
              role: 'CTO',
              description:
                '<p>Edison bulb retro cloud bread echo park, helvetica stumptown taiyakitaxidermy 90s cronut +1 kinfolk. Single-origin coffee ennui shaman taiyakivape DIY tote bag drinking vinegar cronut adaptogen squid fanny packvaporware.</p>',
              isEdit: false,
              profile: 'https://dummyimage.com/305x305',
              profileFill: false,
            },
          ],
        },
        blockName: 'testimonial-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_statistic-1') {
      newSection = {
        id: id,
        type: 'static_statistic-1',
        sectionId: flexId,
        content: {
          gridValue: 4,
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
          ],
        },
        blockName: 'statistic-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_statistic-2') {
      newSection = {
        id: id,
        type: 'static_statistic-2',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Moon hashtag pop-up try-hard offal truffaut',
          htmlTag: 'h2',
          description:
            '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
            },
          ],
          image: 'https://dummyimage.com/600x300',
          objectFit: 'fill',
        },
        blockName: 'statistic-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_statistic-3') {
      newSection = {
        id: id,
        type: 'static_statistic-3',
        sectionId: flexId,
        content: {
          gridValue: 4,
          title: 'Moon hashtag pop-up try-hard offal truffaut',
          htmlTag: 'h2',
          description:
            '<p>Pour-over craft beer pug drinking vinegar live-edge gastropub, keytar neutrasustainable fingerstache kickstarter.</p>',
          item: [
            {
              id: 1,
              start: 0,
              end: 100,
              duration: 2000,
              title: 'Users',
              htmlTag: 'p',
              prefix: '',
              suffix: 'K',
              icon: `<svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                      viewBox='0 0 24 24'
                    >
                      <path d='M8 17l4 4 4-4m-4-5v9'></path>
                      <path d='M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29'></path>
                    </svg>`,
              isFill: false,
            },
            {
              id: 2,
              start: 0,
              end: 200,
              duration: 2000,
              title: 'Subscribes',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                     <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 3,
              start: 0,
              end: 300,
              duration: 2000,
              title: 'Downloads',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                   <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                   <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 4,
              start: 0,
              end: 400,
              duration: 2000,
              title: 'Products',
              htmlTag: 'p',
              prefix: '',
              suffix: '',
              icon: `<svg
                    fill='none'
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    class='text-indigo-500 w-12 h-12 mb-3 inline-block'
                    viewBox='0 0 24 24'
                  >
                    <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                  </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'statistic-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_contact-1') {
      newSection = {
        id: id,
        type: 'static_contact-1',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
          shortCode: '',
        },
        blockName: 'contact-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_contact-2') {
      newSection = {
        id: id,
        type: 'static_contact-2',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          note: 'Chicharrones blog helvetica normcore iceland tousled brook viral artisan.',
          address_title: 'Address',
          address_title_htmlTag: 'h2',
          address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
          email_title: 'Email',
          email_title_htmlTag: 'h2',
          email_value: 'example@email.com',
          phone_title: 'Phone',
          phone_title_htmlTag: 'h2',
          phone_value: '123-456-7890',
        },
        blockName: 'contact-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_contact-3') {
      newSection = {
        id: id,
        type: 'static_contact-3',
        sectionId: flexId,
        content: {
          title: 'Feedback',
          htmlTag: 'h2',
          description: '<p>Post-ironic portland shabby chic echo park, banjo fashion axe</p>',
          address_value: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter',
          email_value: 'example@email.com',
          iconArray: [
            {
              id: 1,
              icon: `
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                      <path d='M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z'></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 2,
              icon: `
                   <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                  >
                    <path d='M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z'></path>
                  </svg>`,
              isFill: false,
            },
            {
              id: 3,
              icon: `
                     <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                      <rect width='20' height='20' x='2' y='2' rx='5' ry='5'></rect>
                      <path d='M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01'></path>
                    </svg>`,
              isFill: false,
            },
            {
              id: 4,
              icon: `
                    <svg
                      fill='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2'
                      class='w-5 h-5'
                      viewBox='0 0 24 24'
                    >
                     <path d='M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z'></path>
                   </svg>`,
              isFill: false,
            },
          ],
        },
        blockName: 'contact-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          note_font_weight: '',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '#28282B',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      };
    } else if (sectionType === 'static_cta-1') {
      newSection = {
        id: id,
        type: 'static_cta-1',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          button: 'Button',
        },
        blockName: 'cta-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_cta-2') {
      newSection = {
        id: id,
        type: 'static_cta-2',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
          form_title: 'Sign Up',
          form_title_htmlTag: 'h2',
          note: 'Literally you probably have not heard of them jean shorts.',
        },
        blockName: 'cta-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          note_font_weight: '500',
          note_font_size: '',
          note_font_height: '',
          note_font_color: '',
          note_font_align: '',
          note_font_style: '',
          note_font_decoration: '',
          note_font_capitalize: '',
          note_font_break: '',
          note_font_wrap: '',
          note_letter_spacing: '',
          note_word_spacing: '',
          note_text_stroke: '',
          note_stroke_color: '',
          note_text_shadow_color: '',
          note_text_shadow_blur: '',
          note_text_shadow_horizontal: '',
          note_text_shadow_vertical: '',
          note_text_rotate: '',
          form_title_font_weight: '500',
          form_title_font_size: '',
          form_title_font_height: '',
          form_title_font_color: '',
          form_title_font_align: '',
          form_title_font_style: '',
          form_title_font_decoration: '',
          form_title_font_capitalize: '',
          form_title_font_break: '',
          form_title_font_wrap: '',
          form_title_letter_spacing: '',
          form_title_word_spacing: '',
          form_title_text_stroke: '',
          form_title_stroke_color: '',
          form_title_text_shadow_color: '',
          form_title_text_shadow_blur: '',
          form_title_text_shadow_horizontal: '',
          form_title_text_shadow_vertical: '',
          form_title_text_rotate: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_cta-3') {
      newSection = {
        id: id,
        type: 'static_cta-3',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
        },
        blockName: 'cta-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_cta-4') {
      newSection = {
        id: id,
        type: 'static_cta-4',
        sectionId: flexId,
        content: {
          description:
            '<p>Slow-carb next level shoindxgoitch ethical authentic, scenester sriracha forage.</p>',
          title: 'Slow-carb next level shoindcgoitch ethical authentic, poko scenester',
          htmlTag: 'h2',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
        },
        blockName: 'cta-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_hero-1') {
      newSection = {
        id: id,
        type: 'static_hero-1',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          sub_title_font_weight: '',
          sub_title_font_size: '',
          sub_title_font_height: '',
          sub_title_font_color: '#28282B',
          sub_title_font_align: '',
          sub_title_font_style: '',
          sub_title_font_decoration: '',
          sub_title_font_capitalize: '',
          sub_title_font_break: '',
          sub_title_font_wrap: '',
          sub_title_letter_spacing: '',
          sub_title_word_spacing: '',
          sub_title_text_stroke: '',
          sub_title_stroke_color: '',
          sub_title_text_shadow_color: '',
          sub_title_text_shadow_blur: '',
          sub_title_text_shadow_horizontal: '',
          sub_title_text_shadow_vertical: '',
          sub_title_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_hero-2') {
      newSection = {
        id: id,
        type: 'static_hero-2',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_hero-3') {
      newSection = {
        id: id,
        type: 'static_hero-3',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          first_btn: 'Button',
          second_btn: 'Button',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
        },
        blockName: 'hero-3',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_hero-4') {
      newSection = {
        id: id,
        type: 'static_hero-4',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon:`
          <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 512 512'
           >
             <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
          </svg>`,
          second_icon:`
         <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 305 305'
           >
             <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
             <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
         </svg>`
        },
        blockName: 'hero-4',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_hero-5') {
      newSection = {
        id: id,
        type: 'static_hero-5',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon:`
          <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 512 512'
           >
             <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
          </svg>`,
          second_icon:`
         <svg
             xmlns='http://www.w3.org/2000/svg'
             fill='currentColor'
             class='w-6 h-6'
             viewBox='0 0 305 305'
           >
             <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
             <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
         </svg>`
        },
        blockName: 'hero-5',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_hero-6') {
      newSection = {
        id: id,
        type: 'static_hero-6',
        sectionId: flexId,
        content: {
          description:
            '<p>Copper mug try-hard pitchfork pour-over freegan heirloom neutra air plant cold-pressed tacos poke beard tote bag. Heirloom echo park mlkshk tote bag selvage hot chicken authentic tumeric truffaut hexagon try-hard chambray.</p>',
          title: 'Before they sold out readymade gluten',
          htmlTag: 'h2',
          note: 'Neutra shabby chic ramps, viral fixie.',
          first_btn_title: 'GET IT ON',
          first_btn_sub_title: 'Google Play',
          first_btn_link: '#',
          second_btn_title: 'GET IT ON',
          second_btn_sub_title: 'Google Play',
          second_btn_link: '#',
          image: 'https://dummyimage.com/720x600',
          objectFit: 'fill',
          first_icon:`
           <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              class='w-6 h-6'
              viewBox='0 0 512 512'
            >
              <path d='M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z'></path>
           </svg>`,
           second_icon:`
          <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='currentColor'
              class='w-6 h-6'
              viewBox='0 0 305 305'
            >
              <path d='M40.74 112.12c-25.79 44.74-9.4 112.65 19.12 153.82C74.09 286.52 88.5 305 108.24 305c.37 0 .74 0 1.13-.02 9.27-.37 15.97-3.23 22.45-5.99 7.27-3.1 14.8-6.3 26.6-6.3 11.22 0 18.39 3.1 25.31 6.1 6.83 2.95 13.87 6 24.26 5.81 22.23-.41 35.88-20.35 47.92-37.94a168.18 168.18 0 0021-43l.09-.28a2.5 2.5 0 00-1.33-3.06l-.18-.08c-3.92-1.6-38.26-16.84-38.62-58.36-.34-33.74 25.76-51.6 31-54.84l.24-.15a2.5 2.5 0 00.7-3.51c-18-26.37-45.62-30.34-56.73-30.82a50.04 50.04 0 00-4.95-.24c-13.06 0-25.56 4.93-35.61 8.9-6.94 2.73-12.93 5.09-17.06 5.09-4.64 0-10.67-2.4-17.65-5.16-9.33-3.7-19.9-7.9-31.1-7.9l-.79.01c-26.03.38-50.62 15.27-64.18 38.86z'></path>
              <path d='M212.1 0c-15.76.64-34.67 10.35-45.97 23.58-9.6 11.13-19 29.68-16.52 48.38a2.5 2.5 0 002.29 2.17c1.06.08 2.15.12 3.23.12 15.41 0 32.04-8.52 43.4-22.25 11.94-14.5 17.99-33.1 16.16-49.77A2.52 2.52 0 00212.1 0z'></path>
          </svg>`
        },
        blockName: 'hero-6',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#28282B',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_text_color: '#ffffff',
          second_button_color: '#f3f4f6',
          second_button_text_color: '#000000',
        },
      };
    } else if (sectionType === 'static_pricing-1') {
      newSection = {
        id: id,
        type: 'static_pricing-1',
        sectionId: flexId,
        content: {
          title: 'Pricing',
          htmlTag: 'h1',
          gridValue: 4,
          description: '<p>Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p>',
          item: [
            {
              id: 1,
              title: 'Start',
              htmlTag: 'h3',
              price: 'Free',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 2,
              title: 'PRO',
              htmlTag: 'h3',
              price: '$38',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2.5'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 3,
              title: 'BUSINESS',
              htmlTag: 'h3',
              price: '$56',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                      fill='none'
                      stroke='currentColor'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                      stroke-width='2.5'
                      class='w-3 h-3'
                      viewBox='0 0 24 24'
                    >
                      <path d='M20 6L9 17l-5-5'></path>
                    </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
            {
              id: 4,
              title: 'SPECIAL',
              htmlTag: 'h3',
              price: '$72',
              button: 'Button',
              time_period: '/mon',
              description: '<p>Literally you probably have not heard of them jean shorts.</p>',
              isEdit: false,
              subItem: [
                {
                  subLink: ' Vexillologist pitchfork',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Tumeric plaid portland',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
                {
                  subLink: 'Mixtape chillwave tumeric',
                  icon: ` <svg
                  fill='none'
                  stroke='currentColor'
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2.5'
                  class='w-3 h-3'
                  viewBox='0 0 24 24'
                >
                  <path d='M20 6L9 17l-5-5'></path>
                </svg>`,
                  isFill: false,
                },
              ],
            },
          ],
        },
        blockName: 'pricing-1',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    } else if (sectionType === 'static_pricing-2') {
      newSection = {
        id: id,
        type: 'static_pricing-2',
        sectionId: flexId,
        content: {
          title: 'Pricing',
          htmlTag: 'h1',
          description:
            '<p>Banh mi cornhole echo park skateboard authentic crucifix neutra tilde lyft biodiesel artisan direct trade mumblecore 3 wolf moon twee</p>',
          link: 'Learn more',
          link_url: '#',
          button: 'Button',
          button_url: '#',
          item: [
            {
              id: 1,
              plan: 'Start',
              htmlTag: 'h3',
              price: 'Free',
              speed: '5 Mb/s',
              storage: '15GB',
              isEdit: false,
            },
            {
              id: 2,
              plan: 'PRO',
              htmlTag: 'h3',
              price: '$38',
              speed: '25 Mb/s',
              storage: '25GB',
              isEdit: false,
            },
            {
              id: 3,
              plan: 'BUSINESS',
              htmlTag: 'h3',
              price: '$56',
              speed: '36 Mb/s',
              storage: '40GB',
              isEdit: false,
            },
            {
              id: 4,
              plan: 'SPECIAL',
              htmlTag: 'h3',
              price: '$72',
              speed: '48 Mb/s',
              storage: '120GB',
              isEdit: false,
            },
          ],
        },
        blockName: 'pricing-2',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '96',
          padding_right: '20',
          padding_bottom: '96',
          padding_left: '20',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          font: '',
          title_font_weight: '500',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '#111827',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '1.6',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_text_rotate: '',
          description_font_weight: '',
          description_font_size: '',
          description_font_height: '',
          description_font_color: '#28282B',
          description_font_align: '',
          description_font_style: '',
          description_font_decoration: '',
          description_font_capitalize: '',
          description_font_break: '',
          description_font_wrap: '',
          description_letter_spacing: '',
          description_word_spacing: '',
          description_text_stroke: '',
          description_stroke_color: '',
          description_text_shadow_color: '',
          description_text_shadow_blur: '',
          description_text_shadow_horizontal: '',
          description_text_shadow_vertical: '',
          description_text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          button_color: '#6366f1',
          button_hover_color: '3949AB',
          button_text_color: '#ffffff',
        },
      };
    }

    if (sectionType === 'static_content-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-1',
      });
    } else if (sectionType === 'static_content-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-2',
      });
    } else if (sectionType === 'static_content-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-3',
      });
    } else if (sectionType === 'static_content-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        console.log(updatedSections, 'updatedSections=-=-=->>');
        console.log(gridValue, 'updatedSections=-=-=->>');

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-4',
      });
    } else if (sectionType === 'static_content-5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-5',
      });
    } else if (sectionType === 'static_content-6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-6',
      });
    } else if (sectionType === 'static_content-7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-7',
      });
    } else if (sectionType === 'static_content-8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'content-8',
      });
    } else if (sectionType === 'static_feature-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-1',
      });
    } else if (sectionType === 'static_feature-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-2',
      });
    } else if (sectionType === 'static_feature-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-3',
      });
    } else if (sectionType === 'static_feature-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-4',
      });
    } else if (sectionType === 'static_feature-5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-5',
      });
    } else if (sectionType === 'static_feature-6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-6',
      });
    } else if (sectionType === 'static_feature-7') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-7',
      });
    } else if (sectionType === 'static_feature-8') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'feature-8',
      });
    } else if (sectionType === 'static_step-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-1',
      });
    } else if (sectionType === 'static_step-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-2',
      });
    } else if (sectionType === 'static_step-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'step-3',
      });
    } else if (sectionType === 'static_team-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team-1',
      });
    } else if (sectionType === 'static_team-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team-2',
      });
    } else if (sectionType === 'static_team-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'team-3',
      });
    } else if (sectionType === 'static_testimonial-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial-1',
      });
    } else if (sectionType === 'static_testimonial-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial-2',
      });
    } else if (sectionType === 'static_testimonial-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'testimonial-3',
      });
    } else if (sectionType === 'static_statistic-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic-1',
      });
    } else if (sectionType === 'static_statistic-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic-2',
      });
    } else if (sectionType === 'static_statistic-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'statistic-3',
      });
    } else if (sectionType === 'static_contact-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact-1',
      });
    } else if (sectionType === 'static_contact-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact-2',
      });
    } else if (sectionType === 'static_contact-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'contact-3',
      });
    } else if (sectionType === 'static_cta-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta-1',
      });
    } else if (sectionType === 'static_cta-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta-2',
      });
    } else if (sectionType === 'static_cta-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta-3',
      });
    } else if (sectionType === 'static_cta-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'cta-4',
      });
    } else if (sectionType === 'static_hero-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-1',
      });
    } else if (sectionType === 'static_hero-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-2',
      });
    } else if (sectionType === 'static_hero-3') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-3',
      });
    } else if (sectionType === 'static_hero-4') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-4',
      });
    } else if (sectionType === 'static_hero-5') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-5',
      });
    } else if (sectionType === 'static_hero-6') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'hero-6',
      });
    } else if (sectionType === 'static_pricing-1') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'pricing-1',
      });
    } else if (sectionType === 'static_pricing-2') {
      setSectionAdd((prevSections) => {
        const updatedSections = { ...prevSections };

        if (!updatedSections[gridValue]) {
          updatedSections[gridValue] = [];
        }

        if (!updatedSections[gridValue][index]) {
          updatedSections[gridValue][index] = [];
        }
        const sectionArray = updatedSections[gridValue][index];
        const newSectionWithProps = {
          ...newSection,
          orderId: sectionArray?.length + 1,
        };

        if (sectionType === 'grid') {
          const gridSectionExists = updatedSections[gridValue][index]?.some(
            (section) => section?.blockName === 'grid',
          );

          if (!gridSectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else if (
          selectedIndex === 'grid_two_rows' ||
          selectedIndex === 'grid_two_cols' ||
          selectedIndex === 'grid_three_cols' ||
          selectedIndex === 'grid_three_rows' ||
          selectedIndex === 'grid_two_for_two' ||
          selectedIndex === 'grid_three_for_three'
        ) {
          const sectionExists = updatedSections[gridValue][index]?.some(
            (section) =>
              section?.type === 'grid_two_rows' ||
              section?.type === 'grid_two_cols' ||
              section?.type === 'grid_three_cols' ||
              section?.type === 'grid_three_rows' ||
              section?.type === 'grid_two_for_two' ||
              section?.type === 'grid_three_for_three',
          );

          if (!sectionExists) {
            updatedSections[gridValue][index]?.push(newSectionWithProps);
          }
        } else {
          updatedSections[gridValue][index]?.push(newSectionWithProps);
        }

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((item) => {
            if (item.section_id === newSectionWithProps.sectionId) {
              return {
                ...item,
                section: {
                  ...item.section,
                  [gridValue]: item.section?.[gridValue]
                    ? [...item.section[gridValue], newSectionWithProps]
                    : [newSectionWithProps],
                },
              };
            }
            return item;
          });

          return updatedLayout;
        });

        return updatedSections;
      });
      setEditSection({
        section_id: flexId,
        section_type: 'pricing-2',
      });
    }
  };

  const openAddSection = (e, value, item) => {
    e.stopPropagation();
    setGridValue(value);
    setOpenTab(item);
  };

  const handleSectionClick = (e, sectionIndex, itemIndex) => {
    e.stopPropagation();
    setActiveIndex(sectionIndex);
    setSelectedIndex(itemIndex);
  };

  const [dragItems, setDragItems] = useState(styleSection);

  const createNewSection = (item, selectedIndex) => {
    function generateCustomObjectId() {
      const timestamp = Math.floor(Date.now() / 1000).toString(16);

      const randomValue = Math.random().toString(16).substr(2, 10);

      let counter = Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, '0');

      return timestamp + randomValue + counter;
    }

    const id = generateCustomObjectId(24);

    const commonProps = {
      id: id,
      type: selectedIndex,
    };

    const sectionMap = {
      heading: {
        ...commonProps,
        content: { title: 'Your Content Heading here...', htmlTag: 'h2' },
        blockName: 'heading',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      grid: { ...commonProps, content: '', blockName: 'grid' },
      image: {
        ...commonProps,
        content: { url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          }, link: '' },
        blockName: 'image',
        sectionId: elementorId,
        style: {
          image_align: '',
          image_width: '',
          image_maxWidth: '',
          image_height: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_color: '',
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          opacity: '',
          image_rotate: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      textEditor: {
        ...commonProps,
        content: {
          textEditorValue:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
        },
        blockName: 'textEditor',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      video: {
        ...commonProps,
        content: {
          item: {
            source: 'youtube',
            link: 'https://www.youtube.com/watch?v=668nUCeBHyY',
            startTime: '',
            endTime: '',
            autoPlay: true,
            mute: false,
            loop: false,
            playerControl: true,
            modestBranding: false,
            privacyMode: true,
            lazyLoad: false,
            introTitle: true,
            introPortrait: true,
            introByLine: true,
          },
        },
        blockName: 'video',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          aspect_ratio: '',
          width: '',
          video_align: '',
          video_rotate: '',
          background_position: '',
          aspectRatio: '',
        },
      },
      button: {
        ...commonProps,
        content: { text: 'Click Here', link: '#', icon: '' },
        blockName: 'button',
        sectionId: elementorId,
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          text_shadow_color: '',
          font_shadow_blur: '',
          font_shadow_horizontal: '',
          font_shadow_vertical: '',
          font_shadow_spread: '',
          button_padding_top: '',
          button_padding_right: '',
          button_padding_bottom: '',
          button_padding_left: '',
          button_padding_all: '',
          button_border_style: '',
          button_border_top: '',
          button_border_right: '',
          button_border_bottom: '',
          button_border_left: '',
          button_border_all: '',
          button_border_radius_top: '',
          button_border_radius_right: '',
          button_border_radius_bottom: '',
          button_border_radius_left: '',
          button_border_radius_all: '',
          button_border_color: '',
          button_background: '#61CE70',
          button_background_gradient: 'button_classic',
          button_gradient_color_start: '',
          button_gradient_start_position: '',
          button_gradient_color_end: '',
          button_gradient_end_position: '',
          button_gradient_angle: '',
          button_box_shadow_color: '',
          button_box_shadow_blur: '',
          button_box_shadow_horizontal: '',
          button_box_shadow_vertical: '',
          background_position: '',
        },
      },
      divider: {
        ...commonProps,
        content: {
          width: '100',
          style: 'solid',
          align: 'left',
          element: 'none',
          text: 'Divider',
          icon: '<svg aria-hidden="true" class="e-font-icon-svg e-fas-star" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"></path></svg>',
        },
        blockName: 'divider',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          divider_weight: '',
          gap: '',
          divider_color: '',
          divider_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          divider_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
        },
      },
      spacer: {
        ...commonProps,
        content: { width: '40' },
        blockName: 'spacer',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
        },
      },
      googleMap: {
        ...commonProps,
        content: {
          location: 'TemplateTrip, 4030, Central Bazzar, Varachha Main Rd, Surat, Gujarat 395006',
          latitude: 21.2100675,
          longitude: 72.849892,
        },
        blockName: 'googleMap',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          zoom: '',
          map_color: '',
          map_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          map_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      },
      icon: {
        ...commonProps,
        content: {
          icon: " <svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svg>",
          link: '',
        },
        blockName: 'icon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          icon_color: '',
          icon_align: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          text_shadow_color: '',
          icon_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          height: '',
          width: '',
        },
      },
      imageBox: {
        ...commonProps,
        content: {
          url: bgImageDefault,
          imageSize: {
            width: '300',
            height: '300',
          },
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'imageBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_spacing: '',
          content_spacing: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      },
      generalIconBox: {
        ...commonProps,
        content: {
          icon: `<svg xmlns='http://www.w3.org/2000/svg'  width='50' height='50' fill='currentColor' class='bi bi-star-fill' viewBox='0 0 16 16'><path d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z' /></svgxmlns=>`,
          link: '',
          title: 'This is the heading',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          htmlTag: 'h2',
        },
        blockName: 'generalIconBox',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_box_shadow_blur: '',
          icon_border_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
        },
      },
      generalIconList: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              text: 'List Item #1',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-check' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'><path d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'></path></svg>`,
            },
            {
              id: 2,
              text: 'List Item #2',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-times' viewBox='0 0 352 512'  xmlns='http://www.w3.org/2000/svg'><path d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path></svg>`,
            },
            {
              id: 3,
              text: 'List Item #3',
              link: '#',
              icon: `<svg aria-hidden='true' class='e-font-icon-svg e-fas-dot-circle' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg'> <path d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm80 248c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80z'></path> </svg>`,
            },
          ],
        },
        blockName: 'generalIconList',
      },
      counter: {
        ...commonProps,
        content: {
          start: 0,
          end: 100,
          duration: 2000,
          title: 'Cool Number',
          htmlTag: 'h2',
          prefix: '',
          suffix: '',
        },
        blockName: 'counter',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          icon_width: '',
          icon_color: '',
          icon_spacing: '',
          content_spacing: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          space_between: '',
          item_align: '',
          divider_visible: '',
          divider_style: '',
          divider_weight: '',
          divider_width: '',
          divider_color: '',
          horizontal_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
        },
      },
      testimonial: {
        ...commonProps,
        content: {
          url: bgImageDefault,
          imageSize: '',
          name: 'John Doe',
          title: 'Designer',
          description:
            '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p>',
          position: 'aside',
          alignment: 'center',
        },
        blockName: 'testimonial',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          name_font_weight: '',
          name_font_size: '',
          name_font_height: '',
          name_font_color: '',
          name_font_align: '',
          name_font_decoration: '',
          name_font_capitalize: '',
          name_font_break: '',
          name_font_wrap: '',
          name_letter_spacing: '',
          name_word_spacing: '',
          name_text_stroke: '',
          name_stroke_color: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_align: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          image_width: '',
          image_border_style: '',
          image_border_radius_top: '',
          image_border_radius_right: '',
          image_border_radius_bottom: '',
          image_border_radius_left: '',
          image_border_top: '',
          image_border_right: '',
          image_border_bottom: '',
          image_border_left: '',
          image_box_shadow_blur: '',
          image_border_color: '',
          image_position: '',
          content_alignment: '',
          vertical_alignment: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          name_shadow_color: '',
          name_shadow_blur: '',
          name_shadow_horizontal: '',
          name_shadow_vertical: '',
        },
      },
      tabs: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              tabName: 'tab-1',
              tabDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              tabName: 'tab-2',
              tabDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'tabs',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      },
      accordion: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              accordionName: 'accordion-1',
              accordionDescription:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.',
              isEdit: false,
            },
            {
              id: 2,
              accordionName: 'accordion-2',
              accordionDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
              isEdit: false,
            },
          ],
        },
        blockName: 'accordion',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_align: '',
          title_font_style: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          title_text_shadow_color: '',
          title_text_shadow_blur: '',
          title_text_shadow_horizontal: '',
          title_text_shadow_vertical: '',
          title_active_font_color: '',
        },
      },
      socialIcon: {
        ...commonProps,
        content: {
          item: [
            {
              id: 1,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-facebook' fill='#3b5998' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z'></path></svg>`,
            },
            {
              id: 2,
              link: '#',
              icon: `<svg class='e-font-icon-svg e-fab-twitter' fill='#1da1f2' viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' >  <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z'></path> </svg>`,
            },
            {
              id: 3,
              link: '#',
              icon: `<svg  class='e-font-icon-svg e-fab-youtube' fill='#cd201f' viewBox='0 0 576 512'  xmlns='http://www.w3.org/2000/svg'> <path d='M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z'></path></svg>`,
            },
          ],
        },
        blockName: 'socialIcon',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          icon_width: '',
          icon_border_style: '',
          icon_border_radius_top: '',
          icon_border_radius_right: '',
          icon_border_radius_bottom: '',
          icon_border_radius_left: '',
          icon_border_top: '',
          icon_border_right: '',
          icon_border_bottom: '',
          icon_border_left: '',
          icon_border_color: '',
          icon_spacing: '',
          icon_border_radius_all: '',
          icon_padding: '',
          icon_align: '',
          icon_color: '',
        },
      },
      alert: {
        ...commonProps,
        content: {
          title: 'This is an Alert',
          content: 'I am a description. Click the edit button to change this text.',
          alertType: 'info',
          showIcon: true,
          icon: `<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' className='bi bi-x-lg' viewBox='0 0 16 16'> <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z' /></svg>`,
        },
        blockName: 'alert',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          text_rotate: '',
          title_font_weight: '',
          title_font_size: '',
          title_font_height: '',
          title_font_color: '',
          title_font_decoration: '',
          title_font_capitalize: '',
          title_font_break: '',
          title_font_wrap: '',
          title_letter_spacing: '',
          title_word_spacing: '',
          title_text_stroke: '',
          title_stroke_color: '',
          desc_font_weight: '',
          desc_font_size: '',
          desc_font_height: '',
          desc_font_color: '',
          desc_font_decoration: '',
          desc_font_capitalize: '',
          desc_font_break: '',
          desc_font_wrap: '',
          desc_letter_spacing: '',
          desc_word_spacing: '',
          desc_text_stroke: '',
          desc_stroke_color: '',
          icon_width: '',
          icon_border_color: '',
          icon_position: '',
          content_alignment: '',
          vertical_alignment: '',
          icon_border_radius_all: '',
          title_shadow_color: '',
          title_shadow_blur: '',
          title_shadow_horizontal: '',
          title_shadow_vertical: '',
          desc_shadow_color: '',
          desc_shadow_blur: '',
          desc_shadow_horizontal: '',
          desc_shadow_vertical: '',
          alert_background: '',
        },
      },
      html: {
        ...commonProps,
        content: {
          item: '<h1>Write Your Code</h1>',
        },
        blockName: 'html',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          stroke_color: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          html_align: '',
          html_rotate: '',
          background_position: '',
        },
      },
      rating: {
        ...commonProps,
        blockName: 'rating',
        content: {
          totalRate: 5,
          initialRate: 2,
          icon: `<svg fill='#ccd6df' width='24' height='24' viewBox='0 0 24 24'  xmlns='http://www.w3.org/2000/svg' stroke='none' class='cursor-pointer'><path d='M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z'></path></svg>`,
        },
        blockName: 'rating',
        style: {
          margin: '',
          padding: '',
          margin_top: '',
          margin_right: '',
          margin_bottom: '',
          margin_left: '',
          margin_all: '',
          padding_top: '',
          padding_right: '',
          padding_bottom: '',
          padding_left: '',
          padding_all: '',
          font: '',
          font_weight: '',
          font_size: '',
          font_height: '',
          font_color: '',
          font_align: '',
          font_style: '',
          font_decoration: '',
          font_capitalize: '',
          font_break: '',
          font_wrap: '',
          border_style: '',
          border_top: '',
          border_right: '',
          border_bottom: '',
          border_left: '',
          border_all: '',
          border_radius_top: '',
          border_radius_right: '',
          border_radius_bottom: '',
          border_radius_left: '',
          border_radius_all: '',
          border_color: '',
          letter_spacing: '',
          word_spacing: '',
          text_stroke: '',
          stroke_color: '',
          text_shadow_color: '',
          text_shadow_blur: '',
          text_shadow_horizontal: '',
          text_shadow_vertical: '',
          text_rotate: '',
          background: '',
          background_image: '',
          background_image_size: '',
          background_repeat: '',
          background_attachment: '',
          background_fit: '',
          box_shadow_color: '',
          box_shadow_blur: '',
          box_shadow_horizontal: '',
          box_shadow_vertical: '',
          box_shadow_spread: '',
          box_shadow_position: '',
          background_gradient: '',
          gradient_color_start: '',
          gradient_start_position: '',
          gradient_color_end: '',
          gradient_end_position: '',
          gradient_angle: '',
          background_position: '',
          icon_color: '',
          unmarked_color: '',
          icon_size: '',
          icon_spacing: '',
        },
      },
      textPath: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'textPath',
      },
      mediaImage: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'mediaImage',
      },
      mediaVideo: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'mediaVideo',
      },
      youtube: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'youtube',
      },
      formBlock: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'formBlock',
      },
      label: { ...commonProps, content: 'Lorem Ipsum is simply dummy text...', blockName: 'label' },
      input: { ...commonProps, content: 'Lorem Ipsum is simply dummy text...', blockName: 'input' },
      fileUpload: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'fileUpload',
      },
      textArea: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'textArea',
      },
      checkBox: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'checkBox',
      },
      radioButton: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'radioButton',
      },
      select: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'select',
      },
      formButton: {
        ...commonProps,
        content: 'Lorem Ipsum is simply dummy text...',
        blockName: 'formButton',
      },
    };

    return sectionMap[item.id] || null;
  };
  const dropContainerRef = useRef(null);

  const [destinationId, setDestinationId] = useState(null);
  const [gridDestinationId, setGridDestinationId] = useState(null);
  const [dropPosition, setDropPosition] = useState(null);

  const dragProps = {
    onDragEnd(sourceIndex, destinationIndex) {
      setDestinationId(null);
      setGridDestinationId(null);
      const getListToReorder = (sectionKey) => {
        const section = sectionAdd[sectionKey];
        return Array.isArray(section) ? section : Object.values(section || {});
      };
      if (
        !editSection ||
        editSection?.section_index === undefined ||
        editSection?.section_index < 0
      ) {
        console.error('Invalid editSection or section_index');
        return;
      }

      const sectionKey = sectionMap[editSection?.section_type];

      if (!sectionKey) {
        console.error('Unknown section_type:', editSection?.section_type);
        return;
      }

      const listToReorder = getListToReorder(sectionKey);

      if (!Array.isArray(listToReorder) || listToReorder?.length <= editSection?.section_index) {
        console.error('Invalid listToReorder or section_index out of bounds');
        return;
      }

      // Reordering logic
      const reorderedList = [...listToReorder[editSection?.section_index]];

      if (
        sourceIndex < 0 ||
        sourceIndex >= reorderedList?.length ||
        destinationIndex < 0 ||
        destinationIndex > reorderedList?.length
      ) {
        console.error('Invalid sourceIndex or destinationIndex');
        return;
      }

      const [removedItem] = reorderedList?.splice(sourceIndex, 1);
      reorderedList?.splice(destinationIndex, 0, removedItem);

      setSectionAdd((prevState) => {
        const newState = { ...prevState };
        newState[sectionKey][editSection?.section_index] = reorderedList;
        return newState;
      });
      setGetLayout((prevLayout) => {
        const updatedLayout = prevLayout?.map((item) => {
          if (item?.section_id === reorderedList[0]?.sectionId) {
            return {
              ...item,
              section: {
                ...item?.section,
                [sectionKey]: reorderedList,
              },
            };
          }
          return item;
        });
        return updatedLayout;
      });
    },
    nodeSelector: '.draggable-item',
    handleSelector: 'div',
    scrollSpeed: 4,
    enableScroll:true,
    lineClassName:'dragLine'
  };
  console.log('editSection', editSection);

  const handleDrop = (e) => {
    e.preventDefault();

    const itemData = e.dataTransfer.getData('basic-item');
    if (!itemData) {
      console.error('No data found for basic-item');
      return;
    }
    const tailwindItem = [
      'static_content-1',
      'static_content-2',
      'static_content-3',
      'static_content-4',
      'static_content-5',
      'static_content-6',
      'static_content-7',
      'static_content-8',
      'static_feature-1',
      'static_feature-2',
      'static_feature-3',
      'static_feature-4',
      'static_feature-5',
      'static_feature-6',
      'static_feature-7',
      'static_feature-8',
      'static_step-1',
      'static_step-2',
      'static_step-3',
      'static_team-1',
      'static_team-2',
      'static_team-3',
      'static_testimonial-1',
      'static_testimonial-2',
      'static_testimonial-3',
      'static_statistic-1',
      'static_statistic-2',
      'static_statistic-3',
      'static_contact-1',
      'static_contact-2',
      'static_contact-3',
      'static_cta-1',
      'static_cta-2',
      'static_cta-3',
      'static_cta-4',
      'static_hero-1',
      'static_hero-2',
      'static_hero-3',
      'static_hero-4',
      'static_hero-5',
      'static_hero-6',
      'static_pricing-1',
      'static_pricing-2',
    ];
    try {
      const item = JSON.parse(itemData);
      if (tailwindItem?.includes(item?.type)) {
        handleItemClick(e, item, '', item?.value);
      } else {
        const newSection = createNewSection(item, selectedIndex);
        const destinationIndex = destinationId ? destinationId : gridDestinationId;
        setSectionAdd((prevSections) => {
          const updatedSections = { ...prevSections };

          if (!updatedSections[gridValue]) {
            updatedSections[gridValue] = [];
          }

          if (!updatedSections[gridValue][editSection?.section_index]) {
            updatedSections[gridValue][editSection?.section_index] = [];
          }
          const sectionArray = updatedSections[gridValue][editSection?.section_index];
          const newSectionWithProps = {
            ...newSection,
            orderId: sectionArray?.length + 1,
            sectionId: elementorId,
            // style: 'style',
          };
          const itemExists = sectionArray?.some((section) => section?.id === newSection?.id);
          if (itemExists) {
            console.warn('Item already exists:', newSection);
            return prevSections;
          }
          if (item.blockName === 'grid') {
            const gridSectionExists = updatedSections[gridValue][editSection?.section_index]?.some(
              (section) => section?.blockName === 'grid',
            );

            if (!gridSectionExists) {
              updatedSections[gridValue][editSection?.section_index]?.push(newSectionWithProps);
            }
          } else if (
            selectedIndex === 'grid_two_rows' ||
            selectedIndex === 'grid_two_cols' ||
            selectedIndex === 'grid_three_cols' ||
            selectedIndex === 'grid_three_rows' ||
            selectedIndex === 'grid_two_for_two' ||
            selectedIndex === 'grid_three_for_three'
          ) {
            const sectionExists = updatedSections[gridValue][editSection?.section_index]?.some(
              (section) =>
                section?.type === 'grid_two_rows' ||
                section?.type === 'grid_two_cols' ||
                section?.type === 'grid_three_cols' ||
                section?.type === 'grid_three_rows' ||
                section?.type === 'grid_two_for_two' ||
                section?.type === 'grid_three_for_three',
            );

            if (!sectionExists) {
              if (dropPosition === 'below') {
                updatedSections[gridValue][editSection?.section_index]?.splice(
                  destinationIndex + 1,
                  0,
                  newSectionWithProps,
                );
              } else {
                updatedSections[gridValue][editSection?.section_index]?.splice(
                  destinationIndex,
                  0,
                  newSectionWithProps,
                );
              }
            }
          } else {
            if (dropPosition === 'below') {
              updatedSections[gridValue][editSection?.section_index]?.splice(
                destinationIndex + 1,
                0,
                newSectionWithProps,
              );
            } else {
              updatedSections[gridValue][editSection?.section_index]?.splice(
                destinationIndex,
                0,
                newSectionWithProps,
              );
            }
          }
          setDestinationId(null);
          setGridDestinationId(null);
          // updatedSections[gridValue][editSection?.section_index].sort((a, b) => a.orderId - b.orderId);
          return updatedSections;
        });

        setGetLayout((prevLayout) => {
          const updatedLayout = prevLayout.map((layoutItem) => {
            if (layoutItem.section_id === elementorId) {
              const updatedSection = { ...layoutItem.section };
              if (!updatedSection[gridValue]) {
                updatedSection[gridValue] = [];
              }
              const exists = updatedSection[gridValue].some(
                (existingItem) => existingItem.id === newSection.id,
              );
              if (!exists) {
                updatedSection[gridValue].splice(destinationIndex, 0, {
                  ...newSection,
                  orderId: updatedSection[gridValue].length + 1,
                  sectionId: layoutItem.section_id,
                });
              } else {
                console.warn('Item already exists:', newSection);
              }
              return {
                ...layoutItem,
                section: updatedSection,
              };
            }
            return layoutItem;
          });
          return updatedLayout;
        });
      }
    } catch (error) {
      console.error('Invalid JSON data:', error);
      return;
    }
  };

  return (
    <>
      <div className='overflow-hidden relative h-[92.5%]'>
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div
          className='relative live-editor w-full flex items-start h-full'
          ref={dropContainerRef}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {windowWidth > 768 && windowWidth < 1280 && editSection?.section_type ? (
            <div className='hidden  edit-style-section md:block xl:hidden md:w-[30%] xl:w-[16%] overflow-y-auto p-3 secondary-bg-color h-full'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-medium text-primary text-base capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <EditHeadingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHeadingDesign={setHeadingDesign}
                      headingDesign={headingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={headingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <EditImageStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageDesign={setImageDesign}
                      imageDesign={imageDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <EditTextEditorStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTextEditorDesign={setTextEditorDesign}
                      textEditorDesign={textEditorDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={textEditorDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <EditVideoStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setVideoDesign={setVideoDesign}
                      videoDesign={videoDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={videoDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <EditButtonStyleSection
                      setButtonDesign={setButtonDesign}
                      buttonDesign={buttonDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={buttonDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <EditDividerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setDividerDesign={setDividerDesign}
                      dividerDesign={dividerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={dividerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <EditSpacerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSpacerDesign={setSpacerDesign}
                      spacerDesign={spacerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={spacerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <EditTestiMonialStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTestiMonialDesign={setTestiMonialDesign}
                      testiMonialDesign={testiMonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={testiMonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <EditSocialIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSocialIconDesign={setSocialIconDesign}
                      socialIconDesign={socialIconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={socialIconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <EditAlertStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAlertDesign={setAlertDesign}
                      alertDesign={alertDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={alertDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <EditHtmlStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHtmlDesign={setHtmlDesign}
                      htmlDesign={htmlDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={htmlDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <EditImageBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageBoxDesign={setImageBoxDesign}
                      imageBoxDesign={imageBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <EditIconBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconBoxDesign={setIconBoxDesign}
                      iconBoxDesign={iconBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <EditIconListStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconListDesign={setIconListDesign}
                      iconListDesign={iconListDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconListDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <EditMapStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setMapDesign={setMapDesign}
                      mapDesign={mapDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={mapDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <EditIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconDesign={setIconDesign}
                      iconDesign={iconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <EditCounterStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setCounterDesign={setCounterDesign}
                      counterDesign={counterDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={counterDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <EditTabStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTabDesign={setTabDesign}
                      tabDesign={tabDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={tabDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <EditAccordianStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAccordianDesign={setAccordianDesign}
                      accordianDesign={accordianDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={accordianDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <EditRatingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setRatingDesign={setRatingDesign}
                      ratingDesign={ratingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={ratingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <EditFirstContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstContentDesign={setFirstContentDesign}
                      firstContentDesign={firstContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' ? (
                //  && editSection?.section_type
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'>
                      {' '}
                      cvbgdsfx fghdcf vfdx fdsx fgdfrg sxd gfs gsfgs sgsdg sdggs fs sf
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}{' '}
              :
            </div>
          ) : (
            <div className='hidden md:block md:w-[30%] xl:w-[16%] overflow-y-auto py-3 secondary-bg-color h-full'>
              {pending && !getLayout ? (
                <div className='w-full px-3 h-[800px]'>
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <hr className='my-4 main-border-color' />
                  <div className='grid grid-cols-1 space-y-2'>
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                    <Skeleton
                      className='min-h-[50px] max-h-[50px]'
                      variant='rectangular'
                      animation='wave'
                    />
                  </div>
                  <hr className='my-4 main-border-color' />
                  <Skeleton
                    className='min-h-[30px] max-h-[30px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              ) : (
                <div className='theme-setting'>
                  <p className='font-medium text-primary text-base px-3'>{t('lang_Home_Page')}</p>
                  <hr className='my-3 border-t main-border-color'></hr>
                  <span className='font-medium text-primary text-xs px-3'>{t('lang_Header')}</span>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Announcement_Bar')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <AnnouncementSection handleChangeAnnouncement={handleChangeAnnouncement} />
                  </details>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Header')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <HeaderSection isChangeHeader={isChangeHeader} />
                  </details>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <div className='w-full'>
                    <>
                      <HomePageSectionList
                        getLayout={getLayout}
                        editSection={editSection}
                        selectSection={selectSection}
                        UpdateSectionStatus={UpdateSectionStatus}
                        handleSort={handleSort}
                        handleGetLayout={handleGetLayout}
                        hoverId={hoverId}
                        setHoverId={setHoverId}
                        setFilterId={setFilterId}
                        sectionAdd={sectionAdd}
                        handleSubItemSort={handleSubItemSort}
                        setSubSecId={setSubSecId}
                        loadingSections={loadingSections}
                        openAddSection={openAddSection}
                        handleSectionClick={handleSectionClick}
                        setElementorId={setElementorId}
                        getPrevSecId={getPrevSecId}
                      />
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setIsAddSection(true);
                        }}
                        className='inline-flex items-center mx-2.5 text-link hover:underline font-medium transition-all  my-2 duration-150 text-sm'
                      >
                        <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Section')}
                      </button>
                    </>
                  </div>
                  <hr className='mb-3 border-t main-border-color'></hr>
                  <details className='cursor-pointer group main-border-color'>
                    <summary
                      htmlFor='Meta Tag Title'
                      className='text-base w-full table-text flex flex-col items-center'
                    >
                      <div className='flex w-full items-center justify-between'>
                        <div>
                          <span className='text-sm'>{t('lang_Footer')}</span>
                        </div>
                        <span className='transition group-open:rotate-180'>
                          <ChevronDown className=' w-4 h-5' />
                        </span>
                      </div>
                    </summary>
                    <FooterSection isChangeFooter={isChangeFooter} />
                  </details>
                </div>
              )}
            </div>
          )}
          <div className={`p-0 md:ps-2 lg:px-4 bg-transparent h-full w-full md:w-[70%] xl:w-[68%]`}>
            {pending && !getLayout ? (
              <div className='w-full'>
                <div>
                  <Skeleton
                    className='min-h-[90px] max-h-[90px]'
                    variant='rectangular'
                    animation='wave'
                  />
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[500px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
                <div className='grid mt-6 mx-auto grid-cols-1'>
                  <Skeleton
                    className='min-h-[550px] my-2 max-h-[400px]'
                    variant='rectangular'
                    animation='wave'
                  />
                </div>
              </div>
            ) : (
              <div
                style={{ backgroundColor: commonSetting?.primary_body_bg_color }}
                className='h-full overflow-auto'
              >
                <VisualAnnounccementBar colors={commonSetting} announcement={announcement} />
                <StoreNavbar
                  clientInfo={clientData}
                  colors={commonSetting}
                  groupHeaderData={groupHeaderData}
                />
                {/* <HomePageSection
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  selectSection={selectSection}
                  handleSort={handleSort}
                  editSection={editSection}
                  manufactureDesign={manufactureDesign}
                  categoryDesign={categoryDesign}
                  productDesign={productDesign}
                  reassuranceDesign={reassuranceDesign}
                  bannerDesign={bannerDesign}
                  sliderDesign={sliderDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  blogDesign={blogDesign}
                  setEditSection={setEditSection}
                  setEditProductOtherSection={setEditProductOtherSection}
                  hoverId={hoverId}
                  setHoverId={setHoverId}
                  setFilterId={setFilterId}
                /> */}
                <SelectedStructureSection
                  flexBoxValue={flexBoxValue}
                  // handleSubDivClick={handleSubDivClick}
                  setSectionAdd={setSectionAdd}
                  removeSection={removeSection}
                  selectedIndex={selectedIndex}
                  handleFlexBoxClick={handleFlexBoxClick}
                  openAddSection={openAddSection}
                  editSection={editSection}
                  handleSectionClick={handleSectionClick}
                  activeIndex={activeIndex}
                  sectionAdd={sectionAdd}
                  setActiveIndex={setActiveIndex}
                  handleHeadingClick={handleHeadingClick}
                  activeHeadingIndex={activeHeadingIndex}
                  gridValue={gridValue}
                  activeHeading={activeHeading}
                  handleHeadingClickLocal={handleHeadingClickLocal}
                  removedIndices={removedIndices}
                  activeImageIndex={activeImageIndex}
                  handleImageClick={handleImageClick}
                  selectSection={selectSection}
                  setEditSection={setEditSection}
                  dragProps={dragProps}
                  dropContainerRef={dropContainerRef}
                  destinationId={destinationId}
                  setDestinationId={setDestinationId}
                  gridDestinationId={gridDestinationId}
                  setGridDestinationId={setGridDestinationId}
                  handleRemoveSingleItem={handleRemoveSingleItem}
                  handleDuplicateSingleItem={handleDuplicateSingleItem}
                  handleCopySingleItem={handleCopySingleItem}
                  handleCutSingleItem={handleCutSingleItem}
                  getPrevSecId={getPrevSecId}
                  setElementorId={setElementorId}
                  commonSetting={commonSetting}
                  getLayout={getLayout}
                  visualData={visualData}
                  handleSort={handleSort}
                  manufactureDesign={manufactureDesign}
                  categoryDesign={categoryDesign}
                  productDesign={productDesign}
                  reassuranceDesign={reassuranceDesign}
                  bannerDesign={bannerDesign}
                  sliderDesign={sliderDesign}
                  cmsOfferDesign={cmsOfferDesign}
                  blogDesign={blogDesign}
                  setEditProductOtherSection={setEditProductOtherSection}
                  hoverId={hoverId}
                  setHoverId={setHoverId}
                  setFilterId={setFilterId}
                  setFlexBoxValue={setFlexBoxValue}
                  dropPosition={dropPosition}
                  setDropPosition={setDropPosition}
                  selectContextItem={selectContextItem}
                  getContextMenuItem={getContextMenuItem}
                />
                <AddSectionElementor
                  handleFlexBoxClick={handleFlexBoxClick}
                  gridValue={gridValue}
                  setIsFlexBoxVisible={setIsFlexBoxVisible}
                  setIsGridVisible={setIsGridVisible}
                  isGridVisible={isGridVisible}
                  isFlexBoxVisible={isFlexBoxVisible}
                  isPlusButtonVisible={isPlusButtonVisible}
                  setIsPlusButtonVisible={setIsPlusButtonVisible}
                  isLayoutVisible={isLayoutVisible}
                  setIsLayoutVisible={setIsLayoutVisible}
                />
                <Footer colors={commonSetting} groupFooterData={groupFooterData} />
              </div>
            )}
          </div>
          {windowWidth > 1280 && (
            <div className='hidden  edit-style-section xl:block xl:w-[16%] h-full p-5 secondary-bg-color overflow-y-auto'>
              <ul
                className='flex border-b items-center list-none overflow-x-auto flex-row'
                role='tablist'
              >
                <li className='text-center lg:mb-0'>
                  <a
                    className={
                      'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150' +
                      (openTab === 'setting'
                        ? 'text-link border-b-2 border-b-[#28282B]'
                        : ' border-b-2 table-text border-transparent')
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab('setting');
                    }}
                    data-toggle='tab'
                    href='#link1'
                    role='tablist'
                  >
                    {t('lang_setting')}
                  </a>
                </li>
                {ultimatorKitExtension?.extension_name === 'Ultimator Kit' ? (
                  <>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'style'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('style');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_style')}
                      </a>
                    </li>
                    <li className='text-center me-4 lg:mb-0'>
                      <a
                        className={
                          'text-xs font-bold break-keep capitalize px-2 pb-2 inline-block leading-normal transition-all duration-150 ' +
                          (openTab === 'add_section'
                            ? 'text-link border-b-2 border-b-[#28282B]'
                            : ' border-b-2 table-text border-transparent')
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab('add_section');
                        }}
                        data-toggle='tab'
                        href='#link1'
                        role='tablist'
                      >
                        {t('lang_Add')}
                      </a>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </ul>
              {openTab === 'setting' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_Section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'product' ? (
                    <EditProductSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategorySection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'manufacture' ? (
                    <EditManufactureSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerSection
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      section_type={editSection?.section_type}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <HeadingSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sectionContent={headingSetting?.title}
                      headingLevel={headingSetting?.htmlTag}
                      handleContentChange={handleContentChange}
                      handleHtmlTagChange={handleHtmlTagChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <ImageSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleImageUpload={handleImageUpload}
                      previewImage={bgImage}
                      handleImageSizeChange={handleImageSizeChange}
                      handleImageLinkChange={handleImageLinkChange}
                      currentImageSize={bgImage?.imageSize}
                      currentImageLink={bgImage?.link}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <TextEditorSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleTextEditorChange={handleTextEditorChange}
                      textEditorValue={textEditorValue?.textEditorValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <VideoSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      videoValue={videoValue}
                      handleVideoChange={handleVideoChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <ButtonSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleButtonChange={handleButtonChange}
                      buttonValue={buttonValue}
                      handleButtonLinkChange={handleButtonLinkChange}
                      handleIconSelectValue={handleIconSelectValue}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <DividerSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleDividerStyleChange={handleDividerStyleChange}
                      handleDividerWidthChange={handleDividerWidthChange}
                      dividerValue={dividerValue}
                      handleAlignmentChange={handleAlignmentChange}
                      handleAddElementChange={handleAddElementChange}
                      handleAddElementValue={handleAddElementValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <SpacerSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleSpacerWidthChange={handleSpacerWidthChange}
                      spacerValue={spacerValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <GoogleMapSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleLocationChange={handleLocationChange}
                      locationValue={locationValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <IconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleIconElementorValue={handleIconElementorValue}
                      handleIconLinkChange={handleIconLinkChange}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      iconValue={iconValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <ImageBoxSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      handleIconBoxImageUpload={handleIconBoxImageUpload}
                      previewImage={imageBox}
                      handleImageBoxImageSizeChange={handleImageBoxImageSizeChange}
                      handleImageBoxTitleChange={handleImageBoxTitleChange}
                      handleImageBoxHtmlTagChange={handleImageBoxHtmlTagChange}
                      currentImageSize={imageBox?.imageSize}
                      sectionContent={imageBox?.title}
                      currentImageLink={imageBox?.link}
                      headingLevel={imageBox?.htmlTag}
                      sectionContentDescription={imageBox?.description}
                      handleImageBoxDescriptionChange={handleImageBoxDescriptionChange}
                      handleImageBoxImageLinkChange={handleImageBoxImageLinkChange}
                      setImageBox={setImageBox}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <ImageIconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      previewImage={imageIcon}
                      handleIconBoxImageUpload={handleIconBoxImageUpload}
                      handleImageIconTitleChange={handleImageIconTitleChange}
                      handleImageIconHtmlTagChange={handleImageIconHtmlTagChange}
                      sectionContent={imageIcon?.title}
                      currentImageLink={imageIcon?.link}
                      headingLevel={imageIcon?.htmlTag}
                      sectionContentDescription={imageIcon?.description}
                      imageIcon={imageIcon}
                      handleImageIconDescriptionChange={handleImageIconDescriptionChange}
                      handleImageIconImageLinkChange={handleImageIconImageLinkChange}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      handleIconBoxImageValue={handleIconBoxImageValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <IconListSettingApply
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      items={iconListData?.item}
                      setIconListData={setIconListData}
                      handleIconListDataValue={handleIconListDataValue}
                      handleIconListDataRemoveValue={handleIconListDataRemoveValue}
                      handleIconListDataCopyValue={handleIconListDataCopyValue}
                      setTabIndex={setTabIndex}
                      iconListData={iconListData}
                      tabIndex={tabIndex}
                      handleIconListValue={handleIconListValue}
                      handleIconListIconValue={handleIconListIconValue}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <CounterSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      numberCounter={numberCounter}
                      handleCounterChange={handleCounterChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <TestimonialSetting
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      testiMonialValue={testiMonialValue}
                      HandleTestiMonialChange={HandleTestiMonialChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <TabSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      tabValue={tabValue}
                      setTabValue={setTabValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleTabValue={handleTabValue}
                      handleAddTabsItem={handleAddTabsItem}
                      handleCopyTabsItem={handleCopyTabsItem}
                      handleTabsDataRemoveValue={handleTabsDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <AccordionSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      accordionValue={accordionValue}
                      setAccordionValue={setAccordionValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleAccordionValue={handleAccordionValue}
                      handleAddAccordionItem={handleAddAccordionItem}
                      handleCopyAccordionItem={handleCopyAccordionItem}
                      handleAccordionDataRemoveValue={handleAccordionDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <AlertSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      alertValue={alertValue}
                      handleAlertChange={handleAlertChange}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <SocialIconSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      items={socialIconData?.item}
                      setsocialIconData={setsocialIconData}
                      handleSocialIconDataValue={handleSocialIconDataValue}
                      handleSocialIconDataRemoveValue={handleSocialIconDataRemoveValue}
                      handleSocialIconCopyValue={handleSocialIconCopyValue}
                      setTabIndex={setTabIndex}
                      socialIconData={socialIconData}
                      tabIndex={tabIndex}
                      handleSocialIconListValue={handleSocialIconListValue}
                      handleSocialIconValue={handleSocialIconValue}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <HtmlSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      htmlValue={htmlValue}
                      setHtmlValue={setHtmlValue}
                      handleHtmlChange={handleHtmlChange}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <RatingSettingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      ratingValue={ratingValue}
                      HandleRatingChange={HandleRatingChange}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                      handleRatingIconSelectValue={handleRatingIconSelectValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <StaticFirstContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstContentValue={firstContentValue}
                      setFirstContentValue={setFirstContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={firstContentValue?.title}
                      headingLevel={firstContentValue?.htmlTag}
                      descriptionContent={firstContentValue?.description}
                      subTitleContent={firstContentValue?.subTitle}
                      subTitleheadingLevel={firstContentValue?.subHtmlTag}
                      sectionButtonContent={firstContentValue?.button}
                      sectionGridContent={firstContentValue?.gridValue}
                      handleFirstContentValue={handleFirstContentValue}
                      handleAddFirstContentItem={handleAddFirstContentItem}
                      handleCopyFirstContentItem={handleCopyFirstContentItem}
                      handleFirstContentDataRemoveValue={handleFirstContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstContentTagChange={handleFirstContentTagChange}
                      handleFirstContentTitleValueChange={handleFirstContentTitleValueChange}
                      handleFirstContentSubTitleValueChange={handleFirstContentSubTitleValueChange}
                      handleFirstContentSubTitleTagChange={handleFirstContentSubTitleTagChange}
                      handleFirstContentDescriptionChange={handleFirstContentDescriptionChange}
                      handleFirstContentButtonValueChange={handleFirstContentButtonValueChange}
                      handleFirstContentGridValueChange={handleFirstContentGridValueChange}
                    />
                  ) : editSection?.section_type === 'content-2' ? (
                    <StaticSecondContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondContentValue={secondContentValue}
                      setSecondContentValue={setSecondContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={secondContentValue?.title}
                      headingLevel={secondContentValue?.htmlTag}
                      descriptionContent={secondContentValue?.description}
                      sectionGridContent={secondContentValue?.gridValue}
                      handleSecondContentValue={handleSecondContentValue}
                      handleAddSecondContentItem={handleAddSecondContentItem}
                      handleCopySecondContentItem={handleCopySecondContentItem}
                      handleSecondContentDataRemoveValue={handleSecondContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondContentTagChange={handleSecondContentTagChange}
                      handleSecondContentTitleValueChange={handleSecondContentTitleValueChange}
                      handleSecondContentDescriptionChange={handleSecondContentDescriptionChange}
                      handleSecondContentGridValueChange={handleSecondContentGridValueChange}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'content-3' ? (
                    <StaticThirdContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdContentValue={thirdContentValue}
                      setThirdContentValue={setThirdContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={thirdContentValue?.title}
                      headingLevel={thirdContentValue?.htmlTag}
                      descriptionContent={thirdContentValue?.description}
                      sectionGridContent={thirdContentValue?.gridValue}
                      sectionButtonContent={thirdContentValue?.button}
                      handleThirdContentValue={handleThirdContentValue}
                      handleAddThirdContentItem={handleAddThirdContentItem}
                      handleCopyThirdContentItem={handleCopyThirdContentItem}
                      handleThirdContentDataRemoveValue={handleThirdContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdContentTagChange={handleThirdContentTagChange}
                      handleThirdContentTitleValueChange={handleThirdContentTitleValueChange}
                      handleThirdContentDescriptionChange={handleThirdContentDescriptionChange}
                      handleThirdContentGridValueChange={handleThirdContentGridValueChange}
                      handleThirdContentButtonValueChange={handleThirdContentButtonValueChange}
                    />
                  ) : editSection?.section_type === 'content-4' ? (
                    <StaticForthContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      forthContentValue={forthContentValue}
                      setForthContentValue={setForthContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={forthContentValue?.title}
                      headingLevel={forthContentValue?.htmlTag}
                      subTitleHeadingLevel={forthContentValue?.sub_title_htmlTag}
                      descriptionContent={forthContentValue?.description}
                      sectionGridContent={forthContentValue?.gridValue}
                      sectionButtonContent={forthContentValue?.button}
                      sectionSubTitleContent={forthContentValue?.sub_title}
                      handleForthContentValue={handleForthContentValue}
                      handleAddForthContentItem={handleAddForthContentItem}
                      handleCopyForthContentItem={handleCopyForthContentItem}
                      handleForthContentDataRemoveValue={handleForthContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleForthContentTagChange={handleForthContentTagChange}
                      handleForthContentTitleValueChange={handleForthContentTitleValueChange}
                      handleForthContentGridValueChange={handleForthContentGridValueChange}
                      handleForthContentButtonValueChange={handleForthContentButtonValueChange}
                      handleForthContentSubTitleValueChange={handleForthContentSubTitleValueChange}
                      handleForthContentDescriptionChange={handleForthContentDescriptionChange}
                    />
                  ) : editSection?.section_type === 'content-5' ? (
                    <StaticFifthContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFifthContentTagChange={handleFifthContentTagChange}
                      sectionContent={fifthContentValue?.title}
                      headingLevel={fifthContentValue?.htmlTag}
                      handleFifthContentTitleValueChange={handleFifthContentTitleValueChange}
                      handleFifthContentDescriptionChange={handleFifthContentDescriptionChange}
                      descriptionContent={fifthContentValue?.description}
                      sectionButtonContent={fifthContentValue?.button}
                      handleFifthContentButtonValueChange={handleFifthContentButtonValueChange}
                      handleFifthContentSubButtonValueChange={
                        handleFifthContentSubButtonValueChange
                      }
                      sectionSubButtonContent={fifthContentValue?.button_link}
                      sectionIconContent={fifthContentValue?.icon}
                      handleFifthContentIconValueChange={handleFifthContentIconValueChange}
                      sectionIconLinkContent={fifthContentValue?.link}
                      handleFifthContentIconLinkValueChange={handleFifthContentIconLinkValueChange}
                      sectionIconFillContent={fifthContentValue?.isFill}
                      handleFifthContentIconFillValueChange={handleFifthContentIconFillValueChange}
                    />
                  ) : editSection?.section_type === 'content-6' ? (
                    <StaticSixContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSixContentTagChange={handleSixContentTagChange}
                      sectionContent={sixContentValue?.title}
                      headingLevel={sixContentValue?.htmlTag}
                      valueContent={sixContentValue?.content}
                      sectionButtonLink={sixContentValue?.link_url}
                      handleSixContentTitleValueChange={handleSixContentTitleValueChange}
                      handleSixContentDescriptionChange={handleSixContentDescriptionChange}
                      handleSixContentContentChange={handleSixContentContentChange}
                      descriptionContent={sixContentValue?.description}
                      sectionButtonContent={sixContentValue?.button}
                      handleSixContentSubButtonValueChange={handleSixContentSubButtonValueChange}
                      handleSixContentButtonLinkChange={handleSixContentButtonLinkChange}
                      sectionSubButtonContent={sixContentValue?.button_link}
                      sectionImageContent={sixContentValue?.image}
                      handleSixContentImageChange={handleSixContentImageChange}
                      sectionProfileContent={sixContentValue?.profile}
                      handleSixContentProfileChange={handleSixContentProfileChange}
                      sectionObjectFitContent={sixContentValue?.objectFit}
                      handleSixContentObjectFitChange={handleSixContentObjectFitChange}
                      sectionIconFillContent={sixContentValue?.isFill}
                      handleSixContentIconFillValueChange={handleSixContentIconFillValueChange}
                      handleSixContentProfileFillValueChange={
                        handleSixContentProfileFillValueChange
                      }
                      sectionProfileFillContent={sixContentValue?.profileFill}
                      sectionIconContent={sixContentValue?.icon}
                      handleSixContentIconChange={handleSixContentIconChange}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'content-7' ? (
                    <StaticSevenContentSection
                      section_id={editSection?.section_id}
                      sevenContentValue={sevenContentValue}
                      setSevenContentValue={setSevenContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={sevenContentValue?.gridValue}
                      handleSevenContentValue={handleSevenContentValue}
                      handleSevenContentItem={handleSevenContentItem}
                      handleCopySevenContentItem={handleCopySevenContentItem}
                      handleSevenContentDataRemoveValue={handleSevenContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSevenContentGridValueChange={handleSevenContentGridValueChange}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'content-8' ? (
                    <StaticEightContentSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      eightContentValue={eightContentValue}
                      setEightContentValue={setEightContentValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={eightContentValue?.gridValue}
                      sectionContent={eightContentValue?.title}
                      subTitleheadingLevel={eightContentValue?.htmlTag}
                      descriptionContent={eightContentValue?.description}
                      handleEightContentValue={handleEightContentValue}
                      handleAddEightContentItem={handleAddEightContentItem}
                      handleCopyEightContentItem={handleCopyEightContentItem}
                      handleEightContentDataRemoveValue={handleEightContentDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleEightContentGridValueChange={handleEightContentGridValueChange}
                      handleEightContentTitleValueChange={handleEightContentTitleValueChange}
                      handleEightContentTitleTagChange={handleEightContentTitleTagChange}
                      handleEightContentDescriptionChange={handleEightContentDescriptionChange}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'feature-1' ? (
                    <StaticFirstFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstFeatureValue={firstFeatureValue}
                      setFirstFeatureValue={setFirstFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={firstFeatureValue?.gridValue}
                      sectionContent={firstFeatureValue?.title}
                      subTitleheadingLevel={firstFeatureValue?.htmlTag}
                      handleFirstFeatureValue={handleFirstFeatureValue}
                      handleAddFirstFeatureItem={handleAddFirstFeatureItem}
                      handleCopyFirstFeatureItem={handleCopyFirstFeatureItem}
                      handleFirstFeatureDataRemoveValue={handleFirstFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstFeatureGridValueChange={handleFirstFeatureGridValueChange}
                      handleFirstFeatureTitleValueChange={handleFirstFeatureTitleValueChange}
                      handleFirstFeatureTitleTagChange={handleFirstFeatureTitleTagChange}
                    />
                  ) : editSection?.section_type === 'feature-2' ? (
                    <StaticSecondFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondFeatureValue={secondFeatureValue}
                      setSecondFeatureValue={setSecondFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={secondFeatureValue?.gridValue}
                      sectionContent={secondFeatureValue?.title}
                      subTitleheadingLevel={secondFeatureValue?.htmlTag}
                      descriptionContent={secondFeatureValue?.description}
                      sectionButtonContent={secondFeatureValue?.button}
                      handleSecondFeatureValue={handleSecondFeatureValue}
                      handleAddSecondFeatureItem={handleAddSecondFeatureItem}
                      handleCopySecondFeatureItem={handleCopySecondFeatureItem}
                      handleSecondFeatureDataRemoveValue={handleSecondFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondFeatureGridValueChange={handleSecondFeatureGridValueChange}
                      handleSecondFeatureTitleValueChange={handleSecondFeatureTitleValueChange}
                      handleSecondFeatureTitleTagChange={handleSecondFeatureTitleTagChange}
                      handleSecondFeatureDescriptionChange={handleSecondFeatureDescriptionChange}
                      handleSecondFeatureButtonValueChange={handleSecondFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'feature-3' ? (
                    <StaticThirdFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdFeatureValue={thirdFeatureValue}
                      setThirdFeatureValue={setThirdFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdFeatureValue={handleThirdFeatureValue}
                      handleAddThirdFeatureItem={handleAddThirdFeatureItem}
                      handleCopyThirdFeatureItem={handleCopyThirdFeatureItem}
                      handleThirdFeatureDataRemoveValue={handleThirdFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdFeatureImageValue={handleThirdFeatureImageValue}
                      handleThirdFeatureObjectFitValue={handleThirdFeatureObjectFitValue}
                      setIsNotification={setIsNotification}
                      setNotificationMsg={setNotificationMsg}
                      setSeverity={setSeverity}
                    />
                  ) : editSection?.section_type === 'feature-4' ? (
                    <StaticForthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      forthFeatureValue={forthFeatureValue}
                      setForthFeatureValue={setForthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleForthFeatureValue={handleForthFeatureValue}
                      handleAddForthFeatureItem={handleAddForthFeatureItem}
                      handleCopyForthFeatureItem={handleCopyForthFeatureItem}
                      handleForthFeatureDataRemoveValue={handleForthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'feature-5' ? (
                    <StaticFifthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      fifthFeatureValue={fifthFeatureValue}
                      setFifthFeatureValue={setFifthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={fifthFeatureValue?.title}
                      headingLevel={fifthFeatureValue?.htmlTag}
                      subTitleContent={fifthFeatureValue?.subTitle}
                      subTitleheadingLevel={fifthFeatureValue?.subHtmlTag}
                      sectionGridContent={fifthFeatureValue?.gridValue}
                      handlefifthFeatureValue={handlefifthFeatureValue}
                      handleAddFifthFeatureItem={handleAddFifthFeatureItem}
                      handleCopyFifthFeatureItem={handleCopyFifthFeatureItem}
                      handleFifthFeatureDataRemoveValue={handleFifthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFifthFeatureTagChange={handleFifthFeatureTagChange}
                      handleFifthFeatureTitleValueChange={handleFifthFeatureTitleValueChange}
                      handleFifthFeatureSubTitleValueChange={handleFifthFeatureSubTitleValueChange}
                      handleFifthFeatureSubTitleTagChange={handleFifthFeatureSubTitleTagChange}
                      handleFifthFeatureGridValueChange={handleFifthFeatureGridValueChange}
                    />
                  ) : editSection?.section_type === 'feature-6' ? (
                    <StaticSixthFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sixthFeatureValue={sixthFeatureValue}
                      setSixthFeatureValue={setSixthFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionButtonContent={sixthFeatureValue?.button}
                      handleSixthFeatureValue={handleSixthFeatureValue}
                      handleAddSixthFeatureItem={handleAddSixthFeatureItem}
                      handleCopySixthFeatureItem={handleCopySixthFeatureItem}
                      handleSixthFeatureDataRemoveValue={handleSixthFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSixthFeatureTagChange={handleSixthFeatureTagChange}
                    />
                  ) : editSection?.section_type === 'feature-7' ? (
                    <StaticSevenFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      sevenFeatureValue={sevenFeatureValue}
                      setSevenFeatureValue={setSevenFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={sevenFeatureValue?.gridValue}
                      sectionContent={sevenFeatureValue?.title}
                      subTitleheadingLevel={sevenFeatureValue?.htmlTag}
                      descriptionContent={sevenFeatureValue?.description}
                      sectionButtonContent={sevenFeatureValue?.button}
                      handleSevenFeatureValue={handleSevenFeatureValue}
                      handleAddSevenFeatureItem={handleAddSevenFeatureItem}
                      handleCopySevenFeatureItem={handleCopySevenFeatureItem}
                      handleSevenFeatureDataRemoveValue={handleSevenFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSevenFeatureGridValueChange={handleSevenFeatureGridValueChange}
                      handleSevenFeatureTitleValueChange={handleSevenFeatureTitleValueChange}
                      handleSevenFeatureTitleTagChange={handleSevenFeatureTitleTagChange}
                      handleSevenFeatureDescriptionChange={handleSevenFeatureDescriptionChange}
                      handleSevenFeatureButtonValueChange={handleSevenFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'feature-8' ? (
                    <StaticEightFeatureSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      eightFeatureValue={eightFeatureValue}
                      setEightFeatureValue={setEightFeatureValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionGridContent={eightFeatureValue?.gridValue}
                      sectionContent={eightFeatureValue?.title}
                      subTitleheadingLevel={eightFeatureValue?.htmlTag}
                      descriptionContent={eightFeatureValue?.description}
                      sectionButtonContent={eightFeatureValue?.button}
                      handleEightFeatureValue={handleEightFeatureValue}
                      handleAddEightFeatureItem={handleAddEightFeatureItem}
                      handleCopyEightFeatureItem={handleCopyEightFeatureItem}
                      handleEightFeatureDataRemoveValue={handleEightFeatureDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleEightFeatureGridValueChange={handleEightFeatureGridValueChange}
                      handleEightFeatureTitleValueChange={handleEightFeatureTitleValueChange}
                      handleEightFeatureTitleTagChange={handleEightFeatureTitleTagChange}
                      handleEightFeatureDescriptionChange={handleEightFeatureDescriptionChange}
                      handleEightFeatureButtonValueChange={handleEightFeatureButtonValueChange}
                    />
                  ) : editSection?.section_type === 'step-1' ? (
                    <StaticFirstStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstStepValue={firstStepValue}
                      setFirstStepValue={setFirstStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstStepValue={handleFirstStepValue}
                      handleAddFirstStepItem={handleAddFirstStepItem}
                      handleCopyFirstStepItem={handleCopyFirstStepItem}
                      handleFirstStepDataRemoveValue={handleFirstStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'step-2' ? (
                    <StaticSecondStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondStepValue={secondStepValue}
                      setSecondStepValue={setSecondStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleSecondStepValue={handleSecondStepValue}
                      handleAddSecondStepItem={handleAddSecondStepItem}
                      handleCopySecondStepItem={handleCopySecondStepItem}
                      handleSecondStepDataRemoveValue={handleSecondStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'step-3' ? (
                    <StaticThirdStepSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdStepValue={thirdStepValue}
                      setThirdStepValue={setThirdStepValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdStepValue={handleThirdStepValue}
                      handleAddThirdStepItem={handleAddThirdStepItem}
                      handleCopyThirdStepItem={handleCopyThirdStepItem}
                      handleThirdStepDataRemoveValue={handleThirdStepDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                    />
                  ) : editSection?.section_type === 'team-1' ? (
                    <StaticFirstTeamSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstTeamValue={firstTeamValue}
                      setFirstTeamValue={setFirstTeamValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstTeamValue={handleFirstTeamValue}
                      handleAddFirstTeamItem={handleAddFirstTeamItem}
                      handleCopyFirstTeamItem={handleCopyFirstTeamItem}
                      handleFirstTeamDataRemoveValue={handleFirstTeamDataRemoveValue}
                      sectionGridContent={firstTeamValue?.gridValue}
                      sectionContent={firstTeamValue?.title}
                      subTitleheadingLevel={firstTeamValue?.htmlTag}
                      descriptionContent={firstTeamValue?.description}
                      sectionButtonContent={firstTeamValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstTeamDescriptionChange={handleFirstTeamDescriptionChange}
                      handleFirstTeamTagChange={handleFirstTeamTagChange}
                      handleFirstTeamTitleValueChange={handleFirstTeamTitleValueChange}
                      handleFirstTeamGridValueChange={handleFirstTeamGridValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'team-2' ? (
                    <StaticSecondTeamSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondTeamValue={secondTeamValue}
                      setSecondTeamValue={setSecondTeamValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleSecondTeamValue={handleSecondTeamValue}
                      handleAddSecondTeamItem={handleAddSecondTeamItem}
                      handleCopySecondTeamItem={handleCopySecondTeamItem}
                      handleSecondTeamDataRemoveValue={handleSecondTeamDataRemoveValue}
                      sectionGridContent={secondTeamValue?.gridValue}
                      sectionContent={secondTeamValue?.title}
                      subTitleheadingLevel={secondTeamValue?.htmlTag}
                      descriptionContent={secondTeamValue?.description}
                      sectionButtonContent={secondTeamValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondTeamDescriptionChange={handleSecondTeamDescriptionChange}
                      handleSecondTeamTagChange={handleSecondTeamTagChange}
                      handleSecondTeamTitleValueChange={handleSecondTeamTitleValueChange}
                      handleSecondTeamGridValueChange={handleSecondTeamGridValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'team-3' ? (
                    <StaticThirdTeamSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdTeamValue={thirdTeamValue}
                      setThirdTeamValue={setThirdTeamValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdTeamValue={handleThirdTeamValue}
                      handleAddThirdTeamItem={handleAddThirdTeamItem}
                      handleCopyThirdTeamItem={handleCopyThirdTeamItem}
                      handleThirdTeamDataRemoveValue={handleThirdTeamDataRemoveValue}
                      sectionGridContent={thirdTeamValue?.gridValue}
                      sectionContent={thirdTeamValue?.title}
                      subTitleheadingLevel={thirdTeamValue?.htmlTag}
                      descriptionContent={thirdTeamValue?.description}
                      sectionButtonContent={thirdTeamValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdTeamDescriptionChange={handleThirdTeamDescriptionChange}
                      handleThirdTeamTagChange={handleThirdTeamTagChange}
                      handleThirdTeamTitleValueChange={handleThirdTeamTitleValueChange}
                      handleThirdTeamGridValueChange={handleThirdTeamGridValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial-1' ? (
                    <StaticFirstTestimonialSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstTestimonialValue={firstTestimonialValue}
                      setFirstTestimonialValue={setFirstTestimonialValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstTestimonialValue={handleFirstTestimonialValue}
                      handleAddFirstTestimonialItem={handleAddFirstTestimonialItem}
                      handleCopyFirstTestimonialItem={handleCopyFirstTestimonialItem}
                      handleFirstTestimonialDataRemoveValue={handleFirstTestimonialDataRemoveValue}
                      sectionGridContent={firstTestimonialValue?.gridValue}
                      sectionContent={firstTestimonialValue?.title}
                      subTitleheadingLevel={firstTestimonialValue?.htmlTag}
                      descriptionContent={firstTestimonialValue?.description}
                      sectionButtonContent={firstTestimonialValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstTestimonialDescriptionChange={
                        handleFirstTestimonialDescriptionChange
                      }
                      handleFirstTestimonialTagChange={handleFirstTestimonialTagChange}
                      handleFirstTestimonialTitleValueChange={
                        handleFirstTestimonialTitleValueChange
                      }
                      handleFirstTestimonialGridValueChange={handleFirstTestimonialGridValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial-2' ? (
                    <StaticSecondTestimonialSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondTestimonialValue={secondTestimonialValue}
                      setSecondTestimonialValue={setSecondTestimonialValue}
                      sectionContent={secondTestimonialValue?.title}
                      subTitleheadingLevel={secondTestimonialValue?.htmlTag}
                      descriptionContent={secondTestimonialValue?.description}
                      sectionRoleContent={secondTestimonialValue?.role}
                      headingLevelRole={secondTestimonialValue?.role_htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondTestimonialDescriptionChange={
                        handleSecondTestimonialDescriptionChange
                      }
                      handleSecondTestimonialTagChange={handleSecondTestimonialTagChange}
                      handleSecondTestimonialRoleTagChange={handleSecondTestimonialRoleTagChange}
                      handleSecondTestimonialTitleValueChange={
                        handleSecondTestimonialTitleValueChange
                      }
                      handleSecondTestimonialRoleValueChange={
                        handleSecondTestimonialRoleValueChange
                      }
                      sectionIconContent={secondTestimonialValue?.icon}
                      sectionIconFillContent={secondTestimonialValue?.isFill}
                      handleSecondTestimonialIconFillValueChange={
                        handleSecondTestimonialIconFillValueChange
                      }
                      handleSecondTestimonialIconChange={handleSecondTestimonialIconChange}
                    />
                  ) : editSection?.section_type === 'testimonial-3' ? (
                    <StaticThirdTestimonialSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdTestimonialValue={thirdTestimonialValue}
                      setThirdTestimonialValue={setThirdTestimonialValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdTestimonialValue={handleThirdTestimonialValue}
                      handleAddThirdTestimonialItem={handleAddThirdTestimonialItem}
                      handleCopyThirdTestimonialItem={handleCopyThirdTestimonialItem}
                      handleThirdTestimonialDataRemoveValue={handleThirdTestimonialDataRemoveValue}
                      sectionGridContent={thirdTestimonialValue?.gridValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdTestimonialGridValueChange={handleThirdTestimonialGridValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'statistic-1' ? (
                    <StaticFirstStatisticSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstStatisticValue={firstStatisticValue}
                      setFirstStatisticValue={setFirstStatisticValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleFirstStatisticValue={handleFirstStatisticValue}
                      handleAddFirstStatisticItem={handleAddFirstStatisticItem}
                      handleCopyFirstStatisticItem={handleCopyFirstStatisticItem}
                      handleFirstStatisticDataRemoveValue={handleFirstStatisticDataRemoveValue}
                      sectionGridContent={firstStatisticValue?.gridValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstStatisticGridValueChange={handleFirstStatisticGridValueChange}
                    />
                  ) : editSection?.section_type === 'statistic-2' ? (
                    <StaticSecondStatisticSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondStatisticValue={secondStatisticValue}
                      setSecondStatisticValue={setSecondStatisticValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleSecondStatisticValue={handleSecondStatisticValue}
                      handleAddSecondStatisticItem={handleAddSecondStatisticItem}
                      handleCopySecondStatisticItem={handleCopySecondStatisticItem}
                      handleSecondStatisticDataRemoveValue={handleSecondStatisticDataRemoveValue}
                      sectionGridContent={secondStatisticValue?.gridValue}
                      descriptionContent={secondStatisticValue?.description}
                      sectionContent={secondStatisticValue?.title}
                      headingLevel={secondStatisticValue?.htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondStatisticGridValueChange={handleSecondStatisticGridValueChange}
                      handleSecondStatisticTitleValueChange={handleSecondStatisticTitleValueChange}
                      handleSecondStatisticTagChange={handleSecondStatisticTagChange}
                      handleSecondStatisticDescriptionChange={
                        handleSecondStatisticDescriptionChange
                      }
                      setSeverity={setSeverity}
                      setNotificationMsg={setNotificationMsg}
                      setIsNotification={setIsNotification}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'statistic-3' ? (
                    <StaticThirdStatisticSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      thirdStatisticValue={thirdStatisticValue}
                      setThirdStatisticValue={setThirdStatisticValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      handleThirdStatisticValue={handleThirdStatisticValue}
                      handleAddThirdStatisticItem={handleAddThirdStatisticItem}
                      handleCopyThirdStatisticItem={handleCopyThirdStatisticItem}
                      handleThirdStatisticDataRemoveValue={handleThirdStatisticDataRemoveValue}
                      sectionGridContent={thirdStatisticValue?.gridValue}
                      descriptionContent={thirdStatisticValue?.description}
                      sectionContent={thirdStatisticValue?.title}
                      headingLevel={thirdStatisticValue?.htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdStatisticGridValueChange={handleThirdStatisticGridValueChange}
                      handleThirdStatisticTitleValueChange={handleThirdStatisticTitleValueChange}
                      handleThirdStatisticTagChange={handleThirdStatisticTagChange}
                      handleThirdStatisticDescriptionChange={handleThirdStatisticDescriptionChange}
                    />
                  ) : editSection?.section_type === 'contact-1' ? (
                    <StaticFirstContactSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={firstContactValue?.description}
                      sectionContent={firstContactValue?.title}
                      headingLevel={firstContactValue?.htmlTag}
                      noteValueContent={firstContactValue?.note}
                      urlValueContent={firstContactValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstContactTitleValueChange={handleFirstContactTitleValueChange}
                      handleFirstContactTagChange={handleFirstContactTagChange}
                      handleFirstContactDescriptionChange={handleFirstContactDescriptionChange}
                      handleFirstContactNoteValueChange={handleFirstContactNoteValueChange}
                      handleFirstContactShortCodeValueChange={
                        handleFirstContactShortCodeValueChange
                      }
                    />
                  ) : editSection?.section_type === 'contact-2' ? (
                    <StaticSecondContactSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={secondContactValue?.description}
                      sectionContent={secondContactValue?.title}
                      headingLevel={secondContactValue?.htmlTag}
                      noteValueContent={secondContactValue?.note}
                      addressTitleValueContent={secondContactValue?.address_title}
                      addressTitleHtmlTag={secondContactValue?.address_title_htmlTag}
                      addressValueContent={secondContactValue?.address_value}
                      emailTitleContent={secondContactValue?.email_title}
                      emailValueContent={secondContactValue?.email_value}
                      phoneTitleContent={secondContactValue?.phone_title}
                      phoneValueContent={secondContactValue?.phone_value}
                      emailTitleHtmlTag={secondContactValue?.email_title_htmlTag}
                      emailPhoneHtmlTag={secondContactValue?.phone_title_htmlTag}
                      urlValueContent={secondContactValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondContactTitleValueChange={handleSecondContactTitleValueChange}
                      handleSecondContactTagChange={handleSecondContactTagChange}
                      handleSecondContactDescriptionChange={handleSecondContactDescriptionChange}
                      handleSecondContactNoteValueChange={handleSecondContactNoteValueChange}
                      handleSecondContactAddressTitleValueChange={
                        handleSecondContactAddressTitleValueChange
                      }
                      handleSecondContactAddressTitleTagChange={
                        handleSecondContactAddressTitleTagChange
                      }
                      handleSecondContactAddressValueChange={handleSecondContactAddressValueChange}
                      handleSecondContactEmailTitleChange={handleSecondContactEmailTitleChange}
                      handleSecondContactEmailValueChange={handleSecondContactEmailValueChange}
                      handleSecondContactPhoneTitleChange={handleSecondContactPhoneTitleChange}
                      handleSecondContactPhoneValueChange={handleSecondContactPhoneValueChange}
                      handleSecondContactEmailTitleTagChange={
                        handleSecondContactEmailTitleTagChange
                      }
                      handleSecondContactPhoneTitleTagChange={
                        handleSecondContactPhoneTitleTagChange
                      }
                      handleSecondContactShortCodeValueChange={
                        handleSecondContactShortCodeValueChange
                      }
                    />
                  ) : editSection?.section_type === 'contact-3' ? (
                    <StaticThirdContactSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={thirdContactValue?.description}
                      sectionContent={thirdContactValue?.title}
                      headingLevel={thirdContactValue?.htmlTag}
                      addressValueContent={thirdContactValue?.address_value}
                      emailValueContent={thirdContactValue?.email_value}
                      urlValueContent={thirdContactValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdContactTitleValueChange={handleThirdContactTitleValueChange}
                      handleThirdContactTagChange={handleThirdContactTagChange}
                      handleThirdContactDescriptionChange={handleThirdContactDescriptionChange}
                      handleThirdContactAddressValueChange={handleThirdContactAddressValueChange}
                      handleThirdContactEmailValueChange={handleThirdContactEmailValueChange}
                      handleThirdContactValueChange={handleThirdContactValueChange}
                      thirdContactValue={thirdContactValue}
                      setThirdContactValue={setThirdContactValue}
                      handleThirdContactShortCodeValueChange={
                        handleThirdContactShortCodeValueChange
                      }
                    />
                  ) : editSection?.section_type === 'cta-1' ? (
                    <StaticFirstCtaSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={firstCtaValue?.description}
                      sectionContent={firstCtaValue?.button}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstCtaDescriptionChange={handleFirstCtaDescriptionChange}
                      handleFirstCtaButtonValueChange={handleFirstCtaButtonValueChange}
                    />
                  ) : editSection?.section_type === 'cta-2' ? (
                    <StaticSecondCtaSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={secondCtaValue?.description}
                      sectionContent={secondCtaValue?.title}
                      headingLevel={secondCtaValue?.htmlTag}
                      formTitleValue={secondCtaValue?.form_title}
                      formTitleTag={secondCtaValue?.form_title_htmlTag}
                      noteTitleValue={secondCtaValue?.note}
                      urlValueContent={secondCtaValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondCtaTitleValueChange={handleSecondCtaTitleValueChange}
                      handleSecondCtaTagChange={handleSecondCtaTagChange}
                      handleSecondCtaDescriptionChange={handleSecondCtaDescriptionChange}
                      handleSecondCtaFormTitleValueChange={handleSecondCtaFormTitleValueChange}
                      handleSecondCtaFormNoteValueChange={handleSecondCtaFormNoteValueChange}
                      handleSecondCtaFormTitleTagChange={handleSecondCtaFormTitleTagChange}
                      handleSecondCtaShortCodeValueChange={handleSecondCtaShortCodeValueChange}
                    />
                  ) : editSection?.section_type === 'cta-3' ? (
                    <StaticThirdCtaSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={thirdCtaValue?.description}
                      sectionContent={thirdCtaValue?.title}
                      headingLevel={thirdCtaValue?.htmlTag}
                      urlValueContent={thirdCtaValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdCtaTitleValueChange={handleThirdCtaTitleValueChange}
                      handleThirdCtaTagChange={handleThirdCtaTagChange}
                      handleThirdCtaDescriptionChange={handleThirdCtaDescriptionChange}
                      handleThirdCtaShortCodeValueChange={handleThirdCtaShortCodeValueChange}
                    />
                  ) : editSection?.section_type === 'cta-4' ? (
                    <StaticForthCtaSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={forthCtaValue?.description}
                      sectionContent={forthCtaValue?.title}
                      headingLevel={forthCtaValue?.htmlTag}
                      firstBtnContent={forthCtaValue?.first_btn_title}
                      firstBtnSubTitleContent={forthCtaValue?.first_btn_sub_title}
                      secondBtnSubTitleContent={forthCtaValue?.second_btn_sub_title}
                      firstBtnLinkValue={forthCtaValue?.first_btn_link}
                      secondBtnLinkValue={forthCtaValue?.second_btn_link}
                      secondBtnContent={forthCtaValue?.second_btn_title}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleForthCtaTitleValueChange={handleForthCtaTitleValueChange}
                      handleForthCtaTagChange={handleForthCtaTagChange}
                      handleForthCtaDescriptionChange={handleForthCtaDescriptionChange}
                      handleForthCtaFirstBtnTitleValueChange={
                        handleForthCtaFirstBtnTitleValueChange
                      }
                      handleForthCtaFirstBtnSubTitleValueChange={
                        handleForthCtaFirstBtnSubTitleValueChange
                      }
                      handleForthCtaSecondBtnTitleValueChange={
                        handleForthCtaSecondBtnTitleValueChange
                      }
                      handleForthCtaSecondBtnSubTitleValueChange={
                        handleForthCtaSecondBtnSubTitleValueChange
                      }
                      handleForthCtaFirstBtnLinkValueChange={handleForthCtaFirstBtnLinkValueChange}
                      handleForthCtaSecondBtnLinkValueChange={
                        handleForthCtaSecondBtnLinkValueChange
                      }
                    />
                  ) : editSection?.section_type === 'hero-1' ? (
                    <StaticFirstHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={firstHeroValue?.description}
                      sectionContent={firstHeroValue?.title}
                      firstBtnContent={firstHeroValue?.first_btn}
                      secondBtnContent={firstHeroValue?.second_btn}
                      headingLevel={firstHeroValue?.htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      setFirstHeroValue={setFirstHeroValue}
                      handleFisrtHeroTitleValueChange={handleFisrtHeroTitleValueChange}
                      handleFirstHeroTagChange={handleFirstHeroTagChange}
                      handleFirstHeroDescriptionChange={handleFirstHeroDescriptionChange}
                      handleFisrtHeroFirstBtnValueChange={handleFisrtHeroFirstBtnValueChange}
                      handleFisrtHeroSecondBtnValueChange={handleFisrtHeroSecondBtnValueChange}
                      handleSettingImageUpload={handleSettingImageUpload}
                      firstHeroValue={firstHeroValue}
                      handleFirstHeroValue={handleFirstHeroValue}
                    />
                  ) : editSection?.section_type === 'hero-2' ? (
                    <StaticSecondHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={secondHeroValue?.description}
                      sectionContent={secondHeroValue?.title}
                      firstBtnContent={secondHeroValue?.first_btn}
                      secondBtnContent={secondHeroValue?.second_btn}
                      headingLevel={secondHeroValue?.htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondHeroTitleValueChange={handleSecondHeroTitleValueChange}
                      handleSecondHeroTagChange={handleSecondHeroTagChange}
                      handleSecondHeroDescriptionChange={handleSecondHeroDescriptionChange}
                      handleSecondHeroFirstBtnValueChange={handleSecondHeroFirstBtnValueChange}
                      handleSecondHeroSecondBtnValueChange={handleSecondHeroSecondBtnValueChange}
                      secondHeroValue={secondHeroValue}
                      setSecondHeroValue={setSecondHeroValue}
                      handleSecondHeroValue={handleSecondHeroValue}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-3' ? (
                    <StaticThirdHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={thirdHeroValue?.description}
                      sectionContent={thirdHeroValue?.title}
                      firstBtnContent={thirdHeroValue?.first_btn}
                      secondBtnContent={thirdHeroValue?.second_btn}
                      headingLevel={thirdHeroValue?.htmlTag}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleThirdHeroTitleValueChange={handleThirdHeroTitleValueChange}
                      handleThirdHeroTagChange={handleThirdHeroTagChange}
                      handleThirdHeroDescriptionChange={handleThirdHeroDescriptionChange}
                      handleThirdHeroFirstBtnValueChange={handleThirdHeroFirstBtnValueChange}
                      handleThirdHeroSecondBtnValueChange={handleThirdHeroSecondBtnValueChange}
                      thirdHeroValue={thirdHeroValue}
                      setThirdHeroValue={setThirdHeroValue}
                      handleThirdHeroValue={handleThirdHeroValue}
                      handleSettingImageUpload={handleSettingImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-4' ? (
                    <StaticForthHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={forthHeroValue?.description}
                      sectionContent={forthHeroValue?.title}
                      firstBtnContent={forthHeroValue?.first_btn_title}
                      firstBtnSubTitleContent={forthHeroValue?.first_btn_sub_title}
                      secondBtnSubTitleContent={forthHeroValue?.second_btn_sub_title}
                      firstBtnLinkValue={forthHeroValue?.first_btn_link}
                      secondBtnLinkValue={forthHeroValue?.second_btn_link}
                      secondBtnContent={forthHeroValue?.second_btn_title}
                      headingLevel={forthHeroValue?.htmlTag}
                      noteContent={forthHeroValue?.note}
                      urlValueContent={forthHeroValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleForthHeroTitleValueChange={handleForthHeroTitleValueChange}
                      handleForthHeroTagChange={handleForthHeroTagChange}
                      handleForthHeroDescriptionChange={handleForthHeroDescriptionChange}
                      handleForthHeroFirstBtnValueChange={handleForthHeroFirstBtnValueChange}
                      handleForthHeroSecondBtnValueChange={handleForthHeroSecondBtnValueChange}
                      handleForthHeroFirstBtnSubTitleValueChange={
                        handleForthHeroFirstBtnSubTitleValueChange
                      }
                      handleForthHeroFirstBtnLinkValueChange={
                        handleForthHeroFirstBtnLinkValueChange
                      }
                      handleForthHeroSecondBtnSubTitleValueChange={
                        handleForthHeroSecondBtnSubTitleValueChange
                      }
                      handleForthHeroSecondBtnLinkValueChange={
                        handleForthHeroSecondBtnLinkValueChange
                      }
                      handleForthHeroNoteValueChange={handleForthHeroNoteValueChange}
                      handleForthHeroShortCodeValueChange={handleForthHeroShortCodeValueChange}
                      forthHeroValue={forthHeroValue}
                      setForthHeroValue={setForthHeroValue}
                      handleForthHeroValue={handleForthHeroValue}
                      handleSettingImageUpload={handleSettingImageUpload}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                    />
                  ) : editSection?.section_type === 'hero-5' ? (
                    <StaticFifthHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={fifthHeroValue?.description}
                      sectionContent={fifthHeroValue?.title}
                      firstBtnContent={fifthHeroValue?.first_btn_title}
                      firstBtnSubTitleContent={fifthHeroValue?.first_btn_sub_title}
                      secondBtnSubTitleContent={fifthHeroValue?.second_btn_sub_title}
                      firstBtnLinkValue={fifthHeroValue?.first_btn_link}
                      secondBtnLinkValue={fifthHeroValue?.second_btn_link}
                      secondBtnContent={fifthHeroValue?.second_btn_title}
                      headingLevel={fifthHeroValue?.htmlTag}
                      noteContent={fifthHeroValue?.note}
                      urlValueContent={forthHeroValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFifthHeroTitleValueChange={handleFifthHeroTitleValueChange}
                      handleFifthHeroTagChange={handleFifthHeroTagChange}
                      handleFifthHeroDescriptionChange={handleFifthHeroDescriptionChange}
                      handleFifthHeroFirstBtnValueChange={handleFifthHeroFirstBtnValueChange}
                      handleFifthHeroSecondBtnValueChange={handleFifthHeroSecondBtnValueChange}
                      handleFifthHeroFirstBtnSubTitleValueChange={
                        handleFifthHeroFirstBtnSubTitleValueChange
                      }
                      handleFifthHeroFirstBtnLinkValueChange={
                        handleFifthHeroFirstBtnLinkValueChange
                      }
                      handleFifthHeroSecondBtnSubTitleValueChange={
                        handleFifthHeroSecondBtnSubTitleValueChange
                      }
                      handleFifthHeroSecondBtnLinkValueChange={
                        handleFifthHeroSecondBtnLinkValueChange
                      }
                      handleFifthHeroNoteValueChange={handleFifthHeroNoteValueChange}
                      handleFifthHeroShortCodeValueChange={handleFifthHeroShortCodeValueChange}
                      fifthHeroValue={fifthHeroValue}
                      setFifthHeroValue={setFifthHeroValue}
                      handleFifthHeroValue={handleFifthHeroValue}
                      handleSettingImageUpload={handleSettingImageUpload}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                    />
                  ) : editSection?.section_type === 'hero-6' ? (
                    <StaticSixthHeroSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      descriptionContent={sixthHeroValue?.description}
                      sectionContent={sixthHeroValue?.title}
                      firstBtnContent={sixthHeroValue?.first_btn_title}
                      firstBtnSubTitleContent={sixthHeroValue?.first_btn_sub_title}
                      secondBtnSubTitleContent={sixthHeroValue?.second_btn_sub_title}
                      firstBtnLinkValue={sixthHeroValue?.first_btn_link}
                      secondBtnLinkValue={sixthHeroValue?.second_btn_link}
                      secondBtnContent={sixthHeroValue?.second_btn_title}
                      headingLevel={sixthHeroValue?.htmlTag}
                      noteContent={sixthHeroValue?.note}
                      urlValueContent={sixthHeroValue?.shortCode}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSixthHeroTitleValueChange={handleSixthHeroTitleValueChange}
                      handleSixthHeroTagChange={handleSixthHeroTagChange}
                      handleSixthHeroDescriptionChange={handleSixthHeroDescriptionChange}
                      handleSixthHeroFirstBtnValueChange={handleSixthHeroFirstBtnValueChange}
                      handleSixthHeroSecondBtnValueChange={handleSixthHeroSecondBtnValueChange}
                      handleSixthHeroFirstBtnSubTitleValueChange={
                        handleSixthHeroFirstBtnSubTitleValueChange
                      }
                      handleSixthHeroFirstBtnLinkValueChange={
                        handleSixthHeroFirstBtnLinkValueChange
                      }
                      handlSixthHeroSecondBtnSubTitleValueChange={
                        handlSixthHeroSecondBtnSubTitleValueChange
                      }
                      handleSixthHeroSecondBtnLinkValueChange={
                        handleSixthHeroSecondBtnLinkValueChange
                      }
                      handleSixthHeroNoteValueChange={handleSixthHeroNoteValueChange}
                      handleSixthHeroShortCodeValueChange={handleSixthHeroShortCodeValueChange}
                      sixthHeroValue={sixthHeroValue}
                      setSixthHeroValue={setSixthHeroValue}
                      handleSixthHeroValue={handleSixthHeroValue}
                      handleSettingImageUpload={handleSettingImageUpload}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                    />
                  ) : editSection?.section_type === 'pricing-1' ? (
                    <StaticFirstPricingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      firstPricingValue={firstPricingValue}
                      setFirstPricingValue={setFirstPricingValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={firstPricingValue?.title}
                      headingLevel={firstPricingValue?.htmlTag}
                      descriptionContent={firstPricingValue?.description}
                      sectionGridContent={firstPricingValue?.gridValue}
                      handleFirstPricingValue={handleFirstPricingValue}
                      handleAddFirstPricingItem={handleAddFirstPricingItem}
                      handleCopyFirstPricingItem={handleCopyFirstPricingItem}
                      handleFirstPricingDataRemoveValue={handleFirstPricingDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleFirstPricingTagChange={handleFirstPricingTagChange}
                      handleFirstPricingTitleValueChange={handleFirstPricingTitleValueChange}
                      handleFirstPricingDescriptionChange={handleFirstPricingDescriptionChange}
                      handleFirstPricingGridValueChange={handleFirstPricingGridValueChange}
                    />
                  ) : editSection?.section_type === 'pricing-2' ? (
                    <StaticSecondPricingSection
                      section_id={editSection?.section_id}
                      section_type={editSection?.section_type}
                      secondPricingValue={secondPricingValue}
                      setSecondPricingValue={setSecondPricingValue}
                      tabIndex={tabIndex}
                      setTabIndex={setTabIndex}
                      sectionContent={secondPricingValue?.title}
                      headingLevel={secondPricingValue?.htmlTag}
                      descriptionContent={secondPricingValue?.description}
                      linkContent={secondPricingValue?.link}
                      linkUrlContent={secondPricingValue?.link_url}
                      buttonContent={secondPricingValue?.button}
                      buttonUrlContent={secondPricingValue?.button_url}
                      handleSecondPricingValue={handleSecondPricingValue}
                      handleAddSecondPricingItem={handleAddSecondPricingItem}
                      handleCopySecondPricingItem={handleCopySecondPricingItem}
                      handleSecondPricingDataRemoveValue={handleSecondPricingDataRemoveValue}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleSecondPricingTagChange={handleSecondPricingTagChange}
                      handleSecondPricingTitleValueChange={handleSecondPricingTitleValueChange}
                      handleSecondPricingDescriptionChange={handleSecondPricingDescriptionChange}
                      handleSecondPricingLinkValueChange={handleSecondPricingLinkValueChange}
                      handleSecondPricingLinkUrlValueChange={handleSecondPricingLinkUrlValueChange}
                      handleSecondPricingButtonValueChange={handleSecondPricingButtonValueChange}
                      handleSecondPricingButtonUrlValueChange={
                        handleSecondPricingButtonUrlValueChange
                      }
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'style' && editSection?.section_type ? (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='font-semibold text-primary text-[13px] capitalize'>
                      {t('lang_update')} {editSection?.section_type} {t('lang_style_section')}
                    </p>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        selectSection(e, '', '');
                      }}
                      className='close-btn'
                    >
                      <X className='w-4 h-4' />
                    </button>
                  </div>
                  <hr className='my-2 main-border-color' />
                  {editSection?.section_type === 'manufacture' ? (
                    <EditManufactureStyleSection
                      manufactureDesign={manufactureDesign}
                      setManufactureDesign={setManufactureDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'category' ? (
                    <EditCategoryStyleSection
                      categoryDesign={categoryDesign}
                      setCategoryDesign={setCategoryDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'product' ? (
                    <EditProductStyleSection
                      productDesign={productDesign}
                      setProductDesign={setProductDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                      visualData={visualData}
                    />
                  ) : editSection?.section_type === 'reassurance' ? (
                    <EditReassuranceStyleSection
                      reassuranceDesign={reassuranceDesign}
                      setReassuranceDesign={setReassuranceDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'banner' ? (
                    <EditBannerStyleSection
                      bannerDesign={bannerDesign}
                      setBannerDesign={setBannerDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'slider' ? (
                    <EditSliderStyleSection
                      sliderDesign={sliderDesign}
                      setSliderDesign={setSliderDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'cmsoffer' ? (
                    <EditCmsOfferStyleSection
                      cmsOfferDesign={cmsOfferDesign}
                      setCmsOfferDesign={setCmsOfferDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'blog' ? (
                    <EditBlogStyleSection
                      blogDesign={blogDesign}
                      setBlogDesign={setBlogDesign}
                      section_id={editSection?.section_id}
                      getSectionData={getSectionData}
                      handleUpdateCss={handleUpdateCss}
                    />
                  ) : editSection?.section_type === 'heading' ? (
                    <EditHeadingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHeadingDesign={setHeadingDesign}
                      headingDesign={headingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={headingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'image' ? (
                    <EditImageStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageDesign={setImageDesign}
                      imageDesign={imageDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'textEditor' ? (
                    <EditTextEditorStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTextEditorDesign={setTextEditorDesign}
                      textEditorDesign={textEditorDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={textEditorDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'video' ? (
                    <EditVideoStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setVideoDesign={setVideoDesign}
                      videoDesign={videoDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={videoDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'button' ? (
                    <EditButtonStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setButtonDesign={setButtonDesign}
                      buttonDesign={buttonDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={buttonDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'divider' ? (
                    <EditDividerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setDividerDesign={setDividerDesign}
                      dividerDesign={dividerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={dividerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'spacer' ? (
                    <EditSpacerStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSpacerDesign={setSpacerDesign}
                      spacerDesign={spacerDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={spacerDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial' ? (
                    <EditTestiMonialStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTestiMonialDesign={setTestiMonialDesign}
                      testiMonialDesign={testiMonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={testiMonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'socialIcon' ? (
                    <EditSocialIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSocialIconDesign={setSocialIconDesign}
                      socialIconDesign={socialIconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={socialIconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'alert' ? (
                    <EditAlertStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAlertDesign={setAlertDesign}
                      alertDesign={alertDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={alertDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'html' ? (
                    <EditHtmlStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setHtmlDesign={setHtmlDesign}
                      htmlDesign={htmlDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={htmlDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'imageBox' ? (
                    <EditImageBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setImageBoxDesign={setImageBoxDesign}
                      imageBoxDesign={imageBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={imageBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'generalIconBox' ? (
                    <EditIconBoxStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconBoxDesign={setIconBoxDesign}
                      iconBoxDesign={iconBoxDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconBoxDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'generalIconList' ? (
                    <EditIconListStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconListDesign={setIconListDesign}
                      iconListDesign={iconListDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconListDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'googleMap' ? (
                    <EditMapStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setMapDesign={setMapDesign}
                      mapDesign={mapDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={mapDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'icon' ? (
                    <EditIconStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setIconDesign={setIconDesign}
                      iconDesign={iconDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={iconDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'counter' ? (
                    <EditCounterStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setCounterDesign={setCounterDesign}
                      counterDesign={counterDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={counterDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'tabs' ? (
                    <EditTabStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setTabDesign={setTabDesign}
                      tabDesign={tabDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={tabDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'accordion' ? (
                    <EditAccordianStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setAccordianDesign={setAccordianDesign}
                      accordianDesign={accordianDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={accordianDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'rating' ? (
                    <EditRatingStyleSection
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setRatingDesign={setRatingDesign}
                      ratingDesign={ratingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={ratingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-1' ? (
                    <EditFirstContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstContentDesign={setFirstContentDesign}
                      firstContentDesign={firstContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-2' ? (
                    <EditSecondContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondContentDesign={setSecondContentDesign}
                      secondContentDesign={secondContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-3' ? (
                    <EditThirdContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdContentDesign={setThirdContentDesign}
                      thirdContentDesign={thirdContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-4' ? (
                    <EditForthContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthContentDesign={setForthContentDesign}
                      forthContentDesign={forthContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-5' ? (
                    <EditFifthContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFifthContentDesign={setFifthContentDesign}
                      fifthContentDesign={fifthContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={fifthContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-6' ? (
                    <EditSixContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSixContentDesign={setSixContentDesign}
                      sixContentDesign={sixContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sixContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-7' ? (
                    <EditSevenContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSevenContentDesign={setSevenContentDesign}
                      sevenContentDesign={sevenContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sevenContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'content-8' ? (
                    <EditEightContentStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setEightContentDesign={setEightContentDesign}
                      eightContentDesign={eightContentDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={eightContentDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-1' ? (
                    <EditFirstFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstFeatureDesign={setFirstFeatureDesign}
                      firstFeatureDesign={firstFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-2' ? (
                    <EditSecondFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondFeatureDesign={setSecondFeatureDesign}
                      secondFeatureDesign={secondFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-3' ? (
                    <EditThirdFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdFeatureDesign={setThirdFeatureDesign}
                      thirdFeatureDesign={thirdFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-4' ? (
                    <EditForthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthFeatureDesign={setForthFeatureDesign}
                      forthFeatureDesign={forthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-5' ? (
                    <EditFifthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFifthFeatureDesign={setFifthFeatureDesign}
                      fifthFeatureDesign={fifthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={fifthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-6' ? (
                    <EditSixthFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSixthFeatureDesign={setSixthFeatureDesign}
                      sixthFeatureDesign={sixthFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sixthFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-7' ? (
                    <EditSevenFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSevenFeatureDesign={setSevenFeatureDesign}
                      sevenFeatureDesign={sevenFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sevenFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'feature-8' ? (
                    <EditEightFeatureStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setEightFeatureDesign={setEightFeatureDesign}
                      eightFeatureDesign={eightFeatureDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={eightFeatureDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'step-1' ? (
                    <EditFirstStepStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstStepDesign={setFirstStepDesign}
                      firstStepDesign={firstStepDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstStepDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'step-2' ? (
                    <EditSecondStepStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondStepDesign={setSecondStepDesign}
                      secondStepDesign={secondStepDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondStepDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'step-3' ? (
                    <EditThirdStepStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdStepDesign={setThirdStepDesign}
                      thirdStepDesign={thirdStepDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdStepDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'team-1' ? (
                    <EditFirstTeamStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstTeamDesign={setFirstTeamDesign}
                      firstTeamDesign={firstTeamDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstTeamDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'team-2' ? (
                    <EditSecondTeamStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondTeamDesign={setSecondTeamDesign}
                      secondTeamDesign={secondTeamDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondTeamDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'team-3' ? (
                    <EditThirdTeamStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdTeamDesign={setThirdTeamDesign}
                      thirdTeamDesign={thirdTeamDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdTeamDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial-1' ? (
                    <EditFirstTestimonialStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstTestimonialDesign={setFirstTestimonialDesign}
                      firstTestimonialDesign={firstTestimonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstTestimonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial-2' ? (
                    <EditSecondTestimonialStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondTestimonialDesign={setSecondTestimonialDesign}
                      secondTestimonialDesign={secondTestimonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondTestimonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'testimonial-3' ? (
                    <EditThirdTestimonialStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdTestimonialDesign={setThirdTestimonialDesign}
                      thirdTestimonialDesign={thirdTestimonialDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdTestimonialDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'statistic-1' ? (
                    <EditFirstStatisticStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstStatisticDesign={setFirstStatisticDesign}
                      firstStatisticDesign={firstStatisticDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstStatisticDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'statistic-2' ? (
                    <EditSecondStatisticStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondStatisticDesign={setSecondStatisticDesign}
                      secondStatisticDesign={secondStatisticDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondStatisticDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'statistic-3' ? (
                    <EditThirdStatisticStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdStatisticDesign={setThirdStatisticDesign}
                      thirdStatisticDesign={thirdStatisticDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdStatisticDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'contact-1' ? (
                    <EditFirstContactStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstContactDesign={setFirstContactDesign}
                      firstContactDesign={firstContactDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstContactDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'contact-2' ? (
                    <EditSecondContactStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondContactDesign={setSecondContactDesign}
                      secondContactDesign={secondContactDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondContactDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'contact-3' ? (
                    <EditThirdContactStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdContactDesign={setThirdContactDesign}
                      thirdContactDesign={thirdContactDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdContactDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'cta-1' ? (
                    <EditFirstCtaStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstCtaDesign={setFirstCtaDesign}
                      firstCtaDesign={firstCtaDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstCtaDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'cta-2' ? (
                    <EditSecondCtaStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondCtaDesign={setSecondCtaDesign}
                      secondCtaDesign={secondCtaDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondCtaDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'cta-3' ? (
                    <EditThirdCtaStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdCtaDesign={setThirdCtaDesign}
                      thirdCtaDesign={thirdCtaDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdCtaDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'cta-4' ? (
                    <EditForthCtaStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthCtaDesign={setForthCtaDesign}
                      forthCtaDesign={forthCtaDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthCtaDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-1' ? (
                    <EditFirstHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstHeroDesign={setFirstHeroDesign}
                      firstHeroDesign={firstHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-2' ? (
                    <EditSecondHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondHeroDesign={setSecondHeroDesign}
                      secondHeroDesign={secondHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-3' ? (
                    <EditThirdHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setThirdHeroDesign={setThirdHeroDesign}
                      thirdHeroDesign={thirdHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={thirdHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-4' ? (
                    <EditForthHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setForthHeroDesign={setForthHeroDesign}
                      forthHeroDesign={forthHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={forthHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-5' ? (
                    <EditFifthHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFifthHeroDesign={setFifthHeroDesign}
                      fifthHeroDesign={fifthHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={fifthHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'hero-6' ? (
                    <EditSixthHeroStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSixthHeroDesign={setSixthHeroDesign}
                      sixthHeroDesign={sixthHeroDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={sixthHeroDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'pricing-1' ? (
                    <EditFirstPricingStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setFirstPricingDesign={setFirstPricingDesign}
                      firstPricingDesign={firstPricingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={firstPricingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : editSection?.section_type === 'pricing-2' ? (
                    <EditSecondPricingStyleContainer
                      handleApplyChangesElementor={handleApplyChangesElementor}
                      setSecondPricingDesign={setSecondPricingDesign}
                      secondPricingDesign={secondPricingDesign}
                      section_id={editSection?.section_id}
                      updateSectionStyle={updateSectionStyle}
                      previewImage={secondPricingDesign?.background_image}
                      saveElementorData={saveElementorData}
                      btnLoading={btnLoading}
                      handleBackgroundImageUpload={handleBackgroundImageUpload}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : openTab === 'add_section' ? (
                // && editSection?.section_type
                <>
                  <AddElementsStyleSection
                    handleAddSection={handleAddSection}
                    activeIndex={activeIndex}
                    editSection={editSection}
                    gridValue={gridValue}
                    dragItems={dragItems}
                    saveElementorData={saveElementorData}
                    btnLoading={btnLoading}
                    handleFlexBoxClick={handleFlexBoxClick}
                    openAddSection={openAddSection}
                    setGridValue={setGridValue}
                    setOpenTab={setOpenTab}
                    handleItemClick={handleItemClick}
                  />
                </>
              ) : (
                <>
                  <div className='flex items-center justify-between mt-4'>
                    <p className='text-base font-medium'> {t('lang_Customize_Your_Templates')}</p>
                  </div>
                  <hr className='my-2 main-border-color' />
                  <p className='text-sm table-text'>{t('lang_Select_Section')}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {!open && (
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            zIndex: '9999',
          }}
          className='shadow-inner block md:hidden'
        >
          <div className='bg-[#222222] text-white text-xs text-center rounded-t-xl p-2'>
            {t('lang_Select_Section_Details')}
          </div>
          <div className='relative h-[65px]  secondary-bg-color flex items-center'>
            <div onMouseDown={() => setOpen(true)} onTouchMove={() => setOpen(true)}>
              <Puller />
            </div>
            {editSection?.section_type ? (
              <div className='flex relative items-center w-full'>
                <button
                  onClick={(e) => selectSection(e, '', '')}
                  className='flex absolute left-[20px] top-[20px] -translate-y-[20px] text-sm items-center'
                >
                  <ChevronLeft className='w-4 h-4' />
                  {t('lang_Sections')}
                </button>
                <p
                  className='text-center w-full capitalize'
                  onMouseDown={() => setOpen(true)}
                  onTouchMove={() => setOpen(true)}
                >
                  {t('lang_edit')} {editSection?.section_type} {t('lang_Section')}
                </p>
              </div>
            ) : (
              <div
                className='flex relative items-center w-full'
                onMouseDown={() => setOpen(true)}
                onTouchMove={() => setOpen(true)}
              >
                <p className='text-center block w-full'>{t('lang_Show_All_Sections')}</p>
              </div>
            )}
          </div>
        </div>
      )}
      <Dialog
        open={isAddSection}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsAddSection(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <AddLayout closeAddSection={closeAddSection} getAllLayout={handleGetLayout} />
      </Dialog>
    </>
  );
};

export default HomePageEditor;
