import React, { useEffect, useState } from 'react'
import { Checkbox, Dialog, Pagination } from '@mui/material';
import { Transition } from './Transition';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const SelectVariantQuantity = ({ openVariantQty, closeVariantQtyModal, locationData, groupVariantData, setGroupVariantData, openVariantQtyindex, alreadySelected }) => {
    const [pos_quantity, setPos_Quantity] = useState([])

    useEffect(() => {
        if (alreadySelected?.length > 0 && openVariantQtyindex !== undefined) {
            const data = alreadySelected[openVariantQtyindex];
            if (data?.pos_quantity) {
                setPos_Quantity(data.pos_quantity);
            } else {
                setPos_Quantity(locationData.map((elem) => ({
                    quantity: 0,
                    pos_location: elem?._id,
                    location_name: elem?.name,
                })));
            }
        }
    }, [alreadySelected, openVariantQtyindex, locationData]);

    const handlePOSLocation = (e, elem) => {
        const { value } = e.target;
        const existingLocationIndex = pos_quantity?.findIndex(
            (data) => data?.pos_location === elem?._id
        );

        if (existingLocationIndex !== -1) {
            const updatedLocation = {
                ...pos_quantity[existingLocationIndex],
                quantity: Number(value),
            };

            const updatedLocations = [...pos_quantity];
            updatedLocations[existingLocationIndex] = updatedLocation;
            setPos_Quantity(updatedLocations);
        } else {
            const newLocation = {
                quantity: Number(value),
                pos_location: elem?._id,
                location_name: elem?.name,
            };
            setPos_Quantity([...pos_quantity, newLocation]);
        }
    };

    const handleSubmit = () => {
        const updatedItems = [...groupVariantData];

        if (updatedItems[openVariantQtyindex]) {
            updatedItems[openVariantQtyindex] = {
                ...updatedItems[openVariantQtyindex],
                pos_quantity: pos_quantity,
            };
        }
        setGroupVariantData(updatedItems);
        closeVariantQtyModal();
        setPos_Quantity([]);
    };

    return (
        <Dialog
            open={openVariantQty}
            TransitionComponent={Transition}
            keepMounted
            onClose={closeVariantQtyModal}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className='secondary-bg-color table-text w-full lg:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
                <div className='flex border-b main-border-color pb-3 items-center justify-between'>
                    <h6 className='font-medium text-xl'>{t('lang_Select_Location')}</h6>
                    <button className='close-btn' onClick={closeVariantQtyModal}><X className="w-4 h-4" /></button>
                </div>

                <div className='h-[450px] overflow-y-auto divide-y'>
                    {locationData?.length > 0 ? locationData.map((elem, index) => {
                        const value = pos_quantity.find(x => x?.pos_location === elem?._id)?.quantity || 0;

                        return (
                            <div key={elem?._id || index} className='flex items-center justify-between'>
                                <label htmlFor={`pos_quantity_${index}`}>
                                    {elem?.name}
                                </label>
                                <input
                                    className='flex items-end px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                    type='number'
                                    id={`pos_quantity_${index}`}
                                    name='pos_location'
                                    value={value === 0 ? '' : value} 
                                    onChange={(e) => handlePOSLocation(e, elem)} // Use onChange for live updates
                                    placeholder='e.g. 100'
                                />
                            </div>
                        );
                    })
                    :
                    <div className='w-full flex items-center justify-center border px-2 py-2'>
                        {t('lang_Data_not_found')}
                    </div>
                    }
                </div>
                <div className='flex justify-between pagination items-center flex-wrap'>
                    <button className={`flex px-5 py-2 btn rounded-s text-sm items-center text-white transition duration-300 ${pos_quantity?.length <= 0 && 'inline-block cursor-not-allowed opacity-50 bg-btn'}`} disabled={pos_quantity?.length <= 0} onClick={(e) => { e.preventDefault(); handleSubmit() }}>Add Location</button>
                </div>
            </div>
        </Dialog>
    )
}

export default SelectVariantQuantity;
