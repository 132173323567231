import { t } from 'i18next';
import React from 'react';

const SpacerSettingSection = ({
  section_id,
  handleSpacerWidthChange,
  spacerValue,
  saveElementorData,
  btnLoading,
}) => {
  const handleWidthChange = (e) => {
    const selectedWidth = e.target.value;
    handleSpacerWidthChange(section_id, selectedWidth);
  };

  return (
    <div>
      <div className='w-full mb-4'>
        <label>Width (0-600px)</label>
        <input
          type='range'
          min='0'
          max='600'
          value={spacerValue?.width || 100}
          onChange={handleWidthChange}
          className='w-full mt-2'
        />
        <div>{spacerValue?.width}px</div>
      </div>
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default SpacerSettingSection;
