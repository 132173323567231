import React from 'react';
import { Checkbox } from '@mui/material';
import { t } from 'i18next';
const EditProductTitle = ({ handleEditProductPageData, productPageData ,handleUpdateSetting}) => {
  return (
    <div>
      <div className='grid grid-cols-1 gap-5 w-full mb-4'>
        <label className='select-none cursor-pointer items-start text-sm table-text flex'>
          <Checkbox
            value={productPageData?.showProductName}
            checked={productPageData?.showProductName}
            onChange={() =>
              handleEditProductPageData({
                ...productPageData,
                showProductName: Boolean(!productPageData?.showProductName),
              })
            }
          />
          <div className='mt-2'> 
            <span className='font-medium'>{t('lang_Show_Product_Name')}</span>
            <p className='text-xs table-text'>{t('lang_Show_Product_Name_Details')}</p>
          </div>
        </label>
        {/* <div className='w-full text-sm'>
          <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
            {t('lang_Text_Size')}
          </label>
          <div className='grid grid-cols-3 w-full table-text primary-bg-color text-sm justify-between p-0.5 mt-2'>
            <div
              className={`${
                productPageData?.productTitleSize === 'small'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleSize: 'small' })
              }
            >
              {t('lang_Small')}
            </div>
            <div
              className={`${
                productPageData?.productTitleSize === 'medium'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleSize: 'medium' })
              }
            >
              {t('lang_Medium')}
            </div>
            <div
              className={`${
                productPageData?.productTitleSize === 'large'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleSize: 'large' })
              }
            >
              {t('lang_Large')}
            </div>
          </div>
        </div> */}
        <div className='w-full text-sm'>
          <label className='text-[13px] w-[120px] font-[500] table-text capitalize'>
            {t('lang_Text_Style')}
          </label>
          <div className='grid grid-cols-3 w-full table-text primary-bg-color text-sm justify-between p-0.5 mt-2'>
            <div
              className={`${
                productPageData?.productTitleStyle === 'normal'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleStyle: 'normal' })
              }
            >
              {t('lang_Normal')}
            </div>
            <div
              className={`${
                productPageData?.productTitleStyle === 'uppercase'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleStyle: 'uppercase' })
              }
            >
              {t('lang_Uppercase')}
            </div>
            <div
              className={`${
                productPageData?.productTitleStyle === 'capitalize'
                  ? 'secondary-bg-color rounded-[6px] cursor-pointer block text-xs font-medium w-full text-center py-1.5'
                  : 'cursor-pointer block w-full text-center py-1.5 text-xs'
              }`}
              onClick={() =>
                handleEditProductPageData({ ...productPageData, productTitleStyle: 'capitalize' })
              }
            >
              {t('lang_Capitalize')}
            </div>
          </div>
        </div>
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        <button
          type='button'
          className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
          onClick={handleUpdateSetting}
        >
          <span>{t('lang_Apply_Changes')}</span>
        </button>
      </div>
    </div>
  );
};

export default EditProductTitle;
