import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';

const ProductBulkEditPage = ({ bulkEditProductLoader, setBulkEditProductLoader }) => {
  const location = useLocation();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [productsData, setProductsData] = useState([]);
  const [editProductsData, setEditProductsData] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const navigate = useNavigate();
  const [isChange, setIsChange] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const ids = queryParams.getAll('id');
  const getProducts = async () => {
    if (ids.length > 0) {
      try {
        const payload = encryptData({ products: ids })
        const ApiCall = await API.post('admin/product/GetProductDataByIds', payload);
        if (ApiCall.status === 200 || data.status === 304) {
          const result = decryptData(ApiCall?.data)
          setProductsData(result.data);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };
  useEffect(() => {
    getProducts();
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const handleChange = (id, field, value) => {
    setIsChange(true);
    setProductsData(
      productsData?.map((item) => {
        if (item._id === id) {
          return { ...item, [field]: value };
        }
        return item;
      }),
    );
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'bulk edit products',
      });
    } else {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'bulk edit products',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/products',
        title: 'edit product',
      });
    } else {
      navigate('/products');
    }
  };
  const handleBulkEdit = async () => {
    try {
      setBulkEditProductLoader(true);
      const payload = encryptData(editProductsData)
      const ApiCall = await API.post('admin/product/BulkUpdateProduct', payload);
      if (ApiCall.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Products updated successfully!');
        setSeverity('success');
        getProducts();
        setEditProductsData([]);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setSaveChanges({
        backLink: '/products',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add product',
      });
      setBulkEditProductLoader(false);
    }
    return item;
  };
  const handleBlur = async (id, field, value) => {
    setEditProductsData((prevUpdatedValues) =>
      prevUpdatedValues.find((item) => item._id === id)
        ? prevUpdatedValues.map((item) =>
          item._id === id ? { ...item, data: { ...item.data, [field]: value } } : item,
        )
        : [...prevUpdatedValues, { _id: id, data: { [field]: value } }],
    );
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex flex-wrap items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_products'), path: '/products' },
                  { name: t('lang_Bulk_Edit_Products'), path: '/products/bulk-edit/products' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Bulk_Edit_Products')}</h4>
            </div>
          </div>
          <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
            <button
              className='flex btn rounded-s text-sm items-center font-medium  text-white transition duration-300 disabled:cursor-not-allowed'
              onClick={handleBulkEdit}
              disabled={bulkEditProductLoader}
            >
              {t('lang_Update_All_Products')}
            </button>
          </div>
        </div>
        <div className='w-full space-y-1.5'>
          <div className='flex w-full h-full overflow-hidden relative'>
            {productsData?.length > 0 ? (
              <div className='w-full'>
                <div style={{ overflowX: 'auto', maxHeight: '700px' }}>
                  <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
                    <thead className='text-xs text-gray-700 uppercase bg-gray-50'>
                      <tr className='w-full'>
                        <th className='min-w-[200px] w-full px-3 py-3 fixed-product-name'>
                          {t('lang_Product_Name')}
                        </th>
                        <th className='min-w-[200px] max-w-[200px] px-3 py-3'>
                          {t('lang_Product_Return_Policy')}
                        </th>
                        <th className='min-w-[150px] max-w-[150px] px-3 py-3'>{t('lang_stock_status')}</th>
                        <th className='min-w-[180px] max-w-[180px] px-3 py-3 uppercase'>{t('lang_mrp')}</th>
                        <th className='min-w-[180px] max-w-[180px] px-3 py-3'>{t('lang_price')}</th>
                        <th className='min-w-[150px] max-w-[150px] px-3 py-3 capitalize'>{t('lang_quantity')}</th>
                      </tr>
                    </thead>
                  </table>
                </div>
                <div style={{ overflowX: 'auto', maxHeight: '700px' }}>
                  <table className='w-full text-sm text-left rtl:text-right text-gray-500'>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {productsData?.length > 0 &&
                        productsData?.map((elem, index) => (
                          <tr key={index} className='odd:bg-white even:bg-gray-50 border-b'>
                            <td className='min-w-[200px] w-full px-3 py-4 fixed-product-name'>
                              <input
                                className='w-full border border-gray-700'
                                type='text'
                                name='product_name'
                                value={elem?.product_name}
                                onChange={(e) =>
                                  handleChange(elem?._id, 'product_name', e.target.value)
                                }
                                onBlur={(e) =>
                                  handleBlur(elem?._id, 'product_name', e.target.value)
                                }
                              />
                            </td>
                            <td className='min-w-[200px] max-w-[200px] px-3 py-4'>
                              <select
                                className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                                name='product_return_policy'
                                value={elem?.product_return_policy}
                                onChange={(e) =>
                                  handleChange(elem?._id, 'product_return_policy', e.target.value)
                                }
                                onBlur={(e) =>
                                  handleBlur(elem?._id, 'product_return_policy', e.target.value)
                                }
                              >
                                <option value='non_returnable'>{t('lang_non_returnable')}</option>
                                <option value='returnable'>{t('lang_returnable')}</option>
                              </select>
                            </td>
                            <td className='min-w-[150px] max-w-[150px] px-3 py-4'>
                              <select
                                className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                                name='stock_status'
                                value={elem?.stock_status}
                                onChange={(e) =>
                                  handleChange(elem?._id, 'stock_status', e.target.value)
                                }
                                onBlur={(e) =>
                                  handleBlur(elem?._id, 'stock_status', e.target.value)
                                }
                              >
                                <option value='in_stock'>{t('lang_in_stock')}</option>
                                <option value='out_of_stock'>{t('lang_out_of_stock')}</option>
                                <option value='available'>{t('lang_available')}</option>
                              </select>
                            </td>
                            <td className='min-w-[180px] max-w-[180px] px-3 py-4'>
                              <input
                                className='w-full border border-gray-700'
                                type='number'
                                name='mrp'
                                value={elem?.mrp}
                                onChange={(e) => handleChange(elem?._id, 'mrp', e.target.value)}
                                onBlur={(e) => handleBlur(elem?._id, 'mrp', e.target.value)}
                              />
                            </td>
                            <td className='min-w-[180px] max-w-[180px] px-3 py-4'>
                              <input
                                className='w-full border border-gray-700'
                                type='number'
                                name='selling_price'
                                value={elem?.selling_price}
                                onChange={(e) =>
                                  handleChange(elem?._id, 'selling_price', e.target.value)
                                }
                                onBlur={(e) =>
                                  handleBlur(elem?._id, 'selling_price', e.target.value)
                                }
                              />
                            </td>
                            <td className='min-w-[150px] max-w-[150px] px-3 py-4'>
                              <input
                                className='w-full border border-gray-700'
                                type='number'
                                name='quantity'
                                value={elem?.quantity}
                                onChange={(e) =>
                                  handleChange(elem?._id, 'quantity', e.target.value)
                                }
                                onBlur={(e) => handleBlur(elem?._id, 'quantity', e.target.value)}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>
        </div>
      </div>

      <style jsx>
        {`
          @media (max-width: 768px) {
            .fixed-product-name {
              position: sticky;
              left: 0;
              z-index: 1;
              background-color: white;
            }
          }
        `}
      </style>
    </>
  );
};

export default ProductBulkEditPage;
