import React, { useEffect, useState } from 'react';
import API from '../../API';
import { Edit, Eye, EyeOff, PlusCircle } from 'feather-icons-react/build/IconComponents';
import Trash2 from 'feather-icons-react/build/IconComponents/Trash2';
import { Dialog, Skeleton } from '@mui/material';
import { Transition } from '../../controllers/Transition';
import AddFooterSection from './VisualLayout/AddFooterSection';
import EditFooterSection from './VisualLayout/EditFooterSection';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import ReactDragListView from 'react-drag-listview';

const FooterSection = ({ isChangeFooter }) => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [pending, setPending] = useState(false);
  const [isFooterAddBlock, setIsFooterAddBlock] = useState(false);
  const [isFooterEditBlock, setIsFooterEditBlock] = useState(false);
  const [editId, setEditId] = useState();
  const [groupFooterData, setGroupFooterData] = useState();
  const [footerSectionId, setFooterSectionId] = useState(null);
  const [hoverId, setHoverId] = useState(null);

  const getFooterData = async () => {
    setPending(true);
    try {
      const data = await API.get(`/admin/footer/getFooterGroups`);
      if (data.status === 200 || data.status === 304) {
        const result = decryptData(data?.data);
        setGroupFooterData(result?.data);
        isChangeFooter(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setPending(false);
    }
  };
  useEffect(() => {
    getFooterData();
  }, []);
  const UpdateFooterStatus = async (row, value) => {
    try {
      const payload = encryptData({
        status: value,
      });
      const data = await API.put(`/admin/footer/updateFooterGroup/${row._id}`, payload);
      if (data.status === 200 || data.status === 304) {
        getFooterData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const closeAddBlock = (value) => {
    setIsFooterAddBlock(value);
  };
  const closeEditBlock = (value) => {
    setIsFooterEditBlock(value);
  };
  const handleDelete = async (id) => {
    try {
      const payload = encryptData({
        id: [id],
      });
      const data = await API.post(`/admin/footer/deleteFooterGroup`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Removed SuccesFully!');
        setSeverity('success');
        getFooterData();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const handleSort = (result) => {
  //   const items = Array.from(groupFooterData);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setGroupFooterData(items);
  //   isChangeFooter(items);
  //   sortFooter(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...groupFooterData];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setGroupFooterData(newItems);
      isChangeFooter(newItems);
      sortFooter(toIndex, footerSectionId);
      setFooterSectionId(null);
      setHoverId(null);
    },
    nodeSelector: '.draggable-item',
    handleSelector: '.draggable-item',
  };

  const sortFooter = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/footer/updateFooterGroup/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <>
      {pending && !groupFooterData ? (
        <div className='space-y-1.5'>
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
          <Skeleton className='min-h-[30px] max-h-[30px]' variant='rectangular' animation='wave' />
        </div>
      ) : (
        <>
          <div className='space-y-0.5  mt-2'>
            <ReactDragListView {...dragProps}>
              {groupFooterData?.map((elem, index) => {
                return (
                  <div
                    style={{
                      boxShadow: hoverId === index ? '6px 6px 35px 7px rgba(0,0,0,0.2)' : 'none',
                    }}
                    className={`draggable-item rounded-[6px] hover:primary-bg-color transition-all duration-150 group select-none px-[15px] py-[7px] text-[13px] headerMenu flex w-full items-center justify-between ${
                      hoverId === index ? 'primary-bg-color' : 'bg-transparent'
                    }`}
                    key={index}
                    onDragStart={() => setFooterSectionId(elem?._id)}
                    onDragOver={() => setHoverId(index)}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setHoverId(null);
                    }}
                  >
                    <div className='flex w-[75%] pe-2 items-center'>
                      <span className='text-[13px] table-text truncate w-full block leading-4 capitalize font-normal'>
                        {elem?.footer_section_title}
                      </span>
                    </div>
                    <div className='relative flex w-[25%] headerMenuBtn items-center justify-end cursor-pointer'>
                      {!elem?.store_information ? (
                        <>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setIsFooterEditBlock(true);
                              setEditId(elem?._id);
                            }}
                          >
                            <Edit className='w-4 h-4 me-1 table-text hover:text-black transition-all duration-150' />
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleDelete(elem?._id);
                            }}
                          >
                            <Trash2 className='w-4 h-4 me-1 table-text hover:text-black transition-all duration-150' />
                          </button>
                        </>
                      ) : (
                        <></>
                      )}
                      <div
                        className=''
                        onClick={() => {
                          UpdateFooterStatus(elem, !elem?.status);
                        }}
                      >
                        {elem.status ? (
                          <Eye className='w-4 h-4 table-text hover:text-black transition-all duration-150' />
                        ) : (
                          <EyeOff className='w-4 h-4 table-text hover:text-black transition-all duration-150' />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </ReactDragListView>
          </div>

          <div className='text-center mt-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                setIsFooterAddBlock(true);
              }}
              className='inline-flex items-center mx-auto text-link hover:underline transition-all duration-150 text-sm'
            >
              <PlusCircle className='w-4 h-4 me-1' /> {t('lang_Add_Block')}
            </button>
          </div>

          <Dialog
            open={isFooterAddBlock}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsFooterAddBlock(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            {isFooterAddBlock && (
              <AddFooterSection
                closeAddBlock={closeAddBlock}
                getFooterData={getFooterData}
                isChangeFooter={isChangeFooter}
              />
            )}
          </Dialog>
          <Dialog
            open={isFooterEditBlock}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setIsFooterEditBlock(false)}
            aria-describedby='alert-dialog-slide-description'
          >
            {isFooterEditBlock && (
              <EditFooterSection
                editBlockId={editId}
                closeEditBlock={closeEditBlock}
                getFooterData={getFooterData}
                isChangeFooter={isChangeFooter}
              />
            )}
          </Dialog>
        </>
      )}
    </>
  );
};

export default FooterSection;
