import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../../API';
import { ChangePathContext } from '../../../App';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import Notification from '../../Notification';
import { Autocomplete, TextField } from '@mui/material';
import generatePin from '../../../controllers/generatePin';
function AddPosStaff() {
    const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
    const navigate = useNavigate();
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [warn, setwarn] = useState(false);
    const [staffData, setStaffData] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        pin: '',
        pos_location: [],
    });
    const [validEmail, setValidEmail] = useState(false);
    const [validEmailErr, setValidEmailErr] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const HandleInput = (e) => {
        if(e.target.name === 'email') {
            setValidEmailErr(false);
        }
        setStaffData({ ...staffData, [e.target.name]: e.target.value });
    };
    const emailVerify = async () => {
        if (staffData?.email?.length >= 3) {
          try {
            const storeApiData = await API.get(`/super/admin/tenant/validemail/${staffData?.email}`);
            if (storeApiData.status === 200 || storeApiData.status === 304) {
              setValidEmail(true);
              setValidEmailErr(false);
            }
          } catch (error) {
            setIsNotification(true);
            setNotificationMsg(t('lang_Email_is_invalid'));
            setSeverity('error');
            setValidEmail(false);
            setValidEmailErr(true);
          }
        }
      };
    const [err, setErr] = useState();
    const [btnLoading, setBtnLoading] = useState(false);
    const getLocation = async () => {
        try {
            let ApiCall = await API.get(
                `/admin/pos/getPOSLocations`,
            );
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const result = decryptData(ApiCall?.data)
                setLocationData(result);
            }
        } catch (error) {
            if (error?.response?.status === 882) {
                setPlanPermission({ isPlanPermissions: false, forModule: 'pos' });
            }
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    };
    useEffect(() => {
        getLocation();
    }, [])
    const HandleSubmit = async (e) => {
        e.preventDefault();
        setBtnLoading(true);
        try {
            const payload = encryptData(staffData)
            await API.post('/admin/pos/addPOSstaff', payload);
            setIsNotification(true);
            setNotificationMsg("Staff Added Successfully!");
            setSeverity('success');
            setSaveChanges({
                isUnsaveChanges: false,
                showChangesPopup: false,
                backLink: '/pos-staff',
                title: 'add staff',
            });
            navigate('/pos-staff');
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
        setBtnLoading(false);
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };

    useEffect(() => {
        if (
            staffData?.email?.length > 0 ||
            staffData?.pin?.length > 0 ||
            staffData?.pos_location?.length > 0
        ) {
            setSaveChanges({
                backLink: '/pos-staff',
                isUnsaveChanges: true,
                showChangesPopup: false,
                title: 'add staff',
            });
        } else {
            setSaveChanges({
                backLink: '/pos-staff',
                isUnsaveChanges: false,
                showChangesPopup: false,
                title: 'add staff',
            });
        }
    }, [staffData]);
    const checkChanges = () => {
        if (
            staffData?.email?.length > 0 ||
            staffData?.pin?.length > 0 ||
            staffData?.pos_location?.length > 0
        ) {
            setSaveChanges({
                isUnsaveChanges: true,
                showChangesPopup: true,
                backLink: '/pos-staff',
                title: 'add staff',
            });
        } else {
            navigate('/pos-staff');
        }
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}

            <div className='h-auto overflow-y-auto pb-[130px]'>
                <div className='flex items-center space-x-2 pb-[30px]'>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            checkChanges();
                        }}
                        className='back-arrow'
                    >
                        <ArrowLeft />
                    </button>
                    <div>
                        <Breadcrumbs
                            crumbs={[
                                { name: t('lang_POS'), path: '/pos-staff' },
                                { name: t('lang_Add_Staff'), path: '/pos-staff/add-staff' },
                            ]}
                        />
                        <div className='flex items-end'>
                            <h4 className='text-xl md:text-2xl font-semibold text-primary '>
                                {t('lang_Add_Staff')}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <form
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
                    >
                        <div className='secondary-bg-color border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
                            <div className='mb-4'>
                                <div className='flex space-x-3 justify-between items-center mb-1'>
                                    <label htmlFor='first_name'>
                                        <p className='text-[13px]'>
                                            {t('lang_firstname')}
                                        </p>
                                    </label>
                                </div>
                                <input
                                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                                    type='text'
                                    id='first_name'
                                    name='first_name'
                                    value={staffData?.first_name}
                                    onChange={(e) => {
                                        HandleInput(e);
                                        setwarn(false);
                                    }}
                                    placeholder={t('lang_Write_Your_First_Name')}
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='flex space-x-3 justify-between items-center mb-1'>
                                    <label htmlFor='last_name'>
                                        <p className='text-[13px]'>
                                            {t('lang_lastname')}
                                        </p>
                                    </label>
                                </div>
                                <input
                                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                                    type='text'
                                    id='last_name'
                                    name='last_name'
                                    value={staffData?.last_name}
                                    onChange={(e) => {
                                        HandleInput(e);
                                        setwarn(false);
                                    }}
                                    placeholder={t('lang_Write_Your_Last_Name')}
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='flex space-x-3 justify-between items-center mb-1'>
                                    <label htmlFor='phone'>
                                        <p className='text-[13px]'>
                                            {t('lang_phone')}
                                        </p>
                                    </label>
                                </div>
                                <input
                                    className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                                    type='text'
                                    id='phone'
                                    name='phone'
                                    value={staffData?.phone}
                                    onChange={(e) => {
                                        HandleInput(e);
                                        setwarn(false);
                                    }}
                                    placeholder={t('lang_Write_Your_Phone')}
                                />
                            </div>
                            <div className='mb-4'>
                                <div className='flex space-x-3 justify-between items-center mb-1'>
                                    <label htmlFor='email'>
                                        <p className='text-[13px]'>
                                            {t('lang_email')}
                                            <span className='text-red-500 ms-1'>*</span>
                                        </p>
                                    </label>
                                </div>
                                <input
                                    className={
                                        err && !staffData?.email
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                            : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                    }
                                    type='text'
                                    id='email'
                                    name='email'
                                    value={staffData?.email}
                                    onChange={(e) => {
                                        HandleInput(e);
                                        setwarn(false);
                                    }}
                                    onBlur={emailVerify}
                                    placeholder={t('lang_Write_Your_Email')}
                                />
                                {err && !staffData?.email ? (
                                    <p className='text-red-500 text-xs mt-1'>{t('lang_Please_Enter_Email')}</p>
                                ) : validEmailErr ? 
                                    <p className='text-red-500 text-xs mt-1'>{t('lang_Email_is_invalid')}</p>
                                : (
                                    <></>
                                )}

                                {warn && (
                                    <p className='text-sm text-red-500'>
                                        {t('lang_Please_Enter_Email')}
                                    </p>
                                )}
                            </div>
                            <div className='mb-4'>
                                <div className='flex space-x-3 justify-between items-center mb-1'>
                                    <label htmlFor='pin' className='flex items-center justify-between space-x-3'>
                                        <p className='text-[13px]'>
                                            {t('lang_PIN')}
                                            <span className='text-red-500 ms-1'>*</span>
                                        </p>
                                        <button
                                            type='button'
                                            className='px-2 py-1 text-sm rounded-[6px] text-black font-medium bg-transparent border border-black hover:text-white hover:bg-black  transition-all duration-300'
                                            onClick={() => setStaffData({ ...staffData, pin: generatePin() })}
                                        >
                                            Generate PIN
                                        </button>
                                    </label>
                                </div>
                                <input
                                    className={
                                        err && staffData?.pin
                                            ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                                            : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                                    }
                                    type='number'
                                    id='pin'
                                    name='pin'
                                    value={staffData?.pin}
                                    onChange={(e) => {
                                        if (e.target.value?.length <= 4 || e.target.value?.length <= 6) {
                                            HandleInput(e);
                                            setwarn(false);
                                        }
                                    }}
                                    placeholder={t('lang_Write_Your_Pin')}
                                />
                                {err && !staffData?.email ? (
                                    <p className='text-red-500 text-xs'>{t('lang_Please_Enter_Your_Pin')}</p>
                                ) : (
                                    <></>
                                )}

                                {warn && (
                                    <p className='text-sm text-red-500'>
                                        {t('lang_Please_Enter_Your_Pin')}
                                    </p>
                                )}
                            </div>
                            <div className='mb-4'>
                                <label>{t('lang_Location')}<span className='text-red-500 ms-1'>*</span></label>
                                <Autocomplete
                                    className='autocomplete'
                                    multiple
                                    options={locationData || []}
                                    getOptionLabel={(x) => x?.name}
                                    onChange={(e, v) => setStaffData({ ...staffData, pos_location: v.map(location => location._id) })}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('lang_Select_Location')}
                                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                            className='cursor-pointer'
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
                    <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                                checkChanges();
                            }}
                            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                        >
                            <ArrowLeft className='w-3 mr-1' />
                            {t('lang_Go_To_POS')}
                        </Link>
                        <div>
                            {!staffData?.email || staffData?.pin?.length < 4 || staffData?.pos_location?.length <= 0 || !validEmail ? (
                                <button
                                    type='button'
                                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                                >
                                    <span>{t('lang_Add_Staff')}</span>
                                </button>
                            ) : btnLoading ? (
                                <button
                                    type='button'
                                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[131px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                                >
                                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                                    <span>{t('lang_loading')}...</span>
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => HandleSubmit(e)}
                                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                                >
                                    <span>{t('lang_Add_Staff')}</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddPosStaff;
