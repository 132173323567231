import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';

const ListItem = ({
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
}) => {

  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.plan}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='plan'>Plan</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='plan'
              name='plan'
              placeholder='Enter plan...'
              value={item?.plan || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='speed'>Speed</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='speed'
              name='speed'
              placeholder='Enter speed...'
              value={item?.speed || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='storage'>Storage</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='storage'
              name='storage'
              placeholder='Enter Storage...'
              value={item?.storage || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='price'>Price</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='price'
              name='price'
              placeholder='Enter price name...'
              value={item?.price || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const StaticSecondPricingSection = ({
  section_id,
  secondPricingValue,
  setSecondPricingValue,
  handleSecondPricingValue,
  tabIndex,
  setTabIndex,
  handleAddSecondPricingItem,
  handleCopySecondPricingItem,
  handleSecondPricingDataRemoveValue,
  saveElementorData,
  btnLoading,
  section_type,
  handleSecondPricingTagChange,
  sectionContent,
  headingLevel,
  handleSecondPricingTitleValueChange,
  handleSecondPricingDescriptionChange,
  descriptionContent,
  linkContent,
  handleSecondPricingLinkValueChange,
  linkUrlContent,
  handleSecondPricingLinkUrlValueChange,
  buttonContent,
  handleSecondPricingButtonValueChange,
  buttonUrlContent,
  handleSecondPricingButtonUrlValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [linkValue, setLinkValue] = useState(linkContent);
  const [buttonValue, setButtonValue] = useState(buttonContent);
  const [buttonUrlValue, setButtonUrlValue] = useState(buttonUrlContent);
  const [linkUrlValue, setLinkUrlValue] = useState(linkUrlContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');

  const ShortquillRef = useRef(null);

  const [shortdescription, setShortDescription] = useState(descriptionContent);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleSecondPricingTitleValueChange(section_id, value);
  };

  useEffect(() => {
    setGroupTitle(sectionContent);
    setShortDescription(descriptionContent);
    setSelectedHeadingLevel(headingLevel);
    setLinkValue(linkContent);
    setLinkUrlValue(linkUrlContent);
    setButtonValue(buttonContent);
    setButtonUrlValue(buttonUrlContent);
  }, [
    sectionContent,
    descriptionContent,
    headingLevel,
    linkContent,
    linkUrlContent,
    buttonContent,
    buttonUrlContent,
  ]);

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...secondPricingValue,
      item: secondPricingValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setSecondPricingValue(updatedfirstContentValue);
    handleSecondPricingValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = secondPricingValue?.item?.length
      ? secondPricingValue?.item?.[secondPricingValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      plan: 'Start',
      htmlTag: 'h3',
      price: 'Free',
      speed: '5 Mb/s',
      storage: '15GB',
      isEdit: false,
    };
    const updatedItems = secondPricingValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddSecondPricingItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopySecondPricingItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleSecondPricingDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...secondPricingValue,
      item: secondPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setSecondPricingValue(updatedfirstContentValue);
    handleSecondPricingValue(section_id, updatedfirstContentValue);
  };

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...secondPricingValue,
      item: secondPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };
    setSecondPricingValue(updatedfirstContentValue);
    handleSecondPricingValue(section_id, updatedfirstContentValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleSecondPricingTagChange(section_id, newTag);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleSecondPricingTitleValueChange(section_id, newValue);
  };

  const linkContentChange = (e) => {
    const newValue = e.target.value;
    setLinkValue(newValue);
    handleSecondPricingLinkValueChange(section_id, newValue);
  };

  const buttonContentChange = (e) => {
    const newValue = e.target.value;
    setButtonValue(newValue);
    handleSecondPricingButtonValueChange(section_id, newValue);
  };

  const buttonUrlContentChange = (e) => {
    const newValue = e.target.value;
    setButtonUrlValue(newValue);
    handleSecondPricingButtonUrlValueChange(section_id, newValue);
  };

  const linkUrlContentChange = (e) => {
    const newValue = e.target.value;
    setLinkUrlValue(newValue);
    handleSecondPricingLinkUrlValueChange(section_id, newValue);
  };

  const handleShortDescQuillChange = (value) => {
    setShortDescription(value);
    handleSecondPricingDescriptionChange(section_id, value);
  };

  const handleBgFitChange = (e) => {
    const backgroundFit = e.target.value;
    const updatedfirstContentValue = {
      ...secondPricingValue,
      item: secondPricingValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, objectFit: backgroundFit } : item,
      ),
    };

    setSecondPricingValue(updatedfirstContentValue);
    handleSecondPricingValue(section_id, updatedfirstContentValue);
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div>
        <label>Description</label>
        <ReactQuill
          modules={module}
          ref={ShortquillRef}
          className='w-full h-auto table-text rounded-[6px] mt-2'
          theme='snow'
          name='description'
          value={shortdescription}
          onChange={handleShortDescQuillChange}
          placeholder='Enter description'
          onBlur={() => {
            setMeta((prevMeta) => ({
              ...prevMeta,
              meta_description:
                shortdescription
                  ?.replace(/<[^>]*>/g, ' ')
                  ?.split(' ')
                  .slice(0, 50)
                  .join(' ') || '',
            }));
          }}
        />
      </div>
      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(secondPricingValue?.item) &&
          secondPricingValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              handleBgFitChange={handleBgFitChange}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mb-4 mt-4'>
        <label>Link</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={linkValue}
          onChange={linkContentChange}
        />
      </div>
      <div className='mb-4 mt-4'>
        <label>Link URL</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={linkUrlValue}
          onChange={linkUrlContentChange}
        />
      </div>

      <div className='mb-4 mt-4'>
        <label>Button</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={buttonValue}
          onChange={buttonContentChange}
        />
      </div>
      <div className='mb-4 mt-4'>
        <label>Button URL</label>
        <input
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={buttonUrlValue}
          onChange={buttonUrlContentChange}
        />
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticSecondPricingSection;
