import { Dialog, fabClasses, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Check,
  ChevronDown,
  Eye,
  Filter,
  PlusCircle,
  X,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { CalendarFill, CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FileDownloader } from '../../controllers/FileDownload';
import { FormatAmount } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import NoRecords from '../Main/NoRecords';

const ViewOrders = ({ permissionCheck }) => {
  const { setPlanPermission } = useContext(PlanPermission);
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const originalDate = new Date();
  const location = useLocation();
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState();
  const initialLimit = localStorage.getItem('limit');
  const token = localStorage.getItem('token');
  const [limit, setLimit] = useState(initialLimit);
  const [page, setPage] = useState(getInitialPage());
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
  const [OrderType, setOrderType] = useState('');
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [isNotification, setIsNotification] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('')
  const [severity, setSeverity] = useState()
  var startRecord = (orderDetails?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    orderDetails?.data?.currentPage * limit,
    orderDetails?.data?.totalRecords,
  );
  const orderStatus = [
    //commented Status Are Our Old Status
    t('lang_return'),   // return
    `${t("lang_return")} ${t("lang_declined")}`,   //return declined
    `${t("lang_order")} ${t("lang_placed")}`, // 'order placed',
    t('lang_in_process'), //in process
    t('lang_ready_to_ship'),
    t('lang_refunded'),   // 'refunded',
    t('lang_out_for_pickup'),
    t('lang_in_transit'),
    t('lang_reached_at_destination_hub'),
    t('lang_out_for_delivery'),
    t('lang_shipped'), // 'shipped',
    t('lang_delivered'), // 'delivered',
    t('lang_payment_pending'), // 'payment pending',
    t('lang_payment_failed'), // 'payment failed',
    t('lang_canceled'), // 'canceled',
    t('lang_denied'), // 'denied',
  ];
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetOrders();
  };

  // sort by status
  const [orderStatusVal, setOrderStatusVal] = useState(location?.state?.orderStatusVal || '');
  const [startDate, setStartDate] = useState(new Date(originalDate.getFullYear(), originalDate.getMonth() - 1, originalDate.getDate()));
  const [endDate, setEndDate] = useState(new Date());
  const GetOrders = async () => {
    setDataLoading(true)
    try {
      let ApiCall = await API.get(
        `/admin/order/GetOrdersTable?page=${page}&limit=${limit}&status=${orderStatusVal.toLowerCase()}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}&order_type=${OrderType}&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).format("YYYY-MM-DD")}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setOrderDetails(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(page - 1)
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'Orders' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setDataLoading(false);
    }
  };
  const [orderStatusCount, setOrderStatusCount] = useState()
  const getOrdersStatusWithCount = async () => {
    try {
      let ApiCall = await API.get(`/admin/order/GetOrdersStatusWithCount?&startdate=${moment(startDate).format("YYYY-MM-DD")}&enddate=${moment(endDate).format("YYYY-MM-DD")}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setOrderStatusCount(result?.data);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'orders' })
      }
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  const [showProduct, setShowProduct] = useState(false);
  const [selectedId, setselectedId] = useState();
  const rowData = orderDetails?.data?.records;
  const columns = [
    {
      name: (
        <div className='flex items-center'>
          <button
            className={`uppercase flex ${iconVisible && sortBy === 'order_id' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('order_id') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('order_id')}
          >
            {t("lang_order")}
            {iconVisible && sortBy === 'order_id' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='ms-2' />
              ) : (
                <CaretDownFill className='ms-2' />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
      ),
      minWidth: '150px',
      cell: (row) => <span>#{row.order_id || '-'}</span>,
    },
    {
      name: (
        <div className='flex items-center'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'user.name' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('user.name') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('user.name')}>
            {t("lang_customer")}
            {iconVisible && sortBy === 'user.name' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='ms-2' />
              ) : (
                <CaretDownFill className='ms-2' />
              )
            ) : (
              <></>
            )}
          </button>
        </div>
      ),
      minWidth: '200px',
      cell: (row) => <span className='capitalize'>{row.user?.name || row?.guest_user?.name}</span>,
    },
    {
      name: (
        <div className='flex w-full items-center justify-end'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'createdAt' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('createdAt') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('createdAt')}>
            {iconVisible && sortBy === 'createdAt' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='me-2' />
              ) : (
                <CaretDownFill className='me-2' />
              )
            ) : (
              <></>
            )}
            {t("lang_date")}
          </button>
        </div>
      ),
      minWidth: '150px',
      cell: (row) => <div className='flex w-full items-center justify-end'>{moment(row?.createdAt).format('DD-MM-YYYY')}</div>,
    },
    {
      name: (
        <div className='flex items-center w-full justify-end pe-3'>
          <button className={`uppercase flex ${iconVisible && sortBy === 'total_price' ? "text-gray-700" : ""}`} onMouseEnter={() => { setIconVisible(true); setSortBy('total_price') }} onMouseLeave={() => { setIconVisible(false); setSortBy('') }} onClick={() => SortData('total_price')}>
            {iconVisible && sortBy === 'total_price' ? (
              !orderBy.isDecending ? (
                <CaretUpFill className='me-2' />
              ) : (
                <CaretDownFill className='me-2' />
              )
            ) : (
              <></>
            )}
            {t("lang_price")}
          </button>
        </div>
      ),
      minWidth: '150px',
      cell: (row) => (
        <span className='w-full text-right block pe-3'>
          {FormatAmount(row?.total_price)}
        </span>
      ),
    },
    {
      name: <div className='w-full text-right block pe-2'>{t("lang_items")}</div>,
      cell: (row, index) => (
        <div
          className='flex items-center justify-end w-full cursor-pointer select-none'
        >
          <button aria-describedby={row?._id} variant="contained" className='flex items-center' onClick={() => { setShowProduct(!showProduct); setselectedId(row._id) }}>
            {`${row?.products?.length} items`} <ChevronDown className='w-3 h-3 ms-1 mt-1' />
          </button>
          {
            row?._id === selectedId ?
              <Dialog
                open={showProduct}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowProduct(false)}
                aria-describedby="alert-dialog-slide-description"
              >
                <div className=' px-5 pb-5 pt-2.5 w-[400px]'>
                  <div className='flex items-center font-medium justify-between'>
                    <h6 className='capitalize'>{`${row.user?.name || row?.guest_user?.name || ''} - `}{t("lang_orders")}</h6>
                    <button className='close-btn' onClick={(e) => { e.preventDefault(); setShowProduct(false) }}>
                      <X className="w-4 h-4 cursor-pointer" />
                    </button>
                  </div>
                  <hr className='my-3' />
                  <div className='divide-y space-y-2 max-h-[550px] overflow-auto'>
                    {row?.products?.map((elem, index) => {
                      return (
                        <div key={index} className='flex w-full pt-2 max-h-20'>
                          <div className='min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] overflow-hidden flex items-center justify-center border border-gray-300'>
                            <img
                              onError={(e) => { e.target.src = NOPHOTO_IMAGE }} src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full mx-auto block max-h-full object-contain'
                              alt={elem?.name}
                            />
                          </div>
                          <div className='flex items-start ps-2 w-full justify-between'>
                            <div className=''>
                              <p className='text-sm line-clamp-2'>{elem?.name}</p>
                              <p className='text-xs mt-0.5 text-gray-500'>{`Total: ${FormatAmount(elem?.product_total)}`}</p>
                            </div>
                            <p className='flex px-2 items-center justify-end text-xs min-w-[55px]'>
                              {FormatAmount(elem?.selling_price)}
                              <X className='w-3 h-3 mx-0.5' />
                              {elem?.quantity}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Dialog> : <></>
          }
        </div>
      ),
      minWidth: '150px',
    },
    {
      name: <div className='w-full text-right block pe-2'>{t("lang_status")}</div>,
      minWidth: '180px',
      cell: (row) => (
        <div className='w-full text-right text-xs whitespace-nowrap'>
          {row.status === 'payment failed' ||
            row.status === 'denied' ||
            row.status === 'return declined' ? (
            <span className='status-bedge text-red-500 bg-red-100'>
              {row.status}
            </span>
          ) : row.status === 'canceled' ? (
            <>
              <span className='status-bedge text-gray-500 bg-gray-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'refunded' ||
            row.status === 'order placed' ? (
            <>
              <span className='status-bedge text-sky-500 bg-sky-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'delivered' ? (
            <>
              <span className='status-bedge text-green-500 bg-green-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'in process' ||
            row.status === 'in transit' ? (
            <>
              <span className='status-bedge text-purple-500 bg-purple-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'ready to ship' ||
            row.status === 'reached at destination hub' ||
            row.status === 'out for pickup' ||
            row.status === 'out for delivery' ? (
            <>
              <span className='status-bedge text-blue-500 bg-blue-100'>
                {row.status}
              </span>
            </>
          ) : row.status === 'shipped' ? (
            <>
              <span className='status-bedge text-orange-500 bg-orange-100'>
                {row.status}
              </span>
            </>
          ) : (
            <>
              <span className='status-bedge text-yellow-500 bg-yellow-100'>
                {row.status}
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      name: <div className='w-full text-right block'>{t("lang_action")}</div>,
      minWidth: '80px',
      cell: (row) => (
        <div className='flex items-center justify-end w-full space-x-3 text-sm'>
          {row.status === 'canceled' || row.status === 'denied' || row.status === 'delivered' ? (
            <div>
              <Tooltip title={t('lang_view')} arrow>
                <button className=''>
                  <Eye
                    className='cursor-pointer w-[17px] h-[17px]'
                    onClick={() => {
                      ViewOrderDetail(row);
                    }}
                  />
                </button>
              </Tooltip>
            </div>
          ) : (
            <>
              {row.status === 'in process' ? (
                <>
                  <div className='flex space-x-3'>
                    <div>
                      <Tooltip title={t('lang_view')} arrow>
                        <button className=''>
                          <Eye
                            className='cursor-pointer w-[17px] h-[17px]'
                            onClick={() => {
                              ViewOrderDetail(row);
                            }}
                          />
                        </button>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip title={t('lang_decline')} arrow>
                        <button className=''>
                          <X
                            className='cursor-pointer w-[17px] h-[17px] text-red-500'
                            onClick={() => {
                              setIsRemoveOrder(true)
                              setRemoveOrderData({ id: row._id, payment_method_name: row?.payment_method_name, total_price: row?.total_price })
                              setConfirmationPopup(true);
                            }}
                          />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              ) : row.status === 'order placed' ? (
                <div className='flex space-x-3'>
                  <div>
                    <Tooltip title={t('lang_view')} arrow>
                      <button className=''>
                        <Eye
                          className='cursor-pointer w-[17px] h-[17px]'
                          onClick={() => {
                            ViewOrderDetail(row);
                          }}
                        />
                      </button>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title={t('lang_accept')} arrow>
                      <button className=''>
                        <Check
                          className='cursor-pointer text-green-500 w-[17px] h-[17px]'
                          onClick={(e) => { e.preventDefault(); AcceptOrder(row?._id) }}
                        />
                      </button>
                    </Tooltip>
                  </div>
                  <div>
                    <Tooltip title={t('lang_decline')} arrow>
                      <button className=''>
                        <X
                          className='cursor-pointer w-[17px] h-[17px] text-red-500'
                          onClick={() => {
                            setIsRemoveOrder(true)
                            setRemoveOrderData({ id: row._id, payment_method_name: row?.payment_method_name, total_price: row?.total_price })
                            setConfirmationPopup(true);
                          }}
                        />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div>
                  <Tooltip title={t('lang_view')} arrow>
                    <button className=''>
                      <Eye
                        className='cursor-pointer w-[17px] h-[17px]'
                        onClick={() => {
                          ViewOrderDetail(row);
                        }}
                      />
                    </button>
                  </Tooltip>
                </div>
              )}
            </>
          )}
        </div>
      ),
    },
  ];
  useEffect(() => {
    GetOrders();
    getOrdersStatusWithCount();
    setClearRow(!clearRow)
  }, [orderStatusVal, page, limit, startDate, endDate, OrderType]);
  const ViewOrderDetail = (elem) => {
    navigate(`/order/${elem._id}`);
  };
  const handleRowClick = (row) => {
    navigate(`/order/${row._id}`);
  };
  // Accept Order
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [isRemoveOrder, setIsRemoveOrder] = useState(false);
  const [removeOrderData, setRemoveOrderData] = useState();
  const [status, setStatus] = useState();
  const AcceptOrder = async (order_id) => {
    try {
      const payload = encryptData({
        status: 'in process',
      })
      let Apicall = await API.put(`/admin/order/updateOrder/${order_id}`, payload);
      if (Apicall.status === 200 || Apicall.status === 304) {
        GetOrders();
        setIsNotification(true)
        setNotificationMsg(`${t("lang_order")} ${t("lang_accepted")} ${t("lang_successfully!")}`)
        setSeverity('success')
        getOrdersStatusWithCount();
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value)
  }
  const handleClose = () => {
    setIsNotification(false)
    setNotificationMsg('')
    setSeverity('')
  }

  // Handle Multiple
  const UpdateMultiple = async () => {
    try {
      const payload = encryptData({
        orders: selectedRowsId,
        status: orderStatusVal,
        change_status: status,
      })
      await API.put(`/admin/order/updateMultiOrderStatus`, payload);
      GetOrders();
      setIsNotification(true)
      setNotificationMsg(`${t("lang_order")} ${status} ${t("lang_successfully!")}`)
      setSeverity("success")
      getOrdersStatusWithCount();
      setSelectedRowsId([])
      setClearRow(!clearRow)
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    };
    setConfirmationPopup(false);
    setStatus()
  }
  // Cancel Order
  const DeclineOrder = async () => {
    try {
      const payload = encryptData({
        status: 'denied',
      })
      let ApiCall = await API.put(`admin/order/updateOrder/${removeOrderData.id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status == 304) {
        if (token) {
          removeOrderData.payment_method_name === 'Razorpay' && (await RefundOrder(removeOrderData.id, removeOrderData.total_price));
        }
        setIsNotification(true)
        setNotificationMsg(`${t("lang_order")} ${t("lang_declined")} ${t("lang_successfully!")}`)
        setSeverity('success')
        getOrdersStatusWithCount();
        GetOrders();
      }
      setConfirmationPopup(false);
      setIsRemoveOrder(false);
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setConfirmationPopup(false);
      setIsRemoveOrder(false);
    }
  };

  // Refund If Razorpay
  const RefundOrder = async (id, price) => {
    try {
      const payload = encryptData({ return: id, total_price: price })
      await API.post('/admin/payment/refund', payload);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);

  useEffect(() => {
    setClearRow(false)
  }, [orderStatusVal])
  const renderButton = (status, index) => {
    // const count = orderStatusCount[status];

    const formattedCount = orderStatusCount[status].toString().padStart(2, '0');
    const count = formattedCount;


    switch (status) {
      case "Delivered":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('delivered'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "delivered" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_delivered")} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "delivered" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "In Process":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('in process'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "in process" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_in_process")} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "in process" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "Ready to ship":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('ready to ship'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "ready to ship" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_ready_to_ship")} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "ready to ship" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "Shipped":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('shipped'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "shipped" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_shipped")} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "shipped" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "Pending":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('order placed'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "order placed" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_order_placed")} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "order placed" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "Denied":
        return (
          <button key={index} onClick={() => { setOrderStatusVal('denied'); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "denied" ? "focus-tab" : "bg-transparent"}`}>
            {t('lang_denied')} <span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "denied" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      case "All":
        return (
          <button key={index} onClick={() => { setOrderStatusVal(''); setPage(1); setSelectedRowsId([]); setClearRow(true) }} className={`tab ${orderStatusVal.toLowerCase() === "" ? "focus-tab" : "bg-transparent"}`}>
            {t("lang_all")}<span className={`p-1 text-[11px] rounded-full ml-1 font-medium ${orderStatusVal.toLowerCase() === "" ? "secondary-bg-color" : "primary-bg-color"}`}>{count}</span>
          </button>
        );
      default:
        return null;
    }
  };
  const [clearRow, setClearRow] = useState(false)
  const handleGenerateShipment = async (key) => {
    try {
      const payload = encryptData({
        orders: selectedRowsId
      })
      if (key === 'label') {
        const apiCall = await API.post(`/admin/shipping/shipmentGeneratelable`, payload)
        const result = decryptData(apiCall?.data)
        if (result?.data?.label_url) {
          FileDownloader(result?.data?.label_url, 'Shipment_label');
        }
      } else {
        const apiCall = await API.post(`/admin/shipping/shipmentGenerateManifest`, payload)
        const result = decryptData(apiCall?.data)
        if (result?.data?.manifest_url) {
          FileDownloader(result?.data?.manifest_url, 'Shipment_manifest');
        }
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    }
  }
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])

  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      {isNotification && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {confirmationPopup &&
        <ConfirmationPopup
          heading={isRemoveOrder ? `${t("lang_remove")} ${t("lang_order")}` : `${t("lang_change")} ${t("lang_order")} ${t("lang_status")}`}
          subheading={isRemoveOrder ? `${t("lang_do_you_want_to")} ${t("lang_denied")} ${t("lang_this")} ${t("lang_order")}?` : `${t("lang_do_you_want_to")} ${status} ${t("lang_this")} ${t("lang_order")}?`}
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={isRemoveOrder ? DeclineOrder : UpdateMultiple}
        />}
      <div className='h-auto overflow-hidden pb-[65px]'>
        <div className='flex items-center justify-between space-x-2 pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_orders'), path: '/orders' }
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t("lang_orders")} </h4>
            </div>
          </div>
          {permissionCheck ?
            <button className='inline-block btn text-white text-[14px] font-medium rounded-[4px] transition duration-300'
              onClick={() => navigate('/order/create-order')}>
              <div className='flex items-center justify-between'>
                <PlusCircle className='h-4 w-4 me-2 hover:text-white' />
                {`${t("lang_add_new", { module: 'Orders' })}`}
              </div>
            </button>
            : <></>}
        </div>
        <div className='h-full w-full'>
          <div className='relative'>
            <div className='w-full h-full rounded-[6px]'>
              {orderDetails?.data?.totalRecords > 0 && <div className='flex flex-wrap items-center justify-between'>
                <div>
                  <div class="flex flex-wrap mb-[10px] w-fit gap-1 text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1">
                    {
                      orderStatusCount &&
                      Object.keys(orderStatusCount).map((status, index) => renderButton(status, index))
                    }
                  </div>
                </div>
                <div className='flex mb-[10px] flex-wrap items-center'>
                  {orderDetails?.data?.totalRecords > 0 && <div>
                    {
                      selectedRowsId?.length > 0 &&
                      <div className='relative'>
                        {
                          orderStatusVal !== '' && orderStatusVal !== 'delivered' &&
                          <>
                            <span className='text-sm px-3'>{selectedRowsId?.length} {t("lang_selected")}</span>
                            <span className='me-2'>|</span>
                          </>
                        }
                        {
                          orderStatusVal === "order placed" ?
                            <button className='bulk-btn mx-1 inline-flex items-center' onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('in process'); setIsRemoveOrder(false) }} disabled={!selectedRowsId?.length}>{`${t("lang_accept")} ${t("lang_selection")}`}</button> :
                            orderStatusVal === "in process" ?
                              <button className='bulk-btn mx-1 inline-flex items-center' onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('ready to ship'); setIsRemoveOrder(false) }} disabled={!selectedRowsId?.length}>{`${t("lang_ready_to_ship")} ${t("lang_selection")}`}</button> :
                              orderStatusVal === "ready to ship" ?
                                <button className='bulk-btn mx-1 inline-flex items-center' onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('shipped'); setIsRemoveOrder(false) }} disabled={!selectedRowsId?.length}>{`${t("Shipped")} ${t("lang_selection")}`}</button> :
                                orderStatusVal === "shipped" ?
                                  <button className='bulk-btn mx-1 inline-flex items-center' onClick={(e) => { e.preventDefault(); setConfirmationPopup(true); setStatus('delivered'); setIsRemoveOrder(false) }} disabled={!selectedRowsId?.length}>{`${t("lang_complete")} ${t("lang_selection")}`}</button> : <></>
                        }
                        {
                          orderStatusVal === "ready to ship" &&
                          <>
                            <button className='bulk-btn mx-1 inline-flex items-center' onClick={() => handleGenerateShipment('label')}>{`${t("lang_generate")} ${t("lang_lable")}`}</button>
                            <button className='bulk-btn mx-1 inline-flex items-center' onClick={() => handleGenerateShipment('manifest')}>{`${t("lang_generate")} ${t("lang_manifest")}`}</button>
                          </>
                        }
                      </div>
                    }
                  </div>}
                  {orderDetails?.data?.totalRecords > 0 && <Tooltip title={t('lang_filter')} arrow>
                    <div
                      className='filter-btn'
                      onClick={(e) => { setFilter(true); setAnchorEl(e.currentTarget) }}>                    
                        <button className='flex items-center'>
                          <Filter />
                        </button>
                    </div>
                  </Tooltip>}
                </div>
              </div>}
            </div>
            <div className={'flex h-auto relative'}>
              <div className='w-full h-auto block'>
                {
                  dataLoading && !orderDetails ?
                    <TableSkeleton forTable="order" /> :
                    <div className='border w-full transition-all duration-100 mb-[10px] main-border-color rounded-[6px] overflow-y-hidden '>
                      <DataTable
                        className='order-table'
                        columns={columns}
                        data={rowData}
                        selectableRowsHighlight
                        selectableRows
                        onRowClicked={handleRowClick}
                        highlightOnHover
                        onSelectedRowsChange={({ selectedRows }) => {
                          setSelectedRowsId([...new Set(selectedRows?.map((x) => x._id))]);
                        }}
                        clearSelectedRows={clearRow}
                      />
                    </div>
                }
                {/* Paginator */}
                {orderDetails?.data ? (
                  <div className='bg-white border border-gray-300 flex flex-wrap p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {
                        limit === "all" ? `${orderDetails?.data?.totalRecords}` + " " + t("lang_records") :
                          `${startRecord} - ${endRecord} of ${orderDetails?.data?.totalRecords} ${t("lang_records")}`
                      }
                    </span>
                    <div>
                      {orderDetails?.data?.totalPages > 1 && (
                        <Pagination
                          count={orderDetails?.data?.totalPages}
                          page={page}
                          onChange={(e, v) => setPage(v)}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                <p className='flex font-semibold uppercase text-xs m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t("lang_filter")}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5 max-w-full'>
                  <div>
                    <div className='grid grid-cols-1 xl:grid-cols-2 gap-2'>
                      <div className='w-full'>
                        <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>{t("lang_from")}</span>
                        <label className='relative w-full block cursor-pointer'>
                          <DatePicker
                            className={`${startDate && "bold-date"} border table-text cursor-pointer w-full focus:border-gray-400 border-gray-300 outline-none`}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                          />
                          <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className=' table-text w-3 h-3' /></span>
                        </label>
                      </div>
                      <div className='w-full'>
                        <span className='text-sm font-medium mb-1 table-text inline-block capitalize'>{t("lang_to")}</span>
                        <label className='relative block w-full cursor-pointer'>
                          <DatePicker
                            className={`${endDate && "bold-date"} border cursor-pointer table-text w-full focus:border-gray-400 border-gray-300 outline-none`}
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                          />
                          <span className='absolute top-[50%] z-50 cursor-pointer -translate-y-[50%] inline-block right-5'><CalendarFill className=' table-text w-3 h-3' /></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {
                    ['order placed', 'in process', 'ready to ship', 'shipped', 'delivered'].every(value => value !== orderStatusVal) &&
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                        {`${t("lang_sort_by")} ${t("lang_status")}`}
                      </span>
                      <div>
                        {
                          <div className='relative'>
                            <select
                              className='outline-none table-text rounded-[6px] w-full font-medium text-sm border border-gray-300 p-1.5'
                              onChange={(e) => {
                                setOrderStatusVal(e.target.value);
                                ; setPage(1)
                              }}
                              name='order_status'
                              value={orderStatusVal}
                            >
                              <option value='' className='table-text capitalize'>{t("lang_all")}</option>
                              {orderStatus.map((elem, index) => {
                                if (orderStatusVal === 'All') {
                                  setOrderStatusVal('');
                                }
                                return (
                                  elem === 'payment failed' ? (
                                    <option key={index} className='table-text'>{t("lang_payment_failed")}</option>
                                  ) : elem === 'return declined' ? (
                                    <option key={index} className='table-text'>{`${t("lang_return")} ${t("lang_declined")}`}</option>
                                  ) : elem === 'payment pending' ? (
                                    <option key={index} className='table-text'>{t("lang_payment_pending")}</option>
                                  ) : (
                                    <option key={index} className='table-text'>{elem}</option>
                                  )
                                );
                              })}
                            </select>
                            <div className='select-arrow'></div>
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t("lang_set_items_limit")}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none table-text rounded-[6px] w-full font-medium text-sm border border-gray-300 p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          setPage(1)
                        }}
                      >
                        {orderDetails?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10' className='table-text'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {orderDetails?.data?.totalRecords > 20 ? (
                          <option value='20' className='table-text'>20</option>
                        ) : (
                          <></>
                        )}
                        {orderDetails?.data?.totalRecords > 50 ? (
                          <option value='50' className='table-text'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all' className='table-text'>{t("lang_all")}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {`${t("orders")} ${t("lang_type")}`}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none  table-text rounded-[6px] w-full font-medium text-sm border border-gray-300 p-1.5'
                        name='limit'
                        value={OrderType}
                        onChange={(e) => { setOrderType(e.target.value); setPage(1) }}
                      >
                        <option value='' className='table-text capitalize'>{t("lang_all")}</option>
                        <option value='1' className='table-text capitalize'>{`${t("registered")} ${t("lang_users")}`}</option>
                        <option value='2' className='table-text'>{`${t("lang_guest")} ${t("lang_users")}`}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrders;
