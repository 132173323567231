import React from 'react';
import EditIconListStyleContainer from './EditIconListStyleContainer';

const EditIconListStyleSection = ({
  previewImage,
  iconListDesign,
  setIconListDesign,
  section_id,
  updateSectionStyle,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditIconListStyleContainer
        iconListDesign={iconListDesign}
        setIconListDesign={setIconListDesign}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditIconListStyleSection;
