import React, { useEffect, useState } from 'react';
import Notification from '../Notification';
import { Autocomplete, Box, TextField } from '@mui/material';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import { ChevronDown, X } from 'feather-icons-react/build/IconComponents';
import API from '../../API';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const CoutryAllowOrder = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [sellingLocationType, setSellingLocationType] = useState('');
  const [shippingLocationType, setShippingLocationType] = useState('');
  const [selectedSellingCountries, setSelectedSellingCountries] = useState([]);
  const [selectedShippingCountries, setSelectedShippingCountries] = useState([]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getCountryAllowed = async () => {
    try {
      const ApiCall = await API.get('/admin/client/getClient');
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        setSelectedSellingCountries(result?.data?.selling_locations);
        setSelectedShippingCountries(result?.data?.shipping_locations);
        setSellingLocationType(result?.data?.selling_location_type);
        setShippingLocationType(result?.data?.shipping_location_type);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getCountryAllowed();
  }, []);
  const filterOptionsForContry = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.code?.toLowerCase()} ${option?.dial_code?.toLowerCase()} ${option?.name?.toLowerCase()}}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  const handleSpecificSellingCountries = (name) => {
    if (name) {
      const cat = selectedSellingCountries.find((x) => x === name);
      if (!cat) {
        setSelectedSellingCountries([...selectedSellingCountries, name]);
      }
    }
  };
  const removeSellingCountries = (name) => {
    const removeRelated = selectedSellingCountries.filter((x) => x !== name);
    setSelectedSellingCountries(removeRelated);
  };
  const handleSpecificShippingCountries = (name) => {
    if (name) {
      const cat = selectedShippingCountries.find((x) => x === name);
      if (!cat) {
        setSelectedShippingCountries([...selectedShippingCountries, name]);
      }
    }
  };
  const removeShippingCountries = (name) => {
    const removeRelated = selectedShippingCountries.filter((x) => x !== name);
    setSelectedShippingCountries(removeRelated);
  };
  const handleSubmit = async () => {
    try {
      let payload = {};
      if (sellingLocationType !== 'all' && selectedSellingCountries?.length > 0) {
        payload = { ...payload, selling_locations: selectedSellingCountries };
      } else {
        payload = { ...payload, selling_locations: [] };
      }
      if (shippingLocationType !== 'all' && selectedShippingCountries?.length > 0) {
        payload = { ...payload, shipping_locations: selectedShippingCountries };
      } else {
        payload = { ...payload, shipping_locations: [] };
      }
      payload.selling_location_type = sellingLocationType;
      payload.shipping_location_type = shippingLocationType;
      const body = encryptData({ clientInfo: payload });
      await API.put('/admin/client/updateClient', body);
      setIsNotification(true);
      setNotificationMsg('Allow to order setting updated successfully!');
      setSeverity('success');
      getCountryAllowed();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Country_Allow_To_Order')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_countries_allow_to_order')}
            </p>
          </div>
        </div>
        <form className='pb-4 sm:pb-[25px] '>
          <div className='mb-4'>
            <label htmlFor='option'>{t('lang_Selling_location_type')}</label>
            <div className='relative mb-4'>
              <select
                className='w-full py-2 rounded-[6px] pe-3 outline-none px-1 border main-border-color'
                value={sellingLocationType}
                onChange={(e) => {
                  setSelectedSellingCountries([]);
                  setSellingLocationType(e.target.value);
                }}
              >
                <option value=''>{t('lang_Select_Sell_Countries')}</option>
                <option value='all'>{t('lang_Sell_To_All_Countries')}</option>
                <option value='specific'>{t('lang_Sell_To_Specific_Countries')}</option>
                <option value='except'>
                  {t('lang_Sell_To_All_Countries_Except_This_Countries')}
                </option>
              </select>
              <div className='select-arrow'></div>
            </div>
            {sellingLocationType !== 'all' && sellingLocationType?.length > 0 && (
              <div className='mb-4'>
                <label htmlFor='specific_countries'>{t('lang_Select_Specific_Countries')}</label>
                <Autocomplete
                  className='autocomplete'
                  options={CountryCodes ? CountryCodes : []}
                  getOptionLabel={(item) => item.name}
                  filterOptions={filterOptionsForContry}
                  onChange={(e, v) => (v ? handleSpecificSellingCountries(v?.name) : '')}
                  renderOption={(props, option) => (
                    <Box
                      key={option.code}
                      component='li'
                      sx={{ '& > img': { mr: 1, fontSize: '10px' } }}
                      {...props}
                    >
                      <img
                        loading='lazy'
                        width='20'
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt='flag'
                      />
                      <p className='text-sm flex items-center'>{option.name}</p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Select countries'
                      className='cursor-pointer'
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      value={params?.inputValue}
                      {...params}
                    />
                  )}
                />
                <div className='flex flex-wrap mt-1'>
                  {selectedSellingCountries?.map((elem, index) => {
                    return (
                      <div
                        className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                        key={index}
                      >
                        <span className='table-text'>{elem}</span>
                        <X
                          className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                          onClick={() => removeSellingCountries(elem)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='mb-4 border-t pt-4'>
            <label htmlFor='option'>{t('lang_Shipping_location_type')}</label>
            <div className='relative mb-4'>
              <select
                className='w-full py-2 rounded-[6px] pe-3 outline-none px-1 border main-border-color'
                value={shippingLocationType}
                onChange={(e) => {
                  setSelectedShippingCountries([]);
                  setShippingLocationType(e.target.value);
                }}
              >
                <option value=''>{t('lang_Select_Ship_Countries')}</option>
                <option value='all'>{t('lang_Ship_To_All_Countries')}</option>
                <option value='specific'>{t('lang_Ship_To_Specific_Countries')}</option>
                <option value='except'>
                  {t('lang_Ship_To_All_Countries_Except_This_Countries')}
                </option>
              </select>
              <div className='select-arrow'></div>
            </div>
            {shippingLocationType !== 'all' && shippingLocationType?.length > 0 && (
              <div className='mb-4'>
                <label htmlFor='specific_countries'>{t('lang_Select_Specific_Countries')}</label>
                <Autocomplete
                  className='autocomplete'
                  options={CountryCodes ? CountryCodes : []}
                  getOptionLabel={(item) => item.name}
                  filterOptions={filterOptionsForContry}
                  onChange={(e, v) => (v ? handleSpecificShippingCountries(v?.name) : '')}
                  renderOption={(props, option) => (
                    <Box
                      key={option.code}
                      component='li'
                      sx={{ '& > img': { mr: 1, fontSize: '10px' } }}
                      {...props}
                    >
                      <img
                        loading='lazy'
                        width='20'
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        alt='flag'
                      />
                      <p className='text-sm flex items-center'>{option.name}</p>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      placeholder='Select countries'
                      className='cursor-pointer'
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      value={params?.inputValue}
                      {...params}
                    />
                  )}
                />
                <div className='flex flex-wrap mt-1'>
                  {selectedShippingCountries?.map((elem, index) => {
                    return (
                      <div
                        className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                        key={index}
                      >
                        <span className='table-text'>{elem}</span>
                        <X
                          className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                          onClick={() => removeShippingCountries(elem)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className='w-full flex justify-end'>
            <button
              type='button'
              className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300 disabled:cursor-not-allowed capitalize'
              disabled={sellingLocationType?.length <= 0 || shippingLocationType?.length <= 0}
              onClick={handleSubmit}
            >
              {t('lang_save')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default CoutryAllowOrder;
