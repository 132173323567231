import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../../API';
import { ChangePathContext } from '../../../App';
import Breadcrumbs from '../../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../../controllers/encryptionUtils';
import Notification from '../../Notification';
import { Autocomplete, Skeleton, TextField } from '@mui/material';
function UpdatePosLocation() {
    const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [addressData, setAddressData] = useState({
        addressLine1: '',
        addressLine2: '',
        country: 'india',
        state: '',
        city: '',
        pincode: '',
        phone: ''
    });
    const [name, setName] = useState('')
    const [isChange, setIsChange] = useState(false);
    const [allStateName, setAllStateName] = useState();
    const [allCityName, setAllCityName] = useState();
    const getParticularLocation = async () => {
        setLoader(true);
        try {
            const apiCall = await API.get(`/admin/pos/getPOSLocation/${params?.location}`)
            if (apiCall?.status === 200) {
                const result = decryptData(apiCall?.data);
                setAddressData({
                    ...addressData,
                    addressLine1: result?.address?.addressLine1,
                    addressLine2: result?.address?.addressLine2,
                    state: result?.address?.state,
                    city: result?.address?.city,
                    pincode: result?.address?.pincode,
                    phone: result?.address?.phone
                })
                setName(result?.name)
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setLoader(false);
        }
    }
    const handleGetStateName = async () => {
        try {
            const ApiCall = await API.get(`/super/admin/pincode/getStateName`);
            const result = decryptData(ApiCall?.data)
            setAllStateName(result?.data);
        } catch (error) {
            console.log(error);
        }
    };
    const handleGetCityName = async (statename) => {
        try {
            const ApiCall = await API.get(
                `/super/admin/pincode/getDataFromStateName?StateName=${statename}`,
            );
            const result = decryptData(ApiCall?.data)
            setAllCityName(result?.data);
        } catch (error) {
            console.log(error);
        }
    };
    const handleGetDataFromPincode = async () => {
        try {
            const ApiCall = await API.get(
                `/super/admin/pincode/getDataFromPincode?pincode=${addressData?.pincode}`,
            );
            if (ApiCall?.data) {
                const result = decryptData(ApiCall?.data)
                handleGetCityName(result?.data?.StateName);
                setAddressData({
                    ...addressData,
                    state: result?.data?.StateName,
                    city: result?.data?.District,
                });
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error?.response?.data?.error);
            setSeverity('error');
            setAddressData({
                ...addressData,
                state: '',
                city: '',
            });
            handleGetCityName('');
        }
    };
    useEffect(() => {
        getParticularLocation();
    }, [params?.location])
    useEffect(() => {
        handleGetStateName();
    }, []);
    const [err, setErr] = useState();
    const [loader, setLoader] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const HandleSubmit = async (e) => {
        e.preventDefault();
        setBtnLoading(true);
        try {
            const body = {
                name,
                address: addressData
            }
            const payload = encryptData(body)
            await API.put(`/admin/pos/updatePOSLocation/${params?.location}`, payload);
            setIsNotification(true);
            setNotificationMsg("Location Added Successfully!");
            setSeverity('success');
            setSaveChanges({
                isUnsaveChanges: false,
                showChangesPopup: false,
                backLink: '/pos-location',
                title: 'add location',
            });
            navigate('/pos-location');
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
        setBtnLoading(false);
    };
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };

    useEffect(() => {
        if (isChange) {
            setSaveChanges({
                backLink: '/pos-location',
                isUnsaveChanges: true,
                showChangesPopup: false,
                title: 'pos location',
            });
        }
    }, [isChange]);
    const checkChanges = () => {
        if (isChange) {
            setSaveChanges({
                isUnsaveChanges: true,
                showChangesPopup: true,
                backLink: '/pos-location',
                title: 'pos location',
            });
        } else {
            navigate('/pos-location');
        }
    };
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}

            <div className='h-auto overflow-y-auto pb-[130px]'>
                <div className='flex items-center space-x-2 pb-[30px]'>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            checkChanges();
                        }}
                        className='back-arrow'
                    >
                        <ArrowLeft />
                    </button>
                    <div>
                        <Breadcrumbs
                            crumbs={[
                                { name: t('lang_POS'), path: '/pos-staff' },
                                { name: t('lang_Update_Location'), path: '/pos-location/add-location' },
                            ]}
                        />
                        <div className='flex items-end'>
                            <h4 className='text-xl md:text-2xl font-semibold text-primary '>
                                {t('lang_Update_Location')}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <form
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
                    >
                        {loader ?
                            <div className='secondary-bg-color shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
                                <div className='flex items-center space-x-4 w-full'>
                                    <div className='w-full'>
                                        <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                                        <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                                        <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='secondary-bg-color border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
                                <div className='mb-4 max-w-full'>
                                    <label htmlFor='name'>{t('lang_name')}<span className='text-red-500 ms-1'>*</span></label>
                                    <input
                                        id='name'
                                        name='name'
                                        type='text'
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                            setIsChange(true);
                                        }}
                                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                        placeholder={t('lang_name')}
                                    />
                                </div>
                                <div className='mb-4 max-w-full'>
                                    <label htmlFor='country'>{t('lang_Country')}</label>
                                    <input
                                        id='country'
                                        name='country'
                                        type='text'
                                        disabled
                                        value={addressData?.country}
                                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                        placeholder={t('lang_Country')}
                                    />
                                </div>
                                <div className='mb-4 max-w-full'>
                                    <label htmlFor='addressLine1'>{t('lang_Address_line_1')}</label>
                                    <input
                                        id='address line 1'
                                        name='oaddressLine1'
                                        type='text'
                                        value={addressData?.addressLine1}
                                        onChange={(e) => {
                                            setAddressData({ ...addressData, addressLine1: e.target.value });
                                            setIsChange(true);
                                        }}
                                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                        placeholder='AddressLine 1'
                                    />
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor='addressLine2'>{t('lang_Address_line_2')}</label>
                                    <input
                                        id='Address line 2'
                                        name='oaddressLine2'
                                        type='text'
                                        value={addressData?.addressLine2}
                                        onChange={(e) => {
                                            setAddressData({ ...addressData, addressLine2: e.target.value });
                                            setIsChange(true);
                                        }}
                                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                        placeholder='AddressLine 2'
                                    />
                                </div>
                                <div className='grid grid-cols-1 md:grid-cols-3 gap-5 mb-4'>
                                    <div className='max-w-full'>
                                        <label htmlFor='Pincode'>{t('lang_Pincode')}<span className='text-red-500 ms-1'>*</span></label>
                                        <input
                                            id='Pincode'
                                            name='opincode'
                                            type='Number'
                                            value={addressData?.pincode}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 6) {
                                                    setAddressData({ ...addressData, pincode: e.target.value });
                                                    setIsChange(true);
                                                }
                                            }}
                                            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                            placeholder='Pincode'
                                            onBlur={() => {
                                                setErr(true);
                                                handleGetDataFromPincode();
                                            }}
                                        />
                                        {addressData?.pincode?.length > 0 &&
                                            addressData?.pincode?.length !== 6 &&
                                            err && (
                                                <p className='text-xs text-red-500'>{t('lang_Pincode_Validation')}</p>
                                            )}
                                    </div>
                                    <div className='max-w-full'>
                                        <label className='block text-black text-sm' htmlFor='email'>
                                            {t('lang_State')}<span className='text-red-500 ms-1'>*</span>
                                        </label>
                                        <Autocomplete
                                            className='autocomplete'
                                            options={allStateName ? allStateName : []}
                                            getOptionLabel={(statename) =>
                                                statename.charAt(0).toUpperCase() + statename.slice(1).toLowerCase()
                                            }
                                            value={
                                                addressData?.state
                                                    ? allStateName?.find((x) => x === addressData?.state)
                                                    : addressData?.state
                                            }
                                            onChange={(e, v) => {
                                                setAddressData({ ...addressData, state: v });
                                                handleGetCityName(v);
                                            }}
                                            renderInput={(Statename) => (
                                                <TextField
                                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                                    placeholder='Select state'
                                                    className='cursor-pointer text-black placeholder:text-black'
                                                    {...Statename}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className='max-w-full'>
                                        <label className='block text-black text-sm' htmlFor='email'>
                                            {t('lang_City')}<span className='text-red-500 ms-1'>*</span>
                                        </label>
                                        <Autocomplete
                                            className='autocomplete'
                                            options={allCityName ? allCityName : []}
                                            getOptionLabel={(cityname) =>
                                                cityname.charAt(0).toUpperCase() + cityname.slice(1).toLowerCase()
                                            }
                                            value={
                                                allCityName?.length > 0
                                                    ? allCityName.find((x) => x === addressData?.city)
                                                    : addressData?.city
                                            }
                                            onChange={(e, v) => setAddressData({ ...addressData, city: v })}
                                            renderInput={(Cityname) => (
                                                <TextField
                                                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                                    placeholder='Select city'
                                                    className='cursor-pointer text-black placeholder:text-black'
                                                    {...Cityname}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='max-w-full'>
                                    <label htmlFor='phone'>{t('lang_phone')}</label>
                                    <input
                                        id='phone'
                                        name='phone'
                                        type='Number'
                                        className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                                        value={addressData?.phone}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 10) {
                                                setAddressData({ ...addressData, phone: e.target.value });
                                                setIsChange(true);
                                            }
                                        }}
                                        placeholder={t('lang_phone')}
                                    />
                                </div>
                            </div>}
                    </form>
                </div>
                <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
                    <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                                checkChanges();
                            }}
                            className='w-fit flex text-link font-medium hover:underline text-sm items-center '
                        >
                            <ArrowLeft className='w-3 mr-1' />
                            {t('lang_Go_To_POS')}
                        </Link>
                        <div>
                            {!name || !addressData?.state || addressData?.pincode?.length < 5 || !addressData?.city ? (
                                <button
                                    type='button'
                                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                                >
                                    <span>{t('lang_Update_Location')}</span>
                                </button>
                            ) : btnLoading ? (
                                <button
                                    type='button'
                                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[131px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                                >
                                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                                    <span>{t('lang_loading')}...</span>
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => HandleSubmit(e)}
                                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                                >
                                    <span>{t('lang_Update_Location')}</span>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UpdatePosLocation;
