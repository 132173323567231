import { Backdrop, CircularProgress, Dialog } from '@mui/material';
import React from 'react';
import { Transition } from '../../controllers/Transition';
import { BardSvg } from '../../Assets/StaticData/BardSvg';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';

const EditMetaField = ({
  openEditMeta,
  setOpenEditMeta,
  editMetaData,
  setEditMetaData,
  setIsDescription,
  generateMetaData,
  handleSubmit,
}) => {
  return (
    <Dialog
      open={openEditMeta}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => setOpenEditMeta(false)}
    >
      <form
        className='secondary-bg-color w-[700px] max-w-[600px] lg:max-w-full border main-border-color  space-y-4 rounded-[6px] p-5'
        method='post'
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
          <h6 className='font-medium text-xl'>{t('lang_Edit_Meta_Field')}</h6>
          <button className='close-btn' onClick={() => setOpenEditMeta(false)}>
            <X className='w-4 h-4' />
          </button>
        </div>
        <div className='relative'>
          <select
            disabled
            name='path'
            value={editMetaData?.path}
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            required
          >
            <option value='home'>{t('lang_Home_Page')}</option>
            <option value='login'>{t('lang_Login_Page')}</option>
            <option value='register'>{t('lang_Register_Page')}</option>
            <option value='shop'>{t('lang_Shop_Page')}</option>
            <option value='blog'>{t('lang_Blog_Page')}</option>
            <option value='portfolio'>{t('lang_Portfolio_page')}</option>
            <option value='faq'>{t('lang_FAQ_page')}</option>
            <option value='contactUs'>{t('lang_Contact_page')}</option>
            <option value='cart'>{t('lang_Cart_page')}</option>
            <option value='wishlist'>{t('lang_Wishlist_page')}</option>
            <option value='order'>{t('lang_Orders_page')}</option>
            <option value='checkout'>{t('lang_Checkout_page')}</option>
            <option value='account'>{t('lang_Account_page')}</option>
            <option value='announcement'>{t('lang_Announcements_page')}</option>
          </select>
          <div className='select-arrow'></div>
        </div>
        <div className='mb-4'>
          <div className='flex items-center justify-between'>
            <label htmlFor='instagram' className='flex items-center justify-between'>
              {t('lang_meta_title')}
            </label>
            <span
              className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
              onClick={() => {
                generateMetaData(editMetaData?.path);
                setOpenEditMeta(false);
              }}
            >
              <BardSvg />
              {t('lang_get_meta_title')}
            </span>
          </div>
          <input
            className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            id='meta_title'
            value={editMetaData?.meta_title}
            name='meta_title'
            placeholder='Enter meta title'
            onChange={(e) => setEditMetaData({ ...editMetaData, meta_title: e.target.value })}
            required
          />
        </div>
        <div className='mb-4'>
          <div className='flex items-center justify-between'>
            <label htmlFor='instagram' className='flex items-center justify-between'>
              {t('lang_meta_description')}
            </label>
            <span
              className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
              onClick={() => {
                setOpenEditMeta(false);
                setIsDescription(true);
              }}
            >
              <BardSvg />
              {t('lang_get_meta_description')}
            </span>
          </div>
          <textarea
            className='w-full px-3 h-24 py-2 border main-border-color rounded-[6px] focus:outline-none'
            type='text'
            value={editMetaData?.meta_description}
            id='meta_description'
            name='meta_description'
            placeholder='Enter meta description'
            onChange={(e) => setEditMetaData({ ...editMetaData, meta_description: e.target.value })}
            required
          />
        </div>
        <button
          type='submit'
          className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
        >
          {t('lang_Update_Meta_field')}
        </button>
      </form>
    </Dialog>
  );
};

export default EditMetaField;
