import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const ThirdStaticTestimonialSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.thirdTestimonialComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_testimonial-3',
  );

  return (
    <div className='w-full'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_testimonial-3', 'setting');
              }}
            >
              <div
                class='container mx-auto'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div class='flex flex-wrap -m-4'>
                  {obj?.content?.item?.map((value) => {
                    return (
                      <div class={`p-4 ${getWidthClass(obj?.content?.gridValue?.toString())}`}>
                        <div class='h-full text-center'>
                          {value?.profile &&
                          (value?.profile.startsWith('data:image/svg+xml') ||
                            value?.profile.includes('<svg')) ? (
                            <p className='w-20 h-20 mb-8  inline-block'>
                              <SvgSection
                                svgContent={value?.profile}
                                width='50px'
                                height='50px'
                                fill={value?.profileFill ? '#9ca3af' : ''}
                                stroke={value?.profileFill ? '#9ca3af' : ''}
                              />
                            </p>
                          ) : (
                            <img
                              alt='profile content'
                              className='w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100'
                              src={value?.profile}
                            />
                          )}

                          <p class='leading-relaxed'>
                            <span
                              class='w-full leading-relaxed text-gray-500'
                              dangerouslySetInnerHTML={{ __html: value?.description || '-' }}
                            ></span>
                          </p>
                          <span class='inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4'></span>
                          {value?.htmlTag === 'h1' ? (
                            <h1>{value?.title}</h1>
                          ) : value?.htmlTag === 'h2' ? (
                            <h2>{value?.title}</h2>
                          ) : value?.htmlTag === 'h3' ? (
                            <h3>{value?.title}</h3>
                          ) : value?.htmlTag === 'h4' ? (
                            <h4>{value?.title}</h4>
                          ) : value?.htmlTag === 'h5' ? (
                            <h5>{value?.title}</h5>
                          ) : value?.htmlTag === 'h6' ? (
                            <h6>{value?.title}</h6>
                          ) : value?.htmlTag === 'div' ? (
                            <div>{value?.title}</div>
                          ) : value?.htmlTag === 'p' ? (
                            <p>{value?.title}</p>
                          ) : value?.htmlTag === 'span' ? (
                            <span>{value?.title}</span>
                          ) : (
                            ''
                          )}
                          {value?.role_htmlTag === 'h1' ? (
                            <h1 class='text-gray-500'>{value?.role}</h1>
                          ) : value?.role_htmlTag === 'h2' ? (
                            <h2 class='text-gray-500'>{value?.role}</h2>
                          ) : value?.role_htmlTag === 'h3' ? (
                            <h3 class='text-gray-500'>{value?.role}</h3>
                          ) : value?.role_htmlTag === 'h4' ? (
                            <h4 class='text-gray-500'>{value?.role}</h4>
                          ) : value?.role_htmlTag === 'h5' ? (
                            <h5 class='text-gray-500'>{value?.role}</h5>
                          ) : value?.role_htmlTag === 'h6' ? (
                            <h6 class='text-gray-500'>{value?.role}</h6>
                          ) : value?.role_htmlTag === 'div' ? (
                            <div class='text-gray-500'>{value?.role}</div>
                          ) : value?.role_htmlTag === 'p' ? (
                            <p class='text-gray-500'>{value?.role}</p>
                          ) : value?.role_htmlTag === 'span' ? (
                            <span class='text-gray-500'>{value?.role}</span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default ThirdStaticTestimonialSection;
