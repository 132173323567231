const VideoSection = ({ obj, mapPositionToCSS, getHeightForAspectRatio }) => {
  const { item } = obj?.content || {};
  const videoSource = item?.source || 'youtube';
  const videoLink = item?.link;
  const autoplay = item?.autoPlay ? 1 : 0;
  const startTime = item?.startTime || 0;
  const endTime = item?.endTime ? `&end=${item.endTime}` : '';
  const mute = item?.mute ? 1 : 0;
  const loop = item?.loop ? 1 : 0;
  const playerControl = item?.playerControl ? 1 : 0;
  const modestBranding = item?.playerControl ? 1 : 0;
  const lazyLoad = item?.lazyLoad ? 1 : 0;

  let videoSrc = '';

  switch (videoSource) {
    case 'youtube':
      const youtubeId = videoLink?.split('v=')[1]?.split('&')[0];
      const youtubePrivacyMode = item?.privacyMode
        ? 'https://www.youtube-nocookie.com/embed/'
        : 'https://www.youtube.com/embed/';

      videoSrc = `${youtubePrivacyMode}${youtubeId}?autoplay=${autoplay}&controls=${playerControl}&mute=${mute}&start=${startTime}${endTime}&loop=${loop}&modestbranding=${modestBranding}&lazyload=${lazyLoad}`;
      break;

    case 'vimeo':
      const vimeoId = videoLink?.split('/').pop();
      const minutes = Math.floor(startTime / 60);
      const seconds = startTime % 60;
      const timeMarker = `#t=${minutes}m${seconds}s`;
      const introTitle = item?.introTitle ? 1 : 0;
      const introPortrait = item?.introPortrait ? 1 : 0;
      const introByLine = item?.introByLine ? 1 : 0;
      const privacyMode = item?.privacyMode ? 'dnt=1&' : '';

      videoSrc = `https://player.vimeo.com/video/${vimeoId}?autoplay=${autoplay}&muted=${mute}&loop=${loop}&title=${introTitle}&portrait=${introPortrait}&byline=${introByLine}&lazyload=${lazyLoad}&${privacyMode}${timeMarker}`;
      break;

    // case 'dailymotion':
    //   const dailymotionId = videoLink?.split('/').pop();
    //   videoSrc = `https://www.dailymotion.com/embed/video/${dailymotionId}?autoplay=${autoplay}&start=${startTime}&mute=${mute}`;
    //   break;

    // case 'selfhosted':
    //   videoSrc = videoLink;
    //   break;

    default:
      return <div>Error: Unsupported video source.</div>;
  }

  const width =
    obj?.style?.width === 'full_width' ? '100%' : obj?.style?.width === 'inline' ? 'auto' : 330;

  const calculatedHeight = getHeightForAspectRatio(
    obj?.style?.aspectRatio,
    width !== 'auto' ? width : 330,
  );

  return (
    <div
      style={{
        display: 'flex',
        justifyContent:
          obj?.style?.video_align === 'end'
            ? 'flex-end'
            : obj?.style?.video_align === 'start'
            ? 'flex-start'
            : obj?.style?.video_align === 'center'
            ? 'center'
            : undefined,
        transform: `rotate(${obj?.style?.video_rotate}deg)`,
        background:
          obj?.style?.background_gradient === 'gradient'
            ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
            : obj?.style?.background_image
            ? `url(${obj?.style?.background_image})`
            : obj?.style?.background,
        backgroundRepeat: obj?.style?.background_repeat,
        backgroundAttachment: obj?.style?.background_attachment,
        objectFit: obj?.style?.background_fit,
        backgroundSize:
          obj?.style?.background_fit === 'cover' || obj?.style?.background_fit === 'contain'
            ? obj?.style?.background_fit
            : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
        backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
        paddingBottom: getHeightForAspectRatio(obj?.style?.aspectRatio),
      }}
    >
      {videoSource === 'selfhosted' ? (
        <video
          width={width}
          height={calculatedHeight}
          src={videoSrc}
          controls={playerControl}
          autoPlay={!!autoplay}
          muted={!!mute}
          loop={!!loop}
        />
      ) : (
        <iframe
          width={width}
          height={calculatedHeight}
          src={videoSrc}
          title={`${videoSource} video player`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default VideoSection;
