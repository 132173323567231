import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import GenerateLiveEditiorTitle from '../../../../../controllers/GenerateLiveEditiorTitle';
import module from '../../../../../controllers/QuillToolBar';
import SvgSection from '../../../../../controllers/SvgSection';
import IconList from '../IconList';
import { FormControlLabel } from '@mui/material';
import MuiSwitch from '../../../../../controllers/MuiSwitch';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  handleQuillChange,
  handleIconFill,
  handleIconClick,
  handleSvgClick,
  handleProfileUpload,
  isHovered,
  setIsHovered,
  handleProfileIconFill,
}) => {
  const ShortquillRef = useRef(null);
  const [shortdescription, setShortDescription] = useState('');
  const [meta, setMeta] = useState({
    meta_description: '',
  });
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='role'>Role</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='role'
              name='role'
              placeholder='Enter role...'
              value={item?.role || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>Description</label>
            <ReactQuill
              modules={module}
              ref={ShortquillRef}
              className='w-full h-auto table-text rounded-[6px] mt-2'
              theme='snow'
              id='description'
              name='description'
              value={item?.description || ''}
              onChange={handleQuillChange}
              placeholder='Enter description'
              onBlur={() => {
                setMeta((prevMeta) => ({
                  ...prevMeta,
                  meta_description:
                    shortdescription
                      ?.replace(/<[^>]*>/g, ' ')
                      ?.split(' ')
                      .slice(0, 50)
                      .join(' ') || '',
                }));
              }}
            />
          </div>

          <div className='w-full mb-4'>
            <label>Profile</label>

            {item?.profile &&
            (item?.profile.startsWith('data:image/svg+xml') || item?.profile.includes('<svg')) ? (
              <SvgSection
                svgContent={item?.profile}
                width={'100%'}
                height={'100px'}
                style={{ marginBottom: '10px' }}
              />
            ) : (
              <img
                src={item?.profile}
                alt='Uploaded Preview'
                className='w-full mb-5 max min-h-28 max-h-72 object-contain'
              />
            )}

            <input
              type='file'
              accept='image/*'
              onChange={(e) => handleProfileUpload(e)}
              className='truncate max-w-full overflow-hidden block mb-5'
            />
          </div>

          <div className='mt-2'>
            <label htmlFor='text'>Icon</label>
            <div
              className='relative flex justify-center p-4 bg-[#e6e8ea] border-[1px] border-[#bcbdbf]'
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {item?.icon &&
                (React.isValidElement(item?.icon) ? (
                  React.cloneElement(item?.icon, { width: '22px', height: '22px' })
                ) : (
                  <SvgSection svgContent={item?.icon} width='22px' height='22px' />
                ))}

              {isHovered && (
                <>
                  <button
                    className='text-sm absolute bottom-0 left-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleIconClick('iconList')}
                  >
                    Icon library
                  </button>
                  <button
                    className='text-sm absolute bottom-0 right-0 bg-black text-white p-2 rounded-[6px]'
                    onClick={() => handleSvgClick()}
                  >
                    SVG Icon
                  </button>
                </>
              )}
            </div>
          </div>

          <div className='w-full mb-4 mt-2'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Profile Fill
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={item?.profileFill || false}
                    onChange={handleProfileIconFill}
                    name='profileFill'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>

          <div className='w-full mb-2 mt-4'>
            <label className='flex items-center justify-between cursor-pointer'>
              <span className='text-sm font-medium text-gray-900 dark:text-gray-300'>
                Icon Fill
              </span>
              <FormControlLabel
                control={
                  <MuiSwitch
                    checked={item?.isFill || false}
                    onChange={handleIconFill}
                    name='isFill'
                    type='checkbox'
                  />
                }
              />
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

const StaticFirstTestimonialSection = ({
  section_id,
  section_type,
  firstTestimonialValue,
  setFirstTestimonialValue,
  handleFirstTestimonialValue,
  tabIndex,
  setTabIndex,
  handleAddFirstTestimonialItem,
  handleCopyFirstTestimonialItem,
  handleFirstTestimonialDataRemoveValue,
  saveElementorData,
  btnLoading,
  handleFirstTestimonialDescriptionChange,
  descriptionContent,
  sectionContent,
  headingLevel,
  handleFirstTestimonialTagChange,
  handleFirstTestimonialTitleValueChange,
  sectionGridContent,
  handleFirstTestimonialGridValueChange,
  handleSettingImageUpload,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];

  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');
  const [gridValue, setGridValue] = useState(sectionGridContent);

  const [isHovered, setIsHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const fileInputRef = useRef(null);

  const [meta, setMeta] = useState({
    meta_description: '',
  });

  useEffect(() => {
    setGroupTitle(sectionContent);
    setGridValue(sectionGridContent);
  }, [sectionContent, sectionGridContent]);

  const handleQuillChange = (content) => {
    console.log(content, 'content=-=-=-=>>>');
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
  };

  const handleHeadingTagChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleFirstTestimonialTagChange(section_id, newTag);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    console.log(newValue, 'newValue=-=-=-=>>');
    setGridValue(newValue);
    handleFirstTestimonialGridValueChange(section_id, newValue);
  };

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = firstTestimonialValue?.item?.length
      ? firstTestimonialValue?.item?.[firstTestimonialValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      title: 'Lorem',
      htmlTag: 'h2',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit in liber tempor incididunt ut labore et dolore magna aliqu',
      isEdit: false,
    };
    const updatedItems = firstTestimonialValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
      profile: 'https://dummyimage.com/106x106',
      profileFill: false,
      icon: ` <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='currentColor'
                      class='block w-5 h-5 text-gray-400 mb-4'
                      viewBox='0 0 975.036 975.036'
                    >
                      <path d='M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z'></path>
                    </svg>`,
      isFill: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddFirstTestimonialItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyFirstTestimonialItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleFirstTestimonialDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: value } : item,
      ),
    };

    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
  };

  const onContentChange = (e) => {
    const newValue = e.target.value;
    setGroupTitle(newValue);
    handleFirstTestimonialTitleValueChange(section_id, newValue);
  };

  const getAiGeneratedTitle = (value) => {
    setGroupTitle(value);
    handleFirstTestimonialTitleValueChange(section_id, value);
  };

  const handleProfileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const validImageTypes = ['image/svg+xml', 'image/jpeg', 'image/png'];
    if (!validImageTypes.includes(file.type)) {
      alert('Please select a valid SVG, JPEG, or PNG file.');
      return;
    }

    const reader = new FileReader();
    if (file.type === 'image/svg+xml') {
      reader.onload = (e) => {
        const svgContent = e.target.result;
        const updatedfirstContentValue = {
          ...firstTestimonialValue,
          item: firstTestimonialValue?.item?.map((item, i) =>
            i === tabIndex ? { ...item, profile: svgContent } : item,
          ),
        };

        setFirstTestimonialValue(updatedfirstContentValue);
        handleFirstTestimonialValue(section_id, updatedfirstContentValue);
      };
      reader.readAsText(file);
    } else {
      try {
        const result = await handleSettingImageUpload(event);
        const updatedfirstContentValue = {
          ...firstTestimonialValue,
          item: firstTestimonialValue?.item?.map((item, i) =>
            i === tabIndex ? { ...item, profile: result } : item,
          ),
        };
        setFirstTestimonialValue(updatedfirstContentValue);
        handleFirstTestimonialValue(section_id, updatedfirstContentValue);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleIconFill = (e) => {
    const { checked } = e.target;
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, isFill: checked } : item,
      ),
    };

    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
  };

  const handleProfileIconFill = (e) => {
    const { checked } = e.target;
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, profileFill: checked } : item,
      ),
    };

    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    const updatedfirstContentValue = {
      ...firstTestimonialValue,
      item: firstTestimonialValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, icon: iconComponent } : item,
      ),
    };

    setFirstTestimonialValue(updatedfirstContentValue);
    handleFirstTestimonialValue(section_id, updatedfirstContentValue);
    setIsOpen(false);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        handleIconSelect(imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mb-4'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={groupTitle}
          onChange={onContentChange}
        />
        <label>Title HTMLTag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={selectedHeadingLevel}
            onChange={handleHeadingTagChange}
          >
            {dropDownValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(firstTestimonialValue?.item) &&
          firstTestimonialValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              handleIconFill={handleIconFill}
              handleProfileIconFill={handleProfileIconFill}
              handleIconClick={handleIconClick}
              handleSvgClick={handleSvgClick}
              handleProfileUpload={handleProfileUpload}
              isHovered={isHovered}
              setIsHovered={setIsHovered}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mt-4 mb-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
};

export default StaticFirstTestimonialSection;
