import { t } from 'i18next';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ShowFaq = () => {
  const location = useLocation();
  const { elem } = location.state;
  return (
    <>
      <div className='flex items-end justify-between bg-white absolute top-[80px] z-40 w-full overflow-auto pb-2'>
        <h4 className='text-xl md:text-2xl font-medium ps-3 text-semibold text-center'>
          {t('lang_Show_FAQ')}
        </h4>
      </div>
      <div className='pt-[128px] p-5 overflow-x-hidden w-full primary-bg-color rounded-[6px] h-full'>
        <div className='p-4'>
          <div className='w-1/2 mx-auto border bg-white border-gray-300 rounded-[6px]'>
            <div className='relative flex flex-col min-w-0 break-words bg-white p-5 w-full rounded-[6px]'>
              <div className='flex-auto'>
                <div className='tab-content tab-space'>
                  <div id='link1'>
                    <>
                      <div className='mb-4'>
                        <label
                          className='flex text-gray-700 text-sm font-bold mb-2'
                          htmlFor='Qustion'
                        >
                          {t('lang_Question')}
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                          type='text'
                          id='question'
                          name='question'
                          value={elem.question}
                          disabled
                        />
                      </div>
                      <div className=''>
                        <label
                          className='block text-gray-700 text-sm font-bold mb-2'
                          htmlFor='Answer'
                        >
                          {t('lang_Answer')}
                        </label>
                        <textarea
                          rows={10}
                          className='w-full h-auto p-3 border border-gray-300 rounded-[6px] focus:outline-none'
                          type='readOnly'
                          id='answer'
                          name='answer'
                          value={elem.answer}
                          disabled
                        />
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowFaq;
