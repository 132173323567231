import { ArrowLeft, ChevronDown, Code, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SelectCategories from '../../controllers/SelectCategories';
import SelectProduct from '../../controllers/SelectProduct';
import SourceCode from '../../controllers/SourceCode';
import Notification from '../Notification';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
function AddTab() {
  const navigate = useNavigate();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [tabStatus, setTabStatus] = useState(true);
  const [description, setDescription] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [tabData, setTabData] = useState({
    title: '',
  });
  const [selectedProduct, setSelectedProduct] = useState([]);
  const handleChange = (e) => {
    setTabData({ ...tabData, [e.target.name]: e.target.value });
  };
  //select product
  const [isSelectProduct, setIsSelectProduct] = useState(false);
  const [openCategoryOptions, setOpenCategoryOptions] = useState(false);
  const closeSelectProduct = () => {
    setIsSelectProduct(false);
  };
  const closeCategoryModal = () => {
    setOpenCategoryOptions(false);
  };
  const getSelectedProduct = (value) => {
    setSelectedProduct(value);
  };
  const removeSelectProduct = (e, id) => {
    e.preventDefault();
    const filterSelectedProduct = selectedProduct?.filter((x) => x?.product !== id);
    setSelectedProduct(filterSelectedProduct);
  };
  const [getCategory, setGetCategory] = useState();
  const handleGetCategory = async () => {
    try {
      let getCategoryForExtraTab = await API.get(`/admin/category/GetCategoriesWithAllParent`);
      const result = decryptData(getCategoryForExtraTab?.data)
      setGetCategory(result?.data?.records);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [selectCategory, setSelectCategory] = useState([]);
  const handleRemoveCategory = (id) => {
    const removeProduct = selectCategory.filter((x) => x?._id !== id);
    setSelectCategory(removeProduct);
  };
  const getSelectedCategory = (value) => {
    setSelectCategory(value);
  };
  const [err, setErr] = useState(false);
  // Quill Emmpty check
  const strippedContent = description?.replace(/<[^>]*>/g, '');
  const trimmedContent = strippedContent?.trim();
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        tab_title: tabData ? tabData.title : '',
        tab_description: description,
        tab_status: tabStatus,
        product: selectedProduct?.map((x) => x?.product),
        category: selectCategory?.map((x) => x?._id),
      })
      await API.post('/admin/extratab/addExtratab', payload);
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/tabs',
        title: 'add tab',
      });
      navigate('/tabs');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (description?.length > 0 || tabData.title?.length > 0) {
      setSaveChanges({
        backLink: '/tabs',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add tab',
      });
    } else {
      setSaveChanges({
        backLink: '/tabs',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add tab',
      });
    }
  }, [description, tabData]);
  const checkChanges = () => {
    if (description?.length > 0 || tabData.title?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/tabs',
        title: 'add tab',
      });
    } else {
      navigate('/tabs');
    }
  };
  useEffect(() => {
    handleGetCategory();
  }, []);

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {isSelectProduct && (
        <SelectProduct
          isSelectProduct={isSelectProduct}
          api={`/admin/extratab/GetProductsForExtraTab?`}
          closeSelectProduct={closeSelectProduct}
          getSelectedProduct={getSelectedProduct}
          alreadySelected={selectedProduct}
        />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center pb-[30px] space-x-2'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_custom_tabs'), path: '/tabs' },
                { name: t('lang_Add_New_Custom_Tab'), path: '/tabs/add-tab' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Custom_Tab')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color shadow  rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='title' className='capitalize'>
                  {t('lang_title')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={
                    err && tabData?.title?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-md focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-md focus:outline-none`
                  }
                  type='text'
                  name='title'
                  placeholder='Enter tab title'
                  value={tabData.title}
                  onChange={(e) => handleChange(e)}
                />
                {err && tabData?.title?.length === 0 ? (
                  <p className='text-red-500 text-sm font-medium'>
                    {t('lang_Please_Enter_Tab_Title')}
                  </p>
                ) : (
                  <></>
                )}
              </div>
              <div>
                <div className='flex justify-between items-center'>
                  <label htmlFor='description' className='capitalize'>
                    {t('lang_description')}
                  </label>
                  <span
                    className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                    onClick={handleViewHtml}
                  >
                    <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                  </span>
                </div>
                <ReactQuill
                  ref={quillRef}
                  modules={module}
                  theme='snow'
                  value={description}
                  onChange={handleQuillChange}
                />
              </div>
            </div>
            <details className='secondary-bg-color cursor-pointer group shadow  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div>
                  {t('lang_categories')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Select_Category_For_Add_In_This_Tab')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mt-4 main-border-color' />
              <div className='w-full text-center'>
                {selectCategory?.length > 0 ? (
                  <div className='max-h-[450px] overflow-y-auto divide-y'>
                    {selectCategory?.map((elem, index) => {
                      return (
                        <div
                          key={index}
                          className='flex main-border-color items-center py-2 space-x-3'
                        >
                          <button
                            className='close-btn'
                            onClick={(e) => {
                              e.preventDefault();
                              handleRemoveCategory(elem?._id);
                            }}
                          >
                            <X className='w-[14px] h-[14px] text-red-600' />
                          </button>
                          <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              src={elem?.image || NOPHOTO_IMAGE}
                              className='max-w-full max-h-full'
                              alt={elem?.category_name}
                            />
                          </div>
                          <p className='text-sm table-text'>{elem?.category_name}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <button
                    className='bulk-btn mt-4'
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenCategoryOptions(true);
                    }}
                  >
                    {t('lang_Select_Categories')}
                  </button>
                )}
                {selectCategory?.length > 0 && (
                  <>
                    <div className='flex items-center border-t main-border-color justify-between pt-2'>
                      <button
                        className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenCategoryOptions(true);
                        }}
                      >
                        + {t('lang_Select_More_Categories')}
                      </button>
                      <button
                        className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectCategory([]);
                        }}
                      >
                        <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                        Remove all categories
                      </button>
                    </div>
                  </>
                )}
              </div>
            </details>
            <details className='secondary-bg-color cursor-pointer group shadow rounded-[6px] p-5 sm:p-[25px]'>
              <summary className='text-base flex items-center justify-between font-medium '>
                <div>
                  {t('lang_products')}
                  <p className='text-xs table-text font-normal'>
                    {t('lang_Select_Product_For_Add_In_This_Tab')}
                  </p>
                </div>
                <span className='transition group-open:rotate-180'>
                  <ChevronDown className=' w-4 h-5' />
                </span>
              </summary>
              <hr className='mt-4 main-border-color' />
              <div className='w-full text-center'>
                {selectedProduct?.length > 0 ? (
                  <div className='max-h-[450px] overflow-y-auto divide-y'>
                    {selectedProduct?.map((elem) => {
                      return (
                        <div className='flex items-center py-2 main-border-color space-x-3'>
                          <button
                            className='close-btn'
                            onClick={(e) => removeSelectProduct(e, elem?.product)}
                          >
                            <X className='w-[14px] h-[14px] text-red-600' />
                          </button>
                          <div className='w-[50px] h-[50px] p-0.5 border main-border-color flex items-center justify-center'>
                            <img
                              src={elem?.image}
                              className='max-w-full max-h-full'
                              alt={elem?.name}
                            />
                          </div>
                          <p className='text-sm table-text'>{elem?.name}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <button
                    className='bulk-btn mt-4'
                    onClick={(e) => {
                      e.preventDefault();
                      setIsSelectProduct(true);
                    }}
                  >
                    {t('lang_Select_Product')}
                  </button>
                )}
                {selectedProduct?.length > 0 && (
                  <>
                    <div className='flex items-center border-t main-border-color justify-between pt-2'>
                      <button
                        className='inline-block outline-none text-link hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setIsSelectProduct(true);
                        }}
                      >
                        + {t('lang_select_more_product')}
                      </button>
                      <button
                        className='inline-flex outline-none text-red-600 hover:underline text-[13px] font-medium transition duration-150'
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedProduct([]);
                        }}
                      >
                        <Trash2 className='h-4 w-4 me-2 mt-0.5' />
                        {t('lang_remove_all_product')}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </details>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Custom_Tabs')}
            </Link>
            {tabData?.title === '' || trimmedContent === '' ? (
              <button
                type='button'
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_Custom_Tab')}</span>
              </button>
            ) : btnLoading ? (
              <button
                type='button'
                className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[150px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
              >
                <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                <span>{t('lang_loading')}...</span>
              </button>
            ) : (
              <button
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
              >
                <span>{t('lang_Add_Custom_Tab')}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
      {openCategoryOptions && (
        <SelectCategories
          openCategoryOptions={openCategoryOptions}
          closeCategoryModal={closeCategoryModal}
          api={`/admin/category/GetCategoriesWithAllParent`}
          alreadySelectedCategories={selectCategory}
          getSelectedCategory={getSelectedCategory}
        />
      )}
    </>
  );
}

export default AddTab;
