import { Switch, styled } from '@mui/material';

const MuiSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '& .MuiSwitch-input':{
        position: 'absolute'
    },
    // '&:active': {
    //     '& .MuiSwitch-thumb': { 
    //         width: 15,
    //     },
    //     '& .MuiSwitch-switchBase.Mui-checked': {
    //         transform: 'translateX(9px)',
    //     },
    // },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        transition: 'transform 0.3s ease', // Apply a smooth transition for the switch's position
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        // transition: theme.transitions.create(['width'], {
        //     duration: 200,
        // }),
        transition: 'transform 0.02s ease', // Smooth transition for the thumb's movement
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export default MuiSwitch

