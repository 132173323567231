import { t } from 'i18next';
import React from 'react';

const GoogleMapSettingSection = ({ section_id, handleLocationChange, locationValue,saveElementorData,
  btnLoading, }) => {
  const handleLocationValueChange = (e) => {
    const selectLink = e.target.value;
    const type = e.target.name
    handleLocationChange(section_id, selectLink,type);
  };

  return (
    <div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Location</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='location'
          placeholder='Enter location here...'
          onChange={handleLocationValueChange}
          value={locationValue?.location}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Latitude</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='latitude'
          placeholder='Enter Latitude  here...'
          onChange={handleLocationValueChange}
          value={locationValue?.latitude}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Longitude</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='longitude'
          placeholder='Enter Longitude  here...'
          onChange={handleLocationValueChange}
          value={locationValue?.longitude}
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default GoogleMapSettingSection;