import { Pagination, Popover, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const AllAnnounement = () => {
  const [annoucements, setAnnouncement] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const navigate = useNavigate();
  const [page, setPage] = useState(getInitialPage());
  const [limit, setLimit] = useState(10);
  const [filter, setFilter] = useState(false);
  const [Annfilter, setAnnFilter] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  var startRecord = (annoucements?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(annoucements?.data?.currentPage * limit, annoucements?.data?.totalRecords);
  const GetAnnouncemnet = async () => {
    setDataLoading(true)
    try {
      let ApiCall = await API.get(
        `/admin/announcement/UserGetAnnouncements?type=${Annfilter}&limit=${limit}&page=${page}`,
      );
      const result = decryptData(ApiCall?.data)
      setAnnouncement(result);
      if (Number(startRecord) > Number(result?.data?.totalRecords)) {
        setPage(page - 1)
      }
    } catch (error) {
      setIsNotification(true)
      setNotificationMsg(error)
      setSeverity("error")
    } finally {
      setDataLoading(false)
    }
  };
  useEffect(() => {
    replacePageInUrl(page)
  }, [page])
  useEffect(() => {
    GetAnnouncemnet();
  }, [limit, page, Annfilter]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button onClick={(e) => { e.preventDefault(); navigate('/dashboard') }} className='back-arrow'><ArrowLeft /></button>
            <div>
              <Breadcrumbs crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_announcements'), path: '/announcement' }
              ]} />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_announcements')}</h4>
            </div>
          </div>
          <div
            className='filter-btn'
            onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}
          >
            <Tooltip title='Filter' arrow>
              <button className=''>
                <Filter className='w-5 h-5' />
              </button>
            </Tooltip>
          </div>
        </div>
        <div className='overflow-x-hidden'>
          <div className={'flex w-full h-full overflow-hidden relative'}>
            {
              dataLoading && !annoucements ?
                <TableSkeleton forTable="order" /> :
                <div className='w-full space-y-[20px]'>
                  {
                    annoucements?.data?.records?.length > 0 ?
                      <div className='w-full  transition-all duration-100 border main-border-color annoucements-table inline-block overflow-x-auto overflow-y-hidden rounded-[6px]'>
                        <table className='w-full'>
                          <thead>
                            <tr>
                              <td className='px-3 min-w-[250px] max-w-[250px] capitalize'>{t("lang_title")}</td>
                              <td className='min-w-[200px] w-full px-3 capitalize'>{t("lang_description")}</td>
                              <td className='min-w-100px] max-w-100px] px-3 text-right capitalize'>{t("lang_action")}</td>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              annoucements?.data?.records?.map((elem, index) => {
                                return (
                                  <tr key={index}>
                                    <td className='px-3 min-w-[250px] max-w-[250px]'><span>{elem?.announcement_title || '-'}</span></td>
                                    <td className=' px-3 min-w-[250px] w-full'><span className='line-clamp-2'>{elem?.short_description || '-'}</span></td>
                                    <td className='px-3 min-w-[100px] max-w-[100px] text-right'>
                                      <div className='flex items-center justify-end w-full'>
                                        <Tooltip title='View' arrow>
                                          <button className=''>
                                            <Eye
                                              className='w-[17px] h-[17px] text-black-500'
                                              onClick={() => navigate('/announcement/viewannouncement', { state: elem?._id })}
                                            />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div> : <NoRecords />
                  }
                  {/* Paginator */}
                  {annoucements?.data ? (
                    <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                      <span className='text-sm font-medium'>
                        {limit === 'all'
                          ? `${annoucements?.data?.totalRecords}` + ' ' + t('lang_records')
                          : `${startRecord} - ${endRecord} of ${annoucements?.data?.totalRecords} ${t("lang_records")}`}
                      </span>
                      <div>
                        {annoucements?.data?.totalPages !== 1 ? (
                          <Pagination
                            count={annoucements?.data?.totalPages}
                            page={page}
                            onChange={(e, v) => setPage(v)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
            }
            <Popover
              open={filter}
              anchorEl={anchorEl}
              onClose={() => { setFilter(false); setAnchorEl(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              PaperProps={{
                style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
              }}
              className='rounded-[6px]'
            >
              <div className='p-[20px] filter'>
                <p className='flex text-primary font-medium uppercase text-sm m-0 '>
                  <Filter className='me-1 w-4 h-4' />
                  {t("lang_filter")}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5 overflow-hidden'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t("lang_set_items_limit")}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          setPage(1);
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        }}
                      >
                        {annoucements?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {annoucements?.data?.totalRecords > 20 ? (
                          <option value='20'>20</option>
                        ) : (
                          <></>
                        )}
                        {annoucements?.data?.totalRecords > 50 ? (
                          <option value='50'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all'>{t("lang_all")}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                      {`${t("lang_announcement")} ${t("lang_type")}`}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full text-slate-500 text-sm border main-border-color px-3 py-1.5'
                        name='limit'
                        value={Annfilter}
                        onChange={(e) => {
                          setPage(1)
                          setAnnFilter(e.target.value);
                        }}
                      >
                        <option value=''>{t("lang_all")}</option>
                        <option value='1'>{`${t("lang_unread")} ${t("lang_announcement")}`}</option>
                        <option value='2'>{`${t("lang_viewed")} ${t("lang_announcement")}`}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAnnounement;
