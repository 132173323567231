import { Checkbox, FormControlLabel, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount, formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const ViewDiscount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [discountInfo, setDiscountInfo] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [clearRow, setClearRow] = useState(false);
  const [status, setStatus] = useState('all');
  // paginate
  const [Page, setPage] = useState(getInitialPage());
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  const { setPlanPermission } = useContext(PlanPermission);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [search, setSearch] = useState(
    location?.state?.searchkey ? location?.state?.searchkey : '',
  );
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
  var startRecord = (discountInfo?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(discountInfo?.currentPage * limit, discountInfo?.totalRecords);
  const [allDiscountCount, setAllDiscountCount] = useState()
  const [activeDiscountCount, setActiveDiscountCount] = useState()
  const [expireDiscountCount, setExpireDiscountCount] = useState()
  useEffect(() => {
    setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
  }, [location?.state?.searchkey]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
    GetDisconts();
  };
  useEffect(() => {
    if (search?.length > 0 || search?.length > 2) {
      GetDisconts();
    }
  }, [search]);
  const GetDisconts = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(
        `/admin/discount/viewDiscount?status=${status}&page=${Page}&limit=${limit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        const totalRecords = result?.data?.totalRecords || 0;
        const formattedRecords = totalRecords.toString().padStart(2, '0');
        setDiscountInfo(result?.data);
        if(status === 'all') {
          setAllDiscountCount(formattedRecords)
          setExpireDiscountCount(result?.data?.expiredCount.toString().padStart(2, '0'))
          setActiveDiscountCount(result?.data?.activeCount.toString().padStart(2, '0'))
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'discount' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [status]);
  useEffect(() => {
    GetDisconts();
    setClearRow(!clearRow);
  }, [limit, Page, status]);
  // Edit Discount Status
  const HandleSubmit = async (row, value) => {
    try {
      const payload = encryptData({
        discount_status: value,
      })
      let ApiCall = await API.put(`/admin/discount/updateDiscount/${row._id}`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetDisconts();
        setIsNotification(true);
        setNotificationMsg(
          value === 'enabled' ? 'Discount is set to active' : 'Discount is set to hidden',
        );
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  // delete Discount
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      let ApiCall = await API.post(`/admin/discount/deleteDiscount`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetDisconts();
        setIsNotification(true);
        setNotificationMsg('Discount removed successfully!');
        setSeverity('success');
        setClearRow(!clearRow);
        setSelectedRowsId([]);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  // edit Discount
  const Handleedit = (id) => {
    navigate(`/discounts/${id}`);
  };
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = discountInfo?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = discountInfo?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = discountInfo?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => (!item.default ? item._id : ''))]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(discountInfo?.records?.map((x) => (!x?.default ? x?._id : '')));
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };

  return (
    <>
      <ConfirmationPopup
        heading='Delete Discount'
        subheading='Do you really want to delete this dicount from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_discounts'), path: '/discounts' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_discounts')}{' '}
              </h4>
            </div>
          </div>
          <div></div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300 '
              onClick={() => navigate('/discounts/add-discount')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('lang_Add_New_Discount')}
            </button>
          </div>
        </div>
        <div className='flex justify-between overflow-hidden h-full space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            {/* <div className='flex items-center'>
              {selectedRowsId && selectedRowsId?.length > 0 && (
                <>
                  <span className='text-sm px-3'>
                    {selectedRowsId?.length} {t('lang_selected')}
                  </span>
                  |
                  <button
                    className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-3.5 h-3.5' />
                    <span>{t('lang_delete_selection')}</span>
                  </button>
                </>
              )}
            </div> */}
            <div className='flex items-center justify-between mb-2'>
              <div className='flex mb-2'>
                {discountInfo?.totalRecords > 0 && <div class="grid w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded p-1">
                <button className={`tab ${(status === "all" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('all')}>
                  {t('lang_all')} 
                  {allDiscountCount > 0 && <span
                      className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                        status === 'all' ? "secondary-bg-color" : "primary-bg-color"
                      }`}
                    >
                      {allDiscountCount}
                    </span>}
                </button>
                {activeDiscountCount > 0 && <button className={`tab ${(status === "active" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('active')}>
                  {t('lang_active')}
                  {activeDiscountCount > 0 && <span
                      className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                        status === 'active' ? "secondary-bg-color" : "primary-bg-color"
                      }`}
                    >
                      {activeDiscountCount}
                    </span>}
                </button>}
                {expireDiscountCount > 0 &&<button className={`tab ${(status === "expire" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('expire')}>
                  {t('lang_expired')}
                  {expireDiscountCount > 0 && <span
                      className={`p-1 text-[11px] rounded-full ml-1 font-medium ${
                        status === 'expire' ? "secondary-bg-color" : "primary-bg-color"
                      }`}
                    >
                      {expireDiscountCount}
                    </span>}
                </button>}
                </div>}
                <div className='flex items-center'>
              {selectedRowsId && selectedRowsId?.length > 0 && (
                <>
                  <span className='text-sm px-3'>
                    {selectedRowsId?.length} {t('lang_selected')}
                  </span>
                  |
                  <button
                    className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                    onClick={(e) => {
                      e.preventDefault();
                      setConfirmationPopup(true);
                    }}
                  >
                    <Trash2 className='w-3.5 h-3.5' />
                    <span>{t('lang_delete_selection')}</span>
                  </button>
                </>
              )}
            </div>
              </div>
                {discountInfo?.totalRecords > 0 && <Tooltip title='Filter' arrow>
                  <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                    <button className=''>
                      <Filter className='w-4 h-4 sm:w-[18px] sm:h-[18px]' />
                    </button>
                  </div>
                </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full overflow-hidden space-y-1'>
                {dataLoading && !discountInfo ? (
                  <TableSkeleton forTable='order' />
                ) : discountInfo?.records?.length > 0 ? (
                  <div className='w-full transition-all duration-100 category-table inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={selectedRowsId?.length === discountInfo?.records?.length}
                              indeterminate={
                                selectedRowsId?.length !== discountInfo?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'coupon_code' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('coupon_code');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('coupon_code')}
                              >
                                {t('lang_coupon')}
                                {iconVisible && sortBy === `coupon_code` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3'>
                            <div className='flex items-center w-full justify-end'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'discount' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('discount');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('discount')}
                              >
                                {iconVisible && sortBy === `discount` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_value')}
                              </button>
                            </div>
                          </td>
                          <td className='w-full px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'discount_name' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('discount_name');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('discount_name')}
                              >
                                {t('lang_description')}
                                {iconVisible && sortBy === `discount_name` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                            <div className='flex items-center justify-end w-full'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'start_date' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('start_date');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('start_date')}
                              >
                                {iconVisible && sortBy === `start_date` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_start_date')}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                            <div className='flex items-center justify-end w-full'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'end_date' ? 'text-gray-700' : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                  setSortBy('end_date');
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                  setSortBy('');
                                }}
                                onClick={() => SortData('end_date')}
                              >
                                {iconVisible && sortBy === `end_date` ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='me-2' />
                                  ) : (
                                    <CaretDownFill className='me-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                                {t('lang_end_date')}
                              </button>
                            </div>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3'>
                            used
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            {t('lang_status')}
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <span className='text-right w-full'>{t('lang_action')}</span>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {discountInfo?.records?.map((row, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                                }`}
                              onClick={() => Handleedit(row?._id)}
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  disabled={row?.default}
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === row?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, row?._id)}
                                />
                              </td>
                              <td className='min-w-[200px] max-w-[200px] px-3'>
                                <span className=''>{row.coupon_code || '-'}</span>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3'>
                                <span className='capitalize block pe-2 w-full text-right'>
                                  {row.discount_Type === 'amount'
                                    ? FormatAmount(row?.discount)
                                    : `${row.discount}%`}
                                </span>
                              </td>
                              <td className='w-full px-3'>
                                <span className='capitalize line-clamp-2'>
                                  {row.discount_name || '-'}
                                </span>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                                <div className='flex items-center justify-end w-full'>
                                  {formatDate(row?.start_date) || '-'}
                                </div>
                              </td>
                              <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                                <div className='flex items-center justify-end w-full'>
                                  {row?.end_date ? formatDate(row?.end_date) : '-'}
                                </div>
                              </td>
                              <td className='min-w-[100px] max-w-[100px] px-3'>
                                <span className='capitalize line-clamp-2'>
                                  {row.usage_count || '-'}
                                </span>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='w-full text-right'>
                                  <label className='relative flex justify-end w-full'>
                                    <FormControlLabel
                                      checked={row.discount_status === 'enabled'}
                                      control={<MuiSwitch />}
                                      onChange={() => {
                                        HandleSubmit(
                                          row,
                                          row.discount_status === 'enabled'
                                            ? 'disabled'
                                            : 'enabled',
                                        );
                                      }}
                                    />
                                  </label>
                                </div>
                              </td>
                              <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-3 flex items-center justify-end w-full'>
                                  <Tooltip title='Edit' arrow>
                                    <button
                                      className='text-black-500 hover:text-black-600'
                                      onClick={() => {
                                        Handleedit(row?._id);
                                      }}
                                    >
                                      <Edit className='w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title='Delete' arrow>
                                    <button
                                      className=' text-red-500 hover:text-red-600'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationPopup(true);
                                        setSelectedRowsId([row?._id]);
                                      }}
                                    >
                                      <Trash className='w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {discountInfo?.totalPages ? (
                  <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${discountInfo?.totalRecords}` + ' ' + 'Records'
                        : `${startRecord} - ${endRecord} of ${discountInfo?.totalRecords} Records`}
                    </span>
                    <div>
                      {discountInfo?.totalPages !== 1 ? (
                        <Pagination
                          count={discountInfo?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                  <p className='z-10 flex  text-primary font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5'>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_search')}
                      </span>
                      <label className='relative block w-full'>
                        <input
                          className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                          placeholder='Search Discounts'
                          type='text'
                          name='search'
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                            setPage(1);
                          }}
                        />
                      </label>
                    </div>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          }}
                        >
                          {discountInfo?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {discountInfo?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                          {discountInfo?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDiscount;
