import { Tooltip } from '@mui/material';
import { Edit, Trash } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData } from '../../controllers/encryptionUtils';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import RegisterMetaFieldForm from './RegisterMetaFieldForm';

const GetRegisterMetaField = () => {
  const [clientInfo, setClientInfo] = useState([]);
  const [openMetaFieldForm, setOpenMetaFieldForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleCloseNotification = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getCustomField = async () => {
    try {
      const ApiCall = await API.get('/admin/client/GetCustomField');
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setClientInfo(result?.data);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getCustomField();
  }, [openMetaFieldForm, confirmationPopup]);
  const handleEdit = (elem) => {
    setOpenMetaFieldForm(true);
    setEditData(elem);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const removeMetaField = async () => {
    try {
      let Delete = await API.delete(`/admin/client/DeleteCustomField/${deleteId}`);
      if (Delete.status === 200) {
        setIsNotification(true);
        setNotificationMsg('Field removed successfully');
        setSeverity('success');
        setDeleteId();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setConfirmationPopup(false);
    }
  };
  const handleCloseMetaFieldForm = () => {
    setOpenMetaFieldForm(false);
    setEditData({});
  };
  return (
    <div className='w-full h-full overflow-y-auto  py-4 pl-3 md:pl-4'>
      {isNotification && notificationMsg && (
        <Notification
          message={notificationMsg}
          close={handleCloseNotification}
          severity={severity}
        />
      )}
      <div className='flex flex-wrap items-end justify-between w-full pb-4'>
        <div className='flex items-center space-x-2'>
          <h5>{t('lang_Registration')}</h5>
        </div>
        <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
          <button
            type='button'
            className='flex btn rounded-s text-sm items-center font-medium  text-white transition duration-300'
            onClick={() => setOpenMetaFieldForm(true)}
          >
            {t('lang_Add_Register')}
          </button>
        </div>
      </div>
      {clientInfo?.length > 0 ? (
        <div className='w-full transition-all duration-100 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
          <table className='w-full'>
            <thead>
              <tr>
                <td className='px-3 w-full uppercase'>{t('lang_group_name')}</td>
                <td className='px-3 w-full'>{t('lang_Content_type')}</td>
                <td className='min-w-[120px] max-w-[120px] px-3 text-right'>{t('lang_action')}</td>
              </tr>
            </thead>
            <tbody>
              {clientInfo?.length > 0 &&
                clientInfo?.map((elem, index) => (
                  <tr key={index} onClick={() => handleEdit(elem)}>
                    <td className='px-3 w-full'>
                      <span className='capitalize'>{elem.custom_field_name || '-'}</span>
                    </td>
                    <td className='px-3 w-full'>
                      <span className='capitalize'>{elem.custom_field_type || '-'}</span>
                    </td>
                    <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                      <div className='w-full space-x-2 text-right'>
                        <Tooltip title='Edit' arrow>
                          <button
                            type='button'
                            className=' text-black-500 hover:text-black-600'
                            onClick={() => handleEdit(elem)}
                          >
                            <Edit className='w-[17px] h-[17px]' />
                          </button>
                        </Tooltip>
                        <Tooltip title='Delete' arrow>
                          <button
                            type='button'
                            className='text-red-500 hover:text-red-600'
                            onClick={() => {
                              setConfirmationPopup(true);
                              setDeleteId(elem?._id);
                            }}
                          >
                            <Trash className=' w-[17px] h-[17px]' />
                          </button>
                        </Tooltip>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecords />
      )}
      {openMetaFieldForm && (
        <RegisterMetaFieldForm
          openMetaFieldForm={openMetaFieldForm}
          handleClose={handleCloseMetaFieldForm}
          heading={'Registration'}
          editData={editData}
        />
      )}
      {confirmationPopup && (
        <ConfirmationPopup
          heading='Delete Custom Field'
          subheading='Do you really want to delete this field from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={removeMetaField}
        />
      )}
    </div>
  );
};

export default GetRegisterMetaField;
