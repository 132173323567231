import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
const ErrorPage = () => {
  return (
    <>
      <main className='grid min-h-full place-items-center secondary-bg-color px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <p className='text-base font-bold mb-10 text-[72px] text-indigo-600'>404</p>
          <h1 className='text-3xl font-bold tracking-tight text-primary sm:text-5xl'>
            {t('lang_Page_not_found')}
          </h1>
          <p className='mt-2 text-base leading-7 table-text'>{t('lang_Page_not_found_details')}</p>
          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link to='/dashboard' className='rounded-[6px] btn text-white text-sm px-8 py-2'>
              {t('lang_Go_back_home')}
            </Link>
            <a href='#' className='text-sm hover:underline font-semibold table-text'>
              {t('lang_Contact_support')} <span aria-hidden='true'>&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
};

export default ErrorPage;
