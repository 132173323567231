import React, { useState } from 'react';

const AddSectionElementor = ({
  handleFlexBoxClick,
  isFlexBoxVisible,
  setIsFlexBoxVisible,
  setIsPlusButtonVisible,
  isPlusButtonVisible,
  isLayoutVisible,
  setIsLayoutVisible,
  isGridVisible,
  setIsGridVisible,
}) => {
  const handlePlusClick = () => {
    setIsLayoutVisible(true);
    setIsPlusButtonVisible(false);
  };

  const handleClickFlexBox = () => {
    setIsFlexBoxVisible(true);
    setIsLayoutVisible(false);
    setIsPlusButtonVisible(false);
    setIsGridVisible(false);
  };

  const handleClickGrid = () => {
    setIsGridVisible(true);
    setIsFlexBoxVisible(false);
    setIsLayoutVisible(false);
    setIsPlusButtonVisible(false);
  };

  const handleClose = () => {
    setIsPlusButtonVisible(true);
    setIsLayoutVisible(false);
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
  };
  const handleBack = () => {
    setIsPlusButtonVisible(false);
    setIsLayoutVisible(true);
    setIsFlexBoxVisible(false);
    setIsGridVisible(false);
  };

  return (
    <div className='relative align-middle m-5 p-10 flex justify-center glow-1 border-2 border-dashed border-[#d5d8dc]'>
      {isFlexBoxVisible || isGridVisible ? (
        <button className='absolute top-2 left-2 p-1 rounded-full' onClick={handleBack}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            class='feather feather-chevron-left'
          >
            <polyline points='15 18 9 12 15 6'></polyline>
          </svg>
        </button>
      ) : (
        ''
      )}
      {isLayoutVisible || isFlexBoxVisible || isGridVisible ? (
        <button className='absolute top-2 right-2 p-1 rounded-full' onClick={handleClose}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='26'
            height='26'
            fill='currentColor'
            className='bi bi-x'
            viewBox='0 0 16 16'
          >
            <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
          </svg>
        </button>
      ) : (
        ''
      )}

      <div className={`flex flex-col w-full ${!isFlexBoxVisible ? 'items-center' : ''}`}>
        {isPlusButtonVisible && (
          <div
            className='bg-[#e6e8ea] h-9 w-9 rounded-full flex justify-center items-center cursor-pointer hover:bg-[#d5dade]'
            onClick={handlePlusClick}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='28'
              height='28'
              fill='currentColor'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
            </svg>
          </div>
        )}

        {isLayoutVisible && (
          <div>
            <p className='text-center'>Which layout would you like to use?</p>
            <div className='flex justify-center'>
              <div className='flex flex-row gap-10'>
                <div onClick={handleClickFlexBox} className='cursor-pointer'>
                  <div className='flex gap-[2px] mt-4 group parent-hover cursor-pointer'>
                    <div className='h-21 w-11 bg-[#e6e8ea] child-hover'></div>
                    <div className='flex flex-col gap-[2px]'>
                      <div className='h-10 w-11 bg-[#e6e8ea] child-hover'></div>
                      <div className='h-10 w-11 bg-[#e6e8ea] child-hover'></div>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p className='text-center'>FlexBox</p>
                  </div>
                </div>

                <div onClick={handleClickGrid} className='cursor-pointer'>
                  <div className='flex mt-4 group cursor-pointer'>
                    <div>
                      <div className='h-10 w-10 border-2 border-solid border-[#ccd1d5] border-r-0 group-hover:bg-[#6f7a86]'></div>
                      <div className='h-10 w-10 border-2 border-solid border-[#ccd1d5] border-t-0 border-r-0 group-hover:bg-[#6f7a86]'></div>
                    </div>
                    <div>
                      <div className='h-10 w-10 border-2 border-solid border-[#ccd1d5] group-hover:bg-[#6f7a86]'></div>
                      <div className='h-10 w-10 border-2 border-solid border-[#ccd1d5] border-t-0 group-hover:bg-[#6f7a86]'></div>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <p className='text-center'>Grid</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isFlexBoxVisible && (
          <>
            <p className='text-center'>Select your structure</p>
            <div className='grid grid-rows-2 grid-cols-6 gap-5 w-full pl-32 pr-32 mt-6'>
              <div
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_cols');
                }}
                className='bg-[#d5d8dc] flex justify-center items-center p-5 hover:bg-[#6f7a86] cursor-pointer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  fill='#FFFFFF'
                  class='bi bi-arrow-down'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1'
                  />
                </svg>
              </div>
              <div
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_rows');
                }}
                className='bg-[#d5d8dc] flex justify-center items-center p-5 hover:bg-[#6f7a86] cursor-pointer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  fill='#FFFFFF'
                  class='bi bi-arrow-right'
                  viewBox='0 0 16 16'
                >
                  <path
                    fill-rule='evenodd'
                    d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8'
                  />
                </svg>
              </div>
              <div
                className='grid grid-cols-2 gap-[2px] group cursor-pointer'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_two_grid');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-[30%_70%] group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_grid_two');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-[25%_25%_25%_25%] group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_four_grid');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-[25%_50%_25%] group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_three_grid_three');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-2 grid-rows-2 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_four_grid_four');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-2 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_two_grid_one');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86] col-span-2'></div>
              </div>
              <div
                className='grid grid-cols-2 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_one_grid_two');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86] row-span-2'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-3 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_six_grid_six');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-3 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_five_grid');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86] col-span-2'></div>
              </div>
              <div
                className='grid grid-cols-3 group cursor-pointer gap-[2px]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'flex_two_grid_two');
                }}
              >
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86] col-span-2'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86]'></div>
                <div className='bg-[#d5d8dc] group-hover:bg-[#6f7a86] col-span-2'></div>
              </div>
            </div>
          </>
        )}
        {isGridVisible && (
          <>
            <p className='text-center'>Select your structure</p>
            <div className='grid grid-rows-1 grid-cols-6 gap-5 w-full pl-32 pr-32 mt-6'>
              <div
                className='grid grid-cols-2 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_two_rows');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0 p-6'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] p-6'></div>
              </div>
              <div
                className='grid grid-rows-2 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_two_cols');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-cols-3 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_three_cols');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-rows-3 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_three_rows');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-rows-2 grid-cols-2 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_two_for_two');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0 border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86]'></div>
              </div>
              <div
                className='grid grid-rows-2 grid-cols-3 group cursor-pointer h-[100%]'
                onClick={(e) => {
                  handleFlexBoxClick(e, 'grid_three_for_three');
                }}
              >
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0 border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0 border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-b-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86] border-r-0'></div>
                <div className='border-2 border-dashed border-[#b1b5b9] group-hover:bg-[#6f7a86]'></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AddSectionElementor;
