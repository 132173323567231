import React, { useEffect, useState } from 'react';
import Notification from '../Notification';
import API from '../../API';
import { Tooltip } from '@mui/material';
import { ChevronDown, Edit, Trash } from 'feather-icons-react/build/IconComponents';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import EditNavbarField from './EditNavbarField';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const NavbarField = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [navbarField, setNavbarField] = useState({
    title: '',
    redirect_url: '',
  });
  const [editNavbarField, setEditNavbarField] = useState({
    title: '',
    redirect_url: '',
  });
  const [storeNavbarField, setStoreNavbarField] = useState([]);
  const handleChange = (e) => {
    setNavbarField({ ...navbarField, [e.target.name]: e.target.value });
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [openEditNavbarField, setOpenEditNavbarField] = useState(false);
  const [editId, setEditId] = useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId?.length > 0) {
        const payload = encryptData(editNavbarField);
        const apiCall = await API.put(`/admin/client/UpdateTopNavBarFields/${editId}`, payload);
        if (apiCall.status === 200) {
          getNavbarFields();
          setIsNotification(true);
          setNotificationMsg('Field update successfully!');
          setSeverity('success');
          setEditNavbarField({
            title: '',
            redirect_url: '',
          });
          setOpenEditNavbarField(false);
        }
      } else {
        const payload = encryptData({
          top_nav_bar_fields: navbarField,
        });
        const apiCall = await API.post('/admin/client/AddTopNavBarFields', payload);
        if (apiCall.status === 200) {
          getNavbarFields();
          setIsNotification(true);
          setNotificationMsg('Field added successfully!');
          setSeverity('success');
          setNavbarField({
            title: '',
            redirect_url: '',
          });
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const getNavbarFields = async () => {
    try {
      const apiCall = await API.get('/admin/client/GetTopNavBarFields');
      const result = decryptData(apiCall?.data);
      setStoreNavbarField(result?.data);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getNavbarFields();
  }, []);
  const handleEditField = async (data) => {
    setOpenEditNavbarField(true);
    setEditId(data?._id);
    setEditNavbarField({
      title: data?.title,
      redirect_url: data?.redirect_url,
    });
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const handleDeleteField = async () => {
    try {
      const apiCall = await API.delete(`/admin/client/DeleteTopNavBarFields/${deleteId}`);
      if (apiCall.status === 200) {
        getNavbarFields();
        setIsNotification(true);
        setNotificationMsg('Field delete successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Navbar Field'
        subheading='Do you really want to delete this navbar field from your store?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDeleteField}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
        <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
          <div className='w-full'>
            <div className='text-base font-medium text-primary'>
            {t('lang_Navbar_Announcements')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_Navbar_Announcements_For_Show_Information')}
            </p>
          </div>
          </div>
          <>
            <form
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              method='POST'
              className='w-full text-sm mx-auto rounded-[6px] pb-[25px]'
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='title' className='flex items-center justify-between capitalize'>
                    {t('lang_title')}
                  </label>
                </div>
                <input
                  className={`w-full px-3 py-2 border ${
                    navbarField?.title?.split(' ').length < 60
                      ? 'main-border-color'
                      : 'border-red-500'
                  } rounded-[6px] focus:outline-none`}
                  type='text'
                  value={
                    navbarField?.title?.length
                      ? navbarField?.title?.split(' ').slice(0, 40).join(' ')
                      : ''
                  }
                  name='title'
                  placeholder='Enter title'
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                />
                <p className='w-full text-xs mt-0 text-gray-400'>{t('lang_Only_Words_Allowed')}</p>
              </div>
              <div className='mb-4'>
                <div className='flex items-center justify-between'>
                  <label htmlFor='instagram' className='flex items-center justify-between'>
                    {t('lang_Redirect_Url')}
                  </label>
                </div>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='url'
                  value={navbarField?.redirect_url}
                  name='redirect_url'
                  placeholder='Enter redirect url'
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className='w-full flex justify-end'>
                <button
                  type='submit'
                  className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
                >
                  {t('lang_Add_Navbar_Announcements')}
                </button>
              </div>
            </form>
            {storeNavbarField?.length > 0 ? (
              <>
                <div className='space-y-4'>
                  <h6 className='text-lg font-medium my-4'>{t('lang_Navbar_Announcements')}</h6>
                  {storeNavbarField?.map((field, index) => {
                    return (
                      <div className='w-full h-auto ' key={index}>
                        <div className='flex items-center w-full justify-between rounded-[6px] border main-border-color py-2 px-3 space-x-4 text-sm'>
                          <div className='grid grid-cols-1 table-text md:grid-cols-2 w-full gap-5'>
                            <p className='me-8 overflow-auto'>
                              <span className='font-medium capitalize'>{t('lang_title')} :</span>
                              <span className='ms-2 '>{field.title}</span>
                            </p>
                            <p className='overflow-auto'>
                              <span className='font-medium'>{t('lang_Redirect_Url')} :</span>
                              <span className='ms-2'>{field.redirect_url}</span>
                            </p>
                          </div>
                          <div className='flex mx-4 items-center space-x-4'>
                            <Tooltip title='Edit' arrow>
                              <button onClick={() => handleEditField(field)}>
                                <Edit className='w-4 h-4 text-black-500' />
                              </button>
                            </Tooltip>
                            <Tooltip title='Delete' arrow>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeleteId(field?._id);
                                  setConfirmationPopup(true);
                                }}
                              >
                                <Trash className='w-4 h-4 text-red-500' />
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      {openEditNavbarField && (
        <EditNavbarField
          openEditNavbarField={openEditNavbarField}
          setOpenEditNavbarField={setOpenEditNavbarField}
          editNavbarField={editNavbarField}
          setEditNavbarField={setEditNavbarField}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default NavbarField;
