import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
    ArrowLeft,
    Filter,
    Trash2
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Eye } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { FormatAmount } from '../../controllers/FormatAmount';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

const Contract = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dataLoading, setDataLoading } = useContext(DataLoad);
    const initialLimit = localStorage.getItem('limit');
    const [limit, setLimit] = useState(initialLimit);
    const [subscriptionOrderData, setSubscriptionOrderData] = useState();
    const [selectedRowsId, setSelectedRowsId] = useState([]);
    const [clearRow, setClearRow] = useState(false);
    const [status, setStatus] = useState('all');
    // paginate
    const [Page, setPage] = useState(getInitialPage());
    useEffect(() => {
        replacePageInUrl(Page);
    }, [Page]);
    const { setPlanPermission } = useContext(PlanPermission);
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const [search, setSearch] = useState(
        location?.state?.searchkey ? location?.state?.searchkey : '',
    );
    const [sortBy, setSortBy] = useState('');
    const [iconVisible, setIconVisible] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
    var startRecord = (subscriptionOrderData?.currentPage - 1) * limit + 1;
    var endRecord = Math.min(subscriptionOrderData?.currentPage * limit, subscriptionOrderData?.totalRecords);
    useEffect(() => {
        setSearch(location?.state?.searchkey ? location?.state?.searchkey : '');
    }, [location?.state?.searchkey]);
    // Sorting Data
    const SortData = (sort_by) => {
        if (!orderBy.isDecending) {
            setOrderBy({ orderByValue: 'DESC', isDecending: true });
        } else {
            setOrderBy({ orderByValue: 'ASC', isDecending: false });
        }
        GetContracts();
    };
    useEffect(() => {
        if (search?.length > 0 || search?.length > 2) {
            GetContracts();
        }
    }, [search]);
    const GetContracts = async () => {
        setDataLoading(true);
        try {
            let ApiCall = await API.get(
                `/admin/payment/subscriptionorder?page=${Page}&${status !== "all" && 'status=' + status}&limit=${limit}&searchkey=${search}&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
            );
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                const result = decryptData(ApiCall?.data)
                setSubscriptionOrderData(result?.data);
            }
        } catch (error) {
            if (error?.response?.status === 882) {
                setPlanPermission({ isPlanPermissions: false, forModule: 'ProductSubscription' });
            }
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setDataLoading(false);
        }
    };
    useEffect(() => {
        GetContracts();
        setClearRow(!clearRow);
    }, [limit, Page, status]);
    // Edit Contract Status
    const HandleSubmit = async (row, value) => {
        try {
            setIsActive(true);
            const payload = encryptData({
                discount_status: value,
            })
            // let ApiCall = await API.put(`/admin/discount/updateDiscount/${row._id}`, payload);
            if (ApiCall.status === 200 || ApiCall.status === 304) {
                GetContracts();
                setIsNotification(true);
                setNotificationMsg(
                    value === 'enabled' ? 'Contract is set to active' : 'Contract is set to hidden',
                );
                setSeverity('success');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            setIsActive(false);
        }
    };
    const viewContractDetail = (row) => {
        navigate(`/contract/${row._id}`);
    }
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    useEffect(() => {
        localStorage.setItem('limit', limit);
    }, [limit]);

    const [filter, setFilter] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);


    const handleSelectRow = (e, id) => {
        if (e?.nativeEvent?.shiftKey) {
            const prevIndex = subscriptionOrderData?.records?.findIndex(
                (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
            );
            const currIndex = subscriptionOrderData?.records?.findIndex((x) => x._id === id);
            const startIndex = Math.min(prevIndex, currIndex);
            const endIndex = Math.max(prevIndex, currIndex);
            const shiftSelected = subscriptionOrderData?.records?.slice(startIndex, endIndex + 1);

            if (selectedRowsId?.includes(id)) {
                setSelectedRowsId((prev) => [
                    ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
                ]);
            } else {
                setSelectedRowsId((prev) => [
                    ...new Set([...prev, ...shiftSelected.map((item) => (!item.default ? item._id : ''))]),
                ]);
            }
        } else if (id === 'all') {
            if (selectedRowsId?.length > 0) {
                setSelectedRowsId([]);
            } else {
                setSelectedRowsId(subscriptionOrderData?.records?.map((x) => (!x?.default ? x?._id : '')));
            }
        } else {
            const existId = selectedRowsId?.includes(id);
            if (!existId) {
                setSelectedRowsId((prev) => [...prev, id]);
            } else {
                setSelectedRowsId((prev) => prev.filter((x) => x !== id));
            }
        }
    };

    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <div className='h-auto overflow-y-auto pb-[64px]'>
                <div className='flex items-end justify-between w-full pb-[30px]'>
                    <div className='flex items-center space-x-2'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                navigate('/dashboard');
                            }}
                            className='back-arrow'
                        >
                            <ArrowLeft />
                        </button>
                        <div>
                            <Breadcrumbs
                                crumbs={[
                                    { name: t('lang_dashboard'), path: `/dashboard` },
                                    { name: t('lang_Contracts'), path: '/contracts' },
                                ]}
                            />
                            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                                {t('lang_Contracts')}{' '}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className='flex justify-between overflow-hidden h-full space-x-3 w-full relative'>
                    <div className='w-full space-y-3'>
                        <div className='flex items-center'>
                            {selectedRowsId && selectedRowsId?.length > 0 && (
                                <>
                                    <span className='text-sm px-3'>
                                        {selectedRowsId?.length} {t('lang_selected')}
                                    </span>
                                    |
                                    <button
                                        className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setConfirmationPopup(true);
                                        }}
                                    >
                                        <Trash2 className='w-3.5 h-3.5' />
                                        <span>{t('lang_delete_selection')}</span>
                                    </button>
                                </>
                            )}
                        </div>
                        {subscriptionOrderData?.totalRecords > 0 && <div className='flex items-center justify-between mb-2'>
                            <div class="grid mb-2 w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded-[6px] p-1">
                                <a
                                    className={`tab ${status === 'all' ? 'focus-tab' : 'bg-transparent'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setStatus('all');
                                    }}
                                    data-toggle='tab'
                                    href='#link1'
                                >
                                    {t('lang_all')}
                                    {subscriptionOrderData?.totalRecords > 0 && <span
                                        className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'all' ? "secondary-bg-color" : "primary-bg-color"
                                            }`}
                                    >
                                        {subscriptionOrderData?.totalRecords.toString().padStart(2, '0')}
                                    </span>}
                                </a>
                                {subscriptionOrderData?.active > 0 && <a
                                    className={`tab ${status === 'active' ? 'focus-tab' : 'bg-transparent'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setStatus('active');
                                    }}
                                    data-toggle='tab'
                                    href='#link1'
                                >
                                    {t('lang_active')}
                                    {subscriptionOrderData?.active > 0 && (
                                        <span
                                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'active' ? "secondary-bg-color" : "primary-bg-color"
                                                }`}
                                        >
                                            {subscriptionOrderData?.active.toString().padStart(2, '0')}
                                        </span>
                                    )}
                                </a>}
                                {subscriptionOrderData?.created > 0 && <a
                                    className={`tab ${status === 'created' ? 'focus-tab' : 'bg-transparent'}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setStatus('created');
                                    }}
                                    data-toggle='tab'
                                    href='#link1'
                                >
                                    {t('lang_created')}
                                    {subscriptionOrderData?.created > 0 && (
                                        <span
                                            className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'created' ? "secondary-bg-color" : "primary-bg-color"
                                                }`}
                                        >
                                            {subscriptionOrderData?.created.toString().padStart(2, '0')}
                                        </span>
                                    )}
                                </a>}
                                {subscriptionOrderData?.expired > 0 && (
                                    <a
                                        className={`tab ${status === 'expired' ? 'focus-tab' : 'bg-transparent'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setStatus('expired');
                                        }}
                                        data-toggle='tab'
                                        href='#link1'
                                    >
                                        {t('lang_expired')}
                                        {subscriptionOrderData?.expired > 0 && (
                                            <span
                                                className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'expired' ? "secondary-bg-color" : "primary-bg-color"
                                                    }`}
                                            >
                                                {subscriptionOrderData?.expired.toString().padStart(2, '0')}
                                            </span>
                                        )}
                                    </a>
                                )}
                            </div>
                            <Tooltip title='Filter' arrow>
                                <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                                    <button className=''>
                                        <Filter className='w-4 h-4 sm:w-[18px] sm:h-[18px]' />
                                    </button>
                                </div>
                            </Tooltip>
                        </div>}
                        <div
                            className={'flex w-full h-full overflow-hidden relative'}
                        >
                            <div className='w-full overflow-hidden space-y-1'>
                                {dataLoading && !subscriptionOrderData ? (
                                    <TableSkeleton forTable='order' />
                                ) : subscriptionOrderData?.records?.length > 0 ? (
                                    <div className='w-full transition-all duration-100 category-table inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                                        <table className='w-full'>
                                            <thead>
                                                <tr>
                                                    <td className='px-3 table-head-checkbox'>
                                                        <Checkbox
                                                            checked={selectedRowsId?.length === subscriptionOrderData?.records?.length}
                                                            indeterminate={
                                                                selectedRowsId?.length !== subscriptionOrderData?.records?.length &&
                                                                selectedRowsId?.length > 0
                                                            }
                                                            onChange={(e) => {
                                                                handleSelectRow(e, 'all');
                                                            }}
                                                        />
                                                    </td>
                                                    <td className='min-w-[200px] max-w-[200px] px-3'>
                                                        {t('lang_user')}
                                                    </td>
                                                    <td className='min-w-[100px] max-w-[100px] px-3'>
                                                        {t('lang_amount')}
                                                    </td>
                                                    <td className='w-full px-3'>
                                                        {t('lang_product')}
                                                    </td>
                                                    <td className='min-w-[100px] max-w-[100px] px-3'>
                                                        {t('lang_Delivery_interval')}
                                                    </td>
                                                    <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                                        {t('lang_status')}
                                                    </td>
                                                    <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                                        <span className='text-right w-full'>{t('lang_action')}</span>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {subscriptionOrderData?.records?.map((row, index) => {
                                                    return (
                                                        <tr
                                                            key={index}
                                                            className={`${selectedRowsId?.find((x) => x === row?._id) ? 'selected-row' : ''
                                                                }`}
                                                            onClick={() => viewContractDetail(row)}
                                                        >
                                                            <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                                                <Checkbox
                                                                    disabled={row?.default}
                                                                    checked={
                                                                        selectedRowsId?.length > 0 &&
                                                                        selectedRowsId?.find((x) => x === row?._id)
                                                                    }
                                                                    onChange={(e) => handleSelectRow(e, row?._id)}
                                                                />
                                                            </td>
                                                            <td className='min-w-[200px] max-w-[200px] px-3'>
                                                                <span className=''>{row?.user?.name || '-'}</span>
                                                            </td>
                                                            <td className='min-w-[100px] max-w-[100px] px-3'>
                                                                <span className='capitalize block pe-2 w-full text-center'>
                                                                    {FormatAmount(row?.amount / 100)}
                                                                </span>
                                                            </td>
                                                            <td className='w-full px-3'>
                                                                <span className='capitalize line-clamp-2'>
                                                                    {row?.product?.product_name || '-'}
                                                                </span>
                                                            </td>
                                                            <td className='min-w-[100px] max-w-[100px] px-3'>
                                                                {row?.period}
                                                            </td>
                                                            <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                                                                <span className={`relative capitalize inline-block px-4 py-1 font-medium rounded-md  ${row?.status === 'created' ? 'text-black-500 bg-blue-100' : row?.status === 'active' ? 'text-green-600 bg-green-100' : 'text-red-500 bg-red-100'} leading-tight`}>
                                                                    {row?.status}
                                                                </span>
                                                            </td>
                                                            <td className='min-w-[120px] max-w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                                                <div className='flex items-center justify-end w-full space-x-3 text-sm'>
                                                                    <Tooltip title={t('lang_view')} arrow>
                                                                        <button className=''>
                                                                            <Eye
                                                                                className='cursor-pointer w-[17px] h-[17px]'
                                                                                onClick={() => {
                                                                                    viewContractDetail(row);
                                                                                }}
                                                                            />
                                                                        </button>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <NoRecords />
                                )}
                                {/* Paginator */}
                                {subscriptionOrderData?.totalPages ? (
                                    <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                                        <span className='text-sm font-medium'>
                                            {limit === 'all'
                                                ? `${subscriptionOrderData?.totalRecords}` + ' ' + 'Records'
                                                : `${startRecord} - ${endRecord} of ${subscriptionOrderData?.totalRecords} Records`}
                                        </span>
                                        <div>
                                            {subscriptionOrderData?.totalPages !== 1 ? (
                                                <Pagination
                                                    count={subscriptionOrderData?.totalPages}
                                                    page={Page}
                                                    onChange={(e, v) => setPage(v)}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                            <Popover
                                open={filter}
                                anchorEl={anchorEl}
                                onClose={() => { setFilter(false); setAnchorEl(null) }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                PaperProps={{
                                    style: { marginRight: '35px', marginTop: '10px' }, // Adjust padding and margin
                                }}
                                className='rounded-[6px]'
                            >
                                <div className='p-[20px] filter'>
                                    <p className='z-10 flex  text-primary font-semibold uppercase text-sm m-0'>
                                        <Filter className='me-1 w-4 h-4' />
                                        {t('lang_filter')}
                                    </p>
                                    <hr className='main-border-color my-3'></hr>
                                    <div className='grid gap-y-5'>
                                        <div>
                                            <span className='text-sm table-text font-medium mb-1 inline-block'>
                                                {t('lang_search')}
                                            </span>
                                            <label className='relative block w-full'>
                                                <input
                                                    className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                                                    placeholder='Search contracts with only customer name'
                                                    type='text'
                                                    name='search'
                                                    value={search}
                                                    onChange={(e) => {
                                                        setSearch(e.target.value);
                                                        setPage(1);
                                                    }}
                                                />
                                            </label>
                                        </div>
                                        <div>
                                            <span className='text-sm table-text font-medium mb-1 inline-block'>
                                                {t('lang_set_items_limit')}
                                            </span>
                                            <div className='relative'>
                                                <select
                                                    className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                                                    name='limit'
                                                    value={limit}
                                                    onChange={(e) => {
                                                        setPage(1);
                                                        e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                                                    }}
                                                >
                                                    {subscriptionOrderData?.totalRecords > 10 ? (
                                                        <>
                                                            <option value='10'>10</option>
                                                        </>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {subscriptionOrderData?.totalRecords > 20 ? <option value='20'>20</option> : <></>}
                                                    {subscriptionOrderData?.totalRecords > 50 ? <option value='50'>50</option> : <></>}
                                                    <option value='all'>{t('lang_all')}</option>
                                                </select>
                                                <div className='select-arrow'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contract;
