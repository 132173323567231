import React, { useContext, useEffect, useRef, useState } from 'react';
import Breadcrumbs from '../../controllers/breadCrumbs';
import API from '../../API';
import { useParams, useNavigate } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Loader from '../Loader/Loader';
import { ArrowLeft, Edit, Move, Trash, X } from 'feather-icons-react/build/IconComponents';
import Notification from '../Notification';
import { TrashFill } from 'react-bootstrap-icons';
import NoDataFound from '../Main/NoDataFound';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { ChangePathContext } from '../../App';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const AddLayout = ({ closeAddSection, getAllLayout, bannerAdd }) => {
  const [sectionMenu, setSectionMenu] = useState([
    'slider',
    'category',
    'product',
    'manufacture',
    'banner',
    'blog',
    'cmsoffer',
    'reassurance',
  ]);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [nodata, setNodata] = useState(false);
  const [selectedRowsId, setSelectedRowsId] = useState();
  const params = useParams();
  const [selectSection, setSelectSection] = useState(bannerAdd ? 'banner' : 'slider');
  const [selectItems, setSelectItems] = useState('');
  const [sliderData, setSliderData] = useState();
  const [bannerData, setBannerData] = useState();
  const [brandData, setBrandData] = useState();
  const [blogData, setBlogData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [productData, setProductData] = useState();
  const [cmsOfferData, setCmsOfferData] = useState();
  const [reassuranceData, setReassuranceData] = useState();
  const [getLayout, setGetLayout] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  const handleSelectSection = () => {
    switch (selectSection) {
      case 'slider':
        API.get(`/admin/slider/getSliderGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setSliderData(result?.data);
          })
          .catch((error) => console.error(error));
        break;
      case 'banner':
        API.get(`/admin/banner/getBannerGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setBannerData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'manufacture':
        API.get(`/admin/dynamicBrand/getBrandGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setBrandData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'blog':
        API.get(`/admin/dynamicBlog/getBlogGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setBlogData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'category':
        API.get(`/admin/dynamicCategory/getCategoryGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setCategoryData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'product':
        API.get(`/admin/dynamicProduct/getProductGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setProductData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'cmsoffer':
        API.get(`/admin/cmsoffer/getCmsOffers`)
          .then((res) => {
            const result = decryptData(res?.data)
            setCmsOfferData(result?.data);
          })
          .catch((error) => console.error(error));
      case 'reassurance':
        API.get(`/admin/reassurance/getReassuranceGroups`)
          .then((res) => {
            const result = decryptData(res?.data)
            setReassuranceData(result?.data);
          })
          .catch((error) => console.error(error));
      default:
        break;
    }
  };
  useEffect(() => {
    handleSelectSection();
  }, [selectSection]);
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddLayout = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        layout: params?.layoutName,
        section: {
          section_type: selectSection,
          section_id: selectItems,
        },
      })
      await API.post(`/admin/layout/addSection`, payload);
      setSelectSection('slider');
      setSelectItems('');
      getAllLayout();
      closeAddSection(false);
    } catch (error) {
      console.log(error);
    }
    setBtnLoading(false);
  };
  const [pending, setPending] = useState(false);
  const handleGetLayout = async () => {
    setPending(true);
    try {
      const Apicall = await API.get(`/admin/layout/getLayout/${params?.layoutName}`);
      const result = decryptData(Apicall?.data)
      setGetLayout(result?.data?.sections);
      setPending(false);
    } catch (error) {
      setNodata(true);
    }
  };
  useEffect(() => {
    handleGetLayout();
  }, []);
  const UpdateSectionStatus = async (id, value) => {
    try {
      const payload = encryptData({
        status: value,
      })
      const data = await API.put(`/admin/layout/updateSection/${id}`, payload);
      if (data.status === 200 || data.status === 304) {
        handleGetLayout();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      console.log(error);
    }
  };
  const [isClear, setIsClear] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/layout/deleteSection`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Section Removed SuccesFully!');
        setSeverity('success');
        handleGetLayout();
        setIsClear(true);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortSection = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      })
      await API.put(`/admin/layout/updateSection/${id}`, payload);
      handleGetLayout();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const DragItem = useRef();
  const DragOverItem = useRef();
  const HandleSort = (e) => {
    e.preventDefault();
    let _sort = [...getLayout];
    const dragItemContent = _sort.splice(DragItem.current, 1)[0];
    _sort.splice(DragOverItem.current, 0, dragItemContent);
    sortSection(DragOverItem.current, dragItemContent._id);
    DragItem.current = null;
    DragOverItem.current = null;
    let FinalArray = { getLayout: _sort };
    setGetLayout(FinalArray);
  };
  const columns = [
    {
      name: '',
      width: '50px',
      padding: '0px',
      cell: (row, index) => (
        <div
          className='cursor-move text-center block w-full'
          draggable
          onDragStart={(e) => (DragItem.current = index)}
          onDragEnter={(e) => (DragOverItem.current = index)}
          onDragEnd={HandleSort}
        >
          <Move className='cursor-move w-4' />
        </div>
      ),
    },
    {
      name: 'section type',
      cell: (row) => <span className='line-clamp-2'>{row?.section_type}</span>,
      minWidth: '150px',
    },
    {
      name: 'section',
      cell: (row) => (
        <span
          className='line-clamp-2'
          dangerouslySetInnerHTML={{
            __html:
              row?.section_type === 'slider'
                ? row?.section?.slider_title
                : row?.section_type === 'banner'
                  ? row?.section?.banner_title
                  : row?.section_type === 'manufacture'
                    ? row?.section?.brand_section_title
                    : row?.section_type === 'blog'
                      ? row?.section?.blog_section_title
                      : row?.section_type === 'category'
                        ? row?.section?.category_section_title
                        : row?.section_type === 'product'
                          ? row?.section?.product_section_title
                          : row?.section_type === 'cmsoffer'
                            ? row?.section?.cms_offer_title
                            : row?.section_type === 'reassurance'
                              ? row?.section?.reassurance_title
                              : '',
          }}
        ></span>
      ),
      minWidth: '300px',
    },
    {
      name: 'Status',
      width: '100px',
      cell: (row) => (
        <label className='relative justify-center w-full flex ms-2 items-center cursor-pointer'>
          <input
            type='checkbox'
            checked={row.status}
            name='slider'
            onChange={() => {
              UpdateSectionStatus(row?._id, !row.status);
            }}
            className='sr-only peer'
          />
          <div className="w-9 h-5 absolute bg-gray-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:main-border-color after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-black"></div>
        </label>
      ),
    },
    {
      name: <span className='w-full text-right capitalize'>{t('lang_action')}</span>,
      width: '80px',
      cell: (row) => (
        <div className='space-x-2 w-full text-right'>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleEdit(row);
            }}
          >
            <Edit className='w-[17px] h-[17px] text-black-500' />
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setConfirmationPopup(true);
              setSelectedRowsId([row?._id]);
            }}
          >
            <Trash className='w-[17px] h-[17px] text-red-500' />
          </button>
        </div>
      ),
    },
  ];
  const navigate = useNavigate();
  const handleEdit = (row) => {
    if (row?.section_type === 'product') {
      navigate(`/module-setting/product-group/${row?.section_id}`);
    } else if (row?.section_type === 'category') {
      navigate(`/module-setting/category-group/${row?.section_id}`);
    } else if (row?.section_type === 'cmsoffer') {
      navigate(`/module-setting/cms-offer/${row?.section_id}`);
    } else if (row?.section_type === 'blog') {
      navigate(`/module-setting/blog-group/${row?.section_id}`);
    } else if (row?.section_type === 'product') {
      navigate(`/module-setting/product-group/${row?.section_id}`);
    } else if (row?.section_type === 'reassurance') {
      navigate(`/module-setting/reassurance-group/${row?.section_id}`);
    } else if (row?.section_type === 'slider') {
      navigate(`/module-setting/slider-group/${row?.section_id}`);
    } else if (row?.section_type === 'banner') {
      navigate(`/module-setting/banner-group/${row?.section_id}`);
    } else if (row?.section_type === 'manufacture') {
      navigate(`/module-setting/manufacture-group/${row?.section_id}`);
    }
  };

  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      {nodata ? (
        <NoDataFound />
      ) : (
        <div className='h-auto secondary-bg-color w-[600px] p-5 overflow-y-auto'>
          <div className='flex text-primary items-center justify-between space-x-2 w-full'>
            <div>
              <h4 className='font-medium'>{t('lang_Add_Section')}</h4>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                closeAddSection(false);
              }}
              className='close-btn'
            >
              <X className='w-4 h-4' />
            </button>
          </div>
          <hr className='my-4 main-border-color'></hr>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full'
          >
            <div className='secondary-bg-color rounded-[6px]'>
              <div className='mb-4'>
                <label htmlFor='product_name'>{t('lang_Section_Type')}</label>
                <div className='relative w-full'>
                  <select
                    className='w-full capitalize'
                    value={selectSection}
                    onChange={(e) => setSelectSection(e.target.value)}
                  >
                    {/* {sectionMenu?.map((elem) => {
                      return (
                        <option value={elem} className='capitalize'>
                          {elem}
                        </option>
                      );
                    })} */}
                    {bannerAdd ? (
                      <>
                        <option value='banner'>Banner</option>
                        <option value='cmsoffer'>cmsoffer</option>
                      </>
                    ) : (
                      sectionMenu.map((section, index) => (
                        <option key={index} value={section}>
                          {section.charAt(0).toUpperCase() + section.slice(1)}
                        </option>
                      ))
                    )}
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4'>
                <label htmlFor='product_name'>{t('lang_Section')}</label>
                <div className={`relative w-full ${err ? 'border border-red-500 rounded-[6px]' : ''}`}>
                  <select
                    className='w-full capitalize'
                    value={selectItems}
                    onClick={() => setErr(false)}
                    onChange={(e) => {
                      setSelectItems(e.target.value);
                    }}
                  >
                    <option value='' className='capitalize'>
                      {t('lang_Select_section')}
                    </option>
                    {selectSection === 'slider' ? (
                      sliderData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option value={elem?._id} className='capitalize'>
                              {elem?.slider_title}
                            </option>
                          )
                        );
                      })
                    ) : selectSection === 'banner' ? (
                      bannerData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option value={elem?._id} className='capitalize'>
                              {elem?.banner_title}
                            </option>
                          )
                        );
                      })
                    ) : selectSection === 'manufacture' ? (
                      brandData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option
                              value={elem?._id}
                              className='capitalize'
                              dangerouslySetInnerHTML={{ __html: elem?.brand_section_title }}
                            ></option>
                          )
                        );
                      })
                    ) : selectSection === 'blog' ? (
                      blogData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option
                              value={elem?._id}
                              className='capitalize'
                              dangerouslySetInnerHTML={{ __html: elem?.blog_section_title }}
                            ></option>
                          )
                        );
                      })
                    ) : selectSection === 'category' ? (
                      categoryData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option
                              value={elem?._id}
                              className='capitalize'
                              dangerouslySetInnerHTML={{ __html: elem?.category_section_title }}
                            ></option>
                          )
                        );
                      })
                    ) : selectSection === 'product' ? (
                      productData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option
                              value={elem?._id}
                              className='capitalize'
                              dangerouslySetInnerHTML={{ __html: elem?.product_section_title }}
                            ></option>
                          )
                        );
                      })
                    ) : selectSection === 'cmsoffer' ? (
                      cmsOfferData?.map((elem) => {
                        return (
                          <option
                            value={elem?._id}
                            className='capitalize'
                            dangerouslySetInnerHTML={{ __html: elem?.cms_offer_title }}
                          ></option>
                        );
                      })
                    ) : selectSection === 'reassurance' ? (
                      reassuranceData?.map((elem) => {
                        return (
                          elem?.status && (
                            <option
                              value={elem?._id}
                              className='capitalize'
                              dangerouslySetInnerHTML={{ __html: elem?.reassurance_title }}
                            ></option>
                          )
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </select>
                  <div className='select-arrow'></div>
                </div>
                {err ? (
                  <p className='text-red-500 text-xs'>{t('lang_Please_Select_section')}</p>
                ) : (
                  <></>
                )}
              </div>
              <div>
                {selectItems === '' ? (
                  <button
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                    type='button'
                    disabled
                  >
                    <span>{t('lang_Add_Section')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => handleAddLayout(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span>{t('lang_Add_Section')}</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddLayout;
