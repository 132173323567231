import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Filter,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';

const Files = () => {
  const navigate = useNavigate();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [files, setFiles] = useState();
  const [selectedRowsId, setSelectedRowsId] = useState();
  const [clearRow, setClearRow] = useState(false);
  const [status, setStatus] = useState('all');
  // paginate
  const [Page, setPage] = useState(getInitialPage());
  const totalPages = Math.ceil(files?.imageUrls?.length / Number(limit));
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  const { setPlanPermission } = useContext(PlanPermission);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  var startRecord = (Page - 1) * limit;
  var endRecord = Math.min(Page * limit, files?.imageUrls?.length);
  const [paginatedItems, setPaginatedItems] = useState([]);
  const [attachedCount, setAttachedCount] = useState()
  const [unAttachedCount, setUnAttachedCount] = useState()
  useEffect(() => {
    setPaginatedItems(files?.imageUrls?.slice(startRecord, endRecord));
  }, [files, limit, Page]);
  const GetFiles = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(
        `/admin/fileupload/getAllImagesFromBucket?filter=${status}`,
      );
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        if (status === 'all') {
          setAttachedCount(result?.attachedCount)
          setUnAttachedCount(result?.unattachedCount)
        }
        setFiles(result);
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'discount' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    setPage(1);
  }, [status]);
  useEffect(() => {
    GetFiles();
    setClearRow(!clearRow);
  }, [status]);
  // delete Files
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async () => {
    try {
      let ApiCall = await API.post(`/admin/fileupload/delete_file_s3?url=${selectedRowsId}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        GetFiles();
        setIsNotification(true);
        setNotificationMsg('Files removed successfully!');
        setSeverity('success');
        setClearRow(!clearRow);
        setSelectedRowsId();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <>
      <ConfirmationPopup
        heading={t('lang_Delete_File')}
        subheading={t('lang_Delete_File_Text')}
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={HandleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Files'), path: '/files' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Files')}{' '}
              </h4>
            </div>
          </div>
          <div></div>
        </div>
        <div className='flex justify-between overflow-hidden h-full space-x-3 w-full relative'>
          <div className='w-full space-y-1.5'>
            <div className='flex items-center justify-between mb-2'>
              <div className='flex mb-2'>
                {paginatedItems?.length > 0 && <div class="grid w-fit grid-flow-col text-center table-text border main-border-color secondary-bg-color rounded p-1">
                  <button className={`tab ${(status === "all" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('all')}>
                    {t('lang_all')}
                    {files?.imageUrls?.length > 0 && (
                      <span
                        className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'all' ? "secondary-bg-color" : "primary-bg-color"
                          }`}
                      >
                        {files?.imageUrls?.length}
                      </span>
                    )}
                  </button>
                  {attachedCount > 0 && <button className={`tab ${(status === "attached" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('attached')}>
                    {t('lang_Attached')}
                    {attachedCount > 0 && (
                      <span
                        className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'attached' ? "secondary-bg-color" : "primary-bg-color"
                          }`}
                      >
                        {attachedCount}
                      </span>
                    )}
                  </button>}
                  {unAttachedCount > 0 && <button className={`tab ${(status === "unattached" ? 'focus-tab' : 'bg-transparent')}`} onClick={() => setStatus('unattached')}>
                    {t('lang_Unattached')}
                    {unAttachedCount > 0 && (
                      <span
                        className={`p-1 text-[11px] rounded-full ml-1 font-medium ${status === 'unattached' ? "secondary-bg-color" : "primary-bg-color"
                          }`}
                      >
                        {unAttachedCount}
                      </span>
                    )}
                  </button>}
                </div>}
              </div>
              {paginatedItems?.length > 0 && <Tooltip title='Filter' arrow>
                <div className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
                  <button className=''>
                    <Filter className='w-4 h-4 sm:w-[18px] sm:h-[18px]' />
                  </button>
                </div>
              </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full overflow-hidden space-y-1'>
                {dataLoading && !paginatedItems ? (
                  <TableSkeleton forTable='order' />
                ) : paginatedItems?.length > 0 ? (
                  <div className='w-full transition-all duration-100 category-table inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 thead-image min-w-[80px]'>{t('lang_image')}</td>
                          <td className='px-3'>
                            name
                          </td>
                          <td className='px-3 text-right'>
                            <span className='text-right w-full'>{t('lang_action')}</span>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {paginatedItems?.map((row, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${selectedRowsId === row ? 'selected-row' : ''
                                }`}
                            >
                              <td className='px-3 table-image min-w-[80px]'>
                                <img
                                  className='p-1'
                                  onError={(e) => {
                                    e.target.src = NOPHOTO_IMAGE;
                                  }}
                                  width={40}
                                  height={50}
                                  src={row || NOPHOTO_IMAGE}
                                  alt='Product image'
                                />
                              </td>
                              <td className='px-3'>
                                <span className='line-clamp-2'>
                                  {row || '-'}
                                </span>
                              </td>
                              <td className='px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='space-x-3 flex items-center justify-end w-full'>
                                  <Tooltip title='Delete' arrow>
                                    <button
                                      className=' text-red-500 hover:text-red-600'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setConfirmationPopup(true);
                                        setSelectedRowsId(row);
                                      }}
                                    >
                                      <Trash className='w-[17px] h-[17px]' />
                                    </button>
                                  </Tooltip>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {totalPages ? (
                  <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <span className='text-sm font-medium'>
                      {limit === 'all'
                        ? `${files?.imageUrls?.length}` + ' ' + 'Records'
                        : `${startRecord} - ${endRecord} of ${files?.imageUrls?.length} Records`}
                    </span>
                    <div>
                      {totalPages !== 1 ? (
                        <Pagination
                          count={totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px', marginTop: '10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                  <p className='z-10 flex  text-primary font-semibold uppercase text-sm m-0'>
                    <Filter className='me-1 w-4 h-4' />
                    {t('lang_filter')}
                  </p>
                  <hr className='main-border-color my-3'></hr>
                  <div className='grid gap-y-5'>
                    <div>
                      <span className='text-sm table-text font-medium mb-1 inline-block'>
                        {t('lang_set_items_limit')}
                      </span>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          }}
                        >
                          {files?.imageUrls?.length > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {files?.imageUrls?.length > 20 ? <option value='20'>20</option> : <></>}
                          {files?.imageUrls?.length > 50 ? <option value='50'>50</option> : <></>}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Files;
