import React, { useState } from 'react';
import GenerateLiveEditiorTitle from '../../../../controllers/GenerateLiveEditiorTitle';
import { t } from 'i18next';

const ImageBoxSettingSection = ({
  handleIconBoxImageUpload,
  previewImage,
  section_id,
  handleImageBoxImageSizeChange,
  currentImageSize,
  sectionContent,
  section_type,
  handleImageBoxTitleChange,
  handleImageBoxHtmlTagChange,
  headingLevel,
  sectionContentDescription,
  handleImageBoxDescriptionChange,
  handleImageBoxImageLinkChange,
  currentImageLink,
  setImageBox,
  saveElementorData,
  btnLoading,
}) => {
  const [groupTitle, setGroupTitle] = useState(sectionContent);
  const [groupDescription, setGroupDescription] = useState(sectionContentDescription);
  const [selectedHeadingLevel, setSelectedHeadingLevel] = useState(headingLevel || 'h2');

  const dropDownValue = [
    { value: { width: '150', height: '150' }, label: 'Thumbnail - 150 X 150' },
    { value: { width: '300', height: '300' }, label: 'Medium - 300 X 300' },
    { value: { width: '768', height: '768' }, label: 'Medium Large - 768 X 768' },
    { value: { width: '1024', height: '1024' }, label: 'Large - 1024 X 1024' },
  ];

  const dropDownTagValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const handleSizeChange = (e) => {
    const selectedSize = JSON.parse(e.target.value);
    handleImageBoxImageSizeChange(section_id, selectedSize);
  };

  const currentImageSizeString = JSON.stringify(currentImageSize);

  const getAiGeneratedTitle = (value) => {
    // setGroupTitle(value);
    setImageBox((prev) => ({
      ...prev,
      title: value,
    }));
    handleImageBoxTitleChange(section_id, value);
  };
  const onContentChange = (e) => {
    const newValue = e.target.value;
    setImageBox((prev) => ({
      ...prev,
      title: newValue,
    }));
    handleImageBoxTitleChange(section_id, newValue);
  };
  const onContentDescriptionChange = (e) => {
    const newValue = e.target.value;
    setImageBox((prev) => ({
      ...prev,
      description: newValue,
    }));
    handleImageBoxDescriptionChange(section_id, newValue);
  };
  const handleHeadingChange = (e) => {
    const newTag = e.target.value;
    setSelectedHeadingLevel(newTag);
    handleImageBoxHtmlTagChange(section_id, newTag);
  };

  const handleLinkChange = (e) => {
    const selectLink = e.target.value;
    handleImageBoxImageLinkChange(section_id, selectLink);
  };

  return (
    <div>
      {previewImage && (
        <img
          src={previewImage?.url}
          alt='Uploaded Preview'
          className='w-full mb-5 max min-h-28 max-h-72 object-contain'
        />
      )}

      <input
        type='file'
        accept='image/*'
        name='url'
        onChange={(e) => handleIconBoxImageUpload(e, section_id)}
        className='truncate max-w-full overflow-hidden block mb-5'
      />

      <div className='w-full'>
        <label>Image Resolution</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            onChange={handleSizeChange}
            value={currentImageSizeString}
          >
            {dropDownValue.map((obj) => (
              <option key={obj?.label} value={JSON.stringify(obj?.value)}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='mt-2'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Title</span>
          <GenerateLiveEditiorTitle
            getAiGeneratedTitle={getAiGeneratedTitle}
            section_type={section_type}
          />
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={previewImage?.title}
          onChange={onContentChange}
        />
      </div>
      <div className='w-full'>
        <label>HTML Tag</label>
        <div className='relative'>
          <select
            className='w-full mt-2'
            value={previewImage?.htmlTag}
            onChange={handleHeadingChange}
          >
            {dropDownTagValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>
      <div className='mt-2'>
        <label htmlFor='group title' className='flex justify-between'>
          <span htmlFor='image'>Description</span>
        </label>
        <textarea
          className='border w-full outline-none rounded-[6px] main-border-color px-4 py-2 text-sm mt-2'
          placeholder='Add your heading text here...'
          value={previewImage?.description}
          onChange={onContentDescriptionChange}
          rows={6}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <label htmlFor='group title'>Link</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='group title'
          name='group title'
          placeholder='Paste URL here...'
          onChange={handleLinkChange}
          value={currentImageLink}
        />
      </div>
      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ImageBoxSettingSection;
