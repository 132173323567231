import { Skeleton } from '@mui/material';
import { ArrowLeft, Code, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import module, { formatCode } from '../../controllers/QuillToolBar';
import SourceCode from '../../controllers/SourceCode';
import UpdateCustomMetaField from '../../controllers/UpdateCustomMetaField';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import NoDataFound from '../Main/NoDataFound';
import Notification from '../Notification';
function UpdateVarient() {
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [description, setDescription] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [groupValueArray, setGroupValueArray] = useState([]);
  const [varientGroup, setvarientGroup] = useState();
  const [formSpinner, setFormSpinner] = useState(true);
  const [groupName, setGroupName] = useState('');
  const [meta_fields, setmeta_fields] = useState({});
  const [isCustomField, setIsCustomField] = useState(false);
  const getMetaField = async () => {
    const ApiCall = await API.get(`/admin/metafieldgroup/getMetaFieldGroup/variant`);
    const result = decryptData(ApiCall?.data)
    if (result?.data?.custom_fields?.length > 0) {
      setIsCustomField(true);
    }
  }
  useEffect(() => {
    getMetaField();
  }, [])
  const getVarientById = async () => {
    setFormSpinner(true);
    try {
      const ApiCall = await API.get(
        `/admin/product/GetProductVariantsGroupById/${params?.variantsid}`,
      );
      const result = decryptData(ApiCall?.data)
      setGroupName(result?.data?.group_name);
      setDescription(result?.data?.description);
      setGroupValueArray(result?.data?.group_value);
      setvarientGroup({ group_name: result?.data?.group_name });
      setmeta_fields(result?.data?.custom_fields);
      setFormSpinner(false);
    } catch (error) {
      setNodata(true);
      console.log(error);
    }
  };
  useEffect(() => {
    getVarientById();
  }, [params?.variantsid]);
  const handleChange = (e) => {
    setIsChange(true);
    setvarientGroup({ ...varientGroup, [e.target.name]: e.target.value });
  };
  const [isChange, setIsChange] = useState(false);
  const [groupValue, setGroupValue] = useState([]);
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  // save Group Values In Array
  const SaveGroupValue = () => {
    if (groupValue.length > 0) {
      if (groupValue.includes(',')) {
        let TagSeperator = groupValue.split(',');
        let finalArray = [];
        let productTags = [...groupValueArray, ...TagSeperator];
        productTags.filter((tag) => {
          const searchRegex = new RegExp(`^${tag.trim()}$`, 'i');
          let exists = finalArray.some((finalTag) => searchRegex.test(finalTag));
          if (!exists) {
            finalArray.push(tag.trim());
          }
        });
        setGroupValueArray(finalArray);
      } else {
        const searchRegex = new RegExp(`^${groupValue}$`, 'i');

        const exists = groupValueArray.some((tag) => searchRegex.test(tag));

        if (!exists) {
          setGroupValueArray((prev) => [...prev, groupValue?.trim()]);
        }
      }
      setGroupValue('');
    }
  };
  // remove Tag
  const RemoveTag = (index) => {
    if (index > -1) {
      // only splice array when item is found
      groupValueArray.splice(index, 1); // 2nd parameter means remove one item only
    }
    setGroupValueArray([...groupValueArray]);
  };
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        group_name: varientGroup?.group_name?.trim(),
        group_value: groupValueArray,
        description,
        custom_fields: meta_fields,
      })
      const data = await API.put(
        `/admin/product/updateProductVariantsGroup/${params?.variantsid}`, payload
      );
      if (data.status === 200 || data.status === 304) {
        setSaveChanges({
          isUnsaveChanges: false,
          showChangesPopup: false,
          backLink: '/group-variants',
          title: 'edit varient',
        });
        navigate('/group-variants');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };

  //Quil To HTML code and Functions
  const quillRef = useRef(null);
  const handleQuillChange = (value) => {
    setIsChange(true);
    setDescription(value);
  };
  const [html, setHtml] = useState({ htmlPP: false, code: '' });
  const handleViewHtml = (e) => {
    e.preventDefault();
    const quill = quillRef.current.getEditor();
    const htmlContent = quill.root.innerHTML;
    const formattedHtml = formatCode(htmlContent);
    setHtml({ htmlPP: true, code: formattedHtml }); // Log or use the HTML content as needed
  };
  const saveHTML = (value) => {
    setHtml({ ...html, htmlPP: false });
    setDescription(value);
  };
  const closeCode = () => {
    setHtml({ ...html, htmlPP: false });
  };
  useEffect(() => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: false,
        backLink: '/group-variants',
        title: 'edit varient',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange === true) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/group-variants',
        title: 'edit varient',
      });
    } else {
      navigate('/group-variants');
    }
  };

  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_group_variants'), path: '/group-variants' },
                {
                  name: t('lang_Update_group_variant'),
                  path: '/group-variants/edit-group-variants',
                },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Update_group_variant')} - {groupName}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            {formSpinner ? (
              <>
                <div className='space-y-4 secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
                  <div className='flex items-center space-x-4 w-full'>
                    <div className='w-full'>
                      <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                      <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                    </div>
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[45px]' variant='rounded-[6px]' />
                  </div>
                  <div className='w-full'>
                    <Skeleton className='w-[200px] mb-1' variant='rounded-[6px]' />
                    <Skeleton className='w-full min-h-[200px]' variant='rounded-[6px]' />
                  </div>
                </div>
                <Skeleton className='w-full mt-4 min-h-[45px]' variant='rounded-[6px]' />
              </>
            ) : (
              <>
                <div className='secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
                  <div className='mb-4'>
                    <label htmlFor='product_name' className='lowercase'>
                      {t('lang_group_name')}
                      <span className='text-red-500 ms-1'>*</span>
                    </label>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='group_name'
                      name='group_name'
                      value={varientGroup?.group_name}
                      onChange={handleChange}
                      placeholder='Group name'
                    />
                  </div>
                  <div className='mb-2'>
                    <div className='flex items-center justify-between'>
                      <label htmlFor='Description' className='lowercase'>
                        {t('lang_group_value')}
                        <span className='text-red-500 ms-1'>*</span>
                      </label>
                    </div>
                    <input
                      className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                      type='text'
                      id='group_value'
                      name='group_value'
                      value={groupValue}
                      onChange={(e) => {
                        setGroupValue(e.target.value);
                        setIsChange(true);
                      }}
                      onKeyDown={(e) => (e.key === 'Enter' ? SaveGroupValue() : '')}
                      placeholder='e.g. Black'
                    />
                    <p className='w-full text-xs mt-0 text-gray-400'>
                      {t('lang_use')} <b className='text-gray-500'>{t('lang_Enter')}</b>{' '}
                      {t('lang_key_to_separate_the_value')}
                    </p>
                  </div>
                  <div className='flex flex-wrap items-center mb-4'>
                    {groupValueArray &&
                      groupValueArray.map((elem, index) => {
                        return (
                          <span
                            key={index}
                            className='primary-bg-color table-text flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                          >
                            {elem}
                            <X
                              className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                              onClick={() => RemoveTag(index)}
                            />
                          </span>
                        );
                      })}
                  </div>
                  <div className='mb-4'>
                    <div className='flex justify-between items-center'>
                      <label htmlFor='description'>{t('lang_group_description')}</label>
                      <section
                        className='inline-flex items-center cursor-pointer text-link hover:underline text-[13px] rounded-[6px] transition duration-300 px-1'
                        onClick={handleViewHtml}
                      >
                        <Code className='w-[15px] h-[15px] me-1' /> {t('lang_source_code')}
                      </section>
                    </div>
                    <ReactQuill
                      ref={quillRef}
                      modules={module}
                      theme='snow'
                      value={description?.length && description?.split(' ').slice(0, 280).join(' ')}
                      onChange={handleQuillChange}
                    />
                    <div className='w-full flex justify-between'>
                      <p className='text-xs mt-0 text-gray-400'>{t('lang_Only_Words_allowed', { words: `${description?.split(/\s+/)?.filter(Boolean)?.length || 0}/280` })}</p>
                    </div>
                  </div>
                </div>
                <div className='my-4'>
                  {isCustomField && <UpdateCustomMetaField
                    meta_fields={meta_fields}
                    setmeta_fields={setmeta_fields}
                    heading='variant'
                  />}
                </div>
              </>
            )}
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_to_group_variants')}
            </Link>
            <div>
              {groupValueArray?.length === 0 ||
                varientGroup?.group_name === '' ||
                !varientGroup?.group_name ? (
                <button
                  type='button'
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Variant_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[196px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_loading')}...</span>
                </button>
              ) : (
                <button
                  type='button'
                  onClick={(e) => HandleSubmit(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Update_Variant_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {html?.htmlPP && (
        <SourceCode
          open={html?.htmlPP}
          htmlCode={html?.code}
          closeCode={closeCode}
          saveHTML={saveHTML}
        />
      )}
    </>
  );
}

export default UpdateVarient;
