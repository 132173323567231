import { Checkbox, Pagination, Popover, Tooltip } from '@mui/material';
import {
  ArrowLeft,
  Edit,
  Filter,
  PlusCircle,
  Trash,
  Trash2,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import API from '../../API';
import { DataLoad, PlanPermission } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';

function PortfolioCategory() {
  const navigate = useNavigate();
  const [PortfolioCategory, setAllPortfolioCategory] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [Page, setPage] = useState(getInitialPage());
  const { setPlanPermission } = useContext(PlanPermission);
  const [clearRow, setClearRow] = useState(false);
  const initialLimit = localStorage.getItem('limit');
  const [limit, setLimit] = useState(initialLimit);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [iconVisible, setIconVisible] = useState(false);
  const [orderBy, setOrderBy] = useState({ orderByValue: '', isDecending: false });
  var startRecord = (PortfolioCategory?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    PortfolioCategory?.data?.currentPage * limit,
    PortfolioCategory?.data?.totalRecords,
  );
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  // Sorting Data
  const SortData = (sort_by) => {
    if (!orderBy.isDecending) {
      setOrderBy({ orderByValue: 'DESC', isDecending: true });
    } else {
      setOrderBy({ orderByValue: 'ASC', isDecending: false });
    }
  };
  // fetch All Categories
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();

  useEffect(() => {
    if (search === '' || search?.length > 2) {
      GetPortfolioCategoriesWithAllParent();
    }
  }, [search]);

  const GetPortfolioCategoriesWithAllParent = async () => {
    setDataLoading(true);
    try {
      const GetCategories = await API.get(
        `/admin/PortfolioCategory/GetPortfolioCategoriesWithParent?page=${Page}&limit=${limit}&searchkey=${search?.length > 2 ? search : ''
        }&sort_by=${sortBy}&order_by=${orderBy.orderByValue}`,
      );
      if (GetCategories.status === 200 || GetCategories.status === 304) {
        const result = decryptData(GetCategories?.data)
        setAllPortfolioCategory(result);
        if (Number(startRecord) > Number(result?.data?.totalRecords)) {
          setPage(Page - 1);
        }
      }
    } catch (error) {
      if (error?.response?.status === 882) {
        setPlanPermission({ isPlanPermissions: false, forModule: 'portfolio' });
      }
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    GetPortfolioCategoriesWithAllParent();
    setClearRow(!clearRow);
  }, [Page, limit, sortBy, orderBy]);
  // Api call For delete  Product
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const HandleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      })
      const data = await API.post(`/admin/PortfolioCategory/deletePortfolioCategory`, payload);
      if (data.status === 200 || data.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Category Removed Successfully');
        setSeverity('success');
        setSelectedRowsId([]);
        setClearRow(!clearRow);
        GetPortfolioCategoriesWithAllParent();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  useEffect(() => {
    localStorage.setItem('limit', limit);
  }, [limit]);

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSelectRow = (e, id) => {
    if (e?.nativeEvent?.shiftKey) {
      const prevIndex = PortfolioCategory?.data?.records?.findIndex(
        (x) => x._id === selectedRowsId[selectedRowsId?.length - 1],
      );
      const currIndex = PortfolioCategory?.data?.records?.findIndex((x) => x._id === id);
      const startIndex = Math.min(prevIndex, currIndex);
      const endIndex = Math.max(prevIndex, currIndex);
      const shiftSelected = PortfolioCategory?.data?.records?.slice(startIndex, endIndex + 1);

      if (selectedRowsId?.includes(id)) {
        setSelectedRowsId((prev) => [
          ...new Set(prev.filter((x) => !shiftSelected.find((item) => item._id === x))),
        ]);
      } else {
        setSelectedRowsId((prev) => [
          ...new Set([...prev, ...shiftSelected.map((item) => (!item.default ? item._id : ''))]),
        ]);
      }
    } else if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(
          PortfolioCategory?.data?.records?.map((x) => (!x?.default ? x?._id : '')),
        );
      }
    } else {
      const existId = selectedRowsId?.includes(id);
      if (!existId) {
        setSelectedRowsId((prev) => [...prev, id]);
      } else {
        setSelectedRowsId((prev) => prev.filter((x) => x !== id));
      }
    }
  };
  return (
    <>
      <>
        <ConfirmationPopup
          heading='Delete Portfolio Category'
          subheading='Do you really want to delete this portfolio category from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={HandleDelete}
        />
        {isNotification && notificationMsg && (
          <Notification message={notificationMsg} close={handleClose} severity={severity} />
        )}
        <div className='flex flex-wrap items-end justify-between w-full overflow-auto pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/dashboard');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Portfolio_Categories'), path: '/portfolio-categories' },
                ]}
              />
              <div className='flex items-end'>
                <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                  {t('lang_Portfolio_Categories')}{' '}
                </h4>
              </div>
            </div>
          </div>
          <div className='flex items-center'>
            <button
              className='flex items-center btn text-white text-sm font-medium py-2.5 px-8 rounded-[6px] transition duration-300'
              onClick={() => navigate('/portfolio-categories/add-portfolio-category')}
            >
              <PlusCircle className='w-4 h-4 sm:w-[18px] sm:h-[18px] me-2' />
              {t('Add Portfolio Category')}
            </button>
          </div>
        </div>
        <div className='flex justify-between space-x-3 w-full relative'>
          <div className='w-full space-y-2'>
            <div className='flex items-center justify-between'>
              <div className='flex items-center'>
                {selectedRowsId && selectedRowsId?.length > 0 && (
                  <>
                    <span className='text-sm px-3'>
                      {selectedRowsId?.length} {t('lang_selected')}
                    </span>
                    |
                    <button
                      className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                      onClick={(e) => {
                        e.preventDefault();
                        setConfirmationPopup(true);
                      }}
                    >
                      <Trash2 className='w-3.5 h-3.5' />
                      <span>{t('lang_delete_selection')}</span>
                    </button>
                  </>
                )}
              </div>
                {PortfolioCategory?.data?.totalRecords > 0 && <Tooltip title='Filter' arrow>
                  <div className='filter-btn' onClick={(e) => {setFilter(!filter);setAnchorEl(e.currentTarget)}}>
                    <button className=''>
                      <Filter className='h-5 w-5' />
                    </button>
                  </div>
                </Tooltip>}
            </div>
            <div
              className={'flex w-full h-full overflow-hidden relative'}
            >
              <div className='w-full'>
                {dataLoading && !PortfolioCategory ? (
                  <TableSkeleton forTable='order' />
                ) : PortfolioCategory?.data?.records?.length > 0 ? (
                  <div className='w-full  transition-all duration-100 blog-table mb-1 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
                    <table className='w-full'>
                      <thead>
                        <tr>
                          <td className='px-3 table-head-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length === PortfolioCategory?.data?.records?.length
                              }
                              indeterminate={
                                selectedRowsId?.length !==
                                PortfolioCategory?.data?.records?.length &&
                                selectedRowsId?.length > 0
                              }
                              onChange={(e) => {
                                handleSelectRow(e, 'all');
                              }}
                            />
                          </td>
                          <td className='min-w-[280px] max-w-[280px] px-3'>
                            <div className='flex items-center'>
                              <button
                                className={`uppercase flex ${iconVisible && sortBy === 'lowercase_category'
                                  ? 'text-gray-700'
                                  : ''
                                  }`}
                                onMouseEnter={() => {
                                  setIconVisible(true);
                                }}
                                onMouseLeave={() => {
                                  setIconVisible(false);
                                }}
                                onClick={() => {
                                  setSortBy('lowercase_category');
                                  SortData('lowercase_category');
                                }}
                              >
                                {t('lang_Portfolio_Category')}
                                {iconVisible && sortBy === 'lowercase_category' ? (
                                  !orderBy.isDecending ? (
                                    <CaretUpFill className='ms-2' />
                                  ) : (
                                    <CaretDownFill className='ms-2' />
                                  )
                                ) : (
                                  <></>
                                )}
                              </button>
                            </div>
                          </td>
                          <td className='w-full px-3 uppercase'>{t('lang_description')}</td>
                          <td className='w-[120px] px-3 text-right uppercase'>
                            {t('lang_action')}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {PortfolioCategory?.data?.records?.map((elem, index) => {
                          return (
                            <tr
                              key={index}
                              className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                                }`}
                              onClick={() =>
                                navigate(`/portfolio-categories/${elem._id}`)
                              }
                            >
                              <td className='px-3 table-body-checkbox' onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                  disabled={elem?.default}
                                  checked={
                                    selectedRowsId?.length > 0 &&
                                    selectedRowsId?.find((x) => x === elem?._id)
                                  }
                                  onChange={(e) => handleSelectRow(e, elem?._id)}
                                />
                              </td>
                              <td className='min-w-[280px] max-w-[280px] px-3'>
                                <span className='capitalize'>{elem.parent_category || '-'}</span>
                              </td>
                              <td className='w-full px-3'>
                                <span className='capitalize line-clamp-2'>
                                  {elem?.description
                                    ? elem.description.replace(/<[^>]*>/g, ' ')
                                    : '-'}
                                </span>
                              </td>
                              <td className='w-[120px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                                <div className='flex items-center justify-end w-full space-x-4'>
                                  <div>
                                    <Tooltip title='Edit' arrow>
                                      <button className=''>
                                        <Edit
                                          className='text-black-500 w-[17px] h-[17px]'
                                          onClick={() =>
                                            navigate(`/portfolio-categories/${elem._id}`)
                                          }
                                        />
                                      </button>
                                    </Tooltip>
                                  </div>
                                  {elem?.default !== true ? (
                                    <div>
                                      <Tooltip title='Delete' arrow>
                                        <button className=''>
                                          <Trash
                                            className='text-red-500 w-[17px] h-[17px]'
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setConfirmationPopup(true);
                                              setSelectedRowsId([elem?._id]);
                                            }}
                                          />
                                        </button>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <NoRecords />
                )}
                {/* Paginator */}
                {PortfolioCategory?.data ? (
                  <div className='secondary-bg-color border main-border-color hidden sm:flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                    <div className='hidden sm:block'>
                      <p className='flex w-full  space-x-4'>
                        <span className='text-sm font-medium'>
                          {limit === 'all'
                            ? `${PortfolioCategory?.data?.totalRecords}` + ' ' + 'Records'
                            : `${startRecord} - ${endRecord} of ${PortfolioCategory?.data?.totalRecords} Records`}
                        </span>
                      </p>
                    </div>
                    <div>
                      {PortfolioCategory?.data?.totalPages !== 1 ? (
                        <Pagination
                          count={PortfolioCategory?.data?.totalPages}
                          page={Page}
                          onChange={(e, v) => setPage(v)}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Popover
                open={filter}
                anchorEl={anchorEl}
                onClose={() => { setFilter(false); setAnchorEl(null) }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                PaperProps={{
                  style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
                }}
                className='rounded-[6px]'
              >
                <div className='p-[20px] filter'>
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t('lang_filter')}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block capitalize'>
                      {t('lang_search')}
                    </span>
                    <label className='relative block w-full'>
                      <input
                        className='w-full  placeholder:text-slate-400 block secondary-bg-color border main-border-color rounded-[6px] py-2 pl-3 pr-12 shadow-sm focus:outline-none sm:text-sm'
                        placeholder='Search Portfolio Category'
                        type='text'
                        name='search'
                        value={search}
                        onChange={(e) => {
                          setPage(1);
                          setSearch(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_set_items_limit')}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full text-slate-500 font-medium text-sm border main-border-color p-1.5'
                        name='limit'
                        value={limit}
                        onChange={(e) => {
                          setPage(1);
                          e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                        }}
                      >
                        {PortfolioCategory?.data?.totalRecords > 10 ? (
                          <>
                            <option value='10'>10</option>
                          </>
                        ) : (
                          <></>
                        )}
                        {PortfolioCategory?.data?.totalRecords > 20 ? (
                          <option value='20'>20</option>
                        ) : (
                          <></>
                        )}
                        {PortfolioCategory?.data?.totalRecords > 50 ? (
                          <option value='50'>50</option>
                        ) : (
                          <></>
                        )}
                        <option value='all'>All</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                </div>
                </div>
                </Popover>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default PortfolioCategory;
