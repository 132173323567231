import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ParallaxBanner } from 'react-scroll-parallax';
const Banner = ({ colors, bannerData, bannerDesign }) => {
  const filterBanner =
    bannerData?.section?.banners && bannerData?.section?.banners?.filter((x) => x.banner_status);
  const banner_limit =
    bannerData?.section?.banner_limit < filterBanner?.length
      ? bannerData?.section?.banner_limit
      : filterBanner?.length;
  const [isHoveredIndex, setIsHoveredIndex] = useState();
  return bannerData?.status ? (
    <div
      // style={{ width: banner_limit > 1 ? '90%' : '100%' }}
      className={`${
        banner_limit > 1 ? 'mx-auto' : 'w-full'
      } mx-auto grid grid-cols-1 md:grid-cols-${banner_limit} gap-5`}
      style={{
        display: bannerDesign?.display,
        flexDirection: bannerDesign?.direction,
        alignItems: bannerDesign?.align_item,
        gap: `${bannerDesign?.gap}px`,
        marginTop: `${bannerDesign?.margin_top}px`,
        marginBottom: `${bannerDesign?.margin_bottom}px`,
        marginLeft: `${bannerDesign?.margin_left}px`,
        marginRight: `${bannerDesign?.margin_right}px`,
        paddingTop: `${bannerDesign?.padding_top}px`,
        paddingBottom: `${bannerDesign?.padding_bottom}px`,
        paddingLeft: `${bannerDesign?.padding_left}px`,
        paddingRight: `${bannerDesign?.padding_right}px`,
        height: `${bannerDesign?.height}${bannerDesign?.height_unit}`,
        width: `${bannerDesign?.width}${bannerDesign?.width_unit}`,
        borderStyle: bannerDesign?.border_style,
        borderWidth: `${bannerDesign?.border_width}px`,
        borderColor: bannerDesign?.border_color,
        float: bannerDesign?.float,
        maxHeight: `${bannerDesign?.max_height}${bannerDesign?.max_height_unit}`,
        maxWidth: `${bannerDesign?.max_width}${bannerDesign?.max_width_unit}`,
        minHeight: `${bannerDesign?.min_height}${bannerDesign?.min_height_unit}`,
        minWidth: `${bannerDesign?.min_width}${bannerDesign?.min_width_unit}`,
      }}
    >
      {bannerData?.section?.banner_parallax === true
        ? bannerData?.section?.banners?.map((elem, index) => {
            return (
              elem?.banner_status === true && (
                <ParallaxBanner
                  layers={[{ image: elem?.banner_img ? elem?.banner_img : noSlider, speed: -10 }]}
                  className='aspect-[3.3/1]'
                >
                  <div
                    className={`w-[90%] left-[5%] mx-auto absolute top-[50%] -translate-y-[50%] flex items-center ${
                      elem?.text_position === 'left'
                        ? 'justify-start'
                        : elem?.text_position === 'right'
                        ? 'justify-end'
                        : 'justify-center'
                    }`}
                  >
                    <div
                      className={`${banner_limit > 1 ? 'w-full lg:w-[40%]' : 'w-full lg:w-[35%]'}`}
                    >
                      <div
                        className='w-full break-words overflow-hidden'
                        dangerouslySetInnerHTML={{ __html: elem.banner_text }}
                      ></div>
                      {elem?.banner_button_text?.length && elem?.is_button_visible ? (
                        <Link
                          aria-label='manufacturers'
                          style={{
                            transition: 'background-color 0.3s ease, color 0.3s ease',
                            borderRadius: elem?.banner_button_redius,
                            backgroundColor:
                              isHoveredIndex === index
                                ? elem?.banner_button_hover_color || ''
                                : elem?.banner_button_color,
                            color:
                              isHoveredIndex === index
                                ? elem?.banner_button_hover_text_color || ''
                                : elem?.banner_button_text_color,
                          }}
                          onMouseEnter={() => {
                            setIsHoveredIndex(index);
                          }}
                          onMouseLeave={() => setIsHoveredIndex()}
                          className={`inline-block capitalize mt-[10px] lg:mt-[20px] px-5 md:px-9 py-2.5 md:py-3 text-xs lg:text-sm font-medium`}
                        >
                          {elem?.banner_button_text}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </ParallaxBanner>
              )
            );
          })
        : filterBanner &&
          filterBanner?.slice(0, banner_limit)?.map((elem, index) => {
            return elem?.banner_status ? (
              <div
                className={`md:h-[300px] lg:h-[400px] ${
                  banner_limit > 1 ? 'xl:h-[450px]' : 'xl:h-[600px]'
                } block relative`}
                key={index}
              >
                <img
                  width={1500}
                  height={500}
                  src={elem?.banner_img ? elem?.banner_img : noSlider}
                  alt='slider Image'
                  className='w-full h-full object-cover object-center'
                  style={{ objectFit: bannerDesign?.fit }}
                />
                <div
                  className={`w-[90%] left-[5%] mx-auto absolute top-[50%] -translate-y-[50%] flex items-center ${
                    elem?.text_position === 'left'
                      ? 'justify-start'
                      : elem?.text_position === 'right'
                      ? 'justify-end'
                      : 'justify-center'
                  }`}
                >
                  <div
                    className={`${banner_limit > 1 ? 'w-full lg:w-[40%]' : 'w-full lg:w-[35%]'}`}
                  >
                    <div
                      className='w-full break-words overflow-hidden'
                      dangerouslySetInnerHTML={{ __html: elem.banner_text }}
                    ></div>
                    {elem?.banner_button_text?.length && elem?.is_button_visible ? (
                      <Link
                        aria-label='manufacturers'
                        style={{
                          transition: 'background-color 0.3s ease, color 0.3s ease',
                          borderRadius: elem?.banner_button_redius,
                          backgroundColor:
                            isHoveredIndex === index
                              ? elem?.banner_button_hover_color || ''
                              : elem?.banner_button_color,
                          color:
                            isHoveredIndex === index
                              ? elem?.banner_button_hover_text_color || ''
                              : elem?.banner_button_text_color,
                        }}
                        onMouseEnter={() => {
                          setIsHoveredIndex(index);
                        }}
                        onMouseLeave={() => setIsHoveredIndex()}
                        className={`inline-block capitalize mt-[10px] lg:mt-[20px] px-5 md:px-9 py-2.5 md:py-3 text-xs lg:text-sm font-medium`}
                      >
                        {elem?.banner_button_text}
                      </Link>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          })}
    </div>
  ) : (
    <></>
  );
};

export default Banner;
