import React from 'react';
import ReactDragListView from 'react-drag-listview';
import {
  applyFontSizeToTags,
  getWidthClass,
  mapPositionToCSS,
} from '../../../../../controllers/DynamicEditorSwitchCase';
import SvgSection from '../../../../../controllers/SvgSection';

const SecondStaticContactSection = ({
  handleSectionClick,
  openAddSection,
  selectSection,
  setElementorId,
  sectionIndex,
  section,
  dropContainerRef,
  sectionAdd,
  dragProps,
  editSection,
}) => {
  const filteredSections = sectionAdd?.secondContactComponment?.[sectionIndex]?.filter(
    (obj) => obj?.type === 'static_contact-2',
  );

  return (
    <div className='w-full'>
      {filteredSections?.map((obj, itemIndex) => {
        const isActive =
          editSection?.section_type === obj?.blockName &&
          editSection?.section_id === obj?.id &&
          editSection?.section_index === itemIndex;

        const description = obj?.content?.description || '-';
        const fontSize = obj?.style?.description_font_size;
        const lineHeight = obj?.style?.description_font_height;
        const styledContent = applyFontSizeToTags(description, fontSize, lineHeight);

        return (
          <ReactDragListView {...dragProps}>
            <section
              class='text-gray-600 body-font relative'
              key={itemIndex}
              className={`cursor-pointer border-[2px] transition-all duration-300 ${
                isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
              }  hover:border-2 hover:border-blue-500 overflow-auto`}
              onClick={(e) => {
                selectSection(e, obj?.id, obj?.blockName, itemIndex),
                  openAddSection(e, 'static_contact-2', 'setting');
              }}
            >
              <div
                className='container mx-auto flex sm:flex-nowrap flex-wrap'
                style={{
                  paddingTop: `${obj?.style?.padding_top}px`,
                  paddingBottom: `${obj?.style?.padding_bottom}px`,
                  paddingLeft: `${obj?.style?.padding_left}px`,
                  paddingRight: `${obj?.style?.padding_right}px`,
                  marginTop: `${obj?.style?.margin_top}px`,
                  marginBottom: `${obj?.style?.margin_bottom}px`,
                  marginLeft: `${obj?.style?.margin_left}px`,
                  marginRight: `${obj?.style?.margin_right}px`,
                  borderTop: `${obj?.style?.border_top}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderBottom: `${obj?.style?.border_bottom}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderLeft: `${obj?.style?.border_left}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderRight: `${obj?.style?.border_right}px ${obj?.style?.border_style} ${obj?.style?.border_color}`,
                  borderTopLeftRadius: `${obj?.style?.border_radius_top}px`,
                  borderTopRightRadius: `${obj?.style?.border_radius_right}px`,
                  borderBottomLeftRadius: `${obj?.style?.border_radius_left}px`,
                  borderBottomRightRadius: `${obj?.style?.border_radius_bottom}px`,
                  boxShadow: `${obj?.style?.box_shadow_horizontal}px ${obj?.style?.box_shadow_vertical}px ${obj?.style?.box_shadow_blur}px ${obj?.style?.box_shadow_spread}px ${obj?.style?.box_shadow_color}`,
                  background:
                    obj?.style?.background_gradient === 'gradient'
                      ? `linear-gradient(${obj?.style?.gradient_angle}deg, ${obj?.style?.gradient_color_start} ${obj?.style?.gradient_start_position}%, ${obj?.style?.gradient_color_end} ${obj?.style?.gradient_end_position}%)`
                      : obj?.style?.background_image
                      ? `url(${obj?.style?.background_image})`
                      : obj?.style?.background,
                  backgroundRepeat: obj?.style?.background_repeat,
                  backgroundAttachment: obj?.style?.background_attachment,
                  objectFit: obj?.style?.background_fit,
                  backgroundSize:
                    obj?.style?.background_fit === 'cover' ||
                    obj?.style?.background_fit === 'contain'
                      ? obj?.style?.background_fit
                      : `${obj?.style?.background_image_size?.width}px ${obj?.style?.background_image_size?.height}px`,
                  backgroundPosition: mapPositionToCSS(obj?.style?.background_position),
                }}
              >
                <div className='lg:w-2/3 md:w-1/2 bg-gray-300 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative'>
                  <iframe
                    width='100%'
                    height='100%'
                    className='absolute inset-0'
                    frameBorder='0'
                    title='map'
                    marginHeight='0'
                    marginWidth='0'
                    scrolling='no'
                    src='https://maps.google.com/maps?width=100%&height=600&hl=en&q=%C4%B0zmir+(My%20Business%20Name)&ie=UTF8&t=&z=14&iwloc=B&output=embed'
                    style={{
                      filter: 'grayscale(1) contrast(1.2) opacity(0.4)',
                    }}
                  ></iframe>
                  <div className='bg-white relative flex flex-wrap py-6 rounded shadow-md'>
                    <div className='lg:w-1/2 px-6'>
                      {obj?.content?.address_title_htmlTag === 'h1' ? (
                        <h1>{obj?.content?.address_title}</h1>
                      ) : obj?.content?.address_title_htmlTag === 'h2' ? (
                        <h2>{obj?.content?.address_title}</h2>
                      ) : obj?.content?.address_title_htmlTag === 'h3' ? (
                        <h3>{obj?.content?.address_title}</h3>
                      ) : obj?.content?.address_title_htmlTag === 'h4' ? (
                        <h4>{obj?.content?.address_title}</h4>
                      ) : obj?.content?.address_title_htmlTag === 'h5' ? (
                        <h5>{obj?.content?.address_title}</h5>
                      ) : obj?.content?.address_title_htmlTag === 'h6' ? (
                        <h6>{obj?.content?.address_title}</h6>
                      ) : obj?.content?.address_title_htmlTag === 'div' ? (
                        <div>{obj?.content?.address_title}</div>
                      ) : obj?.content?.address_title_htmlTag === 'p' ? (
                        <p>{obj?.content?.address_title}</p>
                      ) : obj?.content?.address_title_htmlTag === 'span' ? (
                        <span>{obj?.content?.address_title}</span>
                      ) : (
                        ''
                      )}
                      <p className='mt-1'>{obj?.content?.address_value}</p>
                    </div>
                    <div className='lg:w-1/2 px-6 mt-4 lg:mt-0'>
                      {obj?.content?.email_title_htmlTag === 'h1' ? (
                        <h1>{obj?.content?.email_title}</h1>
                      ) : obj?.content?.email_title_htmlTag === 'h2' ? (
                        <h2>{obj?.content?.email_title}</h2>
                      ) : obj?.content?.email_title_htmlTag === 'h3' ? (
                        <h3>{obj?.content?.email_title}</h3>
                      ) : obj?.content?.email_title_htmlTag === 'h4' ? (
                        <h4>{obj?.content?.email_title}</h4>
                      ) : obj?.content?.email_title_htmlTag === 'h5' ? (
                        <h5>{obj?.content?.email_title}</h5>
                      ) : obj?.content?.email_title_htmlTag === 'h6' ? (
                        <h6>{obj?.content?.email_title}</h6>
                      ) : obj?.content?.email_title_htmlTag === 'div' ? (
                        <div>{obj?.content?.email_title}</div>
                      ) : obj?.content?.email_title_htmlTag === 'p' ? (
                        <p>{obj?.content?.email_title}</p>
                      ) : obj?.content?.email_title_htmlTag === 'span' ? (
                        <span>{obj?.content?.email_title}</span>
                      ) : (
                        ''
                      )}
                      <a className='text-indigo-500 leading-relaxed'>{obj?.content?.email_value}</a>
                      {obj?.content?.phone_title_htmlTag === 'h1' ? (
                        <h1>{obj?.content?.phone_title}</h1>
                      ) : obj?.content?.phone_title_htmlTag === 'h2' ? (
                        <h2>{obj?.content?.phone_title}</h2>
                      ) : obj?.content?.phone_title_htmlTag === 'h3' ? (
                        <h3>{obj?.content?.phone_title}</h3>
                      ) : obj?.content?.phone_title_htmlTag === 'h4' ? (
                        <h4>{obj?.content?.phone_title}</h4>
                      ) : obj?.content?.phone_title_htmlTag === 'h5' ? (
                        <h5>{obj?.content?.phone_title}</h5>
                      ) : obj?.content?.phone_title_htmlTag === 'h6' ? (
                        <h6>{obj?.content?.phone_title}</h6>
                      ) : obj?.content?.phone_title_htmlTag === 'div' ? (
                        <div>{obj?.content?.phone_title}</div>
                      ) : obj?.content?.phone_title_htmlTag === 'p' ? (
                        <p>{obj?.content?.phone_title}</p>
                      ) : obj?.content?.phone_title_htmlTag === 'span' ? (
                        <span>{obj?.content?.phone_title}</span>
                      ) : (
                        ''
                      )}
                      <p className='leading-relaxed'>{obj?.content?.phone_value}</p>
                    </div>
                  </div>
                </div>
                <div className='lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0'>
                  {obj?.content?.htmlTag === 'h1' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '40px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '45px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h2' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '36px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '41px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h3' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '32px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '37px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h4' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '26px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '33px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h5' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '22px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '29px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'h6' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: obj?.style?.title_font_size
                          ? `${obj?.style?.title_font_size}px`
                          : '18px',
                        lineHeight: obj?.style?.title_font_height
                          ? `${obj?.style?.title_font_height}px`
                          : '25px',
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'div' ? (
                    <div
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </div>
                  ) : obj?.content?.htmlTag === 'span' ? (
                    <span
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </span>
                  ) : obj?.content?.htmlTag === 'p' ? (
                    <p
                      style={{
                        fontWeight: obj?.style?.title_font_weight,
                        fontSize: `${obj?.style?.title_font_size}px`,
                        lineHeight: `${obj?.style?.title_font_height}px`,
                        color: obj?.style?.title_font_color,
                        textAlign: obj?.style?.title_font_align,
                        fontStyle: obj?.style?.title_font_style,
                        textDecoration: obj?.style?.title_font_decoration,
                        textTransform: obj?.style?.title_font_capitalize,
                        wordBreak: obj?.style?.title_font_break,
                        textWrap: obj?.style?.title_font_wrap,
                        letterSpacing: `${obj?.style?.title_letter_spacing}px`,
                        wordSpacing: `${obj?.style?.title_word_spacing}px`,
                        WebkitTextStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textStroke: `${obj?.style?.title_text_stroke}px ${obj?.style?.title_stroke_color}`,
                        textShadow: `${obj?.style?.title_text_shadow_horizontal}px ${obj?.style?.title_text_shadow_vertical}px ${obj?.style?.title_text_shadow_blur}px ${obj?.style?.title_text_shadow_color}`,
                        transform: `rotate(${obj?.style?.title_text_rotate}deg)`,
                      }}
                    >
                      {obj?.content?.title}
                    </p>
                  ) : (
                    <></>
                  )}
                  <div
                    style={{
                      fontWeight: obj?.style?.description_font_weight,
                      fontSize: `${obj?.style?.description_font_size}px`,
                      lineHeight: `${obj?.style?.description_font_height}px`,
                      color: obj?.style?.description_font_color,
                      textAlign: obj?.style?.description_font_align,
                      fontStyle: obj?.style?.description_font_style,
                      textDecoration: obj?.style?.description_font_decoration,
                      textTransform: obj?.style?.description_font_capitalize,
                      wordBreak: obj?.style?.description_font_break,
                      textWrap: obj?.style?.description_font_wrap,
                      letterSpacing: `${obj?.style?.description_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.description_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textStroke: `${obj?.style?.description_text_stroke}px ${obj?.style?.description_stroke_color}`,
                      textShadow: `${obj?.style?.description_text_shadow_horizontal}px ${obj?.style?.description_text_shadow_vertical}px ${obj?.style?.description_text_shadow_blur}px ${obj?.style?.description_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.description_text_rotate}deg)`,
                    }}
                    className='line-clamp-2'
                    dangerouslySetInnerHTML={{ __html: styledContent }}
                  ></div>
                  <div>
                    {obj?.content?.form?.map((formField, index) => {
                      return (
                        <>
                          <div key={index}>
                            <div className='mb-5 mt-5'>
                              <label
                                htmlFor='fieldType'
                                className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                              >
                                {formField?.labelValue}
                              </label>
                              {formField?.type === 'textarea' ? (
                                <textarea
                                  type={formField?.type ? formField?.type : ''}
                                  name={formField?.name ? formField?.name : ''}
                                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                  required={!!formField?.required}
                                  maxLength={formField?.maxLength ? formField?.maxLength : ''}
                                  minLength={formField?.minLength ? formField?.minLength : ''}
                                  placeholder={
                                    formField?.placeholder ? formField?.defaultValue : ''
                                  }
                                  autoComplete={formField?.autoComplete ? 'on' : 'off'}
                                  max={formField?.max ? formField?.max : ''}
                                  min={formField?.min ? formField?.min : ''}
                                ></textarea>
                              ) : formField?.type === 'select' ? (
                                <>
                                  <div className='mb-5 mt-2'>
                                    <select
                                      name={formField?.name ? formField.name : ''}
                                      type={formField?.type ? formField?.type : ''}
                                      required={!!formField?.required}
                                      className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    >
                                      {formField?.options?.map((val, index) => {
                                        return (
                                          <option key={index} value={val}>
                                            {val}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </>
                              ) : formField?.type === 'checkbox' || formField?.type === 'radio' ? (
                                <>
                                  <div className='mb-5 mt-2'>
                                    {formField?.options?.map((val, index) => {
                                      return (
                                        <div className='flex items-center mb-4' key={index}>
                                          <input
                                            type={formField?.type ? formField?.type : ''}
                                            name={formField?.name ? formField.name : ''}
                                            required={!!formField?.required}
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                          />
                                          <label
                                            htmlFor='default-checkbox'
                                            className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                          >
                                            {val}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : formField?.type === 'acceptence' ? (
                                <>
                                  <div className='mb-5 mt-2'>
                                    <div className='flex items-center mb-4'>
                                      <input
                                        type='checkbox'
                                        name={formField?.name ? formField?.name : ''}
                                        required={!!formField?.required}
                                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                      />
                                      <label
                                        htmlFor='default-checkbox'
                                        className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                      >
                                        {formField?.condition}
                                      </label>
                                    </div>
                                  </div>
                                </>
                              ) : formField?.type === 'quiz' ? (
                                <>
                                  <div className='mb-5 mt-2'>
                                    <label
                                      htmlFor='fieldType'
                                      className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                                    >
                                      {formField?.question}
                                    </label>
                                    {formField?.options?.map((val, index) => {
                                      return (
                                        <div className='flex items-center mb-4' key={index}>
                                          <input
                                            type='radio'
                                            name={formField?.name ? formField?.name : ''}
                                            required={!!formField?.required}
                                            className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                                          />
                                          <label
                                            htmlFor='default-checkbox'
                                            className='ms-2 text-sm font-medium text-gray-900 dark:text-gray-300'
                                          >
                                            {val}
                                          </label>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </>
                              ) : formField?.type === 'submit' ? (
                                <>
                                  <div className='mb-5 mt-2'>
                                    <button
                                      type={formField?.type ? formField?.type : 'button'}
                                      name={formField?.name ? formField?.name : ''}
                                      className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'
                                    >
                                      {formField?.buttonName}
                                    </button>
                                  </div>
                                </>
                              ) : formField?.type === 'date' ? (
                                <>
                                  <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                    Select Min Date
                                  </label>
                                  <input
                                    type='date'
                                    name={`${formField?.name}-min`}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    required={!!formField?.required}
                                    defaultValue={
                                      formField?.defaultValue
                                        ? formField?.defaultValue
                                        : formField?.min
                                    }
                                    min={formField?.min ? formField?.min : ''}
                                  />

                                  <label className='block mt-4 mb-1 text-sm font-medium text-gray-700 dark:text-gray-300'>
                                    Select Max Date
                                  </label>
                                  <input
                                    type='date'
                                    name={`${formField?.name}-max`}
                                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                    required={!!formField?.required}
                                    defaultValue={formField?.max ? formField?.max : ''}
                                    max={formField?.max ? formField?.max : ''}
                                  />
                                </>
                              ) : (
                                <input
                                  type={formField?.type ? formField?.type : ''}
                                  name={formField?.name ? formField?.name : ''}
                                  className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                                  required={!!formField?.required}
                                  maxLength={formField?.maxLength ? formField?.maxLength : ''}
                                  minLength={formField?.minLength ? formField?.minLength : ''}
                                  defaultValue={
                                    formField?.placeholder === false ? formField.defaultValue : ''
                                  }
                                  placeholder={
                                    formField?.placeholder ? formField?.defaultValue : ''
                                  }
                                  autoComplete={formField?.autoComplete ? 'on' : 'off'}
                                  max={formField?.max ? formField?.max : ''}
                                  min={formField?.min ? formField?.min : ''}
                                  accept={
                                    formField?.fileTypes
                                      ? formField?.fileTypes.replace(/\|/g, ',')
                                      : ''
                                  }
                                  limit={formField?.limit ? formField?.limit : ''}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div
                    class='mt-3'
                    style={{
                      fontWeight: obj?.style?.note_font_weight,
                      fontSize: obj?.style?.note_font_size
                        ? `${obj?.style?.note_font_size}px`
                        : '12px',
                      lineHeight: `${obj?.style?.note_font_height}px`,
                      color: obj?.style?.note_font_color,
                      textAlign: obj?.style?.note_font_align,
                      fontStyle: obj?.style?.note_font_style,
                      textDecoration: obj?.style?.note_font_decoration,
                      textTransform: obj?.style?.note_font_capitalize,
                      wordBreak: obj?.style?.note_font_break,
                      textWrap: obj?.style?.note_font_wrap,
                      letterSpacing: `${obj?.style?.note_letter_spacing}px`,
                      wordSpacing: `${obj?.style?.note_word_spacing}px`,
                      WebkitTextStroke: `${obj?.style?.note_text_stroke}px ${obj?.style?.note_stroke_color}`,
                      textStroke: `${obj?.style?.note_text_stroke}px ${obj?.style?.note_stroke_color}`,
                      textShadow: `${obj?.style?.note_text_shadow_horizontal}px ${obj?.style?.note_text_shadow_vertical}px ${obj?.style?.note_text_shadow_blur}px ${obj?.style?.note_text_shadow_color}`,
                      transform: `rotate(${obj?.style?.note_text_rotate}deg)`,
                    }}
                  >
                    {obj?.content?.note}
                  </div>
                </div>
              </div>
            </section>
          </ReactDragListView>
        );
      })}
    </div>
  );
};

export default SecondStaticContactSection;
