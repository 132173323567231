import { Autocomplete, Box, Dialog, FormControlLabel, TextField } from '@mui/material';
import { ArrowLeft, X } from 'feather-icons-react/build/IconComponents';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import { CountryCodes } from '../../Assets/StaticData/countrycode';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { encryptData } from '../../controllers/encryptionUtils';
import { GetClient, clientData } from '../../controllers/GetClient';
import ImageUploader from '../../controllers/ImageUploader';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import Notification from '../Notification';
import DeleteStore from './DeleteStore';
const EditProfile = () => {
  const navigate = useNavigate();
  const user = clientData?.user;
  const [isNotification, setIsNotification] = useState(false);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false)
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [deletestorepopup, setDeleteStorePopup] = useState(false)
  // image upload logic
  const [twoFA, settwoFA] = useState(user?.twoFA);
  // image upload logic
  const [image, setimage] = useState(user?.profile_picture);
  const handleSetImage = (value) => {
    setimage(value)
  }
  const [profileData, setprofileData] = useState({
    firstname: user?.first_name ? `${user?.first_name}` : '',
    lastname: user?.last_name !== undefined ? `${user?.last_name}` : '',
    ophone: user?.phone ? `${user?.phone}` : '',
    odial_code: user?.country_code || ''
  });

  const { firstname, lastname, ophone, odial_code } = profileData;
  const [err, setErr] = useState();

  const HandleInput = (e) => {
    setprofileData({ ...profileData, [e.target.name]: e.target.value });
    setIsChange(true)
  };

  const HandleMobileNumberInput = (e) => {
    setErr(false);
    if (e.target.value.length <= 10) {
      setprofileData({ ...profileData, ophone: e.target.value });
      setIsChange(true)
    }
  };

  const [clickcheckbox, setClickCheckBox] = useState({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false })
  const { checkbox1, checkbox2, checkbox3, checkbox4 } = clickcheckbox
  const [btnLoading, setBtnLoading] = useState(false)
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  // Add profileData
  const HandleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoading(true)
    const payload = {
      first_name: firstname,
      last_name: lastname,
      phone: ophone,
      country_code: odial_code,
      profile_picture: image,
      twoFA,
    }
    try {
      const body = encryptData(payload)
      const HS = await API.put('/admin/user/UpdateInfo', body);
      if (HS.status === 200 || HS.status === 304) {
        setIsNotification(true);
        setNotificationMsg(`${t("lang_profile")} ${t("lang_edited")} ${t("lang_successfully!")}`);
        setSeverity('success');
        setSaveChanges({ isUnsaveChanges: false, showChangesPopup: false, backLink: '/profile', title: 'edit profile' })
        navigate('/profile');
        GetClient();
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false)
  };

  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    if (isChange) {
      setSaveChanges({ backLink: '/profile', isUnsaveChanges: true, showChangesPopup: false, title: `${t("lang_edit")} ${t("lang_profile")}` })
    }
  }, [isChange])
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({ isUnsaveChanges: true, showChangesPopup: true, backLink: '/profile', title: `${t("lang_edit")} ${t("lang_profile")}` })
    } else {
      navigate('/profile')
    }
  }
  const filterOptionsForContry = (options, state) => {
    const normalizedInputValue = state.inputValue?.toLowerCase();
    return options.filter((option) => {
      const combinedFields = `${option?.code?.toLowerCase()} ${option?.dial_code?.toLowerCase()} ${option?.name?.toLowerCase()}}`;
      return combinedFields.includes(normalizedInputValue);
    });
  };
  return (
    <>
      {isNotification && notificationMsg ? (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      ) : (
        <></>
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button onClick={(e) => { e.preventDefault(); checkChanges() }} className='back-arrow'><ArrowLeft /></button>
          <div>
            <Breadcrumbs crumbs={[
              { name: t('lang_dashboard'), path: `/dashboard` },
              { name: t('lang_profile'), path: '/profile' },
              { name: `${t("lang_edit")} ${t("lang_profile")}`, path: '/profile/edit-profile' }
            ]} />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {`${t("lang_edit")} ${t("lang_profile")}`}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          method='POST'
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='secondary-bg-color p-5 sm:p-[25px] shadow rounded-[6px]'>
            <div className='mb-4'>
              <label className='block table-text text-sm font-medium mb-1' htmlFor='image'>
                {`${t("lang_profile")} ${t("lang_photo")}`}
              </label>
              <ImageUploader alreadyImage={image?.length > 0 ? image : ''} handleSetImage={handleSetImage} folder="profile" format="image" />
            </div>
            <div className='flex space-x-5'>
              <div className='mb-4 w-full'>
                <label className='block table-text text-sm font-medium mb-1' htmlFor='first_name'>
                  {`${t("lang_first")} ${t("lang_name")}`}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='first_name'
                  name='firstname'
                  value={firstname}
                  onChange={HandleInput}
                  placeholder={`${t("lang_enter")} ${t("lang_your")} ${t("lang_firstname")}`}
                />
              </div>
              <div className='mb-4 w-full'>
                <label className='block table-text text-sm font-medium mb-1 capitalize' htmlFor='last_name'>
                  {`${t("lang_last")} ${t("lang_name")}`}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  type='text'
                  id='last_name'
                  name='lastname'
                  value={lastname}
                  onChange={HandleInput}
                  placeholder={`${t("lang_enter")} ${t("lang_your")} ${t("lang_lastname")}`}
                />
              </div>
            </div>
            <div className='mb-4'>
              <label className='block table-text text-sm font-medium mb-1 capitalize' htmlFor='email'>
                {t("lang_email")}
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='email'
                name='email'
                disabled
                placeholder={`${t("lang_enter")} ${t("lang_your")} ${t("lang_email")}`}
                value={user?.email}
              />
              <p className='text-red-500 font-medium text-xs'>{err}</p>
            </div>
            <div className='flex space-x-5'>
              <div className='mb-4 w-full'>
                <label className='block table-text text-sm font-medium mb-1' htmlFor='role'>
                  {t("lang_role")}
                </label>
                <input
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none '
                  type='text'
                  disabled
                  id='role'
                  placeholder={t('lang_role')}
                  name='role'
                  value={user?.role}
                />
              </div>

              <div className='mb-4 w-full contryCode'>
                <label className='block table-text text-sm font-medium mb-1' htmlFor='phone'>
                  {t("lang_mobile_number")}<span className='text-red-500 ms-1'>*</span>
                </label>
                <div className='flex'>
                  <Autocomplete
                    className='w-28 autocomplete contryCode'
                    options={CountryCodes ? CountryCodes : []}
                    filterOptions={filterOptionsForContry}
                    disableClearable
                    onChange={(e, v) => { setprofileData({ ...profileData, odial_code: v?.dial_code }); setIsChange(true) }}
                    value={CountryCodes.find((country_code) => country_code?.dial_code === user?.country_code)?.dial_code || null}
                    renderOption={(props, option) => (
                      <Box key={option.code} component="li" sx={{ '& > img': { mr: 1, fontSize: '10px' } }} {...props}>
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt="flag"
                        />
                        <p className="text-sm flex items-center">{option.dial_code}</p>
                      </Box>
                    )}
                    renderInput={(dial_code) => (
                      <TextField
                        placeholder='+91'
                        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        className='select-none cursor-pointer border-black'
                        {...dial_code}
                      />
                    )}
                  />
                  <input
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none '
                    type='number'
                    maxLength={10}
                    id='phone'
                    value={ophone}
                    placeholder={`${t("lang_enter")} ${t("lang_your")} ${t("lang_mobile_number")}`}
                    name='ophone'
                    onChange={HandleMobileNumberInput}
                    onBlur={() => setErr(true)}
                  />
                </div>
                {ophone?.length > 0 && ophone?.length !== 10 && err && (
                  <p className='text-xs font-medium text-red-500'>
                    {t("lang_mobile_should_digit", { digits: 10 })}
                  </p>
                )}
              </div>
            </div>
            <div className='mb-4'>
              <label className='block table-text text-sm font-medium mb-1' htmlFor='language'>
                {t("lang_language")}
              </label>
              <select
                name='language'
                className='w-full border main-border-color focus:border-gray-500 rounded-[6px] py-1 outline-none px-3 text-sm'
                onChange={(e) => changeLanguage(e.target.value)}
              >
                <option value='en'>{t("lang_english")}(English)</option>
              </select>
              <div className='select-arrow'></div>
              <p className='text-red-500 font-medium text-xs'>{err}</p>
            </div>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2'>
                <p className='font-medium text-sm me-2'>{t("lang_two_factor_authentication")}</p>
                <label className="relative flex items-center cursor-pointer">
                  <FormControlLabel
                    checked={twoFA}
                    control={<MuiSwitch />}
                    onChange={() => {
                      settwoFA((prev) => !prev);
                      setIsChange(true)
                    }}
                  />
                </label>
              </div>
              <span className='cursor-pointer inline-block text-red-600 font-medium underline text-sm mt-2' onClick={() => setDeleteStorePopup(true)}>{`${t("lang_delete")} ${t("lang_my")} ${t("lang_store")}`}</span>
            </div>
          </div>


          {/* deletestore popup */}
          {/* <Dialog
            open={deletestorepopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { setDeleteStorePopup(false); setClickCheckBox({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) }}
            aria-describedby="alert-dialog-slide-description"
          >
            <div className="secondary-bg-color rounded-[6px] p-5 sm:p-[25px] w-full">
              <div className='flex items-center justify-between'>
                <h5 className='font-medium text-primary'>Delete store</h5>
                <button className='close-btn' onClick={(e) => { e.preventDefault(); setDeleteStorePopup(false); setClickCheckBox({ checkbox1: false, checkbox2: false, checkbox3: false, checkbox4: false }) }}><X className="w-4 h-4" /></button>
              </div>
              <hr className='my-4 main-border-color' />
              <p className='text-[15px] font-medium'>
                Before you delete your store, please review and accept the following conditions
              </p>
              <ul className='my-3 space-y-3 list-none text-sm'>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox1} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox1: !checkbox1 })} /> All your data will be permanently deleted including your products, orders, customers, etc.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox2} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox2: !checkbox2 })} /> All your account information will be permanently deleted from our database.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox3} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox3: !checkbox3 })} /> You will not be able to recover your store's data back.</li>
                </label>
                <label className='block mb-2 cursor-pointer'>
                  <li className='flex items-center'> <input type='checkbox' className='w-4 h-4 me-2' checked={checkbox4} onClick={() => setClickCheckBox({ ...clickcheckbox, checkbox4: !checkbox4 })} /> You have a {moment(clientData?.billing_detail?.next_billing_date).diff(new Date(), 'days')} days left until your plan expire, and please note that the purchase is non-refundable.</li>
                </label>
              </ul>

              <div className='flex justify-between'>
                {
                  checkbox1 && checkbox2 && checkbox3 && checkbox4 ?
                    <button className="bg-red-500 hover:bg-red-600 rounded-[4px] px-5 mt-2 py-2 text-white text-sm" onClick={() => { navigate('/profile/delete-store', { state: user?.email }) }}>Continue to deletion</button> :
                    <button className="bg-red-500 opacity-40 cursor-not-allowed rounded-[4px] px-5 mt-2 py-2 text-white text-sm">Continue to deletion</button>
                }
              </div>
            </div>
          </Dialog> */}
          <DeleteStore email={user?.email} deletestorepopup={deletestorepopup} setDeleteStorePopup={setDeleteStorePopup} />
        </form>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[60%] mx-auto'>
            <Link
              to={`/profile`}
              className='w-fit flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {`${t("lang_go_to")} ${t("lang_profile")}`}
            </Link>

            {ophone?.length !== 10 ?
              <button
                className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300 capitalize'
                onClick={(e) => e.preventDefault()}
              >
                {`${t("lang_update")} ${t("lang_info")}`}
              </button>
              : btnLoading ?
                <button type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[150px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'>
                  <div className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent text-yellow-1000 rounded-full">
                  </div>
                  <span>{t("lang_loading")}...</span>
                </button> :
                <button
                  className='py-2 text-white rounded-[6px] btn text-[14px] capitalize'
                  type='submit'
                  onClick={HandleSubmit}
                >
                  {`${t("lang_update")} ${t("lang_info")}`}
                </button>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
