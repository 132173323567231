import React from 'react'

function UpdateCustomer() {
  return (
    <>
      <div className='pt-[108px] pe-3 pb-4 overflow-x-hidden h-full bg-[#F0F0F0] w-full'>
        <div className='flex flex-wrap'>
          <div className='w-1/2 mx-auto border bg-white border-gray-300 rounded-lg p-5'>
          <h4 className='text-xl md:text-2xl font-medium'>Update customer</h4>
          <hr className='my-4'></hr>
            <div className='relative flex flex-col min-w-0 break-words bg-white w-full rounded-[6px]'>
              <div className='flex-auto'>
                <div className='tab-content tab-space'>
                  <div id='link1'>
                    <>
                      <div className='mb-4'>
                        <label
                          className='flex text-gray-700 text-sm font-bold mb-2'
                          htmlFor='Customer Name'
                        >
                          Customer name
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                          type='text'
                          id='Customer Name'
                          name='Customer Name'
                        //   value={faqData}
                        //   onChange={(e) => setFaqData(e.target.value)}
                          placeholder='Name'
                        />
                      </div>
                      <div className='mb-4'>
                        <label
                          className='flex text-gray-700 text-sm font-bold mb-2'
                          htmlFor='Email'
                        >
                          Email
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                          type='text'
                          id='Email'
                          name='Email'
                        //   value={faqData}
                        //   onChange={(e) => setFaqData(e.target.value)}
                          placeholder='Email'
                        />
                      </div>
                      <div className='mb-4'>
                        <label
                          className='flex text-gray-700 text-sm font-bold mb-2'
                          htmlFor='Mobile Number'
                        >
                          Mobile number
                        </label>
                        <input
                          className='w-full px-3 py-2 border border-gray-300 rounded-[6px] focus:outline-none'
                          type='text'
                          id='Mobile Number'
                          name='Mobile Number'
                        //   value={faqData}
                        //   onChange={(e) => setFaqData(e.target.value)}
                          placeholder='Mobile Number'
                        />
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
            <button
            className='block w-full btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
            type='submit'
          >
            Update Customer
          </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateCustomer;