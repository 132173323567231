import React from 'react';
import { useState, useEffect } from 'react';
import API from '../../API';
import Notification from '../Notification';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { FormatAmount } from '../../controllers/FormatAmount';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const Subscriptions = () => {
  const [staticSubData, setStaticData] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  let token = localStorage.getItem('token');
  let business_name = localStorage.getItem('business_name');
  const GetStaticSubData = async (_id) => {
    try {
      const payload = encryptData({ planId: _id })
      const ApiCall = await API.post(`/admin/plan/purchasePlan`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data)
        setStaticData(result);
        setNotificationMsg('Plan purchase successfully');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [subscriptiondata, setSubscriptiondata] = useState();
  const GetSubscriptionPlans = async () => {
    if (business_name) {
      try {
        const ApiCall = await API.get(`/admin/plan/getPlanList`);
        if (ApiCall.status === 200 || ApiCall.status === 304) {
          const result = decryptData(ApiCall?.data)
          setSubscriptiondata(result);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(error);
        setSeverity('error');
      }
    }
  };

  useEffect(() => {
    GetSubscriptionPlans();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              navigate('/dashboard');
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_dashboard'), path: `/dashboard` },
                { name: t('lang_Subscription'), path: '/subscription' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>{t('lang_Subscription')}</h4>
          </div>
        </div>

        <div className='flex flex-wrap lg:space-x-4 space-y-4 lg:space-y-0 lg:flex-nowrap justify-center'>
          {subscriptiondata?.data?.records.map((elem) => {
            return (
              <>
                <div className='w-[350px] rounded-[6px] secondary-bg-color shadow-[0px 1px 2px #E1E3E5] border main-border-color'>
                  <div className='p-[20px]'>
                    <div className='flex w-full justify-end'>
                      <p className='status-bedge text-green-500 bg-green-100'>
                        {elem.name}
                      </p>
                    </div>
                    <div>
                      <p className='text-primary text-[40px] leading-[63px] font-bold'>
                        {elem.name}
                      </p>
                    </div>
                    <div>
                      <p className='table-text mb-4 text-sm '>{elem.description}</p>
                      <p className='table-text primary-bg-color px-5 py-2 text-[18px] leading-[28px] font-medium flex items-center justify-center space-x-1'>
                        <span className='text-primary text-[23px] font-medium'>
                          {FormatAmount(elem?.price)}
                        </span>
                        <span className='text-[15px] font-medium'>/ {elem.plan_type}</span>
                      </p>
                    </div>
                  </div>
                  {elem?.features?.length > 0 && (
                    <ul className='table-text block text-center text-[15px] leading-[28px]'>
                      {elem?.features.map((x, index) => (
                        <li key={index} className='text-center w-full'>
                          {x}
                        </li>
                      ))}
                    </ul>
                  )}
                  <div className='p-[20px]'>
                    <button
                      className='btn w-full rounded-[6px] capitalize px-6 py-3 text-[#fff] text-sm'
                      onClick={() => GetStaticSubData(elem._id)}
                    >
                      {t('lang_buy_plan')}
                    </button>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};
export default Subscriptions;
