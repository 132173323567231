import React, { useState, useEffect } from 'react';
import { mapPositionToCSS } from './DynamicEditorSwitchCase';

const NumberAnimation = ({ start, end, duration, title, htmlTag, prefix, suffix, obj }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const totalDuration = parseInt(duration, 10);
    if (isNaN(totalDuration) || totalDuration <= 0) return;
    const totalSteps = Math.abs(end - start);
    const incrementTime = totalDuration / totalSteps;
    if (totalSteps === 0) return;

    let current = start;
    const step = end > start ? 1 : -1;
    const timer = setInterval(() => {
      current += step;
      setCount(current);
      if ((step > 0 && current >= end) || (step < 0 && current <= end)) {
        clearInterval(timer);
        setCount(end);
      }
    }, incrementTime);
    return () => clearInterval(timer);
  }, [start, end, duration, prefix, suffix]);

  console.log(obj?.style?.title_spacing, 'title_position=-=-=>>');

  return (
    <div
      className='flex w-full justify-evenly'
      style={{
        flexDirection:
          obj?.style?.title_position === 'before'
            ? 'column-reverse'
            : obj?.style?.title_position === 'after'
            ? 'column'
            : obj?.style?.title_position === 'start'
            ? 'row-reverse'
            : obj?.style?.title_position === 'end'
            ? 'row'
            : 'column',
      }}
    >
      <p
        className='text-[#6ec1e4] w-full text-[55px] text-center font-semibold flex'
        style={{
          fontWeight: obj?.style?.counter_font_weight,
          fontSize: `${obj?.style?.counter_font_size}px`,
          lineHeight: `${obj?.style?.counter_font_height}px`,
          color: obj?.style?.counter_font_color,
          textAlign: obj?.style?.counter_font_align,
          fontStyle: obj?.style?.counter_font_style,
          textDecoration: obj?.style?.counter_font_decoration,
          textTransform: obj?.style?.counter_font_capitalize,
          wordBreak: obj?.style?.counter_font_break,
          textWrap: obj?.style?.counter_font_wrap,
          letterSpacing: `${obj?.style?.counter_letter_spacing}px`,
          wordSpacing: `${obj?.style?.counter_word_spacing}px`,
          WebkitTextStroke: `${obj?.style?.counter_text_stroke}px ${obj?.style?.counter_stroke_color}`,
          textStroke: `${obj?.style?.counter_text_stroke}px ${obj?.style?.counter_stroke_color}`,
          textShadow: `${obj?.style?.number_shadow_horizontal}px ${obj?.style?.number_shadow_vertical}px ${obj?.style?.number_shadow_blur}px ${obj?.style?.number_shadow_color}`,
          transform: `rotate(${obj?.style?.counter_text_rotate}deg)`,
          justifyContent:
            obj?.style?.number_position === 'start'
              ? 'flex-start'
              : obj?.style?.number_position === 'center'
              ? 'center'
              : obj?.style?.number_position === 'end'
              ? 'flex-end'
              : 'center',
        }}
      >
        {prefix}
        {count}
        {suffix}
      </p>
      {htmlTag === 'h1' ? (
        <h1
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h1>
      ) : htmlTag === 'h2' ? (
        <h2
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h2>
      ) : htmlTag === 'h3' ? (
        <h3
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h3>
      ) : htmlTag === 'h4' ? (
        <h4
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h4>
      ) : htmlTag === 'h5' ? (
        <h5
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h5>
      ) : htmlTag === 'h6' ? (
        <h6
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </h6>
      ) : htmlTag === 'span' ? (
        <span
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </span>
      ) : htmlTag === 'div' ? (
        <div
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </div>
      ) : (
        <p
          className='text-center flex w-full'
          style={{
            fontWeight: obj?.style?.font_weight,
            fontSize: `${obj?.style?.font_size}px`,
            lineHeight: `${obj?.style?.font_height}px`,
            color: obj?.style?.font_color,
            textAlign: obj?.style?.font_align,
            fontStyle: obj?.style?.font_style,
            textDecoration: obj?.style?.font_decoration,
            textTransform: obj?.style?.font_capitalize,
            wordBreak: obj?.style?.font_break,
            textWrap: obj?.style?.font_wrap,
            letterSpacing: `${obj?.style?.letter_spacing}px`,
            wordSpacing: `${obj?.style?.word_spacing}px`,
            WebkitTextStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textStroke: `${obj?.style?.text_stroke}px ${obj?.style?.stroke_color}`,
            textShadow: `${obj?.style?.text_shadow_horizontal}px ${obj?.style?.text_shadow_vertical}px ${obj?.style?.text_shadow_blur}px ${obj?.style?.text_shadow_color}`,
            transform: `rotate(${obj?.style?.text_rotate}deg)`,
            // height: '12vh',
            alignItems:
              obj?.style?.title_vertical_alignment === 'top'
                ? 'flex-start'
                : obj?.style?.title_vertical_alignment === 'center'
                ? 'center'
                : obj?.style?.title_vertical_alignment === 'bottom'
                ? 'flex-end'
                : 'center',
            justifyContent:
              obj?.style?.title_horizontal_alignment === 'start'
                ? 'start'
                : obj?.style?.title_horizontal_alignment === 'center'
                ? 'center'
                : obj?.style?.title_horizontal_alignment === 'end'
                ? 'end'
                : 'center',
            marginTop:
              obj?.style?.title_position === 'after' ? `${obj?.style?.title_spacing}px` : '',
            marginBottom:
              obj?.style?.title_position === 'before' ? `${obj?.style?.title_spacing}px` : '',
          }}
        >
          {title}
        </p>
      )}
    </div>
  );
};

export default NumberAnimation;
