import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';

const ListItem = ({
  dropDownValue,
  item,
  handleItemClick1,
  isSelected,
  handleInputChange,
  onCopy,
  onRemove,
  inputValues,
  tabIndex,
}) => {
  console.log('ListItem', item);
  return (
    <div
      className={`p-3 mb-2 mt-2 border border-gray-200 rounded-md ${
        isSelected ? 'bg-gray-100 divide-slate-400 divide-y' : ''
      }`}
    >
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <span className='text-sm font-medium'>{item?.title}</span>
        </div>
        <div className='flex items-center space-x-3'>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Edit'
            onClick={handleItemClick1}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-pencil-square'
              viewBox='0 0 16 16'
            >
              <path d='M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z' />
              <path
                fillRule='evenodd'
                d='M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z'
              />
            </svg>
          </button>
          <button
            className='text-gray-400 hover:text-gray-600'
            title='Copy'
            onClick={() => onCopy(item)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='bi bi-copy'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z'
              />
            </svg>
          </button>
          <button
            className='text-red-400 hover:text-red-600'
            title='Remove'
            onClick={() => onRemove(item?.id)}
          >
            <svg
              className='w-5 h-5'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
            >
              <path d='M18 6l-12 12M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>
      {isSelected && (
        <div className='mt-2'>
          <div className='w-full mb-4'>
            <label htmlFor='title'>Title</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='title'
              name='title'
              placeholder='Enter title...'
              value={item?.title || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label>HTMLTag</label>
            <div className='relative'>
              <select
                className='w-full mt-2'
                id='htmlTag'
                name='htmlTag'
                value={item?.htmlTag || ''}
                onChange={handleInputChange}
              >
                {dropDownValue?.map((obj) => (
                  <option key={obj?.value} value={obj?.value}>
                    {obj?.label}
                  </option>
                ))}
              </select>
              <div className='select-arrow'></div>
            </div>
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='start'>Starting Number</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='number'
              id='start'
              name='start'
              placeholder='Enter start number...'
              value={
                inputValues?.[tabIndex]?.start !== undefined
                  ? inputValues[tabIndex].start
                  : item?.start >= 0
                  ? item.start
                  : ''
              }
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='end'>Ending Number</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='number'
              id='end'
              name='end'
              placeholder='Enter end number...'
              value={
                inputValues?.[tabIndex]?.end !== undefined
                  ? inputValues?.[tabIndex]?.end
                  : item?.end >= 0
                  ? item?.end
                  : ''
              }
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='duration'>Animation Duration</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='number'
              id='duration'
              name='duration'
              placeholder='Enter duration...'
              value={
                inputValues?.[tabIndex]?.duration !== undefined
                  ? inputValues?.[tabIndex]?.duration
                  : item?.duration >= 0
                  ? item?.duration
                  : ''
              }
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='prefix'>Number Prefix</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='prefix'
              name='prefix'
              placeholder='Enter prefix...'
              value={item?.prefix || ''}
              onChange={handleInputChange}
            />
          </div>

          <div className='w-full mb-4'>
            <label htmlFor='suffix'>Number Suffix</label>
            <input
              className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none bg-white'
              type='text'
              id='suffix'
              name='suffix'
              placeholder='Enter suffix...'
              value={item?.suffix || ''}
              onChange={handleInputChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const StaticFirstStatisticSection = ({
  section_id,
  firstStatisticValue,
  setFirstStatisticValue,
  handleFirstStatisticValue,
  tabIndex,
  setTabIndex,
  handleAddFirstStatisticItem,
  handleCopyFirstStatisticItem,
  handleFirstStatisticDataRemoveValue,
  saveElementorData,
  btnLoading,
  sectionGridContent,
  handleFirstStatisticGridValueChange,
}) => {
  const dropDownValue = [
    { value: 'h1', label: 'H1' },
    { value: 'h2', label: 'H2' },
    { value: 'h3', label: 'H3' },
    { value: 'h4', label: 'H4' },
    { value: 'h5', label: 'H5' },
    { value: 'h6', label: 'H6' },
    { value: 'div', label: 'Div' },
    { value: 'span', label: 'Span' },
    { value: 'p', label: 'P' },
  ];

  const dropDownGridValue = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
  ];

  const [gridValue, setGridValue] = useState(sectionGridContent);
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    setGridValue(sectionGridContent);
  }, [sectionGridContent]);

  const handleQuillChange = (content) => {
    const updatedfirstContentValue = {
      ...firstStatisticValue,
      item: firstStatisticValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, description: content } : item,
      ),
    };

    setFirstStatisticValue(updatedfirstContentValue);
    handleFirstStatisticValue(section_id, updatedfirstContentValue);
  };

  const onGridContentChange = (e) => {
    const newValue = e.target.value;
    setGridValue(newValue);
    handleFirstStatisticGridValueChange(section_id, newValue);
  };

  const handleItemClick = (index) => {
    setTabIndex((prev) => (prev === index ? null : index));
    const updatedfirstContentValue = {
      ...firstStatisticValue,
      item: firstStatisticValue?.item?.map((item, i) => ({
        ...item,
        isEdit: i === index,
      })),
    };
    setFirstStatisticValue(updatedfirstContentValue);
    handleFirstStatisticValue(section_id, updatedfirstContentValue);
  };
  const addItem = () => {
    const newId = firstStatisticValue?.item?.length
      ? firstStatisticValue?.item?.[firstStatisticValue?.item?.length - 1]?.id + 1
      : 1;
    const newItem = {
      id: newId,
      start: 0,
      end: 100,
      duration: 2000,
      title: 'Users',
      htmlTag: 'p',
      prefix: '',
      suffix: 'K',
      isEdit: false,
    };
    const updatedItems = firstStatisticValue?.item?.map((item) => ({
      ...item,
      isEdit: false,
    }));
    const finalItems = [...updatedItems, newItem];
    handleAddFirstStatisticItem(section_id, finalItems);
    setTabIndex(null);
  };

  const copyItem = (item, index) => {
    handleCopyFirstStatisticItem(section_id, item, index);
  };

  const removeItem = (id) => {
    handleFirstStatisticDataRemoveValue(section_id, id);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const numericFields = ['start', 'end', 'duration'];
    const updatedValue = numericFields?.includes(name) ? parseInt(value, 10) : value;

    if (numericFields?.includes(name) && (isNaN(updatedValue) || value === '')) {
      setInputValues((prev) => ({
        ...prev,
        [tabIndex]: { ...prev[tabIndex], [name]: value },
      }));
      return;
    }

    const updatedfirstContentValue = {
      ...firstStatisticValue,
      item: firstStatisticValue?.item?.map((item, i) =>
        i === tabIndex ? { ...item, [name]: updatedValue } : item,
      ),
    };

    setFirstStatisticValue(updatedfirstContentValue);
    handleFirstStatisticValue(section_id, updatedfirstContentValue);
    if (numericFields?.includes(name)) {
      setInputValues((prev) => ({
        ...prev,
        [tabIndex]: { ...prev[tabIndex], [name]: updatedValue },
      }));
    }
  };
  console.log('firstStatisticValue', firstStatisticValue);
  return (
    <div className='max-w-md mx-auto mt-4'>
      <div className='mt-4'>
        <label>Section</label>
        {Array.isArray(firstStatisticValue?.item) &&
          firstStatisticValue?.item?.map((item, index) => (
            <ListItem
              key={index}
              item={item}
              handleItemClick1={() => handleItemClick(index)}
              isSelected={tabIndex === index}
              onCopy={() => copyItem(item, index)}
              onRemove={removeItem}
              handleInputChange={handleInputChange}
              dropDownValue={dropDownValue}
              handleQuillChange={handleQuillChange}
              inputValues={inputValues}
              tabIndex={tabIndex}
            />
          ))}
      </div>

      <button
        onClick={addItem}
        className='mt-4 px-4 py-2 bg-gray-600 text-white font-medium rounded-md hover:bg-gray-700 focus:outline-none'
      >
        + Add Item
      </button>

      <div className='mt-4 mb-4'>
        <label>Grid</label>
        <div className='relative'>
          <select className='w-full mt-2' value={gridValue} onChange={onGridContentChange}>
            {dropDownGridValue?.map((obj) => (
              <option key={obj?.value} value={obj?.value}>
                {obj?.label}
              </option>
            ))}
          </select>
          <div className='select-arrow'></div>
        </div>
      </div>

      <div className='sticky flex items-center h-[50px] bg-[#ffffff] bottom-0 left-0 w-full z-50'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default StaticFirstStatisticSection;
