import { WifiOff } from 'feather-icons-react/build/IconComponents';
import React, { useState, useEffect } from 'react';

const NetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(true);
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    const updateStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateStatus);
    window.addEventListener('offline', updateStatus);

    updateStatus();

    return () => {
      window.removeEventListener('online', updateStatus);
      window.removeEventListener('offline', updateStatus);
    };
  }, []);

  useEffect(() => {
    if (!isOnline) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  }, [isOnline]);

  if (!isShowing) return null;

  return (
    <div className="fixed bottom-20 right-4 md:right-8 md:bottom-20 bg-[#28282B] text-white py-3 px-4 md:px-5 rounded-lg shadow-lg max-w-xs md:max-w-sm w-full md:w-auto z-50">
      <div className="flex items-start space-x-3">
        <WifiOff className="w-5 h-5" />
        <div className="flex-1">
          <p className="text-sm md:text-base leading-tight break-words">
            Looks like you're offline. Some functionality may be unavailable. Please check your connection.
          </p>
        </div>
        <button
          className="text-white hover:text-gray-300 text-sm md:text-base flex-shrink-0 ml-2"
          onClick={() => setIsShowing(false)}
        >
          Dismiss
        </button>
      </div>
    </div>
  );

};

export default NetworkStatus;
