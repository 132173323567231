import React from 'react';
import EditMapStyleContainer from './EditMapStyleContainer';

const EditMapStyleSection = ({
  mapDesign,
  setMapDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditMapStyleContainer
        mapDesign={mapDesign}
        setMapDesign={setMapDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditMapStyleSection;
