import React from 'react';
import { Skeleton } from '@mui/material';

function SkeletonLoader() {
  return (
    <div className='h-auto overflow-y-auto pb-[64px] w-full'>
      <div className='flex items-center justify-between pb-4'>
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-[200px] rounded-[6px] min-h-[40px]'
        />
        <div className='flex items-center space-x-4'>
          <Skeleton
            variant='rectangular'
            animation='wave'
            className='w-[200px] rounded-[6px] min-h-[40px]'
          />
          <Skeleton
            variant='rectangular'
            animation='wave'
            className='w-[200px] rounded-[6px] min-h-[40px]'
          />
        </div>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-5 gap-5 gap-x-4 gap-y-4 pb-4'>
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
      </div>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-5 gap-x-4 gap-y-4 pb-4'>
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[80px]'
        />
      </div>
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-x-4 gap-y-4'>
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[380px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[380px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[380px]'
        />
        <Skeleton
          variant='rectangular'
          animation='wave'
          className='w-full rounded-[6px] min-h-[380px]'
        />
      </div>
    </div>
  );
}

export default SkeletonLoader;
