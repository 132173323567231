import React, { useEffect, useState } from 'react';
import API from '../../API';
import Notification from '../Notification';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
const Robots = () => {
  const [robotsField, setRobotsField] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const getRobots = async () => {
    try {
      const apiCall = await API.get('admin/client/get_robot_file');
      if (apiCall?.status === 200) {
        if (apiCall?.data?.length > 0) {
          const result = decryptData(apiCall?.data);
          setRobotsField(result);
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = encryptData({ robot: robotsField });
      const apiCall = await API.post('admin/client/robot_file', payload);
      if (apiCall.status === 200) {
        setRobotsField('');
        getRobots();
        setIsNotification(true);
        setNotificationMsg('Robots added successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getRobots();
    console.log('#in');
  }, []);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Robots_Text')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Add_robots_text_for_show_information')}
            </p>
          </div>
        </div>
          <>
            <form
              method='post'
              className='w-full pb-4 sm:pb-[25px] text-sm mx-auto rounded-[6px]'
              onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className='mb-4'>
                <label htmlFor='title'>{t('lang_Robots_Text')}</label>
                <textarea
                  className={`w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`}
                  value={robotsField}
                  name='robots'
                  placeholder='Enter robots text'
                  onChange={(e) => setRobotsField(e.target.value)}
                  required
                />
              </div>
              <div className='w-full flex justify-end'>
                <button
                  type='submit'
                  className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
                >
                  {t('lang_Save_Robots_Text')}
                </button>
              </div>
            </form>
          </>
      </div>
    </>
  );
};

export default Robots;
