import { Backdrop, Box, Modal, Tooltip } from '@mui/material';
import {
  ChevronsLeft,
  ChevronsRight,
  ExternalLink,
} from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { ChevronUp } from 'react-bootstrap-icons';
import Confetti from 'react-confetti';
import SidebarData from '../../controllers/SidebarData';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, Extention } from '../../App';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import logo from '../../Assets/white-logo.svg';
import { GetInstalledExtentionData, clientData } from '../../controllers/GetClient';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import shortlogo from '../../Assets/short-logo.svg';
import Notification from '../Notification';
const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [popUp, setPopUp] = useState(false);
  const { installedExtensions } = useContext(Extention);
  const [celebratingOrders, setCelebratingOrders] = useState();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const navigate = useNavigate();
  const clientInfo = clientData;
  const [planData, setPlanData] = useState();
  useEffect(() => {
    setPlanData(clientInfo?.plan_data?.authorized_features);
  }, [clientInfo]);
  useEffect(() => {
    GetInstalledExtentionData();
  }, []);
  useEffect(() => {
    HandleCongratsInfo();
  }, []);
  const [newArray, setNewArray] = useState();
  useEffect(() => {
    const allExtensions =
      installedExtensions?.length > 0 && installedExtensions?.map((x) => x.extension_name);
    setNewArray(
      SidebarData?.apps?.filter(
        (x) => allExtensions?.length > 0 && allExtensions?.includes(t(x.menuName)),
      ),
    );
  }, [installedExtensions]);
  let otherMenuArray;
  if (clientInfo && clientInfo?.user?.role !== 'admin') {
    otherMenuArray = SidebarData?.other?.filter((item) => t(item.menuName) !== 'Delete logs');
  } else {
    otherMenuArray = SidebarData?.other;
  }
  const HandleCongratsInfo = async () => {
    try {
      const ApiCall = await API.get('/admin/analytics/TotalOrdersCount');
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        const numberList = [
          1, 10, 100, 500, 1000, 5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000,
          90000, 100000,
        ];

        const congratsCheck = Math.max(
          ...numberList.filter((number) => number <= result?.data?.order_count),
          0,
        );

        if (congratsCheck > clientInfo?.celebrated_orders) {
          setCelebratingOrders(congratsCheck);
          setPopUp(true);
        }
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  const HandleCelebratingOrders = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          celebrated_orders: celebratingOrders,
        },
      });
      await API.put('/admin/client/updateClient', payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    if (celebratingOrders) {
      HandleCelebratingOrders();
    }
  }, [celebratingOrders]);

  const url = window.location.href.split('/');
  const [openTooltip, setOpenTooltip] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [openTab, setOpenTab] = useState('');
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const filterSubmenu = (submenu) => {
    return submenu?.filter(
      (item) => planData?.includes(item?.planStatus) || item?.planStatus === '',
    );
  };
  //general menu filter
  const filteredGeneral =
    clientInfo?.billing_detail?.billing_plan === 'Trial'
      ? SidebarData?.general
      : SidebarData?.general
          ?.map((menu) => ({
            ...menu,
            submenu: filterSubmenu(menu?.submenu || []),
          }))
          .filter(
            (menu) =>
              planData?.includes(menu?.planStatus) ||
              (menu?.planStatus === '' && menu.submenu.length > 0),
          );

  //apps menu filter
  const filterednewArray =
    clientInfo?.billing_detail?.billing_plan === 'Trial'
      ? newArray
      : newArray
          ?.map((menu) => ({
            ...menu,
            submenu: filterSubmenu(menu?.submenu || []),
          }))
          .filter(
            (menu) =>
              planData?.includes(menu?.planStatus) ||
              (menu?.planStatus === '' && menu.submenu.length > 0),
          );

  //configuration menu filter
  const filteredconfiguration =
    clientInfo?.billing_detail?.billing_plan === 'Trial'
      ? SidebarData?.configuration
      : SidebarData?.configuration
          ?.map((menu) => ({
            ...menu,
            submenu: filterSubmenu(menu?.submenu || []),
          }))
          .filter(
            (menu) =>
              planData?.includes(menu?.planStatus) ||
              (menu?.planStatus === '' && menu.submenu.length > 0),
          );

  //other menu filter
  const filteredotherMenuArray =
    clientInfo?.billing_detail?.billing_plan === 'Trial'
      ? otherMenuArray
      : otherMenuArray
          ?.map((menu) => ({
            ...menu,
            submenu: filterSubmenu(menu?.submenu || []),
          }))
          .filter((menu) => planData?.includes(menu?.planStatus) || menu?.planStatus === '');
  const handleNavigate = (link) => {
    if (link === 'https://help.shopeasy.ai/') {
      window.open(link, '_blank');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div
        className={`bg-primary relative h-screen sidebar transition-all duration-1000 ${
          !isOpen && 'close'
        }`}
      >
        <div
          className={`w-full pt-[20px] h-[55px] ${
            isOpen ? 'flex px-[15px]' : 'px-[8px]'
          } items-center`}
        >
          {isOpen ? (
            <NavLink
              to='/dashboard'
              className='w-full inline-block transition-all duration-1000 text-center'
            >
              <img className={'w-[175px] h-auto block mx-auto'} src={logo} alt='Your Company' />
            </NavLink>
          ) : (
            <NavLink
              to='/dashboard'
              className='w-full inline-block transition-all duration-1000 text-center'
            >
              <img className={'h-6 w-auto block mx-auto'} src={shortlogo} alt='Your Company' />
            </NavLink>
          )}
          <div
            className={`h-[35px] rounded-[6px] cursor-pointer select-none  duration-150 flex items-center justify-center menu-arrow ml-auto  ${
              !isOpen ? 'w-full mx-auto mt-1' : 'w-[35px]'
            } `}
          >
            {isOpen ? (
              <ChevronsLeft className='text-white w-[18px] h-[18px]' onClick={toggleSidebar} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ChevronsRight className='text-white w-[18px] h-[18px]' onClick={toggleSidebar} />
              </div>
            )}
          </div>
        </div>
        {/* menu items */}
        <div
          className={`h-full pb-[15px] max-w-full overflow-y-auto overflow-x-hidden mb-12 w-full relative ${
            isOpen ? 'mt-4 px-[15px]' : 'mt-10 px-[8px]'
          }`}
        >
          <div className={`h-full transition-all space-y-0.5 duration-300`}>
            {/* general menu */}
            {filteredGeneral?.length > 0 && isOpen ? (
              <span className='text-[11px] text-left font-medium text-[#FAF9F6] opacity-50 py-2 block uppercase'>
                {t('lang_general')}
              </span>
            ) : (
              <span className='my-2'></span>
            )}
            {filteredGeneral?.map((elem, index) => {
              const isIncludeInUrl =
                elem?.includeInUrl && elem?.includeInUrl?.some((value) => url?.includes(value));
              return (
                <div className='w-full relative' key={index}>
                  <div
                    className={`w-full relative items-center gap-x-1.5 group select-none ${
                      isOpen == false && elem?.submenu?.length > 0 ? 'hidden' : 'flex'
                    }`}
                    autoFocus
                  >
                    <Tooltip
                      title={t(elem?.menuName)}
                      arrow
                      placement='right'
                      open={openTooltip}
                      disableHoverListener={isOpen}
                      classes={{ tooltip: 'my-tooltip-class' }}
                    >
                      <Link
                        to={!saveChanges?.isUnsaveChanges ? elem?.link : '#'}
                        className={
                          isIncludeInUrl && openTab !== t(elem?.menuName)
                            ? `menu-item space-x-2 px-2.5 text-white`
                            : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white`
                        }
                        onClick={(e) => {
                          if (saveChanges?.isUnsaveChanges) {
                            e.preventDefault();
                            setSaveChanges({
                              isUnsaveChanges: false,
                              showChangesPopup: true,
                              backLink: elem?.link,
                            });
                          } else {
                            setOpenTab(openTab === t(elem?.menuName) ? '' : t(elem?.menuName));
                          }
                        }}
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div
                            className={`flex space-x-2 items-center ${
                              !isOpen ? 'justify-center' : ''
                            } ${elem?.submenu?.length > 0 ? 'w-[90%]' : 'w-full'}`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: elem?.menuIcon }}></div>
                            {isOpen && (
                              <span className='font-QuicksandMedium text-sm block'>
                                {t(elem?.menuName)}
                              </span>
                            )}
                          </div>
                          {isOpen && elem?.submenu?.length > 0 && (
                            <div className='flex items-center justify-end w-[10%]'>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setOpenTab(
                                    openTab === t(elem?.menuName) ? '' : t(elem?.menuName),
                                  );
                                }}
                                className='min-w-[20px] h-[20px] rounded-[6px] duration-150 flex items-center justify-center menu-arrow hover:text-white'
                              >
                                <ChevronUp
                                  className={`w-3 h-3 ${
                                    openTab === t(elem?.menuName)
                                      ? 'transition duration-150 rotate-0'
                                      : 'transition duration-150 rotate-180'
                                  }`}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    </Tooltip>
                  </div>

                  {openTab === t(elem?.menuName) || isOpen === false ? (
                    <div className={`${isOpen ? 'ms-5' : 'ms-0'}`}>
                      {elem?.submenu?.map((x, index) => {
                        const isIncludeInUrl = x?.includeInUrl?.some((value) =>
                          url.includes(value),
                        );
                        return (
                          <Link
                            key={index}
                            to={!saveChanges?.isUnsaveChanges ? x?.submenulink : '#'}
                            className='w-full relative flex items-center gap-x-1.5 group select-none'
                            onClick={(e) => {
                              if (saveChanges?.isUnsaveChanges) {
                                e.preventDefault();
                                setSaveChanges({
                                  isUnsaveChanges: false,
                                  showChangesPopup: true,
                                  backLink: x?.submenulink,
                                });
                              }
                            }}
                          >
                            <Tooltip
                              title={t(x?.submenuName)}
                              arrow
                              placement='right'
                              open={openTooltip}
                              disableHoverListener={isOpen}
                              classes={{ tooltip: 'my-tooltip-class' }}
                            >
                              <button
                                className={
                                  isIncludeInUrl
                                    ? `menu-item space-x-2 px-2.5 text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                    : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                }
                              >
                                <div dangerouslySetInnerHTML={{ __html: x?.submenuIcon }}></div>
                                {isOpen && (
                                  <span className='font-QuicksandMedium text-sm block'>
                                    {t(x?.submenuName)}
                                  </span>
                                )}
                              </button>
                            </Tooltip>
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}

            {/* apps menu */}
            {filterednewArray?.length > 0 && installedExtensions?.length > 0 && isOpen ? (
              <span className='text-[11px] text-left font-medium text-[#FAF9F6] opacity-50 py-2 block uppercase'>
                {t('lang_apps')}
              </span>
            ) : (
              installedExtensions?.length > 0 && <span className='my-2'></span>
            )}
            {filterednewArray?.map((elem, index) => {
              const isIncludeInUrl =
                elem?.includeInUrl && elem?.includeInUrl?.some((value) => url?.includes(value));
              return (
                <div className='w-full' key={index}>
                  <div
                    className={`w-full relative items-center gap-x-1.5 group select-none ${
                      isOpen == false && elem?.submenu?.length > 0 ? 'hidden' : 'flex'
                    }`} 
                    autoFocus
                  >
                    <Tooltip
                      title={t(elem?.menuName)}
                      arrow
                      placement='right'
                      open={openTooltip}
                      disableHoverListener={isOpen}
                      classes={{ tooltip: 'my-tooltip-class' }}
                    >
                      <Link
                        to={!saveChanges?.isUnsaveChanges ? elem?.link : '#'}
                        className={
                          isIncludeInUrl && openTab !== t(elem?.menuName)
                            ? `menu-item space-x-2 px-2.5  text-white `
                            : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white`
                        }
                        onClick={(e) => {
                          if (saveChanges?.isUnsaveChanges) {
                            e.preventDefault();
                            setSaveChanges({
                              isUnsaveChanges: false,
                              showChangesPopup: true,
                              backLink: elem?.link,
                            });
                          } else {
                            setOpenTab(
                              elem?.submenu?.length > 0
                                ? openTab === t(elem?.menuName)
                                  ? t(elem?.menuName)
                                  : openTab !== t(elem?.menuName) && t(elem?.menuName)
                                : '',
                            );
                          }
                        }}
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div
                            className={`flex space-x-2 items-center ${
                              elem?.submenu?.length > 0 ? 'w-[90%]' : 'w-full'
                            } ${!isOpen ? 'justify-center' : ''}`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: elem?.menuIcon }}></div>
                            {isOpen && (
                              <span className='font-QuicksandMedium text-sm block'>
                                {t(elem?.menuName)}
                              </span>
                            )}
                            {elem?.menuName === 'lang_POS' && (
                              <Tooltip title='Visit store' onClick={(e) => e.stopPropagation()}>
                                <button
                                  onClick={() => {
                                    window.open(`http://localhost:3001`, '_blank');
                                  }}
                                  className='duration-200'
                                >
                                  <ExternalLink className='w-4 h-4' />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                          {isOpen && elem?.submenu?.length > 0 && (
                            <div className='flex items-center justify-end w-[10%]'>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setOpenTab(
                                    openTab === t(elem?.menuName)
                                      ? ''
                                      : openTab !== t(elem?.menuName) && t(elem?.menuName),
                                  );
                                }}
                                className='min-w-[20px] h-[20px] rounded-[6px] duration-150 flex items-center justify-center menu-arrow hover:text-white'
                              >
                                <ChevronUp
                                  className={`w-3 h-3 ${
                                    openTab === t(elem?.menuName)
                                      ? 'transition  duration-150 rotate-0'
                                      : 'transition  duration-150 rotate-180'
                                  }`}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    </Tooltip>
                  </div>

                  {openTab === t(elem?.menuName) || isOpen === false ? (
                    <div className={`${isOpen ? 'ms-5' : ''}`}>
                      {elem?.submenu?.map((x, index) => {
                        const isIncludeInUrl = x?.includeInUrl?.some((value) =>
                          url.includes(value),
                        );
                        return (
                          <Link
                            key={index}
                            to={!saveChanges?.isUnsaveChanges ? x?.submenulink : '#'}
                            className='w-full relative flex items-center gap-x-1.5 group select-none'
                            onClick={(e) => {
                              if (saveChanges?.isUnsaveChanges) {
                                e.preventDefault();
                                setSaveChanges({
                                  isUnsaveChanges: false,
                                  showChangesPopup: true,
                                  backLink: x?.submenulink,
                                });
                              }
                            }}
                          >
                            <Tooltip
                              title={t(x?.submenuName)}
                              arrow
                              placement='right'
                              open={openTooltip}
                              disableHoverListener={isOpen}
                              classes={{ tooltip: 'my-tooltip-class' }}
                            >
                              <button
                                className={
                                  isIncludeInUrl
                                    ? `menu-item space-x-2 px-2.5 text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                    : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                }
                              >
                                <div dangerouslySetInnerHTML={{ __html: x?.submenuIcon }}></div>
                                {isOpen && (
                                  <span className='font-QuicksandMedium text-sm block'>
                                    {t(x?.submenuName)}
                                  </span>
                                )}
                              </button>
                            </Tooltip>
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
            {/* configuration menu */}
            {filteredconfiguration?.length > 0 && isOpen ? (
              <span className='text-[11px] text-left font-medium text-[#FAF9F6] opacity-50 py-2 block uppercase'>
                {t('lang_configuration')}
              </span>
            ) : (
              <span className='my-2'></span>
            )}
            {filteredconfiguration?.map((elem, index) => {
              const isIncludeInUrl =
                elem?.includeInUrl && elem?.includeInUrl?.some((value) => url?.includes(value));
              return (
                <div className='w-full' key={index}>
                  <div
                    className={`w-full relative items-center gap-x-1.5 group select-none ${
                      isOpen == false && elem?.submenu?.length > 0 ? 'hidden' : 'flex'
                    }`}
                    autoFocus
                  >
                    <Tooltip
                      title={t(elem?.menuName)}
                      arrow
                      placement='right'
                      open={openTooltip}
                      disableHoverListener={isOpen}
                      classes={{ tooltip: 'my-tooltip-class' }}
                    >
                      <Link
                        to={!saveChanges?.isUnsaveChanges ? elem?.link : '#'}
                        className={
                          isIncludeInUrl && openTab !== t(elem?.menuName)
                            ? `menu-item space-x-2 px-2.5  text-white`
                            : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white`
                        }
                        onClick={(e) => {
                          if (saveChanges?.isUnsaveChanges) {
                            e.preventDefault();
                            setSaveChanges({
                              isUnsaveChanges: false,
                              showChangesPopup: true,
                              backLink: elem?.link,
                            });
                          } else {
                            setOpenTab(
                              elem?.submenu?.length > 0
                                ? openTab === t(elem?.menuName)
                                  ? t(elem?.menuName)
                                  : openTab !== t(elem?.menuName) && t(elem?.menuName)
                                : '',
                            );
                          }
                        }}
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div
                            className={`flex space-x-2 items-center ${
                              elem?.submenu?.length > 0 ? 'w-[90%]' : 'w-full'
                            } ${!isOpen ? 'justify-center' : ''}`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: elem?.menuIcon }}></div>
                            {isOpen && (
                              <span className='font-QuicksandMedium text-sm block'>
                                {t(elem?.menuName)}
                              </span>
                            )}
                          </div>
                          {isOpen && elem?.submenu?.length > 0 && (
                            <div className='flex items-center justify-end w-[10%]'>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setOpenTab(
                                    openTab === t(elem?.menuName)
                                      ? ''
                                      : openTab !== t(elem?.menuName) && t(elem?.menuName),
                                  );
                                }}
                                className='min-w-[20px] h-[20px] rounded-[6px] duration-150 flex items-center justify-center menu-arrow hover:text-white'
                              >
                                <ChevronUp
                                  className={`w-3 h-3 ${
                                    openTab === t(elem?.menuName)
                                      ? 'transition  duration-150 rotate-0'
                                      : 'transition  duration-150 rotate-180'
                                  }`}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    </Tooltip>
                  </div>

                  {openTab === t(elem?.menuName) || isOpen === false ? (
                    <div className={isOpen ? 'ms-5' : ''}>
                      {elem?.submenu?.map((x, index) => {
                        const isIncludeInUrl = x?.includeInUrl?.some((value) =>
                          url.includes(value),
                        );
                        return (
                          <Link
                            key={index}
                            to={!saveChanges?.isUnsaveChanges ? x?.submenulink : '#'}
                            className='w-full relative flex items-center gap-x-1.5 group select-none'
                            onClick={(e) => {
                              if (saveChanges?.isUnsaveChanges) {
                                e.preventDefault();
                                setSaveChanges({
                                  isUnsaveChanges: false,
                                  showChangesPopup: true,
                                  backLink: x?.submenulink,
                                });
                              }
                            }}
                          >
                            <Tooltip
                              title={t(x?.submenuName)}
                              arrow
                              placement='right'
                              open={openTooltip}
                              disableHoverListener={isOpen}
                              classes={{ tooltip: 'my-tooltip-class' }}
                            >
                              <button
                                className={
                                  isIncludeInUrl
                                    ? `menu-item space-x-2 px-2.5 text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                    : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                }
                              >
                                <div dangerouslySetInnerHTML={{ __html: x?.submenuIcon }}></div>
                                {isOpen && (
                                  <span className='font-QuicksandMedium text-sm block'>
                                    {t(x?.submenuName)}
                                  </span>
                                )}
                              </button>
                            </Tooltip>
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
            {/* other menu */}
            {filteredotherMenuArray?.length > 0 && isOpen ? (
              <span className='text-[11px] text-left font-medium text-[#FAF9F6] opacity-50 py-2 block uppercase'>
                {t('lang_other')}
              </span>
            ) : (
              <span className='my-2'></span>
            )}
            {filteredotherMenuArray?.map((elem, index) => {
              const isIncludeInUrl =
                elem?.includeInUrl && elem?.includeInUrl?.some((value) => url?.includes(value));
              return (
                <div className='w-full' key={index}>
                  <div
                    className={`w-full relative items-center gap-x-1.5 group select-none ${
                      isOpen == false && elem?.submenu?.length > 0 ? 'hidden' : 'flex'
                    }`}
                    autoFocus
                  >
                    <Tooltip
                      title={t(elem?.menuName)}
                      arrow
                      placement='right'
                      open={openTooltip}
                      disableHoverListener={isOpen}
                      classes={{ tooltip: 'my-tooltip-class' }}
                    >
                      <Link
                        to={
                          saveChanges?.isUnsaveChanges === false &&
                          elem?.link !== 'https://help.shopeasy.ai/'
                            ? elem?.link
                            : '#'
                        }
                        onClick={(e) => {
                          handleNavigate(elem?.link);
                          if (saveChanges?.isUnsaveChanges) {
                            e.preventDefault();
                            setSaveChanges({
                              isUnsaveChanges: false,
                              showChangesPopup: true,
                              backLink: elem?.link,
                            });
                          } else {
                            setOpenTab(
                              openTab === t(elem?.menuName)
                                ? ''
                                : openTab !== t(elem?.menuName) && t(elem?.menuName),
                            );
                          }
                        }}
                        className={
                          isIncludeInUrl && openTab !== t(elem?.menuName)
                            ? `menu-item space-x-2 px-2.5 text-white`
                            : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white`
                        }
                      >
                        <div className='flex w-full items-center justify-between'>
                          <div
                            className={`flex space-x-2 items-center ${
                              elem?.submenu?.length > 0 ? 'w-[90%]' : 'w-full'
                            } ${!isOpen ? 'justify-center' : ''}`}
                          >
                            <div dangerouslySetInnerHTML={{ __html: elem?.menuIcon }}></div>
                            {isOpen && (
                              <span className='font-QuicksandMedium capitalize text-sm block'>
                                {t(elem?.menuName)}
                              </span>
                            )}
                          </div>
                          {isOpen && elem?.submenu?.length > 0 && (
                            <div className='flex items-center justify-end w-[10%]'>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setOpenTab(
                                    openTab === t(elem?.menuName)
                                      ? ''
                                      : openTab !== t(elem?.menuName) && t(elem?.menuName),
                                  );
                                }}
                                className='min-w-[20px] h-[20px] rounded-[6px] duration-150 flex items-center justify-center menu-arrow hover:text-white'
                              >
                                <ChevronUp
                                  className={`w-3 h-3 ${
                                    openTab === t(elem?.menuName)
                                      ? 'transition duration-150 rotate-0'
                                      : 'transition duration-150 rotate-180'
                                  }`}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </Link>
                    </Tooltip>
                  </div>

                  {openTab === t(elem?.menuName) || isOpen === false ? (
                    <div className={isOpen ? 'ms-5' : ''}>
                      {elem?.submenu?.map((x, index) => {
                        const isIncludeInUrl = x?.includeInUrl?.some((value) =>
                          url.includes(value),
                        );
                        return (
                          <Link
                            key={index}
                            to={saveChanges?.isUnsaveChanges === false ? x?.submenulink : '#'}
                            onClick={(e) => {
                              if (saveChanges?.isUnsaveChanges) {
                                e.preventDefault();
                                setSaveChanges({
                                  isUnsaveChanges: false,
                                  showChangesPopup: true,
                                  backLink: x?.submenulink,
                                });
                              }
                            }}
                            className='w-full relative flex items-center gap-x-1.5 group select-none'
                          >
                            <Tooltip
                              title={t(x?.submenuName)}
                              arrow
                              placement='right'
                              open={openTooltip}
                              disableHoverListener={isOpen}
                              classes={{ tooltip: 'my-tooltip-class' }}
                            >
                              <button
                                className={
                                  isIncludeInUrl
                                    ? `menu-item space-x-2 px-2.5 text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                    : `menu-item-close space-x-2 bg-transparent px-2.5 hover:text-white ${
                                        !isOpen ? 'justify-center' : ''
                                      }`
                                }
                              >
                                <div dangerouslySetInnerHTML={{ __html: x?.submenuIcon }}></div>
                                {isOpen && (
                                  <span className='font-QuicksandMedium text-sm block'>
                                    {t(x?.submenuName)}
                                  </span>
                                )}
                              </button>
                            </Tooltip>
                          </Link>
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {celebratingOrders && popUp && (
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={popUp}
          onClose={() => setPopUp(false)}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          {/* <Fade in={open}> */}
          <Box>
            <div className='flex justify-center z-10 absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] overflow-hidden bg-opacity-10'>
              <div className='modal fade' id='myModal' role='dialog'>
                <div className='modal-dialog '>
                  <div className='card'>
                    {/* <div className="text-right cross">
                          <i className="fa fa-times" />
                        </div> */}
                    {/* bg-gradient-to-t from-zinc-600 via-gray-300 to-slate-50 */}
                    <div className='text-center secondary-bg-color'>
                      <div className=''>
                        <Confetti className='overflow-hidden' />
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={
                            'https://img.icons8.com/bubbles/200/000000/trophy.png' || NOPHOTO_IMAGE
                          }
                          className='m-auto block'
                          alt='image'
                        />
                      </div>

                      <div className='card-body z-10'>
                        <h4 className=''>CONGRATULATIONS!</h4>
                        <p className=' '>
                          Congratulations to our dedicated admin team on reaching a significant
                          milestone – {celebratingOrders} orders successfully completed! 🎉 Your
                          hard work, efficiency, and commitment to delivering top-notch service have
                          contributed immensely to our success. Thank you for your unwavering
                          dedication, and here's to many more milestones ahead!
                        </p>
                      </div>

                      {/* <p>
                            You have been personally selected to take part in our 2017 annual
                            visitors survey!
                          </p> */}
                      <button
                        className='btn mb-4 btn-out btn-square continue'
                        onClick={() => setPopUp(false)}
                      >
                        CONTINUE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Box>
          {/* </Fade> */}
        </Modal>
      )}
    </>
  );
};

export default Sidebar;
