import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { NOPHOTO_IMAGE } from '../../../Assets/StaticData/StaticImage';
import { formatDate } from '../../../controllers/FormatAmount';
import { t } from 'i18next';
const Blog = ({ colors, blogData, blogDesign }) => {
  const limitSlidesPerView =
    blogData?.section?.blog_limit < blogData?.section?.blogs?.length
      ? blogData?.section?.blog_limit
      : blogData?.section?.blogs?.length;
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredIndex, setIsHoveredIndex] = useState(false);
  const [setting, setSetting] = useState();
  const [display, setDisplay] = useState();
  useEffect(() => {
    setSetting({
      dots: false,
      infinite: Number(limitSlidesPerView) > 1 ? true : false,
      autoplay: true,
      autoplaySpeed: Number(blogData?.section?.carousel_speed_ms),
      slidesToShow:
        window?.innerWidth > 900
          ? Number(limitSlidesPerView)
          : window?.innerWidth > 700 && window?.innerWidth <= 900
          ? 2
          : window?.innerWidth > 100 && window?.innerWidth <= 700
          ? 1
          : 1,
      slidesToScroll: 1,
      pauseOnHover: true,
      centerPadding: '50px',
      swipeToSlide: true,
      prevArrow: (
        <div className='slick-prev'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width={12}
            height={20}
            viewBox='0 0 12 20'
            id='left'
          >
            <g fill='none' fillRule='evenodd'>
              <g
                fill={`${colors?.primary_button_color}`}
                fillRule='nonzero'
                transform='translate(-14 -8)'
              >
                <g transform='translate(14 8)'>
                  <path d='M5.909 10.681a1.987 1.987 0 0 1-.278-.732c.004-.253.103-.496.278-.679l5.517-5.732c.761-.81.766-2.072.01-2.887l-.028-.03A1.959 1.959 0 0 0 8.554.613L.287 9.226A1.08 1.08 0 0 0 0 9.949c.044.275.141.538.287.775l8.267 8.665a1.959 1.959 0 0 0 2.854-.012l.028-.036a2.133 2.133 0 0 0-.01-2.9l-5.517-5.76z' />
                </g>
              </g>
            </g>
          </svg>
        </div>
      ),
      nextArrow: (
        <div className='slick-next'>
          <svg xmlns='http://www.w3.org/2000/svg' width={12} height={20} id='right'>
            <path
              fill={`${colors?.primary_button_color}`}
              d='M6.091 10.681c.14-.223.235-.472.278-.732a1.003 1.003 0 0 0-.278-.679L.574 3.538A2.116 2.116 0 0 1 .564.651L.592.62A1.959 1.959 0 0 1 3.446.613l8.267 8.613c.182.197.285.455.287.723a2.124 2.124 0 0 1-.287.775l-8.267 8.665a1.959 1.959 0 0 1-2.854-.012l-.028-.036a2.133 2.133 0 0 1 .01-2.9l5.517-5.76z'
            />
          </svg>
        </div>
      ),
    });
    setDisplay(blogData?.section?.blog_display_grid);
  }, [blogData, limitSlidesPerView, window?.innerWidth]);

  return (
    blogData?.section?.blogs?.length > 0 &&
    blogData?.status && (
      <div
        className='mx-auto slider-container'
        style={{
          display: blogDesign?.display,
          flexDirection: blogDesign?.direction,
          alignItems: blogDesign?.align_item,
          // gap: `${blogDesign?.gap}px`,
          marginTop: `${blogDesign?.margin_top}px`,
          marginBottom: `${blogDesign?.margin_bottom}px`,
          marginLeft: `${blogDesign?.margin_left}px`,
          marginRight: `${blogDesign?.margin_right}px`,
          paddingTop: `${blogDesign?.padding_top}px`,
          paddingBottom: `${blogDesign?.padding_bottom}px`,
          paddingLeft: `${blogDesign?.padding_left}px`,
          paddingRight: `${blogDesign?.padding_right}px`,
          height: `${blogDesign?.height}${blogDesign?.height_unit}`,
          width: `${blogDesign?.width}${blogDesign?.width_unit}`,
          borderStyle: blogDesign?.border_style,
          borderWidth: `${blogDesign?.border_width}px`,
          borderColor: blogDesign?.border_color,
          float: blogDesign?.float,
          maxHeight: `${blogDesign?.max_height}${blogDesign?.max_height_unit}`,
          maxWidth: `${blogDesign?.max_width}${blogDesign?.max_width_unit}`,
          minHeight: `${blogDesign?.min_height}${blogDesign?.min_height_unit}`,
          minWidth: `${blogDesign?.min_width}${blogDesign?.min_width_unit}`,
        }}
      >
        <div
          // style={{ color: colors?.main_title_color }}
          style={{
            fontSize: blogDesign?.font_size ? `${blogDesign?.font_size}px` : '32px',
            lineHeight: `${blogDesign?.font_height}px`,
            fontWeight: blogDesign?.font_weight ? blogDesign?.font_weight : 500,
            fontStyle: blogDesign?.font_style,
            color: blogDesign?.font_color,
            textAlign: blogDesign?.font_align ? blogDesign?.font_align : 'center',
            textDecoration: blogDesign?.font_decoration,
            textTransform: blogDesign?.font_capitalize,
            wordBreak: blogDesign?.font_break,
            textWrap: blogDesign?.font_wrap,
          }}
          // className='w-full lg:w-[50%] mx-auto font-medium text-center mb-[20px] break-words overflow-hidden'
        >
          {blogData?.section?.blog_section_title}
        </div>
        <div style={{ width: '90%', margin: '0 auto' }}>
          {blogData?.section?.carousel_status ? (
            <Slider {...setting} className='w-full blog-slider'>
              {blogData &&
                blogData?.section?.blogs?.map((elem, index) => {
                  return (
                    <div
                      style={{
                        display: display === true || display === 'true' ? 'block' : 'grid',
                      }}
                      className={`w-full slick-slide rounded-[6px] p-5 ${
                        display === true || display === 'true'
                          ? 'block shadow'
                          : 'grid grid-cols-2 space-x-4'
                      }`}
                    >
                      <div className='w-full'>
                        <Link
                          aria-label='blogimage'
                          className={`block mx-auto cursor-pointer overflow-hidden`}
                        >
                          <img
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.blogData?.image || NOPHOTO_IMAGE}
                            alt='blog Image'
                            style={{
                              width: blogData?.section?.blog_image_width,
                              height: blogData?.section?.blog_image_height,
                              objectFit: blogDesign?.fit
                            }}
                            className={`block mx-auto cursor-pointer`}
                          />
                        </Link>
                      </div>
                      <div className='w-full pt-4 space-y-3'>
                        <div className='flex items-center justify-between'>
                          {elem?.blogData?.blogcategory?.blogcategory_name && (
                            <span className='primary-bg-color rounded-[6px] px-3 py-1 text-sm font-normal '>
                              {elem?.blogData?.blogcategory?.blogcategory_name}
                            </span>
                          )}
                          <p
                            style={{ color: colors?.primary_body_text_color }}
                            className='text-[12px] font-normal'
                          >
                            {formatDate(elem?.blogData?.createdAt)}
                          </p>
                        </div>
                        <Link aria-label='blogtitle' className='block'>
                          <h6
                            style={{ color: colors?.primary_body_text_color }}
                            className='line-clamp-1 text-[1rem] font-medium'
                          >
                            {elem?.blogData?.title}
                          </h6>
                        </Link>
                        <div>
                          {elem?.blogData?.description && (
                            <p
                              style={{ color: colors?.primary_body_text_color }}
                              className='line-clamp-2 text-sm    '
                              dangerouslySetInnerHTML={{ __html: elem?.blogData?.description }}
                            ></p>
                          )}
                        </div>
                        <Link
                          aria-label='blogmore'
                          className='transition-all duration-300 inline-block relative text-[13px] font-medium align-middle cursor-pointer'
                        >
                          <p
                            className='ps-[35px] transition-all duration-300 '
                            onMouseEnter={() => setIsHoveredIndex(elem?.blogData?._id)}
                            onMouseLeave={() => setIsHoveredIndex('')}
                            style={{
                              color:
                                isHoveredIndex === elem?.blogData?._id
                                  ? colors?.secondary_button_color
                                  : colors?.primary_button_color,
                            }}
                          >
                            <span
                              style={{
                                backgroundColor:
                                  isHoveredIndex === elem?.blogData?._id
                                    ? colors?.secondary_button_color
                                    : colors?.primary_button_color,
                              }}
                              className='absolute left-0 top-[50%] -translate-y-[50%] w-[30px] h-[2px] bg-black transition-all duration-300 '
                            ></span>
                            {t('lang_Read_More')}
                          </p>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          ) : (
            <div
              className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-${
                limitSlidesPerView - 1
              } xl:grid-cols-${limitSlidesPerView} gap-5 w-full`}
            >
              {blogData &&
                blogData?.section?.blogs?.map((elem, index) => {
                  return (
                    <div
                      key={index}
                      className={`w-full rounded-[6px] p-5 ${
                        blogData?.section?.blog_display_grid ||
                        blogData?.section?.blog_display_grid === 'true'
                          ? 'block shadow'
                          : 'grid grid-cols-2 space-x-4'
                      }`}
                    >
                      <div className='w-full'>
                        <Link
                          aria-label='blogimage'
                          className={`block mx-auto cursor-pointer overflow-hidden`}
                        >
                          <img
                            onError={(e) => {
                              e.target.src = NOPHOTO_IMAGE;
                            }}
                            src={elem?.blogData?.image || NOPHOTO_IMAGE}
                            alt='blog Image'
                            style={{
                              width: blogData?.section?.blog_image_width,
                              height: blogData?.section?.blog_image_height,
                            }}
                            className={`block mx-auto cursor-pointer`}
                          />
                        </Link>
                      </div>
                      <div className='w-full pt-4 space-y-3'>
                        <div className='flex items-center justify-between'>
                          {elem?.blogData?.blogcategory?.blogcategory_name && (
                            <span className='primary-bg-color rounded-[6px] px-3 py-1 text-sm font-normal '>
                              {elem?.blogData?.blogcategory?.blogcategory_name}
                            </span>
                          )}
                          <p
                            style={{ color: colors?.primary_body_text_color }}
                            className='text-[12px] font-normal'
                          >
                            {formatDate(elem?.blogData?.createdAt)}
                          </p>
                        </div>
                        <Link aria-label='blogtitle' className='block'>
                          <h6
                            style={{ color: colors?.primary_body_text_color }}
                            className='line-clamp-1 text-[1rem] font-medium'
                          >
                            {elem?.blogData?.title}
                          </h6>
                        </Link>
                        <div>
                          {elem?.blogData?.description && (
                            <p
                              style={{ color: colors?.primary_body_text_color }}
                              className='line-clamp-2 text-sm    '
                              dangerouslySetInnerHTML={{ __html: elem?.blogData?.description }}
                            ></p>
                          )}
                        </div>
                        <Link
                          aria-label='blogmore'
                          className='transition-all duration-300 inline-block relative text-[13px] font-medium align-middle cursor-pointer'
                        >
                          <p
                            className='ps-[35px] transition-all duration-300 '
                            onMouseEnter={() => setIsHoveredIndex(elem?.blogData?._id)}
                            onMouseLeave={() => setIsHoveredIndex('')}
                            style={{
                              color:
                                isHoveredIndex === elem?.blogData?._id
                                  ? colors?.secondary_button_color
                                  : colors?.primary_button_color,
                            }}
                          >
                            <span
                              style={{
                                backgroundColor:
                                  isHoveredIndex === elem?.blogData?._id
                                    ? colors?.secondary_button_color
                                    : colors?.primary_button_color,
                              }}
                              className='absolute left-0 top-[50%] -translate-y-[50%] w-[30px] h-[2px] bg-black transition-all duration-300 '
                            ></span>
                            {t('lang_Read_More')}
                          </p>
                        </Link>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {blogData?.section?.button_text?.length ? (
            <div className='text-center mt-[10px]'>
              <Link
                aria-label='manufacturers'
                style={{
                  transition: 'background-color 0.3s ease, color 0.3s ease',
                  borderRadius: colors?.button_redius,
                  backgroundColor: isHovered
                    ? colors?.secondary_button_color || ''
                    : colors?.primary_button_color,
                  color: isHovered
                    ? colors?.secondary_button_text_color || ''
                    : colors?.primary_button_text_color,
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`uppercase text-sm inline-block px-9 py-3`}
              >
                {blogData?.section?.button_text}
              </Link>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    )
  );
};

export default Blog;
