import { FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MuiSwitch from '../../controllers/MuiSwitch';
import API from '../../API';
import { ChevronDown } from 'feather-icons-react/build/IconComponents';
import Notification from '../Notification';
import { t } from 'i18next';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';

const ProductCatalog = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const [view, setView] = useState();
  const [defaultFilter, setDefaultFilter] = useState();
  const [productCatalogData, setProductCatalogData] = useState({
    products_per_row: '',
    rows_per_page: '',
  });
  const [formLoading, setFormLoading] = useState(false);
  const HandleClientInfo = async () => {
    setFormLoading(true);
    try {
      const client = await API.get('/admin/client/getClient');
      const result = decryptData(client?.data);
      setProductCatalogData({
        products_per_row: result?.data?.products_per_row,
        rows_per_page: result?.data?.rows_per_page,
      });
      setView(result?.data?.display_grid);
      setDefaultFilter(
        result?.data?.default_filter_select ? result?.data?.default_filter_select : 'BestSeller',
      );
      setFormLoading(false);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const [productFilter, setProductFilter] = useState({
    A_Z: true,
    BestSeller: true,
    TopRated: true,
    Featured: true,
    HighToLow: true,
    LowToHigh: true,
    Newest: true,
    Special: true,
    Z_A: true,
  });
  const getProductFilter = async () => {
    try {
      const ApiCall = await API.get('/admin/client/GetProductFilter');
      if (ApiCall?.data) {
        const result = decryptData(ApiCall?.data);
        setProductFilter({
          A_Z: result?.A_Z,
          BestSeller: result?.BestSeller,
          Featured: result?.Featured,
          TopRated: result?.TopRated,
          HighToLow: result?.HighToLow,
          LowToHigh: result?.LowToHigh,
          Newest: result?.Newest,
          Special: result?.Special,
          Z_A: result?.Z_A,
        });
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    HandleClientInfo();
    getProductFilter();
  }, []);

  useEffect(() => {
    updateFilter();
  }, [productFilter, defaultFilter]);
  const updateFilter = async () => {
    try {
      const payload = encryptData({
        clientInfo: {
          product_filter: productFilter,
          default_filter_select: defaultFilter,
        },
      });
      const ApiCall = await API.put(`/admin/client/updateClient`, payload);
      HandleClientInfo();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const HandleSubmit = async (e) => {
    e.preventDefault();
    let body;
    try {
      body = {
        clientInfo: {
          products_per_row: productCatalogData?.products_per_row,
          rows_per_page: productCatalogData?.rows_per_page,
          display_grid: view,
        },
      };
      const payload = encryptData(body);
      let ApiCall = await API.put('/admin/client/updateClient', payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        HandleClientInfo();
        setIsNotification(true);
        setNotificationMsg('Profile Updated SuccessFully');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='secondary-bg-color table-text shadow space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
        <div className='w-full'>
          <div className='text-base font-medium text-primary'>
            {t('lang_Product_Catalog')}
            <p className='text-xs table-text font-normal'>
              {t('lang_Manage_product_view_in_store')}
            </p>
          </div>
        </div>
        <>
          <div className='w-full'>
            <div className='grid grid-cols-1 sm:grid-cols-2 mb-4 w-full gap-5'>
              <div className='w-full'>
                <label
                  className='block table-text mb-1 text-[13px] capitalize font-medium'
                  htmlFor='instagram'
                >
                  {t('lang_Product_per_row')}
                </label>
                <div className='relative'>
                  <select
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    name='products_per_row'
                    value={productCatalogData?.products_per_row}
                    onChange={(e) =>
                      setProductCatalogData({
                        ...productCatalogData,
                        products_per_row: e.target.value,
                      })
                    }
                  >
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                <p className='w-full text-xs mt-0 text-gray-400'>
                  {t('lang_How_Many_Products_Should_Be_Shown_Per_Row')}
                </p>
              </div>
              <div className='w-full'>
                <label
                  className='block table-text mb-1 text-[13px] capitalize font-medium'
                  htmlFor='instagram'
                >
                  {t('lang_Rows_per_page')}
                </label>
                <div className='relative'>
                  <select
                    className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                    name='rows_per_page'
                    value={productCatalogData?.rows_per_page}
                    onChange={(e) =>
                      setProductCatalogData({
                        ...productCatalogData,
                        rows_per_page: e.target.value,
                      })
                    }
                  >
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5</option>
                    <option value='6'>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
                <p className='w-full text-xs mt-0 text-gray-400'>
                  {t('lang_How_Many_Rows_Should_Be_Shown_Per_Row')}
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <label
                className='block table-text mb-1 text-[13px] capitalize font-medium'
                htmlFor='instagram'
              >
                {t('lang_Product_View')}
              </label>
              <div className='relative'>
                <select
                  className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                  value={view}
                  onChange={(e) => setView(e.target.value)}
                >
                  <option value={true}>{t('lang_Grid')}</option>
                  <option value={false}>{t('lang_List')}</option>
                </select>
                <div className='select-arrow'></div>
              </div>
            </div>
            <div className='mb-4'>
              <label
                className='block table-text mb-1 text-[13px] capitalize font-medium'
                htmlFor='instagram'
              >
                {t('lang_Product_sort_by')}
              </label>
              <table className='w-full bg-gray-200 border main-border-color rounded-[6px] overflow-hidden'>
                <thead>
                  <tr className='h-[45px] align-middle primary-bg-color border-b main-border-color text-xs uppercase font-semibold'>
                    <td className='px-4 w-full'>{t('lang_Field')}</td>
                    <td className='px-4 w-[80px] capitalize'>{t('lang_default')}</td>
                    <td className='px-4 w-[80px]'>{t('lang_status')}</td>
                  </tr>
                </thead>
                <tbody className='secondary-bg-color'>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Best_Seller_product')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.BestSeller && (
                        <input
                          type='radio'
                          onChange={(e) => setDefaultFilter('BestSeller')}
                          value={defaultFilter}
                          checked={defaultFilter === 'BestSeller'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'BestSeller' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.BestSeller === true}
                          disabled={defaultFilter === 'BestSeller'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({
                              ...productFilter,
                              BestSeller: !productFilter?.BestSeller,
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Top_Rated_product')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.TopRated && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('TopRated')}
                          checked={defaultFilter === 'TopRated'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'TopRated' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.TopRated === true}
                          disabled={defaultFilter === 'TopRated'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({
                              ...productFilter,
                              TopRated: !productFilter?.TopRated,
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Featured_Product')}</td>
                    <td className='px-3 py-2  text-center w-[80px]'>
                      {productFilter?.Featured && (
                        <input
                          type='radio'
                          onChange={(e) => setDefaultFilter('Featured')}
                          value={defaultFilter}
                          checked={defaultFilter === 'Featured'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'Featured' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.Featured === true}
                          disabled={defaultFilter === 'Featured'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({
                              ...productFilter,
                              Featured: !productFilter?.Featured,
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Newest_Product')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.Newest && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('Newest')}
                          checked={defaultFilter === 'Newest'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'Newest' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.Newest === true}
                          disabled={defaultFilter === 'Newest'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({ ...productFilter, Newest: !productFilter?.Newest })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Special_Products')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.Special && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('Special')}
                          checked={defaultFilter === 'Special'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'Special' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.Special === true}
                          disabled={defaultFilter === 'Special'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({ ...productFilter, Special: !productFilter?.Special })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_High_To_Low')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.HighToLow && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('HighToLow')}
                          checked={defaultFilter === 'HighToLow'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'HighToLow' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.HighToLow === true}
                          disabled={defaultFilter === 'HighToLow'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({
                              ...productFilter,
                              HighToLow: !productFilter?.HighToLow,
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Low_To_High')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.LowToHigh && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('LowToHigh')}
                          checked={defaultFilter === 'LowToHigh'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'LowToHigh' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.LowToHigh === true}
                          disabled={defaultFilter === 'LowToHigh'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({
                              ...productFilter,
                              LowToHigh: !productFilter?.LowToHigh,
                            })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_A_to_Z')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.A_Z && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('A_Z')}
                          checked={defaultFilter === 'A_Z'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'A_Z' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.A_Z === true}
                          disabled={defaultFilter === 'A_Z'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({ ...productFilter, A_Z: !productFilter?.A_Z })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                  <tr className='h-[50px] border-b border-gray-200 secondary-bg-color hover:bg-[#fafafa]'>
                    <td className='px-3 py-2 w-full'>{t('lang_Z_to_A')}</td>
                    <td className='px-3 py-2 text-center w-[80px]'>
                      {productFilter?.Z_A && (
                        <input
                          type='radio'
                          value={defaultFilter}
                          onChange={(e) => setDefaultFilter('Z_A')}
                          checked={defaultFilter === 'Z_A'}
                          className='text-center w-4 h-4'
                        />
                      )}
                    </td>
                    <td className='px-3 py-2 text-right w-[80px]'>
                      <label
                        className={`relative flex ms-2 items-center ${
                          defaultFilter === 'Z_A' ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                      >
                        <FormControlLabel
                          checked={productFilter?.Z_A === true}
                          disabled={defaultFilter === 'Z_A'}
                          control={<MuiSwitch />}
                          onChange={() =>
                            setProductFilter({ ...productFilter, Z_A: !productFilter?.Z_A })
                          }
                        />
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='w-full flex justify-end'>
              <button
                type='button'
                onClick={(e) => HandleSubmit(e)}
                className='inline-block btn text-white text-sm font-medium py-3 px-8 rounded-[6px] transition duration-300'
              >
                {t('lang_Submit')}
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default ProductCatalog;
