import React from 'react';
import EditIconStyleContainer from './EditIconStyleContainer';

const EditIconStyleSection = ({
  iconDesign,
  setIconDesign,
  handleApplyChangesElementor,
  section_id,
  updateSectionStyle,
  previewImage,
  saveElementorData,
  btnLoading,
  handleBackgroundImageUpload
}) => {
  return (
    <>
      <EditIconStyleContainer
        iconDesign={iconDesign}
        setIconDesign={setIconDesign}
        handleApplyChangesElementor={handleApplyChangesElementor}
        section_id={section_id}
        updateSectionStyle={updateSectionStyle}
        previewImage={previewImage}
        saveElementorData={saveElementorData}
        btnLoading={btnLoading}
        handleBackgroundImageUpload={handleBackgroundImageUpload}
      />
    </>
  );
};

export default EditIconStyleSection;
