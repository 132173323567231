import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const handleDragOver = (
  e,
  sectionType,
  sectionIndex,
  itemIndex,
  currentSectionType,
  currentSectionIndex,
  setDropPosition,
  setDestinationId,
  setGridDestinationId,
) => {
  e.preventDefault();

  const provideSection = [
    'subFirstGrid',
    'subSecondGrid',
    'subThirdGrid',
    'subForthGrid',
    'subFifthGrid',
    'subSixGrid',
    'subSecondSectionFirstGrid',
    'subSecondSectionSecondGrid',
    'subSecondSectionThirdGrid',
    'subSecondSectionForthGrid',
    'subSecondSectionFifthGrid',
    'subSecondSectionSixGrid',
    'subThirdSectionFirstGrid',
    'subThirdSectionSecondGrid',
    'subThirdSectionThirdGrid',
    'subThirdSectionForthGrid',
    'subThirdSectionFifthGrid',
    'subThirdSectionSixGrid',
    'subForthSectionFirstGrid',
    'subForthSectionSecondGrid',
    'subForthSectionThirdGrid',
    'subForthSectionForthGrid',
    'subForthSectionFifthGrid',
    'subForthSectionSixGrid',
    'subFifthSectionFirstGrid',
    'subFifthSectionSecondGrid',
    'subFifthSectionThirdGrid',
    'subFifthSectionForthGrid',
    'subFifthSectionFifthGrid',
    'subFifthSectionSixGrid',
    'subSixSectionFirstGrid',
    'subSixSectionSecondGrid',
    'subSixSectionThirdGrid',
    'subSixSectionForthGrid',
    'subSixSectionFifthGrid',
    'subSixSectionSixGrid',
  ];

  if (sectionType === currentSectionType && sectionIndex === currentSectionIndex) {
    const rect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY;
    if (mouseY < rect.top + rect.height / 2) {
      setDropPosition('above');
      if (provideSection?.includes(sectionType)) {
        setGridDestinationId(itemIndex);
      } else {
        setDestinationId(itemIndex);
      }
    } else {
      setDropPosition('below');
      if (provideSection?.includes(sectionType)) {
        setGridDestinationId(itemIndex);
      } else {
        setDestinationId(itemIndex);
      }
    }
  }
};

export const RenderContextMenu = ({
  menuVisible,
  setMenuVisible,
  menuPosition,
  handleMenuItemClick,
  selectedSingleItem,
  setCopiedItem,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  sectionIndex,
  handleCopySingleItem,
  handleCutSingleItem,
  newItem,
  cutItem,
  copyItem,
  selectSection,
  openAddSection,
  isEdit,
  setCutItem,
}) => {
  useEffect(() => {
    if (newItem) {
      handleCopySingleItem(newItem);
    }
  }, [newItem]);

  useEffect(() => {
    if (cutItem) {
      handleCutSingleItem(cutItem);
    }
  }, [cutItem]);

  if (!menuVisible) return null;
  return ReactDOM.createPortal(
    <div
      className={`absolute bg-white shadow-md list-none  rounded-md`}
      style={{
        top: menuPosition.y,
        left: menuPosition.x,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        padding: '10px',
        borderRadius: '5px',
        zIndex: 1000,
      }}
      onMouseLeave={() => setMenuVisible(false)}
    >
      <div>
        <button
          onClick={(e) => {
            e.stopPropagation()
            setCutItem({ selectedSingleItem, type: 'cut' });
            handleMenuItemClick();
          }}
          // className={`block rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full ${
          //   cutItem ? 'opacity-50 cursor-not-allowed' : ''
          // }`}
          className={`block rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full`}
        >
          Cut
        </button>
        <button
          onClick={() => {
            setCopiedItem({ selectedSingleItem, type: 'copy', sectionIndex });
            handleMenuItemClick();
          }}
          className={`block rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full`}
        >
          Copy
        </button>
        <button
          onClick={() => {
            if (copyItem) {
              handleCopySingleItem(copyItem);
            } else if (cutItem) {
              handleCopySingleItem(cutItem);
            }
            handleMenuItemClick();
            // setCopiedItem(null);
            // setCutItem(null);
          }}
          className={`block rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full ${
            !cutItem && !copyItem ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          Paste
        </button>
        <button
          onClick={(e) => {
            selectSection(e, isEdit?.id, isEdit?.blockName, isEdit?.index);
            openAddSection(e, isEdit?.sectionType, isEdit?.type);
            handleMenuItemClick();
          }}
          className='block  rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full'
        >
          Edit
        </button>
        <button
          onClick={() => {
            handleRemoveSingleItem(selectedSingleItem);
            handleMenuItemClick();
          }}
          className='block  rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full'
        >
          Delete
        </button>
        <button
          onClick={() => {
            handleDuplicateSingleItem(selectedSingleItem);
            handleMenuItemClick();
          }}
          className='block  rounded px-4 py-2 text-left text-gray-700 hover:bg-gray-100 w-full'
        >
          Duplicate
        </button>
      </div>
    </div>,
    document.body,
  );
};

const ContextMenuItem = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedSingleItem, setSelectedSingleItem] = useState(null);
  const [copyItem, setCopiedItem] = useState(null);
  const [newItem, setNewItem] = useState(null);
  const [cutItem, setCutItem] = useState(null);
  const [isEdit, setEditItem] = useState(null);
  const handleRightClick = (e, obj, sectionArray, itemIndex) => {
    e.preventDefault();
    const menuX = e.clientX;
    const menuY = e.clientY;
    setMenuPosition({ x: menuX, y: menuY });
    setMenuVisible(true);
    setSelectedSingleItem({ obj, sectionArray, itemIndex });
  };

  const handleCopyItem = (e, obj, sectionArray, itemIndex, sectionIndex) => {
    e.stopPropagation();
    setSelectedSingleItem({ obj, sectionArray, itemIndex, sectionIndex });
  };

  const handleMenuItemClick = () => {
    setMenuVisible(false);
    // setSelectedSingleItem(null);
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey) {
      if (event.key === 'c') {
        if (selectedSingleItem) {
          setCopiedItem({ selectedSingleItem, type: 'copy' });
        }
      } else if (event.key === 'v') {
        if (copyItem) {
          setNewItem(copyItem);
          setTimeout(() => {
            setNewItem(null);
          }, 0);
        }
        if (cutItem) {
          setNewItem(cutItem);
          setTimeout(() => {
            setNewItem(null);
          }, 0);
        }
      } else if (event.key === 'x') {
        if (selectedSingleItem) {
          setCutItem({ selectedSingleItem, type: 'cut' });
          setCopiedItem({ selectedSingleItem, type: 'cut' });
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedSingleItem, copyItem, cutItem]);

  return {
    menuVisible,
    menuPosition,
    setMenuVisible,
    setMenuPosition,
    selectedSingleItem,
    setSelectedSingleItem,
    handleRightClick,
    handleMenuItemClick,
    newItem,
    cutItem,
    setNewItem,
    setCopiedItem,
    handleCopyItem,
    copyItem,
    isEdit,
    setEditItem,
    setCutItem,
  };
};

export default ContextMenuItem;
