import { Checkbox, Dialog, FormControlLabel, Pagination, Popover, Tooltip } from '@mui/material';
import { ArrowLeft, Eye, Filter, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { StarFill } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { DataLoad } from '../../App';
import { NOPHOTO_IMAGE, NOUSER_IMAGE } from '../../Assets/StaticData/StaticImage';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import { formatDate } from '../../controllers/FormatAmount';
import MuiSwitch from '../../controllers/MuiSwitch';
import { getInitialPage, replacePageInUrl } from '../../controllers/PageHandleFromQuery';
import { Transition } from '../../controllers/Transition';
import NoDataFound from '../Main/NoDataFound';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
const PerticularReviews = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [nodata, setNodata] = useState(false);
  const [clearRow, setClearRow] = useState(false);
  const [Page, setPage] = useState(getInitialPage());
  const initialLimit = localStorage.getItem('limit');
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const [limit, setLimit] = useState(initialLimit);
  const [popUp, setPopUp] = useState(false);
  const [ProdutReviews, setProductReviews] = useState();
  const [allReviewname, setAllReviewname] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [filter, setFilter] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedRowsId, setSelectedRowsId] = useState([]);
  var startRecord = (ProdutReviews?.data?.currentPage - 1) * limit + 1;
  var endRecord = Math.min(
    ProdutReviews?.data?.currentPage * limit,
    ProdutReviews?.data?.totalRecords,
  );
  useEffect(() => {
    replacePageInUrl(Page);
  }, [Page]);
  const GetProductReviews = async () => {
    setDataLoading(true);
    try {
      let ApiCall = await API.get(
        `/admin/review/getProductReviews/${params.reviews_id}?page=${Page}&limit=${limit}&review_type=${allReviewname}`,
      );
      const result = decryptData(ApiCall?.data)
      setProductReviews(result);
      if (Number(startRecord) > Number(result?.data?.totalRecords)) {
        setPage(Page - 1);
      }
    } catch (error) {
      setNodata(true);
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    } finally {
      setDataLoading(false);
    }
  };
  const [popdata, setPopData] = useState();

  //Disable Review
  const DisableReview = async (id, value) => {
    try {
      const payload = encryptData({
        is_visible: value,
      })
      await API.put(`/admin/review/UpdateReview/${id}`, payload);
      GetProductReviews();
      setIsNotification(true);
      setNotificationMsg(value ? 'Review is set to active' : 'Review is set to hidden');
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // Table And Data
  const [productData, setProductData] = useState({
    name: '',
    image: '',
  });
  let tableData = ProdutReviews?.data?.records;
  useEffect(() => {
    setProductData({
      name: tableData && tableData[0]?.product?.product_name,
      image: tableData && tableData[0]?.product?.image,
    });
  }, [tableData]);

  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(ProdutReviews?.data?.records?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  // Update Multiple Review
  const UpdateMultipleReview = async (value) => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
        is_visible: value,
      })
      await API.put('/admin/review/UpdateManyReview', payload);
      GetProductReviews();
      setSelectedRowsId([]);
      setClearRow(!clearRow);
      setIsNotification(true);
      setNotificationMsg(`Review set to ${value ? 'active' : 'hidden'}`);
      setSeverity('success');
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  useEffect(() => {
    GetProductReviews();
  }, [allReviewname]);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  return nodata ? (
    <NoDataFound />
  ) : (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[64px]'>
        <div className='flex items-end justify-between w-full pb-[30px]'>
          <div className='flex items-center space-x-2'>
            <button
              onClick={(e) => {
                e.preventDefault();
                navigate('/reviews');
              }}
              className='back-arrow'
            >
              <ArrowLeft />
            </button>
            <div>
              <Breadcrumbs
                crumbs={[
                  { name: t('lang_dashboard'), path: `/dashboard` },
                  { name: t('lang_Reviews'), path: '/reviews' },
                  { name: t('lang_Perticular_Product_Review'), path: '/reviews/perticuler-review' },
                ]}
              />
              <h4 className='text-xl md:text-2xl font-semibold text-primary'>
                {t('lang_Reviews')}{' '}
              </h4>
            </div>
          </div>
          <button className='filter-btn' onClick={(e) => { setFilter(!filter); setAnchorEl(e.currentTarget) }}>
            <Tooltip title='Filter' arrow>
              <button className=''>
                <Filter className='h-5 w-5' />
              </button>
            </Tooltip>
          </button>
        </div>
        <div
          className={'flex w-full h-full overflow-hidden relative'}
        >
          <div className='w-full'>
            <div className='w-full  transition-all duration-100 inline-block mb-4 border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
              <div className='flex border-b main-border-color p-2 items-center'>
                <div className='h-10 w-10 rounded-full overflow-hidden'>
                  <img
                    className='max-w-full max-h-full object-contain object-top'
                    onError={(e) => {
                      e.target.src = NOPHOTO_IMAGE;
                    }}
                    src={productData?.image || NOPHOTO_IMAGE}
                    alt={productData?.name}
                  />
                </div>
                <h6 className='text-base md:text-lg ms-2 font-medium'>{productData?.name}</h6>
                <div className='flex items-center'>
                  {selectedRowsId && selectedRowsId?.length > 0 && (
                    <>
                      <span className='text-sm px-3'>{selectedRowsId?.length} Selected</span>|
                      <button
                        className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                        onClick={() => UpdateMultipleReview(false)}
                      >
                        <span>{t('lang_Hide_Review')}</span>
                      </button>
                      <button
                        className='bulk-btn space-x-2 inline-flex items-center'
                        onClick={() => UpdateMultipleReview(true)}
                      >
                        <span>{t('lang_Show_Review')}</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
              {dataLoading && !ProdutReviews ? (
                <TableSkeleton forTable='order' />
              ) : ProdutReviews?.data?.records?.length > 0 ? (
                <table className='w-full'>
                  <thead>
                    <tr>
                      <td className='px-3 table-head-checkbox'>
                        <Checkbox
                          checked={selectedRowsId?.length === ProdutReviews?.data?.records?.length}
                          indeterminate={
                            selectedRowsId?.length !== ProdutReviews?.data?.records?.length &&
                            selectedRowsId?.length > 0
                          }
                          onChange={() => {
                            handleSelectRow('all');
                          }}
                        />
                      </td>
                      <td className='min-w-[200px] max-w-[200px] px-3 capitalize'>
                        {t('lang_user')}
                      </td>
                      <td className='min-w-[200px] max-w-[200px] px-3'>{t('lang_email')}</td>
                      <td className='min-w-[100px] max-w-[100px] px-3'>{t('lang_Rating')}</td>
                      <td className='w-full px-3 capitalize'>{t('lang_comment')}</td>
                      <td className='min-w-[150px] max-w-[150px] px-3 text-right capitalize'>
                        {t('lang_date')}
                      </td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {ProdutReviews?.data?.records?.map((elem, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                            }`}
                        >
                          <td className='px-3 table-body-checkbox'>
                            <Checkbox
                              disabled={elem?.default}
                              checked={
                                selectedRowsId?.length > 0 &&
                                selectedRowsId?.find((x) => x === elem?._id)
                              }
                              onChange={() => handleSelectRow(elem?._id)}
                            />
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3'>
                            <div
                              className={`flex items-center ${elem?.verify_status && 'cursor-pointer'
                                } space-x-2 text-sm`}
                              onClick={() =>
                                elem?.verify_status ? navigate(`/customers/${elem?.user?._id}`) : ''
                              }
                            >
                              <span>{elem?.name}</span>
                            </div>
                          </td>
                          <td className='min-w-[200px] max-w-[200px] px-3'>
                            <div className='flex items-center space-x-2 text-sm'>
                              <span>{elem?.user?.email || '-'}</span>
                            </div>
                          </td>
                          <td className='min-w-[100px] max-w-[100px] px-3'>
                            {elem?.rating >= 5 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#00b67a] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.rating}</span>
                              </div>
                            ) : elem?.rating >= 4 && elem?.rating < 5 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#73cf11] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.rating}</span>
                              </div>
                            ) : elem?.rating >= 3 && elem?.rating < 4 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ffce00] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.rating}</span>
                              </div>
                            ) : elem?.rating >= 2 && elem?.rating < 3 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ff8622] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.rating}</span>
                              </div>
                            ) : elem?.rating < 2 ? (
                              <div className='inline-flex items-center space-x-2 bg-[#ff3722] text-white px-3 py-0.5 rounded-[6px]'>
                                <StarFill />
                                <span className='font-medium text-sm'>{elem?.rating}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className='w-full px-3'>
                            <div
                              className='block cursor-pointer'
                              onClick={(e) => {
                                e.preventDefault();
                                setPopUp(true);
                                setPopData(elem);
                              }}
                            >
                              <p className='line-clamp-2 text-left'>
                                {elem?.description
                                  ? elem?.description.replace(/<[^>]*>/g, ' ')
                                  : elem?.review
                                    ? elem?.review
                                    : '-'}
                              </p>
                            </div>
                          </td>
                          <td className='min-w-[150px] max-w-[150px] px-3 text-right'>
                            <p className='text-right w-full'>
                              {elem?.review_date ? formatDate(elem?.review_date) : '-'}
                            </p>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <div className='flex items-center space-x-2 justify-end w-full'>
                              <div>
                                <label className='relative flex justify-end w-full'>
                                  <FormControlLabel
                                    checked={elem?.is_visible}
                                    control={<MuiSwitch />}
                                    onChange={() => {
                                      DisableReview(elem._id, !elem?.is_visible);
                                    }}
                                  />
                                </label>
                              </div>
                              <div>
                                <Tooltip title='Show' arrow>
                                  <button className=''>
                                    <Eye
                                      className='-800 w-[18px] h-[18px]'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setPopUp(true);
                                        setPopData(elem);
                                      }}
                                    />
                                  </button>
                                </Tooltip>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <NoRecords />
              )}
              {/*================paginator================ */}
            </div>
            {ProdutReviews?.data ? (
              <div className='secondary-bg-color border main-border-color flex flex-1 p-5 items-center justify-between pagination overflow-hidden rounded-[6px]'>
                <span className='text-sm font-medium'>
                  {limit === 'all'
                    ? `${ProdutReviews?.data?.totalRecords}` + ' ' + 'Records'
                    : `${startRecord} - ${endRecord} of ${ProdutReviews?.data?.totalRecords} Records`}
                </span>
                <div>
                  {ProdutReviews?.data?.totalPages !== 1 ? (
                    <Pagination
                      count={ProdutReviews?.data?.totalPages}
                      page={page}
                      onChange={(e, v) => setPage(v)}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <Popover
            open={filter}
            anchorEl={anchorEl}
            onClose={() => { setFilter(false); setAnchorEl(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: { marginRight: '35px',marginTop:'10px' }, // Adjust padding and margin
            }}
            className='rounded-[6px]'
          >
            <div className='p-[20px] filter'>
              <div>
                <p className='flex  text-primary font-semibold uppercase text-sm m-0'>
                  <Filter className='me-1 w-4 h-4' />
                  {t('lang_filter')}
                </p>
                <hr className='main-border-color my-3'></hr>
                <div className='grid gap-y-5'>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_Reviews_Type')}
                    </span>
                    <div className='relative'>
                      <select
                        className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                        name='limit'
                        value={allReviewname}
                        onChange={(e) => {
                          setPage(1);
                          setAllReviewname(e.target.value);
                        }}
                      >
                        <option value='' className='capitalize'>
                          {t('lang_all')}
                        </option>
                        <option value='1'>{t('lang_Registered_Users')}</option>
                        <option value='2'>{t('lang_Guest_Users')}</option>
                      </select>
                      <div className='select-arrow'></div>
                    </div>
                  </div>
                  <div>
                    <span className='text-sm table-text font-medium mb-1 inline-block'>
                      {t('lang_set_items_limit')}
                    </span>
                    <div>
                      <div className='relative'>
                        <select
                          className='outline-none rounded-[6px] w-full table-text font-medium text-sm border main-border-color p-1.5'
                          name='limit'
                          value={limit}
                          onChange={(e) => {
                            setPage(1);
                            e.target.value === 'all' ? setLimit('all') : setLimit(e.target.value);
                          }}
                        >
                          {ProdutReviews?.data?.totalRecords > 10 ? (
                            <>
                              <option value='10'>10</option>
                            </>
                          ) : (
                            <></>
                          )}
                          {ProdutReviews?.data?.totalRecords > 20 ? (
                            <option value='20'>20</option>
                          ) : (
                            <></>
                          )}
                          {ProdutReviews?.data?.totalRecords > 50 ? (
                            <option value='50'>50</option>
                          ) : (
                            <></>
                          )}
                          <option value='all'>{t('lang_all')}</option>
                        </select>
                        <div className='select-arrow'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <Dialog
        open={popUp}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setPopUp(false)}
        aria-describedby='alert-dialog-slide-description'
      >
        <div className='flex min-h-full min-w-[400px] items-end justify-center  text-center sm:items-center sm:p-0'>
          <div className='overflow-hidden w-full p-5'>
            <div className='flex items-center justify-between mb-2'>
              <div className='flex items-center'>
                <div className='h-10 w-10 rounded-full overflow-hidden'>
                  <img
                    className='h-full w-full object-cover object-top rounded-full'
                    onError={(e) => {
                      e.target.src = NOUSER_IMAGE;
                    }}
                    src={popdata?.image || NOUSER_IMAGE}
                    alt={popdata?.name}
                  />
                </div>
                <p className='m-2 font-medium'>{popdata?.name}</p>
              </div>
              <X
                className='text-link w-4 h-4 cursor-pointer  transition-all duration-300'
                onClick={(e) => {
                  e.preventDefault();
                  setPopUp(false);
                }}
              />
            </div>
            <div className='flex items-center justify-between mt-4'>
              <div>
                {popdata?.rating === 5 ? (
                  <>
                    <div className='flex items-center space-x-2 bg-[#00b67a] text-white px-3 py-0.5 rounded-[6px]'>
                      <StarFill />
                      <p className='font-medium text-sm'>{popdata?.rating}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {popdata?.rating >= 4 && popdata?.rating < 5 ? (
                  <>
                    <div className='flex items-center space-x-2 bg-[#73cf11] text-white px-3 py-0.5 rounded-[6px]'>
                      <StarFill />
                      <p className='font-medium text-sm'>{popdata?.rating}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {popdata?.rating >= 3 && popdata?.rating < 4 ? (
                  <>
                    <div className='flex items-center space-x-2 bg-[#ffce00] text-white px-3 py-0.5 rounded-[6px]'>
                      <StarFill />
                      <p className='font-medium text-sm'>{popdata?.rating}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {popdata?.rating >= 2 && popdata?.rating < 3 ? (
                  <>
                    <div className='flex items-center space-x-2 bg-[#ff8622] text-white px-3 py-0.5 rounded-[6px]'>
                      <StarFill />
                      <p className='font-medium text-sm'>{popdata?.rating}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {popdata?.rating < 2 ? (
                  <>
                    <div className='flex items-center space-x-2 bg-[#ff3722] text-white px-3 py-0.5 rounded-[6px]'>
                      <StarFill />
                      <p className='font-medium text-sm'>{popdata?.rating}</p>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <p className='ml-3'>{popdata?.review_date.split('T')[0]}</p>
            </div>
            <p className='font-medium text-left block mt-4'>
              {' '}
              {popdata?.review ? (
                popdata?.review
              ) : (
                <p dangerouslySetInnerHTML={{ __html: popdata?.description }}></p>
              )}
            </p>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default PerticularReviews;
