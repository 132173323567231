import React from 'react';
import AddSectionSixGrid from './AddSectionSixGrid';
import ReactDragListView from 'react-drag-listview';
import { handleDragOver, RenderContextMenu } from '../../../../controllers/ContextMenuItem';
const { DragColumn } = ReactDragListView;
import HeadingSection from '../../../../Component/InputControllSection/HeadingSection';
import ImageSection from '../../../../Component/InputControllSection/ImageSection';
import VideoContentSection from '../../../../Component/InputControllSection/VideoContentSection';
import ButtonSection from '../../../../Component/InputControllSection/ButtonSection';
import DividerSection from '../../../../Component/InputControllSection/DividerSection';
import SpacerSection from '../../../../Component/InputControllSection/SpacerSection';
import MapSection from '../../../../Component/InputControllSection/MapSection';
import IconSection from '../../../../Component/InputControllSection/IconSection';
import ImageBoxSection from '../../../../Component/InputControllSection/ImageBoxSection';
import GeneralIconBox from '../../../../Component/InputControllSection/GeneralIconBox';
import GeneralIconList from '../../../../Component/InputControllSection/GeneralIconList';
import CounterSection from '../../../../Component/InputControllSection/CounterSection';
import TestimonialSection from '../../../../Component/InputControllSection/TestimonialSection';
import SocialIconSection from '../../../../Component/InputControllSection/SocialIconSection';
import AlertSection from '../../../../Component/InputControllSection/AlertSection';
import HtmlSection from '../../../../Component/InputControllSection/HtmlSection';
import RatingSection from '../../../../Component/InputControllSection/RatingSection';
import TextEditorSection from '../../../InputControllSection/TextEditorSection';
import TabsComponent from '../../../InputControllSection/TabsComponent';
import AccordionSection from '../../../InputControllSection/AccordionSection';

const SecondSectionAdd = ({
  flexBoxValue,
  removeSection,
  handleFlexBoxClick,
  openAddSection,
  activeIndex,
  handleSectionClick,
  sectionAdd,
  editSection,
  sectionAddSecondGrid,
  sectionIndex,
  section,
  gridValue,
  activeHeadingIndex,
  handleHeadingClick,
  activeHeading,
  selectedIndex,
  setSectionAdd,
  handleSubDivClick,
  selectSection,
  setEditSection,
  dragProps,
  dropContainerRef,
  destinationId,
  setDestinationId,
  gridDestinationId,
  setGridDestinationId,
  setElementorId,
  dropPosition,
  setDropPosition,
  handleRemoveSingleItem,
  handleDuplicateSingleItem,
  handleCopySingleItem,
  handleCutSingleItem,
  getPrevSecId,
  menuVisible,
  setMenuVisible,
  menuPosition,
  setMenuPosition,
  selectedSingleItem,
  handleRightClick,
  handleMenuItemClick,
  newItem,
  cutItem,
  setCutItem,
  setCopiedItem,
  handleCopyItem,
  copyItem,
  isEdit,
  setEditItem,
  getContextMenuItem,
}) => {
  const filteredSections = sectionAdd?.secondGridRow?.[sectionIndex]?.filter(
    (obj) => obj.type === 'flex_rows',
  );
  const getAlertClass = (type) => {
    switch (type) {
      case 'success':
        return 'bg-green-100 border-green-400 text-green-800';
      case 'warning':
        return 'bg-yellow-100 border-yellow-400 text-yellow-800';
      case 'danger':
        return 'bg-red-100 border-red-400 text-red-800';
      case 'info':
      default:
        return 'bg-blue-100 border-blue-400 text-black-800';
    }
  };

  return (
    <>
      <div
        className={`relative m-5 p-1 flex flex-col justify-center ${
          filteredSections?.length > 0 ? '' : 'items-center'
        } border-[1px] border-dashed border-[#d5d8dc] group`}
        onClick={(e) => {
          handleSectionClick(e, sectionIndex, section?.type || section?.section_type);
          openAddSection(e, 'secondGridRow', 'add_section');
          selectSection(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
          );
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            'flex_rows',
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
          getPrevSecId(e, sectionIndex);
        }}
        ref={dropContainerRef}
        onContextMenu={(e) => {
          const menuX = e.clientX;
          const menuY = e.clientY;
          setMenuPosition({ x: menuX, y: menuY });
          setMenuVisible(true);
          selectSection(e, section?.id || section?.section_id, 'flex_rows', sectionIndex);
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            'flex_rows',
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
        }}
        onDragEnter={(e) => {
          e.preventDefault(),
            handleSectionClick(e, sectionIndex, section?.type || section?.section_type),
            openAddSection(e, 'secondGridRow', 'add_section'),
            selectSection(e, section?.id, section?.type || section?.section_type, sectionIndex);
          getContextMenuItem(
            e,
            section?.id || section?.section_id,
            section?.type || section?.section_type,
            sectionIndex,
            section?.section_type,
          );
          setElementorId(section?.id || section?.section_id);
        }}
        onDragOver={(e) => e.preventDefault()}
      >
        {filteredSections?.length > 0 ? (
          <DragColumn {...dragProps}>
            <div className='w-full flex overflow-auto'>
              {filteredSections?.map((obj, itemIndex) => {
                const isActive =
                  editSection?.section_type === obj?.blockName &&
                  editSection?.section_id === obj?.id &&
                  editSection?.section_index === itemIndex;

                const currentActiveSection =
                  destinationId === itemIndex &&
                  editSection?.section_type === 'flex_rows' &&
                  editSection?.section_index === sectionIndex;

                const abovePosition = dropPosition === 'above' && itemIndex === destinationId;
                const belowPosition = dropPosition === 'below' && itemIndex === destinationId;

                const borderStyle = {
                  borderTop: abovePosition && currentActiveSection ? '2px dashed red' : '',
                  borderBottom: belowPosition && currentActiveSection ? '2px dashed green' : '',
                };

                if (obj?.blockName === 'grid') {
                  return (
                    <div
                      key={itemIndex}
                      className={`cursor-pointer border-[2px] transition-all duration-300 ${
                        isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                      }  hover:border-2 hover:border-blue-500`}
                      onClick={(e) => selectSection(e, obj?.id, obj?.blockName, itemIndex)}
                    >
                      <AddSectionSixGrid
                        flexBoxValue={flexBoxValue}
                        setSectionAdd={setSectionAdd}
                        removeSection={removeSection}
                        gridValue={gridValue}
                        handleFlexBoxClick={handleFlexBoxClick}
                        openAddSection={openAddSection}
                        activeIndex={activeIndex}
                        handleSectionClick={handleSectionClick}
                        sectionAdd={sectionAdd}
                        editSection={editSection}
                        sectionIndex={sectionIndex}
                        section={section}
                        handleHeadingClick={handleHeadingClick}
                        activeHeadingIndex={activeHeadingIndex}
                        activeHeading={activeHeading}
                        handleSubDivClick={handleSubDivClick}
                        selectedIndex={selectedIndex}
                        selectSection={selectSection}
                        dragProps={dragProps}
                        gridDestinationId={gridDestinationId}
                        setGridDestinationId={setGridDestinationId}
                        dropContainerRef={dropContainerRef}
                        setElementorId={setElementorId}
                        setDropPosition={setDropPosition}
                        setDestinationId={setDestinationId}
                        dropPosition={dropPosition}
                        handleRemoveSingleItem={handleRemoveSingleItem}
                        handleDuplicateSingleItem={handleDuplicateSingleItem}
                        handleCopySingleItem={handleCopySingleItem}
                        handleCutSingleItem={handleCutSingleItem}
                        getPrevSecId={getPrevSecId}
                        menuVisible={menuVisible}
                        setMenuVisible={setMenuVisible}
                        menuPosition={menuPosition}
                        setMenuPosition={setMenuPosition}
                        selectedSingleItem={selectedSingleItem}
                        handleRightClick={handleRightClick}
                        handleMenuItemClick={handleMenuItemClick}
                        newItem={newItem}
                        cutItem={cutItem}
                        setCutItem={setCutItem}
                        setCopiedItem={setCopiedItem}
                        handleCopyItem={handleCopyItem}
                        copyItem={copyItem}
                        isEdit={isEdit}
                        setEditItem={setEditItem}
                        getContextMenuItem={getContextMenuItem}
                      />
                    </div>
                  );
                }
                return (
                  <div
                    className={`draggable-item cursor-pointer hover:border-2 hover:border-blue-500 ${
                      isActive ? 'border-2 border-blue-500' : 'border-2 border-transparent'
                    }`}
                    style={borderStyle}
                    onClick={(e) => {
                      selectSection(e, obj?.id, obj?.blockName, itemIndex);
                      openAddSection(e, 'secondGridRow', 'setting');
                      handleCopyItem(e, obj, 'secondGridRow', itemIndex, sectionIndex);
                      getContextMenuItem(
                        e,
                        section?.id || section?.section_id,
                        'flex_rows',
                        sectionIndex,
                        section?.section_type,
                      );
                      setElementorId(section?.id || section?.section_id);
                    }}
                    onDragOver={(e) => {
                      handleDragOver(
                        e,
                        'flex_rows',
                        sectionIndex,
                        itemIndex,
                        editSection?.section_type,
                        editSection?.section_index,
                        setDropPosition,
                        setDestinationId,
                        setGridDestinationId,
                      );
                    }}
                    onDragLeave={(e) => {
                      e.preventDefault();
                      setDestinationId(null);
                      setDropPosition(null);
                    }}
                    onContextMenu={(e) => {
                      handleRightClick(e, obj, 'flex_rows', itemIndex, sectionIndex),
                        // selectSection(e, obj?.id, obj?.blockName, itemIndex);
                        getContextMenuItem(
                          e,
                          section?.id || section?.section_id,
                          'flex_rows',
                          sectionIndex,
                        );
                      setEditItem({
                        id: obj?.id,
                        blockName: obj?.blockName,
                        index: itemIndex,
                        sectionType: 'secondGridRow',
                        type: 'setting',
                      });
                    }}
                  >
                    {obj?.blockName === 'heading' ? (
                      <>
                        <HeadingSection obj={obj} />
                      </>
                    ) : obj?.blockName === 'image' ? (
                      <div>
                        <ImageSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'textEditor' ? (
                      <div>
                        <TextEditorSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'video' ? (
                      <div>
                        <VideoContentSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'button' ? (
                      <div>
                        <ButtonSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'divider' ? (
                      <div>
                        <div
                          className='flex items-center'
                          style={{
                            width: `${obj?.content?.width}%`,
                          }}
                        >
                          <hr
                            className={`border-l-2 border-black mx-auto border-${obj?.content?.style}`}
                            style={{
                              height: `${obj?.content?.width}px`,
                            }}
                          />
                          {obj?.content?.element === 'text' ? (
                            <span className='mx-2'>{obj?.content?.text}</span>
                          ) : obj?.content?.element === 'upload' ? (
                            ''
                          ) : obj?.content?.element === 'none' ? (
                            ''
                          ) : (
                            ''
                          )}
                          <hr
                            className={`border-l-2 border-black mx-auto border-${obj?.content?.style}`}
                            style={{
                              height: `${obj?.content?.width}px`,
                            }}
                          />
                        </div>
                      </div>
                    ) : obj?.blockName === 'spacer' ? (
                      <>
                        <SpacerSection obj={obj} />
                      </>
                    ) : obj?.blockName === 'googleMap' ? (
                      <div>
                        <MapSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'icon' ? (
                      <div>
                        <IconSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'imageBox' ? (
                      <div>
                        <ImageBoxSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'generalIconBox' ? (
                      <div>
                        <GeneralIconBox obj={obj} />
                      </div>
                    ) : obj?.blockName === 'generalIconList' ? (
                      <div>
                        <GeneralIconList obj={obj} />
                      </div>
                    ) : obj?.blockName === 'counter' ? (
                      <div>
                        <CounterSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'testimonial' ? (
                      <div>
                        <TestimonialSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'tabs' ? (
                      <TabsComponent obj={obj} />
                    ) : obj?.blockName === 'accordion' ? (
                      <AccordionSection section={obj?.content} obj={obj} />
                    ) : obj?.blockName === 'socialIcon' ? (
                      <div className='flex justify-center space-x-4'>
                        <SocialIconSection obj={obj} />
                      </div>
                    ) : obj?.blockName === 'alert' ? (
                      <>
                        <AlertSection obj={obj} />
                      </>
                    ) : obj?.blockName === 'html' ? (
                      <>
                        <HtmlSection obj={obj} />
                      </>
                    ) : obj?.blockName === 'rating' ? (
                      <RatingSection obj={obj} />
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
          </DragColumn>
        ) : (
          <div className='cursor-pointer'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='28'
              height='28'
              fill='#9da5ae'
              className='bi bi-plus'
              viewBox='0 0 16 16'
            >
              <path d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4' />
            </svg>
          </div>
        )}
        <RenderContextMenu
          menuVisible={menuVisible}
          menuPosition={menuPosition}
          handleMenuItemClick={handleMenuItemClick}
          selectedSingleItem={selectedSingleItem}
          setMenuVisible={setMenuVisible}
          setCopiedItem={setCopiedItem}
          handleRemoveSingleItem={handleRemoveSingleItem}
          handleDuplicateSingleItem={handleDuplicateSingleItem}
          sectionIndex={sectionIndex}
          handleCopySingleItem={handleCopySingleItem}
          handleCutSingleItem={handleCutSingleItem}
          newItem={newItem}
          cutItem={cutItem}
          setCutItem={setCutItem}
          copyItem={copyItem}
          selectSection={selectSection}
          openAddSection={openAddSection}
          isEdit={isEdit}
        />
        <div className='absolute -top-14 left-[50%] -translate-x-[50%] py-1 bg-[#3b82f6] text-white rounded-t-md opacity-0 group-hover:opacity-100 transition-opacity px-4'>
          <button onClick={(e) => handleFlexBoxClick(e, 'flex_rows')} className='mr-2'>
            +
          </button>
          <button onClick={() => removeSection(sectionIndex, 'flex_rows')} className='ml-2'>
            ×
          </button>
        </div>
      </div>
    </>
  );
};

export default SecondSectionAdd;
