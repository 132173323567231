import { Checkbox, Dialog, FormControlLabel } from '@mui/material';
import { ArrowLeft, Trash, Trash2, X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext, DataLoad } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MuiSwitch from '../../controllers/MuiSwitch';
import { Transition } from '../../controllers/Transition';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import TableSkeleton from '../Skeleton/TableSkeleton';
import { NOPHOTO_IMAGE } from '../../Assets/StaticData/StaticImage';
import ReactDragListView from 'react-drag-listview';

const EditBlogGroup = () => {
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { dataLoading, setDataLoading } = useContext(DataLoad);
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [isChange, setIsChange] = useState(false);
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const params = useParams();
  const navigate = useNavigate();
  const [storeBlog, setStoreBlog] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState([]);
  const [blogGroupTitle, setBlogGroupTitle] = useState('');
  const [blogData, setBlogData] = useState({
    blog_limit: 3,
    blog_display_grid: true,
    carousel_status: true,
    status: true,
    blog_image_width: '',
    blog_image_height: '',
    carousel_speed_ms: '',
    button_text: '',
    button_link: '',
  });
  const [editBlogId, setEditBlogId] = useState(null);

  const handleGetPerticularBlogGroup = async () => {
    setDataLoading(true);
    try {
      const apiCall = await API.get(`/admin/dynamicBlog/getBlogGroupById/${params?.groupId}`);
      const result = decryptData(apiCall?.data);
      setBlogData({
        button_text: result?.data[0]?.button_text,
        button_link: result?.data[0]?.button_link,
        blog_limit: result?.data[0]?.blog_limit,
        blog_display_grid: result?.data[0]?.blog_display_grid,
        carousel_status: result?.data[0]?.carousel_status,
        status: result?.data[0]?.status,
        blog_image_width: result?.data[0]?.blog_image_width,
        blog_image_height: result?.data[0]?.blog_image_height,
        carousel_speed_ms: result?.data[0]?.carousel_speed_ms,
      });
      setBlogGroupTitle(result?.data[0]?.blog_section_title);
      setStoreBlog(result?.data[0]?.blogs);
    } catch (error) {
      console.log(error);
    } finally {
      setDataLoading(false);
    }
  };
  useEffect(() => {
    handleGetPerticularBlogGroup();
  }, []);
  const [addBlog, setAddBlog] = useState(false);
  const [dataErr, setDataErr] = useState(false);
  const handleAddBlog = async () => {
    try {
      const payload = encryptData({
        blog_group_id: params?.groupId,
        blogs: selectedBlog,
      });
      await API.post('/admin/dynamicblog/addBlog', payload);
      setSelectedBlog([]);
      setSearch('');
      setAddBlog(false);
      handleGetPerticularBlogGroup();
    } catch (error) {
      console.log(error);
    }
  };
  const [selectedBlog, setSelectedBlog] = useState([]);
  const handleSelectBlog = (id) => {
    if (id) {
      const existBlog = selectedBlog?.find((x) => x?.blog === id);
      if (!existBlog) {
        setSelectedBlog([...selectedBlog, { blog: id }]);
      } else {
        const removeSelected = selectedBlog?.filter((x) => x?.blog !== id);
        setSelectedBlog(removeSelected);
      }
    }
  };
  const [search, setSearch] = useState('');
  const [allBlogs, setAllBlogs] = useState([]);
  const GetBlogs = async () => {
    try {
      const apiCall = await API.get(
        `/admin/blog/GetBlogTable?searchkey=${search}&dynamicBlogGroup=${params?.groupId}&is_draft=false`,
      );
      if (apiCall.status === 200 || apiCall.status === 304) {
        const result = decryptData(apiCall?.data);
        setAllBlogs(result?.data?.records);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    if (addBlog === true && (search?.length === 0 || search?.length >= 3)) {
      GetBlogs();
    }
    if (!addBlog) {
      setSearch('');
      setSelectedBlog([]);
    }
  }, [addBlog, search]);
  const [btnLoading, setBtnLoading] = useState(false);
  const handleAddBlogGroup = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      await API.put(`/admin/dynamicBlog/updateBlogGroup/${params?.groupId}`, {
        blog_section_title: blogGroupTitle,
        button_text: blogData?.button_text,
        button_link: blogData?.button_link,
        blog_limit: blogData?.blog_limit,
        blog_display_grid: blogData?.blog_display_grid,
        carousel_status: blogData?.carousel_status,
        status: blogData?.status,
        blog_image_width: blogData?.blog_image_width,
        blog_image_height: blogData?.blog_image_height,
        carousel_speed_ms: blogData?.carousel_speed_ms,
      });
      navigate('/module-setting/blog-group');
      setSaveChanges({
        isUnsaveChanges: false,
        showChangesPopup: false,
        backLink: '/module-setting/blog-group',
        title: 'edit blog group',
      });
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const handleDelete = async () => {
    try {
      const payload = encryptData({
        id: selectedRowsId,
      });
      await API.post(`/admin/dynamicBlog/deleteBlog`, payload);
      setIsNotification(true);
      setNotificationMsg('Removed SuccesFully!');
      setSeverity('success');
      handleGetPerticularBlogGroup();
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setConfirmationPopup(false);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const sortBlog = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : Number(index),
      });
      await API.put(`/admin/dynamicBlog/updateBlog/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(storeBlog);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setStoreBlog(items);
  //   sortBlog(result?.destination?.index, result?.draggableId);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...storeBlog];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setStoreBlog(newItems);
      sortBlog(toIndex, editBlogId);
      setEditBlogId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  useEffect(() => {
    if (isChange) {
      setSaveChanges({
        backLink: '/module-setting/blog-group',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'edit blog group',
      });
    }
  }, [isChange]);
  const checkChanges = () => {
    if (isChange) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/module-setting/blog-group',
        title: 'edit blog group',
      });
    } else {
      navigate('/module-setting/blog-group');
    }
  };
  const handleSelectRow = (id) => {
    if (id === 'all') {
      if (selectedRowsId?.length > 0) {
        setSelectedRowsId([]);
      } else {
        setSelectedRowsId(storeBlog?.map((x) => x?._id));
      }
    } else {
      const existId = selectedRowsId?.length > 0 && selectedRowsId?.find((x) => x === id);
      if (!existId) {
        setSelectedRowsId([...selectedRowsId, id]);
      } else {
        const removeSelected = selectedRowsId?.filter((x) => x !== id);
        setSelectedRowsId(removeSelected);
      }
    }
  };
  return (
    <>
      <ConfirmationPopup
        heading='Delete Blog'
        subheading='Do you really want to delete this blog from this blog group?'
        confirmationPopup={confirmationPopup}
        handleCloseConfirm={handleCloseConfirm}
        runFunction={handleDelete}
      />
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('lang_Module_Setting'), path: '/module-setting' },
                { name: t('lang_Blog_Group'), path: '/module-setting/blog-group' },
                { name: t('lang_Edit_Blog_Group'), path: '/blog-categories/edit-blog-group' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Edit_Blog_Group')}
            </h4>
          </div>
        </div>
        <form
          onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
          className='w-full lg:w-[70%] xl:w-[60%] mx-auto'
        >
          <div className='secondary-bg-color w-full border main-border-color  space-y-4 rounded-[6px] p-5 sm:p-[25px]'>
            <div className='w-full mb-4'>
              <label htmlFor='group title'>
                {t('lang_Blog_Group_Title')}
                <span className='text-red-500 ms-1'>*</span>
              </label>
              <input
                className='w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none'
                type='text'
                id='group title'
                name='group title'
                value={blogGroupTitle}
                onChange={(e) => {
                  setBlogGroupTitle(e.target.value);
                  setIsChange(true);
                }}
                placeholder='Enter title....'
              />
              {dataErr && blogGroupTitle?.length === 0 && (
                <p className='text-xs text-red-500'>{t('lang_Please_Enter_Group_Title')}</p>
              )}
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 w-full'>
              <div className='mb-4 flex items-center'>
                <label>{t('lang_Blog_Group_Status')}</label>
                <label className='relative flex justify-end w-full'>
                  <FormControlLabel
                    checked={blogData?.status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, status: !blogData?.status });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Blog_Limit')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_limit}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_limit: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='w-full'>
                <label>{t('lang_Blog_Display')}</label>
                <div className='relative'>
                  <select
                    className='w-full'
                    value={blogData?.blog_display_grid}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_display_grid: e.target.value });
                      setIsChange(true);
                    }}
                  >
                    <option value={true}>{t('lang_Grid')}</option>
                    <option value={false}>{t('lang_List')}</option>
                  </select>
                  <div className='select-arrow'></div>
                </div>
              </div>
              <div className='mb-4 flex items-center justify-between'>
                <label>{t('lang_Carousel_Status')}</label>
                <label className='relative flex ms-2 items-center cursor-pointer'>
                  <FormControlLabel
                    checked={blogData?.carousel_status}
                    control={<MuiSwitch />}
                    onChange={(e) => {
                      setBlogData({ ...blogData, carousel_status: !blogData?.carousel_status });
                      setIsChange(true);
                    }}
                  />
                </label>
              </div>
              <div className='w-full'>
                <label>{t('lang_Carousel_Speed_Ms')}</label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.carousel_speed_ms}
                    disabled={blogData?.carousel_status === 'disabled'}
                    onChange={(e) => {
                      setBlogData({ ...blogData, carousel_speed_ms: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 5000'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Text')}</label>
                <input
                  type='text'
                  value={blogData?.button_text}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  onChange={(e) => {
                    setBlogData({ ...blogData, button_text: e.target.value });
                    setIsChange(true);
                  }}
                  placeholder='e.g. Explore All'
                />
              </div>
              <div className='w-full'>
                <label htmlFor='image'>{t('lang_Button_Link')}</label>
                <input
                  type='text'
                  value={blogData?.button_link}
                  className='w-full border main-border-color px-4 py-2 text-sm outline-none focus:border-gray-400 rounded-[6px]'
                  placeholder='Add link'
                  onChange={(e) => {
                    setBlogData({ ...blogData, button_link: e.target.value });
                    setIsChange(true);
                  }}
                />
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Width')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_width}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_image_width: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 318'
                  />
                </div>
              </div>
              <div className='w-full'>
                <label>
                  {t('lang_Blog_Image_Height')}
                  <span className='lowercase text-xs font-normal text-gray-500'>
                    {' '}
                    ( {t('lang_In_Pixels')} )
                  </span>
                </label>
                <div className='relative'>
                  <input
                    type='number'
                    value={blogData?.blog_image_height}
                    onChange={(e) => {
                      setBlogData({ ...blogData, blog_image_height: e.target.value });
                      setIsChange(true);
                    }}
                    className='w-full border main-border-color px-4 py-2 rounded-[6px] text-sm outline-none focus:border-gray-500'
                    placeholder='e.g. 192'
                  />
                </div>
              </div>
            </div>
            <hr className='border-t main-border-color my-4'></hr>
            <div className='flex items-end w-full justify-between'>
              <div className='flex items-center justify-between w-full text-sm'>
                {storeBlog?.length === 0 ? (
                  <div>
                    {t('lang_Select_Blogs_For_Show_In_This_Group')}
                    <span className='text-red-500 ms-1'>*</span>
                  </div>
                ) : (
                  <div className='flex items-center'>
                    {selectedRowsId && selectedRowsId?.length > 0 && (
                      <>
                        <span className='text-sm px-3'>
                          {selectedRowsId?.length} {t('lang_selected')}
                        </span>
                        |
                        <button
                          className='bulk-btn mx-3 space-x-2 inline-flex items-center'
                          onClick={(e) => {
                            e.preventDefault();
                            setConfirmationPopup(true);
                          }}
                        >
                          <Trash2 className='w-3.5 h-3.5' />
                          <span>{t('lang_delete_selection')}</span>
                        </button>
                      </>
                    )}
                  </div>
                )}
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    setAddBlog(true);
                  }}
                  className='cursor-pointer text-link inline-flex items-center font-medium underline ms-1'
                >
                  {t('lang_Select_Blogs')}
                </span>
              </div>
            </div>
            {dataLoading && !storeBlog ? (
              <TableSkeleton />
            ) : storeBlog?.length > 0 ? (
              <div className='w-full inline-block border main-border-color rounded-[4px] overflow-x-auto overflow-y-hidden'>
                <table className='w-full'>
                  <thead>
                    <tr className='w-full'>
                      <td className='px-3 table-head-checkbox'>
                        <Checkbox
                          checked={selectedRowsId?.length === storeBlog?.length}
                          indeterminate={
                            selectedRowsId?.length !== storeBlog?.length &&
                            selectedRowsId?.length > 0
                          }
                          onChange={() => {
                            handleSelectRow('all');
                          }}
                        />
                      </td>
                      <td className='thead-image px-3 min-w-[80px]'>{t('lang_image')}</td>
                      <td className='w-full px-3'>{t('lang_Blog_Name')}</td>
                      <td className='min-w-[120px] max-w-[120px] px-3 text-right capitalize'>
                        {t('lang_action')}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <ReactDragListView {...dragProps}>
                      {storeBlog?.map((elem, index) => (
                        <tr
                          style={{
                            // boxShadow: snapshot.isDragging
                            //   ? '6px 8px 35px 7px rgba(0,0,0,0.2)'
                            //   : 'none',
                            background: 'white',
                          }}
                          className={`${selectedRowsId?.find((x) => x === elem?._id) ? 'selected-row' : ''
                            } select-none flex w-full`}
                          key={index}
                          onDragStart={() => setEditBlogId(elem?._id)}
                        >
                          <td className='px-3 table-body-checkbox'>
                            <Checkbox
                              checked={
                                selectedRowsId?.length > 0 &&
                                selectedRowsId?.find((x) => x === elem?._id)
                              }
                              className='inline-block'
                              onChange={() => handleSelectRow(elem?._id)}
                            />
                          </td>
                          <td className='table-image px-3 min-w-[80px]'>
                            <img
                              onError={(e) => {
                                e.target.src = NOPHOTO_IMAGE;
                              }}
                              width={80}
                              height={50}
                              src={elem?.blogData?.image || NOPHOTO_IMAGE}
                              className='p-1 max-w-full max-h-full'
                              alt={elem?.blogData?.title}
                            />
                          </td>
                          <td className='w-full px-3'>
                            <span className='line-clamp-2'>{elem?.blogData?.title || '-'}</span>
                          </td>
                          <td className='min-w-[120px] max-w-[120px] px-3 text-right'>
                            <div className='space-x-2 w-full text-right'>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  setConfirmationPopup(true);
                                  setSelectedRowsId([elem?._id]);
                                }}
                              >
                                <Trash className='w-[17px] h-[17px] text-red-500' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </ReactDragListView>
                  </tbody>
                </table>
              </div>
            ) : (
              <NoRecords />
            )}
          </div>

        </form>
        <Dialog
          open={addBlog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setAddBlog(false)}
          aria-describedby='alert-dialog-slide-description'
        >
          <div className='secondary-bg-color w-full sm:w-[600px] border main-border-color  space-y-4 rounded-[6px] p-5'>
            <div className='flex border-b text-primary main-border-color pb-3 items-center justify-between'>
              <h6 className='font-medium text-xl'>{t('lang_Select_Blogs')}</h6>
              <button
                className='close-btn'
                onClick={(e) => {
                  e.preventDefault();
                  setSearch('');
                  setAddBlog(false);
                  setSelectedBlog([]);
                  handleGetPerticularBlogGroup();
                }}
              >
                <X className='w-4 h-4' />
              </button>
            </div>
            <input
              type='text'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='w-full border main-border-color px-4 py-2 rounded-[6px] outline-none focus:border-gray-500 text-sm'
              placeholder='Search blogs..'
            />
            <div className='h-[450px] overflow-y-auto divide-y'>
              {allBlogs?.map((elem) => {
                return (
                  <label className='flex py-2 main-border-color table-text items-start space-x-4'>
                    <input
                      type='checkbox'
                      checked={selectedBlog?.find((x) => x?.blog === elem?._id)}
                      value={elem?._id}
                      onChange={(e) => {
                        handleSelectBlog(elem?._id);
                        setIsChange(true);
                      }}
                      className='block mt-1 w-4 h-4'
                    />
                    <div className='flex space-x-2'>
                      <div className='w-[60px] h-[60px] p-1 border main-border-color flex items-center justify-center'>
                        <img
                          onError={(e) => {
                            e.target.src = NOPHOTO_IMAGE;
                          }}
                          src={elem?.image || NOPHOTO_IMAGE}
                          className='max-w-full max-h-full'
                          alt='blog image'
                        />
                      </div>
                      <p className='text-base break-all'>{elem?.title}</p>
                    </div>
                  </label>
                );
              })}
            </div>
            <button className='btn text-sm text-white' onClick={() => handleAddBlog()}>
              {t('lang_Add_Blog')}
            </button>
          </div>
        </Dialog>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
          <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
            <Link
              onClick={(e) => {
                e.preventDefault();
                checkChanges();
              }}
              className='w-fit mt-2 flex text-link font-medium hover:underline text-sm items-center '
            >
              <ArrowLeft className='w-3 mr-1' />
              {t('lang_Go_To_Blog_Group')}
            </Link>
            <div>
              {!blogGroupTitle || blogGroupTitle === '' || storeBlog?.length === 0 ? (
                <button
                  type='button'
                  disabled
                  className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Blog_Group')}</span>
                </button>
              ) : btnLoading ? (
                <button
                  type='button'
                  className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[142px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                >
                  <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                  <span>{t('lang_Saving')}</span>
                </button>
              ) : (
                <button
                  onClick={(e) => handleAddBlogGroup(e)}
                  className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                >
                  <span>{t('lang_Edit_Blog_Group')}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditBlogGroup;
