import { Autocomplete, TextField } from '@mui/material';
import { ArrowLeft } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import API from '../../API';
import { ChangePathContext } from '../../App';
import Breadcrumbs from '../../controllers/breadCrumbs';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import Notification from '../Notification';
const AddStaff = () => {
  const navigate = useNavigate();
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const { saveChanges, setSaveChanges } = useContext(ChangePathContext);
  const [staffinfo, setStaffinfo] = useState({
    email: '',
    role: '',
  });
  const { email, role } = staffinfo;
  const [roles, setRoles] = useState();
  const [error, setError] = useState({ show: false, type: '', message: '' });
  const [validEmail, setValidEmail] = useState(false);
  const [validEmailErr, setValidEmailErr] = useState(false);
  const validatePassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[-_.,?!@#$%^&*()+=]/g.test(password);

    if (password?.length < 8) {
      setError({
        show: true,
        type: 'length',
        message: 'Password must be in 8 characters',
      });
      return false;
    }
    if (!hasUpperCase) {
      setError({
        show: true,
        type: 'hasUpperCase',
        message: 'Password must contain at least one uppercase letter.',
      });
      return false;
    }
    if (!hasLowerCase) {
      setError({
        show: true,
        type: 'hasLowerCase',
        message: 'Password must contain at least one lowercase letter.',
      });

      return false;
    }

    if (!hasNumber) {
      setError({
        show: true,
        type: 'hasNumber',
        message: 'Password must contain at least one number.',
      });

      return false;
    }

    if (!hasSymbol) {
      setError({
        show: true,
        type: 'hasSymbol',
        message: 'Password must contain at least one symbol.',
      });
      return false;
    }
    setError({ show: false, type: '', message: '' });
  };
  const HandleInput = (e) => {
    if (e.target.name === 'password') {
      validatePassword(e.target.value);
    } else if(e.target.name === 'email') {
      setValidEmailErr(false);
    }
    setStaffinfo({ ...staffinfo, [e.target.name]: e.target.value });
  };
  const emailVerify = async () => {
    if (email?.length >= 3) {
      try {
        const storeApiData = await API.get(`/super/admin/tenant/validemail/${email}`);
        if (storeApiData.status === 200 || storeApiData.status === 304) {
          setValidEmail(true);
          setValidEmailErr(false);
        }
      } catch (error) {
        setIsNotification(true);
        setNotificationMsg(t('lang_Email_is_invalid'));
        setSeverity('error');
        setValidEmail(false);
        setValidEmailErr(true);
      }
    }
  };
  const [err, setErr] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const HandleStaff = async (e) => {
    e.preventDefault();
    setBtnLoading(true);
    try {
      const payload = encryptData({
        email,
        role,
      })
      let ApiCall = await API.post(`/admin/client/addStaff`, payload);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Mail send successfully!');
        setSeverity('success');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
    setBtnLoading(false);
  };
  // Get Role Names
  const GetRoleNames = async () => {
    try {
      let ApiCall = await API.get(`/admin/role/getRoleName`);
      const result = decryptData(ApiCall?.data)
      setRoles(result);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  const handleClose = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  useEffect(() => {
    GetRoleNames();
  }, []);
  useEffect(() => {
    if (role?.length > 0 || email?.length > 0) {
      setSaveChanges({
        backLink: '/team',
        isUnsaveChanges: true,
        showChangesPopup: false,
        title: 'add team',
      });
    } else {
      setSaveChanges({
        backLink: '/team',
        isUnsaveChanges: false,
        showChangesPopup: false,
        title: 'add team',
      });
    }
  }, [role, email]);
  const checkChanges = () => {
    if (role?.length > 0 || email?.length > 0) {
      setSaveChanges({
        isUnsaveChanges: true,
        showChangesPopup: true,
        backLink: '/team',
        title: 'add team',
      });
    } else {
      navigate('/team');
    }
  };
  return (
    <>
      {isNotification && notificationMsg && (
        <Notification message={notificationMsg} close={handleClose} severity={severity} />
      )}
      <div className='h-auto overflow-y-auto pb-[130px]'>
        <div className='flex items-center space-x-2 pb-[30px]'>
          <button
            onClick={(e) => {
              e.preventDefault();
              checkChanges();
            }}
            className='back-arrow'
          >
            <ArrowLeft />
          </button>
          <div>
            <Breadcrumbs
              crumbs={[
                { name: t('Teamlang_team'), path: '/team' },
                { name: t('lang_Add_New_Team'), path: '/team/add-team' },
              ]}
            />
            <h4 className='text-xl md:text-2xl font-semibold text-primary'>
              {t('lang_Add_New_Team')}
            </h4>
          </div>
        </div>
        <div className='flex justify-evenly'>
          <form
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            method='POST'
            className='space-y-4 w-full lg:w-[70%] xl:w-[60%] mx-auto'
          >
            <div className='secondary-bg-color border main-border-color  rounded-[6px] p-5 sm:p-[25px]'>
              <div className='mb-4'>
                <label htmlFor='email'>
                  {t('lang_email')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>
                <input
                  className={
                    err && email?.length === 0
                      ? `w-full px-3 py-2 border border-red-500 rounded-[6px] focus:outline-none`
                      : `w-full px-3 py-2 border main-border-color rounded-[6px] focus:outline-none`
                  }
                  type='text'
                  id='email'
                  name='email'
                  value={email}
                  onChange={HandleInput}
                  onBlur={emailVerify}
                  placeholder='Enter email'
                />
                {err && email?.length === 0 ? (
                  <p className='text-red-500 text-sm font-medium'>{t('lang_Please_Enter_Email')}</p>
                ) : validEmailErr ?
                  <p className='text-red-500 text-sm font-medium'>{t('lang_Email_is_invalid')}</p>
                :
                (
                  <></>
                )}
              </div>
              <div>
                <label htmlFor='role' className='capitalize'>
                  {t('lang_role')}
                  <span className='text-red-500 ms-1'>*</span>
                </label>

                <Autocomplete
                  className='autocomplete'
                  options={roles?.data?.length > 0 ? roles?.data : []}
                  getOptionLabel={(role) => role.name}
                  onChange={(e, v) => (v ? setStaffinfo({ ...staffinfo, role: v.name }) : null)}
                  value={roles?.data?.find((rrole) => rrole.name === role) || null}
                  renderInput={(category) => (
                    <TextField
                      onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                      placeholder='Select role'
                      className='cursor-pointer '
                      {...category}
                    />
                  )}
                />
                {err && role === 'Select Role' ? (
                  <p className='text-red-500 text-sm font-medium'>{t('lang_Please_Select_Role')}</p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className='flex items-center shadow absolute justify-between w-full bottom-[0px] z-50  secondary-bg-color border-t main-border-color p-3 left-0'>
           <div className='w-[90%] flex items-center justify-between lg:w-[70%] xl:w-[58%] mx-auto'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  checkChanges();
                }}
                className='w-fit flex text-link font-medium hover:underline text-sm items-center '
              >
                <ArrowLeft className='w-3 mr-1' />
                {t('lang_Go_To_Team')}
              </Link>
              <div>
                {role === '' || email === '' || !validEmail ? (
                  <button
                    type='button'
                    className='inline-block cursor-not-allowed opacity-50 bg-btn text-white text-[12px] sm:text-[14px] font-medium py-[6px] px-[10px] sm:py-2 sm:px-5 rounded-[4px] transition duration-300'
                  >
                    <span> {t('lang_Add_New_Team')}</span>
                  </button>
                ) : btnLoading ? (
                  <button
                    type='button'
                    className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[12px] sm:text-[14px] font-medium w-[150px] cursor-not-allowed py-[6px] sm:py-2 rounded-[4px] transition duration-300'
                  >
                    <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
                    <span>{t('lang_loading')}...</span>
                  </button>
                ) : (
                  <button
                    onClick={(e) => HandleStaff(e)}
                    className='inline-block btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
                  >
                    <span> {t('lang_Add_New_Team')}</span>
                  </button>
                )}
              </div>
           </div>
           </div>
      </div>
    </>
  );
};

export default AddStaff;
