import { Autocomplete, Dialog, TextField } from '@mui/material';
import { X } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import Notification from '../Component/Notification';
import { Transition } from './Transition';
import API from '../API';
import { decryptData, encryptData } from './encryptionUtils';
import ImageUploader from './ImageUploader';
import { clientData } from './GetClient';

const MarketPlaceForm = ({ isFormOpen, closeForm }) => {
    const [allProduct, setAllProduct] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const [bgImage, setBgImage] = useState('');
    const [isNotification, setIsNotification] = useState(false);
    const [notificationMsg, setNotificationMsg] = useState('');
    const [severity, setSeverity] = useState();
    const handleClose = () => {
        setIsNotification(false);
        setNotificationMsg('');
        setSeverity('');
    };
    const getAllProducts = async () => {
        try {
            const data = await API.post('/admin/product/GetProductsTable')
            if (data.status === 200 || data.status === 304) {
                const result = decryptData(data?.data);
                setAllProduct(result?.data?.records);
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    }
    useEffect(() => {
        getAllProducts();
    }, [])
    const filterOptionsForProduct = (options, state) => {
        const normalizedInputValue = state.inputValue?.toLowerCase();
        return options.filter((option) => {
            const combinedFields = `${option?._id}}`;
            return combinedFields.includes(normalizedInputValue);
        });
    };
    const handleSpecificSellingCountries = (name) => {
        if (name) {
            const cat = selectedProduct.find((x) => x === name);
            if (!cat) {
                setSelectedProduct([...selectedProduct, name]);
            }
        }
    };
    const removeSellingCountries = (name) => {
        const removeRelated = selectedProduct.filter((x) => x !== name);
        setSelectedProduct(removeRelated);
    };
    const handleSetImage = (value) => {
        setBgImage(value);
    }
    const syncProduct = async (e) => {
        try {
            e.preventDefault()
            const body = {
                to_tenant: 'Whispo',
                productIds: selectedProduct
            }
            const payload = encryptData(body)
            const apiCall = await API.post('/admin/marketplace/syncmarketplace', payload)
            if (apiCall?.status === 200 || apiCall?.status === 304) {
                setIsNotification(true);
                setNotificationMsg("Product sync successfully");
                setSeverity('success');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        } finally {
            closeForm();
        }
    }
    const updateMarketBGImage = async (e) => {
        try {
            e.preventDefault();
            const payload = {
                clientInfo: {
                    background_image: bgImage,
                }
            }
            const ApiCall = await API.put('/admin/client/updateClient', payload);
            if (ApiCall?.status === 200 || ApiCall?.status === 304) {
                setIsNotification(true);
                setNotificationMsg("Set background image successfully");
                setSeverity('success');
            }
        } catch (error) {
            setIsNotification(true);
            setNotificationMsg(error);
            setSeverity('error');
        }
    }
    useEffect(() => {
        if(clientData?.background_image) {
            setBgImage(clientData?.background_image)
        }
    }, [clientData])
    return (
        <>
            {isNotification && notificationMsg && (
                <Notification message={notificationMsg} close={handleClose} severity={severity} />
            )}
            <Dialog
                open={isFormOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => {
                    closeForm();
                }}
                aria-describedby='alert-dialog-slide-description'
            >
                <div className='h-auto secondary-bg-color overflow-y-auto p-5'>
                    <div className='flex items-end justify-between w-full overflow-auto'>
                        <h6 className='text-xl md:text-2xl font-semibold text-primary'>MarketPlace Form</h6>
                        <button
                            type='button'
                            className='close-btn'
                            onClick={(e) => {
                                e.preventDefault();
                                closeForm();
                            }}
                        >
                            <X className='h-4 w-4' aria-hidden='true' />
                        </button>
                    </div>
                    <hr className='my-4 main-border-color' />
                    <div className='flex w-full md:w-[600px] justify-evenly'>
                        <form
                            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                            method='POST'
                            className='space-y-4 w-full mx-auto'
                        >
                            <div className='group space-y-4'>
                                <div className='mb-4'>
                                    <div className='w-full'>
                                        <label htmlFor='product_name'>
                                            {`${t('lang_background')} ${t('lang_Image')}`}
                                            <span className='text-red-500 ms-1'>*</span>
                                        </label>

                                        <ImageUploader
                                            alreadyImage={bgImage}
                                            handleSetImage={handleSetImage}
                                            folder='marketplace'
                                            format='image'
                                        />
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <label htmlFor='specific_countries'>{t('lang_Select_Products')}</label>
                                    <Autocomplete
                                        className='autocomplete'
                                        options={allProduct ? allProduct : []}
                                        getOptionLabel={(item) => item.product_name}
                                        filterOptions={filterOptionsForProduct}
                                        filterSelectedOptions
                                        onChange={(e, v) => (v ? handleSpecificSellingCountries(v?._id) : '')}
                                        renderInput={(params) => (
                                            <TextField
                                                placeholder={t('lang_Select_Products')}
                                                className='cursor-pointer'
                                                onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                                                value={params?.inputValue}
                                                {...params}
                                            />
                                        )}
                                    />
                                    <div className='flex flex-wrap mt-1'>
                                        {selectedProduct?.map((elem, index) => {
                                            return (
                                                <div
                                                    className='primary-bg-color flex items-center me-2 mb-2 min-w-[50px]  text-xs text-center px-3 capitalize py-1.5 rounded-[6px]'
                                                    key={index}
                                                >
                                                    <span className='table-text'>{allProduct?.find(x => x._id === elem)?.product_name}</span>
                                                    <X
                                                        className='h-[14px] text-red-500 hover:text-red-700 w-[14px] ms-1 cursor-pointer'
                                                        onClick={() => removeSellingCountries(elem)}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                                <div className='w-full flex justify-end'>
                                    <button
                                        type='button'
                                        onClick={(e) => {syncProduct(e); if(clientData?.background_image !== bgImage){ updateMarketBGImage(e) }}}
                                        className='btn text-white text-sm'
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default MarketPlaceForm