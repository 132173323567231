import { Tooltip } from '@mui/material';
import { Edit, Trash } from 'feather-icons-react/build/IconComponents';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import API from '../../API';
import ConfirmationPopup from '../../controllers/ConfirmationPopup';
import { decryptData, encryptData } from '../../controllers/encryptionUtils';
import MetaFieldForm from '../../controllers/MetaFieldForm';
import NoRecords from '../Main/NoRecords';
import Notification from '../Notification';
import ReactDragListView from 'react-drag-listview';

const GetPortfolioMetaField = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [openMetaFieldForm, setOpenMetaFieldForm] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [isNotification, setIsNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [severity, setSeverity] = useState();
  const [metaFieldId, setMetaFieldId] = useState(null);

  const handleCloseNotification = () => {
    setIsNotification(false);
    setNotificationMsg('');
    setSeverity('');
  };
  const getPortfolioMetaField = async () => {
    try {
      const ApiCall = await API.get('admin/metafieldgroup/getMetaFieldGroup/portfolio');
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        const result = decryptData(ApiCall?.data);
        setPortfolioData(result?.data?.custom_fields);
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };
  useEffect(() => {
    getPortfolioMetaField();
  }, [openMetaFieldForm, confirmationPopup]);

  const sortProduct = async (index, id) => {
    try {
      const payload = encryptData({
        newIndex: index === 0 ? 0 : index,
      });
      await API.put(`/admin/metafieldgroup/UpdateMetaField/${id}`, payload);
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
    }
  };

  // const HandleSort = (result) => {
  //   const items = Array.from(portfolioData);
  //   const [reorderedItem] = items.splice(result?.source?.index, 1);
  //   items.splice(result?.destination?.index, 0, reorderedItem);
  //   setPortfolioData(items);
  // };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const newItems = [...portfolioData];
      const [movedItem] = newItems.splice(fromIndex, 1);
      newItems.splice(toIndex, 0, movedItem);
      setPortfolioData(newItems);
      sortProduct(toIndex, metaFieldId);
      setMetaFieldId(null);
    },
    nodeSelector: 'tr',
    handleSelector: 'tr',
  };

  const handleEdit = (elem) => {
    setOpenMetaFieldForm(true);
    setEditData(elem);
  };
  const handleCloseConfirm = (value) => {
    setConfirmationPopup(value);
  };
  const removePortfolioMetaField = async () => {
    try {
      const ApiCall = await API.delete(`admin/metafieldgroup/DeleteMetaFieldGroup/${deleteId}`);
      if (ApiCall.status === 200 || ApiCall.status === 304) {
        setIsNotification(true);
        setNotificationMsg('Meta field deleted successfully');
        setSeverity('success');
        setConfirmationPopup(false);
        setDeleteId('');
      }
    } catch (error) {
      setIsNotification(true);
      setNotificationMsg(error);
      setSeverity('error');
      setConfirmationPopup(false);
    }
  };
  const handleCloseMetaFieldForm = () => {
    setOpenMetaFieldForm(false);
    setEditData({});
  };
  return (
    <div className='w-full h-full overflow-y-auto  py-4 pl-3 md:pl-4'>
      {isNotification && notificationMsg && (
        <Notification
          message={notificationMsg}
          close={handleCloseNotification}
          severity={severity}
        />
      )}
      <div className='flex flex-wrap items-end justify-between w-full pb-4'>
        <div className='flex items-center space-x-2'>
          <h5>{t('lang_Portfolio')}</h5>
        </div>
        <div className='flex items-center bg-[#28282B] rounded-[6px] text-sm font-medium'>
          <button
            type='button'
            className='flex btn rounded-s text-sm items-center font-medium  text-white transition duration-300'
            onClick={() => setOpenMetaFieldForm(true)}
          >
            {t('lang_Add_Portfolio')}
          </button>
        </div>
      </div>
      {portfolioData?.length > 0 ? (
        <div className='w-full transition-all duration-100 inline-block border main-border-color rounded-[6px] overflow-x-auto overflow-y-hidden'>
          <table className='w-full'>
            <thead>
              <tr>
                <td className='px-3 w-full'>{t('lang_group_name')}</td>
                <td className='px-3 w-full'>{t('lang_Content_type')}</td>
                <td className='min-w-[120px] max-w-[120px] px-3 text-right'>{t('lang_action')}</td>
              </tr>
            </thead>
            <tbody>
              <ReactDragListView {...dragProps}>
                {portfolioData?.length > 0 &&
                  portfolioData?.map((elem, index) => (
                    <tr
                      style={{
                        // boxShadow: snapshot.isDragging ? '6px 8px 35px 7px rgba(0,0,0,0.2)' : 'none',
                        background: 'white',
                      }}
                      key={index}
                      onClick={() => handleEdit(elem)}
                      onDragStart={() => setMetaFieldId(elem?._id)}
                    >
                      <td className='px-3 w-full'>
                        <span className='capitalize'>{elem.custom_field_name || '-'}</span>
                      </td>
                      <td className='px-3 w-full'>
                        <span className='capitalize'>{elem.custom_field_type || '-'}</span>
                      </td>
                      <td className='min-w-[100px] max-w-[100px] px-3 text-right' onClick={(e) => e.stopPropagation()}>
                        <div className='w-full space-x-2 text-right'>
                          <Tooltip title='Edit' arrow>
                            <button
                              type='button'
                              className=' text-black-500 hover:text-black-600'
                              onClick={() => handleEdit(elem)}
                            >
                              <Edit className='w-[17px] h-[17px]' />
                            </button>
                          </Tooltip>
                          <Tooltip title='Delete' arrow>
                            <button
                              type='button'
                              className='text-red-500 hover:text-red-600'
                              onClick={() => {
                                setConfirmationPopup(true);
                                setDeleteId(elem?._id);
                              }}
                            >
                              <Trash className=' w-[17px] h-[17px]' />
                            </button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
              </ReactDragListView>
            </tbody>
          </table>
        </div>
      ) : (
        <NoRecords />
      )}
      {openMetaFieldForm && (
        <MetaFieldForm
          openMetaFieldForm={openMetaFieldForm}
          handleClose={handleCloseMetaFieldForm}
          heading='PortFolio'
          editData={editData}
        />
      )}
      {confirmationPopup && (
        <ConfirmationPopup
          heading='Delete portfolio meta-field'
          subheading='Do you really want to delete this portfolio meta-field from your store?'
          confirmationPopup={confirmationPopup}
          handleCloseConfirm={handleCloseConfirm}
          runFunction={removePortfolioMetaField}
        />
      )}
    </div>
  );
};

export default GetPortfolioMetaField;
