import { Dialog } from '@mui/material';
import { Loader, Trash, Upload } from 'feather-icons-react/build/IconComponents';
import React, { useRef, useState } from 'react';
import { Bandaid } from 'react-bootstrap-icons';
import IconList from './IconList';
import { t } from 'i18next';

const ButtonSettingSection = ({
  handleButtonChange,
  section_id,
  buttonValue,
  handleButtonLinkChange,
  handleIconSelectValue,
  setIsOpen,
  isOpen,
  saveElementorData,
  btnLoading,
}) => {
  const fileInputRef = useRef(null);

  const handleButtonValueChange = (e) => {
    handleButtonChange(section_id, e.target.value);
  };

  const handleButtonLinkValueChange = (e) => {
    handleButtonLinkChange(section_id, e.target.value);
  };

  const handleIconClick = (value) => {
    if (value === 'iconList') {
      setIsOpen(true);
    }
  };

  const handleIconSelect = (iconComponent) => {
    handleIconSelectValue(section_id, iconComponent);
  };

  const handleSvgClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('No file selected. Please select an SVG file.');
      return;
    }
    if (file && file.type === 'image/svg+xml') {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageUrl = e.target.result;
        const isValidSvg = /<svg[\s\S]*<\/svg>/i.test(imageUrl);
        if (!isValidSvg) {
          alert('Invalid SVG content. Please upload a valid SVG file.');
          return;
        }
        handleIconSelectValue(section_id, imageUrl);
      };
      reader.readAsText(file);
    } else {
      alert('Please select a valid SVG file.');
    }
  };

  return (
    <div className='w-full mb-4 mt-2'>
      <div className='w-full mb-4'>
        <label htmlFor='buttonText'>Text</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='buttonText'
          name='buttonText'
          placeholder='Click Here...'
          onChange={handleButtonValueChange}
          value={buttonValue?.text || ''}
        />
      </div>

      <div className='w-full mb-4'>
        <label htmlFor='buttonLink'>Link</label>
        <input
          className='w-full px-3 mt-2 py-2 border main-border-color rounded-[6px] focus:outline-none'
          type='text'
          id='buttonLink'
          name='buttonLink'
          placeholder='#'
          onChange={handleButtonLinkValueChange}
          value={buttonValue?.link || ''}
        />
      </div>
      <div className='w-full mb-4 mt-2'>
        <input
          type='file'
          accept='.svg'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
      </div>
      <div className='flex justify-between items-center'>
        <label>Icon</label>
        <div className='flex space-x-2'>
          <button
            className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
            // onClick={() => handleButtonIconClick('disable')}
          >
            <Bandaid className='text-gray-600' />
          </button>

          <button
            className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
            // onClick={() => handleButtonIconClick('upload')}
            onClick={() => handleSvgClick()}
          >
            <Upload className='text-gray-600' />
          </button>

          <button
            className='p-2 border rounded-[6px] bg-gray-100 hover:bg-gray-200'
            onClick={() => handleIconClick('iconList')}
          >
            <Loader className='text-gray-600' />
          </button>
        </div>
      </div>

      {isOpen ? (
        <IconList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          section_id={section_id}
          handleIconSelect={handleIconSelect}
        />
      ) : (
        ''
      )}
      <div className='absolute flex items-center bg-[#ffffff] bottom-0 w-full z-50 py-4'>
        {btnLoading ? (
          <button
            type='button'
            className='inline-flex items-center justify-center space-x-2 bg-btn text-white text-[14px] font-medium w-[122px] cursor-not-allowed py-2 rounded-[4px] transition duration-300'
          >
            <div className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'></div>
            <span>{t('lang_loading')}...</span>
          </button>
        ) : (
          <button
            type='button'
            className='inline-block bg-btn text-white text-[14px] font-medium py-2 px-5 rounded-[4px] transition duration-300'
            onClick={(e) => saveElementorData(e)}
          >
            <span>{t('lang_Apply_Changes')}</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ButtonSettingSection;
