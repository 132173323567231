import React, { useState } from 'react';

const DynamicStyleState = () => {
  const [headingDesign, setHeadingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [mapDesign, setMapDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    zoom: '',
    map_color: '',
    map_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    map_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
    width: '',
  });

  const [spacerDesign, setSpacerDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [testiMonialDesign, setTestiMonialDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    name_font_weight: '',
    name_font_size: '',
    name_font_height: '',
    name_font_color: '',
    name_font_align: '',
    name_font_decoration: '',
    name_font_capitalize: '',
    name_font_break: '',
    name_font_wrap: '',
    name_letter_spacing: '',
    name_word_spacing: '',
    name_text_stroke: '',
    name_stroke_color: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    image_width: '',
    image_border_style: '',
    image_border_radius_top: '',
    image_border_radius_right: '',
    image_border_radius_bottom: '',
    image_border_radius_left: '',
    image_border_top: '',
    image_border_right: '',
    image_border_bottom: '',
    image_border_left: '',
    image_box_shadow_blur: '',
    image_border_color: '',
    image_position: '',
    content_alignment: '',
    vertical_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
    name_shadow_color: '',
    name_shadow_blur: '',
    name_shadow_horizontal: '',
    name_shadow_vertical: '',
  });

  const [socialIconDesign, setSocialIconDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    icon_width: '',
    icon_border_style: '',
    icon_border_radius_top: '',
    icon_border_radius_right: '',
    icon_border_radius_bottom: '',
    icon_border_radius_left: '',
    icon_border_top: '',
    icon_border_right: '',
    icon_border_bottom: '',
    icon_border_left: '',
    icon_border_color: '',
    icon_spacing: '',
    icon_border_radius_all: '',
    icon_padding: '',
    icon_align: '',
    icon_color: '',
  });

  const [alertDesign, setAlertDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    icon_width: '',
    icon_border_color: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
    alert_background: '',
  });

  const [htmlDesign, setHtmlDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    html_align: '',
    html_rotate: '',
    background_position: '',
  });

  const [iconDesign, setIconDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    icon_color: '',
    icon_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    icon_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
    width: '',
  });

  const [iconBoxDesign, setIconBoxDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    icon_width: '',
    icon_border_style: '',
    icon_border_radius_top: '',
    icon_border_radius_right: '',
    icon_border_radius_bottom: '',
    icon_border_radius_left: '',
    icon_border_top: '',
    icon_border_right: '',
    icon_border_bottom: '',
    icon_border_left: '',
    icon_box_shadow_blur: '',
    icon_border_color: '',
    icon_spacing: '',
    content_spacing: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
  });

  const [iconListDesign, setIconListDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    icon_width: '',
    icon_color: '',
    icon_spacing: '',
    content_spacing: '',
    icon_position: '',
    content_alignment: '',
    vertical_alignment: '',
    icon_border_radius_all: '',
    space_between: '',
    item_align: '',
    divider_visible: '',
    divider_style: '',
    divider_weight: '',
    divider_width: '',
    divider_color: '',
    horizontal_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
  });

  const [imageDesign, setImageDesign] = useState({
    image_align: '',
    image_width: '',
    image_maxWidth: '',
    image_height: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_color: '',
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    opacity: '',
    image_rotate: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [textEditorDesign, setTextEditorDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [videoDesign, setVideoDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    aspect_ratio: '',
    width: '',
    video_align: '',
    video_rotate: '',
    background_position: '',
    aspectRatio: '',
  });

  const [buttonDesign, setButtonDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    text_shadow_color: '',
    font_shadow_blur: '',
    font_shadow_horizontal: '',
    font_shadow_vertical: '',
    font_shadow_spread: '',
    button_padding_top: '',
    button_padding_right: '',
    button_padding_bottom: '',
    button_padding_left: '',
    button_padding_all: '',
    button_border_style: '',
    button_border_top: '',
    button_border_right: '',
    button_border_bottom: '',
    button_border_left: '',
    button_border_all: '',
    button_border_radius_top: '',
    button_border_radius_right: '',
    button_border_radius_bottom: '',
    button_border_radius_left: '',
    button_border_radius_all: '',
    button_border_color: '',
    button_background: '#61CE70',
    button_background_gradient: 'button_classic',
    button_gradient_color_start: '',
    button_gradient_start_position: '',
    button_gradient_color_end: '',
    button_gradient_end_position: '',
    button_gradient_angle: '',
    button_box_shadow_color: '',
    button_box_shadow_blur: '',
    button_box_shadow_horizontal: '',
    button_box_shadow_vertical: '',
    background_position: '',
  });

  const [dividerDesign, setDividerDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    divider_weight: '',
    gap: '',
    divider_color: '',
    divider_align: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    stroke_color: '',
    text_shadow_color: '',
    divider_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    height: '',
  });

  const [imageBoxDesign, setImageBoxDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    text_rotate: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    desc_font_weight: '',
    desc_font_size: '',
    desc_font_height: '',
    desc_font_color: '',
    desc_font_align: '',
    desc_font_decoration: '',
    desc_font_capitalize: '',
    desc_font_break: '',
    desc_font_wrap: '',
    desc_letter_spacing: '',
    desc_word_spacing: '',
    desc_text_stroke: '',
    desc_stroke_color: '',
    image_width: '',
    image_border_style: '',
    image_border_radius_top: '',
    image_border_radius_right: '',
    image_border_radius_bottom: '',
    image_border_radius_left: '',
    image_border_top: '',
    image_border_right: '',
    image_border_bottom: '',
    image_border_left: '',
    image_box_shadow_blur: '',
    image_border_color: '',
    image_spacing: '',
    content_spacing: '',
    image_position: '',
    content_alignment: '',
    vertical_alignment: '',
    title_shadow_color: '',
    title_shadow_blur: '',
    title_shadow_horizontal: '',
    title_shadow_vertical: '',
    desc_shadow_color: '',
    desc_shadow_blur: '',
    desc_shadow_horizontal: '',
    desc_shadow_vertical: '',
  });

  const [counterDesign, setCounterDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    number_shadow_color: '',
    number_shadow_blur: '',
    number_shadow_horizontal: '',
    number_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    counter_font_weight: '',
    counter_font_size: '',
    counter_font_height: '',
    counter_font_color: '',
    counter_font_align: '',
    counter_font_style: '',
    counter_font_decoration: '',
    counter_font_capitalize: '',
    counter_font_break: '',
    counter_font_wrap: '',
    counter_letter_spacing: '',
    counter_word_spacing: '',
    counter_text_stroke: '',
    counter_stroke_color: '',
    title_position: '',
    title_horizontal_alignment: '',
    title_vertical_alignment: '',
    number_position: '',
    title_spacing: '',
  });

  const [tabDesign, setTabDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_active_font_color: '',
  });

  const [accordianDesign, setAccordianDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_active_font_color: '',
  });

  const [ratingDesign, setRatingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '',
    padding_right: '',
    padding_bottom: '',
    padding_left: '',
    padding_all: '',
    font: '',
    font_weight: '',
    font_size: '',
    font_height: '',
    font_color: '',
    font_align: '',
    font_style: '',
    font_decoration: '',
    font_capitalize: '',
    font_break: '',
    font_wrap: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    letter_spacing: '',
    word_spacing: '',
    text_stroke: '',
    stroke_color: '',
    text_shadow_color: '',
    text_shadow_blur: '',
    text_shadow_horizontal: '',
    text_shadow_vertical: '',
    text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    icon_color: '',
    unmarked_color: '',
    icon_size: '',
    icon_spacing: '',
  });

  const [firstContentDesign, setFirstContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '1.6',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [secondCtaDesign, setSecondCtaDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    note_font_weight: '500',
    note_font_size: '',
    note_font_height: '',
    note_font_color: '',
    note_font_align: '',
    note_font_style: '',
    note_font_decoration: '',
    note_font_capitalize: '',
    note_font_break: '',
    note_font_wrap: '',
    note_letter_spacing: '',
    note_word_spacing: '',
    note_text_stroke: '',
    note_stroke_color: '',
    note_text_shadow_color: '',
    note_text_shadow_blur: '',
    note_text_shadow_horizontal: '',
    note_text_shadow_vertical: '',
    note_text_rotate: '',
    form_title_font_weight: '500',
    form_title_font_size: '',
    form_title_font_height: '',
    form_title_font_color: '',
    form_title_font_align: '',
    form_title_font_style: '',
    form_title_font_decoration: '',
    form_title_font_capitalize: '',
    form_title_font_break: '',
    form_title_font_wrap: '',
    form_title_letter_spacing: '',
    form_title_word_spacing: '',
    form_title_text_stroke: '',
    form_title_stroke_color: '',
    form_title_text_shadow_color: '',
    form_title_text_shadow_blur: '',
    form_title_text_shadow_horizontal: '',
    form_title_text_shadow_vertical: '',
    form_title_text_rotate: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [thirdCtaDesign, setThirdCtaDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [forthCtaDesign, setForthCtaDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [firstCtaDesign, setFirstCtaDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_hover_color: '3949AB',
    button_text_color: '#ffffff',
  });

  const [secondContentDesign, setSecondContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdContentDesign, setThirdContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
  });

  const [forthContentDesign, setForthContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    sub_title_font_weight: '',
    sub_title_font_size: '',
    sub_title_font_height: '',
    sub_title_font_color: '#28282B',
    sub_title_font_align: '',
    sub_title_font_style: '',
    sub_title_font_decoration: '',
    sub_title_font_capitalize: '',
    sub_title_font_break: '',
    sub_title_font_wrap: '',
    sub_title_letter_spacing: '',
    sub_title_word_spacing: '',
    sub_title_text_stroke: '',
    sub_title_stroke_color: '',
    sub_title_text_shadow_color: '',
    sub_title_text_shadow_blur: '',
    sub_title_text_shadow_horizontal: '',
    sub_title_text_shadow_vertical: '',
    sub_title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
  });

  const [fifthContentDesign, setFifthContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [sixContentDesign, setSixContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    content_font_weight: '',
    content_font_size: '',
    content_font_height: '',
    content_font_color: '#6B7280',
    content_font_align: '',
    content_font_style: '',
    content_font_decoration: '',
    content_font_capitalize: '',
    content_font_break: '',
    content_font_wrap: '',
    content_letter_spacing: '',
    content_word_spacing: '',
    content_text_stroke: '',
    content_stroke_color: '',
    content_text_shadow_color: '',
    content_text_shadow_blur: '',
    content_text_shadow_horizontal: '',
    content_text_shadow_vertical: '',
    content_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [sevenContentDesign, setSevenContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    button_link_text_color: '#ffffff',
  });

  const [eightContentDesign, setEightContentDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstFeatureDesign, setFirstFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondFeatureDesign, setSecondFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [sevenFeatureDesign, setSevenFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [eightFeatureDesign, setEightFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstStepDesign, setFirstStepDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondStepDesign, setSecondStepDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdStepDesign, setThirdStepDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdFeatureDesign, setThirdFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [forthFeatureDesign, setForthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [fifthFeatureDesign, setFifthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    sub_title_font_weight: '500',
    sub_title_font_size: '',
    sub_title_font_height: '',
    sub_title_font_color: '#6366f1',
    sub_title_font_align: '',
    sub_title_font_style: '',
    sub_title_font_decoration: '',
    sub_title_font_capitalize: '',
    sub_title_font_break: '',
    sub_title_font_wrap: '',
    sub_title_letter_spacing: '1.6',
    sub_title_word_spacing: '',
    sub_title_text_stroke: '',
    sub_title_stroke_color: '',
    sub_title_text_shadow_color: '',
    sub_title_text_shadow_blur: '',
    sub_title_text_shadow_horizontal: '',
    sub_title_text_shadow_vertical: '',
    sub_title_text_rotate: '',
    title_font_weight: '500',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#111827',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '1.6',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [sixthFeatureDesign, setSixthFeatureDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstTeamDesign, setFirstTeamDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondTeamDesign, setSecondTeamDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#6B7280',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdTeamDesign, setThirdTeamDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstTestimonialDesign, setFirstTestimonialDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondTestimonialDesign, setSecondTestimonialDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdTestimonialDesign, setThirdTestimonialDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstStatisticDesign, setFirstStatisticDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [secondStatisticDesign, setSecondStatisticDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdStatisticDesign, setThirdStatisticDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstContactDesign, setFirstContactDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    note_font_weight: '',
    note_font_size: '',
    note_font_height: '',
    note_font_color: '#28282B',
    note_font_align: '',
    note_font_style: '',
    note_font_decoration: '',
    note_font_capitalize: '',
    note_font_break: '',
    note_font_wrap: '',
    note_letter_spacing: '',
    note_word_spacing: '',
    note_text_stroke: '',
    note_stroke_color: '',
    note_text_shadow_color: '',
    note_text_shadow_blur: '',
    note_text_shadow_horizontal: '',
    note_text_shadow_vertical: '',
    note_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [firstHeroDesign, setFirstHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [secondHeroDesign, setSecondHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [thirdHeroDesign, setThirdHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [forthHeroDesign, setForthHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [fifthHeroDesign, setFifthHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [sixthHeroDesign, setSixthHeroDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [firstPricingDesign, setFirstPricingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [secondPricingDesign, setSecondPricingDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
    button_color: '#6366f1',
    button_text_color: '#ffffff',
    second_button_color: '#f3f4f6',
    second_button_text_color: '#000000',
  });

  const [secondContactDesign, setSecondContactDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    note_font_weight: '',
    note_font_size: '',
    note_font_height: '',
    note_font_color: '#28282B',
    note_font_align: '',
    note_font_style: '',
    note_font_decoration: '',
    note_font_capitalize: '',
    note_font_break: '',
    note_font_wrap: '',
    note_letter_spacing: '',
    note_word_spacing: '',
    note_text_stroke: '',
    note_stroke_color: '',
    note_text_shadow_color: '',
    note_text_shadow_blur: '',
    note_text_shadow_horizontal: '',
    note_text_shadow_vertical: '',
    note_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  const [thirdContactDesign, setThirdContactDesign] = useState({
    margin: '',
    padding: '',
    margin_top: '',
    margin_right: '',
    margin_bottom: '',
    margin_left: '',
    margin_all: '',
    padding_top: '96',
    padding_right: '20',
    padding_bottom: '96',
    padding_left: '20',
    padding_all: '',
    border_style: '',
    border_top: '',
    border_right: '',
    border_bottom: '',
    border_left: '',
    border_all: '',
    border_radius_top: '',
    border_radius_right: '',
    border_radius_bottom: '',
    border_radius_left: '',
    border_radius_all: '',
    border_color: '',
    font: '',
    title_font_weight: '',
    title_font_size: '',
    title_font_height: '',
    title_font_color: '#28282B',
    title_font_align: '',
    title_font_style: '',
    title_font_decoration: '',
    title_font_capitalize: '',
    title_font_break: '',
    title_font_wrap: '',
    title_letter_spacing: '',
    title_word_spacing: '',
    title_text_stroke: '',
    title_stroke_color: '',
    title_text_shadow_color: '',
    title_text_shadow_blur: '',
    title_text_shadow_horizontal: '',
    title_text_shadow_vertical: '',
    title_text_rotate: '',
    description_font_weight: '',
    description_font_size: '',
    description_font_height: '',
    description_font_color: '#28282B',
    description_font_align: '',
    description_font_style: '',
    description_font_decoration: '',
    description_font_capitalize: '',
    description_font_break: '',
    description_font_wrap: '',
    description_letter_spacing: '',
    description_word_spacing: '',
    description_text_stroke: '',
    description_stroke_color: '',
    description_text_shadow_color: '',
    description_text_shadow_blur: '',
    description_text_shadow_horizontal: '',
    description_text_shadow_vertical: '',
    description_text_rotate: '',
    note_font_weight: '',
    note_font_size: '',
    note_font_height: '',
    note_font_color: '#28282B',
    note_font_align: '',
    note_font_style: '',
    note_font_decoration: '',
    note_font_capitalize: '',
    note_font_break: '',
    note_font_wrap: '',
    note_letter_spacing: '',
    note_word_spacing: '',
    note_text_stroke: '',
    note_stroke_color: '',
    note_text_shadow_color: '',
    note_text_shadow_blur: '',
    note_text_shadow_horizontal: '',
    note_text_shadow_vertical: '',
    note_text_rotate: '',
    background: '',
    background_image: '',
    background_image_size: '',
    background_repeat: '',
    background_attachment: '',
    background_fit: '',
    box_shadow_color: '',
    box_shadow_blur: '',
    box_shadow_horizontal: '',
    box_shadow_vertical: '',
    box_shadow_spread: '',
    box_shadow_position: '',
    background_gradient: '',
    gradient_color_start: '',
    gradient_start_position: '',
    gradient_color_end: '',
    gradient_end_position: '',
    gradient_angle: '',
    background_position: '',
  });

  return {
    headingDesign,
    setHeadingDesign,
    mapDesign,
    setMapDesign,
    spacerDesign,
    setSpacerDesign,
    testiMonialDesign,
    setTestiMonialDesign,
    socialIconDesign,
    setSocialIconDesign,
    alertDesign,
    setAlertDesign,
    htmlDesign,
    setHtmlDesign,
    iconDesign,
    setIconDesign,
    iconBoxDesign,
    setIconBoxDesign,
    iconListDesign,
    setIconListDesign,
    imageDesign,
    setImageDesign,
    textEditorDesign,
    setTextEditorDesign,
    videoDesign,
    setVideoDesign,
    buttonDesign,
    setButtonDesign,
    dividerDesign,
    setDividerDesign,
    imageBoxDesign,
    setImageBoxDesign,
    counterDesign,
    setCounterDesign,
    tabDesign,
    setTabDesign,
    accordianDesign,
    setAccordianDesign,
    ratingDesign,
    setRatingDesign,
    firstContentDesign,
    setFirstContentDesign,
    secondCtaDesign,
    setSecondCtaDesign,
    thirdCtaDesign,
    setThirdCtaDesign,
    forthCtaDesign,
    setForthCtaDesign,
    firstCtaDesign,
    setFirstCtaDesign,
    secondContentDesign,
    setSecondContentDesign,
    thirdContentDesign,
    setThirdContentDesign,
    forthContentDesign,
    setForthContentDesign,
    fifthContentDesign,
    setFifthContentDesign,
    sixContentDesign,
    setSixContentDesign,
    sevenContentDesign,
    setSevenContentDesign,
    eightContentDesign,
    setEightContentDesign,
    firstFeatureDesign,
    setFirstFeatureDesign,
    secondFeatureDesign,
    setSecondFeatureDesign,
    sevenFeatureDesign,
    setSevenFeatureDesign,
    eightFeatureDesign,
    setEightFeatureDesign,
    firstStepDesign,
    setFirstStepDesign,
    secondStepDesign,
    setSecondStepDesign,
    thirdStepDesign,
    setThirdStepDesign,
    thirdFeatureDesign,
    setThirdFeatureDesign,
    forthFeatureDesign,
    setForthFeatureDesign,
    fifthFeatureDesign,
    setFifthFeatureDesign,
    sixthFeatureDesign,
    setSixthFeatureDesign,
    firstTeamDesign,
    setFirstTeamDesign,
    secondTeamDesign,
    setSecondTeamDesign,
    thirdTeamDesign,
    setThirdTeamDesign,
    firstTestimonialDesign,
    setFirstTestimonialDesign,
    secondTestimonialDesign,
    setSecondTestimonialDesign,
    thirdTestimonialDesign,
    setThirdTestimonialDesign,
    firstStatisticDesign,
    setFirstStatisticDesign,
    secondStatisticDesign,
    setSecondStatisticDesign,
    thirdStatisticDesign,
    setThirdStatisticDesign,
    firstContactDesign,
    setFirstContactDesign,
    firstHeroDesign,
    setFirstHeroDesign,
    secondHeroDesign,
    setSecondHeroDesign,
    thirdHeroDesign,
    setThirdHeroDesign,
    forthHeroDesign,
    setForthHeroDesign,
    fifthHeroDesign,
    setFifthHeroDesign,
    sixthHeroDesign,
    setSixthHeroDesign,
    firstPricingDesign,
    setFirstPricingDesign,
    secondPricingDesign,
    setSecondPricingDesign,
    secondContactDesign,
    setSecondContactDesign,
    thirdContactDesign,
    setThirdContactDesign,
  };
};

export default DynamicStyleState;
